import React from 'react';
import { SearchIcon } from '@heroicons/react/outline';
import classNames from 'classnames';

export interface ISearchInput {
  id: string;
  className?: string;
  value?: string | string[];
  placeholder?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
}

export const SearchInput: React.FC<ISearchInput> = ({
  children,
  id,
  placeholder,
  className,
  value,
  onChange,
  ...restProps
}) => {
  const classes = classNames(
    className,
    'bg-dark-200 block w-full pl-10 py-2 text-medium text-white border-gray-300 outline-0'
  )

  return (
    <div className="relative flex grow">
      <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
        <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
      </div>
      <input
        id={id}
        type="text"
        className={classes}
        placeholder={placeholder || 'Search'}
        value={value || ''}
        onChange={(event) => onChange(event)}
      />
    </div>
  );
};

SearchInput.displayName = 'SearchInput';
