/* eslint-disable react/no-array-index-key */
import React from 'react'

import { BLOCKCHAIN_CONFIG } from '@alpha-shares/config';

import { ChainId } from '@alpha-shares/web3-sdk';
import supportedChains from '@alpha-shares/res-utils/chains'
import { useMarketplaceRouteBasePath } from '@alpha-shares/res-utils/hooks';
import { useCrossChainTokens, UseCrossChainTokensArgs } from '@alpha-shares/res-utils/hooks/useCrossChainTokens'

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'

import {
  Typography,
  MultiCarousel,
  Button
} from '../../..';

import TokenCard from '../../../collections/TokenCard';

interface IAssetCarousel {
  title?: string;
  subHeader?: string;
  titleClass?: string;
  HeadingLink?: JSX.Element
  chainId?: ChainId;
  collectionAddress?: string[];
  queryParams?: UseCrossChainTokensArgs;
  linkToCollectionPage?: boolean;
  config?: {
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
  }
}

const LoadingState = () => (
  <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>
    <div className="opacity-[30] animate animate-pulse m-3 lg:m-4 rounded-xl">
      <Skeleton count={1} style={{aspectRatio: '1 / 1'}} containerClassName='animate animate-pulse rounded-xl' className=' bg-dark-50 rounded-t-xl' />
      <Skeleton count={1} height={124} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-b-xl' />          
    </div>
  </SkeletonTheme>
  )

export const LatestListingsCarousel:React.FC<IAssetCarousel> = ({
  title = 'More From This Collection',
  subHeader,
  titleClass = 'mx-auto text-center py-4 mt-6',  
  linkToCollectionPage = true,
  queryParams = {
    numberPerChain: 24,
    query: [
      {
        nativeSource: 'www.alphashares.io',
        chainId: ChainId.AVALANCHE,
        contracts: [BLOCKCHAIN_CONFIG.ALPHA_NFT_ADDRESS[ChainId.AVALANCHE], '0xcf91b99548b1c17dd1095c0680e20de380635e20'],
      },
      {
        chainId: ChainId.ETHEREUM,
        contracts: ['0xa88b82af76ecf08cf652846d10857eaeeca40c97', '0x60e4d786628fea6478f785a6d7e704777c86a7c6']
      }
    ]
  },
  config = {
    sm: 1,
    md: 2,
    lg: 5,
    xl: 6
  }
}) => {
  const marketPlaceRouteBasePath = useMarketplaceRouteBasePath()

  const { loaded, results } = useCrossChainTokens(queryParams.numberPerChain, queryParams.query)

  let listingsCarouselData: any[] = []  

  listingsCarouselData = React.useMemo(() => {
    if (loaded && results.length > 0) {
      results.forEach((result, idx) => {
        let chainListings = result?.data
        // @ts-ignore
        if (chainListings?.tokens?.length)  {
          // @ts-ignore
          let thisChainCollections = chainListings?.tokens?.map((token) => {
            return {
              ...token,
              chainId: result.chainId
            }
          })
          listingsCarouselData.push(thisChainCollections)
        }        
      })
    }
    // @ts-ignore
    return listingsCarouselData.flatMap(item => item)

  }, [loaded] )

  const [playingElement, setPlayingElement] = React.useState<
    HTMLAudioElement | HTMLVideoElement | null
  >()

  const responsive = [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 1,          
      }
    },
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,
        centerPadding: '40px'          
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    }      
  ]

  const HeadingLinkComponent = () => { 
    const chain = supportedChains.find((chain) => chain.id === queryParams.query[0].chainId)
    return (
      <div className='mx-auto mb-3 px-6'>
        <Button
          href={`${marketPlaceRouteBasePath}/${chain?.name.toLowerCase()}/collection/${queryParams.query[0].contracts[0]}`}
          variant='tertiary' 
          className='mx-6 max-w-lg md:mx-auto'
          >View Collection</Button>
      </div>
    )
  }

  const canLinkToCollectionPage = React.useMemo(() => {
    return queryParams.query.length === 1 && queryParams.query[0].contracts.length === 1
  },[queryParams.query]);
  
  return (    
    <div className='relative pt-6 mt-6 md:mx-0'>
      
      <div className='flex items-center justify-center'>
        {subHeader && (
          <Typography
            variant="subtitle1"
            className="mt-6 text-center mx-auto !text-primary-500 -mb-12"
          >
            {subHeader}
          </Typography>
        )}
        {/* {canLinkToCollectionPage && linkToCollectionPage && <HeadingLinkComponent />} */}
      </div>
    {/* @ts-ignore */}
      <MultiCarousel
        carouselTitle={title}
        titleClass={titleClass}
        //@ts-ignore
        responsiveConfig={responsive}
        centerMode
        autoPlay
        HeadingLink={(canLinkToCollectionPage && linkToCollectionPage) ? <HeadingLinkComponent /> : undefined}
        // autoPlaySpeed={5000}
      > 
        {(!loaded) && ([...new Array(12).fill('x')].map((item, index) => (
          <LoadingState key={index} />
        )))}

        {/* @ts-ignore */}
        {listingsCarouselData && listingsCarouselData.map((token, idx) => (
          <div className="p-3 lg:p-4" key={idx}>
            <div className='bg-dark-400 rounded-xl overflow-hidden'>
              <TokenCard
                key={idx}
                // @ts-ignore
                token={token}
                // @ts-ignore
                address={token?.token?.contract}
                // mutate={mutate}
                rarityEnabled={true}
                onMediaPlayed={(e) => {
                  if (
                    playingElement
                      && playingElement !== e.nativeEvent.target
                  ) {
                    playingElement.pause()
                  }
                  const element = (e.nativeEvent.target as HTMLAudioElement)
                      || (e.nativeEvent.target as HTMLVideoElement)
                  if (element) {
                    setPlayingElement(element)
                  }
                }}                
              />
            </div>
          </div>  
        ))}
      </MultiCarousel>
      </div>
  )
}
