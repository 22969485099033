import React from 'react';
import Image from "next/image";

export interface IHeroProps {
  alt: string;
  src?: string;
  width?: number;
  height?: number;
  children?: React.ReactNode;
  childrenContainerClasses?: string
}

export const Hero: React.FC<IHeroProps> = ({
  children,
  alt,
  src,
  childrenContainerClasses = 'relative pt-8 lg:pt-24 lg:pb-12',
  width,
  height,
}) => (
  <div className="relative overflow-hidden b-6">
    <div className="absolute inset-x-0 bottom-0" />
    <div className="mx-auto">
      <div className="relative">
        <div className="lg:hidden absolute inset-0 overflow-hidden">
          {src ? (
            <Image
              className="h-full w-full object-cover"
              src={src}
              alt={alt}
              width={width}
              height={height}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          ) : null}
          {!src && <div className="absolute inset-0 animated-gradient-dark" />}

        </div>
        <div className="hidden lg:block absolute inset-0 overflow-hidden">
          {src ? (
            <Image
              className="h-full w-full object-cover"
              src={src}
              alt={alt}
              fill
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          ) : null}
          {!src && <div className="absolute inset-0 animated-gradient-dark" />}

        </div>
        <div className={childrenContainerClasses}>
          {children}
        </div>
      </div>
    </div>
  </div>
);

Hero.displayName = 'Hero';
