/* eslint-disable jsx-a11y/media-has-caption */

import React from 'react';
import classNames from 'classnames';
import Image from "next/image";
import { blurData } from './Shimmer';

export interface IImageProps {
  className?: string;
  src: string;
  alt: string;
  height?: number;
  width?: number;
  fill?: boolean;
  rounded?: boolean;
  autoPlay?: boolean;
  style?: {
    width: number,
    height: number
  }
}

export const ImageElement: React.FC<IImageProps> = ({
  className,
  src,
  alt,
  width = 415,
  height = 415,
  style,
  fill,
  rounded,
  autoPlay = false
}) => {
  let imageSrc = src;
  const containerClasses = classNames(
    'rounded-xl overflow-hidden',
    className
  );

  imageSrc = imageSrc !== 'loading' ? imageSrc : blurData(width, height);

  if (imageSrc?.endsWith('mp4')) {
    return (
      <div style={{ ...style }} className={containerClasses}>
        <video src={imageSrc} controls autoPlay={autoPlay} muted loop height="100%" width="100%" />
      </div>
    )
  }

  return (
    <div style={{ ...style }} className={containerClasses}>
      {fill ? (
        <Image
          placeholder="blur"
          blurDataURL={blurData(width, height)}
          unoptimized
          alt={alt}
          src={imageSrc}
          fill={fill}
          className={`${rounded && 'rounded-xl overflow-hidden'}`}
          style={{
            maxWidth: "100%",
            height: "auto"
          }} />
      ) : (
        <Image
          placeholder="blur"
          blurDataURL={blurData(width, height)}
          unoptimized
          alt={alt}
          src={imageSrc}
          width={width}
          height={height}
          className={`${rounded && 'rounded-xl overflow-hidden'}`}
          style={{
            maxWidth: "100%",
            height: "auto"
          }} />
      )}

    </div>
  );
};

ImageElement.displayName = 'Image';
