/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface ILeftSlideSidebar {
  open: boolean;
  setOpen: any;
  className?: string;
  children: JSX.Element;
}

export const LeftSlideSidebar: React.FC<ILeftSlideSidebar> = ({
  open,
  setOpen,
  children,
  className,
}) => (
  <Transition.Root show={open} as={Fragment}>
    <Dialog
      as="div"
      auto-reopen="true"
      className="fixed inset-0 overflow-hidden"
      onClose={setOpen}
      style={{ zIndex: 1100 }}
    >
      <div className="absolute inset-0 overflow-hidden">
        <Transition.Child
          as={Fragment}
          enter="transition ease-in-out duration-300 sm:duration-400"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transform transition ease-in-out duration-300 sm:duration-400"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-80" />
        </Transition.Child>
        <div className="fixed inset-y-0 left-0 pr-4 max-w-full flex sm:pr-4">
          <Transition.Child
            as={Fragment}
            enter="transform transition ease-in-out duration-500 sm:duration-700"
            enterFrom="-translate-x-full"
            enterTo="-translate-x-0"
            leave="transform transition ease-in-out duration-500 sm:duration-700"
            leaveFrom="-translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div
              className={`w-screen max-w-[360px] absolute h-screen ${className}`}
            >
              <div className="h-full flex flex-col shadow-xl overflow-y-auto lg:scrollbar scrollbar-thumb-dark-300 scrollbar-track-dark-50">
                {children}
              </div>
            </div>
          </Transition.Child>
        </div>
      </div>
    </Dialog>
  </Transition.Root>
);
