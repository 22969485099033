export const durationOptions = [
  {
    key: '1 Day',
    value: 1
  },
  {
    key: '2 Days',
    value: 2
  },
  {
    key: '3 Days',
    value: 3
  },
  {
    key: '7 Days',
    value: 7
  }, {
    key: '14 Days',
    value: 14
  }, {
    key: '30 Days',
    value: 30
  }, {
    key: '60 Days',
    value: 60
  }, {
    key: '90 Days',
    value: 90
  }, {
    key: '180 Days',
    value: 180
  }
]
