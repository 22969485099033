export const ASSET_DETAIL_VIEW = {
  TRAITS_ENABLED: true,
  PRICE_HISTORY_ENABLED: false,
  LISTINGS_ENABLED: false,
  OFFERS_ENABLED: true,
  ABOUT_COLLECTION_ENABLED: true,
  DESCRIPTION_ENABLED: true,
  ITEM_HISTORY_ENABLED: true,
  NFT_TYPE_CARD_ENABLED: true,
}
