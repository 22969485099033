export const SEO = {
  SITE_TITLE: 'Alpha Shares | Multichain NFT Marketplace Buy, Sell, Mint & Auction NFTs',
  SITE_TITLE_SHORT: ' | Alpha Shares Multichain NFT Marketplace Buy, Sell, Mint & Auction NFTs',
  DESCRIPTION: 'Alpha Shares is the first NFT Marketplace driven by its community of NFT and token holders. Experience trading, minting, buying, selling and learning about NFT collectibles on the AlphaShares today.',
  MINT_TITLE: 'Alpha Shares Launchpad Minting Project:',
  COLLECTION_TITLE: 'Alpha Shares Marketplace Trading:',
  OPEN_GRAPH: {
    twitterUsername: 'AlphaShares'
  }
};
