import { useRouter } from 'next/router'
import { useState, createContext, FC, useEffect, useCallback, useContext } from 'react'
import supportedChains, { DefaultChain } from '@alpha-shares/res-utils/chains'
import { useAccount, useNetwork } from 'wagmi'

const supportedChainsMap = supportedChains.reduce((map, chain) => {
  map[chain.id] = chain
  return map
}, {} as Record<string, (typeof supportedChains)[0]>)

export const ChainContext = createContext<{
  chain: typeof DefaultChain
  switchCurrentChain: (chainId: string | number) => void
  fees: string
}>({
  chain: DefaultChain,
  switchCurrentChain: () => {},
  fees: '0x6a6608f21a7cb98fba2c31f5fd917d8a84e1590a:200'
})

const ChainContextProvider: FC<any> = ({ children }) => {
  const { address } = useAccount()
  const { chain } = useNetwork()
  const [globalChainId, setGlobalChainId] = useState(DefaultChain.id)
  const [fees, setFees] = useState('0x6a6608f21a7cb98fba2c31f5fd917d8a84e1590a:000')
  const router = useRouter()

  async function getFees() {
    try {
      const response = await fetch('https://marketplace-api-sandy.vercel.app' + `/api/nft/${chain?.id}/${address}/fees`)
      const data = await response.json()
  
      setFees(data.fees)  
    } catch (error) {
      console.log(error)
    }
    
  }

  useEffect(() => {
    const savedChainId: number = Number(
      localStorage.getItem('reservoir.chainId') || DefaultChain.id
    )
    let selectedChain: (typeof supportedChains)[0] | undefined

    if (router.query.chain) {
      selectedChain = supportedChains.find(
        (chain) => chain.routePrefix === router.query.chain
      )
    }
    if (!selectedChain) {
      selectedChain = supportedChains.find(
        (chain) => chain.id === +savedChainId
      )
    }

    // TODO:: determine if this can be removed completely
    // if (!router.query.chain && selectedChain) {
    //   router.query.chain = selectedChain.routePrefix
    //   router.push(router, undefined, { shallow: true })
    // }

    const id = selectedChain?.id || DefaultChain.id

    setGlobalChainId(id)
    localStorage.setItem('reservoir.chainId', `${id}`)
  }, [])

  useEffect(() => {
    if (chain?.id && address) {
      getFees()
    }
  },[chain?.id, address])

  const switchCurrentChain = useCallback(
    (chainId: string | number) => {
      if (chainId === globalChainId) {
        return
      }

      setGlobalChainId(+chainId)

      if (typeof window !== 'undefined') {
        localStorage.setItem('reservoir.chainId', `${chainId}`)
      }
    },
    [globalChainId, setGlobalChainId]
  )

  let currentChain = DefaultChain
  if (globalChainId && supportedChainsMap[globalChainId]) {
    currentChain = supportedChainsMap[globalChainId]
  }

  return (
    <ChainContext.Provider value={{ chain: currentChain, switchCurrentChain, fees }}>
      {children}
    </ChainContext.Provider>
  )
}

export const useChainContext = () => useContext(ChainContext)

export default ChainContextProvider
