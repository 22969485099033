import React from 'react'
import Image from "next/image"
import { useQueryClient } from '@tanstack/react-query'

import { Typography, Button } from '../../'

import {
  type HomePageQuery,
  type PoweredBySection as PoweredBySectionType
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

export const PoweredBySection:React.FC = () => {
  const queryClient = useQueryClient()
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage'])
  const page = queryData?.page
  const poweredBySection = page?.poweredBySection

  const {
    heading, information, logo, buttons
  } = poweredBySection as PoweredBySectionType

  return (
    <div className="container mx-auto max-w-[80%] lg:max-w-[50%] mt-8 grid lg:grid-cols-2 gap-10">
      <div className="text-center flex items-center content-center justify-center lg:justify-end">
        {logo && logo?.url && (
        <Image
          src={logo?.url}
          width={500}
          height={500}
          alt="Logo"
          style={{
            maxWidth: "100%",
            height: "auto"
          }} />
        )}
      </div>
      <div>
        <Typography variant="h2" className="text-4xl font-bold py-8">
          {heading}
        </Typography>
        <Typography variant="p" className="text-sm font-semibold">
          {information}
        </Typography>
        <div className="flex pt-6 gap-6">
          {buttons && buttons.map((btnObj) => (
            <Button
              key={btnObj.id}
              href={btnObj.buttonLink}
              // @ts-ignore
              variant={btnObj.buttonVariant}
              target={btnObj.externalLink ? '_blank' : '_parent'}
            >
              {btnObj.buttonText}
            </Button>
          ))}
        </div>
      </div>
    </div>
  );
}
