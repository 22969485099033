import { GetStaticPropsContext } from 'next';

import { QueryClient, dehydrate } from '@tanstack/react-query';

import { 
  GetSidebarNavigationDocument,
  GetSidebarNavigationQuery,
  GetSidebarNavigationQueryVariables,
  AppId
} from '@alpha-shares/graphql-codegen/generated/graphCMS';

import { ssrGetGraphCMSData } from '@alpha-shares/fetchers'

export function withNavStaticProps(
  getStaticPropsFunc?: (
    context: GetStaticPropsContext,
    sharedPageStaticProps: any,
  ) => Promise<any>,
  appId: AppId = AppId.MarketplaceNext,  
) {
  return async (
    context: GetStaticPropsContext,
  ): Promise<any> => {
    // The in-memory or disk cache will be explained later on.
    const queryClient = new QueryClient();

    await queryClient.prefetchQuery(['navigationData'], () => ssrGetGraphCMSData<GetSidebarNavigationQuery>(GetSidebarNavigationDocument, {
      variables: {
        applicationId: appId
      } as GetSidebarNavigationQueryVariables
    }))

    // Shortcut if there's no getStaticPropsFunc defined, which makes it
    // simpler to write code for pages that don't need to fetch anything else
    if (getStaticPropsFunc == null) {
      return {
        props: {
          dehydratedState: dehydrate(queryClient),
        },
        revalidate: 60,
      };
    }

    return await getStaticPropsFunc(context, {
      props: {
        dehydratedState: dehydrate(queryClient),
      },
      revalidate: 60,
    });
  };
}