import React from 'react';
import Image from 'next/image';
import { graphCMSClient } from '@alpha-shares/fetchers'
import { RichText } from '@graphcms/rich-text-react-renderer';
import { VideoCarousel } from '../Carousels/VideoCarousel';
import { Button } from '../../Atoms';
import { ClientOnly } from '../../Wrappers';
import { CTASectionGeneric } from '../../Organisms/CTASections';
import {
  useLandingPageBySlugQuery,
  useGetMintingProjectBySlugQuery,
  CtaSection,
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

import { MintCountDownTimer } from '../Mint/MintCountDownTimer';
import { Typography } from '../../Atoms';

type TLandingPageProps = {
  slug: string
}

const renderers = {
  h1: ({ children }: { children: React.ReactNode }) => <Typography  variant='h1' className="mb-4 mt-8">{children}</Typography>,
  h2: ({ children }: { children: React.ReactNode }) => <Typography  variant='h2' as='h3' className="mb-4 mt-8">{children}</Typography>,
  h3: ({ children }: { children: React.ReactNode }) => <Typography variant='h3' className="text-white">{children}</Typography>,
  h4: ({ children }: { children: React.ReactNode }) => <Typography variant='h4' className="text-white">{children}</Typography>,
  li: ({ children }: { children: React.ReactNode }) => <li className="text-white mt-1">{children}</li>,
  bold: ({ children }: { children: React.ReactNode }) => <strong>{children}</strong>,
  p: ({ children }: { children: React.ReactNode }) => <Typography variant='p'>{children}</Typography>,
  Asset: {
    image: ({ url, alt, width, height }: { url: string, alt: string, width: number, height: number }) => {
      return (
        <div className='relative aspect-square overflow-hidden w-full h-16'>
        <Image
          src={url}
          alt={alt}
          fill
          className="h-full w-full object-cover"          
        />
        </div>
      );
    },
  },
}

const smallScreenVideoOptions = {
  height: '195',
  width: '320',
  playerVars: {
    autoplay: 0,
    controls: 1,
  },
};

export const LandingPage:React.FC<TLandingPageProps> = ({ slug }) => {
  const { data } = useLandingPageBySlugQuery(graphCMSClient, 
    { 
      slug 
    }
  )

  const { data: mintProject } = useGetMintingProjectBySlugQuery(graphCMSClient,
    {
      slug
    }
  )

  const pageData = data?.landingPage;
  const heroImage = pageData?.heroBannerImage?.url;
  const logoImage = pageData?.logoImage?.url;
  const heroText = pageData?.heroText;
  const videos = pageData?.youtubeCarousel;
  const textImageRow = pageData?.textImageRow;
  const pressSectionTitle = pageData?.pressSectionTitle;
  const mintDate = mintProject?.mintingProject?.mintDate
  const ctaSection = pageData?.ctaSection
  const ctaSectionBottom = pageData?.ctaSectionBottom

  return (
    <>
      {heroImage && (
        <>
        <div className="lg:hidden relative flex flex-col"  style={{aspectRatio: '2300 / 1080', background: `url(${heroImage}) no-repeat center center`, marginTop: '-200px' } }>
          <div className='mt-24 flex flex-col' >
            {/* @ts-ignore */}
            <Image src={logoImage} alt="Hero Image" width={500} height={500} className="mx-auto" style={{ marginTop: '100px'}} />
            <ClientOnly>
              <MintCountDownTimer className='bg-transparent flex justify-center' mintDate={mintDate} singleLine={false} />
            </ClientOnly>
            <Button href="https://www.alphashares.io/mint/alpha-pack-pups" className="mx-auto my-6" variant="secondary">View Mint Page</Button>
          </div>
        </div>
        {heroText && (
          <div className='text-center md:container mx-auto bg-dark-550 rounded-xl lg:hidden'>
            <RichText
              renderers={renderers}
              content={heroText?.raw} 
            />
          </div>
          )}  
          </>
      )}
      {heroImage && (
        <div className="hidden lg:block relative flex flex-col"  style={{aspectRatio: '2300 / 1080', background: `url(${heroImage}) no-repeat center center`, marginTop: '-200px' } }>
          <div className='mt-24 flex flex-col' >
            {/* @ts-ignore */}
            <Image src={logoImage} alt="Hero Image" width={500} height={500} className="mx-auto" style={{ marginTop: '120px'}} />
            <ClientOnly>
              <MintCountDownTimer className='bg-transparent flex justify-center' mintDate={mintDate} singleLine={false} />
            </ClientOnly>
            <Button href="https://www.alphashares.io/mint/alpha-pack-pups" className="mx-auto mt-8" variant="secondary">View Mint Page</Button>
          </div>
          {heroText && (
          <div className='text-center md:container mx-auto bg-dark-550 p-8 pt-2 mt-8 rounded-xl' style={{
            position: 'absolute',
            left: '50%',
            bottom: '-200px',
            transform: 'translate(-50%, -50%)',
            margin: '0 auto'
          }}>
            <RichText
              renderers={renderers}
              content={heroText?.raw} 
            />
          </div>
          )}
        </div>  
      )}
      
      <ClientOnly>
      {videos && (
      <>
      <div style={{ marginTop: '140px' }} className='mx-8 max-w-[960px] mx-auto hidden lg:block'>
        
        {videos && (
          <VideoCarousel
            videoIds={videos.youtubeVideoId}
          />
        )}        
      </div>
      <div className='mx-8 mt-8 lg:hidden'>
        <ClientOnly>
        {videos && (
          <VideoCarousel            
            options={smallScreenVideoOptions}
            videoIds={videos.youtubeVideoId}
          />
        )}
        </ClientOnly>
      </div>
      </>
      )}
      </ClientOnly>

      <CTASectionGeneric ctaSection={ctaSection as CtaSection} />
      
      {textImageRow && (
        <div className='mt-4'>
          {textImageRow?.map((section, index) => {
            const imageSide = section?.imageSide;

            return (
            <React.Fragment key={index}>
              {imageSide === 'right' && (              
                <div className={`py-12 ${(index % 2 === 0) ? 'bg-dark-400 bg-opacity-80' : ''}`}>
                  <div className="md:container mx-auto grid grid-cols-1 lg:flex items-center gap-12">
                    <div className='hidden lg:block w-full lg:w-1/2 px-8'>
                      <RichText
                        renderers={renderers}
                        content={section?.text?.raw}                  
                      />
                    </div>
                    {section?.image?.url && (
                    <div className='relative aspect-square w-full lg:w-1/2 px-8'>
                      <Image
                        src={section?.image?.url}
                        alt={'Image'}
                        width={500}
                        height={500}
                        className="h-full w-full rounded-xl"          
                      />
                    </div>
                    )}
                    <div className='lg:hidden w-full lg:w-1/2 px-8'>
                      <RichText
                        renderers={renderers}
                        content={section?.text?.raw}                  
                      />
                    </div>
                  </div>   
                </div>       
              )}
              {imageSide === 'left' && (              
                <div className={`py-12 ${(index % 2 === 0) ? 'bg-dark-400 bg-opacity-80' : ''}`}>
                  <div className="md:container mx-auto grid grid-cols-1 lg:flex items-center gap-12">
                  {section?.image?.url && (                  
                  <div className='relative aspect-square w-full lg:w-1/2 px-8'>
                    <Image
                      src={section?.image?.url}
                      alt={'Image'}
                      width={500}
                      height={500}
                      className="h-full w-full rounded-xl"          
                    />
                  </div>
                  )}
                  <div className='w-full lg:w-1/2 px-8'>
                    <RichText
                      renderers={renderers}
                      content={section?.text?.raw}                  
                    />
                  </div>
                </div>   
                </div>       
              )}
            </React.Fragment>
          )})}
        </div>
      )}

      <CTASectionGeneric ctaSection={ctaSectionBottom as CtaSection} />
      
      
      
    </>
  )
}