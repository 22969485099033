/* eslint-disable react/no-array-index-key */
import React from 'react'
import YouTube from "react-youtube";
import ImageGallery from 'react-image-gallery';

import "react-image-gallery/styles/css/image-gallery.css";

const o = {
  height: '585',
  width: '960',
  playerVars: {
    autoplay: 0,
    controls: 1,
  },
};

interface IAssetCarousel {
  videoIds: string[];
  options?: typeof o;
}


export const VideoCarousel:React.FC<IAssetCarousel> = ({
  videoIds,
  options = o
}) => {
  const onVideoReady = (event: any) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }

  const items = videoIds.map((item) => ({
    original: `https://img.youtube.com/vi/${item}/0.jpg`,
    thumbnail: `https://img.youtube.com/vi/${item}/0.jpg`,
    embedUrl: `https://www.youtube.com/embed/${item}`,
    renderItem: (item: any) => (
        <YouTube className='mx-auto' videoId={item.embedUrl.split('/').pop()} opts={options} onReady={onVideoReady} id={`video-${item.embedUrl.split('/').pop()}`} />
    )
  }))

  return (
    <ImageGallery items={items} />
  )
 
}