/* This example requires Tailwind CSS v2.0+ */
import React, { useState } from 'react'
import classNames from 'classnames'
import { Switch } from '@headlessui/react'

type ToggleSwitchProps = {
  onChange: React.Dispatch<React.SetStateAction<boolean>>
  enabled: boolean
}

export const ToggleSwitch:React.FC<ToggleSwitchProps> = ({ onChange, enabled }) => {
  return (
    <Switch
      checked={enabled}
      onChange={onChange}
      className={classNames(
        enabled ? 'bg-dark-500' : 'bg-dark-500',
        'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none'
      )}
    >
      <span className="sr-only">Use setting</span>
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none inline-block h-5 w-5 rounded-full bg-accent-500 shadow transform ring-0 transition ease-in-out duration-200'
        )}
      />
    </Switch>
  )
}
