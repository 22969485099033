import React from 'react'

import { useQueryClient } from '@tanstack/react-query';

import {
  Accordion,
  Typography,
} from '../../';

import {
  HomePageQuery,
  type FaqSection
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

export const FAQSection:React.FC = () => {
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage']);
  const page = queryData?.page;
  const fAQSection = page?.fAQSection;
  const faqs = fAQSection as FaqSection

  return (
    <div className="text-center mt-6 mx-auto max-w-screen-lg px-4 lg:px-0">
      <Typography variant="h2" as="h3" className="py-8">
        {faqs.heading}
      </Typography>
      <div>
        {faqs.questions.map((faq) => (
          <Accordion
            key={faq.id}
            title={faq.question}
            panelClasses="text-left"
            content={faq.answer}
          />
        ))}
      </div>
    </div>
  )
}
