/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import classNames from 'classnames';
import { Disclosure, Transition } from '@headlessui/react';
import { ChevronUpIcon, ChevronDownIcon } from '@heroicons/react/outline';

import { type Maybe } from '@alpha-shares/graphql-codegen/generated/graphCMS';

interface IAccordionProps {
  title?: string | JSX.Element | undefined | Maybe<string>;
  content?: string | JSX.Element | undefined | Maybe<string>;
  panelClasses?: string;
  titleClasses?: string;
  panelPadding?: string;
  defaultOpen?: boolean;
}
export const Accordion: React.FC<IAccordionProps> = ({
  title,
  content,
  panelClasses,
  panelPadding = 'p-4 lg:p-6',
  titleClasses,
  defaultOpen = false
}) => {
  const mergedPanelClasses = classNames(
    'bg-dark-400 overflow-hidden bg-opacity-40',
    panelClasses,
    panelPadding
  );
  const mergedTitleClasses = classNames(
    'bg-dark-300 bg-opacity-60 p-4 w-full text-left flex justify-between font-semibold',
    titleClasses
  );

  return (
    <div className="w-full text-white mt-0.5 overflow-hidden">
      <Disclosure
        defaultOpen={defaultOpen}
      >
        {({ open }) => (
          <>
            <Disclosure.Button className={mergedTitleClasses}>
              <span className="">{title}</span>
              <ChevronDownIcon className={`w-7 h-7 ${open && 'rotate-180 transition-all'}`} />
            </Disclosure.Button>
            <div className="overflow-hidden">
              <Transition
                enter="transition-all duration-150 transform"
                enterFrom="opacity-0 -translate-y-1/2"
                enterTo="opacity-100 translate-y-0"
                leave="transition-all duration-250 transform"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 -translate-y-1/2"
              >
                <Disclosure.Panel static className={mergedPanelClasses}>
                  {content}
                </Disclosure.Panel>
              </Transition>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
};
