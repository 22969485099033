export const LANDING_NAV = {
  LANDING_NAV_MENU_ITEMS: [
    {
      text: 'Home',
      href: '/',
    },
    {
      text: 'Discord',
      href: '#',
    },
    {
      text: 'Telegram',
      href: '#',
    },
    {
      text: 'Twitter',
      href: '#',
    },
    {
      text: 'Docs',
      href: '#',
    },
  ],
};
