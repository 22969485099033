/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useQueryClient } from '@tanstack/react-query'
import {
  ImageElement,
  MultiCarousel,
} from '../../../';

import {
  GetMintingProjectBySlugQuery,
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

interface IAssetCarousel {
  title?: string;
  titleClass?: string;
  containerClasses?: string;
  imageClasses?: string;
  HeadingLink?: JSX.Element;
  showCollectionTitle?: boolean;
  slug: string;
}

const config = {
  sm: 1,
  md: 1,
  lg: 1,
  xl: 2,
  xxl: 3
}

const responsive = {
  desktopxlg: {
    breakpoint: { max: 6000, min: 2500 },
    items: config.xxl,
    slidesToSlide: 1, // optional, default to 1.
  },
  desktoplg: {
    breakpoint: { max: 2499, min: 1919 },
    items: config.xl,
    slidesToSlide: 1, // optional, default to 1.
  },
  desktop: {
    breakpoint: { max: 1918, min: 1024 },
    items: config.lg,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: config.md,
    slidesToSlide: 1 // optional, default to 1.
  },
  mobile: {
    breakpoint: {
      max: 464,
      min: 0
    },
    items: config.sm
  },
};

export const ImageCarousel:React.FC<IAssetCarousel> = ({
  title = 'More From This Collection',
  titleClass = '',
  containerClasses,
  imageClasses,
  HeadingLink,
  showCollectionTitle = true,
  slug
}) => {
  const queryClient = useQueryClient()
  
  const queryData = queryClient.getQueryData<GetMintingProjectBySlugQuery>(['getMintingProjectBySlug', { slug }])
  const mintingProject = queryData?.mintingProject

  const carouselImages = mintingProject?.collectionSampleImages

  return (
    <>
      {/* @ts-ignore */}
      <MultiCarousel
        carouselTitle={null}
        containerClasses={containerClasses}
        HeadingLink={HeadingLink}
        titleClass={titleClass}
      >

        {carouselImages && carouselImages.map((item) => (
          <div className={`${imageClasses} mx-3 `} key={item.url}>
            {item?.url ? (
              <ImageElement
                src={item?.url}
                alt={item?.url}
                className="mx-auto flex justify-center"
                height={600}
                width={600}
                rounded
              />
            ) : (
              <ImageElement
                src="loading"
                alt="Image"
              />
            )}
            <div className="absolute h-full w-full top-0 cursor-grab" />
          </div>
        ))}
      </MultiCarousel>
    </>
  )
}
