import React from 'react'

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'

export const MintItemLoadingState:React.FC = () => (
  <div className='relative shadow-xl bg-dark-300 p-3 rounded-xl border border-transparent hover:border-primary-500'>
    <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>
      <Skeleton count={1} style={{aspectRatio: '1 / 1'}} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-t-xl' />
      <Skeleton count={1} style={{aspectRatio: '5 / 4'}} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-b-xl' />          
    </SkeletonTheme>
  </div>
)