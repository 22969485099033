import React, { useEffect, useState } from 'react';

// @ts-ignore
export const ClientOnly = ({ children }) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => {
    setMounted(true);
  }, []);
  if (!mounted) return null;

  return <>{children}</>;
};
