import React from 'react';
import classNames from '@alpha-shares/theme/utils/cn';
import Link from 'next/link';

export type ButtonType = 'button' | 'submit' | 'reset'
export type ButtonVariant = 'primary' | 'secondary' | 'tertiary' | 'menu' | 'accent' | 'grid' | 'cancel' | 'submit' | 'success' | 'link';


export interface ButtonProps {
  variant?: ButtonVariant;
  size?: string;
  classes?: string;
  children: React.ReactNode;
  buttonType?: ButtonType;
  leftIcon?: React.ReactNode;
  [x: string]: any;
  title?: string;
  truncate?: boolean;
}

const commonClasses = [
  'leading-loose',
  'outline-none',
  'ease-linear',
  'transition-all duration-150',
  'focus:outline-none',
  'hover:shadow-lg',
  'items-center',
  'justify-center',
  'rounded-xl',
  'text-center',
  'border'
].join(' ');

const variantClasses = {
  primary: [
    'text-black',
    'bg-primary-200',
    'hover:bg-opacity-70',
    'border-transparent',
  ].join(' '),

  secondary: [
    'flex justify-center items-center',
    'text-white',
    'bg-dark-100',
    'hover:bg-dark-300',
    'active:bg-dark-300',
    'border-transparent'
  ].join(' '),

  tertiary: [
    'text-primary-500',
    'bg-transparent',
    'border',
    'border-primary-500',
    'hover:bg-primary-500 hover:text-black hover:bg-opacity-70',
    'active:bg-primary-600',
  ].join(' '),

  menu: [
    'text-white',
    'bg-transparent',
    'border',
    'border-transparent',
    'hover:bg-primary-200 hover:text-black hover:bg-opacity-70',
    'active:bg-primary-600',
    'rounded-md',
    '!px-3'
  ],

  success: [
    'text-white',
    'bg-secondary',
    'hover:bg-secondary-600',
    'border-transparent'
  ].join(' '),

  link: [
    'text-white',
    'border-none'
  ].join(' '),

  cancel: `text-white bg-rose-500 border-none shadow-none
            hover:shadow-none hover:bg-rose-400
            active:bg-rose-800`,

  accent: `text-accent-900 bg-accent-500   border-none shadow-none
            hover:shadow-none hover:bg-accent-400
            active:bg-accent-800`,

  submit: `text-white bg-primary-500 border border-none 
            hover:bg-primary-400 hover:border-primary-500
            active:bg-primary-600`,

  grid: 'disabled:opacity-50 flex-1 font-medium p-4 text-sm hover:bg-gray-300 hover:bg-opacity-50 transition-colors',
};

const sizeClasses = {
  xsmall: 'text-xs px-3 py-1',
  small: 'text-sm px-2 py-2',
  smedium: 'text-sm px-4 py-2 font-semibold',
  medium: 'text-base font-semibold px-6 py-2',
  large: 'text-lg font-semibold px-12 py-4',
  xlarge: 'text-xl font-semibold px-6 py-3',
};

const defaultSize = 'medium';

const getSizeClasses = (size: keyof typeof sizeClasses) => sizeClasses[size] || sizeClasses[defaultSize];
const getVariantClasses = (variant: ButtonVariant) => variantClasses[variant] || variantClasses.primary;

export const Button: React.FC<ButtonProps> = ({
  children,
  className,
  variant = 'primary',
  size = 'medium',
  leftIcon,
  rounded,
  title,
  truncate,
  ...restProps
}) => {
  const { target, href, buttonType = 'button' } = restProps;
  const mergedClasses = classNames(
    variant !== 'grid' ? commonClasses : '',
    getVariantClasses(variant),
    // @ts-ignore TODO: fix this
    variant !== 'grid' ? getSizeClasses(size) : '',
    'disabled:bg-opacity-20 disabled:text-gray-700',
    truncate ? 'truncate' : 'flex',
    className
  );

  let buttonContent = children;

  if (leftIcon) {
    buttonContent = (
      <>
        {leftIcon || ''}
        <span>{children}</span>
      </>
    );
  }

  let ButtonHTML = (
    <button
      type={buttonType}
      {...restProps}
      className={mergedClasses}
      title={title}
    >
      {buttonContent}
    </button>
  );

  if (href && target) {
    ButtonHTML = (
      <a href={href} target={target} title={title}>
        <button className={mergedClasses}>{buttonContent}</button>
      </a>
    );
  }
  if (href && !target) {
    ButtonHTML = (
      <Link legacyBehavior href={href} {...restProps}>
        <a title={title} className={mergedClasses}>
          {buttonContent}
        </a>
      </Link>
    );
  }
  return ButtonHTML;
};

Button.displayName = 'Button';
