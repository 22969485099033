import * as React from 'react';
import { Button } from '../../Atoms/Button';
import classNames from 'classnames';
import { ConnectButton } from '@rainbow-me/rainbowkit';
import { LogoutIcon, LoginIcon, UserIcon } from '@heroicons/react/solid';

type AccountProps = {
  className?: string;
};

const ConnectButtonRainbowkit: React.FC<AccountProps> = ({ className }) => {
  return (
    <div className={className}>
      <ConnectButton.Custom>
        {({
          account,
          chain,
          openAccountModal,
          openConnectModal,
          authenticationStatus,
          mounted,
        }) => {
        // Note: If your app doesn't use authentication, you
        // can remove all 'authenticationStatus' checks
          const ready = mounted && authenticationStatus !== 'loading';
          const connected = ready
          && account
          && chain
          && (!authenticationStatus
            || authenticationStatus === 'authenticated');

          return (
            <>
              {(() => {
                if (!connected) {
                  return (
                    <Button
                      className={classNames('w-full min-w-[130px]')}
                      size="small"
                      variant="primary"
                      onClick={openConnectModal}
                    >
                      <LoginIcon className="w-5 h-4 mr-1" />
                      &nbsp;Connect
                    </Button>
                  );
                }

                return (
                  <div
                    className="flex gap-0 p-1 rounded-full"
                  >
                    <Button
                      size="small"
                      variant="primary"
                      className={`${(className || 'min-w-[140px]')}`}
                      onClick={openAccountModal}
                    >
                      Disconnect Wallet
                    </Button>
                  </div>

                );
              })()}
            </>
          );
        }}
      </ConnectButton.Custom>
    </div>
  );
};

export { ConnectButtonRainbowkit };
export default ConnectButtonRainbowkit;
