import { ChainId } from '@alpha-shares/web3-sdk';

export const BLOCKCHAIN_CONFIG = {
  NULL_ADDRESS: '0x0000000000000000000000000000000000000000',
  PUPS_PRESALE: {
    [ChainId.BSC]: '0xA30D9D53e1eF95f943FE256Ac48B3079F6AbbCA7',
    [ChainId.ETHEREUM]: '0xe2fd86746c4DC9AD511Cdd97B49a6954e0Eb749e',
    [ChainId.POLYGON]: '0x273afe32Fadb6CDD9467B91F5CF0BbeBF84A696b'
  },
  USDT: {
    [ChainId.BSC]: '0x55d398326f99059fF775485246999027B3197955',
    [ChainId.ETHEREUM]: '0xdAC17F958D2ee523a2206206994597C13D831ec7',
    [ChainId.POLYGON]: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F'
  },
  ACCOUNT_HEADER: {
    [ChainId.AVALANCHE]: {
      MINTING: true
    }
  },
  MAIN_TOKEN_ADDRESS: {
    [ChainId.AVALANCHE]: '0x325a98F258a5732c7b06555603F6aF5BC1C17F0a',
  },
  MAIN_TOKEN_V2: {
    [ChainId.AVALANCHE]: '0x9c3254bb4F7f6A05A4Aaf2Cadce592C848d043c1',
  },
  SHARE_CREATOR_ADDRESS: {
    [ChainId.AVALANCHE]: '0xFA72412A59beE38CdD7AB3b75687E9D868fd996B',
  },
  AMC_ADDRESS: '0xF3549D8B646f3c93A10a8794E8320Cf2280D3BE6',
  WBTC_ADDRESS: '0x1BFD67037B42Cf73acF2047067bd4F2C47D9BfD6',
  AMCR_DISTRIBUTOR: '0x21F89cb6E641182Ab82B35013fc510Fc0bb3A70c',
  MASS_LP_STAKING_ADDRESS: '0x90Ff6B94D32F13a2bFa998c0e451f7803415c7bD',
  ALPHA_NFT_ADDRESS: {
    [ChainId.AVALANCHE]: '0x375c29c56b1b92acb2eb7a1504b1315a4b81d3b4',
  },
  ALPHA_SHARE_STAKING_ADDRESS: {
    [ChainId.AVALANCHE]: '0x837C334421aAF2B00Dc54D4e19ACfb94B17d670e',
  },
  ALPHA_SHARE_STAKING_DIVIDENDS_MODEL_ADDRESS: {
    [ChainId.AVALANCHE]: '0x4Aa60d02065e638559eE0106dc6866559a9b347d',
  },
  ALPHA_TOKEN_SINGLE_STAKING_ADDRESS: {
    [ChainId.AVALANCHE]: '0x356ea99F33E679a446903670F5E9f57bDAb92Fe0',
  },
  ALPHA_TOKEN_SINGLE_STAKING_ADDRESS_V2: {
    [ChainId.AVALANCHE]: '0x59407aF2257e2f22Ff17bFa9C8f519381eA7Ad61',
  },
  ALPHA_USDC_STAKING: {
    [ChainId.AVALANCHE]: '0x5924E237Cbd5dc27c9076B08d7d6a1AD07C2e256',
  },
  ALPHA_USDC_PAIR: {
    [ChainId.AVALANCHE]: '0xE7C9065eEF88462C2E1D9E3208b7D300E6B81C39'
  },
  MASS_TOKEN: {
    [ChainId.AVALANCHE]: '0x63Dd6F87e8BD5008878E51DaF473DFB3105C7537',
  },
  TREASURY_SPLITTER: {
    [ChainId.AVALANCHE]: '0x635698A16875d6c4327734b9a27cD3cbbd545986'
  },
  OWNER_SPLITTER: {
    [ChainId.AVALANCHE]: '0x38cB510e9E3E7CfE0576fee3B09Ad11Ac56b9593'
  },
  ARTIST_SPLITTER: {
    [ChainId.AVALANCHE]: '0x6553462dbCFe3676053A5c75e7EAEBd242743428'
  },
  SHARES_PAYMENT_METHODS: {
    ADDRESSES: {
      USDC: {
        [ChainId.AVALANCHE]: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'
      }
    },
    AVAX: {
      MAIN_TOKEN_PRICE: 100,
      BONDS: [{
        id: 1,
        name: 'USDC',
        price: '100000000',
        displayPrice: 100,
        address: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E'
      }]
    }
  },
  BLACKLISTED_WALLETS: [
    '0x5033fc2e041112124a568f102bd20cf3f7d3e5b6'
  ]
};
