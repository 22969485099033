import React from 'react'
import { useQueryClient } from '@tanstack/react-query';

import { Typography } from '../../';

import {
  type HomePageQuery,
  type InfoCardsSection as InfoCardsSectionType
} from '@alpha-shares/graphql-codegen/generated/graphCMS';

export const InfoCardsSection:React.FC = () => {
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage']);
  const page = queryData?.page;
  const infoCardsSection = page?.infoCardsSection;
  
  const { heading, infoCard } = infoCardsSection as InfoCardsSectionType;

  return (
    <div className="text-center mt-24 max-w-screen-xl mx-auto px-4 lg:px-0">
      <Typography variant="h2" as="div">
        {heading}
      </Typography>
      <div className="mt-6 grid lg:grid-cols-2 gap-6">
        {infoCard.map((info) => (
          <div key={info.id} className="bg-dark-400 p-10 text-left rounded-xl">
            <Typography variant="h5" className="pb-4">
              {info.cardHeading}
            </Typography>
            <Typography variant="p" as="div" className="text-gray-400 font-semibold text-sm">
              {info.cardText}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  )
}
