import { GraphQLClient } from 'graphql-request';

const credentialsAppSync = {
  'x-api-key': process.env.appSyncApiKey,
  region: process.env.appSyncRegion
}

export const appSyncClient = new GraphQLClient(
  process.env.appSyncEndpoint as string,
  {
    // @ts-ignore
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...credentialsAppSync
    },
  },
);

