
import Link from 'next/link'
import {
  Text,
  Flex,
  Box,
  FormatCryptoCurrency,
} from '../../primitives'


import { useContext, } from 'react'


import { ChainContext } from '@alpha-shares/react-components/Contexts/ChainContextProvider'

import Img from '../../primitives/Img'
import useTopSellingCollections from '@alpha-shares/res-utils/hooks/useTopSellingCollections'
import ReactMarkdown from 'react-markdown'

import { styled } from '@alpha-shares/theme/stitches.config'

import ChainToggle from '../../common/ChainToggle'
import optimizeImage from '@alpha-shares/res-utils/optimizeImage'
import { MarkdownLink } from '../../primitives/MarkdownLink'


import { Typography, Button } from '@alpha-shares/react-components'

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'


const StyledImage = styled('img', {})

type TrendingCollectionsProps = {
  
}


const LoadingState = () => (
  <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>
    <div className="opacity-[30] animate animate-pulse rounded-xl transition transition-all hover:border-primary-500 border border-transparent">
      <Skeleton count={1} style={{aspectRatio: '3 / 1'}} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-t-xl' />
      <Skeleton count={1} height={35} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50' />          
      <Skeleton count={1} height={180} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-b-xl' />          
    </div>
  </SkeletonTheme>
  )

export const TrendingCollections:React.FC<TrendingCollectionsProps> = () => {

  const { chain } = useContext(ChainContext)
  let collectionsData: any;

  const { data: topSellingCollectionsData, isFetching, isLoading } = useTopSellingCollections(
    {
      period: '24h',
      includeRecentSales: true,
      limit: 7,
      fillType: 'sale',
    },
    chain.id   
  )

  if (topSellingCollectionsData && topSellingCollectionsData.collections) {
    collectionsData = topSellingCollectionsData.collections
  }
  
  return (
    <>
    <div className='flex items-center justify-between'>          
          <Typography variant="h4">{chain.name} Trending Collections</Typography>
          <ChainToggle />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-6'>
          {(!collectionsData || isFetching || isLoading) && ([...new Array(6).fill('x')].map((item, index) => (
            <LoadingState key={index} />
          )))}
          {(!isFetching && !isLoading && collectionsData) && collectionsData
              .slice(1, 7)
              .map((collection: any) => {
                return (
                  <Link
                    key={collection.id}
                    href={`/${chain.routePrefix}/collection/${collection.id}`}
                    style={{ display: 'grid' }}
                    className='rounded-xl transition transition-all hover:border-primary-500 border border-transparent'
                  >
                    <Flex
                      direction="column"
                      css={{
                        flex: 1,
                        width: '100%',
                        borderRadius: 12,
                        cursor: 'pointer',
                        height: '100%',
                        background: '$neutralBgSubtle',
                        $$shadowColor: '$colors$panelShadow',
                        boxShadow: '0 0px 12px 0px $$shadowColor',

                        overflow: 'hidden',
                        position: 'relative',
                        p: '$3',
                        '&:hover > div > div> img:nth-child(1)': {
                          transform: 'scale(1.075)',
                        },
                      }}
                    >
                      <Flex
                        direction="column"
                        css={{
                          zIndex: 2,
                          position: 'relative',
                          flex: 1,
                          width: '100%',
                        }}
                      >
                        <Box
                          css={{
                            position: 'relative',
                            overflow: 'hidden',
                            borderRadius: 8,
                          }}
                        >
                          {collection?.banner?.length ||
                          collection.recentSales?.[0]?.token?.image?.length ? (
                            <img
                              loading="lazy"
                              src={optimizeImage(
                                collection?.banner ||
                                  collection.recentSales?.[0]?.token?.image ||
                                  collection.recentSales?.[0]?.collection
                                    ?.image,
                                800
                              )}
                              style={{
                                transition: 'transform 300ms ease-in-out',
                                width: '100%',
                                borderRadius: 8,
                                height: 250,
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <Box
                              css={{
                                width: '100%',
                                borderRadius: 8,
                                height: 250,
                                background: '$gray3',
                              }}
                            />
                          )}
                          <Img
                            src={
                              optimizeImage(collection?.image, 72 * 2) as string
                            }
                            alt={collection?.name as string}
                            width={72}
                            height={72}
                            css={{
                              width: 72,
                              height: 72,
                              border: '2px solid rgba(255,255,255,0.6)',
                              position: 'absolute',
                              bottom: '$3',
                              left: '$3',
                              borderRadius: 8,
                            }}
                          />
                        </Box>
                        <Flex
                          css={{ my: '$4', mb: '$2' }}
                          justify="between"
                          align="center"
                        >
                          <Text style="h5" as="h5" ellipsify css={{ flex: 1 }}>
                            {collection?.name}
                          </Text>
                        </Flex>

                        <Box
                          css={{
                            maxWidth: 720,
                            lineHeight: 1.5,
                            fontSize: 16,
                            flex: 1,
                            fontWeight: 400,
                            display: '-webkit-box',
                            color: '$gray12',
                            fontFamily: '$body',
                            WebkitLineClamp: 3,
                            WebkitBoxOrient: 'vertical',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            '& a': {
                              fontWeight: 500,
                              cursor: 'pointer',
                              textDecoration: 'underline',
                            },
                          }}
                        >
                          <ReactMarkdown
                            children={collection?.description || ''}
                            components={{
                              a: MarkdownLink,
                              p: Text as any,
                            }}
                          />
                        </Box>

                        <Flex css={{ mt: '$4' }}>
                          <Box css={{ mr: '$5' }}>
                            <Text
                              style="subtitle2"
                              color="subtle"
                              as="p"
                              css={{ mb: 2 }}
                            >
                              FLOOR
                            </Text>
                            <FormatCryptoCurrency
                              amount={
                                collection?.floorAsk?.price?.amount?.native ?? 0
                              }
                              textStyle={'h6'}
                              logoHeight={12}
                              address={
                                collection?.floorAsk?.price?.currency?.contract
                              }
                            />
                          </Box>

                          <Box css={{ mr: '$4' }}>
                            <Text style="subtitle2" color="subtle" as="p">
                              24H SALES
                            </Text>
                            <Text style="h6" as="h4" css={{ mt: 2 }}>
                              {collection.count?.toLocaleString()}
                            </Text>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Link>
                )
              })}
        </div>
        <Box css={{ my: '$5' }}>
          <Link href={`/${chain.routePrefix}/collections/trending`}>
            <Button>See More</Button>
          </Link>
        </Box>
        </>
  )
}