/* eslint-disable react/no-array-index-key */
import React from 'react'

import { graphCMSClient } from '@alpha-shares/fetchers'

import {
  MultiCarousel,  
} from '../../../';

import {  
  useFeaturedCreatorGroupQuery,
  type CollectionCreator
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

import { CreatorCard } from '../../CreatorsView/CreatorCard'
import { CreatorCardLoadingState } from '../../CreatorsView/CreatorCardLoadingState'


interface IAssetCarousel {
  title?: string;
  titleClass?: string;
  HeadingLink?: JSX.Element
  numberPerChain?: number;
  showGradientbg?: boolean;
  config?: {
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
  }
}


export const FeaturedCreatorsCarousel:React.FC<IAssetCarousel> = ({
  title = 'Featured Creators',
  titleClass = '', 
  HeadingLink,
  config = {
    sm: 1,
    md: 2,
    lg: 5,
    xl: 6
  },
  showGradientbg = false
}) => {

  const { data, isFetched } = useFeaturedCreatorGroupQuery(graphCMSClient, { id: 'clrnt8dugvvn10ait5xxwtrn1' })
  
  
  const responsive = [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 6,
        slidesToScroll: 1,          
      }
    },
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,
        centerPadding: '40px'          
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    }      
  ]
 
  return (
    <div className='relative pt-6 mt-0 md:mx-0'>
      {showGradientbg && (
      <div className="absolute w-full h-[580px] -mt-10 pointer-events-none opacity-50" style={
        {
          backgroundImage: 'url(https://media.graphassets.com/S26EpP1URReV49K6sW5e)', 
          backgroundSize: 'auto', 
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',                    
        }}></div>
        )}
      <MultiCarousel
        HeadingLink={HeadingLink}
        carouselTitle={title}
        autoPlay
        centerMode={false}
        containerClasses='mt-6'
        titleClass={`mx-auto text-center text-white text-3xl font-bold mb-8 ${titleClass}`}
        responsiveConfig={responsive}
      >
        {(!isFetched) && (
        ([...new Array(6).fill('x')].map((item, index) => (          
              <CreatorCardLoadingState key={index} />
          )))
        )}  
        {isFetched && data?.featuredCreatorGroup?.collectionCreators.map((creator, idx) => {
          return (
            <div key={idx} className='px-3'>
              <CreatorCard creator={creator as CollectionCreator} /> 
            </div>
          )}
        )}
      </MultiCarousel>
    </div>
  )
}
