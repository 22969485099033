import { ChainId, NetworkTradableTokens } from '@alpha-shares/web3-sdk'

import { Currency } from '@reservoir0x/reservoir-kit-ui'
import { reservoirChains } from '@reservoir0x/reservoir-sdk'
import { zeroAddress } from 'viem'
import {
  arbitrum,
  mainnet,
  polygon,
  optimism,
  Chain,
  bsc,
  avalanche,
  polygonZkEvm,
  zkSync,
  linea,
  zora,
  base,
  arbitrumNova,
  scroll,
} from 'wagmi/chains'
import usdcContracts from './usdcContracts'

//CONFIGURABLE: The default export controls the supported chains for the marketplace. Removing
// or adding chains will result in adding more or less chains to the marketplace.
// They are an extension of the wagmi chain objects

export type ReservoirChain = Chain & {
  lightIconUrl: string
  darkIconUrl: string
  reservoirBaseUrl: string
  proxyApi?: string
  routePrefix: string
  apiKey?: string
  coingeckoId?: string
  collectionSetId?: string
  community?: string
  wssUrl?: string
  listingCurrencies?: Currency[]
  oracleBidsEnabled?: boolean
  checkPollingInterval?: number
}

const nativeCurrencyBase = {
  contract: zeroAddress,
  symbol: 'ETH',
  decimals: 18,
  coinGeckoId: 'ethereum',
}

const usdcCurrencyBase = {
  contract: '',
  symbol: 'USDC',
  decimals: 6,
  coinGeckoId: 'usd-coin',
}

// TODO:: configure chain currencies here

export const DefaultChain: ReservoirChain = {
  ...mainnet,
  // Any url to display the logo of the chain in light mode
  lightIconUrl: 'https://media.graphassets.com/E6TwUoyvRvCLqVmcDhpb',
  // Any url to display the logo of the chain in dark mode
  darkIconUrl: 'https://media.graphassets.com/5Lm4DR7SIG6ja3VYUiHw',
  // The base url of the reservoir api, this is used in the app when
  // directly interacting with the reservoir indexer servers (in the api proxy for example)
  // or when prefetching server side rendered data
  reservoirBaseUrl: reservoirChains.mainnet.baseApiUrl,
  // Used on the client side portions of the marketplace that need an api key added
  // Prevents the api key from being leaked in the clientside requests
  // If you'd like to disable proxying you can just change the proxyApi to the reservoirBaseUrl
  // Doing so will omit the api key unless further changes are made
  proxyApi: '/api/alpha-shares/ethereum',
  // A prefix used in the asset specific routes on the app (tokens/collections)
  routePrefix: 'ethereum',
  // Coingecko id, used to convert the chain's native prices to usd. Can be found here:
  // https://www.coingecko.com/en/api/documentation#operations-coins-get_coins_list
  coingeckoId: 'ethereum',
  collectionSetId: process.env.NEXT_PUBLIC_ETH_COLLECTION_SET_ID,
  community: process.env.NEXT_PUBLIC_ETH_COMMUNITY,
  wssUrl: 'wss://ws.reservoir.tools',
  listingCurrencies: NetworkTradableTokens[ChainId.ETHEREUM],
  // listingCurrencies: [
  //   nativeCurrencyBase,
  //   {
  //     ...usdcCurrencyBase,
  //     contract: usdcContracts[mainnet.id],
  //   },    
  // ],
  oracleBidsEnabled: true,
  checkPollingInterval: reservoirChains.mainnet.checkPollingInterval,
}

export default [
  DefaultChain,
  {
    ...polygon,
    lightIconUrl: 'https://media.graphassets.com/cLUnf1RcSB7g2r4vdUQV',
    darkIconUrl: 'https://media.graphassets.com/ADEXZW27Qzq9GfWV7EK3',
    reservoirBaseUrl: reservoirChains.polygon.baseApiUrl,
    proxyApi: '/api/alpha-shares/polygon',
    routePrefix: 'polygon',
    coingeckoId: 'matic-network',
    collectionSetId: process.env.NEXT_PUBLIC_POLYGON_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_POLYGON_COMMUNITY,
    wssUrl: 'wss://ws-polygon.reservoir.tools',
    listingCurrencies: NetworkTradableTokens[ChainId.POLYGON],
    oracleBidsEnabled: true,
    checkPollingInterval: reservoirChains.polygon.checkPollingInterval,
  },
  {
    ...arbitrum,
    name: 'Arbitrum',
    lightIconUrl: 'https://media.graphassets.com/HgiYjpXCQceRQd4cmRNs',
    darkIconUrl: 'https://media.graphassets.com/rAu9pr0QyKoesT9GT3sg',
    reservoirBaseUrl: reservoirChains.arbitrum.baseApiUrl,
    proxyApi: '/api/alpha-shares/arbitrum',
    routePrefix: 'arbitrum',
    coingeckoId: 'arbitrum-iou',
    collectionSetId: process.env.NEXT_PUBLIC_ARBITRUM_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_ARBITRUM_COMMUNITY,
    wssUrl: 'wss://ws-arbitrum.reservoir.tools',
    listingCurrencies: NetworkTradableTokens[ChainId.ARBITRUM],
    checkPollingInterval: reservoirChains.arbitrum.checkPollingInterval,
  },
  {
    ...arbitrumNova,
    lightIconUrl: 'https://media.graphassets.com/xZeyXtuqRkaqgyd7LBR9',
    darkIconUrl: 'https://media.graphassets.com/SnBQHIMShSE6CUAChsVw',
    reservoirBaseUrl: reservoirChains.arbitrumNova.baseApiUrl,
    proxyApi: '/api/alpha-shares/arbitrum-nova',
    routePrefix: 'arbitrum-nova',
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_ARBITRUM_NOVA_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_ARBITRUM_NOVA_COMMUNITY,
    checkPollingInterval: reservoirChains.arbitrumNova.checkPollingInterval,
  },
  {
    ...optimism,
    name: 'Optimism',
    lightIconUrl: 'https://media.graphassets.com/9QH7BRKgQPeYdipFHrnn',
    darkIconUrl: 'https://media.graphassets.com/zk6lcdLzQcSmHc25VYWC',
    reservoirBaseUrl: reservoirChains.optimism.baseApiUrl,
    proxyApi: '/api/alpha-shares/optimism',
    routePrefix: 'optimism',
    coingeckoId: 'optimism',
    collectionSetId: process.env.NEXT_PUBLIC_OPTIMISM_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_OPTIMISM_COMMUNITY,
    wssUrl: 'wss://ws-optimism.reservoir.tools',
    listingCurrencies: NetworkTradableTokens[ChainId.OPTIMISM],
    checkPollingInterval: reservoirChains.optimism.checkPollingInterval,
  },
  {
    ...zora,
    name: 'Zora',
    lightIconUrl: 'https://media.graphassets.com/6UrKhaMMSSq8ylLdufmm',
    darkIconUrl: 'https://media.graphassets.com/nPR1BzayR06pYd2duLMq',
    reservoirBaseUrl: reservoirChains.zora.baseApiUrl,
    proxyApi: '/api/alpha-shares/zora',
    routePrefix: 'zora',
    coingeckoId: 'ethereum',
    checkPollingInterval: reservoirChains.zora.checkPollingInterval,
  },
  {
    ...bsc,
    lightIconUrl: 'https://media.graphassets.com/JS5ZZxsaQ56OvbIU7FvQ',
    darkIconUrl: 'https://media.graphassets.com/J85D8RsSAuTttALuJcs0',
    reservoirBaseUrl: reservoirChains.bsc.baseApiUrl,
    proxyApi: '/api/alpha-shares/bsc',
    routePrefix: 'bsc',
    coingeckoId: 'binancecoin',
    collectionSetId: process.env.NEXT_PUBLIC_BSC_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_BSC_COMMUNITY,
    wssUrl: 'wss://ws-bsc.reservoir.tools',
    listingCurrencies: NetworkTradableTokens[ChainId.BSC],
    checkPollingInterval: reservoirChains.bsc.checkPollingInterval,
  },
  {
    ...avalanche,
    lightIconUrl: 'https://media.graphassets.com/7tB9qwT1SCK0QxophpIa',
    darkIconUrl: 'https://media.graphassets.com/WhEDmrd8SpGPiFD7rGWD',
    reservoirBaseUrl: reservoirChains.avalanche.baseApiUrl,
    proxyApi: '/api/alpha-shares/avalanche',
    routePrefix: 'avalanche',
    coingeckoId: 'avalanche-2',
    collectionSetId: process.env.NEXT_PUBLIC_AVALANCHE_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_AVALANCHE_COMMUNITY,
    checkPollingInterval: reservoirChains.avalanche.checkPollingInterval,
    listingCurrencies: NetworkTradableTokens[ChainId.AVALANCHE],
  },
  {
    ...base,
    lightIconUrl: 'https://media.graphassets.com/QXimnLmrRbqv1ijqQ5Ee',
    darkIconUrl: 'https://media.graphassets.com/Rn50G13JQiaRVkhaSPrp',
    reservoirBaseUrl: reservoirChains.base.baseApiUrl,
    proxyApi: '/api/alpha-shares/base',
    routePrefix: 'base',
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_BASE_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_BASE_COMMUNITY,
    checkPollingInterval: reservoirChains.base.checkPollingInterval,
  },
  {
    ...linea,
    lightIconUrl: 'https://media.graphassets.com/jxzGRPdtSu98uz2eDyiY',
    darkIconUrl: 'https://media.graphassets.com/wKk2A3SwWDSADBObVQHV',
    reservoirBaseUrl: reservoirChains.linea.baseApiUrl,
    proxyApi: '/api/alpha-shares/linea',
    routePrefix: 'linea',
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_LINEA_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_LINEA_COMMUNITY,
    checkPollingInterval: reservoirChains.linea.checkPollingInterval,
  },
  {
    ...polygonZkEvm,
    lightIconUrl: 'https://media.graphassets.com/ue7OX39QUW7Apu9G6fAf',
    darkIconUrl: 'https://media.graphassets.com/Pq9kyuQbSkO15cUANiCx',
    reservoirBaseUrl: 'https://api-polygon-zkevm.reservoir.tools',
    proxyApi: '/api/alpha-shares/polygon-zkevm',

    routePrefix: 'polygon-zkevm',
    apiKey: process.env.RESERVOIR_API_KEY,
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_POLYGON_ZKEVM_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_POLYGON_ZKEVM_COMMUNITY,
    checkPollingInterval: reservoirChains.polygonZkEvm.checkPollingInterval,
  },
  {
    ...zkSync,
    name: 'zkSync',
    lightIconUrl: 'https://media.graphassets.com/EYtCMnySESoRG9t9EUQx',
    darkIconUrl: 'https://media.graphassets.com/WtWivJQ3Tmy6RZ8a5C0H',
    reservoirBaseUrl: 'https://api-zksync.reservoir.tools',
    proxyApi: '/api/alpha-shares/zksync',
    routePrefix: 'zksync',
    apiKey: process.env.RESERVOIR_API_KEY,
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_ZKSYNC_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_ZKSYNC_COMMUNITY,
    checkPollingInterval: reservoirChains.zkSync.checkPollingInterval,
  },
  {
    ...scroll,
    name: 'Scroll',
    lightIconUrl: 'https://media.graphassets.com/FQhv5UOxRGuhd7MxW13C',
    darkIconUrl: 'https://media.graphassets.com/l9Th17ZaT8CWLEjxtHJF',
    reservoirBaseUrl: 'https://api-scroll.reservoir.tools',
    proxyApi: '/api/alpha-shares/scroll',
    routePrefix: 'scroll',
    apiKey: process.env.RESERVOIR_API_KEY,
    coingeckoId: 'ethereum',
    collectionSetId: process.env.NEXT_PUBLIC_SCROLL_COLLECTION_SET_ID,
    community: process.env.NEXT_PUBLIC_SCROLL_COMMUNITY,
    checkPollingInterval: reservoirChains.scroll.checkPollingInterval,
  },
] as ReservoirChain[]
