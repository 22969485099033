import React from 'react'
import { ChevronLeftIcon } from '@heroicons/react/outline';

import { Button } from '../../'

interface ICarouselControlsProps {
  handlePrevious: () => void;
  handleNext: () => void;
  page?: number;
  hasNextPage?: boolean;
}

export const CarouselControls:React.FC<ICarouselControlsProps> = ({
  handleNext,
  handlePrevious,
  page,
  hasNextPage = true
}) => {
  return (
    <div className="flex justify-center lg:justify-between w-full">
      <div className="p-2 bg-dark-50 rounded-r-full -ml-6">
        <Button disabled={page === 1} className={`!px-2 !rounded-full opacity-70 hover:opacity-100 ${page === 1 && 'cursor-not-allowed'}`} variant="tertiary" onClick={() => handlePrevious()}>
          <ChevronLeftIcon className="w-4 lg:w-6 h-4 lg:h-6" />
        </Button>
      </div>
      <div className="p-2 bg-dark-50 rounded-l-full -mr-6">
        <Button disabled={!hasNextPage} className={`!px-2 !rounded-full opacity-70 hover:opacity-100 ${!hasNextPage && 'cursor-not-allowed'}`} variant="tertiary" onClick={() => handleNext()}>
          <ChevronLeftIcon className="rotate-180 w-4 lg:w-6 h-4 lg:h-6" />
        </Button>
      </div>
    </div>
  )
}

type SlickControlsProps = {
  className?: string;
  style?: React.CSSProperties;
  onClick: () => void;
}

export const NextArrow:React.FC<SlickControlsProps> = (props) => {
  const { className, style, onClick } = props;
  return(
    <div style={{...style, position: 'absolute', top: '50%', right: '0', transform: 'translate(0, -50%)'}} className="opacity-0 transition transition-all p-2 bg-dark-50 md:opacity-70 md:hover:opacity-100 rounded-l-full">
      <Button className={`!px-2 !rounded-full opacity-70 hover:opacity-100`} variant="tertiary" onClick={() => onClick()}>
        <ChevronLeftIcon className="rotate-180 w-4 lg:w-6 h-4 lg:h-6" />
      </Button>
    </div>
  )
}

export const PrevArrow:React.FC<SlickControlsProps> = (props) => {
  const { className, style, onClick } = props;
  return(
    <div style={{...style, zIndex: 1000, position: 'absolute', top: '50%', left: 0, transform: 'translate(0, -50%)'}} className="opacity-0 transition transition-all p-2 bg-dark-50 md:opacity-70 md:hover:opacity-100 rounded-r-full">
      <Button className={`!px-2 !rounded-full opacity-70 hover:opacity-100`} variant="tertiary" onClick={() => onClick()}>
        <ChevronLeftIcon className="w-4 lg:w-6 h-4 lg:h-6" />
      </Button>
    </div>
  )
}
