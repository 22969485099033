import React, { FC } from 'react';
import classNames from 'classnames';

export interface CardProps {
  size?: string;
  variant?: string;
  children: React.ReactNode;
  className?: string;
}

const variantClasses = {
  noborderRadius: `relative px-4 py-10
   py-1`,

  borderRadius: `relative px-4 py-10 sm:rounded-3xl
  py-4`,
};

const sizeClasses = {
  small: 'sm:p-10',
  medium: 'sm:p-20',
  large: 'sm:p-40',
};

const defaultSize = 'small';
// @ts-ignore
const getVariantClasses = (variant) => variantClasses[variant] || variantClasses.noborderRadius;
// @ts-ignore
const getSizeClasses = (size) => sizeClasses[size] || sizeClasses[defaultSize];

export const Card: FC<CardProps> = ({
  children,
  className,
  variant,
  size,
  ...restProps
}) => {
  const mergedClasses = classNames(
    className,
    getVariantClasses(variant),
    getSizeClasses(size)
  );
  return (
    <div className={mergedClasses} {...restProps}>
      {children}
    </div>
  );
};
