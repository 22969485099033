import { ChainId } from '@alpha-shares/web3-sdk';

import {
  DiscordIcon, TwitterIcon, TelegramIcon
} from '@alpha-shares/react-components'

export const SOCIALS = {
  name: 'Socials',
  items: [
    {
      name: 'Discord',
      route: 'https://discord.gg/alphashares',
      target: '_blank',
      description: 'Join us on Discord!',
      icon: DiscordIcon
    },
    {
      name: 'Twitter',
      route: 'https://twitter.com/AlphaShares',
      target: '_blank',
      description: 'Follow our tweets for updates!',
      icon: TwitterIcon,
    },
    // {
    //   name: 'Medium',
    //   route: 'https://alphashares.medium.com/',
    //   target: '_blank',
    //   description: 'Follow our Medium Blog!',
    //   icon: MediumIcon,
    // },
    {
      name: 'Telegram',
      route: 'https://t.me/Alpha_Shares',
      target: '_blank',
      description: 'Join us on Telegram!',
      icon: TelegramIcon,
    },
  ]
}

export const NAVIGATION = [
  {
    name: 'Mint',
    route: '/mint',
  },
  {
    name: 'Explore',
    route: '/explore',
  },
  {
    name: 'Browse',
    route: '/assets',
  },

  // {
  //   name: 'Help Center',
  //   route: '/help-center',
  // },
  // {
  //   name: 'Docs',
  //   route: 'https://docs.alphashares.io',
  //   target: '_blank'
  // },
  // {
  //   name: 'About',
  //   sections: [
  //     SOCIALS,
  //     {
  //       name: 'Security',
  //       items: [
  //         {
  //           name: 'KYC',
  //           route: 'https://rugdoc.io/project/alpha-shares/',
  //           target: '_blank',
  //           description: 'RugDoc KYC Report',
  //           icon: ShieldCheckIcon,
  //         },
  //         {
  //           name: 'Audit',
  //           route: 'https://paladinsec.co/projects/alphashares/',
  //           target: '_blank',
  //           description: 'Paladin Security Audit Report',
  //           icon: BadgeCheckIcon,
  //         },
  //         // {
  //         //   name: 'LP Locked',
  //         //   route: 'https://www.team.finance/view-coin/0x325a98F258a5732c7b06555603F6aF5BC1C17F0a?name=$ALPHA&symbol=$ALPHA',
  //         //   target: '_blank',
  //         //   description: 'Liquidity Locked with Team Finance',
  //         //   icon: CurrencyDollarIcon,
  //         // },
  //         {
  //           name: 'Multisig Wallet',
  //           route: 'https://snowtrace.io/address/0x2b93ea09a9e2df790aa12e7a039d798aab27226a',
  //           target: '_blank',
  //           description: 'Gnosis Multisig Wallet',
  //           icon: ClipboardCheckIcon,
  //         },
  //       ]
  //     }
  //   ]
  // },
];

export const FOOTER_NAV = {
  mission: 'Alpha ($ALPHA) is strictly a utility token and not a security or any form of investment of any sort. $ALPHA is utilized as a voucher and a loyalty reward system that enables individuals to gain access to our platforms and services. There is no tie or correlation  between Alpha Share\'s leadership or contractors efforts to the price of the Alpha ($ALPHA) utility token. I understand Alpha ($ALPHA) is NOT an investment of money, not in a common enterprise, purchasers should hold no expectation of profits from holding tokens, where those profits are derived primarily from the efforts of others.',
  social: [
    {
      name: 'Discord',
      href: 'https://discord.gg/alphashares',
      target: '_blank',
    },
    {
      name: 'Telegram',
      href: 'https://t.me/Alpha_Shares',
      target: '_blank',
    },
    {
      name: 'Twitter',
      href: 'https://twitter.com/AlphaShares',
      target: '_blank',
    },
    {
      name: 'Docs',
      href: 'https://docs.alphanodes.finance/alphadocs/',
      target: '_blank',
    },
  ],
  solutions: [
    {
      name: 'Launchpad',
      href: 'https://launch.alphashares.io',
      target: '_blank'
    },
    {
      name: 'NFT Marketplace',
      href: '/explore'
    },
    {
      name: 'Staking',
      href: '/account'
    }
  ],
  support: [
    {
      name: 'Help Center',
      href: '/help-center',
    },
    {
      name: 'Docs',
      href: 'https://docs.alphashares.io/guides/welcome',
      target: '_blank',
    },
  ],
  company: [
    {
      name: 'RugDoc KYC',
      href: 'https://rugdoc.io/project/alpha-shares/',
      target: '_blank',
    },
    {
      name: 'Paladin Audit',
      href: 'https://paladinsec.co/projects/alphashares/',
      target: '_blank',
    },
    {
      name: 'Multisig',
      href: 'https://snowtrace.io/address/0x2b93ea09a9e2df790aa12e7a039d798aab27226a',
      target: '_blank',
    }],
  legal: [{
    name: 'Terms of Service',
    href: 'https://docs.alphashares.io/about/terms-of-service',
    target: '_blank',
  },
  {
    name: 'Disclaimer',
    href: 'https://docs.alphashares.io/about/disclaimer',
    target: '_blank',
  }],
};

export const CHAINS = {
  name: 'Chains',
  sections: [
    {
      name: 'Select Chain',
      items: [
        {
          name: 'Ethereum',
          description: 'Alpha Shares ETH',
          action: {
            func: 'switchChain',
            arg: ChainId.ETHEREUM
          },
          imageSrc: '/images/ETHEREUM.png'
        },
        {
          name: 'Avalanche',
          description: 'Alpha Shares AVAX',
          action: {
            func: 'switchChain',
            arg: ChainId.AVALANCHE
          },
          imageSrc: '/images/AVAX.png'
        },
        {
          name: 'Polygon',
          description: 'Alpha Shares Polygon',
          action: {
            func: 'switchChain',
            arg: ChainId.POLYGON
          },
          imageSrc: '/images/MATIC.png'
        },
        {
          name: 'BNB Smart Chain',
          description: 'Alpha Shares BSC',
          action: {
            func: 'switchChain',
            arg: ChainId.BSC
          },
          imageSrc: '/images/BSC.png'
        },
        {
          name: 'Arbitrum One',
          description: 'Alpha Shares Arbitrum One',
          action: {
            func: 'switchChain',
            arg: ChainId.ARBITRUM
          },
          imageSrc: '/images/ARBITRUM.png'
        },
        {
          name: 'Optimism',
          description: 'Alpha Shares Optimism',
          action: {
            func: 'switchChain',
            arg: ChainId.OPTIMISM
          },
          imageSrc: '/images/OPTIMISM.png'
        },
      ]
    }
  ]
}
