/* eslint-disable react/display-name */

import React from 'react';

// @ts-ignore
export const withContext = (Context, Component) => (props) => (
  <Context>
    <Component {...props} />
  </Context>
);
