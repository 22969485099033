export const handleExternalLinkClick = (URL: string, target = '_blank'):void => {
  if (window) {
    window.open(URL, target);
  }
};

export const formatPrice = (number: number) => {
  return new Intl.NumberFormat().format(number)
}


