import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { APP_BASE_PATH } from '@alpha-shares/config/constants/appBasePaths'

export function useMarketplaceRouteBasePath() {
  const router = useRouter();
  const { basePath } = router;
  const [routeBasePath, setRouteBasePath] = useState('' as string);

  useEffect(() => {
    if (basePath !== APP_BASE_PATH.MarketplaceNext) {
      setRouteBasePath(APP_BASE_PATH.MarketplaceNext)
    }
  },[router?.asPath, basePath])

  return routeBasePath
}

export function useMintBasePath() {
  const router = useRouter();
  const { basePath } = router;
  const [routeBasePath, setRouteBasePath] = useState('' as string);

  useEffect(() => {
    if (basePath !== APP_BASE_PATH.MarketplaceLegacy) {
      setRouteBasePath(APP_BASE_PATH.MarketplaceLegacy)
    }
  },[router?.asPath, basePath])

  return routeBasePath
}

export default useMarketplaceRouteBasePath;