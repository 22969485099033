import React from 'react';
import classNames from 'classnames';

interface ILogoProps {
  widthClass?: string;
  heightClass?: string;
  colorClass?: string;
  containerClass?: string;
}

export const MainLogoIcon: React.FC<ILogoProps> = ({
  widthClass,
  heightClass,
  colorClass,
  containerClass,
}) => {
  const containerClasses = classNames(
    containerClass,
    colorClass,
    'flex items-center'
  );
  const svgClasses = classNames('fill-current', widthClass, heightClass);
  return (
    <div className={containerClasses}>
      <svg
        className={svgClasses}
        viewBox="0 0 144 144"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="cls-1"
          d="M128.019 0H72.017C32.7353 0 0.813927 31.44 0.0138929 70.54C-0.786141 110.94 33.0753 144 73.497 144H143.94C143.94 144 144 143.98 144 143.94V16C144 7.16 136.84 0 127.999 0H128.019ZM82.7974 60.96C97.158 63.96 117.019 68.14 117.019 87.86C117.019 105.36 104.078 116.98 80.6973 116.98H44.7758L56.0363 97.78H81.7574C89.4577 97.78 94.1579 94.52 94.1579 90.34C94.1579 85.38 88.5377 83.42 79.2573 81.46C64.8966 78.58 44.7758 74.92 44.7758 54.3C44.7758 39.68 57.1763 27 79.5173 27H116.999L105.738 46.2H78.0572C70.6169 46.2 67.7368 49.2 67.7368 53C67.7368 57.58 73.097 59.14 82.7574 60.96H82.7974Z"
        />
      </svg>
    </div>
  );
};
