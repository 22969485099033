import React, { Component } from 'react';

import Slider, { type Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { Typography, NextArrow, PrevArrow } from '../../';

interface IMultiCarousel {
  carouselTitle?: string | null;
  className?: string;
  containerClasses?: string;
  titleClass?: string;
  responsiveConfig?: Settings['responsive'];
  children: Array<React.ReactNode>;
  HeadingLink?: JSX.Element;
  autoPlay?: boolean;
  autoPlaySpeed?: number;
  additionalTransform?: number;
  centerMode?: boolean;
  rows?: number;
}

export default class MultiCarousel extends Component<IMultiCarousel> {
  
  constructor(props: IMultiCarousel) {
    super(props);
  }
  
  render() {
    const slickResponsiveSettings: Settings['responsive'] = this.props.responsiveConfig || [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 2,          
        }
      },
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          centerPadding: '40px'          
        }
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          centerPadding: '30px'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          centerPadding: '30px'
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '30px'
        }
      }      
    ]
  
    const settings: Settings = {
      className: "center",
      centerMode: true,
      // centerPadding: '60px', // get prop for this
      autoplay: this.props.autoPlay,
      pauseOnHover: true,
      infinite: true,
      speed: this.props.autoPlaySpeed || 500,
      arrows: true,
      responsive: slickResponsiveSettings,
      // @ts-ignore
      nextArrow: <NextArrow />,
      // @ts-ignore
      prevArrow: <PrevArrow />,
      rows: this.props.rows || 1,
    }
    return (
      <div className={`${this.props.containerClasses}`}>
      {this.props.carouselTitle && this.props.HeadingLink && (
      <div>
        <Typography variant="h2" as="div" className={`py-6 px-8 break-words ${this.props.titleClass}`}>
          {this.props.carouselTitle}
        </Typography>
        <div>
          {this.props.HeadingLink}
        </div>
      </div>
      )}
      {this.props.carouselTitle && !this.props.HeadingLink && (      
        <Typography variant="h2" as="div" className={`px-8 break-words ${this.props.titleClass}`}>
          {this.props.carouselTitle}
        </Typography>
      )}
      <Slider
        {...settings}      
      >
        {this.props.children}
      </Slider>
    </div>
    )
  }
}
