import React from 'react';
import classNames from 'classnames';

import { XIcon } from '@heroicons/react/solid';

export interface ChipProps {
  children: React.ReactNode;
  hasRemove?: boolean;
  selected?: boolean;
  [x: string]: any;
}

export const Chip: React.FC<ChipProps> = ({
  children, onClick, hasRemove, selected
}) => {
  const filterItemClasses = classNames(
    'flex text-center items-center transition-all rounded-lg',
    'cursor-pointer select-none',
    'border',
    'p-2 m-2',
    selected ? 'bg-dark-500 border-accent' : 'bg-dark-400 hover:bg-dark-500 border-accent-800 hover:border-accent'
  );

  return (
    <button className={filterItemClasses} onClick={(evt) => onClick(evt)}>
      {children}
      {hasRemove ? (
        <XIcon className="w-5 h-5 text-white ml-2" aria-hidden="true" />
      ) : (null)}
    </button>
  );
}
