import React from 'react'
import classNames from 'classnames';

import { Button } from '../../Atoms/Button/Button'

import { ChevronRightIcon, ChevronLeftIcon } from '@heroicons/react/outline';

interface ISimplePaginationProps {
  handlePrevious: () => void;
  handleNext: () => void;
  className?: string;
  page?: number;
  hasNextPage?: boolean;
}

export const SimplePagination:React.FC<ISimplePaginationProps> = ({
  handleNext,
  handlePrevious,
  className,
  page,
  hasNextPage = true
}) => {
  const containerClasses = classNames(
    className
  )

  return (
    <div className={containerClasses}>
      <Button disabled={page === 1} className={`!px-2 !rounded-md ${page === 1 && 'cursor-not-allowed'}`} variant="tertiary" onClick={() => handlePrevious()}>
        <ChevronLeftIcon className="w-4 h-4" />
      </Button>
      {page && (
      <span className="text-white px-2 font-semibold">
        {page}
      </span>
      )}
      <Button disabled={!hasNextPage} className={`!px-2 !rounded-md ${!hasNextPage && 'cursor-not-allowed'}`} variant="tertiary" onClick={() => handleNext()}>
        <ChevronRightIcon className="w-4 h-4" />
      </Button>
    </div>
  )
}
