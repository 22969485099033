export const shimmer = (w: number, h: number): string => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g">
      <stop stop-color="#111332" offset="20%" />
      <stop stop-color="#2d3388" offset="50%" />
      <stop stop-color="#111332" offset="90%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#111332" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) => (typeof window === 'undefined'
  ? Buffer.from(str).toString('base64')
  : window.btoa(str)
);

export const blurData = (width: number, height: number): string => `data:image/svg+xml;base64,${toBase64(shimmer(width, height))}`;
