/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';

interface IRightSlideSidebar {
  open: boolean;
  setOpen: any;
  className?: string;
  children: JSX.Element;
}

export const RightSlideSidebar: React.FC<IRightSlideSidebar> = ({
  className,
  open,
  setOpen,
  children,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 overflow-y-scroll z-[70] lg:scrollbar scrollbar-thumb-dark-50 scrollbar-track-dark-300"
        onClose={setOpen}
      >
        <div className="absolute inset-0 overflow-y-scroll lg:scrollbar scrollbar-thumb-dark-50 scrollbar-track-dark-300">
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 sm:duration-400"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transform transition ease-in-out duration-300 sm:duration-400"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full  md:pl-16">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-300 sm:duration-400"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-300 sm:duration-400"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="pointer-events-auto w-screen max-w-2xl">
                <div
                  className={`flex h-full flex-col overflow-y-scroll lg:scrollbar-thin scrollbar-thumb-dark-50 scrollbar-track-dark-300 ${className}`}
                >
                  <div className="relative flex-1 px-0 lg:pl-16">
                    {children}
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
