/* eslint-disable react/no-array-index-key */
import React from 'react'

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { colors } from '@alpha-shares/theme/stitches.config'

import { BLOCKCHAIN_CONFIG } from '@alpha-shares/config';
import { Address } from 'wagmi'
import { ChainId } from '@alpha-shares/web3-sdk';

import {
  Typography,
  MultiCarousel,
} from '../../../';

import TokenCard from '../../../collections/TokenCard'



import { 
  useDynamicTokens, 
} from '@reservoir0x/reservoir-kit-ui'

interface IAssetCarousel {
  title?: string;
  subHeader?: string;
  titleClass?: string;
  HeadingLink?: JSX.Element
  chainId?: ChainId;
  collectionAddress?: string;
  queryParams?: any;
  config?: {
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
  }
}

const LoadingState = () => (
  <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>
    <div className="opacity-[30] animate animate-pulse m-3 lg:m-4 rounded-xl">
      <Skeleton count={1} style={{aspectRatio: '1 / 1'}} containerClassName='animate animate-pulse rounded-xl' className=' bg-dark-50 rounded-t-xl' />
      <Skeleton count={1} height={124} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-b-xl' />          
    </div>
  </SkeletonTheme>
  )


export const AssetsCarousel:React.FC<IAssetCarousel> = ({
  title = 'More From This Collection',
  subHeader,
  titleClass = 'mx-auto text-center py-4 mt-6',  
  HeadingLink,
  chainId,
  collectionAddress = BLOCKCHAIN_CONFIG.ALPHA_NFT_ADDRESS[ChainId.AVALANCHE],
  config = {
    sm: 1,
    md: 3,
    lg: 5,
    xl: 6
  }
}) => {

  const [playingElement, setPlayingElement] = React.useState<
    HTMLAudioElement | HTMLVideoElement | null
  >()

  const tokenQuery: Parameters<typeof useDynamicTokens>['0'] = {
    limit: 12,
    collection: collectionAddress,
    sortBy: 'floorAskPrice',
    sortDirection: 'asc',
    includeQuantity: true,
    includeLastSale: true,
  }

  const { data: tokens } = useDynamicTokens(
    tokenQuery,
    {},
    chainId
    );

    const responsive = [
      {
        breakpoint: 4000,
        settings: {
          slidesToShow: tokens.length < 8 ? tokens.length : 8,
          slidesToScroll: 1,          
        }
      },
      {
        breakpoint: 2500,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          centerPadding: '40px'          
        }
      },
      {
        breakpoint: 1920,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          centerPadding: '30px'
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerPadding: '20px'
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerPadding: '20px'
        }
      }      
    ]

  return (
    <div className='relative pt-6 mt-0 md:mx-0'>
    {subHeader && (
      <Typography
        variant="subtitle1"
        className="text-center mx-auto !text-primary-500 mb-6"
      >
        {subHeader}
      </Typography>
    )}
    {/* @ts-ignore */}
      <MultiCarousel
        HeadingLink={HeadingLink}
        carouselTitle={title}
        titleClass={titleClass}
        //@ts-ignore
        responsiveConfig={responsive}
        centerMode
        autoPlay
      > 
        {(!tokens) && ([...new Array(12).fill('x')].map((item, index) => (
          <LoadingState key={index} />
        )))}
        {/* @ts-ignore */}
        {tokens && tokens.map((token, idx) => (
          <div className="p-3" key={idx}>
            <div className='bg-dark-400 roundedl-xl overflow-hidden'>
            <TokenCard
              key={idx}
              token={token}
              address={collectionAddress as Address}
              // mutate={mutate}
              rarityEnabled={false}
              onMediaPlayed={(e) => {
                if (
                  playingElement
                    && playingElement !== e.nativeEvent.target
                ) {
                  playingElement.pause()
                }
                const element = (e.nativeEvent.target as HTMLAudioElement)
                    || (e.nativeEvent.target as HTMLVideoElement)
                if (element) {
                  setPlayingElement(element)
                }
              }}
              addToCartEnabled={
                  token.market?.floorAsk?.maker?.toLowerCase()
                  !== collectionAddress?.toLowerCase()
                }
            />
            </div>
          </div>    
        ))}
      </MultiCarousel>
    </div>
  )
}
