import React from 'react'

import {
  AlphaWolfIcon,
  LoneWolfIcon,
  RoninWolfIcon,
  ApexWolfIcon
} from '../../'

interface INFTTypeCard {
  tokenId?: number;
  nftType?: 0 | 1 | 2 | 3;
  className?: string;
  variant?: 'description' | 'other';
  descriptionText?: string | null;
  weightText?: string | null;
  availableText?: string | null;
}

export const NFTTypeCard:React.FC<INFTTypeCard> = ({
  tokenId,
  nftType = null,
  className,
  variant,
  descriptionText,
  weightText,
  availableText
}) => {
  const nftTypeMap = {
    0: {
      name: 'Alpha Wolf',
      Icon: AlphaWolfIcon,
      properties: {
        shares: '1',
        total: '9,843'
      }
    },
    1: {
      name: 'Lone Wolf',
      Icon: LoneWolfIcon,
      properties: {
        shares: '3',
        total: 100
      },
    },
    2: {
      name: 'Ronin Wolf',
      Icon: RoninWolfIcon,
      properties: {
        shares: '8',
        total: 47
      },
    },
    3: {
      name: 'Apex Wolf',
      Icon: ApexWolfIcon,
      properties: {
        shares: '18',
        total: 10
      },
    }
  }

  const getType = (tokenId: number): keyof typeof nftTypeMap => {
    if (tokenId <= 10) {
      return 3;
    }
    if (tokenId > 10 && tokenId <= 57) {
      return 2;
    }
    if (tokenId > 57 && tokenId <= 157) {
      return 1;
    }
  
    return 0;
  }

  const nftTypeKey:keyof typeof nftTypeMap  = nftType ?? getType(tokenId as number) as keyof typeof nftTypeMap;
  const { Icon } = nftTypeMap[nftTypeKey];

  return (
    <>
      {
      variant === 'description' ? (
        <div className={`mb-4 flex flex-col lg:flex-row items-center justify-between p-4 ${className}`}>
          {/* workaround for the icon 3 */}
          <div className={`w-32 h-32 ${nftTypeKey === 3 && 'mr-1 ml-1 mt-8'}`}>
            <Icon
              className="w-32 h-32"
            />
          </div>
          <div className="ml-6 mt-4 lg:mt-0 flex flex-col">
            <div className="uppercase text-gray-300 font-black text-2xl pb-2 text-center lg:text-left">
              {nftTypeMap[nftTypeKey].name}
            </div>
            <div className="text-base lg:text-lg font-semibold lg:font-bold mt-2 rounded-r-3xl bg-dark-100 overflow-hidden">
              <div className="flex flex-col text-primary-500 pl-4 py-2">
                {weightText}
              </div>
              <div className="flex flex-col bg-primary-500 text-dark-500 pl-4 py-2">
                {availableText}
              </div>
            </div>
            {descriptionText && (
            <div className="items-center gap-2 mt-4">
              <span className="mt-2 text-base text-gray-400 font-black text-right">
                {descriptionText}
              </span>
            </div>
            )}
          </div>
        </div>

      ) : (
        <div className={`mb-6 lg:flex flex-wrap items-center justify-left p-6 rounded-l-6xl lg:rounded-l-8xl bg-dark-400 ${className}`}>
          <div className="w-36 h-36 overflow-hidden mx-auto lg:mx-0">
            <Icon
              className="w-36 h-36"
            />
          </div>
          <div className="lg:ml-6 mt-6 lg:mt-0 flex flex-col gap-y-2">
            <div className="uppercase text-primary-500 font-black text-4xl pb-2 text-center lg:text-left">
              {nftTypeMap[nftTypeKey].name}
            </div>
            <div className="grid grid-cols-2 items-center gap-2 border-t border-dark-100">
              <span className="mt-2 text-xs text-white uppercase">
                bonus:
              </span>
              <span className="mt-2 text-2xl text-primary-500 font-black uppercase text-right">
                {nftTypeMap[nftTypeKey].properties.shares}x
              </span>
            </div>
            <div className="grid grid-cols-2 items-center gap-2 border-t border-dark-100">
              <span className="mt-2 text-xs text-white uppercase">
                total available:
              </span>
              <span className="mt-2 text-2xl text-primary-500 font-black uppercase text-right">
                {nftTypeMap[nftTypeKey].properties.total}
              </span>
            </div>
          </div>
        </div>

      )
    }
    </>
  )
}
