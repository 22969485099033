/* eslint-disable react/no-array-index-key */
import React from 'react'
import Image from "next/image";
import dynamic from 'next/dynamic';
import {
  Typography,
  Button,
  NFTIcon
} from '@alpha-shares/react-components';
import { formatPrice } from '@alpha-shares/res-utils';

import {
  GetAllMintingProjectsQuery,
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

const MintCountDownTimer = dynamic(() => import('./MintCountDownTimer')
  .then((mod) => mod.MintCountDownTimer))

type TMintCardProps = {
  item: GetAllMintingProjectsQuery['mintingProjects'][0]
}

export const MintCard: React.FC<TMintCardProps> = ({ item }) => {
  return (
    <a key={item.id}  href={`mint/${item.slug}`}>
      <div className='relative aspect-square group'>
        <div className="relative bg-dark-400 rounded-xl overflow-hidden transition transition-all aspect-square">
          {item?.nftCollection?.collectionThumbnail?.url && item.nftCollection.collectionTitle && (              
            <Image
              src={item.nftCollection.collectionThumbnail?.url}
              alt={item.nftCollection.collectionTitle}
              className="h-full w-full"
              width={400}
              height={400}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          )}
        </div>
        <div className='w-full flex flex-col gap-6 bg-dark-550'>
          <div className="pt-2 px-2 text-center z-10 min-h-[130px]">
            <Typography variant="h3" as="h3" className="text-center text-white">
              {item?.nftCollection?.collectionTitle}
            </Typography>
            {(!item?.soldOut) && (
                <div className='flex flex-col px-6 pt-2'>
                  <span className="uppercase text-xs text-primary-300">
                    Mint Starts:
                  </span>
                  <MintCountDownTimer className='mt-2 flex flex-col max-w-xs mx-auto' mintDate={item.mintDate} singleLine={false} />
                </div>
            )}                   
            {(item?.soldOut) && (
              <div className="mx-auto mt-4 rounded-xl bg-accent-500 animate-pulse p-2 w-full text-center text-dark-500 font-bold">Sold Out!</div>
            )}
          </div>
          <div className="flex flex-row items-center justify-between px-8 w-full">
            <div className="flex items-center gap-2 bg-dark-550 shadow-xl p-2 rounded-full">
              <NFTIcon className="text-white" />
              <Typography variant="body1" as="p" className="text-white uppercase">
                {formatPrice(
                  // @ts-ignore
                  item.nftCollection.collectionTotalSupply
                )}
              </Typography>
            </div>
            <div className="flex items-center gap-2 bg-dark-550 shadow-xl p-2 rounded-full justify-end">
              {item?.mintingCurrencyInformation?.tokenIcon?.url && (
                <Image
                  src={item?.mintingCurrencyInformation?.tokenIcon?.url}
                  width={20}
                  height={20}
                  alt="Currency Symbol"
                  style={{
                    maxWidth: "100%",
                    height: "auto"
                  }} />
              )}
              <Typography variant="body1" as="p" className="text-white uppercase">
                {item.mintPrice}
              </Typography>
            </div>
          </div>
          <Button className="mx-auto w-full rounded-t-none rounded-b-xl" variant="primary">View Mint Page</Button>
        </div>
      </div>
    </a>
  );
}