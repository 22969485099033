export const updateAlphaSharesSource = (offer: any) => {
  if (offer?.source?.domain === 'www.alphashares.io') {
    return {
      ...offer,
      source: {
        name: 'Alpha Shares',
        domain: 'alphashares.io',
        icon: 'https://alphashares.io/favicon.ico',
      },
    }
  } else {
    return offer;
  }
}