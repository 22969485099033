import resolveConfig from 'tailwindcss/resolveConfig'
import { tailwindConfig } from './tailwind.config.js'

import merge from 'lodash/merge';

import {  
  darkTheme as reservoirDarkTheme,
  ReservoirKitTheme,  
} from '@reservoir0x/reservoir-kit-ui'
import {
  crimson,
  slate,
  crimsonDark,
  violetDark,
  slateDark,
  greenDark,
  iris,
  irisDark,
  gray,
  green,
  violetDarkA,
  whiteA,
  redDark,
  red,
  blackA,
  violet,
  violetA,
  indigo,
} from '@radix-ui/colors'
import { createStitches } from '@stitches/react'
import type * as Stitches from '@stitches/react'
import { reset } from './utils/css/reset'

export const { theme: tailwindTheme } = resolveConfig(tailwindConfig)

// CONFIGURABLE: Here you can update all your theming (outside of ReservoirKit which can be configured in the app.tsx)
// The theme colors are all already hooked up to stitches scales, so you just need to swap them.
// Don't forget to check the dark mode themes below.
// More on Stitches theme tokens: https://stitches.dev/docs/tokens
// More on Radix color scales: https://www.radix-ui.com/docs/colors/palette-composition/the-scales

// @ts-ignore
export const { colors } = tailwindTheme;


export const { createTheme, keyframes, styled, globalCss, getCssText } =
  createStitches({
    theme: {
      colors: {
        ...crimsonDark,
        ...violetDark,
        ...violetDarkA,
        ...slateDark,
        ...greenDark,
        ...irisDark,
        ...whiteA,
        ...redDark,
        ...blackA,
    
        
    
        //Aliases
    
        //Primary
        primary1: colors.primary[50],
        primary2: colors.primary[100],
        primary3: colors.primary[200],
        primary4: colors.primary[300],
        primary5: colors.primary[400],
        primary6: colors.primary[500],
        primary7: colors.primary[600],
        primary8: colors.primary[700],
        primary9: colors.primary[800],
        primary10: colors.primary[300],
        primary11: colors.primary[500],
    
        //Secondary
        secondary1: colors.secondary[50],
        secondary2: colors.secondary[100],
        secondary3: colors.secondary[200],
        secondary4: colors.secondary[300],
        secondary5: colors.secondary[400],
        secondary6: colors.secondary[500],
        secondary7: colors.secondary[600],
        secondary8: colors.secondary[700],
        secondary9: colors.secondary[800],
        secondary10: colors.secondary[900],
    
        //Gray
        gray9: colors.dark[200],
        gray8: colors.dark[300],
        gray7: colors.dark[400],
        gray6: colors.dark[500],
        gray5: colors.dark[550],
        gray4: colors.slate[600],
        gray3: colors.slate[700],
        gray2: colors.slate[800],
        gray1: colors.slate[900],    
        gray10: colors.dark[100],
        gray11: colors.primary[500],
        gray12: colors.slate[100], // text color
    
        accent: colors.emerald[500],
    
        neutralBgSubtle: colors.dark[400],
        neutralBg: colors.dark[550],
    
        panelBg: colors.dark[500],
        panelBorder: colors.dark[550],
        panelShadow: 'transparent',
        dropdownBg: colors.dark[550],
        sidebarOverlay: 'black',
      },
      space: {
        1: '4px',
        2: '8px',
        3: '12px',
        4: '16px',
        5: '32px',
        6: '64px',
      },
      fontSizes: {},
      fontWeights: {},
      fonts: {
        // body: inter.style.fontFamily,
        button: '$body',
      },
      lineHeights: {},
      letterSpacings: {},
      sizes: {},
      radii: {},
      shadows: {},
      transitions: {},
      breakpoints: {
        sm: 100,
      },
    },
    utils: {
      // MARGIN
      m: (value: Stitches.PropertyValue<'margin'>) => ({
        margin: value,
      }),
      mx: (value: Stitches.PropertyValue<'margin'>) => ({
        marginLeft: value,
        marginRight: value,
      }),
      my: (value: Stitches.PropertyValue<'margin'>) => ({
        marginTop: value,
        marginBottom: value,
      }),
      mt: (value: Stitches.PropertyValue<'margin'>) => ({
        marginTop: value,
      }),
      mb: (value: Stitches.PropertyValue<'margin'>) => ({
        marginBottom: value,
      }),
      ml: (value: Stitches.PropertyValue<'margin'>) => ({
        marginLeft: value,
      }),
      mr: (value: Stitches.PropertyValue<'margin'>) => ({
        marginRight: value,
      }),

      // PADDING
      p: (value: Stitches.PropertyValue<'padding'>) => ({
        padding: value,
      }),
      px: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingLeft: value,
        paddingRight: value,
      }),
      py: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingTop: value,
        paddingBottom: value,
      }),
      pt: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingTop: value,
      }),
      pb: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingBottom: value,
      }),
      pl: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingLeft: value,
      }),
      pr: (value: Stitches.PropertyValue<'padding'>) => ({
        paddingRight: value,
      }),
      // DIMENSIONS
      w: (value: Stitches.PropertyValue<'width'>) => ({
        width: value,
      }),
      h: (value: Stitches.PropertyValue<'height'>) => ({
        height: value,
      }),
      size: (value: Stitches.PropertyValue<'width'>) => ({
        width: value,
        height: value,
      }),
      // GRID
      colSpan: (value: number | 'full') => {
        if (value === 'full') {
          return {
            gridColumn: '1 / -1',
          }
        }
        return {
          gridColumn: `span ${value} / span ${value}`,
        }
      },
    },
    media: {
      sm: '(min-width: 600px)',
      md: '(min-width: 900px)',
      lg: '(min-width: 1200px)',
      xl: '(min-width: 1820px)',
      bp300: '(min-width: 300px)',
      bp400: '(min-width: 400px)',
      bp500: '(min-width: 500px)',
      bp600: '(min-width: 600px)',
      bp700: '(min-width: 700px)',
      bp800: '(min-width: 800px)',
      bp900: '(min-width: 900px)',
      bp1000: '(min-width: 1000px)',
      bp1100: '(min-width: 1100px)',
      bp1200: '(min-width: 1200px)',
      bp1300: '(min-width: 1300px)',
      bp1400: '(min-width: 1400px)',
      bp1500: '(min-width: 1500px)',
      motion: '(prefers-reduced-motion)',
      hover: '(any-hover: hover)',
      dark: '(prefers-color-scheme: dark)',
      light: '(prefers-color-scheme: light)',
    },
  })

export const globalReset = globalCss(reset)

export const darkTheme = createTheme({
  colors: {
    ...crimsonDark,
    ...violetDark,
    ...violetDarkA,
    ...slateDark,
    ...greenDark,
    ...irisDark,
    ...whiteA,
    ...redDark,
    ...blackA,

    

    //Aliases

    //Primary
    primary1: colors.primary[50],
    primary2: colors.primary[100],
    primary3: colors.primary[200],
    primary4: colors.primary[300],
    primary5: colors.primary[400],
    primary6: colors.primary[500],
    primary7: colors.primary[600],
    primary8: colors.primary[700],
    primary9: colors.primary[800],
    primary10: colors.primary[900],
    primary11: colors.primary[950],

    //Secondary
    secondary1: colors.secondary[50],
    secondary2: colors.secondary[100],
    secondary3: colors.secondary[200],
    secondary4: colors.secondary[300],
    secondary5: colors.secondary[400],
    secondary6: colors.secondary[500],
    secondary7: colors.secondary[600],
    secondary8: colors.secondary[700],
    secondary9: colors.secondary[800],
    secondary10: colors.secondary[900],

    //Gray
    gray9: colors.dark[200],
    gray8: colors.dark[300],
    gray7: colors.dark[400],
    gray6: colors.dark[500],
    gray5: colors.dark[550],
    gray4: colors.slate[600],
    gray3: colors.slate[700],
    gray2: colors.slate[800],
    gray1: colors.slate[900],    
    gray10: colors.dark[100],
    gray11: colors.primary[500],
    gray12: colors.slate[100], // text color

    accent: colors.emerald[500],

    neutralBgSubtle: colors.dark[400],
    neutralBg: colors.dark[550],

    panelBg: colors.dark[500],
    panelBorder: colors.dark[550],
    panelShadow: 'transparent',
    dropdownBg: colors.dark[550],
    sidebarOverlay: 'black',
  },
})


//CONFIGURABLE: Here you can override any of the theme tokens provided by RK: https://docs.reservoir.tools/docs/reservoir-kit-theming-and-customization
const reservoirKitThemeOverrides = {
  primaryColor: colors.primary[500],
  primaryHoverColor: colors.primary[700],
  textColor: colors.slate[100],
  contentBackground: colors.dark[550],
  headerBackground: colors.dark[400],
  footerBackground: colors.dark[400],  
  borderColor: colors.dark[550],
  overlayBackground: 'rgba(40, 59, 90, 0.85)',
}

const newTheme = {
  colors: {
    accentBase: colors.accent[500],
    accentBgSubtle: colors.primary[500],
    accentBg: colors.accent[700],
    accentBgHover: colors.primary[500],
    accentBgActive: colors.primary[500],
    accentLine: colors.primary[500],
    accentBorder: colors.primary[500],
    accentBorderHover: colors.dark[300],
    accentSolid: colors.primary[200],
    accentSolidHover: colors.primary[500],
    accentText: colors.primary[500],
    accentTextContrast: colors.primary[500],
    neutralBase: colors.dark[550],
    neutralBgSubtle: colors.primary[500],
    neutralBg: colors.primary[500],
    neutralBgHover: colors.primary[500],
    neutralBgActive: colors.primary[500],
    neutralLine: colors.primary[500],
    neutralBorder: colors.dark[400],
    neutralBorderHover: colors.dark[300],
    neutralSolid: colors.primary[500],
    neutralSolidHover: colors.primary[500],
    neutralText: colors.slate[100],
    neutralTextContrast: colors.slate[100],
    secondaryBase: colors.secondary[500],
    secondaryBgSubtle: colors.dark[300],
    secondaryBg: colors.secondary[500],
    secondaryBgHover: colors.secondary[500],
    secondaryBgActive: colors.secondary[500],
    secondaryLine: colors.secondary[500],
    secondaryBorder: colors.dark[550],
    secondaryBorderHover: colors.secondary[500],
    secondarySolid: colors.secondary[500],
    secondarySolidHover: colors.primary[500],
    secondaryText: colors.slate[100],
    secondaryTextContrast: colors.primary[500],
    borderColor: colors.dark[300],
    textColor: colors.slate[100],
    focusColor: colors.dark[300],
    errorText: colors.slate[100],
    errorAccent: colors.red[500],
    successAccent: colors.accent[500],
    reservoirLogoColor: colors.primary[500],
    inputBackground: colors.dark[300],
    buttonTextColor: colors.dark[550],
    buttonTextHoverColor: colors.dark[50],
    overlayBackground: 'rgba(0, 0, 0, 0.25)',
    headerBackground: colors.dark[500],
    footerBackground: colors.dark[500],
    contentBackground: colors.dark[550],
    wellBackground: colors.dark[550],
    popoverBackground: colors.dark[550],
  },
  radii: {
    borderRadius: '1rem'
  },
  fonts: {
    body: 'Manrope',
    button: 'Manrope',
    headline: 'Manrope',
  },
  assets: {
    ethIcon: 'purple',
    chainIcon: 'dark'
  }
} as ReservoirKitTheme;

export const alphaTheme = newTheme // merge(newTheme, reservoirDarkTheme);

