import React from 'react';
import { useField } from 'formik';
import classNames from 'classnames';

export interface IInputProps {
  className?: string;
  shadowSize?: string;
  [x: string]: any;
}

const shadowClass = {
  none: '',
  small: 'shadow',
  medium: 'shadow-md',
  large: 'shadow-lg',
  xlarge: 'shadow-xl',
  xxl: 'shadow-2xl',
};

// @ts-ignore
const getShadowClass = (shadowSize = 'small') => shadowClass[shadowSize] || 'shadow';

const Input = ({
  shadowSize,
  className,
  ...restProps
}: IInputProps): JSX.Element => {
  // @ts-ignore
  const [field, meta] = useField(restProps);
  const classes = classNames(
    className,
    getShadowClass(shadowSize),
    'text-primary-50 bg-dark-100 rounded-full border border-transparent p-3 placeholder-accent-500 text-primary-500 text-base focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
  );
  return (
    <>
      <input {...field} {...restProps} className={`${classes} ${(meta.error && meta.touched && '!border !border-red-500')}`} />
      {meta.error && meta.touched && (
        <div className="text-red-500 -mb-6 pr-4">* {meta.error}</div>
      )}
    </>
  );
};

export { Input };
