// @ts-nocheck
import { GraphQLClient } from "graphql-request";
import { RequestInit } from "graphql-request/dist/types.dom";
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  UseMutationOptions,
  UseQueryOptions,
  UseInfiniteQueryOptions,
} from "@tanstack/react-query";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };

function fetcher<TData, TVariables extends { [key: string]: any }>(
  client: GraphQLClient,
  query: string,
  variables?: TVariables,
  requestHeaders?: RequestInit["headers"],
) {
  return async (): Promise<TData> =>
    client.request({
      document: query,
      variables,
      requestHeaders,
    });
}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  Hex: { input: any; output: any };
  Json: { input: any; output: any };
  Long: { input: any; output: any };
  RGBAHue: { input: any; output: any };
  RGBATransparency: { input: any; output: any };
  RichTextAST: { input: any; output: any };
};

export type Aggregate = {
  __typename?: "Aggregate";
  count: Scalars["Int"]["output"];
};

export type AlphaNftCollectionTier = Entity &
  Node & {
    __typename?: "AlphaNftCollectionTier";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<AlphaNftCollectionTier>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of AlphaNftCollectionTier versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    information?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    subHeading?: Maybe<Scalars["String"]["output"]>;
    tiers: Array<AlphaNftTier>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type AlphaNftCollectionTierCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AlphaNftCollectionTierDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type AlphaNftCollectionTierHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type AlphaNftCollectionTierPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AlphaNftCollectionTierScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type AlphaNftCollectionTierTiersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AlphaNftTierOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlphaNftTierWhereInput>;
};

export type AlphaNftCollectionTierUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AlphaNftCollectionTierConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AlphaNftCollectionTierWhereUniqueInput;
};

/** A connection to a list of items. */
export type AlphaNftCollectionTierConnection = {
  __typename?: "AlphaNftCollectionTierConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AlphaNftCollectionTierEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AlphaNftCollectionTierCreateInput = {
  cl4cdtmv13t5f01wd710e8m22?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  tiers?: InputMaybe<AlphaNftTierCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AlphaNftCollectionTierCreateManyInlineInput = {
  /** Connect multiple existing AlphaNftCollectionTier documents */
  connect?: InputMaybe<Array<AlphaNftCollectionTierWhereUniqueInput>>;
  /** Create and connect multiple existing AlphaNftCollectionTier documents */
  create?: InputMaybe<Array<AlphaNftCollectionTierCreateInput>>;
};

export type AlphaNftCollectionTierCreateOneInlineInput = {
  /** Connect one existing AlphaNftCollectionTier document */
  connect?: InputMaybe<AlphaNftCollectionTierWhereUniqueInput>;
  /** Create and connect one AlphaNftCollectionTier document */
  create?: InputMaybe<AlphaNftCollectionTierCreateInput>;
};

/** An edge in a connection. */
export type AlphaNftCollectionTierEdge = {
  __typename?: "AlphaNftCollectionTierEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: AlphaNftCollectionTier;
};

/** Identifies documents */
export type AlphaNftCollectionTierManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaNftCollectionTierWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaNftCollectionTierWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaNftCollectionTierWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AlphaNftCollectionTierWhereStageInput>;
  documentInStages_none?: InputMaybe<AlphaNftCollectionTierWhereStageInput>;
  documentInStages_some?: InputMaybe<AlphaNftCollectionTierWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  information_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  information_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  information_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  information_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  information_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  information_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  information_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  information_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  information_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tiers_every?: InputMaybe<AlphaNftTierWhereInput>;
  tiers_none?: InputMaybe<AlphaNftTierWhereInput>;
  tiers_some?: InputMaybe<AlphaNftTierWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AlphaNftCollectionTierOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  information_ASC = "information_ASC",
  information_DESC = "information_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type AlphaNftCollectionTierUpdateInput = {
  cl4cdtmv13t5f01wd710e8m22?: InputMaybe<PageUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  tiers?: InputMaybe<AlphaNftTierUpdateManyInlineInput>;
};

export type AlphaNftCollectionTierUpdateManyInlineInput = {
  /** Connect multiple existing AlphaNftCollectionTier documents */
  connect?: InputMaybe<Array<AlphaNftCollectionTierConnectInput>>;
  /** Create and connect multiple AlphaNftCollectionTier documents */
  create?: InputMaybe<Array<AlphaNftCollectionTierCreateInput>>;
  /** Delete multiple AlphaNftCollectionTier documents */
  delete?: InputMaybe<Array<AlphaNftCollectionTierWhereUniqueInput>>;
  /** Disconnect multiple AlphaNftCollectionTier documents */
  disconnect?: InputMaybe<Array<AlphaNftCollectionTierWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AlphaNftCollectionTier documents */
  set?: InputMaybe<Array<AlphaNftCollectionTierWhereUniqueInput>>;
  /** Update multiple AlphaNftCollectionTier documents */
  update?: InputMaybe<
    Array<AlphaNftCollectionTierUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple AlphaNftCollectionTier documents */
  upsert?: InputMaybe<
    Array<AlphaNftCollectionTierUpsertWithNestedWhereUniqueInput>
  >;
};

export type AlphaNftCollectionTierUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type AlphaNftCollectionTierUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AlphaNftCollectionTierUpdateManyInput;
  /** Document search */
  where: AlphaNftCollectionTierWhereInput;
};

export type AlphaNftCollectionTierUpdateOneInlineInput = {
  /** Connect existing AlphaNftCollectionTier document */
  connect?: InputMaybe<AlphaNftCollectionTierWhereUniqueInput>;
  /** Create and connect one AlphaNftCollectionTier document */
  create?: InputMaybe<AlphaNftCollectionTierCreateInput>;
  /** Delete currently connected AlphaNftCollectionTier document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected AlphaNftCollectionTier document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single AlphaNftCollectionTier document */
  update?: InputMaybe<AlphaNftCollectionTierUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AlphaNftCollectionTier document */
  upsert?: InputMaybe<AlphaNftCollectionTierUpsertWithNestedWhereUniqueInput>;
};

export type AlphaNftCollectionTierUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AlphaNftCollectionTierUpdateInput;
  /** Unique document search */
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type AlphaNftCollectionTierUpsertInput = {
  /** Create document if it didn't exist */
  create: AlphaNftCollectionTierCreateInput;
  /** Update document if it exists */
  update: AlphaNftCollectionTierUpdateInput;
};

export type AlphaNftCollectionTierUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AlphaNftCollectionTierUpsertInput;
  /** Unique document search */
  where: AlphaNftCollectionTierWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AlphaNftCollectionTierWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type AlphaNftCollectionTierWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaNftCollectionTierWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaNftCollectionTierWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaNftCollectionTierWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AlphaNftCollectionTierWhereStageInput>;
  documentInStages_none?: InputMaybe<AlphaNftCollectionTierWhereStageInput>;
  documentInStages_some?: InputMaybe<AlphaNftCollectionTierWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  information_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  information_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  information_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  information_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  information_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  information_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  information_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  information_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  information_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tiers_every?: InputMaybe<AlphaNftTierWhereInput>;
  tiers_none?: InputMaybe<AlphaNftTierWhereInput>;
  tiers_some?: InputMaybe<AlphaNftTierWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AlphaNftCollectionTierWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaNftCollectionTierWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaNftCollectionTierWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaNftCollectionTierWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AlphaNftCollectionTierWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AlphaNftCollectionTier record uniquely */
export type AlphaNftCollectionTierWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AlphaNftTier = Entity & {
  __typename?: "AlphaNftTier";
  description?: Maybe<Scalars["String"]["output"]>;
  heading?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  image?: Maybe<Asset>;
  /** System stage field */
  stage: Stage;
  tier?: Maybe<AlphaWolfTier>;
  totalAvailableText?: Maybe<Scalars["String"]["output"]>;
  weightText?: Maybe<Scalars["String"]["output"]>;
};

export type AlphaNftTierImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AlphaNftTierConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AlphaNftTierWhereUniqueInput;
};

/** A connection to a list of items. */
export type AlphaNftTierConnection = {
  __typename?: "AlphaNftTierConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AlphaNftTierEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AlphaNftTierCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  tier?: InputMaybe<AlphaWolfTier>;
  totalAvailableText?: InputMaybe<Scalars["String"]["input"]>;
  weightText?: InputMaybe<Scalars["String"]["input"]>;
};

export type AlphaNftTierCreateManyInlineInput = {
  /** Create and connect multiple existing AlphaNftTier documents */
  create?: InputMaybe<Array<AlphaNftTierCreateInput>>;
};

export type AlphaNftTierCreateOneInlineInput = {
  /** Create and connect one AlphaNftTier document */
  create?: InputMaybe<AlphaNftTierCreateInput>;
};

export type AlphaNftTierCreateWithPositionInput = {
  /** Document to create */
  data: AlphaNftTierCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type AlphaNftTierEdge = {
  __typename?: "AlphaNftTierEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: AlphaNftTier;
};

/** Identifies documents */
export type AlphaNftTierManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaNftTierWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaNftTierWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaNftTierWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  tier?: InputMaybe<AlphaWolfTier>;
  /** All values that are contained in given list. */
  tier_in?: InputMaybe<Array<InputMaybe<AlphaWolfTier>>>;
  /** Any other value that exists and is not equal to the given value. */
  tier_not?: InputMaybe<AlphaWolfTier>;
  /** All values that are not contained in given list. */
  tier_not_in?: InputMaybe<Array<InputMaybe<AlphaWolfTier>>>;
  totalAvailableText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  totalAvailableText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  totalAvailableText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  totalAvailableText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  totalAvailableText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  totalAvailableText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  totalAvailableText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  totalAvailableText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  totalAvailableText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  totalAvailableText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  weightText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  weightText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  weightText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  weightText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  weightText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  weightText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  weightText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  weightText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  weightText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  weightText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum AlphaNftTierOrderByInput {
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  tier_ASC = "tier_ASC",
  tier_DESC = "tier_DESC",
  totalAvailableText_ASC = "totalAvailableText_ASC",
  totalAvailableText_DESC = "totalAvailableText_DESC",
  weightText_ASC = "weightText_ASC",
  weightText_DESC = "weightText_DESC",
}

export type AlphaNftTierParent = AlphaNftCollectionTier;

export type AlphaNftTierParentConnectInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierConnectInput>;
};

export type AlphaNftTierParentCreateInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierCreateInput>;
};

export type AlphaNftTierParentCreateManyInlineInput = {
  /** Connect multiple existing AlphaNftTierParent documents */
  connect?: InputMaybe<Array<AlphaNftTierParentWhereUniqueInput>>;
  /** Create and connect multiple existing AlphaNftTierParent documents */
  create?: InputMaybe<Array<AlphaNftTierParentCreateInput>>;
};

export type AlphaNftTierParentCreateOneInlineInput = {
  /** Connect one existing AlphaNftTierParent document */
  connect?: InputMaybe<AlphaNftTierParentWhereUniqueInput>;
  /** Create and connect one AlphaNftTierParent document */
  create?: InputMaybe<AlphaNftTierParentCreateInput>;
};

export type AlphaNftTierParentUpdateInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierUpdateInput>;
};

export type AlphaNftTierParentUpdateManyInlineInput = {
  /** Connect multiple existing AlphaNftTierParent documents */
  connect?: InputMaybe<Array<AlphaNftTierParentConnectInput>>;
  /** Create and connect multiple AlphaNftTierParent documents */
  create?: InputMaybe<Array<AlphaNftTierParentCreateInput>>;
  /** Delete multiple AlphaNftTierParent documents */
  delete?: InputMaybe<Array<AlphaNftTierParentWhereUniqueInput>>;
  /** Disconnect multiple AlphaNftTierParent documents */
  disconnect?: InputMaybe<Array<AlphaNftTierParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AlphaNftTierParent documents */
  set?: InputMaybe<Array<AlphaNftTierParentWhereUniqueInput>>;
  /** Update multiple AlphaNftTierParent documents */
  update?: InputMaybe<
    Array<AlphaNftTierParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple AlphaNftTierParent documents */
  upsert?: InputMaybe<
    Array<AlphaNftTierParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type AlphaNftTierParentUpdateManyWithNestedWhereInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierUpdateManyWithNestedWhereInput>;
};

export type AlphaNftTierParentUpdateOneInlineInput = {
  /** Connect existing AlphaNftTierParent document */
  connect?: InputMaybe<AlphaNftTierParentWhereUniqueInput>;
  /** Create and connect one AlphaNftTierParent document */
  create?: InputMaybe<AlphaNftTierParentCreateInput>;
  /** Delete currently connected AlphaNftTierParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected AlphaNftTierParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single AlphaNftTierParent document */
  update?: InputMaybe<AlphaNftTierParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AlphaNftTierParent document */
  upsert?: InputMaybe<AlphaNftTierParentUpsertWithNestedWhereUniqueInput>;
};

export type AlphaNftTierParentUpdateWithNestedWhereUniqueInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierUpdateWithNestedWhereUniqueInput>;
};

export type AlphaNftTierParentUpsertWithNestedWhereUniqueInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierUpsertWithNestedWhereUniqueInput>;
};

export type AlphaNftTierParentWhereInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierWhereInput>;
};

export type AlphaNftTierParentWhereUniqueInput = {
  AlphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierWhereUniqueInput>;
};

export type AlphaNftTierUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  tier?: InputMaybe<AlphaWolfTier>;
  totalAvailableText?: InputMaybe<Scalars["String"]["input"]>;
  weightText?: InputMaybe<Scalars["String"]["input"]>;
};

export type AlphaNftTierUpdateManyInlineInput = {
  /** Create and connect multiple AlphaNftTier component instances */
  create?: InputMaybe<Array<AlphaNftTierCreateWithPositionInput>>;
  /** Delete multiple AlphaNftTier documents */
  delete?: InputMaybe<Array<AlphaNftTierWhereUniqueInput>>;
  /** Update multiple AlphaNftTier component instances */
  update?: InputMaybe<
    Array<AlphaNftTierUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple AlphaNftTier component instances */
  upsert?: InputMaybe<
    Array<AlphaNftTierUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type AlphaNftTierUpdateManyInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  tier?: InputMaybe<AlphaWolfTier>;
  totalAvailableText?: InputMaybe<Scalars["String"]["input"]>;
  weightText?: InputMaybe<Scalars["String"]["input"]>;
};

export type AlphaNftTierUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AlphaNftTierUpdateManyInput;
  /** Document search */
  where: AlphaNftTierWhereInput;
};

export type AlphaNftTierUpdateOneInlineInput = {
  /** Create and connect one AlphaNftTier document */
  create?: InputMaybe<AlphaNftTierCreateInput>;
  /** Delete currently connected AlphaNftTier document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single AlphaNftTier document */
  update?: InputMaybe<AlphaNftTierUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AlphaNftTier document */
  upsert?: InputMaybe<AlphaNftTierUpsertWithNestedWhereUniqueInput>;
};

export type AlphaNftTierUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<AlphaNftTierUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AlphaNftTierWhereUniqueInput;
};

export type AlphaNftTierUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AlphaNftTierUpdateInput;
  /** Unique document search */
  where: AlphaNftTierWhereUniqueInput;
};

export type AlphaNftTierUpsertInput = {
  /** Create document if it didn't exist */
  create: AlphaNftTierCreateInput;
  /** Update document if it exists */
  update: AlphaNftTierUpdateInput;
};

export type AlphaNftTierUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<AlphaNftTierUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: AlphaNftTierWhereUniqueInput;
};

export type AlphaNftTierUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AlphaNftTierUpsertInput;
  /** Unique document search */
  where: AlphaNftTierWhereUniqueInput;
};

/** Identifies documents */
export type AlphaNftTierWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaNftTierWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaNftTierWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaNftTierWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  tier?: InputMaybe<AlphaWolfTier>;
  /** All values that are contained in given list. */
  tier_in?: InputMaybe<Array<InputMaybe<AlphaWolfTier>>>;
  /** Any other value that exists and is not equal to the given value. */
  tier_not?: InputMaybe<AlphaWolfTier>;
  /** All values that are not contained in given list. */
  tier_not_in?: InputMaybe<Array<InputMaybe<AlphaWolfTier>>>;
  totalAvailableText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  totalAvailableText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  totalAvailableText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  totalAvailableText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  totalAvailableText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  totalAvailableText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  totalAvailableText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  totalAvailableText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  totalAvailableText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  totalAvailableText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  weightText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  weightText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  weightText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  weightText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  weightText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  weightText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  weightText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  weightText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  weightText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  weightText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References AlphaNftTier record uniquely */
export type AlphaNftTierWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AlphaSharesInformation = Entity &
  Node & {
    __typename?: "AlphaSharesInformation";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    disclaimer?: Maybe<Scalars["String"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<AlphaSharesInformation>;
    /** List of AlphaSharesInformation versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type AlphaSharesInformationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AlphaSharesInformationDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type AlphaSharesInformationHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type AlphaSharesInformationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AlphaSharesInformationScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type AlphaSharesInformationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type AlphaSharesInformationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AlphaSharesInformationWhereUniqueInput;
};

/** A connection to a list of items. */
export type AlphaSharesInformationConnection = {
  __typename?: "AlphaSharesInformationConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AlphaSharesInformationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AlphaSharesInformationCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  disclaimer?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AlphaSharesInformationCreateManyInlineInput = {
  /** Connect multiple existing AlphaSharesInformation documents */
  connect?: InputMaybe<Array<AlphaSharesInformationWhereUniqueInput>>;
  /** Create and connect multiple existing AlphaSharesInformation documents */
  create?: InputMaybe<Array<AlphaSharesInformationCreateInput>>;
};

export type AlphaSharesInformationCreateOneInlineInput = {
  /** Connect one existing AlphaSharesInformation document */
  connect?: InputMaybe<AlphaSharesInformationWhereUniqueInput>;
  /** Create and connect one AlphaSharesInformation document */
  create?: InputMaybe<AlphaSharesInformationCreateInput>;
};

/** An edge in a connection. */
export type AlphaSharesInformationEdge = {
  __typename?: "AlphaSharesInformationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: AlphaSharesInformation;
};

/** Identifies documents */
export type AlphaSharesInformationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaSharesInformationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaSharesInformationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaSharesInformationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  disclaimer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  disclaimer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  disclaimer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  disclaimer_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  disclaimer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  disclaimer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  disclaimer_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  disclaimer_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  disclaimer_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  disclaimer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  documentInStages_every?: InputMaybe<AlphaSharesInformationWhereStageInput>;
  documentInStages_none?: InputMaybe<AlphaSharesInformationWhereStageInput>;
  documentInStages_some?: InputMaybe<AlphaSharesInformationWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AlphaSharesInformationOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  disclaimer_ASC = "disclaimer_ASC",
  disclaimer_DESC = "disclaimer_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type AlphaSharesInformationUpdateInput = {
  disclaimer?: InputMaybe<Scalars["String"]["input"]>;
};

export type AlphaSharesInformationUpdateManyInlineInput = {
  /** Connect multiple existing AlphaSharesInformation documents */
  connect?: InputMaybe<Array<AlphaSharesInformationConnectInput>>;
  /** Create and connect multiple AlphaSharesInformation documents */
  create?: InputMaybe<Array<AlphaSharesInformationCreateInput>>;
  /** Delete multiple AlphaSharesInformation documents */
  delete?: InputMaybe<Array<AlphaSharesInformationWhereUniqueInput>>;
  /** Disconnect multiple AlphaSharesInformation documents */
  disconnect?: InputMaybe<Array<AlphaSharesInformationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing AlphaSharesInformation documents */
  set?: InputMaybe<Array<AlphaSharesInformationWhereUniqueInput>>;
  /** Update multiple AlphaSharesInformation documents */
  update?: InputMaybe<
    Array<AlphaSharesInformationUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple AlphaSharesInformation documents */
  upsert?: InputMaybe<
    Array<AlphaSharesInformationUpsertWithNestedWhereUniqueInput>
  >;
};

export type AlphaSharesInformationUpdateManyInput = {
  disclaimer?: InputMaybe<Scalars["String"]["input"]>;
};

export type AlphaSharesInformationUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AlphaSharesInformationUpdateManyInput;
  /** Document search */
  where: AlphaSharesInformationWhereInput;
};

export type AlphaSharesInformationUpdateOneInlineInput = {
  /** Connect existing AlphaSharesInformation document */
  connect?: InputMaybe<AlphaSharesInformationWhereUniqueInput>;
  /** Create and connect one AlphaSharesInformation document */
  create?: InputMaybe<AlphaSharesInformationCreateInput>;
  /** Delete currently connected AlphaSharesInformation document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected AlphaSharesInformation document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single AlphaSharesInformation document */
  update?: InputMaybe<AlphaSharesInformationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AlphaSharesInformation document */
  upsert?: InputMaybe<AlphaSharesInformationUpsertWithNestedWhereUniqueInput>;
};

export type AlphaSharesInformationUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AlphaSharesInformationUpdateInput;
  /** Unique document search */
  where: AlphaSharesInformationWhereUniqueInput;
};

export type AlphaSharesInformationUpsertInput = {
  /** Create document if it didn't exist */
  create: AlphaSharesInformationCreateInput;
  /** Update document if it exists */
  update: AlphaSharesInformationUpdateInput;
};

export type AlphaSharesInformationUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AlphaSharesInformationUpsertInput;
  /** Unique document search */
  where: AlphaSharesInformationWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AlphaSharesInformationWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type AlphaSharesInformationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaSharesInformationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaSharesInformationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaSharesInformationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  disclaimer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  disclaimer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  disclaimer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  disclaimer_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  disclaimer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  disclaimer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  disclaimer_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  disclaimer_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  disclaimer_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  disclaimer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  documentInStages_every?: InputMaybe<AlphaSharesInformationWhereStageInput>;
  documentInStages_none?: InputMaybe<AlphaSharesInformationWhereStageInput>;
  documentInStages_some?: InputMaybe<AlphaSharesInformationWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AlphaSharesInformationWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AlphaSharesInformationWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AlphaSharesInformationWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AlphaSharesInformationWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AlphaSharesInformationWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References AlphaSharesInformation record uniquely */
export type AlphaSharesInformationWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum AlphaWolfTier {
  alpha = "alpha",
  apex = "apex",
  lone = "lone",
  ronin = "ronin",
}

/** Identifier for Alpha Shares Applications - use Global for anything that will be common to all apps */
export enum AppId {
  Global = "Global",
  MarketplaceLegacy = "MarketplaceLegacy",
  MarketplaceNext = "MarketplaceNext",
}

/** Asset system model */
export type Asset = Entity &
  Node & {
    __typename?: "Asset";
    backgroundImageMiningNFTLPPageHeroSection: Array<MiningNftlpPageHeroSection>;
    backgroundImageMiningNftAccessSection: Array<MiningNftAccessSection>;
    backgroundImageMiningNftAntimonyAdvantageSection: Array<MiningNftAntimonyAdvantageSection>;
    backgroundImageMiningNftEligibilitySection: Array<MiningNftEligibilitySection>;
    backgroundImageMiningNftProfitRoiSection: Array<MiningNftProfitRoiSection>;
    backgroundImageMiningNftTeamSection: Array<MiningNftTeamSection>;
    backgroundImageMintingStat: Array<MintingStat>;
    backgroundImagePageHero: Array<PageHero>;
    chainNativeCurrencyIconChain: Array<Chain>;
    collectionCategoryBannerNftCategoryDisplay: Array<NftCategoryDisplay>;
    collectionCategoryThumbnailNftCategoryDisplay: Array<NftCategoryDisplay>;
    collectionCreatorBannerImageCollectionCreator: Array<CollectionCreator>;
    collectionCreatorThumbnailImageCollectionCreator: Array<CollectionCreator>;
    collectionFeaturedImageNftCollection: Array<NftCollection>;
    collectionHeaderImageLargeNftCollection: Array<NftCollection>;
    collectionSampleImagesMintingProject: Array<MintingProject>;
    collectionSampleImagesMultiChainMintingProject: Array<MultiChainMintingProject>;
    collectionThumbnailNftCollection: Array<NftCollection>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Asset>;
    /** The file name */
    fileName: Scalars["String"]["output"];
    /** The file handle */
    handle: Scalars["String"]["output"];
    /** The height of the file */
    height?: Maybe<Scalars["Float"]["output"]>;
    heroBannerImageLandingPage: Array<LandingPage>;
    heroImageLeftMintingStat: Array<MintingStat>;
    heroImageMiningNFTLPPageHeroSection: Array<MiningNftlpPageHeroSection>;
    heroImageMintingStat: Array<MintingStat>;
    heroImagesPageHero: Array<PageHero>;
    /** List of Asset versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    imageCtaSection: Array<CtaSection>;
    leftSideOverlayImageMiningNftProfitRoiSection: Array<MiningNftProfitRoiSection>;
    /** System Locale field */
    locale: Locale;
    /** Get the other localizations for this document */
    localizations: Array<Asset>;
    logoImageLandingPage: Array<LandingPage>;
    logoMiningNFTLPPageHeroSection: Array<MiningNftlpPageHeroSection>;
    logoPoweredBySection: Array<PoweredBySection>;
    menuGroupIconMenu: Array<Menu>;
    /** The mime type of the file */
    mimeType?: Maybe<Scalars["String"]["output"]>;
    oreImageMiningNftAntimonyAdvantageSection: Array<MiningNftAntimonyAdvantageSection>;
    periodicTableElementImageMiningNftAntimonyAdvantageSection: Array<MiningNftAntimonyAdvantageSection>;
    projectionImageMiningBtcSupplyDemandModelSection: Array<MiningBtcSupplyDemandModelSection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    roiImageMiningNftProfitRoiSection: Array<MiningNftProfitRoiSection>;
    scheduledIn: Array<ScheduledOperation>;
    seoImageLandingPage: Array<LandingPage>;
    seoImageMultiChainMintingProject: Array<MultiChainMintingProject>;
    /** The file size */
    size?: Maybe<Scalars["Float"]["output"]>;
    /** System stage field */
    stage: Stage;
    tokenIconMintingCurrency: Array<MintingCurrency>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    /** Get the url for the asset with provided transformations applied. */
    url: Scalars["String"]["output"];
    /** The file width */
    width?: Maybe<Scalars["Float"]["output"]>;
  };

/** Asset system model */
export type AssetBackgroundImageMiningNftlpPageHeroSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftlpPageHeroSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
};

/** Asset system model */
export type AssetBackgroundImageMiningNftAccessSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftAccessSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftAccessSectionWhereInput>;
};

/** Asset system model */
export type AssetBackgroundImageMiningNftAntimonyAdvantageSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftAntimonyAdvantageSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
};

/** Asset system model */
export type AssetBackgroundImageMiningNftEligibilitySectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftEligibilitySectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
};

/** Asset system model */
export type AssetBackgroundImageMiningNftProfitRoiSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftProfitRoiSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
};

/** Asset system model */
export type AssetBackgroundImageMiningNftTeamSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftTeamSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftTeamSectionWhereInput>;
};

/** Asset system model */
export type AssetBackgroundImageMintingStatArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatWhereInput>;
};

/** Asset system model */
export type AssetBackgroundImagePageHeroArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageHeroWhereInput>;
};

/** Asset system model */
export type AssetChainNativeCurrencyIconChainArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainWhereInput>;
};

/** Asset system model */
export type AssetCollectionCategoryBannerNftCategoryDisplayArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCategoryDisplayOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCategoryDisplayWhereInput>;
};

/** Asset system model */
export type AssetCollectionCategoryThumbnailNftCategoryDisplayArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCategoryDisplayOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCategoryDisplayWhereInput>;
};

/** Asset system model */
export type AssetCollectionCreatorBannerImageCollectionCreatorArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

/** Asset system model */
export type AssetCollectionCreatorThumbnailImageCollectionCreatorArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

/** Asset system model */
export type AssetCollectionFeaturedImageNftCollectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Asset system model */
export type AssetCollectionHeaderImageLargeNftCollectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Asset system model */
export type AssetCollectionSampleImagesMintingProjectArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectWhereInput>;
};

/** Asset system model */
export type AssetCollectionSampleImagesMultiChainMintingProjectArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

/** Asset system model */
export type AssetCollectionThumbnailNftCollectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Asset system model */
export type AssetCreatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Asset system model */
export type AssetHeroBannerImageLandingPageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageWhereInput>;
};

/** Asset system model */
export type AssetHeroImageLeftMintingStatArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatWhereInput>;
};

/** Asset system model */
export type AssetHeroImageMiningNftlpPageHeroSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftlpPageHeroSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
};

/** Asset system model */
export type AssetHeroImageMintingStatArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatWhereInput>;
};

/** Asset system model */
export type AssetHeroImagesPageHeroArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PageHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageHeroWhereInput>;
};

/** Asset system model */
export type AssetHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

/** Asset system model */
export type AssetImageCtaSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CtaSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CtaSectionWhereInput>;
};

/** Asset system model */
export type AssetLeftSideOverlayImageMiningNftProfitRoiSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftProfitRoiSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
};

/** Asset system model */
export type AssetLocalizationsArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  locales?: Array<Locale>;
};

/** Asset system model */
export type AssetLogoImageLandingPageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageWhereInput>;
};

/** Asset system model */
export type AssetLogoMiningNftlpPageHeroSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftlpPageHeroSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
};

/** Asset system model */
export type AssetLogoPoweredBySectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<PoweredBySectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PoweredBySectionWhereInput>;
};

/** Asset system model */
export type AssetMenuGroupIconMenuArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MenuOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MenuWhereInput>;
};

/** Asset system model */
export type AssetOreImageMiningNftAntimonyAdvantageSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftAntimonyAdvantageSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
};

/** Asset system model */
export type AssetPeriodicTableElementImageMiningNftAntimonyAdvantageSectionArgs =
  {
    after?: InputMaybe<Scalars["String"]["input"]>;
    before?: InputMaybe<Scalars["String"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    locales?: InputMaybe<Array<Locale>>;
    orderBy?: InputMaybe<MiningNftAntimonyAdvantageSectionOrderByInput>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  };

/** Asset system model */
export type AssetProjectionImageMiningBtcSupplyDemandModelSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningBtcSupplyDemandModelSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
};

/** Asset system model */
export type AssetPublishedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetRoiImageMiningNftProfitRoiSectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftProfitRoiSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
};

/** Asset system model */
export type AssetScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Asset system model */
export type AssetSeoImageLandingPageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageWhereInput>;
};

/** Asset system model */
export type AssetSeoImageMultiChainMintingProjectArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

/** Asset system model */
export type AssetTokenIconMintingCurrencyArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

/** Asset system model */
export type AssetUpdatedAtArgs = {
  variation?: SystemDateTimeFieldVariation;
};

/** Asset system model */
export type AssetUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Asset system model */
export type AssetUrlArgs = {
  transformation?: InputMaybe<AssetTransformationInput>;
};

export type AssetConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: AssetWhereUniqueInput;
};

/** A connection to a list of items. */
export type AssetConnection = {
  __typename?: "AssetConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<AssetEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type AssetCreateInput = {
  backgroundImageMiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionCreateManyInlineInput>;
  backgroundImageMiningNftAccessSection?: InputMaybe<MiningNftAccessSectionCreateManyInlineInput>;
  backgroundImageMiningNftAntimonyAdvantageSection?: InputMaybe<MiningNftAntimonyAdvantageSectionCreateManyInlineInput>;
  backgroundImageMiningNftEligibilitySection?: InputMaybe<MiningNftEligibilitySectionCreateManyInlineInput>;
  backgroundImageMiningNftProfitRoiSection?: InputMaybe<MiningNftProfitRoiSectionCreateManyInlineInput>;
  backgroundImageMiningNftTeamSection?: InputMaybe<MiningNftTeamSectionCreateManyInlineInput>;
  backgroundImageMintingStat?: InputMaybe<MintingStatCreateManyInlineInput>;
  backgroundImagePageHero?: InputMaybe<PageHeroCreateManyInlineInput>;
  backgroundImageRichTextImageRow?: InputMaybe<RichTextImageRowCreateManyInlineInput>;
  chainNativeCurrencyIconChain?: InputMaybe<ChainCreateManyInlineInput>;
  collectionCategoryBannerNftCategoryDisplay?: InputMaybe<NftCategoryDisplayCreateManyInlineInput>;
  collectionCategoryThumbnailNftCategoryDisplay?: InputMaybe<NftCategoryDisplayCreateManyInlineInput>;
  collectionCreatorBannerImageCollectionCreator?: InputMaybe<CollectionCreatorCreateManyInlineInput>;
  collectionCreatorThumbnailImageCollectionCreator?: InputMaybe<CollectionCreatorCreateManyInlineInput>;
  collectionFeaturedImageNftCollection?: InputMaybe<NftCollectionCreateManyInlineInput>;
  collectionHeaderImageLargeNftCollection?: InputMaybe<NftCollectionCreateManyInlineInput>;
  collectionSampleImagesMintingProject?: InputMaybe<MintingProjectCreateManyInlineInput>;
  collectionSampleImagesMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  collectionThumbnailNftCollection?: InputMaybe<NftCollectionCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName: Scalars["String"]["input"];
  handle: Scalars["String"]["input"];
  height?: InputMaybe<Scalars["Float"]["input"]>;
  heroBannerImageLandingPage?: InputMaybe<LandingPageCreateManyInlineInput>;
  heroImageLeftMintingStat?: InputMaybe<MintingStatCreateManyInlineInput>;
  heroImageMiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionCreateManyInlineInput>;
  heroImageMintingStat?: InputMaybe<MintingStatCreateManyInlineInput>;
  heroImagesPageHero?: InputMaybe<PageHeroCreateManyInlineInput>;
  iconLargeIconHeading?: InputMaybe<LargeIconHeadingCreateManyInlineInput>;
  iconSmallIconHeadingText?: InputMaybe<SmallIconHeadingTextCreateManyInlineInput>;
  imageCtaSection?: InputMaybe<CtaSectionCreateManyInlineInput>;
  imageImageWithAltTextAndLink?: InputMaybe<ImageWithAltTextAndLinkCreateManyInlineInput>;
  imageNftTier?: InputMaybe<AlphaNftTierCreateManyInlineInput>;
  imageRichTextImageRow?: InputMaybe<RichTextImageRowCreateManyInlineInput>;
  imageTeamMemberImageNameTitle?: InputMaybe<TeamMemberImageNameTitleCreateManyInlineInput>;
  leftSideOverlayImageMiningNftProfitRoiSection?: InputMaybe<MiningNftProfitRoiSectionCreateManyInlineInput>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: InputMaybe<AssetCreateLocalizationsInput>;
  logoImageLandingPage?: InputMaybe<LandingPageCreateManyInlineInput>;
  logoMiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionCreateManyInlineInput>;
  logoPoweredBySection?: InputMaybe<PoweredBySectionCreateManyInlineInput>;
  menuGroupIconMenu?: InputMaybe<MenuCreateManyInlineInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  oreImageMiningNftAntimonyAdvantageSection?: InputMaybe<MiningNftAntimonyAdvantageSectionCreateManyInlineInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection?: InputMaybe<MiningNftAntimonyAdvantageSectionCreateManyInlineInput>;
  projectionImageMiningBtcSupplyDemandModelSection?: InputMaybe<MiningBtcSupplyDemandModelSectionCreateManyInlineInput>;
  roadmapItemSampleImagesRoadmapItem?: InputMaybe<RoadmapItemCreateManyInlineInput>;
  roiImageMiningNftProfitRoiSection?: InputMaybe<MiningNftProfitRoiSectionCreateManyInlineInput>;
  seoImageLandingPage?: InputMaybe<LandingPageCreateManyInlineInput>;
  seoImageMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  tokenIconMintingCurrency?: InputMaybe<MintingCurrencyCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetCreateLocalizationDataInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  fileName: Scalars["String"]["input"];
  handle: Scalars["String"]["input"];
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetCreateLocalizationInput = {
  /** Localization input */
  data: AssetCreateLocalizationDataInput;
  locale: Locale;
};

export type AssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
};

export type AssetCreateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Create and connect multiple existing Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
};

export type AssetCreateOneInlineInput = {
  /** Connect one existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
};

/** An edge in a connection. */
export type AssetEdge = {
  __typename?: "AssetEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Asset;
};

/** Identifies documents */
export type AssetManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImageMiningNFTLPPageHeroSection_every?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  backgroundImageMiningNFTLPPageHeroSection_none?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  backgroundImageMiningNFTLPPageHeroSection_some?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  backgroundImageMiningNftAccessSection_every?: InputMaybe<MiningNftAccessSectionWhereInput>;
  backgroundImageMiningNftAccessSection_none?: InputMaybe<MiningNftAccessSectionWhereInput>;
  backgroundImageMiningNftAccessSection_some?: InputMaybe<MiningNftAccessSectionWhereInput>;
  backgroundImageMiningNftAntimonyAdvantageSection_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  backgroundImageMiningNftAntimonyAdvantageSection_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  backgroundImageMiningNftAntimonyAdvantageSection_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  backgroundImageMiningNftEligibilitySection_every?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
  backgroundImageMiningNftEligibilitySection_none?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
  backgroundImageMiningNftEligibilitySection_some?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
  backgroundImageMiningNftProfitRoiSection_every?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  backgroundImageMiningNftProfitRoiSection_none?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  backgroundImageMiningNftProfitRoiSection_some?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  backgroundImageMiningNftTeamSection_every?: InputMaybe<MiningNftTeamSectionWhereInput>;
  backgroundImageMiningNftTeamSection_none?: InputMaybe<MiningNftTeamSectionWhereInput>;
  backgroundImageMiningNftTeamSection_some?: InputMaybe<MiningNftTeamSectionWhereInput>;
  backgroundImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  backgroundImagePageHero_every?: InputMaybe<PageHeroWhereInput>;
  backgroundImagePageHero_none?: InputMaybe<PageHeroWhereInput>;
  backgroundImagePageHero_some?: InputMaybe<PageHeroWhereInput>;
  chainNativeCurrencyIconChain_every?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_none?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_some?: InputMaybe<ChainWhereInput>;
  collectionCategoryBannerNftCategoryDisplay_every?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryBannerNftCategoryDisplay_none?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryBannerNftCategoryDisplay_some?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryThumbnailNftCategoryDisplay_every?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryThumbnailNftCategoryDisplay_none?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryThumbnailNftCategoryDisplay_some?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCreatorBannerImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionFeaturedImageNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionSampleImagesMintingProject_every?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_none?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_some?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionThumbnailNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  heroBannerImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  heroImageLeftMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  heroImageMiningNFTLPPageHeroSection_every?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  heroImageMiningNFTLPPageHeroSection_none?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  heroImageMiningNFTLPPageHeroSection_some?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  heroImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  heroImagesPageHero_every?: InputMaybe<PageHeroWhereInput>;
  heroImagesPageHero_none?: InputMaybe<PageHeroWhereInput>;
  heroImagesPageHero_some?: InputMaybe<PageHeroWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  imageCtaSection_every?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_none?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_some?: InputMaybe<CtaSectionWhereInput>;
  leftSideOverlayImageMiningNftProfitRoiSection_every?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  leftSideOverlayImageMiningNftProfitRoiSection_none?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  leftSideOverlayImageMiningNftProfitRoiSection_some?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  logoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  logoMiningNFTLPPageHeroSection_every?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  logoMiningNFTLPPageHeroSection_none?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  logoMiningNFTLPPageHeroSection_some?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  logoPoweredBySection_every?: InputMaybe<PoweredBySectionWhereInput>;
  logoPoweredBySection_none?: InputMaybe<PoweredBySectionWhereInput>;
  logoPoweredBySection_some?: InputMaybe<PoweredBySectionWhereInput>;
  menuGroupIconMenu_every?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_none?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_some?: InputMaybe<MenuWhereInput>;
  oreImageMiningNftAntimonyAdvantageSection_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  oreImageMiningNftAntimonyAdvantageSection_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  oreImageMiningNftAntimonyAdvantageSection_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  projectionImageMiningBtcSupplyDemandModelSection_every?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
  projectionImageMiningBtcSupplyDemandModelSection_none?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
  projectionImageMiningBtcSupplyDemandModelSection_some?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  roiImageMiningNftProfitRoiSection_every?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  roiImageMiningNftProfitRoiSection_none?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  roiImageMiningNftProfitRoiSection_some?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  seoImageMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  tokenIconMintingCurrency_every?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_none?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_some?: InputMaybe<MintingCurrencyWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum AssetOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  fileName_ASC = "fileName_ASC",
  fileName_DESC = "fileName_DESC",
  handle_ASC = "handle_ASC",
  handle_DESC = "handle_DESC",
  height_ASC = "height_ASC",
  height_DESC = "height_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  mimeType_ASC = "mimeType_ASC",
  mimeType_DESC = "mimeType_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  size_ASC = "size_ASC",
  size_DESC = "size_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  width_ASC = "width_ASC",
  width_DESC = "width_DESC",
}

/** Transformations for Assets */
export type AssetTransformationInput = {
  document?: InputMaybe<DocumentTransformationInput>;
  image?: InputMaybe<ImageTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssetUpdateInput = {
  backgroundImageMiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionUpdateManyInlineInput>;
  backgroundImageMiningNftAccessSection?: InputMaybe<MiningNftAccessSectionUpdateManyInlineInput>;
  backgroundImageMiningNftAntimonyAdvantageSection?: InputMaybe<MiningNftAntimonyAdvantageSectionUpdateManyInlineInput>;
  backgroundImageMiningNftEligibilitySection?: InputMaybe<MiningNftEligibilitySectionUpdateManyInlineInput>;
  backgroundImageMiningNftProfitRoiSection?: InputMaybe<MiningNftProfitRoiSectionUpdateManyInlineInput>;
  backgroundImageMiningNftTeamSection?: InputMaybe<MiningNftTeamSectionUpdateManyInlineInput>;
  backgroundImageMintingStat?: InputMaybe<MintingStatUpdateManyInlineInput>;
  backgroundImagePageHero?: InputMaybe<PageHeroUpdateManyInlineInput>;
  backgroundImageRichTextImageRow?: InputMaybe<RichTextImageRowUpdateManyInlineInput>;
  chainNativeCurrencyIconChain?: InputMaybe<ChainUpdateManyInlineInput>;
  collectionCategoryBannerNftCategoryDisplay?: InputMaybe<NftCategoryDisplayUpdateManyInlineInput>;
  collectionCategoryThumbnailNftCategoryDisplay?: InputMaybe<NftCategoryDisplayUpdateManyInlineInput>;
  collectionCreatorBannerImageCollectionCreator?: InputMaybe<CollectionCreatorUpdateManyInlineInput>;
  collectionCreatorThumbnailImageCollectionCreator?: InputMaybe<CollectionCreatorUpdateManyInlineInput>;
  collectionFeaturedImageNftCollection?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  collectionHeaderImageLargeNftCollection?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  collectionSampleImagesMintingProject?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  collectionSampleImagesMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  collectionThumbnailNftCollection?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  heroBannerImageLandingPage?: InputMaybe<LandingPageUpdateManyInlineInput>;
  heroImageLeftMintingStat?: InputMaybe<MintingStatUpdateManyInlineInput>;
  heroImageMiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionUpdateManyInlineInput>;
  heroImageMintingStat?: InputMaybe<MintingStatUpdateManyInlineInput>;
  heroImagesPageHero?: InputMaybe<PageHeroUpdateManyInlineInput>;
  iconLargeIconHeading?: InputMaybe<LargeIconHeadingUpdateManyInlineInput>;
  iconSmallIconHeadingText?: InputMaybe<SmallIconHeadingTextUpdateManyInlineInput>;
  imageCtaSection?: InputMaybe<CtaSectionUpdateManyInlineInput>;
  imageImageWithAltTextAndLink?: InputMaybe<ImageWithAltTextAndLinkUpdateManyInlineInput>;
  imageNftTier?: InputMaybe<AlphaNftTierUpdateManyInlineInput>;
  imageRichTextImageRow?: InputMaybe<RichTextImageRowUpdateManyInlineInput>;
  imageTeamMemberImageNameTitle?: InputMaybe<TeamMemberImageNameTitleUpdateManyInlineInput>;
  leftSideOverlayImageMiningNftProfitRoiSection?: InputMaybe<MiningNftProfitRoiSectionUpdateManyInlineInput>;
  /** Manage document localizations */
  localizations?: InputMaybe<AssetUpdateLocalizationsInput>;
  logoImageLandingPage?: InputMaybe<LandingPageUpdateManyInlineInput>;
  logoMiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionUpdateManyInlineInput>;
  logoPoweredBySection?: InputMaybe<PoweredBySectionUpdateManyInlineInput>;
  menuGroupIconMenu?: InputMaybe<MenuUpdateManyInlineInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  oreImageMiningNftAntimonyAdvantageSection?: InputMaybe<MiningNftAntimonyAdvantageSectionUpdateManyInlineInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection?: InputMaybe<MiningNftAntimonyAdvantageSectionUpdateManyInlineInput>;
  projectionImageMiningBtcSupplyDemandModelSection?: InputMaybe<MiningBtcSupplyDemandModelSectionUpdateManyInlineInput>;
  roadmapItemSampleImagesRoadmapItem?: InputMaybe<RoadmapItemUpdateManyInlineInput>;
  roiImageMiningNftProfitRoiSection?: InputMaybe<MiningNftProfitRoiSectionUpdateManyInlineInput>;
  seoImageLandingPage?: InputMaybe<LandingPageUpdateManyInlineInput>;
  seoImageMultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  tokenIconMintingCurrency?: InputMaybe<MintingCurrencyUpdateManyInlineInput>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateLocalizationDataInput = {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateLocalizationInput = {
  data: AssetUpdateLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: InputMaybe<Array<AssetCreateLocalizationInput>>;
  /** Localizations to delete */
  delete?: InputMaybe<Array<Locale>>;
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateLocalizationInput>>;
  upsert?: InputMaybe<Array<AssetUpsertLocalizationInput>>;
};

export type AssetUpdateManyInlineInput = {
  /** Connect multiple existing Asset documents */
  connect?: InputMaybe<Array<AssetConnectInput>>;
  /** Create and connect multiple Asset documents */
  create?: InputMaybe<Array<AssetCreateInput>>;
  /** Delete multiple Asset documents */
  delete?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: InputMaybe<Array<AssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: InputMaybe<Array<AssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: InputMaybe<Array<AssetUpsertWithNestedWhereUniqueInput>>;
};

export type AssetUpdateManyInput = {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  /** Optional updates to localizations */
  localizations?: InputMaybe<AssetUpdateManyLocalizationsInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateManyLocalizationDataInput = {
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssetUpdateManyLocalizationInput = {
  data: AssetUpdateManyLocalizationDataInput;
  locale: Locale;
};

export type AssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: InputMaybe<Array<AssetUpdateManyLocalizationInput>>;
};

export type AssetUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: AssetUpdateManyInput;
  /** Document search */
  where: AssetWhereInput;
};

export type AssetUpdateOneInlineInput = {
  /** Connect existing Asset document */
  connect?: InputMaybe<AssetWhereUniqueInput>;
  /** Create and connect one Asset document */
  create?: InputMaybe<AssetCreateInput>;
  /** Delete currently connected Asset document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Asset document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Asset document */
  update?: InputMaybe<AssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: InputMaybe<AssetUpsertWithNestedWhereUniqueInput>;
};

export type AssetUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: AssetUpdateInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

export type AssetUpsertInput = {
  /** Create document if it didn't exist */
  create: AssetCreateInput;
  /** Update document if it exists */
  update: AssetUpdateInput;
};

export type AssetUpsertLocalizationInput = {
  create: AssetCreateLocalizationDataInput;
  locale: Locale;
  update: AssetUpdateLocalizationDataInput;
};

export type AssetUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: AssetUpsertInput;
  /** Unique document search */
  where: AssetWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type AssetWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type AssetWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImageMiningNFTLPPageHeroSection_every?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  backgroundImageMiningNFTLPPageHeroSection_none?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  backgroundImageMiningNFTLPPageHeroSection_some?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  backgroundImageMiningNftAccessSection_every?: InputMaybe<MiningNftAccessSectionWhereInput>;
  backgroundImageMiningNftAccessSection_none?: InputMaybe<MiningNftAccessSectionWhereInput>;
  backgroundImageMiningNftAccessSection_some?: InputMaybe<MiningNftAccessSectionWhereInput>;
  backgroundImageMiningNftAntimonyAdvantageSection_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  backgroundImageMiningNftAntimonyAdvantageSection_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  backgroundImageMiningNftAntimonyAdvantageSection_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  backgroundImageMiningNftEligibilitySection_every?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
  backgroundImageMiningNftEligibilitySection_none?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
  backgroundImageMiningNftEligibilitySection_some?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
  backgroundImageMiningNftProfitRoiSection_every?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  backgroundImageMiningNftProfitRoiSection_none?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  backgroundImageMiningNftProfitRoiSection_some?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  backgroundImageMiningNftTeamSection_every?: InputMaybe<MiningNftTeamSectionWhereInput>;
  backgroundImageMiningNftTeamSection_none?: InputMaybe<MiningNftTeamSectionWhereInput>;
  backgroundImageMiningNftTeamSection_some?: InputMaybe<MiningNftTeamSectionWhereInput>;
  backgroundImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  backgroundImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  backgroundImagePageHero_every?: InputMaybe<PageHeroWhereInput>;
  backgroundImagePageHero_none?: InputMaybe<PageHeroWhereInput>;
  backgroundImagePageHero_some?: InputMaybe<PageHeroWhereInput>;
  chainNativeCurrencyIconChain_every?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_none?: InputMaybe<ChainWhereInput>;
  chainNativeCurrencyIconChain_some?: InputMaybe<ChainWhereInput>;
  collectionCategoryBannerNftCategoryDisplay_every?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryBannerNftCategoryDisplay_none?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryBannerNftCategoryDisplay_some?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryThumbnailNftCategoryDisplay_every?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryThumbnailNftCategoryDisplay_none?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCategoryThumbnailNftCategoryDisplay_some?: InputMaybe<NftCategoryDisplayWhereInput>;
  collectionCreatorBannerImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorBannerImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreatorThumbnailImageCollectionCreator_some?: InputMaybe<CollectionCreatorWhereInput>;
  collectionFeaturedImageNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionFeaturedImageNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionHeaderImageLargeNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  collectionSampleImagesMintingProject_every?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_none?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMintingProject_some?: InputMaybe<MintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionSampleImagesMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  collectionThumbnailNftCollection_every?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_none?: InputMaybe<NftCollectionWhereInput>;
  collectionThumbnailNftCollection_some?: InputMaybe<NftCollectionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<AssetWhereStageInput>;
  documentInStages_none?: InputMaybe<AssetWhereStageInput>;
  documentInStages_some?: InputMaybe<AssetWhereStageInput>;
  fileName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  fileName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  fileName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  fileName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  fileName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  fileName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  fileName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  fileName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  handle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  handle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  handle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  handle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  handle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  handle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  handle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  handle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  handle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  height?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  height_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  height_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  height_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  height_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  height_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  height_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  height_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  heroBannerImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  heroBannerImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  heroImageLeftMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageLeftMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  heroImageMiningNFTLPPageHeroSection_every?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  heroImageMiningNFTLPPageHeroSection_none?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  heroImageMiningNFTLPPageHeroSection_some?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  heroImageMintingStat_every?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_none?: InputMaybe<MintingStatWhereInput>;
  heroImageMintingStat_some?: InputMaybe<MintingStatWhereInput>;
  heroImagesPageHero_every?: InputMaybe<PageHeroWhereInput>;
  heroImagesPageHero_none?: InputMaybe<PageHeroWhereInput>;
  heroImagesPageHero_some?: InputMaybe<PageHeroWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  imageCtaSection_every?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_none?: InputMaybe<CtaSectionWhereInput>;
  imageCtaSection_some?: InputMaybe<CtaSectionWhereInput>;
  leftSideOverlayImageMiningNftProfitRoiSection_every?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  leftSideOverlayImageMiningNftProfitRoiSection_none?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  leftSideOverlayImageMiningNftProfitRoiSection_some?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  logoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  logoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  logoMiningNFTLPPageHeroSection_every?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  logoMiningNFTLPPageHeroSection_none?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  logoMiningNFTLPPageHeroSection_some?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  logoPoweredBySection_every?: InputMaybe<PoweredBySectionWhereInput>;
  logoPoweredBySection_none?: InputMaybe<PoweredBySectionWhereInput>;
  logoPoweredBySection_some?: InputMaybe<PoweredBySectionWhereInput>;
  menuGroupIconMenu_every?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_none?: InputMaybe<MenuWhereInput>;
  menuGroupIconMenu_some?: InputMaybe<MenuWhereInput>;
  mimeType?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mimeType_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mimeType_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mimeType_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  mimeType_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mimeType_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mimeType_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  oreImageMiningNftAntimonyAdvantageSection_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  oreImageMiningNftAntimonyAdvantageSection_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  oreImageMiningNftAntimonyAdvantageSection_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  periodicTableElementImageMiningNftAntimonyAdvantageSection_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
  projectionImageMiningBtcSupplyDemandModelSection_every?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
  projectionImageMiningBtcSupplyDemandModelSection_none?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
  projectionImageMiningBtcSupplyDemandModelSection_some?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  roiImageMiningNftProfitRoiSection_every?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  roiImageMiningNftProfitRoiSection_none?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  roiImageMiningNftProfitRoiSection_some?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoImageLandingPage_every?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_none?: InputMaybe<LandingPageWhereInput>;
  seoImageLandingPage_some?: InputMaybe<LandingPageWhereInput>;
  seoImageMultiChainMintingProject_every?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_none?: InputMaybe<MultiChainMintingProjectWhereInput>;
  seoImageMultiChainMintingProject_some?: InputMaybe<MultiChainMintingProjectWhereInput>;
  size?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  size_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  size_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  size_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  size_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  size_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  size_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  size_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  tokenIconMintingCurrency_every?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_none?: InputMaybe<MintingCurrencyWhereInput>;
  tokenIconMintingCurrency_some?: InputMaybe<MintingCurrencyWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  width?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  width_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  width_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  width_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  width_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  width_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  width_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  width_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type AssetWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<AssetWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<AssetWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<AssetWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Asset record uniquely */
export type AssetWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type BatchPayload = {
  __typename?: "BatchPayload";
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars["Long"]["output"];
};

export type Button = Entity & {
  __typename?: "Button";
  buttonLink?: Maybe<Scalars["String"]["output"]>;
  buttonText?: Maybe<Scalars["String"]["output"]>;
  buttonVariant?: Maybe<ButtonVariants>;
  externalLink?: Maybe<Scalars["Boolean"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
};

export type ButtonConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ButtonWhereUniqueInput;
};

/** A connection to a list of items. */
export type ButtonConnection = {
  __typename?: "ButtonConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ButtonEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ButtonCreateInput = {
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ButtonCreateManyInlineInput = {
  /** Create and connect multiple existing Button documents */
  create?: InputMaybe<Array<ButtonCreateInput>>;
};

export type ButtonCreateOneInlineInput = {
  /** Create and connect one Button document */
  create?: InputMaybe<ButtonCreateInput>;
};

export type ButtonCreateWithPositionInput = {
  /** Document to create */
  data: ButtonCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ButtonEdge = {
  __typename?: "ButtonEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Button;
};

/** Identifies documents */
export type ButtonManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  /** All values that are contained in given list. */
  buttonVariant_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonVariant_not?: InputMaybe<ButtonVariants>;
  /** All values that are not contained in given list. */
  buttonVariant_not_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum ButtonOrderByInput {
  buttonLink_ASC = "buttonLink_ASC",
  buttonLink_DESC = "buttonLink_DESC",
  buttonText_ASC = "buttonText_ASC",
  buttonText_DESC = "buttonText_DESC",
  buttonVariant_ASC = "buttonVariant_ASC",
  buttonVariant_DESC = "buttonVariant_DESC",
  externalLink_ASC = "externalLink_ASC",
  externalLink_DESC = "externalLink_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
}

export type ButtonParent =
  | CtaSection
  | MiningNftlpPageHeroSection
  | MintingStat
  | PageHero
  | PoweredBySection;

export type ButtonParentConnectInput = {
  CtaSection?: InputMaybe<CtaSectionConnectInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionConnectInput>;
  MintingStat?: InputMaybe<MintingStatConnectInput>;
  PageHero?: InputMaybe<PageHeroConnectInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionConnectInput>;
};

export type ButtonParentCreateInput = {
  CtaSection?: InputMaybe<CtaSectionCreateInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionCreateInput>;
  MintingStat?: InputMaybe<MintingStatCreateInput>;
  PageHero?: InputMaybe<PageHeroCreateInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionCreateInput>;
};

export type ButtonParentCreateManyInlineInput = {
  /** Connect multiple existing ButtonParent documents */
  connect?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Create and connect multiple existing ButtonParent documents */
  create?: InputMaybe<Array<ButtonParentCreateInput>>;
};

export type ButtonParentCreateOneInlineInput = {
  /** Connect one existing ButtonParent document */
  connect?: InputMaybe<ButtonParentWhereUniqueInput>;
  /** Create and connect one ButtonParent document */
  create?: InputMaybe<ButtonParentCreateInput>;
};

export type ButtonParentUpdateInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionUpdateInput>;
  MintingStat?: InputMaybe<MintingStatUpdateInput>;
  PageHero?: InputMaybe<PageHeroUpdateInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionUpdateInput>;
};

export type ButtonParentUpdateManyInlineInput = {
  /** Connect multiple existing ButtonParent documents */
  connect?: InputMaybe<Array<ButtonParentConnectInput>>;
  /** Create and connect multiple ButtonParent documents */
  create?: InputMaybe<Array<ButtonParentCreateInput>>;
  /** Delete multiple ButtonParent documents */
  delete?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Disconnect multiple ButtonParent documents */
  disconnect?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ButtonParent documents */
  set?: InputMaybe<Array<ButtonParentWhereUniqueInput>>;
  /** Update multiple ButtonParent documents */
  update?: InputMaybe<Array<ButtonParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ButtonParent documents */
  upsert?: InputMaybe<Array<ButtonParentUpsertWithNestedWhereUniqueInput>>;
};

export type ButtonParentUpdateManyWithNestedWhereInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateManyWithNestedWhereInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionUpdateManyWithNestedWhereInput>;
  MintingStat?: InputMaybe<MintingStatUpdateManyWithNestedWhereInput>;
  PageHero?: InputMaybe<PageHeroUpdateManyWithNestedWhereInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionUpdateManyWithNestedWhereInput>;
};

export type ButtonParentUpdateOneInlineInput = {
  /** Connect existing ButtonParent document */
  connect?: InputMaybe<ButtonParentWhereUniqueInput>;
  /** Create and connect one ButtonParent document */
  create?: InputMaybe<ButtonParentCreateInput>;
  /** Delete currently connected ButtonParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ButtonParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ButtonParent document */
  update?: InputMaybe<ButtonParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ButtonParent document */
  upsert?: InputMaybe<ButtonParentUpsertWithNestedWhereUniqueInput>;
};

export type ButtonParentUpdateWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateWithNestedWhereUniqueInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionUpdateWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpdateWithNestedWhereUniqueInput>;
  PageHero?: InputMaybe<PageHeroUpdateWithNestedWhereUniqueInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionUpdateWithNestedWhereUniqueInput>;
};

export type ButtonParentUpsertWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpsertWithNestedWhereUniqueInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionUpsertWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpsertWithNestedWhereUniqueInput>;
  PageHero?: InputMaybe<PageHeroUpsertWithNestedWhereUniqueInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionUpsertWithNestedWhereUniqueInput>;
};

export type ButtonParentWhereInput = {
  CtaSection?: InputMaybe<CtaSectionWhereInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
  MintingStat?: InputMaybe<MintingStatWhereInput>;
  PageHero?: InputMaybe<PageHeroWhereInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionWhereInput>;
};

export type ButtonParentWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionWhereUniqueInput>;
  MiningNFTLPPageHeroSection?: InputMaybe<MiningNftlpPageHeroSectionWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatWhereUniqueInput>;
  PageHero?: InputMaybe<PageHeroWhereUniqueInput>;
  PoweredBySection?: InputMaybe<PoweredBySectionWhereUniqueInput>;
};

export type ButtonUpdateInput = {
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ButtonUpdateManyInlineInput = {
  /** Create and connect multiple Button component instances */
  create?: InputMaybe<Array<ButtonCreateWithPositionInput>>;
  /** Delete multiple Button documents */
  delete?: InputMaybe<Array<ButtonWhereUniqueInput>>;
  /** Update multiple Button component instances */
  update?: InputMaybe<Array<ButtonUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Button component instances */
  upsert?: InputMaybe<Array<ButtonUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type ButtonUpdateManyInput = {
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ButtonUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ButtonUpdateManyInput;
  /** Document search */
  where: ButtonWhereInput;
};

export type ButtonUpdateOneInlineInput = {
  /** Create and connect one Button document */
  create?: InputMaybe<ButtonCreateInput>;
  /** Delete currently connected Button document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Button document */
  update?: InputMaybe<ButtonUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Button document */
  upsert?: InputMaybe<ButtonUpsertWithNestedWhereUniqueInput>;
};

export type ButtonUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ButtonUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ButtonWhereUniqueInput;
};

export type ButtonUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ButtonUpdateInput;
  /** Unique document search */
  where: ButtonWhereUniqueInput;
};

export type ButtonUpsertInput = {
  /** Create document if it didn't exist */
  create: ButtonCreateInput;
  /** Update document if it exists */
  update: ButtonUpdateInput;
};

export type ButtonUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ButtonUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ButtonWhereUniqueInput;
};

export type ButtonUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ButtonUpsertInput;
  /** Unique document search */
  where: ButtonWhereUniqueInput;
};

export enum ButtonVariants {
  accent = "accent",
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
}

/** Identifies documents */
export type ButtonWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ButtonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ButtonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  buttonLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonLink_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  buttonVariant?: InputMaybe<ButtonVariants>;
  /** All values that are contained in given list. */
  buttonVariant_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonVariant_not?: InputMaybe<ButtonVariants>;
  /** All values that are not contained in given list. */
  buttonVariant_not_in?: InputMaybe<Array<InputMaybe<ButtonVariants>>>;
  externalLink?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

/** References Button record uniquely */
export type ButtonWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Model for EVM compatible blockchains. */
export type Chain = Entity &
  Node & {
    __typename?: "Chain";
    chainEnum?: Maybe<ChainIDs>;
    chainExplorerUrl?: Maybe<Scalars["String"]["output"]>;
    chainId?: Maybe<Scalars["Int"]["output"]>;
    chainName?: Maybe<Scalars["String"]["output"]>;
    chainNativeCurrencyIcon?: Maybe<Asset>;
    chainNativeCurrencySymbol?: Maybe<Scalars["String"]["output"]>;
    chainRpcEndpoint?: Maybe<Scalars["String"]["output"]>;
    chainSlug: Scalars["String"]["output"];
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Chain>;
    /** List of Chain versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    marketplaceContracts: Array<MarketplaceContract>;
    mintingCurrencies: Array<MintingCurrency>;
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Model for EVM compatible blockchains. */
export type ChainChainNativeCurrencyIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for EVM compatible blockchains. */
export type ChainCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for EVM compatible blockchains. */
export type ChainDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Model for EVM compatible blockchains. */
export type ChainHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

/** Model for EVM compatible blockchains. */
export type ChainMarketplaceContractsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MarketplaceContractOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MarketplaceContractWhereInput>;
};

/** Model for EVM compatible blockchains. */
export type ChainMintingCurrenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

/** Model for EVM compatible blockchains. */
export type ChainNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

/** Model for EVM compatible blockchains. */
export type ChainPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Model for EVM compatible blockchains. */
export type ChainScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Model for EVM compatible blockchains. */
export type ChainUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ChainConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ChainWhereUniqueInput;
};

/** A connection to a list of items. */
export type ChainConnection = {
  __typename?: "ChainConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ChainEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ChainCreateInput = {
  chainEnum?: InputMaybe<ChainIDs>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetCreateOneInlineInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug: Scalars["String"]["input"];
  clp92noyz2tix01upaefshqzg?: InputMaybe<MultiChainMintingContractCreateManyInlineInput>;
  clp95p3t734g801upf4e1239k?: InputMaybe<MultiChainCreditCardLinkCreateManyInlineInput>;
  clqn9hmqcn5rf01uhb2j5he81?: InputMaybe<LatestListingsQueryCreateManyInlineInput>;
  clqnyprarpmi501t8251ieox7?: InputMaybe<MarketplacePageComponentCreateManyInlineInput>;
  clqnyrusypnpp01uha9wscl5h?: InputMaybe<TrendingCollectionsCarouselCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  marketplaceContracts?: InputMaybe<MarketplaceContractCreateManyInlineInput>;
  mintingCurrencies?: InputMaybe<MintingCurrencyCreateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ChainCreateManyInlineInput = {
  /** Connect multiple existing Chain documents */
  connect?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Create and connect multiple existing Chain documents */
  create?: InputMaybe<Array<ChainCreateInput>>;
};

export type ChainCreateOneInlineInput = {
  /** Connect one existing Chain document */
  connect?: InputMaybe<ChainWhereUniqueInput>;
  /** Create and connect one Chain document */
  create?: InputMaybe<ChainCreateInput>;
};

/** An edge in a connection. */
export type ChainEdge = {
  __typename?: "ChainEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Chain;
};

/** chain IDs */
export enum ChainIDs {
  ARBITRUM = "ARBITRUM",
  ARBITRUM_NOVA = "ARBITRUM_NOVA",
  ARBITRUM_ONE = "ARBITRUM_ONE",
  AVALANCHE = "AVALANCHE",
  AVAX = "AVAX",
  BASE = "BASE",
  BSC = "BSC",
  ETH = "ETH",
  ETHEREUM = "ETHEREUM",
  FANTOM = "FANTOM",
  LINEA = "LINEA",
  OPTIMISM = "OPTIMISM",
  POLYGON = "POLYGON",
  POLYGON_ZKEVM = "POLYGON_ZKEVM",
  SCROLL = "SCROLL",
  ZKSYNC_ERA = "ZKSYNC_ERA",
  ZORA = "ZORA",
}

/** Identifies documents */
export type ChainManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ChainWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chainEnum?: InputMaybe<ChainIDs>;
  /** All values that are contained in given list. */
  chainEnum_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainEnum_not?: InputMaybe<ChainIDs>;
  /** All values that are not contained in given list. */
  chainEnum_not_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainExplorerUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainExplorerUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainExplorerUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainExplorerUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainExplorerUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainExplorerUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainExplorerUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainExplorerUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainExplorerUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  chainId_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  chainId_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  chainId_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  chainId_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  chainId_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  chainId_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  chainId_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetWhereInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainNativeCurrencySymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainNativeCurrencySymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainNativeCurrencySymbol_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainNativeCurrencySymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainNativeCurrencySymbol_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  chainNativeCurrencySymbol_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  chainNativeCurrencySymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainNativeCurrencySymbol_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  chainNativeCurrencySymbol_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainRpcEndpoint_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainRpcEndpoint_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainRpcEndpoint_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainRpcEndpoint_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainRpcEndpoint_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainRpcEndpoint_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainRpcEndpoint_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainRpcEndpoint_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainRpcEndpoint_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ChainWhereStageInput>;
  documentInStages_none?: InputMaybe<ChainWhereStageInput>;
  documentInStages_some?: InputMaybe<ChainWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  marketplaceContracts_every?: InputMaybe<MarketplaceContractWhereInput>;
  marketplaceContracts_none?: InputMaybe<MarketplaceContractWhereInput>;
  marketplaceContracts_some?: InputMaybe<MarketplaceContractWhereInput>;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ChainOrderByInput {
  chainEnum_ASC = "chainEnum_ASC",
  chainEnum_DESC = "chainEnum_DESC",
  chainExplorerUrl_ASC = "chainExplorerUrl_ASC",
  chainExplorerUrl_DESC = "chainExplorerUrl_DESC",
  chainId_ASC = "chainId_ASC",
  chainId_DESC = "chainId_DESC",
  chainName_ASC = "chainName_ASC",
  chainName_DESC = "chainName_DESC",
  chainNativeCurrencySymbol_ASC = "chainNativeCurrencySymbol_ASC",
  chainNativeCurrencySymbol_DESC = "chainNativeCurrencySymbol_DESC",
  chainRpcEndpoint_ASC = "chainRpcEndpoint_ASC",
  chainRpcEndpoint_DESC = "chainRpcEndpoint_DESC",
  chainSlug_ASC = "chainSlug_ASC",
  chainSlug_DESC = "chainSlug_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type ChainUpdateInput = {
  chainEnum?: InputMaybe<ChainIDs>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
  clp92noyz2tix01upaefshqzg?: InputMaybe<MultiChainMintingContractUpdateManyInlineInput>;
  clp95p3t734g801upf4e1239k?: InputMaybe<MultiChainCreditCardLinkUpdateManyInlineInput>;
  clqn9hmqcn5rf01uhb2j5he81?: InputMaybe<LatestListingsQueryUpdateManyInlineInput>;
  clqnyprarpmi501t8251ieox7?: InputMaybe<MarketplacePageComponentUpdateManyInlineInput>;
  clqnyrusypnpp01uha9wscl5h?: InputMaybe<TrendingCollectionsCarouselUpdateManyInlineInput>;
  marketplaceContracts?: InputMaybe<MarketplaceContractUpdateManyInlineInput>;
  mintingCurrencies?: InputMaybe<MintingCurrencyUpdateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
};

export type ChainUpdateManyInlineInput = {
  /** Connect multiple existing Chain documents */
  connect?: InputMaybe<Array<ChainConnectInput>>;
  /** Create and connect multiple Chain documents */
  create?: InputMaybe<Array<ChainCreateInput>>;
  /** Delete multiple Chain documents */
  delete?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Disconnect multiple Chain documents */
  disconnect?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Chain documents */
  set?: InputMaybe<Array<ChainWhereUniqueInput>>;
  /** Update multiple Chain documents */
  update?: InputMaybe<Array<ChainUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Chain documents */
  upsert?: InputMaybe<Array<ChainUpsertWithNestedWhereUniqueInput>>;
};

export type ChainUpdateManyInput = {
  chainEnum?: InputMaybe<ChainIDs>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChainUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ChainUpdateManyInput;
  /** Document search */
  where: ChainWhereInput;
};

export type ChainUpdateOneInlineInput = {
  /** Connect existing Chain document */
  connect?: InputMaybe<ChainWhereUniqueInput>;
  /** Create and connect one Chain document */
  create?: InputMaybe<ChainCreateInput>;
  /** Delete currently connected Chain document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Chain document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Chain document */
  update?: InputMaybe<ChainUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Chain document */
  upsert?: InputMaybe<ChainUpsertWithNestedWhereUniqueInput>;
};

export type ChainUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ChainUpdateInput;
  /** Unique document search */
  where: ChainWhereUniqueInput;
};

export type ChainUpsertInput = {
  /** Create document if it didn't exist */
  create: ChainCreateInput;
  /** Update document if it exists */
  update: ChainUpdateInput;
};

export type ChainUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ChainUpsertInput;
  /** Unique document search */
  where: ChainWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type ChainWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type ChainWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ChainWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ChainWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chainEnum?: InputMaybe<ChainIDs>;
  /** All values that are contained in given list. */
  chainEnum_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainEnum_not?: InputMaybe<ChainIDs>;
  /** All values that are not contained in given list. */
  chainEnum_not_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainExplorerUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainExplorerUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainExplorerUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainExplorerUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainExplorerUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainExplorerUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainExplorerUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainExplorerUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainExplorerUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  chainId_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  chainId_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  chainId_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  chainId_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  chainId_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  chainId_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  chainId_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainNativeCurrencyIcon?: InputMaybe<AssetWhereInput>;
  chainNativeCurrencySymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainNativeCurrencySymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainNativeCurrencySymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainNativeCurrencySymbol_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainNativeCurrencySymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainNativeCurrencySymbol_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  chainNativeCurrencySymbol_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  chainNativeCurrencySymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainNativeCurrencySymbol_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  chainNativeCurrencySymbol_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainRpcEndpoint_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainRpcEndpoint_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainRpcEndpoint_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  chainRpcEndpoint_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainRpcEndpoint_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainRpcEndpoint_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainRpcEndpoint_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  chainRpcEndpoint_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainRpcEndpoint_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chainSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  chainSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  chainSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  chainSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  chainSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  chainSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  chainSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  chainSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  chainSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  chainSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<ChainWhereStageInput>;
  documentInStages_none?: InputMaybe<ChainWhereStageInput>;
  documentInStages_some?: InputMaybe<ChainWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  marketplaceContracts_every?: InputMaybe<MarketplaceContractWhereInput>;
  marketplaceContracts_none?: InputMaybe<MarketplaceContractWhereInput>;
  marketplaceContracts_some?: InputMaybe<MarketplaceContractWhereInput>;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type ChainWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ChainWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ChainWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ChainWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<ChainWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Chain record uniquely */
export type ChainWhereUniqueInput = {
  chainExplorerUrl?: InputMaybe<Scalars["String"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
  chainName?: InputMaybe<Scalars["String"]["input"]>;
  chainRpcEndpoint?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum CollectionCategories {
  alpha_project = "alpha_project",
  avatars = "avatars",
  coming_soon = "coming_soon",
  defi = "defi",
  featured = "featured",
  featured_mint = "featured_mint",
  fine_art = "fine_art",
  gamefi = "gamefi",
  launchpad = "launchpad",
  metaverse = "metaverse",
  mining = "mining",
  music = "music",
  utility = "utility",
}

export type CollectionCreator = Entity &
  Node & {
    __typename?: "CollectionCreator";
    /** What types of collections does this creator make? */
    categories: Array<CollectionCategories>;
    /**
     * This image is currently shown when configured as the SEO image.
     *
     * It should be 1200 X 630 pixels for best effect.
     */
    collectionCreatorBannerImage?: Maybe<Asset>;
    collectionCreatorBio?: Maybe<Scalars["String"]["output"]>;
    collectionCreatorName?: Maybe<Scalars["String"]["output"]>;
    collectionCreatorSlug?: Maybe<Scalars["String"]["output"]>;
    collectionCreatorThumbnailImage?: Maybe<Asset>;
    collectionCreatorUrl?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CollectionCreator>;
    /** List of CollectionCreator versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    mintingProjects: Array<MintingProject>;
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type CollectionCreatorCollectionCreatorBannerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorCollectionCreatorThumbnailImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type CollectionCreatorHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type CollectionCreatorMintingProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectWhereInput>;
};

export type CollectionCreatorNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type CollectionCreatorPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CollectionCreatorUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CollectionCreatorConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CollectionCreatorWhereUniqueInput;
};

/** A connection to a list of items. */
export type CollectionCreatorConnection = {
  __typename?: "CollectionCreatorConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CollectionCreatorEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CollectionCreatorCreateInput = {
  categories?: InputMaybe<Array<CollectionCategories>>;
  clrnt6xvj0gs201ka90e03qai?: InputMaybe<FeaturedCreatorGroupCreateManyInlineInput>;
  collectionCreatorBannerImage?: InputMaybe<AssetCreateOneInlineInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetCreateOneInlineInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintingProjects?: InputMaybe<MintingProjectCreateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CollectionCreatorCreateManyInlineInput = {
  /** Connect multiple existing CollectionCreator documents */
  connect?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Create and connect multiple existing CollectionCreator documents */
  create?: InputMaybe<Array<CollectionCreatorCreateInput>>;
};

export type CollectionCreatorCreateOneInlineInput = {
  /** Connect one existing CollectionCreator document */
  connect?: InputMaybe<CollectionCreatorWhereUniqueInput>;
  /** Create and connect one CollectionCreator document */
  create?: InputMaybe<CollectionCreatorCreateInput>;
};

/** An edge in a connection. */
export type CollectionCreatorEdge = {
  __typename?: "CollectionCreatorEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: CollectionCreator;
};

/** Identifies documents */
export type CollectionCreatorManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  categories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  categories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  categories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  categories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  categories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionCreatorBannerImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorBio_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorSlug_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorSlug_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_none?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_some?: InputMaybe<CollectionCreatorWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingProjects_every?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_none?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_some?: InputMaybe<MintingProjectWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CollectionCreatorOrderByInput {
  categories_ASC = "categories_ASC",
  categories_DESC = "categories_DESC",
  collectionCreatorBio_ASC = "collectionCreatorBio_ASC",
  collectionCreatorBio_DESC = "collectionCreatorBio_DESC",
  collectionCreatorName_ASC = "collectionCreatorName_ASC",
  collectionCreatorName_DESC = "collectionCreatorName_DESC",
  collectionCreatorSlug_ASC = "collectionCreatorSlug_ASC",
  collectionCreatorSlug_DESC = "collectionCreatorSlug_DESC",
  collectionCreatorUrl_ASC = "collectionCreatorUrl_ASC",
  collectionCreatorUrl_DESC = "collectionCreatorUrl_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type CollectionCreatorUpdateInput = {
  categories?: InputMaybe<Array<CollectionCategories>>;
  clrnt6xvj0gs201ka90e03qai?: InputMaybe<FeaturedCreatorGroupUpdateManyInlineInput>;
  collectionCreatorBannerImage?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  mintingProjects?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
};

export type CollectionCreatorUpdateManyInlineInput = {
  /** Connect multiple existing CollectionCreator documents */
  connect?: InputMaybe<Array<CollectionCreatorConnectInput>>;
  /** Create and connect multiple CollectionCreator documents */
  create?: InputMaybe<Array<CollectionCreatorCreateInput>>;
  /** Delete multiple CollectionCreator documents */
  delete?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Disconnect multiple CollectionCreator documents */
  disconnect?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CollectionCreator documents */
  set?: InputMaybe<Array<CollectionCreatorWhereUniqueInput>>;
  /** Update multiple CollectionCreator documents */
  update?: InputMaybe<Array<CollectionCreatorUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CollectionCreator documents */
  upsert?: InputMaybe<Array<CollectionCreatorUpsertWithNestedWhereUniqueInput>>;
};

export type CollectionCreatorUpdateManyInput = {
  categories?: InputMaybe<Array<CollectionCategories>>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionCreatorUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CollectionCreatorUpdateManyInput;
  /** Document search */
  where: CollectionCreatorWhereInput;
};

export type CollectionCreatorUpdateOneInlineInput = {
  /** Connect existing CollectionCreator document */
  connect?: InputMaybe<CollectionCreatorWhereUniqueInput>;
  /** Create and connect one CollectionCreator document */
  create?: InputMaybe<CollectionCreatorCreateInput>;
  /** Delete currently connected CollectionCreator document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected CollectionCreator document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CollectionCreator document */
  update?: InputMaybe<CollectionCreatorUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CollectionCreator document */
  upsert?: InputMaybe<CollectionCreatorUpsertWithNestedWhereUniqueInput>;
};

export type CollectionCreatorUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CollectionCreatorUpdateInput;
  /** Unique document search */
  where: CollectionCreatorWhereUniqueInput;
};

export type CollectionCreatorUpsertInput = {
  /** Create document if it didn't exist */
  create: CollectionCreatorCreateInput;
  /** Update document if it exists */
  update: CollectionCreatorUpdateInput;
};

export type CollectionCreatorUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CollectionCreatorUpsertInput;
  /** Unique document search */
  where: CollectionCreatorWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CollectionCreatorWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type CollectionCreatorWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionCreatorWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  categories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  categories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  categories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  categories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  categories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionCreatorBannerImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorBio_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorSlug_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorSlug_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionCreatorSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorThumbnailImage?: InputMaybe<AssetWhereInput>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionCreatorUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionCreatorUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionCreatorUrl_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionCreatorUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionCreatorUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionCreatorUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionCreatorUrl_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionCreatorUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionCreatorUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_none?: InputMaybe<CollectionCreatorWhereStageInput>;
  documentInStages_some?: InputMaybe<CollectionCreatorWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mintingProjects_every?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_none?: InputMaybe<MintingProjectWhereInput>;
  mintingProjects_some?: InputMaybe<MintingProjectWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CollectionCreatorWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionCreatorWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionCreatorWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionCreatorWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CollectionCreatorWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CollectionCreator record uniquely */
export type CollectionCreatorWhereUniqueInput = {
  collectionCreatorName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreatorUrl?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CollectionInfoTable = Entity & {
  __typename?: "CollectionInfoTable";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
  value?: Maybe<Scalars["String"]["output"]>;
};

export type CollectionInfoTableConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CollectionInfoTableWhereUniqueInput;
};

/** A connection to a list of items. */
export type CollectionInfoTableConnection = {
  __typename?: "CollectionInfoTableConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CollectionInfoTableEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CollectionInfoTableCreateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionInfoTableCreateManyInlineInput = {
  /** Create and connect multiple existing CollectionInfoTable documents */
  create?: InputMaybe<Array<CollectionInfoTableCreateInput>>;
};

export type CollectionInfoTableCreateOneInlineInput = {
  /** Create and connect one CollectionInfoTable document */
  create?: InputMaybe<CollectionInfoTableCreateInput>;
};

export type CollectionInfoTableCreateWithPositionInput = {
  /** Document to create */
  data: CollectionInfoTableCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type CollectionInfoTableEdge = {
  __typename?: "CollectionInfoTableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: CollectionInfoTable;
};

/** Identifies documents */
export type CollectionInfoTableManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum CollectionInfoTableOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  value_ASC = "value_ASC",
  value_DESC = "value_DESC",
}

export type CollectionInfoTableParent =
  | MintingProject
  | MultiChainMintingProject;

export type CollectionInfoTableParentConnectInput = {
  MintingProject?: InputMaybe<MintingProjectConnectInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type CollectionInfoTableParentCreateInput = {
  MintingProject?: InputMaybe<MintingProjectCreateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type CollectionInfoTableParentCreateManyInlineInput = {
  /** Connect multiple existing CollectionInfoTableParent documents */
  connect?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Create and connect multiple existing CollectionInfoTableParent documents */
  create?: InputMaybe<Array<CollectionInfoTableParentCreateInput>>;
};

export type CollectionInfoTableParentCreateOneInlineInput = {
  /** Connect one existing CollectionInfoTableParent document */
  connect?: InputMaybe<CollectionInfoTableParentWhereUniqueInput>;
  /** Create and connect one CollectionInfoTableParent document */
  create?: InputMaybe<CollectionInfoTableParentCreateInput>;
};

export type CollectionInfoTableParentUpdateInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type CollectionInfoTableParentUpdateManyInlineInput = {
  /** Connect multiple existing CollectionInfoTableParent documents */
  connect?: InputMaybe<Array<CollectionInfoTableParentConnectInput>>;
  /** Create and connect multiple CollectionInfoTableParent documents */
  create?: InputMaybe<Array<CollectionInfoTableParentCreateInput>>;
  /** Delete multiple CollectionInfoTableParent documents */
  delete?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Disconnect multiple CollectionInfoTableParent documents */
  disconnect?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CollectionInfoTableParent documents */
  set?: InputMaybe<Array<CollectionInfoTableParentWhereUniqueInput>>;
  /** Update multiple CollectionInfoTableParent documents */
  update?: InputMaybe<
    Array<CollectionInfoTableParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple CollectionInfoTableParent documents */
  upsert?: InputMaybe<
    Array<CollectionInfoTableParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type CollectionInfoTableParentUpdateManyWithNestedWhereInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateManyWithNestedWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type CollectionInfoTableParentUpdateOneInlineInput = {
  /** Connect existing CollectionInfoTableParent document */
  connect?: InputMaybe<CollectionInfoTableParentWhereUniqueInput>;
  /** Create and connect one CollectionInfoTableParent document */
  create?: InputMaybe<CollectionInfoTableParentCreateInput>;
  /** Delete currently connected CollectionInfoTableParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected CollectionInfoTableParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CollectionInfoTableParent document */
  update?: InputMaybe<CollectionInfoTableParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CollectionInfoTableParent document */
  upsert?: InputMaybe<CollectionInfoTableParentUpsertWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableParentUpdateWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableParentUpsertWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableParentWhereInput = {
  MintingProject?: InputMaybe<MintingProjectWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type CollectionInfoTableParentWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type CollectionInfoTableUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionInfoTableUpdateManyInlineInput = {
  /** Create and connect multiple CollectionInfoTable component instances */
  create?: InputMaybe<Array<CollectionInfoTableCreateWithPositionInput>>;
  /** Delete multiple CollectionInfoTable documents */
  delete?: InputMaybe<Array<CollectionInfoTableWhereUniqueInput>>;
  /** Update multiple CollectionInfoTable component instances */
  update?: InputMaybe<
    Array<CollectionInfoTableUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CollectionInfoTable component instances */
  upsert?: InputMaybe<
    Array<CollectionInfoTableUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CollectionInfoTableUpdateManyInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
};

export type CollectionInfoTableUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CollectionInfoTableUpdateManyInput;
  /** Document search */
  where: CollectionInfoTableWhereInput;
};

export type CollectionInfoTableUpdateOneInlineInput = {
  /** Create and connect one CollectionInfoTable document */
  create?: InputMaybe<CollectionInfoTableCreateInput>;
  /** Delete currently connected CollectionInfoTable document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CollectionInfoTable document */
  update?: InputMaybe<CollectionInfoTableUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CollectionInfoTable document */
  upsert?: InputMaybe<CollectionInfoTableUpsertWithNestedWhereUniqueInput>;
};

export type CollectionInfoTableUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<CollectionInfoTableUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CollectionInfoTableWhereUniqueInput;
};

export type CollectionInfoTableUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CollectionInfoTableUpdateInput;
  /** Unique document search */
  where: CollectionInfoTableWhereUniqueInput;
};

export type CollectionInfoTableUpsertInput = {
  /** Create document if it didn't exist */
  create: CollectionInfoTableCreateInput;
  /** Update document if it exists */
  update: CollectionInfoTableUpdateInput;
};

export type CollectionInfoTableUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<CollectionInfoTableUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: CollectionInfoTableWhereUniqueInput;
};

export type CollectionInfoTableUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CollectionInfoTableUpsertInput;
  /** Unique document search */
  where: CollectionInfoTableWhereUniqueInput;
};

/** Identifies documents */
export type CollectionInfoTableWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CollectionInfoTableWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  value_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  value_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  value_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  value_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  value_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  value_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  value_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  value_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  value_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References CollectionInfoTable record uniquely */
export type CollectionInfoTableWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type Color = {
  __typename?: "Color";
  css: Scalars["String"]["output"];
  hex: Scalars["Hex"]["output"];
  rgba: Rgba;
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type ColorInput = {
  hex?: InputMaybe<Scalars["Hex"]["input"]>;
  rgba?: InputMaybe<RgbaInput>;
};

export type ColoredText = Entity & {
  __typename?: "ColoredText";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
  tag?: Maybe<Tags>;
  text?: Maybe<Scalars["String"]["output"]>;
  textColor?: Maybe<Colors>;
};

export type ColoredTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ColoredTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type ColoredTextConnection = {
  __typename?: "ColoredTextConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ColoredTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ColoredTextCreateInput = {
  tag?: InputMaybe<Tags>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
};

export type ColoredTextCreateManyInlineInput = {
  /** Create and connect multiple existing ColoredText documents */
  create?: InputMaybe<Array<ColoredTextCreateInput>>;
};

export type ColoredTextCreateOneInlineInput = {
  /** Create and connect one ColoredText document */
  create?: InputMaybe<ColoredTextCreateInput>;
};

export type ColoredTextCreateWithPositionInput = {
  /** Document to create */
  data: ColoredTextCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ColoredTextEdge = {
  __typename?: "ColoredTextEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ColoredText;
};

/** Identifies documents */
export type ColoredTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  tag?: InputMaybe<Tags>;
  /** All values that are contained in given list. */
  tag_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  /** Any other value that exists and is not equal to the given value. */
  tag_not?: InputMaybe<Tags>;
  /** All values that are not contained in given list. */
  tag_not_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  textColor_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  textColor_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  textColor_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ColoredTextOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  tag_ASC = "tag_ASC",
  tag_DESC = "tag_DESC",
  textColor_ASC = "textColor_ASC",
  textColor_DESC = "textColor_DESC",
  text_ASC = "text_ASC",
  text_DESC = "text_DESC",
}

export type ColoredTextParent = CtaSection | MintingStat | PageHero;

export type ColoredTextParentConnectInput = {
  CtaSection?: InputMaybe<CtaSectionConnectInput>;
  MintingStat?: InputMaybe<MintingStatConnectInput>;
  PageHero?: InputMaybe<PageHeroConnectInput>;
};

export type ColoredTextParentCreateInput = {
  CtaSection?: InputMaybe<CtaSectionCreateInput>;
  MintingStat?: InputMaybe<MintingStatCreateInput>;
  PageHero?: InputMaybe<PageHeroCreateInput>;
};

export type ColoredTextParentCreateManyInlineInput = {
  /** Connect multiple existing ColoredTextParent documents */
  connect?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Create and connect multiple existing ColoredTextParent documents */
  create?: InputMaybe<Array<ColoredTextParentCreateInput>>;
};

export type ColoredTextParentCreateOneInlineInput = {
  /** Connect one existing ColoredTextParent document */
  connect?: InputMaybe<ColoredTextParentWhereUniqueInput>;
  /** Create and connect one ColoredTextParent document */
  create?: InputMaybe<ColoredTextParentCreateInput>;
};

export type ColoredTextParentUpdateInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateInput>;
  MintingStat?: InputMaybe<MintingStatUpdateInput>;
  PageHero?: InputMaybe<PageHeroUpdateInput>;
};

export type ColoredTextParentUpdateManyInlineInput = {
  /** Connect multiple existing ColoredTextParent documents */
  connect?: InputMaybe<Array<ColoredTextParentConnectInput>>;
  /** Create and connect multiple ColoredTextParent documents */
  create?: InputMaybe<Array<ColoredTextParentCreateInput>>;
  /** Delete multiple ColoredTextParent documents */
  delete?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Disconnect multiple ColoredTextParent documents */
  disconnect?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ColoredTextParent documents */
  set?: InputMaybe<Array<ColoredTextParentWhereUniqueInput>>;
  /** Update multiple ColoredTextParent documents */
  update?: InputMaybe<Array<ColoredTextParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ColoredTextParent documents */
  upsert?: InputMaybe<Array<ColoredTextParentUpsertWithNestedWhereUniqueInput>>;
};

export type ColoredTextParentUpdateManyWithNestedWhereInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateManyWithNestedWhereInput>;
  MintingStat?: InputMaybe<MintingStatUpdateManyWithNestedWhereInput>;
  PageHero?: InputMaybe<PageHeroUpdateManyWithNestedWhereInput>;
};

export type ColoredTextParentUpdateOneInlineInput = {
  /** Connect existing ColoredTextParent document */
  connect?: InputMaybe<ColoredTextParentWhereUniqueInput>;
  /** Create and connect one ColoredTextParent document */
  create?: InputMaybe<ColoredTextParentCreateInput>;
  /** Delete currently connected ColoredTextParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ColoredTextParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ColoredTextParent document */
  update?: InputMaybe<ColoredTextParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ColoredTextParent document */
  upsert?: InputMaybe<ColoredTextParentUpsertWithNestedWhereUniqueInput>;
};

export type ColoredTextParentUpdateWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpdateWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpdateWithNestedWhereUniqueInput>;
  PageHero?: InputMaybe<PageHeroUpdateWithNestedWhereUniqueInput>;
};

export type ColoredTextParentUpsertWithNestedWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionUpsertWithNestedWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatUpsertWithNestedWhereUniqueInput>;
  PageHero?: InputMaybe<PageHeroUpsertWithNestedWhereUniqueInput>;
};

export type ColoredTextParentWhereInput = {
  CtaSection?: InputMaybe<CtaSectionWhereInput>;
  MintingStat?: InputMaybe<MintingStatWhereInput>;
  PageHero?: InputMaybe<PageHeroWhereInput>;
};

export type ColoredTextParentWhereUniqueInput = {
  CtaSection?: InputMaybe<CtaSectionWhereUniqueInput>;
  MintingStat?: InputMaybe<MintingStatWhereUniqueInput>;
  PageHero?: InputMaybe<PageHeroWhereUniqueInput>;
};

export type ColoredTextUpdateInput = {
  tag?: InputMaybe<Tags>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
};

export type ColoredTextUpdateManyInlineInput = {
  /** Create and connect multiple ColoredText component instances */
  create?: InputMaybe<Array<ColoredTextCreateWithPositionInput>>;
  /** Delete multiple ColoredText documents */
  delete?: InputMaybe<Array<ColoredTextWhereUniqueInput>>;
  /** Update multiple ColoredText component instances */
  update?: InputMaybe<
    Array<ColoredTextUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ColoredText component instances */
  upsert?: InputMaybe<
    Array<ColoredTextUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ColoredTextUpdateManyInput = {
  tag?: InputMaybe<Tags>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
};

export type ColoredTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ColoredTextUpdateManyInput;
  /** Document search */
  where: ColoredTextWhereInput;
};

export type ColoredTextUpdateOneInlineInput = {
  /** Create and connect one ColoredText document */
  create?: InputMaybe<ColoredTextCreateInput>;
  /** Delete currently connected ColoredText document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ColoredText document */
  update?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ColoredText document */
  upsert?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueInput>;
};

export type ColoredTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ColoredTextUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ColoredTextWhereUniqueInput;
};

export type ColoredTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ColoredTextUpdateInput;
  /** Unique document search */
  where: ColoredTextWhereUniqueInput;
};

export type ColoredTextUpsertInput = {
  /** Create document if it didn't exist */
  create: ColoredTextCreateInput;
  /** Update document if it exists */
  update: ColoredTextUpdateInput;
};

export type ColoredTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ColoredTextUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ColoredTextWhereUniqueInput;
};

export type ColoredTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ColoredTextUpsertInput;
  /** Unique document search */
  where: ColoredTextWhereUniqueInput;
};

/** Identifies documents */
export type ColoredTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ColoredTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  tag?: InputMaybe<Tags>;
  /** All values that are contained in given list. */
  tag_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  /** Any other value that exists and is not equal to the given value. */
  tag_not?: InputMaybe<Tags>;
  /** All values that are not contained in given list. */
  tag_not_in?: InputMaybe<Array<InputMaybe<Tags>>>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  textColor?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  textColor_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  textColor_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  textColor_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References ColoredText record uniquely */
export type ColoredTextWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum Colors {
  accent = "accent",
  black = "black",
  dark = "dark",
  gray = "gray",
  primary = "primary",
  secondary = "secondary",
  tertiary = "tertiary",
  white = "white",
}

export enum ComponentName {
  LatestListingsCarousel = "LatestListingsCarousel",
  TopCollection = "TopCollection",
  TrendingCollectionsCarousel = "TrendingCollectionsCarousel",
  TrendingCollectionsGrid = "TrendingCollectionsGrid",
  TrendingCollectionsTable = "TrendingCollectionsTable",
}

export type ConnectPositionInput = {
  /** Connect document after specified document */
  after?: InputMaybe<Scalars["ID"]["input"]>;
  /** Connect document before specified document */
  before?: InputMaybe<Scalars["ID"]["input"]>;
  /** Connect document at last position */
  end?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Connect document at first position */
  start?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CtaSection = Entity &
  Node & {
    __typename?: "CtaSection";
    backgroundFrom?: Maybe<Colors>;
    backgroundTo?: Maybe<Colors>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<CtaSection>;
    /** List of CtaSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    image?: Maybe<Asset>;
    mainButton?: Maybe<Button>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    text: Array<CtaSectiontextUnion>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type CtaSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type CtaSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type CtaSectionImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionMainButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type CtaSectionTextArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CtaSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type CtaSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: CtaSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type CtaSectionConnection = {
  __typename?: "CtaSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<CtaSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type CtaSectionCreateInput = {
  backgroundFrom?: InputMaybe<Colors>;
  backgroundTo?: InputMaybe<Colors>;
  cl4j5yie74k0l01yrcnvpe01m?: InputMaybe<PageCreateManyInlineInput>;
  cl80hlc9r07g801t316j76s3i?: InputMaybe<MintingProjectCreateManyInlineInput>;
  clpbort4i7heq01ui3tch3e14?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  clupv7ul401b008k54hvfdpjm?: InputMaybe<LandingPageCreateManyInlineInput>;
  clusr3adf0j2n07ixcgyh0sj6?: InputMaybe<LandingPageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  mainButton?: InputMaybe<ButtonCreateOneInlineInput>;
  text?: InputMaybe<CtaSectiontextUnionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type CtaSectionCreateManyInlineInput = {
  /** Connect multiple existing CtaSection documents */
  connect?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Create and connect multiple existing CtaSection documents */
  create?: InputMaybe<Array<CtaSectionCreateInput>>;
};

export type CtaSectionCreateOneInlineInput = {
  /** Connect one existing CtaSection document */
  connect?: InputMaybe<CtaSectionWhereUniqueInput>;
  /** Create and connect one CtaSection document */
  create?: InputMaybe<CtaSectionCreateInput>;
};

/** An edge in a connection. */
export type CtaSectionEdge = {
  __typename?: "CtaSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: CtaSection;
};

/** Identifies documents */
export type CtaSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundFrom?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundFrom_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundFrom_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundFrom_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  backgroundTo?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundTo_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundTo_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundTo_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<CtaSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  mainButton?: InputMaybe<ButtonWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** All values in which the union is empty. */
  text_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  text_some?: InputMaybe<CtaSectiontextUnionWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum CtaSectionOrderByInput {
  backgroundFrom_ASC = "backgroundFrom_ASC",
  backgroundFrom_DESC = "backgroundFrom_DESC",
  backgroundTo_ASC = "backgroundTo_ASC",
  backgroundTo_DESC = "backgroundTo_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type CtaSectionUpdateInput = {
  backgroundFrom?: InputMaybe<Colors>;
  backgroundTo?: InputMaybe<Colors>;
  cl4j5yie74k0l01yrcnvpe01m?: InputMaybe<PageUpdateManyInlineInput>;
  cl80hlc9r07g801t316j76s3i?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  clpbort4i7heq01ui3tch3e14?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  clupv7ul401b008k54hvfdpjm?: InputMaybe<LandingPageUpdateManyInlineInput>;
  clusr3adf0j2n07ixcgyh0sj6?: InputMaybe<LandingPageUpdateManyInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  mainButton?: InputMaybe<ButtonUpdateOneInlineInput>;
  text?: InputMaybe<CtaSectiontextUnionUpdateManyInlineInput>;
};

export type CtaSectionUpdateManyInlineInput = {
  /** Connect multiple existing CtaSection documents */
  connect?: InputMaybe<Array<CtaSectionConnectInput>>;
  /** Create and connect multiple CtaSection documents */
  create?: InputMaybe<Array<CtaSectionCreateInput>>;
  /** Delete multiple CtaSection documents */
  delete?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Disconnect multiple CtaSection documents */
  disconnect?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing CtaSection documents */
  set?: InputMaybe<Array<CtaSectionWhereUniqueInput>>;
  /** Update multiple CtaSection documents */
  update?: InputMaybe<Array<CtaSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CtaSection documents */
  upsert?: InputMaybe<Array<CtaSectionUpsertWithNestedWhereUniqueInput>>;
};

export type CtaSectionUpdateManyInput = {
  backgroundFrom?: InputMaybe<Colors>;
  backgroundTo?: InputMaybe<Colors>;
};

export type CtaSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: CtaSectionUpdateManyInput;
  /** Document search */
  where: CtaSectionWhereInput;
};

export type CtaSectionUpdateOneInlineInput = {
  /** Connect existing CtaSection document */
  connect?: InputMaybe<CtaSectionWhereUniqueInput>;
  /** Create and connect one CtaSection document */
  create?: InputMaybe<CtaSectionCreateInput>;
  /** Delete currently connected CtaSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected CtaSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CtaSection document */
  update?: InputMaybe<CtaSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CtaSection document */
  upsert?: InputMaybe<CtaSectionUpsertWithNestedWhereUniqueInput>;
};

export type CtaSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: CtaSectionUpdateInput;
  /** Unique document search */
  where: CtaSectionWhereUniqueInput;
};

export type CtaSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: CtaSectionCreateInput;
  /** Update document if it exists */
  update: CtaSectionUpdateInput;
};

export type CtaSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: CtaSectionUpsertInput;
  /** Unique document search */
  where: CtaSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type CtaSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type CtaSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CtaSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundFrom?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundFrom_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundFrom_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundFrom_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  backgroundTo?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundTo_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundTo_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundTo_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<CtaSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<CtaSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  mainButton?: InputMaybe<ButtonWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  /** All values in which the union is empty. */
  text_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  text_some?: InputMaybe<CtaSectiontextUnionWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type CtaSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<CtaSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<CtaSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<CtaSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<CtaSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References CtaSection record uniquely */
export type CtaSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CtaSectiontextUnion = ColoredText;

export type CtaSectiontextUnionConnectInput = {
  ColoredText?: InputMaybe<ColoredTextConnectInput>;
};

export type CtaSectiontextUnionCreateInput = {
  ColoredText?: InputMaybe<ColoredTextCreateInput>;
};

export type CtaSectiontextUnionCreateManyInlineInput = {
  /** Create and connect multiple existing CtaSectiontextUnion documents */
  create?: InputMaybe<Array<CtaSectiontextUnionCreateInput>>;
};

export type CtaSectiontextUnionCreateOneInlineInput = {
  /** Create and connect one CtaSectiontextUnion document */
  create?: InputMaybe<CtaSectiontextUnionCreateInput>;
};

export type CtaSectiontextUnionCreateWithPositionInput = {
  ColoredText?: InputMaybe<ColoredTextCreateWithPositionInput>;
};

export type CtaSectiontextUnionUpdateInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateInput>;
};

export type CtaSectiontextUnionUpdateManyInlineInput = {
  /** Create and connect multiple CtaSectiontextUnion component instances */
  create?: InputMaybe<Array<CtaSectiontextUnionCreateWithPositionInput>>;
  /** Delete multiple CtaSectiontextUnion documents */
  delete?: InputMaybe<Array<CtaSectiontextUnionWhereUniqueInput>>;
  /** Update multiple CtaSectiontextUnion component instances */
  update?: InputMaybe<
    Array<CtaSectiontextUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple CtaSectiontextUnion component instances */
  upsert?: InputMaybe<
    Array<CtaSectiontextUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type CtaSectiontextUnionUpdateManyWithNestedWhereInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateManyWithNestedWhereInput>;
};

export type CtaSectiontextUnionUpdateOneInlineInput = {
  /** Create and connect one CtaSectiontextUnion document */
  create?: InputMaybe<CtaSectiontextUnionCreateInput>;
  /** Delete currently connected CtaSectiontextUnion document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single CtaSectiontextUnion document */
  update?: InputMaybe<CtaSectiontextUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CtaSectiontextUnion document */
  upsert?: InputMaybe<CtaSectiontextUnionUpsertWithNestedWhereUniqueInput>;
};

export type CtaSectiontextUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type CtaSectiontextUnionUpdateWithNestedWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueInput>;
};

export type CtaSectiontextUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  ColoredText?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type CtaSectiontextUnionUpsertWithNestedWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueInput>;
};

export type CtaSectiontextUnionWhereInput = {
  ColoredText?: InputMaybe<ColoredTextWhereInput>;
};

export type CtaSectiontextUnionWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextWhereUniqueInput>;
};

export enum Currencies {
  ALPHA = "ALPHA",
  AVAX = "AVAX",
  BUSD = "BUSD",
  ETHER = "ETHER",
  USDC = "USDC",
  USDCe = "USDCe",
  USDT = "USDT",
  WAVAX = "WAVAX",
  WBNB = "WBNB",
  WETH = "WETH",
  WFTM = "WFTM",
  WMATIC = "WMATIC",
}

export enum DocumentFileTypes {
  doc = "doc",
  docx = "docx",
  html = "html",
  jpg = "jpg",
  odp = "odp",
  ods = "ods",
  odt = "odt",
  pdf = "pdf",
  png = "png",
  ppt = "ppt",
  pptx = "pptx",
  svg = "svg",
  txt = "txt",
  webp = "webp",
  xls = "xls",
  xlsx = "xlsx",
}

export type DocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: InputMaybe<DocumentFileTypes>;
};

/** Transformations for Documents */
export type DocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: InputMaybe<DocumentOutputInput>;
};

export type DocumentVersion = {
  __typename?: "DocumentVersion";
  createdAt: Scalars["DateTime"]["output"];
  data?: Maybe<Scalars["Json"]["output"]>;
  id: Scalars["ID"]["output"];
  revision: Scalars["Int"]["output"];
  stage: Stage;
};

/** An object with an ID */
export type Entity = {
  /** The id of the object. */
  id: Scalars["ID"]["output"];
  /** The Stage of an object */
  stage: Stage;
};

/** This enumeration holds all typenames that implement the Entity interface. Components and models implement the Entity interface. */
export enum EntityTypeName {
  AlphaNftCollectionTier = "AlphaNftCollectionTier",
  AlphaNftTier = "AlphaNftTier",
  AlphaSharesInformation = "AlphaSharesInformation",
  /** Asset system model */
  Asset = "Asset",
  Button = "Button",
  /** Model for EVM compatible blockchains. */
  Chain = "Chain",
  CollectionCreator = "CollectionCreator",
  CollectionInfoTable = "CollectionInfoTable",
  ColoredText = "ColoredText",
  CtaSection = "CtaSection",
  FAQSection = "FAQSection",
  Faq = "Faq",
  FeaturedCollectionGroup = "FeaturedCollectionGroup",
  /** Set up an array of featured creators in a specified order for display on the marketplace. */
  FeaturedCreatorGroup = "FeaturedCreatorGroup",
  GettingStartedSection = "GettingStartedSection",
  ImageWithAltTextAndLink = "ImageWithAltTextAndLink",
  InfoCard = "InfoCard",
  InfoCardsSection = "InfoCardsSection",
  LandingPage = "LandingPage",
  LargeIconHeading = "LargeIconHeading",
  /** Select the chain and collections to display latest listings from */
  LatestListingsByCollectionAndChainId = "LatestListingsByCollectionAndChainId",
  LatestListingsQuery = "LatestListingsQuery",
  MarketplaceAnnouncementBanner = "MarketplaceAnnouncementBanner",
  MarketplaceContract = "MarketplaceContract",
  MarketplacePage = "MarketplacePage",
  MarketplacePageComponent = "MarketplacePageComponent",
  Menu = "Menu",
  MiningBtcSupplyDemandModelSection = "MiningBtcSupplyDemandModelSection",
  MiningContainer = "MiningContainer",
  MiningHeaderNotice = "MiningHeaderNotice",
  MiningNFTLPPageHeroSection = "MiningNFTLPPageHeroSection",
  MiningNftAccessSection = "MiningNftAccessSection",
  MiningNftAdvantageSection = "MiningNftAdvantageSection",
  MiningNftAntimonyAdvantageSection = "MiningNftAntimonyAdvantageSection",
  MiningNftComparison = "MiningNftComparison",
  MiningNftComparisonTable = "MiningNftComparisonTable",
  MiningNftEligibilitySection = "MiningNftEligibilitySection",
  MiningNftProfitRoiSection = "MiningNftProfitRoiSection",
  MiningNftTeamSection = "MiningNftTeamSection",
  MiningNftWhitelistManualEntry = "MiningNftWhitelistManualEntry",
  MiningNftfaqSection = "MiningNftfaqSection",
  MintingCurrency = "MintingCurrency",
  MintingProject = "MintingProject",
  MintingStat = "MintingStat",
  MultiChainCreditCardLink = "MultiChainCreditCardLink",
  MultiChainMintingContract = "MultiChainMintingContract",
  MultiChainMintingProject = "MultiChainMintingProject",
  NavigationItem = "NavigationItem",
  NftCategoryDisplay = "NftCategoryDisplay",
  NftCollection = "NftCollection",
  Page = "Page",
  PageHero = "PageHero",
  PartnerSection = "PartnerSection",
  PerksSection = "PerksSection",
  PoweredBySection = "PoweredBySection",
  PupsPresaleCommunity = "PupsPresaleCommunity",
  RichTextImageRow = "RichTextImageRow",
  Roadmap = "Roadmap",
  RoadmapItem = "RoadmapItem",
  /** Scheduled Operation system model */
  ScheduledOperation = "ScheduledOperation",
  /** Scheduled Release system model */
  ScheduledRelease = "ScheduledRelease",
  SecurityInfo = "SecurityInfo",
  SimpleLink = "SimpleLink",
  SmallIconHeadingText = "SmallIconHeadingText",
  TeamMemberImageNameTitle = "TeamMemberImageNameTitle",
  TeamSection = "TeamSection",
  TrendingCollectionsCarousel = "TrendingCollectionsCarousel",
  /** User system model */
  User = "User",
  /**
   * Top line is a heading in white
   * Second line is information in accent text
   */
  ValueProposition = "ValueProposition",
  WhitelistedAddress = "WhitelistedAddress",
  YoutubeVideo = "YoutubeVideo",
}

/** Allows to specify input to query models and components directly */
export type EntityWhereInput = {
  /** The ID of an object */
  id: Scalars["ID"]["input"];
  locale?: InputMaybe<Locale>;
  stage: Stage;
  /** The Type name of an object */
  typename: EntityTypeName;
};

export type FaqSection = Entity &
  Node & {
    __typename?: "FAQSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<FaqSection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of FAQSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    questions: Array<Faq>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type FaqSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FaqSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type FaqSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type FaqSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FaqSectionQuestionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type FaqSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type FaqSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FaqSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FaqSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type FaqSectionConnection = {
  __typename?: "FAQSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FaqSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FaqSectionCreateInput = {
  cl4j4cnh64io001yr80fxbpn6?: InputMaybe<PageCreateManyInlineInput>;
  cl4n0tv8h8bz301yra637bcv3?: InputMaybe<PageCreateManyInlineInput>;
  cl83951bc0oze01ujgne6dg9h?: InputMaybe<MintingProjectCreateManyInlineInput>;
  clpboqtd17lkl01t6hy7g7us0?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<FaqCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FaqSectionCreateManyInlineInput = {
  /** Connect multiple existing FAQSection documents */
  connect?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Create and connect multiple existing FAQSection documents */
  create?: InputMaybe<Array<FaqSectionCreateInput>>;
};

export type FaqSectionCreateOneInlineInput = {
  /** Connect one existing FAQSection document */
  connect?: InputMaybe<FaqSectionWhereUniqueInput>;
  /** Create and connect one FAQSection document */
  create?: InputMaybe<FaqSectionCreateInput>;
};

/** An edge in a connection. */
export type FaqSectionEdge = {
  __typename?: "FAQSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: FaqSection;
};

/** Identifies documents */
export type FaqSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<FaqSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  questions_every?: InputMaybe<FaqWhereInput>;
  questions_none?: InputMaybe<FaqWhereInput>;
  questions_some?: InputMaybe<FaqWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FaqSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type FaqSectionUpdateInput = {
  cl4j4cnh64io001yr80fxbpn6?: InputMaybe<PageUpdateManyInlineInput>;
  cl4n0tv8h8bz301yra637bcv3?: InputMaybe<PageUpdateManyInlineInput>;
  cl83951bc0oze01ujgne6dg9h?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  clpboqtd17lkl01t6hy7g7us0?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  questions?: InputMaybe<FaqUpdateManyInlineInput>;
};

export type FaqSectionUpdateManyInlineInput = {
  /** Connect multiple existing FAQSection documents */
  connect?: InputMaybe<Array<FaqSectionConnectInput>>;
  /** Create and connect multiple FAQSection documents */
  create?: InputMaybe<Array<FaqSectionCreateInput>>;
  /** Delete multiple FAQSection documents */
  delete?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Disconnect multiple FAQSection documents */
  disconnect?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FAQSection documents */
  set?: InputMaybe<Array<FaqSectionWhereUniqueInput>>;
  /** Update multiple FAQSection documents */
  update?: InputMaybe<Array<FaqSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FAQSection documents */
  upsert?: InputMaybe<Array<FaqSectionUpsertWithNestedWhereUniqueInput>>;
};

export type FaqSectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FaqSectionUpdateManyInput;
  /** Document search */
  where: FaqSectionWhereInput;
};

export type FaqSectionUpdateOneInlineInput = {
  /** Connect existing FAQSection document */
  connect?: InputMaybe<FaqSectionWhereUniqueInput>;
  /** Create and connect one FAQSection document */
  create?: InputMaybe<FaqSectionCreateInput>;
  /** Delete currently connected FAQSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FAQSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FAQSection document */
  update?: InputMaybe<FaqSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FAQSection document */
  upsert?: InputMaybe<FaqSectionUpsertWithNestedWhereUniqueInput>;
};

export type FaqSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FaqSectionUpdateInput;
  /** Unique document search */
  where: FaqSectionWhereUniqueInput;
};

export type FaqSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: FaqSectionCreateInput;
  /** Update document if it exists */
  update: FaqSectionUpdateInput;
};

export type FaqSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FaqSectionUpsertInput;
  /** Unique document search */
  where: FaqSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FaqSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type FaqSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<FaqSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<FaqSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  questions_every?: InputMaybe<FaqWhereInput>;
  questions_none?: InputMaybe<FaqWhereInput>;
  questions_some?: InputMaybe<FaqWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FaqSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FaqSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FAQSection record uniquely */
export type FaqSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Faq = Entity & {
  __typename?: "Faq";
  answer?: Maybe<Scalars["String"]["output"]>;
  answerRichText?: Maybe<RichText>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  question?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type FaqConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FaqWhereUniqueInput;
};

/** A connection to a list of items. */
export type FaqConnection = {
  __typename?: "FaqConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FaqEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FaqCreateInput = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerRichText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqCreateManyInlineInput = {
  /** Create and connect multiple existing Faq documents */
  create?: InputMaybe<Array<FaqCreateInput>>;
};

export type FaqCreateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: InputMaybe<FaqCreateInput>;
};

export type FaqCreateWithPositionInput = {
  /** Document to create */
  data: FaqCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type FaqEdge = {
  __typename?: "FaqEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Faq;
};

/** Identifies documents */
export type FaqManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  answer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  answer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  answer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  answer_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  answer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  answer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  answer_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  answer_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  answer_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  answer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  question_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  question_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  question_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  question_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  question_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  question_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  question_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum FaqOrderByInput {
  answer_ASC = "answer_ASC",
  answer_DESC = "answer_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  question_ASC = "question_ASC",
  question_DESC = "question_DESC",
}

export type FaqParent =
  | FaqSection
  | LandingPage
  | MiningNftfaqSection
  | MintingProject
  | MultiChainMintingProject;

export type FaqParentConnectInput = {
  FAQSection?: InputMaybe<FaqSectionConnectInput>;
  LandingPage?: InputMaybe<LandingPageConnectInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionConnectInput>;
  MintingProject?: InputMaybe<MintingProjectConnectInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type FaqParentCreateInput = {
  FAQSection?: InputMaybe<FaqSectionCreateInput>;
  LandingPage?: InputMaybe<LandingPageCreateInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionCreateInput>;
  MintingProject?: InputMaybe<MintingProjectCreateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type FaqParentCreateManyInlineInput = {
  /** Connect multiple existing FaqParent documents */
  connect?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Create and connect multiple existing FaqParent documents */
  create?: InputMaybe<Array<FaqParentCreateInput>>;
};

export type FaqParentCreateOneInlineInput = {
  /** Connect one existing FaqParent document */
  connect?: InputMaybe<FaqParentWhereUniqueInput>;
  /** Create and connect one FaqParent document */
  create?: InputMaybe<FaqParentCreateInput>;
};

export type FaqParentUpdateInput = {
  FAQSection?: InputMaybe<FaqSectionUpdateInput>;
  LandingPage?: InputMaybe<LandingPageUpdateInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionUpdateInput>;
  MintingProject?: InputMaybe<MintingProjectUpdateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type FaqParentUpdateManyInlineInput = {
  /** Connect multiple existing FaqParent documents */
  connect?: InputMaybe<Array<FaqParentConnectInput>>;
  /** Create and connect multiple FaqParent documents */
  create?: InputMaybe<Array<FaqParentCreateInput>>;
  /** Delete multiple FaqParent documents */
  delete?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Disconnect multiple FaqParent documents */
  disconnect?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FaqParent documents */
  set?: InputMaybe<Array<FaqParentWhereUniqueInput>>;
  /** Update multiple FaqParent documents */
  update?: InputMaybe<Array<FaqParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FaqParent documents */
  upsert?: InputMaybe<Array<FaqParentUpsertWithNestedWhereUniqueInput>>;
};

export type FaqParentUpdateManyWithNestedWhereInput = {
  FAQSection?: InputMaybe<FaqSectionUpdateManyWithNestedWhereInput>;
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionUpdateManyWithNestedWhereInput>;
  MintingProject?: InputMaybe<MintingProjectUpdateManyWithNestedWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type FaqParentUpdateOneInlineInput = {
  /** Connect existing FaqParent document */
  connect?: InputMaybe<FaqParentWhereUniqueInput>;
  /** Create and connect one FaqParent document */
  create?: InputMaybe<FaqParentCreateInput>;
  /** Delete currently connected FaqParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FaqParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FaqParent document */
  update?: InputMaybe<FaqParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FaqParent document */
  upsert?: InputMaybe<FaqParentUpsertWithNestedWhereUniqueInput>;
};

export type FaqParentUpdateWithNestedWhereUniqueInput = {
  FAQSection?: InputMaybe<FaqSectionUpdateWithNestedWhereUniqueInput>;
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionUpdateWithNestedWhereUniqueInput>;
  MintingProject?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type FaqParentUpsertWithNestedWhereUniqueInput = {
  FAQSection?: InputMaybe<FaqSectionUpsertWithNestedWhereUniqueInput>;
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionUpsertWithNestedWhereUniqueInput>;
  MintingProject?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type FaqParentWhereInput = {
  FAQSection?: InputMaybe<FaqSectionWhereInput>;
  LandingPage?: InputMaybe<LandingPageWhereInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionWhereInput>;
  MintingProject?: InputMaybe<MintingProjectWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type FaqParentWhereUniqueInput = {
  FAQSection?: InputMaybe<FaqSectionWhereUniqueInput>;
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>;
  MiningNftfaqSection?: InputMaybe<MiningNftfaqSectionWhereUniqueInput>;
  MintingProject?: InputMaybe<MintingProjectWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type FaqUpdateInput = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerRichText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqUpdateManyInlineInput = {
  /** Create and connect multiple Faq component instances */
  create?: InputMaybe<Array<FaqCreateWithPositionInput>>;
  /** Delete multiple Faq documents */
  delete?: InputMaybe<Array<FaqWhereUniqueInput>>;
  /** Update multiple Faq component instances */
  update?: InputMaybe<Array<FaqUpdateWithNestedWhereUniqueAndPositionInput>>;
  /** Upsert multiple Faq component instances */
  upsert?: InputMaybe<Array<FaqUpsertWithNestedWhereUniqueAndPositionInput>>;
};

export type FaqUpdateManyInput = {
  answer?: InputMaybe<Scalars["String"]["input"]>;
  answerRichText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
};

export type FaqUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FaqUpdateManyInput;
  /** Document search */
  where: FaqWhereInput;
};

export type FaqUpdateOneInlineInput = {
  /** Create and connect one Faq document */
  create?: InputMaybe<FaqCreateInput>;
  /** Delete currently connected Faq document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Faq document */
  update?: InputMaybe<FaqUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Faq document */
  upsert?: InputMaybe<FaqUpsertWithNestedWhereUniqueInput>;
};

export type FaqUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<FaqUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FaqWhereUniqueInput;
};

export type FaqUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FaqUpdateInput;
  /** Unique document search */
  where: FaqWhereUniqueInput;
};

export type FaqUpsertInput = {
  /** Create document if it didn't exist */
  create: FaqCreateInput;
  /** Update document if it exists */
  update: FaqUpdateInput;
};

export type FaqUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<FaqUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: FaqWhereUniqueInput;
};

export type FaqUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FaqUpsertInput;
  /** Unique document search */
  where: FaqWhereUniqueInput;
};

/** Identifies documents */
export type FaqWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FaqWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FaqWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  answer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  answer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  answer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  answer_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  answer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  answer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  answer_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  answer_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  answer_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  answer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  question?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  question_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  question_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  question_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  question_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  question_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  question_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  question_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  question_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  question_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References Faq record uniquely */
export type FaqWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type FeaturedCollectionGroup = Entity &
  Node & {
    __typename?: "FeaturedCollectionGroup";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<FeaturedCollectionGroup>;
    groupName?: Maybe<Scalars["String"]["output"]>;
    /** List of FeaturedCollectionGroup versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type FeaturedCollectionGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCollectionGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type FeaturedCollectionGroupHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type FeaturedCollectionGroupNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type FeaturedCollectionGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCollectionGroupScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type FeaturedCollectionGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCollectionGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FeaturedCollectionGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type FeaturedCollectionGroupConnection = {
  __typename?: "FeaturedCollectionGroupConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FeaturedCollectionGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FeaturedCollectionGroupCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FeaturedCollectionGroupCreateManyInlineInput = {
  /** Connect multiple existing FeaturedCollectionGroup documents */
  connect?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Create and connect multiple existing FeaturedCollectionGroup documents */
  create?: InputMaybe<Array<FeaturedCollectionGroupCreateInput>>;
};

export type FeaturedCollectionGroupCreateOneInlineInput = {
  /** Connect one existing FeaturedCollectionGroup document */
  connect?: InputMaybe<FeaturedCollectionGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCollectionGroup document */
  create?: InputMaybe<FeaturedCollectionGroupCreateInput>;
};

/** An edge in a connection. */
export type FeaturedCollectionGroupEdge = {
  __typename?: "FeaturedCollectionGroupEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: FeaturedCollectionGroup;
};

/** Identifies documents */
export type FeaturedCollectionGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FeaturedCollectionGroupOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  groupName_ASC = "groupName_ASC",
  groupName_DESC = "groupName_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type FeaturedCollectionGroupUpdateInput = {
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
};

export type FeaturedCollectionGroupUpdateManyInlineInput = {
  /** Connect multiple existing FeaturedCollectionGroup documents */
  connect?: InputMaybe<Array<FeaturedCollectionGroupConnectInput>>;
  /** Create and connect multiple FeaturedCollectionGroup documents */
  create?: InputMaybe<Array<FeaturedCollectionGroupCreateInput>>;
  /** Delete multiple FeaturedCollectionGroup documents */
  delete?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Disconnect multiple FeaturedCollectionGroup documents */
  disconnect?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FeaturedCollectionGroup documents */
  set?: InputMaybe<Array<FeaturedCollectionGroupWhereUniqueInput>>;
  /** Update multiple FeaturedCollectionGroup documents */
  update?: InputMaybe<
    Array<FeaturedCollectionGroupUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FeaturedCollectionGroup documents */
  upsert?: InputMaybe<
    Array<FeaturedCollectionGroupUpsertWithNestedWhereUniqueInput>
  >;
};

export type FeaturedCollectionGroupUpdateManyInput = {
  groupName?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeaturedCollectionGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FeaturedCollectionGroupUpdateManyInput;
  /** Document search */
  where: FeaturedCollectionGroupWhereInput;
};

export type FeaturedCollectionGroupUpdateOneInlineInput = {
  /** Connect existing FeaturedCollectionGroup document */
  connect?: InputMaybe<FeaturedCollectionGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCollectionGroup document */
  create?: InputMaybe<FeaturedCollectionGroupCreateInput>;
  /** Delete currently connected FeaturedCollectionGroup document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FeaturedCollectionGroup document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FeaturedCollectionGroup document */
  update?: InputMaybe<FeaturedCollectionGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FeaturedCollectionGroup document */
  upsert?: InputMaybe<FeaturedCollectionGroupUpsertWithNestedWhereUniqueInput>;
};

export type FeaturedCollectionGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FeaturedCollectionGroupUpdateInput;
  /** Unique document search */
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type FeaturedCollectionGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: FeaturedCollectionGroupCreateInput;
  /** Update document if it exists */
  update: FeaturedCollectionGroupUpdateInput;
};

export type FeaturedCollectionGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FeaturedCollectionGroupUpsertInput;
  /** Unique document search */
  where: FeaturedCollectionGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FeaturedCollectionGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type FeaturedCollectionGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCollectionGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCollectionGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FeaturedCollectionGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCollectionGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCollectionGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCollectionGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FeaturedCollectionGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FeaturedCollectionGroup record uniquely */
export type FeaturedCollectionGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroup = Entity &
  Node & {
    __typename?: "FeaturedCreatorGroup";
    /** Add featured creators here and specify order to be displayed. */
    collectionCreators: Array<CollectionCreator>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<FeaturedCreatorGroup>;
    groupName?: Maybe<Scalars["String"]["output"]>;
    /** List of FeaturedCreatorGroup versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupCollectionCreatorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Set up an array of featured creators in a specified order for display on the marketplace. */
export type FeaturedCreatorGroupUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type FeaturedCreatorGroupConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: FeaturedCreatorGroupWhereUniqueInput;
};

/** A connection to a list of items. */
export type FeaturedCreatorGroupConnection = {
  __typename?: "FeaturedCreatorGroupConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<FeaturedCreatorGroupEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type FeaturedCreatorGroupCreateInput = {
  collectionCreators?: InputMaybe<CollectionCreatorCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type FeaturedCreatorGroupCreateManyInlineInput = {
  /** Connect multiple existing FeaturedCreatorGroup documents */
  connect?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Create and connect multiple existing FeaturedCreatorGroup documents */
  create?: InputMaybe<Array<FeaturedCreatorGroupCreateInput>>;
};

export type FeaturedCreatorGroupCreateOneInlineInput = {
  /** Connect one existing FeaturedCreatorGroup document */
  connect?: InputMaybe<FeaturedCreatorGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCreatorGroup document */
  create?: InputMaybe<FeaturedCreatorGroupCreateInput>;
};

/** An edge in a connection. */
export type FeaturedCreatorGroupEdge = {
  __typename?: "FeaturedCreatorGroupEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: FeaturedCreatorGroup;
};

/** Identifies documents */
export type FeaturedCreatorGroupManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreators_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_some?: InputMaybe<CollectionCreatorWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum FeaturedCreatorGroupOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  groupName_ASC = "groupName_ASC",
  groupName_DESC = "groupName_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type FeaturedCreatorGroupUpdateInput = {
  collectionCreators?: InputMaybe<CollectionCreatorUpdateManyInlineInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeaturedCreatorGroupUpdateManyInlineInput = {
  /** Connect multiple existing FeaturedCreatorGroup documents */
  connect?: InputMaybe<Array<FeaturedCreatorGroupConnectInput>>;
  /** Create and connect multiple FeaturedCreatorGroup documents */
  create?: InputMaybe<Array<FeaturedCreatorGroupCreateInput>>;
  /** Delete multiple FeaturedCreatorGroup documents */
  delete?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Disconnect multiple FeaturedCreatorGroup documents */
  disconnect?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing FeaturedCreatorGroup documents */
  set?: InputMaybe<Array<FeaturedCreatorGroupWhereUniqueInput>>;
  /** Update multiple FeaturedCreatorGroup documents */
  update?: InputMaybe<
    Array<FeaturedCreatorGroupUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple FeaturedCreatorGroup documents */
  upsert?: InputMaybe<
    Array<FeaturedCreatorGroupUpsertWithNestedWhereUniqueInput>
  >;
};

export type FeaturedCreatorGroupUpdateManyInput = {
  groupName?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeaturedCreatorGroupUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: FeaturedCreatorGroupUpdateManyInput;
  /** Document search */
  where: FeaturedCreatorGroupWhereInput;
};

export type FeaturedCreatorGroupUpdateOneInlineInput = {
  /** Connect existing FeaturedCreatorGroup document */
  connect?: InputMaybe<FeaturedCreatorGroupWhereUniqueInput>;
  /** Create and connect one FeaturedCreatorGroup document */
  create?: InputMaybe<FeaturedCreatorGroupCreateInput>;
  /** Delete currently connected FeaturedCreatorGroup document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected FeaturedCreatorGroup document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single FeaturedCreatorGroup document */
  update?: InputMaybe<FeaturedCreatorGroupUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FeaturedCreatorGroup document */
  upsert?: InputMaybe<FeaturedCreatorGroupUpsertWithNestedWhereUniqueInput>;
};

export type FeaturedCreatorGroupUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: FeaturedCreatorGroupUpdateInput;
  /** Unique document search */
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type FeaturedCreatorGroupUpsertInput = {
  /** Create document if it didn't exist */
  create: FeaturedCreatorGroupCreateInput;
  /** Update document if it exists */
  update: FeaturedCreatorGroupUpdateInput;
};

export type FeaturedCreatorGroupUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: FeaturedCreatorGroupUpsertInput;
  /** Unique document search */
  where: FeaturedCreatorGroupWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type FeaturedCreatorGroupWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type FeaturedCreatorGroupWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCreatorGroupWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreators_every?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_none?: InputMaybe<CollectionCreatorWhereInput>;
  collectionCreators_some?: InputMaybe<CollectionCreatorWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_none?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  documentInStages_some?: InputMaybe<FeaturedCreatorGroupWhereStageInput>;
  groupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  groupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  groupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  groupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  groupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  groupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  groupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  groupName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  groupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  groupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type FeaturedCreatorGroupWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<FeaturedCreatorGroupWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<FeaturedCreatorGroupWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<FeaturedCreatorGroupWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<FeaturedCreatorGroupWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References FeaturedCreatorGroup record uniquely */
export type FeaturedCreatorGroupWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type GettingStartedSection = Entity &
  Node & {
    __typename?: "GettingStartedSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<GettingStartedSection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of GettingStartedSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sections: Array<InfoCard>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type GettingStartedSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type GettingStartedSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type GettingStartedSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type GettingStartedSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type GettingStartedSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type GettingStartedSectionSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<InfoCardOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<InfoCardWhereInput>;
};

export type GettingStartedSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type GettingStartedSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: GettingStartedSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type GettingStartedSectionConnection = {
  __typename?: "GettingStartedSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<GettingStartedSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type GettingStartedSectionCreateInput = {
  cl4j4a09d4jmd01xo6nqreoa4?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<InfoCardCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type GettingStartedSectionCreateManyInlineInput = {
  /** Connect multiple existing GettingStartedSection documents */
  connect?: InputMaybe<Array<GettingStartedSectionWhereUniqueInput>>;
  /** Create and connect multiple existing GettingStartedSection documents */
  create?: InputMaybe<Array<GettingStartedSectionCreateInput>>;
};

export type GettingStartedSectionCreateOneInlineInput = {
  /** Connect one existing GettingStartedSection document */
  connect?: InputMaybe<GettingStartedSectionWhereUniqueInput>;
  /** Create and connect one GettingStartedSection document */
  create?: InputMaybe<GettingStartedSectionCreateInput>;
};

/** An edge in a connection. */
export type GettingStartedSectionEdge = {
  __typename?: "GettingStartedSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: GettingStartedSection;
};

/** Identifies documents */
export type GettingStartedSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GettingStartedSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GettingStartedSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GettingStartedSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GettingStartedSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<GettingStartedSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<GettingStartedSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sections_every?: InputMaybe<InfoCardWhereInput>;
  sections_none?: InputMaybe<InfoCardWhereInput>;
  sections_some?: InputMaybe<InfoCardWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum GettingStartedSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type GettingStartedSectionUpdateInput = {
  cl4j4a09d4jmd01xo6nqreoa4?: InputMaybe<PageUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  sections?: InputMaybe<InfoCardUpdateManyInlineInput>;
};

export type GettingStartedSectionUpdateManyInlineInput = {
  /** Connect multiple existing GettingStartedSection documents */
  connect?: InputMaybe<Array<GettingStartedSectionConnectInput>>;
  /** Create and connect multiple GettingStartedSection documents */
  create?: InputMaybe<Array<GettingStartedSectionCreateInput>>;
  /** Delete multiple GettingStartedSection documents */
  delete?: InputMaybe<Array<GettingStartedSectionWhereUniqueInput>>;
  /** Disconnect multiple GettingStartedSection documents */
  disconnect?: InputMaybe<Array<GettingStartedSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing GettingStartedSection documents */
  set?: InputMaybe<Array<GettingStartedSectionWhereUniqueInput>>;
  /** Update multiple GettingStartedSection documents */
  update?: InputMaybe<
    Array<GettingStartedSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple GettingStartedSection documents */
  upsert?: InputMaybe<
    Array<GettingStartedSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type GettingStartedSectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
};

export type GettingStartedSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: GettingStartedSectionUpdateManyInput;
  /** Document search */
  where: GettingStartedSectionWhereInput;
};

export type GettingStartedSectionUpdateOneInlineInput = {
  /** Connect existing GettingStartedSection document */
  connect?: InputMaybe<GettingStartedSectionWhereUniqueInput>;
  /** Create and connect one GettingStartedSection document */
  create?: InputMaybe<GettingStartedSectionCreateInput>;
  /** Delete currently connected GettingStartedSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected GettingStartedSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single GettingStartedSection document */
  update?: InputMaybe<GettingStartedSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single GettingStartedSection document */
  upsert?: InputMaybe<GettingStartedSectionUpsertWithNestedWhereUniqueInput>;
};

export type GettingStartedSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: GettingStartedSectionUpdateInput;
  /** Unique document search */
  where: GettingStartedSectionWhereUniqueInput;
};

export type GettingStartedSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: GettingStartedSectionCreateInput;
  /** Update document if it exists */
  update: GettingStartedSectionUpdateInput;
};

export type GettingStartedSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: GettingStartedSectionUpsertInput;
  /** Unique document search */
  where: GettingStartedSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type GettingStartedSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type GettingStartedSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GettingStartedSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GettingStartedSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GettingStartedSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<GettingStartedSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<GettingStartedSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<GettingStartedSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  sections_every?: InputMaybe<InfoCardWhereInput>;
  sections_none?: InputMaybe<InfoCardWhereInput>;
  sections_some?: InputMaybe<InfoCardWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type GettingStartedSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<GettingStartedSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<GettingStartedSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<GettingStartedSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<GettingStartedSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References GettingStartedSection record uniquely */
export type GettingStartedSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Registered Component Icons */
export enum HeroMenuIcons {
  AlphaDex = "AlphaDex",
  AlphaMeteor = "AlphaMeteor",
  AlphaWallet = "AlphaWallet",
  AlphaWolfIcon = "AlphaWolfIcon",
  AtomIcon = "AtomIcon",
  BitcoinIcon = "BitcoinIcon",
  CalendarIcon = "CalendarIcon",
  ChartBarIcon = "ChartBarIcon",
  ChartPieIcon = "ChartPieIcon",
  CollectionIcon = "CollectionIcon",
  DocumentDuplicateIcon = "DocumentDuplicateIcon",
  FolderIcon = "FolderIcon",
  HomeIcon = "HomeIcon",
  MainLogoIcon = "MainLogoIcon",
  NFTIcon = "NFTIcon",
  PhotographIcon = "PhotographIcon",
  SearchIcon = "SearchIcon",
  ShieldCheckIcon = "ShieldCheckIcon",
  UsersIcon = "UsersIcon",
  ZoomInIcon = "ZoomInIcon",
}

export enum ImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  clip = "clip",
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  crop = "crop",
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  max = "max",
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  scale = "scale",
}

export enum ImageHosting {
  joepegs = "joepegs",
}

export type ImageResizeInput = {
  /** The default value for the fit parameter is fit:clip. */
  fit?: InputMaybe<ImageFit>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: InputMaybe<Scalars["Int"]["input"]>;
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum ImageSide {
  left = "left",
  right = "right",
}

/** Transformations for Images */
export type ImageTransformationInput = {
  /** Resizes the image */
  resize?: InputMaybe<ImageResizeInput>;
};

export type ImageWithAltTextAndLink = Entity & {
  __typename?: "ImageWithAltTextAndLink";
  altText?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  image?: Maybe<Asset>;
  link?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type ImageWithAltTextAndLinkImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ImageWithAltTextAndLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ImageWithAltTextAndLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type ImageWithAltTextAndLinkConnection = {
  __typename?: "ImageWithAltTextAndLinkConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ImageWithAltTextAndLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ImageWithAltTextAndLinkCreateInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImageWithAltTextAndLinkCreateManyInlineInput = {
  /** Create and connect multiple existing ImageWithAltTextAndLink documents */
  create?: InputMaybe<Array<ImageWithAltTextAndLinkCreateInput>>;
};

export type ImageWithAltTextAndLinkCreateOneInlineInput = {
  /** Create and connect one ImageWithAltTextAndLink document */
  create?: InputMaybe<ImageWithAltTextAndLinkCreateInput>;
};

export type ImageWithAltTextAndLinkCreateWithPositionInput = {
  /** Document to create */
  data: ImageWithAltTextAndLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ImageWithAltTextAndLinkEdge = {
  __typename?: "ImageWithAltTextAndLinkEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ImageWithAltTextAndLink;
};

/** Identifies documents */
export type ImageWithAltTextAndLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  altText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  altText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  altText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  altText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  altText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  altText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  altText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  altText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  altText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ImageWithAltTextAndLinkOrderByInput {
  altText_ASC = "altText_ASC",
  altText_DESC = "altText_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  link_ASC = "link_ASC",
  link_DESC = "link_DESC",
}

export type ImageWithAltTextAndLinkParent = PartnerSection;

export type ImageWithAltTextAndLinkParentConnectInput = {
  PartnerSection?: InputMaybe<PartnerSectionConnectInput>;
};

export type ImageWithAltTextAndLinkParentCreateInput = {
  PartnerSection?: InputMaybe<PartnerSectionCreateInput>;
};

export type ImageWithAltTextAndLinkParentCreateManyInlineInput = {
  /** Connect multiple existing ImageWithAltTextAndLinkParent documents */
  connect?: InputMaybe<Array<ImageWithAltTextAndLinkParentWhereUniqueInput>>;
  /** Create and connect multiple existing ImageWithAltTextAndLinkParent documents */
  create?: InputMaybe<Array<ImageWithAltTextAndLinkParentCreateInput>>;
};

export type ImageWithAltTextAndLinkParentCreateOneInlineInput = {
  /** Connect one existing ImageWithAltTextAndLinkParent document */
  connect?: InputMaybe<ImageWithAltTextAndLinkParentWhereUniqueInput>;
  /** Create and connect one ImageWithAltTextAndLinkParent document */
  create?: InputMaybe<ImageWithAltTextAndLinkParentCreateInput>;
};

export type ImageWithAltTextAndLinkParentUpdateInput = {
  PartnerSection?: InputMaybe<PartnerSectionUpdateInput>;
};

export type ImageWithAltTextAndLinkParentUpdateManyInlineInput = {
  /** Connect multiple existing ImageWithAltTextAndLinkParent documents */
  connect?: InputMaybe<Array<ImageWithAltTextAndLinkParentConnectInput>>;
  /** Create and connect multiple ImageWithAltTextAndLinkParent documents */
  create?: InputMaybe<Array<ImageWithAltTextAndLinkParentCreateInput>>;
  /** Delete multiple ImageWithAltTextAndLinkParent documents */
  delete?: InputMaybe<Array<ImageWithAltTextAndLinkParentWhereUniqueInput>>;
  /** Disconnect multiple ImageWithAltTextAndLinkParent documents */
  disconnect?: InputMaybe<Array<ImageWithAltTextAndLinkParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ImageWithAltTextAndLinkParent documents */
  set?: InputMaybe<Array<ImageWithAltTextAndLinkParentWhereUniqueInput>>;
  /** Update multiple ImageWithAltTextAndLinkParent documents */
  update?: InputMaybe<
    Array<ImageWithAltTextAndLinkParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple ImageWithAltTextAndLinkParent documents */
  upsert?: InputMaybe<
    Array<ImageWithAltTextAndLinkParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type ImageWithAltTextAndLinkParentUpdateManyWithNestedWhereInput = {
  PartnerSection?: InputMaybe<PartnerSectionUpdateManyWithNestedWhereInput>;
};

export type ImageWithAltTextAndLinkParentUpdateOneInlineInput = {
  /** Connect existing ImageWithAltTextAndLinkParent document */
  connect?: InputMaybe<ImageWithAltTextAndLinkParentWhereUniqueInput>;
  /** Create and connect one ImageWithAltTextAndLinkParent document */
  create?: InputMaybe<ImageWithAltTextAndLinkParentCreateInput>;
  /** Delete currently connected ImageWithAltTextAndLinkParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ImageWithAltTextAndLinkParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ImageWithAltTextAndLinkParent document */
  update?: InputMaybe<ImageWithAltTextAndLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ImageWithAltTextAndLinkParent document */
  upsert?: InputMaybe<ImageWithAltTextAndLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type ImageWithAltTextAndLinkParentUpdateWithNestedWhereUniqueInput = {
  PartnerSection?: InputMaybe<PartnerSectionUpdateWithNestedWhereUniqueInput>;
};

export type ImageWithAltTextAndLinkParentUpsertWithNestedWhereUniqueInput = {
  PartnerSection?: InputMaybe<PartnerSectionUpsertWithNestedWhereUniqueInput>;
};

export type ImageWithAltTextAndLinkParentWhereInput = {
  PartnerSection?: InputMaybe<PartnerSectionWhereInput>;
};

export type ImageWithAltTextAndLinkParentWhereUniqueInput = {
  PartnerSection?: InputMaybe<PartnerSectionWhereUniqueInput>;
};

export type ImageWithAltTextAndLinkUpdateInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImageWithAltTextAndLinkUpdateManyInlineInput = {
  /** Create and connect multiple ImageWithAltTextAndLink component instances */
  create?: InputMaybe<Array<ImageWithAltTextAndLinkCreateWithPositionInput>>;
  /** Delete multiple ImageWithAltTextAndLink documents */
  delete?: InputMaybe<Array<ImageWithAltTextAndLinkWhereUniqueInput>>;
  /** Update multiple ImageWithAltTextAndLink component instances */
  update?: InputMaybe<
    Array<ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ImageWithAltTextAndLink component instances */
  upsert?: InputMaybe<
    Array<ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ImageWithAltTextAndLinkUpdateManyInput = {
  altText?: InputMaybe<Scalars["String"]["input"]>;
};

export type ImageWithAltTextAndLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ImageWithAltTextAndLinkUpdateManyInput;
  /** Document search */
  where: ImageWithAltTextAndLinkWhereInput;
};

export type ImageWithAltTextAndLinkUpdateOneInlineInput = {
  /** Create and connect one ImageWithAltTextAndLink document */
  create?: InputMaybe<ImageWithAltTextAndLinkCreateInput>;
  /** Delete currently connected ImageWithAltTextAndLink document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ImageWithAltTextAndLink document */
  update?: InputMaybe<ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ImageWithAltTextAndLink document */
  upsert?: InputMaybe<ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueInput>;
};

export type ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<ImageWithAltTextAndLinkUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ImageWithAltTextAndLinkWhereUniqueInput;
  };

export type ImageWithAltTextAndLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ImageWithAltTextAndLinkUpdateInput;
  /** Unique document search */
  where: ImageWithAltTextAndLinkWhereUniqueInput;
};

export type ImageWithAltTextAndLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: ImageWithAltTextAndLinkCreateInput;
  /** Update document if it exists */
  update: ImageWithAltTextAndLinkUpdateInput;
};

export type ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<ImageWithAltTextAndLinkUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: ImageWithAltTextAndLinkWhereUniqueInput;
  };

export type ImageWithAltTextAndLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ImageWithAltTextAndLinkUpsertInput;
  /** Unique document search */
  where: ImageWithAltTextAndLinkWhereUniqueInput;
};

/** Identifies documents */
export type ImageWithAltTextAndLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ImageWithAltTextAndLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  altText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  altText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  altText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  altText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  altText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  altText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  altText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  altText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  altText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  altText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  link?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  link_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  link_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  link_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  link_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  link_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  link_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  link_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  link_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  link_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References ImageWithAltTextAndLink record uniquely */
export type ImageWithAltTextAndLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  link?: InputMaybe<Scalars["String"]["input"]>;
};

export type InfoCard = Entity & {
  __typename?: "InfoCard";
  cardHeading?: Maybe<Scalars["String"]["output"]>;
  cardText?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
};

export type InfoCardConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: InfoCardWhereUniqueInput;
};

/** A connection to a list of items. */
export type InfoCardConnection = {
  __typename?: "InfoCardConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<InfoCardEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InfoCardCreateInput = {
  cardHeading?: InputMaybe<Scalars["String"]["input"]>;
  cardText?: InputMaybe<Scalars["String"]["input"]>;
};

export type InfoCardCreateManyInlineInput = {
  /** Create and connect multiple existing InfoCard documents */
  create?: InputMaybe<Array<InfoCardCreateInput>>;
};

export type InfoCardCreateOneInlineInput = {
  /** Create and connect one InfoCard document */
  create?: InputMaybe<InfoCardCreateInput>;
};

export type InfoCardCreateWithPositionInput = {
  /** Document to create */
  data: InfoCardCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type InfoCardEdge = {
  __typename?: "InfoCardEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: InfoCard;
};

/** Identifies documents */
export type InfoCardManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InfoCardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InfoCardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InfoCardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  cardHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  cardHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  cardHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  cardHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  cardHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  cardHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  cardHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  cardHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  cardHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  cardText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  cardText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  cardText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  cardText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  cardText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  cardText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  cardText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  cardText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  cardText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum InfoCardOrderByInput {
  cardHeading_ASC = "cardHeading_ASC",
  cardHeading_DESC = "cardHeading_DESC",
  cardText_ASC = "cardText_ASC",
  cardText_DESC = "cardText_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
}

export type InfoCardParent = GettingStartedSection | InfoCardsSection;

export type InfoCardParentConnectInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionConnectInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionConnectInput>;
};

export type InfoCardParentCreateInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionCreateInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionCreateInput>;
};

export type InfoCardParentCreateManyInlineInput = {
  /** Connect multiple existing InfoCardParent documents */
  connect?: InputMaybe<Array<InfoCardParentWhereUniqueInput>>;
  /** Create and connect multiple existing InfoCardParent documents */
  create?: InputMaybe<Array<InfoCardParentCreateInput>>;
};

export type InfoCardParentCreateOneInlineInput = {
  /** Connect one existing InfoCardParent document */
  connect?: InputMaybe<InfoCardParentWhereUniqueInput>;
  /** Create and connect one InfoCardParent document */
  create?: InputMaybe<InfoCardParentCreateInput>;
};

export type InfoCardParentUpdateInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionUpdateInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionUpdateInput>;
};

export type InfoCardParentUpdateManyInlineInput = {
  /** Connect multiple existing InfoCardParent documents */
  connect?: InputMaybe<Array<InfoCardParentConnectInput>>;
  /** Create and connect multiple InfoCardParent documents */
  create?: InputMaybe<Array<InfoCardParentCreateInput>>;
  /** Delete multiple InfoCardParent documents */
  delete?: InputMaybe<Array<InfoCardParentWhereUniqueInput>>;
  /** Disconnect multiple InfoCardParent documents */
  disconnect?: InputMaybe<Array<InfoCardParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing InfoCardParent documents */
  set?: InputMaybe<Array<InfoCardParentWhereUniqueInput>>;
  /** Update multiple InfoCardParent documents */
  update?: InputMaybe<Array<InfoCardParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple InfoCardParent documents */
  upsert?: InputMaybe<Array<InfoCardParentUpsertWithNestedWhereUniqueInput>>;
};

export type InfoCardParentUpdateManyWithNestedWhereInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionUpdateManyWithNestedWhereInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionUpdateManyWithNestedWhereInput>;
};

export type InfoCardParentUpdateOneInlineInput = {
  /** Connect existing InfoCardParent document */
  connect?: InputMaybe<InfoCardParentWhereUniqueInput>;
  /** Create and connect one InfoCardParent document */
  create?: InputMaybe<InfoCardParentCreateInput>;
  /** Delete currently connected InfoCardParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected InfoCardParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single InfoCardParent document */
  update?: InputMaybe<InfoCardParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InfoCardParent document */
  upsert?: InputMaybe<InfoCardParentUpsertWithNestedWhereUniqueInput>;
};

export type InfoCardParentUpdateWithNestedWhereUniqueInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionUpdateWithNestedWhereUniqueInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionUpdateWithNestedWhereUniqueInput>;
};

export type InfoCardParentUpsertWithNestedWhereUniqueInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionUpsertWithNestedWhereUniqueInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionUpsertWithNestedWhereUniqueInput>;
};

export type InfoCardParentWhereInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionWhereInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionWhereInput>;
};

export type InfoCardParentWhereUniqueInput = {
  GettingStartedSection?: InputMaybe<GettingStartedSectionWhereUniqueInput>;
  InfoCardsSection?: InputMaybe<InfoCardsSectionWhereUniqueInput>;
};

export type InfoCardUpdateInput = {
  cardHeading?: InputMaybe<Scalars["String"]["input"]>;
  cardText?: InputMaybe<Scalars["String"]["input"]>;
};

export type InfoCardUpdateManyInlineInput = {
  /** Create and connect multiple InfoCard component instances */
  create?: InputMaybe<Array<InfoCardCreateWithPositionInput>>;
  /** Delete multiple InfoCard documents */
  delete?: InputMaybe<Array<InfoCardWhereUniqueInput>>;
  /** Update multiple InfoCard component instances */
  update?: InputMaybe<
    Array<InfoCardUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple InfoCard component instances */
  upsert?: InputMaybe<
    Array<InfoCardUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type InfoCardUpdateManyInput = {
  cardHeading?: InputMaybe<Scalars["String"]["input"]>;
  cardText?: InputMaybe<Scalars["String"]["input"]>;
};

export type InfoCardUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: InfoCardUpdateManyInput;
  /** Document search */
  where: InfoCardWhereInput;
};

export type InfoCardUpdateOneInlineInput = {
  /** Create and connect one InfoCard document */
  create?: InputMaybe<InfoCardCreateInput>;
  /** Delete currently connected InfoCard document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single InfoCard document */
  update?: InputMaybe<InfoCardUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InfoCard document */
  upsert?: InputMaybe<InfoCardUpsertWithNestedWhereUniqueInput>;
};

export type InfoCardUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<InfoCardUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InfoCardWhereUniqueInput;
};

export type InfoCardUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: InfoCardUpdateInput;
  /** Unique document search */
  where: InfoCardWhereUniqueInput;
};

export type InfoCardUpsertInput = {
  /** Create document if it didn't exist */
  create: InfoCardCreateInput;
  /** Update document if it exists */
  update: InfoCardUpdateInput;
};

export type InfoCardUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<InfoCardUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: InfoCardWhereUniqueInput;
};

export type InfoCardUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: InfoCardUpsertInput;
  /** Unique document search */
  where: InfoCardWhereUniqueInput;
};

/** Identifies documents */
export type InfoCardWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InfoCardWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InfoCardWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InfoCardWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  cardHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  cardHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  cardHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  cardHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  cardHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  cardHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  cardHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  cardHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  cardHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  cardText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  cardText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  cardText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  cardText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  cardText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  cardText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  cardText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  cardText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  cardText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  cardText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

/** References InfoCard record uniquely */
export type InfoCardWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type InfoCardsSection = Entity &
  Node & {
    __typename?: "InfoCardsSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<InfoCardsSection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of InfoCardsSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    infoCard: Array<InfoCard>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type InfoCardsSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type InfoCardsSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type InfoCardsSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type InfoCardsSectionInfoCardArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<InfoCardOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<InfoCardWhereInput>;
};

export type InfoCardsSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type InfoCardsSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type InfoCardsSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type InfoCardsSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: InfoCardsSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type InfoCardsSectionConnection = {
  __typename?: "InfoCardsSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<InfoCardsSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type InfoCardsSectionCreateInput = {
  cl4j4b5224p5k01z69r1pcuk8?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  infoCard?: InputMaybe<InfoCardCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type InfoCardsSectionCreateManyInlineInput = {
  /** Connect multiple existing InfoCardsSection documents */
  connect?: InputMaybe<Array<InfoCardsSectionWhereUniqueInput>>;
  /** Create and connect multiple existing InfoCardsSection documents */
  create?: InputMaybe<Array<InfoCardsSectionCreateInput>>;
};

export type InfoCardsSectionCreateOneInlineInput = {
  /** Connect one existing InfoCardsSection document */
  connect?: InputMaybe<InfoCardsSectionWhereUniqueInput>;
  /** Create and connect one InfoCardsSection document */
  create?: InputMaybe<InfoCardsSectionCreateInput>;
};

/** An edge in a connection. */
export type InfoCardsSectionEdge = {
  __typename?: "InfoCardsSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: InfoCardsSection;
};

/** Identifies documents */
export type InfoCardsSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InfoCardsSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InfoCardsSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InfoCardsSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<InfoCardsSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<InfoCardsSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<InfoCardsSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  infoCard_every?: InputMaybe<InfoCardWhereInput>;
  infoCard_none?: InputMaybe<InfoCardWhereInput>;
  infoCard_some?: InputMaybe<InfoCardWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum InfoCardsSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type InfoCardsSectionUpdateInput = {
  cl4j4b5224p5k01z69r1pcuk8?: InputMaybe<PageUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  infoCard?: InputMaybe<InfoCardUpdateManyInlineInput>;
};

export type InfoCardsSectionUpdateManyInlineInput = {
  /** Connect multiple existing InfoCardsSection documents */
  connect?: InputMaybe<Array<InfoCardsSectionConnectInput>>;
  /** Create and connect multiple InfoCardsSection documents */
  create?: InputMaybe<Array<InfoCardsSectionCreateInput>>;
  /** Delete multiple InfoCardsSection documents */
  delete?: InputMaybe<Array<InfoCardsSectionWhereUniqueInput>>;
  /** Disconnect multiple InfoCardsSection documents */
  disconnect?: InputMaybe<Array<InfoCardsSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing InfoCardsSection documents */
  set?: InputMaybe<Array<InfoCardsSectionWhereUniqueInput>>;
  /** Update multiple InfoCardsSection documents */
  update?: InputMaybe<Array<InfoCardsSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple InfoCardsSection documents */
  upsert?: InputMaybe<Array<InfoCardsSectionUpsertWithNestedWhereUniqueInput>>;
};

export type InfoCardsSectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
};

export type InfoCardsSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: InfoCardsSectionUpdateManyInput;
  /** Document search */
  where: InfoCardsSectionWhereInput;
};

export type InfoCardsSectionUpdateOneInlineInput = {
  /** Connect existing InfoCardsSection document */
  connect?: InputMaybe<InfoCardsSectionWhereUniqueInput>;
  /** Create and connect one InfoCardsSection document */
  create?: InputMaybe<InfoCardsSectionCreateInput>;
  /** Delete currently connected InfoCardsSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected InfoCardsSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single InfoCardsSection document */
  update?: InputMaybe<InfoCardsSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single InfoCardsSection document */
  upsert?: InputMaybe<InfoCardsSectionUpsertWithNestedWhereUniqueInput>;
};

export type InfoCardsSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: InfoCardsSectionUpdateInput;
  /** Unique document search */
  where: InfoCardsSectionWhereUniqueInput;
};

export type InfoCardsSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: InfoCardsSectionCreateInput;
  /** Update document if it exists */
  update: InfoCardsSectionUpdateInput;
};

export type InfoCardsSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: InfoCardsSectionUpsertInput;
  /** Unique document search */
  where: InfoCardsSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type InfoCardsSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type InfoCardsSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InfoCardsSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InfoCardsSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InfoCardsSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<InfoCardsSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<InfoCardsSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<InfoCardsSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  infoCard_every?: InputMaybe<InfoCardWhereInput>;
  infoCard_none?: InputMaybe<InfoCardWhereInput>;
  infoCard_some?: InputMaybe<InfoCardWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type InfoCardsSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<InfoCardsSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<InfoCardsSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<InfoCardsSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<InfoCardsSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References InfoCardsSection record uniquely */
export type InfoCardsSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LandingPage = Entity &
  Node & {
    __typename?: "LandingPage";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaSection?: Maybe<CtaSection>;
    ctaSectionBottom?: Maybe<CtaSection>;
    /** Get the document in other stages */
    documentInStages: Array<LandingPage>;
    faQs: Array<Faq>;
    faqSectionTitle?: Maybe<Scalars["String"]["output"]>;
    heroBannerImage?: Maybe<Asset>;
    heroText?: Maybe<RichText>;
    /** List of LandingPage versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    logoImage?: Maybe<Asset>;
    pageTitle?: Maybe<Scalars["String"]["output"]>;
    pressSectionTitle?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    seoImage?: Maybe<Asset>;
    seoPageTitle?: Maybe<Scalars["String"]["output"]>;
    slug?: Maybe<Scalars["String"]["output"]>;
    /** System stage field */
    stage: Stage;
    textImageRow: Array<RichTextImageRow>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    youtubeCarousel?: Maybe<YoutubeVideo>;
  };

export type LandingPageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageCtaSectionBottomArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type LandingPageFaQsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type LandingPageHeroBannerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type LandingPageLogoImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type LandingPageSeoImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageTextImageRowArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<RichTextImageRowOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RichTextImageRowWhereInput>;
};

export type LandingPageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageYoutubeCarouselArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LandingPageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LandingPageWhereUniqueInput;
};

/** A connection to a list of items. */
export type LandingPageConnection = {
  __typename?: "LandingPageConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LandingPageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LandingPageCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionCreateOneInlineInput>;
  faQs?: InputMaybe<FaqCreateManyInlineInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetCreateOneInlineInput>;
  heroText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  logoImage?: InputMaybe<AssetCreateOneInlineInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetCreateOneInlineInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  textImageRow?: InputMaybe<RichTextImageRowCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  youtubeCarousel?: InputMaybe<YoutubeVideoCreateOneInlineInput>;
};

export type LandingPageCreateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Create and connect multiple existing LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>;
};

export type LandingPageCreateOneInlineInput = {
  /** Connect one existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>;
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>;
};

/** An edge in a connection. */
export type LandingPageEdge = {
  __typename?: "LandingPageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: LandingPage;
};

/** Identifies documents */
export type LandingPageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>;
  faQs_every?: InputMaybe<FaqWhereInput>;
  faQs_none?: InputMaybe<FaqWhereInput>;
  faQs_some?: InputMaybe<FaqWhereInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  faqSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  faqSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  faqSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  faqSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  faqSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  faqSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  faqSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  faqSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  faqSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  logoImage?: InputMaybe<AssetWhereInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pressSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pressSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pressSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  pressSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pressSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pressSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pressSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  pressSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pressSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoPageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoPageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoPageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoPageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoPageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoPageTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoPageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoPageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  textImageRow_every?: InputMaybe<RichTextImageRowWhereInput>;
  textImageRow_none?: InputMaybe<RichTextImageRowWhereInput>;
  textImageRow_some?: InputMaybe<RichTextImageRowWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  youtubeCarousel?: InputMaybe<YoutubeVideoWhereInput>;
};

export enum LandingPageOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  faqSectionTitle_ASC = "faqSectionTitle_ASC",
  faqSectionTitle_DESC = "faqSectionTitle_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  pageTitle_ASC = "pageTitle_ASC",
  pageTitle_DESC = "pageTitle_DESC",
  pressSectionTitle_ASC = "pressSectionTitle_ASC",
  pressSectionTitle_DESC = "pressSectionTitle_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  seoDescription_ASC = "seoDescription_ASC",
  seoDescription_DESC = "seoDescription_DESC",
  seoPageTitle_ASC = "seoPageTitle_ASC",
  seoPageTitle_DESC = "seoPageTitle_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type LandingPageUpdateInput = {
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  faQs?: InputMaybe<FaqUpdateManyInlineInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetUpdateOneInlineInput>;
  heroText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  logoImage?: InputMaybe<AssetUpdateOneInlineInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetUpdateOneInlineInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  textImageRow?: InputMaybe<RichTextImageRowUpdateManyInlineInput>;
  youtubeCarousel?: InputMaybe<YoutubeVideoUpdateOneInlineInput>;
};

export type LandingPageUpdateManyInlineInput = {
  /** Connect multiple existing LandingPage documents */
  connect?: InputMaybe<Array<LandingPageConnectInput>>;
  /** Create and connect multiple LandingPage documents */
  create?: InputMaybe<Array<LandingPageCreateInput>>;
  /** Delete multiple LandingPage documents */
  delete?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Disconnect multiple LandingPage documents */
  disconnect?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing LandingPage documents */
  set?: InputMaybe<Array<LandingPageWhereUniqueInput>>;
  /** Update multiple LandingPage documents */
  update?: InputMaybe<Array<LandingPageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple LandingPage documents */
  upsert?: InputMaybe<Array<LandingPageUpsertWithNestedWhereUniqueInput>>;
};

export type LandingPageUpdateManyInput = {
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  heroText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type LandingPageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LandingPageUpdateManyInput;
  /** Document search */
  where: LandingPageWhereInput;
};

export type LandingPageUpdateOneInlineInput = {
  /** Connect existing LandingPage document */
  connect?: InputMaybe<LandingPageWhereUniqueInput>;
  /** Create and connect one LandingPage document */
  create?: InputMaybe<LandingPageCreateInput>;
  /** Delete currently connected LandingPage document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected LandingPage document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LandingPage document */
  update?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LandingPage document */
  upsert?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>;
};

export type LandingPageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LandingPageUpdateInput;
  /** Unique document search */
  where: LandingPageWhereUniqueInput;
};

export type LandingPageUpsertInput = {
  /** Create document if it didn't exist */
  create: LandingPageCreateInput;
  /** Update document if it exists */
  update: LandingPageUpdateInput;
};

export type LandingPageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LandingPageUpsertInput;
  /** Unique document search */
  where: LandingPageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type LandingPageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type LandingPageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  ctaSectionBottom?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_none?: InputMaybe<LandingPageWhereStageInput>;
  documentInStages_some?: InputMaybe<LandingPageWhereStageInput>;
  faQs_every?: InputMaybe<FaqWhereInput>;
  faQs_none?: InputMaybe<FaqWhereInput>;
  faQs_some?: InputMaybe<FaqWhereInput>;
  faqSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  faqSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  faqSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  faqSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  faqSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  faqSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  faqSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  faqSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  faqSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  faqSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroBannerImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  logoImage?: InputMaybe<AssetWhereInput>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pressSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pressSectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pressSectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pressSectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  pressSectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pressSectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pressSectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pressSectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  pressSectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pressSectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoPageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoPageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoPageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoPageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoPageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoPageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoPageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoPageTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoPageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoPageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  textImageRow_every?: InputMaybe<RichTextImageRowWhereInput>;
  textImageRow_none?: InputMaybe<RichTextImageRowWhereInput>;
  textImageRow_some?: InputMaybe<RichTextImageRowWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  youtubeCarousel?: InputMaybe<YoutubeVideoWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type LandingPageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LandingPageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<LandingPageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References LandingPage record uniquely */
export type LandingPageWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type LargeIconHeading = Entity & {
  __typename?: "LargeIconHeading";
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Asset>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type LargeIconHeadingIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LargeIconHeadingConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LargeIconHeadingWhereUniqueInput;
};

/** A connection to a list of items. */
export type LargeIconHeadingConnection = {
  __typename?: "LargeIconHeadingConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LargeIconHeadingEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LargeIconHeadingCreateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetCreateOneInlineInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type LargeIconHeadingCreateManyInlineInput = {
  /** Create and connect multiple existing LargeIconHeading documents */
  create?: InputMaybe<Array<LargeIconHeadingCreateInput>>;
};

export type LargeIconHeadingCreateOneInlineInput = {
  /** Create and connect one LargeIconHeading document */
  create?: InputMaybe<LargeIconHeadingCreateInput>;
};

export type LargeIconHeadingCreateWithPositionInput = {
  /** Document to create */
  data: LargeIconHeadingCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type LargeIconHeadingEdge = {
  __typename?: "LargeIconHeadingEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: LargeIconHeading;
};

/** Identifies documents */
export type LargeIconHeadingManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LargeIconHeadingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LargeIconHeadingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LargeIconHeadingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum LargeIconHeadingOrderByInput {
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
}

export type LargeIconHeadingParent = PerksSection;

export type LargeIconHeadingParentConnectInput = {
  PerksSection?: InputMaybe<PerksSectionConnectInput>;
};

export type LargeIconHeadingParentCreateInput = {
  PerksSection?: InputMaybe<PerksSectionCreateInput>;
};

export type LargeIconHeadingParentCreateManyInlineInput = {
  /** Connect multiple existing LargeIconHeadingParent documents */
  connect?: InputMaybe<Array<LargeIconHeadingParentWhereUniqueInput>>;
  /** Create and connect multiple existing LargeIconHeadingParent documents */
  create?: InputMaybe<Array<LargeIconHeadingParentCreateInput>>;
};

export type LargeIconHeadingParentCreateOneInlineInput = {
  /** Connect one existing LargeIconHeadingParent document */
  connect?: InputMaybe<LargeIconHeadingParentWhereUniqueInput>;
  /** Create and connect one LargeIconHeadingParent document */
  create?: InputMaybe<LargeIconHeadingParentCreateInput>;
};

export type LargeIconHeadingParentUpdateInput = {
  PerksSection?: InputMaybe<PerksSectionUpdateInput>;
};

export type LargeIconHeadingParentUpdateManyInlineInput = {
  /** Connect multiple existing LargeIconHeadingParent documents */
  connect?: InputMaybe<Array<LargeIconHeadingParentConnectInput>>;
  /** Create and connect multiple LargeIconHeadingParent documents */
  create?: InputMaybe<Array<LargeIconHeadingParentCreateInput>>;
  /** Delete multiple LargeIconHeadingParent documents */
  delete?: InputMaybe<Array<LargeIconHeadingParentWhereUniqueInput>>;
  /** Disconnect multiple LargeIconHeadingParent documents */
  disconnect?: InputMaybe<Array<LargeIconHeadingParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing LargeIconHeadingParent documents */
  set?: InputMaybe<Array<LargeIconHeadingParentWhereUniqueInput>>;
  /** Update multiple LargeIconHeadingParent documents */
  update?: InputMaybe<
    Array<LargeIconHeadingParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple LargeIconHeadingParent documents */
  upsert?: InputMaybe<
    Array<LargeIconHeadingParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type LargeIconHeadingParentUpdateManyWithNestedWhereInput = {
  PerksSection?: InputMaybe<PerksSectionUpdateManyWithNestedWhereInput>;
};

export type LargeIconHeadingParentUpdateOneInlineInput = {
  /** Connect existing LargeIconHeadingParent document */
  connect?: InputMaybe<LargeIconHeadingParentWhereUniqueInput>;
  /** Create and connect one LargeIconHeadingParent document */
  create?: InputMaybe<LargeIconHeadingParentCreateInput>;
  /** Delete currently connected LargeIconHeadingParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected LargeIconHeadingParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LargeIconHeadingParent document */
  update?: InputMaybe<LargeIconHeadingParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LargeIconHeadingParent document */
  upsert?: InputMaybe<LargeIconHeadingParentUpsertWithNestedWhereUniqueInput>;
};

export type LargeIconHeadingParentUpdateWithNestedWhereUniqueInput = {
  PerksSection?: InputMaybe<PerksSectionUpdateWithNestedWhereUniqueInput>;
};

export type LargeIconHeadingParentUpsertWithNestedWhereUniqueInput = {
  PerksSection?: InputMaybe<PerksSectionUpsertWithNestedWhereUniqueInput>;
};

export type LargeIconHeadingParentWhereInput = {
  PerksSection?: InputMaybe<PerksSectionWhereInput>;
};

export type LargeIconHeadingParentWhereUniqueInput = {
  PerksSection?: InputMaybe<PerksSectionWhereUniqueInput>;
};

export type LargeIconHeadingUpdateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetUpdateOneInlineInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type LargeIconHeadingUpdateManyInlineInput = {
  /** Create and connect multiple LargeIconHeading component instances */
  create?: InputMaybe<Array<LargeIconHeadingCreateWithPositionInput>>;
  /** Delete multiple LargeIconHeading documents */
  delete?: InputMaybe<Array<LargeIconHeadingWhereUniqueInput>>;
  /** Update multiple LargeIconHeading component instances */
  update?: InputMaybe<
    Array<LargeIconHeadingUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple LargeIconHeading component instances */
  upsert?: InputMaybe<
    Array<LargeIconHeadingUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type LargeIconHeadingUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type LargeIconHeadingUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LargeIconHeadingUpdateManyInput;
  /** Document search */
  where: LargeIconHeadingWhereInput;
};

export type LargeIconHeadingUpdateOneInlineInput = {
  /** Create and connect one LargeIconHeading document */
  create?: InputMaybe<LargeIconHeadingCreateInput>;
  /** Delete currently connected LargeIconHeading document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LargeIconHeading document */
  update?: InputMaybe<LargeIconHeadingUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LargeIconHeading document */
  upsert?: InputMaybe<LargeIconHeadingUpsertWithNestedWhereUniqueInput>;
};

export type LargeIconHeadingUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<LargeIconHeadingUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LargeIconHeadingWhereUniqueInput;
};

export type LargeIconHeadingUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LargeIconHeadingUpdateInput;
  /** Unique document search */
  where: LargeIconHeadingWhereUniqueInput;
};

export type LargeIconHeadingUpsertInput = {
  /** Create document if it didn't exist */
  create: LargeIconHeadingCreateInput;
  /** Update document if it exists */
  update: LargeIconHeadingUpdateInput;
};

export type LargeIconHeadingUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<LargeIconHeadingUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LargeIconHeadingWhereUniqueInput;
};

export type LargeIconHeadingUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LargeIconHeadingUpsertInput;
  /** Unique document search */
  where: LargeIconHeadingWhereUniqueInput;
};

/** Identifies documents */
export type LargeIconHeadingWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LargeIconHeadingWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LargeIconHeadingWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LargeIconHeadingWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References LargeIconHeading record uniquely */
export type LargeIconHeadingWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Select the chain and collections to display latest listings from */
export type LatestListingsByCollectionAndChainId = Entity & {
  __typename?: "LatestListingsByCollectionAndChainId";
  chainAndCollectionParams: Array<LatestListingsQuery>;
  heading?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  numberOfItemsOnCarousel?: Maybe<Scalars["Int"]["output"]>;
  /** System stage field */
  stage: Stage;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

/** Select the chain and collections to display latest listings from */
export type LatestListingsByCollectionAndChainIdChainAndCollectionParamsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LatestListingsQueryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LatestListingsQueryWhereInput>;
};

export type LatestListingsByCollectionAndChainIdConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LatestListingsByCollectionAndChainIdWhereUniqueInput;
};

/** A connection to a list of items. */
export type LatestListingsByCollectionAndChainIdConnection = {
  __typename?: "LatestListingsByCollectionAndChainIdConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LatestListingsByCollectionAndChainIdEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LatestListingsByCollectionAndChainIdCreateInput = {
  chainAndCollectionParams?: InputMaybe<LatestListingsQueryCreateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfItemsOnCarousel?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type LatestListingsByCollectionAndChainIdCreateManyInlineInput = {
  /** Create and connect multiple existing LatestListingsByCollectionAndChainId documents */
  create?: InputMaybe<Array<LatestListingsByCollectionAndChainIdCreateInput>>;
};

export type LatestListingsByCollectionAndChainIdCreateOneInlineInput = {
  /** Create and connect one LatestListingsByCollectionAndChainId document */
  create?: InputMaybe<LatestListingsByCollectionAndChainIdCreateInput>;
};

export type LatestListingsByCollectionAndChainIdCreateWithPositionInput = {
  /** Document to create */
  data: LatestListingsByCollectionAndChainIdCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type LatestListingsByCollectionAndChainIdEdge = {
  __typename?: "LatestListingsByCollectionAndChainIdEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: LatestListingsByCollectionAndChainId;
};

/** Identifies documents */
export type LatestListingsByCollectionAndChainIdManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LatestListingsByCollectionAndChainIdWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LatestListingsByCollectionAndChainIdWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LatestListingsByCollectionAndChainIdWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chainAndCollectionParams_every?: InputMaybe<LatestListingsQueryWhereInput>;
  chainAndCollectionParams_none?: InputMaybe<LatestListingsQueryWhereInput>;
  chainAndCollectionParams_some?: InputMaybe<LatestListingsQueryWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  numberOfItemsOnCarousel?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  numberOfItemsOnCarousel_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  numberOfItemsOnCarousel_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  numberOfItemsOnCarousel_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  numberOfItemsOnCarousel_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  numberOfItemsOnCarousel_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  numberOfItemsOnCarousel_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  numberOfItemsOnCarousel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum LatestListingsByCollectionAndChainIdOrderByInput {
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  numberOfItemsOnCarousel_ASC = "numberOfItemsOnCarousel_ASC",
  numberOfItemsOnCarousel_DESC = "numberOfItemsOnCarousel_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
}

export type LatestListingsByCollectionAndChainIdParent = MarketplacePage;

export type LatestListingsByCollectionAndChainIdParentConnectInput = {
  MarketplacePage?: InputMaybe<MarketplacePageConnectInput>;
};

export type LatestListingsByCollectionAndChainIdParentCreateInput = {
  MarketplacePage?: InputMaybe<MarketplacePageCreateInput>;
};

export type LatestListingsByCollectionAndChainIdParentCreateManyInlineInput = {
  /** Connect multiple existing LatestListingsByCollectionAndChainIdParent documents */
  connect?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentWhereUniqueInput>
  >;
  /** Create and connect multiple existing LatestListingsByCollectionAndChainIdParent documents */
  create?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentCreateInput>
  >;
};

export type LatestListingsByCollectionAndChainIdParentCreateOneInlineInput = {
  /** Connect one existing LatestListingsByCollectionAndChainIdParent document */
  connect?: InputMaybe<LatestListingsByCollectionAndChainIdParentWhereUniqueInput>;
  /** Create and connect one LatestListingsByCollectionAndChainIdParent document */
  create?: InputMaybe<LatestListingsByCollectionAndChainIdParentCreateInput>;
};

export type LatestListingsByCollectionAndChainIdParentUpdateInput = {
  MarketplacePage?: InputMaybe<MarketplacePageUpdateInput>;
};

export type LatestListingsByCollectionAndChainIdParentUpdateManyInlineInput = {
  /** Connect multiple existing LatestListingsByCollectionAndChainIdParent documents */
  connect?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentConnectInput>
  >;
  /** Create and connect multiple LatestListingsByCollectionAndChainIdParent documents */
  create?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentCreateInput>
  >;
  /** Delete multiple LatestListingsByCollectionAndChainIdParent documents */
  delete?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentWhereUniqueInput>
  >;
  /** Disconnect multiple LatestListingsByCollectionAndChainIdParent documents */
  disconnect?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing LatestListingsByCollectionAndChainIdParent documents */
  set?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentWhereUniqueInput>
  >;
  /** Update multiple LatestListingsByCollectionAndChainIdParent documents */
  update?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple LatestListingsByCollectionAndChainIdParent documents */
  upsert?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type LatestListingsByCollectionAndChainIdParentUpdateManyWithNestedWhereInput =
  {
    MarketplacePage?: InputMaybe<MarketplacePageUpdateManyWithNestedWhereInput>;
  };

export type LatestListingsByCollectionAndChainIdParentUpdateOneInlineInput = {
  /** Connect existing LatestListingsByCollectionAndChainIdParent document */
  connect?: InputMaybe<LatestListingsByCollectionAndChainIdParentWhereUniqueInput>;
  /** Create and connect one LatestListingsByCollectionAndChainIdParent document */
  create?: InputMaybe<LatestListingsByCollectionAndChainIdParentCreateInput>;
  /** Delete currently connected LatestListingsByCollectionAndChainIdParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected LatestListingsByCollectionAndChainIdParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LatestListingsByCollectionAndChainIdParent document */
  update?: InputMaybe<LatestListingsByCollectionAndChainIdParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LatestListingsByCollectionAndChainIdParent document */
  upsert?: InputMaybe<LatestListingsByCollectionAndChainIdParentUpsertWithNestedWhereUniqueInput>;
};

export type LatestListingsByCollectionAndChainIdParentUpdateWithNestedWhereUniqueInput =
  {
    MarketplacePage?: InputMaybe<MarketplacePageUpdateWithNestedWhereUniqueInput>;
  };

export type LatestListingsByCollectionAndChainIdParentUpsertWithNestedWhereUniqueInput =
  {
    MarketplacePage?: InputMaybe<MarketplacePageUpsertWithNestedWhereUniqueInput>;
  };

export type LatestListingsByCollectionAndChainIdParentWhereInput = {
  MarketplacePage?: InputMaybe<MarketplacePageWhereInput>;
};

export type LatestListingsByCollectionAndChainIdParentWhereUniqueInput = {
  MarketplacePage?: InputMaybe<MarketplacePageWhereUniqueInput>;
};

export type LatestListingsByCollectionAndChainIdUpdateInput = {
  chainAndCollectionParams?: InputMaybe<LatestListingsQueryUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfItemsOnCarousel?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type LatestListingsByCollectionAndChainIdUpdateManyInlineInput = {
  /** Create and connect multiple LatestListingsByCollectionAndChainId component instances */
  create?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdCreateWithPositionInput>
  >;
  /** Delete multiple LatestListingsByCollectionAndChainId documents */
  delete?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdWhereUniqueInput>
  >;
  /** Update multiple LatestListingsByCollectionAndChainId component instances */
  update?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple LatestListingsByCollectionAndChainId component instances */
  upsert?: InputMaybe<
    Array<LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type LatestListingsByCollectionAndChainIdUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfItemsOnCarousel?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type LatestListingsByCollectionAndChainIdUpdateManyWithNestedWhereInput =
  {
    /** Update many input */
    data: LatestListingsByCollectionAndChainIdUpdateManyInput;
    /** Document search */
    where: LatestListingsByCollectionAndChainIdWhereInput;
  };

export type LatestListingsByCollectionAndChainIdUpdateOneInlineInput = {
  /** Create and connect one LatestListingsByCollectionAndChainId document */
  create?: InputMaybe<LatestListingsByCollectionAndChainIdCreateInput>;
  /** Delete currently connected LatestListingsByCollectionAndChainId document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LatestListingsByCollectionAndChainId document */
  update?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LatestListingsByCollectionAndChainId document */
  upsert?: InputMaybe<LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueInput>;
};

export type LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: LatestListingsByCollectionAndChainIdWhereUniqueInput;
  };

export type LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueInput =
  {
    /** Document to update */
    data: LatestListingsByCollectionAndChainIdUpdateInput;
    /** Unique document search */
    where: LatestListingsByCollectionAndChainIdWhereUniqueInput;
  };

export type LatestListingsByCollectionAndChainIdUpsertInput = {
  /** Create document if it didn't exist */
  create: LatestListingsByCollectionAndChainIdCreateInput;
  /** Update document if it exists */
  update: LatestListingsByCollectionAndChainIdUpdateInput;
};

export type LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<LatestListingsByCollectionAndChainIdUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: LatestListingsByCollectionAndChainIdWhereUniqueInput;
  };

export type LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueInput =
  {
    /** Upsert data */
    data: LatestListingsByCollectionAndChainIdUpsertInput;
    /** Unique document search */
    where: LatestListingsByCollectionAndChainIdWhereUniqueInput;
  };

/** Identifies documents */
export type LatestListingsByCollectionAndChainIdWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LatestListingsByCollectionAndChainIdWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LatestListingsByCollectionAndChainIdWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LatestListingsByCollectionAndChainIdWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chainAndCollectionParams_every?: InputMaybe<LatestListingsQueryWhereInput>;
  chainAndCollectionParams_none?: InputMaybe<LatestListingsQueryWhereInput>;
  chainAndCollectionParams_some?: InputMaybe<LatestListingsQueryWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  numberOfItemsOnCarousel?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  numberOfItemsOnCarousel_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  numberOfItemsOnCarousel_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  numberOfItemsOnCarousel_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  numberOfItemsOnCarousel_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  numberOfItemsOnCarousel_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  numberOfItemsOnCarousel_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  numberOfItemsOnCarousel_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References LatestListingsByCollectionAndChainId record uniquely */
export type LatestListingsByCollectionAndChainIdWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type LatestListingsQuery = Entity & {
  __typename?: "LatestListingsQuery";
  chain?: Maybe<Chain>;
  /** Must be from selected chain */
  collectionContractAddress: Array<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** Include Listings from Other Marketplaces */
  includeOtherMarketplaces: Scalars["Boolean"]["output"];
  /** System stage field */
  stage: Stage;
};

export type LatestListingsQueryChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type LatestListingsQueryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: LatestListingsQueryWhereUniqueInput;
};

/** A connection to a list of items. */
export type LatestListingsQueryConnection = {
  __typename?: "LatestListingsQueryConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<LatestListingsQueryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type LatestListingsQueryCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  collectionContractAddress?: InputMaybe<Array<Scalars["String"]["input"]>>;
  includeOtherMarketplaces: Scalars["Boolean"]["input"];
};

export type LatestListingsQueryCreateManyInlineInput = {
  /** Create and connect multiple existing LatestListingsQuery documents */
  create?: InputMaybe<Array<LatestListingsQueryCreateInput>>;
};

export type LatestListingsQueryCreateOneInlineInput = {
  /** Create and connect one LatestListingsQuery document */
  create?: InputMaybe<LatestListingsQueryCreateInput>;
};

export type LatestListingsQueryCreateWithPositionInput = {
  /** Document to create */
  data: LatestListingsQueryCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type LatestListingsQueryEdge = {
  __typename?: "LatestListingsQueryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: LatestListingsQuery;
};

/** Identifies documents */
export type LatestListingsQueryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LatestListingsQueryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LatestListingsQueryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LatestListingsQueryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  collectionContractAddress?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  collectionContractAddress_contains_all?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  /** Matches if the field array does not contain any of the items provided to the filter */
  collectionContractAddress_contains_none?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  /** Matches if the field array contains at least one item provided to the filter */
  collectionContractAddress_contains_some?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  collectionContractAddress_not?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  includeOtherMarketplaces?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  includeOtherMarketplaces_not?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum LatestListingsQueryOrderByInput {
  collectionContractAddress_ASC = "collectionContractAddress_ASC",
  collectionContractAddress_DESC = "collectionContractAddress_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  includeOtherMarketplaces_ASC = "includeOtherMarketplaces_ASC",
  includeOtherMarketplaces_DESC = "includeOtherMarketplaces_DESC",
}

export type LatestListingsQueryParent = LatestListingsByCollectionAndChainId;

export type LatestListingsQueryParentConnectInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdConnectInput>;
};

export type LatestListingsQueryParentCreateInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdCreateInput>;
};

export type LatestListingsQueryParentCreateManyInlineInput = {
  /** Create and connect multiple existing LatestListingsQueryParent documents */
  create?: InputMaybe<Array<LatestListingsQueryParentCreateInput>>;
};

export type LatestListingsQueryParentCreateOneInlineInput = {
  /** Create and connect one LatestListingsQueryParent document */
  create?: InputMaybe<LatestListingsQueryParentCreateInput>;
};

export type LatestListingsQueryParentCreateWithPositionInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdCreateWithPositionInput>;
};

export type LatestListingsQueryParentUpdateInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateInput>;
};

export type LatestListingsQueryParentUpdateManyInlineInput = {
  /** Create and connect multiple LatestListingsQueryParent component instances */
  create?: InputMaybe<Array<LatestListingsQueryParentCreateWithPositionInput>>;
  /** Delete multiple LatestListingsQueryParent documents */
  delete?: InputMaybe<Array<LatestListingsQueryParentWhereUniqueInput>>;
  /** Update multiple LatestListingsQueryParent component instances */
  update?: InputMaybe<
    Array<LatestListingsQueryParentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple LatestListingsQueryParent component instances */
  upsert?: InputMaybe<
    Array<LatestListingsQueryParentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type LatestListingsQueryParentUpdateManyWithNestedWhereInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateManyWithNestedWhereInput>;
};

export type LatestListingsQueryParentUpdateOneInlineInput = {
  /** Create and connect one LatestListingsQueryParent document */
  create?: InputMaybe<LatestListingsQueryParentCreateInput>;
  /** Delete currently connected LatestListingsQueryParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LatestListingsQueryParent document */
  update?: InputMaybe<LatestListingsQueryParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LatestListingsQueryParent document */
  upsert?: InputMaybe<LatestListingsQueryParentUpsertWithNestedWhereUniqueInput>;
};

export type LatestListingsQueryParentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type LatestListingsQueryParentUpdateWithNestedWhereUniqueInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueInput>;
};

export type LatestListingsQueryParentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type LatestListingsQueryParentUpsertWithNestedWhereUniqueInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueInput>;
};

export type LatestListingsQueryParentWhereInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdWhereInput>;
};

export type LatestListingsQueryParentWhereUniqueInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdWhereUniqueInput>;
};

export type LatestListingsQueryUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  collectionContractAddress?: InputMaybe<Array<Scalars["String"]["input"]>>;
  includeOtherMarketplaces?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LatestListingsQueryUpdateManyInlineInput = {
  /** Create and connect multiple LatestListingsQuery component instances */
  create?: InputMaybe<Array<LatestListingsQueryCreateWithPositionInput>>;
  /** Delete multiple LatestListingsQuery documents */
  delete?: InputMaybe<Array<LatestListingsQueryWhereUniqueInput>>;
  /** Update multiple LatestListingsQuery component instances */
  update?: InputMaybe<
    Array<LatestListingsQueryUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple LatestListingsQuery component instances */
  upsert?: InputMaybe<
    Array<LatestListingsQueryUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type LatestListingsQueryUpdateManyInput = {
  collectionContractAddress?: InputMaybe<Array<Scalars["String"]["input"]>>;
  includeOtherMarketplaces?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type LatestListingsQueryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: LatestListingsQueryUpdateManyInput;
  /** Document search */
  where: LatestListingsQueryWhereInput;
};

export type LatestListingsQueryUpdateOneInlineInput = {
  /** Create and connect one LatestListingsQuery document */
  create?: InputMaybe<LatestListingsQueryCreateInput>;
  /** Delete currently connected LatestListingsQuery document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single LatestListingsQuery document */
  update?: InputMaybe<LatestListingsQueryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single LatestListingsQuery document */
  upsert?: InputMaybe<LatestListingsQueryUpsertWithNestedWhereUniqueInput>;
};

export type LatestListingsQueryUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<LatestListingsQueryUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LatestListingsQueryWhereUniqueInput;
};

export type LatestListingsQueryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: LatestListingsQueryUpdateInput;
  /** Unique document search */
  where: LatestListingsQueryWhereUniqueInput;
};

export type LatestListingsQueryUpsertInput = {
  /** Create document if it didn't exist */
  create: LatestListingsQueryCreateInput;
  /** Update document if it exists */
  update: LatestListingsQueryUpdateInput;
};

export type LatestListingsQueryUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<LatestListingsQueryUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: LatestListingsQueryWhereUniqueInput;
};

export type LatestListingsQueryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: LatestListingsQueryUpsertInput;
  /** Unique document search */
  where: LatestListingsQueryWhereUniqueInput;
};

/** Identifies documents */
export type LatestListingsQueryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<LatestListingsQueryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<LatestListingsQueryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<LatestListingsQueryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  collectionContractAddress?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  collectionContractAddress_contains_all?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  /** Matches if the field array does not contain any of the items provided to the filter */
  collectionContractAddress_contains_none?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  /** Matches if the field array contains at least one item provided to the filter */
  collectionContractAddress_contains_some?: InputMaybe<
    Array<Scalars["String"]["input"]>
  >;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  collectionContractAddress_not?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  includeOtherMarketplaces?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  includeOtherMarketplaces_not?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** References LatestListingsQuery record uniquely */
export type LatestListingsQueryWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Locale system enumeration */
export enum Locale {
  /** System locale */
  en = "en",
}

/** Representing a geolocation point with latitude and longitude */
export type Location = {
  __typename?: "Location";
  distance: Scalars["Float"]["output"];
  latitude: Scalars["Float"]["output"];
  longitude: Scalars["Float"]["output"];
};

/** Representing a geolocation point with latitude and longitude */
export type LocationDistanceArgs = {
  from: LocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type LocationInput = {
  latitude: Scalars["Float"]["input"];
  longitude: Scalars["Float"]["input"];
};

export type MarketplaceAnnouncementBanner = Entity &
  Node & {
    __typename?: "MarketplaceAnnouncementBanner";
    announcementText?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MarketplaceAnnouncementBanner>;
    /** List of MarketplaceAnnouncementBanner versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MarketplaceAnnouncementBannerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplaceAnnouncementBannerDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MarketplaceAnnouncementBannerHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MarketplaceAnnouncementBannerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplaceAnnouncementBannerScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MarketplaceAnnouncementBannerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplaceAnnouncementBannerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

/** A connection to a list of items. */
export type MarketplaceAnnouncementBannerConnection = {
  __typename?: "MarketplaceAnnouncementBannerConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MarketplaceAnnouncementBannerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MarketplaceAnnouncementBannerCreateInput = {
  announcementText?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MarketplaceAnnouncementBannerCreateManyInlineInput = {
  /** Connect multiple existing MarketplaceAnnouncementBanner documents */
  connect?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereUniqueInput>>;
  /** Create and connect multiple existing MarketplaceAnnouncementBanner documents */
  create?: InputMaybe<Array<MarketplaceAnnouncementBannerCreateInput>>;
};

export type MarketplaceAnnouncementBannerCreateOneInlineInput = {
  /** Connect one existing MarketplaceAnnouncementBanner document */
  connect?: InputMaybe<MarketplaceAnnouncementBannerWhereUniqueInput>;
  /** Create and connect one MarketplaceAnnouncementBanner document */
  create?: InputMaybe<MarketplaceAnnouncementBannerCreateInput>;
};

/** An edge in a connection. */
export type MarketplaceAnnouncementBannerEdge = {
  __typename?: "MarketplaceAnnouncementBannerEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MarketplaceAnnouncementBanner;
};

/** Identifies documents */
export type MarketplaceAnnouncementBannerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  announcementText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  announcementText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  announcementText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  announcementText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  announcementText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  announcementText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  announcementText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  announcementText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  announcementText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  announcementText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MarketplaceAnnouncementBannerWhereStageInput>;
  documentInStages_none?: InputMaybe<MarketplaceAnnouncementBannerWhereStageInput>;
  documentInStages_some?: InputMaybe<MarketplaceAnnouncementBannerWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MarketplaceAnnouncementBannerOrderByInput {
  announcementText_ASC = "announcementText_ASC",
  announcementText_DESC = "announcementText_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MarketplaceAnnouncementBannerUpdateInput = {
  announcementText?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarketplaceAnnouncementBannerUpdateManyInlineInput = {
  /** Connect multiple existing MarketplaceAnnouncementBanner documents */
  connect?: InputMaybe<Array<MarketplaceAnnouncementBannerConnectInput>>;
  /** Create and connect multiple MarketplaceAnnouncementBanner documents */
  create?: InputMaybe<Array<MarketplaceAnnouncementBannerCreateInput>>;
  /** Delete multiple MarketplaceAnnouncementBanner documents */
  delete?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereUniqueInput>>;
  /** Disconnect multiple MarketplaceAnnouncementBanner documents */
  disconnect?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MarketplaceAnnouncementBanner documents */
  set?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereUniqueInput>>;
  /** Update multiple MarketplaceAnnouncementBanner documents */
  update?: InputMaybe<
    Array<MarketplaceAnnouncementBannerUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MarketplaceAnnouncementBanner documents */
  upsert?: InputMaybe<
    Array<MarketplaceAnnouncementBannerUpsertWithNestedWhereUniqueInput>
  >;
};

export type MarketplaceAnnouncementBannerUpdateManyInput = {
  announcementText?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarketplaceAnnouncementBannerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MarketplaceAnnouncementBannerUpdateManyInput;
  /** Document search */
  where: MarketplaceAnnouncementBannerWhereInput;
};

export type MarketplaceAnnouncementBannerUpdateOneInlineInput = {
  /** Connect existing MarketplaceAnnouncementBanner document */
  connect?: InputMaybe<MarketplaceAnnouncementBannerWhereUniqueInput>;
  /** Create and connect one MarketplaceAnnouncementBanner document */
  create?: InputMaybe<MarketplaceAnnouncementBannerCreateInput>;
  /** Delete currently connected MarketplaceAnnouncementBanner document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MarketplaceAnnouncementBanner document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MarketplaceAnnouncementBanner document */
  update?: InputMaybe<MarketplaceAnnouncementBannerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MarketplaceAnnouncementBanner document */
  upsert?: InputMaybe<MarketplaceAnnouncementBannerUpsertWithNestedWhereUniqueInput>;
};

export type MarketplaceAnnouncementBannerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MarketplaceAnnouncementBannerUpdateInput;
  /** Unique document search */
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MarketplaceAnnouncementBannerUpsertInput = {
  /** Create document if it didn't exist */
  create: MarketplaceAnnouncementBannerCreateInput;
  /** Update document if it exists */
  update: MarketplaceAnnouncementBannerUpdateInput;
};

export type MarketplaceAnnouncementBannerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MarketplaceAnnouncementBannerUpsertInput;
  /** Unique document search */
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MarketplaceAnnouncementBannerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MarketplaceAnnouncementBannerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  announcementText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  announcementText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  announcementText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  announcementText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  announcementText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  announcementText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  announcementText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  announcementText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  announcementText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  announcementText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MarketplaceAnnouncementBannerWhereStageInput>;
  documentInStages_none?: InputMaybe<MarketplaceAnnouncementBannerWhereStageInput>;
  documentInStages_some?: InputMaybe<MarketplaceAnnouncementBannerWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MarketplaceAnnouncementBannerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplaceAnnouncementBannerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MarketplaceAnnouncementBannerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MarketplaceAnnouncementBanner record uniquely */
export type MarketplaceAnnouncementBannerWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MarketplaceContract = Entity &
  Node & {
    __typename?: "MarketplaceContract";
    chain?: Maybe<Chain>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MarketplaceContract>;
    /** List of MarketplaceContract versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    marketplaceContractAddress?: Maybe<Scalars["String"]["output"]>;
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    transactionFee?: Maybe<Scalars["Float"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MarketplaceContractChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplaceContractCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplaceContractDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MarketplaceContractHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MarketplaceContractNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type MarketplaceContractPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplaceContractScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MarketplaceContractUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplaceContractConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MarketplaceContractWhereUniqueInput;
};

/** A connection to a list of items. */
export type MarketplaceContractConnection = {
  __typename?: "MarketplaceContractConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MarketplaceContractEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MarketplaceContractCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  marketplaceContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  transactionFee?: InputMaybe<Scalars["Float"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MarketplaceContractCreateManyInlineInput = {
  /** Connect multiple existing MarketplaceContract documents */
  connect?: InputMaybe<Array<MarketplaceContractWhereUniqueInput>>;
  /** Create and connect multiple existing MarketplaceContract documents */
  create?: InputMaybe<Array<MarketplaceContractCreateInput>>;
};

export type MarketplaceContractCreateOneInlineInput = {
  /** Connect one existing MarketplaceContract document */
  connect?: InputMaybe<MarketplaceContractWhereUniqueInput>;
  /** Create and connect one MarketplaceContract document */
  create?: InputMaybe<MarketplaceContractCreateInput>;
};

/** An edge in a connection. */
export type MarketplaceContractEdge = {
  __typename?: "MarketplaceContractEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MarketplaceContract;
};

/** Identifies documents */
export type MarketplaceContractManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplaceContractWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplaceContractWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplaceContractWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MarketplaceContractWhereStageInput>;
  documentInStages_none?: InputMaybe<MarketplaceContractWhereStageInput>;
  documentInStages_some?: InputMaybe<MarketplaceContractWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  marketplaceContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  marketplaceContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  marketplaceContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  marketplaceContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  marketplaceContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  marketplaceContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  marketplaceContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  marketplaceContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  marketplaceContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  marketplaceContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  transactionFee?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  transactionFee_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  transactionFee_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  transactionFee_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  transactionFee_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  transactionFee_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  transactionFee_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  transactionFee_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MarketplaceContractOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  marketplaceContractAddress_ASC = "marketplaceContractAddress_ASC",
  marketplaceContractAddress_DESC = "marketplaceContractAddress_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  transactionFee_ASC = "transactionFee_ASC",
  transactionFee_DESC = "transactionFee_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MarketplaceContractUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  marketplaceContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  transactionFee?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MarketplaceContractUpdateManyInlineInput = {
  /** Connect multiple existing MarketplaceContract documents */
  connect?: InputMaybe<Array<MarketplaceContractConnectInput>>;
  /** Create and connect multiple MarketplaceContract documents */
  create?: InputMaybe<Array<MarketplaceContractCreateInput>>;
  /** Delete multiple MarketplaceContract documents */
  delete?: InputMaybe<Array<MarketplaceContractWhereUniqueInput>>;
  /** Disconnect multiple MarketplaceContract documents */
  disconnect?: InputMaybe<Array<MarketplaceContractWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MarketplaceContract documents */
  set?: InputMaybe<Array<MarketplaceContractWhereUniqueInput>>;
  /** Update multiple MarketplaceContract documents */
  update?: InputMaybe<
    Array<MarketplaceContractUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MarketplaceContract documents */
  upsert?: InputMaybe<
    Array<MarketplaceContractUpsertWithNestedWhereUniqueInput>
  >;
};

export type MarketplaceContractUpdateManyInput = {
  transactionFee?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MarketplaceContractUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MarketplaceContractUpdateManyInput;
  /** Document search */
  where: MarketplaceContractWhereInput;
};

export type MarketplaceContractUpdateOneInlineInput = {
  /** Connect existing MarketplaceContract document */
  connect?: InputMaybe<MarketplaceContractWhereUniqueInput>;
  /** Create and connect one MarketplaceContract document */
  create?: InputMaybe<MarketplaceContractCreateInput>;
  /** Delete currently connected MarketplaceContract document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MarketplaceContract document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MarketplaceContract document */
  update?: InputMaybe<MarketplaceContractUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MarketplaceContract document */
  upsert?: InputMaybe<MarketplaceContractUpsertWithNestedWhereUniqueInput>;
};

export type MarketplaceContractUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MarketplaceContractUpdateInput;
  /** Unique document search */
  where: MarketplaceContractWhereUniqueInput;
};

export type MarketplaceContractUpsertInput = {
  /** Create document if it didn't exist */
  create: MarketplaceContractCreateInput;
  /** Update document if it exists */
  update: MarketplaceContractUpdateInput;
};

export type MarketplaceContractUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MarketplaceContractUpsertInput;
  /** Unique document search */
  where: MarketplaceContractWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MarketplaceContractWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MarketplaceContractWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplaceContractWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplaceContractWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplaceContractWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MarketplaceContractWhereStageInput>;
  documentInStages_none?: InputMaybe<MarketplaceContractWhereStageInput>;
  documentInStages_some?: InputMaybe<MarketplaceContractWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  marketplaceContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  marketplaceContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  marketplaceContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  marketplaceContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  marketplaceContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  marketplaceContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  marketplaceContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  marketplaceContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  marketplaceContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  marketplaceContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  transactionFee?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  transactionFee_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  transactionFee_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  transactionFee_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  transactionFee_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  transactionFee_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  transactionFee_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  transactionFee_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MarketplaceContractWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplaceContractWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplaceContractWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplaceContractWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MarketplaceContractWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MarketplaceContract record uniquely */
export type MarketplaceContractWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  marketplaceContractAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarketplacePage = Entity &
  Node & {
    __typename?: "MarketplacePage";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MarketplacePage>;
    /** List of MarketplacePage versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    pageComponentSections: Array<MarketplacePagepageComponentSectionsUnion>;
    /** optional */
    pageSeoDescription?: Maybe<Scalars["String"]["output"]>;
    pageTitle?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MarketplacePageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplacePageDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MarketplacePageHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MarketplacePagePageComponentSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MarketplacePagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplacePageScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MarketplacePageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplacePageComponent = Entity & {
  __typename?: "MarketplacePageComponent";
  chain?: Maybe<Chain>;
  componentHeading?: Maybe<Scalars["String"]["output"]>;
  componentName: ComponentName;
  /** Optional */
  componentSubHeading?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** Optional */
  sideText?: Maybe<RichText>;
  /** System stage field */
  stage: Stage;
};

export type MarketplacePageComponentChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MarketplacePageComponentConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MarketplacePageComponentWhereUniqueInput;
};

/** A connection to a list of items. */
export type MarketplacePageComponentConnection = {
  __typename?: "MarketplacePageComponentConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MarketplacePageComponentEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MarketplacePageComponentCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  componentHeading?: InputMaybe<Scalars["String"]["input"]>;
  componentName: ComponentName;
  componentSubHeading?: InputMaybe<Scalars["String"]["input"]>;
  sideText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MarketplacePageComponentCreateManyInlineInput = {
  /** Create and connect multiple existing MarketplacePageComponent documents */
  create?: InputMaybe<Array<MarketplacePageComponentCreateInput>>;
};

export type MarketplacePageComponentCreateOneInlineInput = {
  /** Create and connect one MarketplacePageComponent document */
  create?: InputMaybe<MarketplacePageComponentCreateInput>;
};

export type MarketplacePageComponentCreateWithPositionInput = {
  /** Document to create */
  data: MarketplacePageComponentCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MarketplacePageComponentEdge = {
  __typename?: "MarketplacePageComponentEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MarketplacePageComponent;
};

/** Identifies documents */
export type MarketplacePageComponentManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplacePageComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplacePageComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplacePageComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  componentHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  componentHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  componentHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  componentHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  componentHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  componentHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  componentHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  componentHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  componentHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  componentHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  componentName?: InputMaybe<ComponentName>;
  /** All values that are contained in given list. */
  componentName_in?: InputMaybe<Array<InputMaybe<ComponentName>>>;
  /** Any other value that exists and is not equal to the given value. */
  componentName_not?: InputMaybe<ComponentName>;
  /** All values that are not contained in given list. */
  componentName_not_in?: InputMaybe<Array<InputMaybe<ComponentName>>>;
  componentSubHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  componentSubHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  componentSubHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  componentSubHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  componentSubHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  componentSubHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  componentSubHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  componentSubHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  componentSubHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  componentSubHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum MarketplacePageComponentOrderByInput {
  componentHeading_ASC = "componentHeading_ASC",
  componentHeading_DESC = "componentHeading_DESC",
  componentName_ASC = "componentName_ASC",
  componentName_DESC = "componentName_DESC",
  componentSubHeading_ASC = "componentSubHeading_ASC",
  componentSubHeading_DESC = "componentSubHeading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
}

export type MarketplacePageComponentParent = MarketplacePage;

export type MarketplacePageComponentParentConnectInput = {
  MarketplacePage?: InputMaybe<MarketplacePageConnectInput>;
};

export type MarketplacePageComponentParentCreateInput = {
  MarketplacePage?: InputMaybe<MarketplacePageCreateInput>;
};

export type MarketplacePageComponentParentCreateManyInlineInput = {
  /** Connect multiple existing MarketplacePageComponentParent documents */
  connect?: InputMaybe<Array<MarketplacePageComponentParentWhereUniqueInput>>;
  /** Create and connect multiple existing MarketplacePageComponentParent documents */
  create?: InputMaybe<Array<MarketplacePageComponentParentCreateInput>>;
};

export type MarketplacePageComponentParentCreateOneInlineInput = {
  /** Connect one existing MarketplacePageComponentParent document */
  connect?: InputMaybe<MarketplacePageComponentParentWhereUniqueInput>;
  /** Create and connect one MarketplacePageComponentParent document */
  create?: InputMaybe<MarketplacePageComponentParentCreateInput>;
};

export type MarketplacePageComponentParentUpdateInput = {
  MarketplacePage?: InputMaybe<MarketplacePageUpdateInput>;
};

export type MarketplacePageComponentParentUpdateManyInlineInput = {
  /** Connect multiple existing MarketplacePageComponentParent documents */
  connect?: InputMaybe<Array<MarketplacePageComponentParentConnectInput>>;
  /** Create and connect multiple MarketplacePageComponentParent documents */
  create?: InputMaybe<Array<MarketplacePageComponentParentCreateInput>>;
  /** Delete multiple MarketplacePageComponentParent documents */
  delete?: InputMaybe<Array<MarketplacePageComponentParentWhereUniqueInput>>;
  /** Disconnect multiple MarketplacePageComponentParent documents */
  disconnect?: InputMaybe<
    Array<MarketplacePageComponentParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing MarketplacePageComponentParent documents */
  set?: InputMaybe<Array<MarketplacePageComponentParentWhereUniqueInput>>;
  /** Update multiple MarketplacePageComponentParent documents */
  update?: InputMaybe<
    Array<MarketplacePageComponentParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MarketplacePageComponentParent documents */
  upsert?: InputMaybe<
    Array<MarketplacePageComponentParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type MarketplacePageComponentParentUpdateManyWithNestedWhereInput = {
  MarketplacePage?: InputMaybe<MarketplacePageUpdateManyWithNestedWhereInput>;
};

export type MarketplacePageComponentParentUpdateOneInlineInput = {
  /** Connect existing MarketplacePageComponentParent document */
  connect?: InputMaybe<MarketplacePageComponentParentWhereUniqueInput>;
  /** Create and connect one MarketplacePageComponentParent document */
  create?: InputMaybe<MarketplacePageComponentParentCreateInput>;
  /** Delete currently connected MarketplacePageComponentParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MarketplacePageComponentParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MarketplacePageComponentParent document */
  update?: InputMaybe<MarketplacePageComponentParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MarketplacePageComponentParent document */
  upsert?: InputMaybe<MarketplacePageComponentParentUpsertWithNestedWhereUniqueInput>;
};

export type MarketplacePageComponentParentUpdateWithNestedWhereUniqueInput = {
  MarketplacePage?: InputMaybe<MarketplacePageUpdateWithNestedWhereUniqueInput>;
};

export type MarketplacePageComponentParentUpsertWithNestedWhereUniqueInput = {
  MarketplacePage?: InputMaybe<MarketplacePageUpsertWithNestedWhereUniqueInput>;
};

export type MarketplacePageComponentParentWhereInput = {
  MarketplacePage?: InputMaybe<MarketplacePageWhereInput>;
};

export type MarketplacePageComponentParentWhereUniqueInput = {
  MarketplacePage?: InputMaybe<MarketplacePageWhereUniqueInput>;
};

export type MarketplacePageComponentUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  componentHeading?: InputMaybe<Scalars["String"]["input"]>;
  componentName?: InputMaybe<ComponentName>;
  componentSubHeading?: InputMaybe<Scalars["String"]["input"]>;
  sideText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MarketplacePageComponentUpdateManyInlineInput = {
  /** Create and connect multiple MarketplacePageComponent component instances */
  create?: InputMaybe<Array<MarketplacePageComponentCreateWithPositionInput>>;
  /** Delete multiple MarketplacePageComponent documents */
  delete?: InputMaybe<Array<MarketplacePageComponentWhereUniqueInput>>;
  /** Update multiple MarketplacePageComponent component instances */
  update?: InputMaybe<
    Array<MarketplacePageComponentUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MarketplacePageComponent component instances */
  upsert?: InputMaybe<
    Array<MarketplacePageComponentUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MarketplacePageComponentUpdateManyInput = {
  componentHeading?: InputMaybe<Scalars["String"]["input"]>;
  componentName?: InputMaybe<ComponentName>;
  componentSubHeading?: InputMaybe<Scalars["String"]["input"]>;
  sideText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MarketplacePageComponentUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MarketplacePageComponentUpdateManyInput;
  /** Document search */
  where: MarketplacePageComponentWhereInput;
};

export type MarketplacePageComponentUpdateOneInlineInput = {
  /** Create and connect one MarketplacePageComponent document */
  create?: InputMaybe<MarketplacePageComponentCreateInput>;
  /** Delete currently connected MarketplacePageComponent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MarketplacePageComponent document */
  update?: InputMaybe<MarketplacePageComponentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MarketplacePageComponent document */
  upsert?: InputMaybe<MarketplacePageComponentUpsertWithNestedWhereUniqueInput>;
};

export type MarketplacePageComponentUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<MarketplacePageComponentUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MarketplacePageComponentWhereUniqueInput;
  };

export type MarketplacePageComponentUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MarketplacePageComponentUpdateInput;
  /** Unique document search */
  where: MarketplacePageComponentWhereUniqueInput;
};

export type MarketplacePageComponentUpsertInput = {
  /** Create document if it didn't exist */
  create: MarketplacePageComponentCreateInput;
  /** Update document if it exists */
  update: MarketplacePageComponentUpdateInput;
};

export type MarketplacePageComponentUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<MarketplacePageComponentUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MarketplacePageComponentWhereUniqueInput;
  };

export type MarketplacePageComponentUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MarketplacePageComponentUpsertInput;
  /** Unique document search */
  where: MarketplacePageComponentWhereUniqueInput;
};

/** Identifies documents */
export type MarketplacePageComponentWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplacePageComponentWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplacePageComponentWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplacePageComponentWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  componentHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  componentHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  componentHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  componentHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  componentHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  componentHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  componentHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  componentHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  componentHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  componentHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  componentName?: InputMaybe<ComponentName>;
  /** All values that are contained in given list. */
  componentName_in?: InputMaybe<Array<InputMaybe<ComponentName>>>;
  /** Any other value that exists and is not equal to the given value. */
  componentName_not?: InputMaybe<ComponentName>;
  /** All values that are not contained in given list. */
  componentName_not_in?: InputMaybe<Array<InputMaybe<ComponentName>>>;
  componentSubHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  componentSubHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  componentSubHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  componentSubHeading_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  componentSubHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  componentSubHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  componentSubHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  componentSubHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  componentSubHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  componentSubHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

/** References MarketplacePageComponent record uniquely */
export type MarketplacePageComponentWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MarketplacePageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MarketplacePageWhereUniqueInput;
};

/** A connection to a list of items. */
export type MarketplacePageConnection = {
  __typename?: "MarketplacePageConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MarketplacePageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MarketplacePageCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  pageComponentSections?: InputMaybe<MarketplacePagepageComponentSectionsUnionCreateManyInlineInput>;
  pageSeoDescription?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MarketplacePageCreateManyInlineInput = {
  /** Connect multiple existing MarketplacePage documents */
  connect?: InputMaybe<Array<MarketplacePageWhereUniqueInput>>;
  /** Create and connect multiple existing MarketplacePage documents */
  create?: InputMaybe<Array<MarketplacePageCreateInput>>;
};

export type MarketplacePageCreateOneInlineInput = {
  /** Connect one existing MarketplacePage document */
  connect?: InputMaybe<MarketplacePageWhereUniqueInput>;
  /** Create and connect one MarketplacePage document */
  create?: InputMaybe<MarketplacePageCreateInput>;
};

/** An edge in a connection. */
export type MarketplacePageEdge = {
  __typename?: "MarketplacePageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MarketplacePage;
};

/** Identifies documents */
export type MarketplacePageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplacePageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplacePageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplacePageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MarketplacePageWhereStageInput>;
  documentInStages_none?: InputMaybe<MarketplacePageWhereStageInput>;
  documentInStages_some?: InputMaybe<MarketplacePageWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values in which the union is empty. */
  pageComponentSections_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  pageComponentSections_some?: InputMaybe<MarketplacePagepageComponentSectionsUnionWhereInput>;
  pageSeoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageSeoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageSeoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageSeoDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  pageSeoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageSeoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageSeoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageSeoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  pageSeoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageSeoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MarketplacePageOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  pageSeoDescription_ASC = "pageSeoDescription_ASC",
  pageSeoDescription_DESC = "pageSeoDescription_DESC",
  pageTitle_ASC = "pageTitle_ASC",
  pageTitle_DESC = "pageTitle_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MarketplacePageUpdateInput = {
  pageComponentSections?: InputMaybe<MarketplacePagepageComponentSectionsUnionUpdateManyInlineInput>;
  pageSeoDescription?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarketplacePageUpdateManyInlineInput = {
  /** Connect multiple existing MarketplacePage documents */
  connect?: InputMaybe<Array<MarketplacePageConnectInput>>;
  /** Create and connect multiple MarketplacePage documents */
  create?: InputMaybe<Array<MarketplacePageCreateInput>>;
  /** Delete multiple MarketplacePage documents */
  delete?: InputMaybe<Array<MarketplacePageWhereUniqueInput>>;
  /** Disconnect multiple MarketplacePage documents */
  disconnect?: InputMaybe<Array<MarketplacePageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MarketplacePage documents */
  set?: InputMaybe<Array<MarketplacePageWhereUniqueInput>>;
  /** Update multiple MarketplacePage documents */
  update?: InputMaybe<Array<MarketplacePageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MarketplacePage documents */
  upsert?: InputMaybe<Array<MarketplacePageUpsertWithNestedWhereUniqueInput>>;
};

export type MarketplacePageUpdateManyInput = {
  pageSeoDescription?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type MarketplacePageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MarketplacePageUpdateManyInput;
  /** Document search */
  where: MarketplacePageWhereInput;
};

export type MarketplacePageUpdateOneInlineInput = {
  /** Connect existing MarketplacePage document */
  connect?: InputMaybe<MarketplacePageWhereUniqueInput>;
  /** Create and connect one MarketplacePage document */
  create?: InputMaybe<MarketplacePageCreateInput>;
  /** Delete currently connected MarketplacePage document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MarketplacePage document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MarketplacePage document */
  update?: InputMaybe<MarketplacePageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MarketplacePage document */
  upsert?: InputMaybe<MarketplacePageUpsertWithNestedWhereUniqueInput>;
};

export type MarketplacePageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MarketplacePageUpdateInput;
  /** Unique document search */
  where: MarketplacePageWhereUniqueInput;
};

export type MarketplacePageUpsertInput = {
  /** Create document if it didn't exist */
  create: MarketplacePageCreateInput;
  /** Update document if it exists */
  update: MarketplacePageUpdateInput;
};

export type MarketplacePageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MarketplacePageUpsertInput;
  /** Unique document search */
  where: MarketplacePageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MarketplacePageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MarketplacePageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplacePageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplacePageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplacePageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MarketplacePageWhereStageInput>;
  documentInStages_none?: InputMaybe<MarketplacePageWhereStageInput>;
  documentInStages_some?: InputMaybe<MarketplacePageWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values in which the union is empty. */
  pageComponentSections_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  pageComponentSections_some?: InputMaybe<MarketplacePagepageComponentSectionsUnionWhereInput>;
  pageSeoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageSeoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageSeoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageSeoDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  pageSeoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageSeoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageSeoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageSeoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  pageSeoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageSeoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MarketplacePageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MarketplacePageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MarketplacePageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MarketplacePageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MarketplacePageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MarketplacePage record uniquely */
export type MarketplacePageWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MarketplacePagepageComponentSectionsUnion =
  | LatestListingsByCollectionAndChainId
  | MarketplacePageComponent
  | TrendingCollectionsCarousel;

export type MarketplacePagepageComponentSectionsUnionConnectInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdConnectInput>;
  MarketplacePageComponent?: InputMaybe<MarketplacePageComponentConnectInput>;
  TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselConnectInput>;
};

export type MarketplacePagepageComponentSectionsUnionCreateInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdCreateInput>;
  MarketplacePageComponent?: InputMaybe<MarketplacePageComponentCreateInput>;
  TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselCreateInput>;
};

export type MarketplacePagepageComponentSectionsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing MarketplacePagepageComponentSectionsUnion documents */
  create?: InputMaybe<
    Array<MarketplacePagepageComponentSectionsUnionCreateInput>
  >;
};

export type MarketplacePagepageComponentSectionsUnionCreateOneInlineInput = {
  /** Create and connect one MarketplacePagepageComponentSectionsUnion document */
  create?: InputMaybe<MarketplacePagepageComponentSectionsUnionCreateInput>;
};

export type MarketplacePagepageComponentSectionsUnionCreateWithPositionInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdCreateWithPositionInput>;
  MarketplacePageComponent?: InputMaybe<MarketplacePageComponentCreateWithPositionInput>;
  TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselCreateWithPositionInput>;
};

export type MarketplacePagepageComponentSectionsUnionUpdateInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateInput>;
  MarketplacePageComponent?: InputMaybe<MarketplacePageComponentUpdateInput>;
  TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselUpdateInput>;
};

export type MarketplacePagepageComponentSectionsUnionUpdateManyInlineInput = {
  /** Create and connect multiple MarketplacePagepageComponentSectionsUnion component instances */
  create?: InputMaybe<
    Array<MarketplacePagepageComponentSectionsUnionCreateWithPositionInput>
  >;
  /** Delete multiple MarketplacePagepageComponentSectionsUnion documents */
  delete?: InputMaybe<
    Array<MarketplacePagepageComponentSectionsUnionWhereUniqueInput>
  >;
  /** Update multiple MarketplacePagepageComponentSectionsUnion component instances */
  update?: InputMaybe<
    Array<MarketplacePagepageComponentSectionsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MarketplacePagepageComponentSectionsUnion component instances */
  upsert?: InputMaybe<
    Array<MarketplacePagepageComponentSectionsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MarketplacePagepageComponentSectionsUnionUpdateManyWithNestedWhereInput =
  {
    LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateManyWithNestedWhereInput>;
    MarketplacePageComponent?: InputMaybe<MarketplacePageComponentUpdateManyWithNestedWhereInput>;
    TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselUpdateManyWithNestedWhereInput>;
  };

export type MarketplacePagepageComponentSectionsUnionUpdateOneInlineInput = {
  /** Create and connect one MarketplacePagepageComponentSectionsUnion document */
  create?: InputMaybe<MarketplacePagepageComponentSectionsUnionCreateInput>;
  /** Delete currently connected MarketplacePagepageComponentSectionsUnion document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MarketplacePagepageComponentSectionsUnion document */
  update?: InputMaybe<MarketplacePagepageComponentSectionsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MarketplacePagepageComponentSectionsUnion document */
  upsert?: InputMaybe<MarketplacePagepageComponentSectionsUnionUpsertWithNestedWhereUniqueInput>;
};

export type MarketplacePagepageComponentSectionsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueAndPositionInput>;
    MarketplacePageComponent?: InputMaybe<MarketplacePageComponentUpdateWithNestedWhereUniqueAndPositionInput>;
    TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type MarketplacePagepageComponentSectionsUnionUpdateWithNestedWhereUniqueInput =
  {
    LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpdateWithNestedWhereUniqueInput>;
    MarketplacePageComponent?: InputMaybe<MarketplacePageComponentUpdateWithNestedWhereUniqueInput>;
    TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselUpdateWithNestedWhereUniqueInput>;
  };

export type MarketplacePagepageComponentSectionsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueAndPositionInput>;
    MarketplacePageComponent?: InputMaybe<MarketplacePageComponentUpsertWithNestedWhereUniqueAndPositionInput>;
    TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type MarketplacePagepageComponentSectionsUnionUpsertWithNestedWhereUniqueInput =
  {
    LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdUpsertWithNestedWhereUniqueInput>;
    MarketplacePageComponent?: InputMaybe<MarketplacePageComponentUpsertWithNestedWhereUniqueInput>;
    TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselUpsertWithNestedWhereUniqueInput>;
  };

export type MarketplacePagepageComponentSectionsUnionWhereInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdWhereInput>;
  MarketplacePageComponent?: InputMaybe<MarketplacePageComponentWhereInput>;
  TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselWhereInput>;
};

export type MarketplacePagepageComponentSectionsUnionWhereUniqueInput = {
  LatestListingsByCollectionAndChainId?: InputMaybe<LatestListingsByCollectionAndChainIdWhereUniqueInput>;
  MarketplacePageComponent?: InputMaybe<MarketplacePageComponentWhereUniqueInput>;
  TrendingCollectionsCarousel?: InputMaybe<TrendingCollectionsCarouselWhereUniqueInput>;
};

export type Menu = Entity &
  Node & {
    __typename?: "Menu";
    applicationId: AppId;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Menu>;
    /** List of Menu versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    menuGroupIcon?: Maybe<Asset>;
    menuGroupName?: Maybe<Scalars["String"]["output"]>;
    menuSection: MenuType;
    navigationItems: Array<NavigationItem>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MenuCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MenuHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MenuMenuGroupIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuNavigationItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NavigationItemOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NavigationItemWhereInput>;
};

export type MenuPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MenuUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MenuConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MenuWhereUniqueInput;
};

/** A connection to a list of items. */
export type MenuConnection = {
  __typename?: "MenuConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MenuEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MenuCreateInput = {
  applicationId: AppId;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  menuGroupIcon?: InputMaybe<AssetCreateOneInlineInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  menuSection: MenuType;
  navigationItems?: InputMaybe<NavigationItemCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MenuCreateManyInlineInput = {
  /** Connect multiple existing Menu documents */
  connect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Create and connect multiple existing Menu documents */
  create?: InputMaybe<Array<MenuCreateInput>>;
};

export type MenuCreateOneInlineInput = {
  /** Connect one existing Menu document */
  connect?: InputMaybe<MenuWhereUniqueInput>;
  /** Create and connect one Menu document */
  create?: InputMaybe<MenuCreateInput>;
};

/** An edge in a connection. */
export type MenuEdge = {
  __typename?: "MenuEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Menu;
};

/** Identifies documents */
export type MenuManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  applicationId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  applicationId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  applicationId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  applicationId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MenuWhereStageInput>;
  documentInStages_none?: InputMaybe<MenuWhereStageInput>;
  documentInStages_some?: InputMaybe<MenuWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  menuGroupIcon?: InputMaybe<AssetWhereInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  menuGroupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  menuGroupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  menuGroupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuGroupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  menuGroupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  menuGroupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  menuGroupName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  menuGroupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  menuGroupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
  /** All values that are contained in given list. */
  menuSection_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuSection_not?: InputMaybe<MenuType>;
  /** All values that are not contained in given list. */
  menuSection_not_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  navigationItems_every?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_none?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_some?: InputMaybe<NavigationItemWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MenuOrderByInput {
  applicationId_ASC = "applicationId_ASC",
  applicationId_DESC = "applicationId_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  menuGroupName_ASC = "menuGroupName_ASC",
  menuGroupName_DESC = "menuGroupName_DESC",
  menuSection_ASC = "menuSection_ASC",
  menuSection_DESC = "menuSection_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** Denotes the position this menu will show up in the sidebar navigation component on alpha shares sites */
export enum MenuType {
  AlphaSharesCollections = "AlphaSharesCollections",
  Ecosystem = "Ecosystem",
  Help = "Help",
  Legal = "Legal",
  Main = "Main",
  Security = "Security",
}

export type MenuUpdateInput = {
  applicationId?: InputMaybe<AppId>;
  menuGroupIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
  navigationItems?: InputMaybe<NavigationItemUpdateManyInlineInput>;
};

export type MenuUpdateManyInlineInput = {
  /** Connect multiple existing Menu documents */
  connect?: InputMaybe<Array<MenuConnectInput>>;
  /** Create and connect multiple Menu documents */
  create?: InputMaybe<Array<MenuCreateInput>>;
  /** Delete multiple Menu documents */
  delete?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Disconnect multiple Menu documents */
  disconnect?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Menu documents */
  set?: InputMaybe<Array<MenuWhereUniqueInput>>;
  /** Update multiple Menu documents */
  update?: InputMaybe<Array<MenuUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Menu documents */
  upsert?: InputMaybe<Array<MenuUpsertWithNestedWhereUniqueInput>>;
};

export type MenuUpdateManyInput = {
  applicationId?: InputMaybe<AppId>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
};

export type MenuUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MenuUpdateManyInput;
  /** Document search */
  where: MenuWhereInput;
};

export type MenuUpdateOneInlineInput = {
  /** Connect existing Menu document */
  connect?: InputMaybe<MenuWhereUniqueInput>;
  /** Create and connect one Menu document */
  create?: InputMaybe<MenuCreateInput>;
  /** Delete currently connected Menu document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Menu document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Menu document */
  update?: InputMaybe<MenuUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Menu document */
  upsert?: InputMaybe<MenuUpsertWithNestedWhereUniqueInput>;
};

export type MenuUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MenuUpdateInput;
  /** Unique document search */
  where: MenuWhereUniqueInput;
};

export type MenuUpsertInput = {
  /** Create document if it didn't exist */
  create: MenuCreateInput;
  /** Update document if it exists */
  update: MenuUpdateInput;
};

export type MenuUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MenuUpsertInput;
  /** Unique document search */
  where: MenuWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MenuWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MenuWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  applicationId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  applicationId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  applicationId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  applicationId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MenuWhereStageInput>;
  documentInStages_none?: InputMaybe<MenuWhereStageInput>;
  documentInStages_some?: InputMaybe<MenuWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  menuGroupIcon?: InputMaybe<AssetWhereInput>;
  menuGroupName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  menuGroupName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  menuGroupName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  menuGroupName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuGroupName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  menuGroupName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  menuGroupName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  menuGroupName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  menuGroupName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  menuGroupName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  menuSection?: InputMaybe<MenuType>;
  /** All values that are contained in given list. */
  menuSection_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  /** Any other value that exists and is not equal to the given value. */
  menuSection_not?: InputMaybe<MenuType>;
  /** All values that are not contained in given list. */
  menuSection_not_in?: InputMaybe<Array<InputMaybe<MenuType>>>;
  navigationItems_every?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_none?: InputMaybe<NavigationItemWhereInput>;
  navigationItems_some?: InputMaybe<NavigationItemWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MenuWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MenuWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MenuWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MenuWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MenuWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Menu record uniquely */
export type MenuWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningBtcSupplyDemandModelSection = Entity &
  Node & {
    __typename?: "MiningBtcSupplyDemandModelSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningBtcSupplyDemandModelSection>;
    /** List of MiningBtcSupplyDemandModelSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    projectionImage?: Maybe<Asset>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningBtcSupplyDemandModelSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningBtcSupplyDemandModelSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningBtcSupplyDemandModelSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningBtcSupplyDemandModelSectionProjectionImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningBtcSupplyDemandModelSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningBtcSupplyDemandModelSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningBtcSupplyDemandModelSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningBtcSupplyDemandModelSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningBtcSupplyDemandModelSectionConnection = {
  __typename?: "MiningBtcSupplyDemandModelSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningBtcSupplyDemandModelSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningBtcSupplyDemandModelSectionCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  projectionImage?: InputMaybe<AssetCreateOneInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningBtcSupplyDemandModelSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningBtcSupplyDemandModelSection documents */
  connect?: InputMaybe<
    Array<MiningBtcSupplyDemandModelSectionWhereUniqueInput>
  >;
  /** Create and connect multiple existing MiningBtcSupplyDemandModelSection documents */
  create?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionCreateInput>>;
};

export type MiningBtcSupplyDemandModelSectionCreateOneInlineInput = {
  /** Connect one existing MiningBtcSupplyDemandModelSection document */
  connect?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereUniqueInput>;
  /** Create and connect one MiningBtcSupplyDemandModelSection document */
  create?: InputMaybe<MiningBtcSupplyDemandModelSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningBtcSupplyDemandModelSectionEdge = {
  __typename?: "MiningBtcSupplyDemandModelSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningBtcSupplyDemandModelSection;
};

/** Identifies documents */
export type MiningBtcSupplyDemandModelSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  projectionImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningBtcSupplyDemandModelSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningBtcSupplyDemandModelSectionUpdateInput = {
  projectionImage?: InputMaybe<AssetUpdateOneInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningBtcSupplyDemandModelSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningBtcSupplyDemandModelSection documents */
  connect?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionConnectInput>>;
  /** Create and connect multiple MiningBtcSupplyDemandModelSection documents */
  create?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionCreateInput>>;
  /** Delete multiple MiningBtcSupplyDemandModelSection documents */
  delete?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningBtcSupplyDemandModelSection documents */
  disconnect?: InputMaybe<
    Array<MiningBtcSupplyDemandModelSectionWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing MiningBtcSupplyDemandModelSection documents */
  set?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereUniqueInput>>;
  /** Update multiple MiningBtcSupplyDemandModelSection documents */
  update?: InputMaybe<
    Array<MiningBtcSupplyDemandModelSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningBtcSupplyDemandModelSection documents */
  upsert?: InputMaybe<
    Array<MiningBtcSupplyDemandModelSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningBtcSupplyDemandModelSectionUpdateManyInput = {
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningBtcSupplyDemandModelSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningBtcSupplyDemandModelSectionUpdateManyInput;
  /** Document search */
  where: MiningBtcSupplyDemandModelSectionWhereInput;
};

export type MiningBtcSupplyDemandModelSectionUpdateOneInlineInput = {
  /** Connect existing MiningBtcSupplyDemandModelSection document */
  connect?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereUniqueInput>;
  /** Create and connect one MiningBtcSupplyDemandModelSection document */
  create?: InputMaybe<MiningBtcSupplyDemandModelSectionCreateInput>;
  /** Delete currently connected MiningBtcSupplyDemandModelSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningBtcSupplyDemandModelSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningBtcSupplyDemandModelSection document */
  update?: InputMaybe<MiningBtcSupplyDemandModelSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningBtcSupplyDemandModelSection document */
  upsert?: InputMaybe<MiningBtcSupplyDemandModelSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningBtcSupplyDemandModelSectionUpdateWithNestedWhereUniqueInput =
  {
    /** Document to update */
    data: MiningBtcSupplyDemandModelSectionUpdateInput;
    /** Unique document search */
    where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
  };

export type MiningBtcSupplyDemandModelSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningBtcSupplyDemandModelSectionCreateInput;
  /** Update document if it exists */
  update: MiningBtcSupplyDemandModelSectionUpdateInput;
};

export type MiningBtcSupplyDemandModelSectionUpsertWithNestedWhereUniqueInput =
  {
    /** Upsert data */
    data: MiningBtcSupplyDemandModelSectionUpsertInput;
    /** Unique document search */
    where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
  };

/** This contains a set of filters that can be used to compare values internally */
export type MiningBtcSupplyDemandModelSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningBtcSupplyDemandModelSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  projectionImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningBtcSupplyDemandModelSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningBtcSupplyDemandModelSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningBtcSupplyDemandModelSection record uniquely */
export type MiningBtcSupplyDemandModelSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningContainer = Entity &
  Node & {
    __typename?: "MiningContainer";
    containerDisplayTitle?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    deployedMiners?: Maybe<Scalars["Int"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<MiningContainer>;
    /** List of MiningContainer versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    miningPoolUsername?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningContainerCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningContainerDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningContainerHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningContainerPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningContainerScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningContainerUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningContainerConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningContainerWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningContainerConnection = {
  __typename?: "MiningContainerConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningContainerEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningContainerCreateInput = {
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningContainerCreateManyInlineInput = {
  /** Connect multiple existing MiningContainer documents */
  connect?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Create and connect multiple existing MiningContainer documents */
  create?: InputMaybe<Array<MiningContainerCreateInput>>;
};

export type MiningContainerCreateOneInlineInput = {
  /** Connect one existing MiningContainer document */
  connect?: InputMaybe<MiningContainerWhereUniqueInput>;
  /** Create and connect one MiningContainer document */
  create?: InputMaybe<MiningContainerCreateInput>;
};

/** An edge in a connection. */
export type MiningContainerEdge = {
  __typename?: "MiningContainerEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningContainer;
};

/** Identifies documents */
export type MiningContainerManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  containerDisplayTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  containerDisplayTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  containerDisplayTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  containerDisplayTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  containerDisplayTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  containerDisplayTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  containerDisplayTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  containerDisplayTitle_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  containerDisplayTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  deployedMiners_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  deployedMiners_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  deployedMiners_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  deployedMiners_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  deployedMiners_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  deployedMiners_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  deployedMiners_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningContainerWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  miningPoolUsername_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  miningPoolUsername_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  miningPoolUsername_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  miningPoolUsername_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  miningPoolUsername_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  miningPoolUsername_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  miningPoolUsername_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  miningPoolUsername_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  miningPoolUsername_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningContainerOrderByInput {
  containerDisplayTitle_ASC = "containerDisplayTitle_ASC",
  containerDisplayTitle_DESC = "containerDisplayTitle_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  deployedMiners_ASC = "deployedMiners_ASC",
  deployedMiners_DESC = "deployedMiners_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  miningPoolUsername_ASC = "miningPoolUsername_ASC",
  miningPoolUsername_DESC = "miningPoolUsername_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningContainerUpdateInput = {
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningContainerUpdateManyInlineInput = {
  /** Connect multiple existing MiningContainer documents */
  connect?: InputMaybe<Array<MiningContainerConnectInput>>;
  /** Create and connect multiple MiningContainer documents */
  create?: InputMaybe<Array<MiningContainerCreateInput>>;
  /** Delete multiple MiningContainer documents */
  delete?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Disconnect multiple MiningContainer documents */
  disconnect?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningContainer documents */
  set?: InputMaybe<Array<MiningContainerWhereUniqueInput>>;
  /** Update multiple MiningContainer documents */
  update?: InputMaybe<Array<MiningContainerUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MiningContainer documents */
  upsert?: InputMaybe<Array<MiningContainerUpsertWithNestedWhereUniqueInput>>;
};

export type MiningContainerUpdateManyInput = {
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningContainerUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningContainerUpdateManyInput;
  /** Document search */
  where: MiningContainerWhereInput;
};

export type MiningContainerUpdateOneInlineInput = {
  /** Connect existing MiningContainer document */
  connect?: InputMaybe<MiningContainerWhereUniqueInput>;
  /** Create and connect one MiningContainer document */
  create?: InputMaybe<MiningContainerCreateInput>;
  /** Delete currently connected MiningContainer document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningContainer document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningContainer document */
  update?: InputMaybe<MiningContainerUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningContainer document */
  upsert?: InputMaybe<MiningContainerUpsertWithNestedWhereUniqueInput>;
};

export type MiningContainerUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningContainerUpdateInput;
  /** Unique document search */
  where: MiningContainerWhereUniqueInput;
};

export type MiningContainerUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningContainerCreateInput;
  /** Update document if it exists */
  update: MiningContainerUpdateInput;
};

export type MiningContainerUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningContainerUpsertInput;
  /** Unique document search */
  where: MiningContainerWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningContainerWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningContainerWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningContainerWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  containerDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  containerDisplayTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  containerDisplayTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  containerDisplayTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  containerDisplayTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  containerDisplayTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  containerDisplayTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  containerDisplayTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  containerDisplayTitle_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  containerDisplayTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  deployedMiners?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  deployedMiners_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  deployedMiners_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  deployedMiners_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  deployedMiners_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  deployedMiners_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  deployedMiners_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  deployedMiners_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningContainerWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningContainerWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  miningPoolUsername?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  miningPoolUsername_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  miningPoolUsername_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  miningPoolUsername_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  miningPoolUsername_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  miningPoolUsername_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  miningPoolUsername_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  miningPoolUsername_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  miningPoolUsername_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  miningPoolUsername_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningContainerWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningContainerWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningContainerWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningContainerWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningContainerWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningContainer record uniquely */
export type MiningContainerWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningHeaderNotice = Entity &
  Node & {
    __typename?: "MiningHeaderNotice";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningHeaderNotice>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of MiningHeaderNotice versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    notice?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningHeaderNoticeCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningHeaderNoticeDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningHeaderNoticeHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningHeaderNoticePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningHeaderNoticeScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningHeaderNoticeUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningHeaderNoticeConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningHeaderNoticeWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningHeaderNoticeConnection = {
  __typename?: "MiningHeaderNoticeConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningHeaderNoticeEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningHeaderNoticeCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningHeaderNoticeCreateManyInlineInput = {
  /** Connect multiple existing MiningHeaderNotice documents */
  connect?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Create and connect multiple existing MiningHeaderNotice documents */
  create?: InputMaybe<Array<MiningHeaderNoticeCreateInput>>;
};

export type MiningHeaderNoticeCreateOneInlineInput = {
  /** Connect one existing MiningHeaderNotice document */
  connect?: InputMaybe<MiningHeaderNoticeWhereUniqueInput>;
  /** Create and connect one MiningHeaderNotice document */
  create?: InputMaybe<MiningHeaderNoticeCreateInput>;
};

/** An edge in a connection. */
export type MiningHeaderNoticeEdge = {
  __typename?: "MiningHeaderNoticeEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningHeaderNotice;
};

/** Identifies documents */
export type MiningHeaderNoticeManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  notice_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  notice_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  notice_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  notice_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  notice_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  notice_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  notice_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  notice_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  notice_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningHeaderNoticeOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  notice_ASC = "notice_ASC",
  notice_DESC = "notice_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningHeaderNoticeUpdateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningHeaderNoticeUpdateManyInlineInput = {
  /** Connect multiple existing MiningHeaderNotice documents */
  connect?: InputMaybe<Array<MiningHeaderNoticeConnectInput>>;
  /** Create and connect multiple MiningHeaderNotice documents */
  create?: InputMaybe<Array<MiningHeaderNoticeCreateInput>>;
  /** Delete multiple MiningHeaderNotice documents */
  delete?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Disconnect multiple MiningHeaderNotice documents */
  disconnect?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningHeaderNotice documents */
  set?: InputMaybe<Array<MiningHeaderNoticeWhereUniqueInput>>;
  /** Update multiple MiningHeaderNotice documents */
  update?: InputMaybe<
    Array<MiningHeaderNoticeUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningHeaderNotice documents */
  upsert?: InputMaybe<
    Array<MiningHeaderNoticeUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningHeaderNoticeUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningHeaderNoticeUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningHeaderNoticeUpdateManyInput;
  /** Document search */
  where: MiningHeaderNoticeWhereInput;
};

export type MiningHeaderNoticeUpdateOneInlineInput = {
  /** Connect existing MiningHeaderNotice document */
  connect?: InputMaybe<MiningHeaderNoticeWhereUniqueInput>;
  /** Create and connect one MiningHeaderNotice document */
  create?: InputMaybe<MiningHeaderNoticeCreateInput>;
  /** Delete currently connected MiningHeaderNotice document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningHeaderNotice document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningHeaderNotice document */
  update?: InputMaybe<MiningHeaderNoticeUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningHeaderNotice document */
  upsert?: InputMaybe<MiningHeaderNoticeUpsertWithNestedWhereUniqueInput>;
};

export type MiningHeaderNoticeUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningHeaderNoticeUpdateInput;
  /** Unique document search */
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MiningHeaderNoticeUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningHeaderNoticeCreateInput;
  /** Update document if it exists */
  update: MiningHeaderNoticeUpdateInput;
};

export type MiningHeaderNoticeUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningHeaderNoticeUpsertInput;
  /** Unique document search */
  where: MiningHeaderNoticeWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningHeaderNoticeWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningHeaderNoticeWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningHeaderNoticeWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningHeaderNoticeWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  notice?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  notice_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  notice_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  notice_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  notice_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  notice_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  notice_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  notice_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  notice_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  notice_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningHeaderNoticeWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningHeaderNoticeWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningHeaderNoticeWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningHeaderNoticeWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningHeaderNoticeWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningHeaderNotice record uniquely */
export type MiningHeaderNoticeWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftlpPageHeroSection = Entity &
  Node & {
    __typename?: "MiningNFTLPPageHeroSection";
    backgroundImage?: Maybe<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    cta?: Maybe<Button>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftlpPageHeroSection>;
    heroImage?: Maybe<Asset>;
    heroTextSection?: Maybe<RichText>;
    /** List of MiningNFTLPPageHeroSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    logo?: Maybe<Asset>;
    mintingDate?: Maybe<Scalars["DateTime"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    whitepaperDownloadLink?: Maybe<Scalars["String"]["output"]>;
  };

export type MiningNftlpPageHeroSectionBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftlpPageHeroSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftlpPageHeroSectionCtaArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftlpPageHeroSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftlpPageHeroSectionHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftlpPageHeroSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftlpPageHeroSectionLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftlpPageHeroSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftlpPageHeroSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftlpPageHeroSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftlpPageHeroSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftlpPageHeroSectionConnection = {
  __typename?: "MiningNFTLPPageHeroSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftlpPageHeroSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftlpPageHeroSectionCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  cta?: InputMaybe<ButtonCreateOneInlineInput>;
  heroImage?: InputMaybe<AssetCreateOneInlineInput>;
  heroTextSection?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  mintingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  whitepaperDownloadLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningNftlpPageHeroSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNFTLPPageHeroSection documents */
  connect?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNFTLPPageHeroSection documents */
  create?: InputMaybe<Array<MiningNftlpPageHeroSectionCreateInput>>;
};

export type MiningNftlpPageHeroSectionCreateOneInlineInput = {
  /** Connect one existing MiningNFTLPPageHeroSection document */
  connect?: InputMaybe<MiningNftlpPageHeroSectionWhereUniqueInput>;
  /** Create and connect one MiningNFTLPPageHeroSection document */
  create?: InputMaybe<MiningNftlpPageHeroSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftlpPageHeroSectionEdge = {
  __typename?: "MiningNFTLPPageHeroSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftlpPageHeroSection;
};

/** Identifies documents */
export type MiningNftlpPageHeroSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  cta?: InputMaybe<ButtonWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftlpPageHeroSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftlpPageHeroSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftlpPageHeroSectionWhereStageInput>;
  heroImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  logo?: InputMaybe<AssetWhereInput>;
  mintingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintingDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintingDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintingDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintingDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintingDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintingDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintingDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitepaperDownloadLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  whitepaperDownloadLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  whitepaperDownloadLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  whitepaperDownloadLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  whitepaperDownloadLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  whitepaperDownloadLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  whitepaperDownloadLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  whitepaperDownloadLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  whitepaperDownloadLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  whitepaperDownloadLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MiningNftlpPageHeroSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  mintingDate_ASC = "mintingDate_ASC",
  mintingDate_DESC = "mintingDate_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  whitepaperDownloadLink_ASC = "whitepaperDownloadLink_ASC",
  whitepaperDownloadLink_DESC = "whitepaperDownloadLink_DESC",
}

export type MiningNftlpPageHeroSectionUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  cta?: InputMaybe<ButtonUpdateOneInlineInput>;
  heroImage?: InputMaybe<AssetUpdateOneInlineInput>;
  heroTextSection?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
  mintingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  whitepaperDownloadLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningNftlpPageHeroSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNFTLPPageHeroSection documents */
  connect?: InputMaybe<Array<MiningNftlpPageHeroSectionConnectInput>>;
  /** Create and connect multiple MiningNFTLPPageHeroSection documents */
  create?: InputMaybe<Array<MiningNftlpPageHeroSectionCreateInput>>;
  /** Delete multiple MiningNFTLPPageHeroSection documents */
  delete?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNFTLPPageHeroSection documents */
  disconnect?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNFTLPPageHeroSection documents */
  set?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereUniqueInput>>;
  /** Update multiple MiningNFTLPPageHeroSection documents */
  update?: InputMaybe<
    Array<MiningNftlpPageHeroSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNFTLPPageHeroSection documents */
  upsert?: InputMaybe<
    Array<MiningNftlpPageHeroSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftlpPageHeroSectionUpdateManyInput = {
  heroTextSection?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  mintingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftlpPageHeroSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftlpPageHeroSectionUpdateManyInput;
  /** Document search */
  where: MiningNftlpPageHeroSectionWhereInput;
};

export type MiningNftlpPageHeroSectionUpdateOneInlineInput = {
  /** Connect existing MiningNFTLPPageHeroSection document */
  connect?: InputMaybe<MiningNftlpPageHeroSectionWhereUniqueInput>;
  /** Create and connect one MiningNFTLPPageHeroSection document */
  create?: InputMaybe<MiningNftlpPageHeroSectionCreateInput>;
  /** Delete currently connected MiningNFTLPPageHeroSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNFTLPPageHeroSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNFTLPPageHeroSection document */
  update?: InputMaybe<MiningNftlpPageHeroSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNFTLPPageHeroSection document */
  upsert?: InputMaybe<MiningNftlpPageHeroSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftlpPageHeroSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftlpPageHeroSectionUpdateInput;
  /** Unique document search */
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MiningNftlpPageHeroSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftlpPageHeroSectionCreateInput;
  /** Update document if it exists */
  update: MiningNftlpPageHeroSectionUpdateInput;
};

export type MiningNftlpPageHeroSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftlpPageHeroSectionUpsertInput;
  /** Unique document search */
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftlpPageHeroSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftlpPageHeroSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  cta?: InputMaybe<ButtonWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftlpPageHeroSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftlpPageHeroSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftlpPageHeroSectionWhereStageInput>;
  heroImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  logo?: InputMaybe<AssetWhereInput>;
  mintingDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintingDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintingDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintingDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintingDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintingDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintingDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintingDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitepaperDownloadLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  whitepaperDownloadLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  whitepaperDownloadLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  whitepaperDownloadLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  whitepaperDownloadLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  whitepaperDownloadLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  whitepaperDownloadLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  whitepaperDownloadLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  whitepaperDownloadLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  whitepaperDownloadLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftlpPageHeroSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftlpPageHeroSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftlpPageHeroSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNFTLPPageHeroSection record uniquely */
export type MiningNftlpPageHeroSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  whitepaperDownloadLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningNftAccessSection = Entity &
  Node & {
    __typename?: "MiningNftAccessSection";
    accessOptions: Array<SmallIconHeadingText>;
    backgroundImage?: Maybe<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftAccessSection>;
    /** List of MiningNftAccessSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftAccessSectionAccessOptionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SmallIconHeadingTextOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SmallIconHeadingTextWhereInput>;
};

export type MiningNftAccessSectionBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAccessSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAccessSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftAccessSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftAccessSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAccessSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftAccessSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAccessSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftAccessSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftAccessSectionConnection = {
  __typename?: "MiningNftAccessSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftAccessSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftAccessSectionCreateInput = {
  accessOptions?: InputMaybe<SmallIconHeadingTextCreateManyInlineInput>;
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftAccessSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNftAccessSection documents */
  connect?: InputMaybe<Array<MiningNftAccessSectionWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftAccessSection documents */
  create?: InputMaybe<Array<MiningNftAccessSectionCreateInput>>;
};

export type MiningNftAccessSectionCreateOneInlineInput = {
  /** Connect one existing MiningNftAccessSection document */
  connect?: InputMaybe<MiningNftAccessSectionWhereUniqueInput>;
  /** Create and connect one MiningNftAccessSection document */
  create?: InputMaybe<MiningNftAccessSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftAccessSectionEdge = {
  __typename?: "MiningNftAccessSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftAccessSection;
};

/** Identifies documents */
export type MiningNftAccessSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAccessSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAccessSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAccessSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  accessOptions_every?: InputMaybe<SmallIconHeadingTextWhereInput>;
  accessOptions_none?: InputMaybe<SmallIconHeadingTextWhereInput>;
  accessOptions_some?: InputMaybe<SmallIconHeadingTextWhereInput>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftAccessSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftAccessSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftAccessSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftAccessSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftAccessSectionUpdateInput = {
  accessOptions?: InputMaybe<SmallIconHeadingTextUpdateManyInlineInput>;
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftAccessSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftAccessSection documents */
  connect?: InputMaybe<Array<MiningNftAccessSectionConnectInput>>;
  /** Create and connect multiple MiningNftAccessSection documents */
  create?: InputMaybe<Array<MiningNftAccessSectionCreateInput>>;
  /** Delete multiple MiningNftAccessSection documents */
  delete?: InputMaybe<Array<MiningNftAccessSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNftAccessSection documents */
  disconnect?: InputMaybe<Array<MiningNftAccessSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftAccessSection documents */
  set?: InputMaybe<Array<MiningNftAccessSectionWhereUniqueInput>>;
  /** Update multiple MiningNftAccessSection documents */
  update?: InputMaybe<
    Array<MiningNftAccessSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftAccessSection documents */
  upsert?: InputMaybe<
    Array<MiningNftAccessSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftAccessSectionUpdateManyInput = {
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftAccessSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftAccessSectionUpdateManyInput;
  /** Document search */
  where: MiningNftAccessSectionWhereInput;
};

export type MiningNftAccessSectionUpdateOneInlineInput = {
  /** Connect existing MiningNftAccessSection document */
  connect?: InputMaybe<MiningNftAccessSectionWhereUniqueInput>;
  /** Create and connect one MiningNftAccessSection document */
  create?: InputMaybe<MiningNftAccessSectionCreateInput>;
  /** Delete currently connected MiningNftAccessSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftAccessSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftAccessSection document */
  update?: InputMaybe<MiningNftAccessSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftAccessSection document */
  upsert?: InputMaybe<MiningNftAccessSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftAccessSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftAccessSectionUpdateInput;
  /** Unique document search */
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MiningNftAccessSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftAccessSectionCreateInput;
  /** Update document if it exists */
  update: MiningNftAccessSectionUpdateInput;
};

export type MiningNftAccessSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftAccessSectionUpsertInput;
  /** Unique document search */
  where: MiningNftAccessSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftAccessSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftAccessSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAccessSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAccessSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAccessSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  accessOptions_every?: InputMaybe<SmallIconHeadingTextWhereInput>;
  accessOptions_none?: InputMaybe<SmallIconHeadingTextWhereInput>;
  accessOptions_some?: InputMaybe<SmallIconHeadingTextWhereInput>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftAccessSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftAccessSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftAccessSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftAccessSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAccessSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAccessSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAccessSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftAccessSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftAccessSection record uniquely */
export type MiningNftAccessSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftAdvantageSection = Entity &
  Node & {
    __typename?: "MiningNftAdvantageSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftAdvantageSection>;
    /** List of MiningNftAdvantageSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    lineItems: Array<SmallIconHeadingText>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftAdvantageSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAdvantageSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftAdvantageSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftAdvantageSectionLineItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SmallIconHeadingTextOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SmallIconHeadingTextWhereInput>;
};

export type MiningNftAdvantageSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAdvantageSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftAdvantageSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAdvantageSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftAdvantageSectionConnection = {
  __typename?: "MiningNftAdvantageSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftAdvantageSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftAdvantageSectionCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  lineItems?: InputMaybe<SmallIconHeadingTextCreateManyInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftAdvantageSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNftAdvantageSection documents */
  connect?: InputMaybe<Array<MiningNftAdvantageSectionWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftAdvantageSection documents */
  create?: InputMaybe<Array<MiningNftAdvantageSectionCreateInput>>;
};

export type MiningNftAdvantageSectionCreateOneInlineInput = {
  /** Connect one existing MiningNftAdvantageSection document */
  connect?: InputMaybe<MiningNftAdvantageSectionWhereUniqueInput>;
  /** Create and connect one MiningNftAdvantageSection document */
  create?: InputMaybe<MiningNftAdvantageSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftAdvantageSectionEdge = {
  __typename?: "MiningNftAdvantageSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftAdvantageSection;
};

/** Identifies documents */
export type MiningNftAdvantageSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAdvantageSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAdvantageSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAdvantageSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftAdvantageSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftAdvantageSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftAdvantageSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  lineItems_every?: InputMaybe<SmallIconHeadingTextWhereInput>;
  lineItems_none?: InputMaybe<SmallIconHeadingTextWhereInput>;
  lineItems_some?: InputMaybe<SmallIconHeadingTextWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftAdvantageSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftAdvantageSectionUpdateInput = {
  lineItems?: InputMaybe<SmallIconHeadingTextUpdateManyInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftAdvantageSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftAdvantageSection documents */
  connect?: InputMaybe<Array<MiningNftAdvantageSectionConnectInput>>;
  /** Create and connect multiple MiningNftAdvantageSection documents */
  create?: InputMaybe<Array<MiningNftAdvantageSectionCreateInput>>;
  /** Delete multiple MiningNftAdvantageSection documents */
  delete?: InputMaybe<Array<MiningNftAdvantageSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNftAdvantageSection documents */
  disconnect?: InputMaybe<Array<MiningNftAdvantageSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftAdvantageSection documents */
  set?: InputMaybe<Array<MiningNftAdvantageSectionWhereUniqueInput>>;
  /** Update multiple MiningNftAdvantageSection documents */
  update?: InputMaybe<
    Array<MiningNftAdvantageSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftAdvantageSection documents */
  upsert?: InputMaybe<
    Array<MiningNftAdvantageSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftAdvantageSectionUpdateManyInput = {
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftAdvantageSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftAdvantageSectionUpdateManyInput;
  /** Document search */
  where: MiningNftAdvantageSectionWhereInput;
};

export type MiningNftAdvantageSectionUpdateOneInlineInput = {
  /** Connect existing MiningNftAdvantageSection document */
  connect?: InputMaybe<MiningNftAdvantageSectionWhereUniqueInput>;
  /** Create and connect one MiningNftAdvantageSection document */
  create?: InputMaybe<MiningNftAdvantageSectionCreateInput>;
  /** Delete currently connected MiningNftAdvantageSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftAdvantageSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftAdvantageSection document */
  update?: InputMaybe<MiningNftAdvantageSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftAdvantageSection document */
  upsert?: InputMaybe<MiningNftAdvantageSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftAdvantageSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftAdvantageSectionUpdateInput;
  /** Unique document search */
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MiningNftAdvantageSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftAdvantageSectionCreateInput;
  /** Update document if it exists */
  update: MiningNftAdvantageSectionUpdateInput;
};

export type MiningNftAdvantageSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftAdvantageSectionUpsertInput;
  /** Unique document search */
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftAdvantageSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftAdvantageSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAdvantageSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAdvantageSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAdvantageSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftAdvantageSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftAdvantageSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftAdvantageSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  lineItems_every?: InputMaybe<SmallIconHeadingTextWhereInput>;
  lineItems_none?: InputMaybe<SmallIconHeadingTextWhereInput>;
  lineItems_some?: InputMaybe<SmallIconHeadingTextWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftAdvantageSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAdvantageSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAdvantageSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAdvantageSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftAdvantageSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftAdvantageSection record uniquely */
export type MiningNftAdvantageSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftAntimonyAdvantageSection = Entity &
  Node & {
    __typename?: "MiningNftAntimonyAdvantageSection";
    backgroundImage?: Maybe<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftAntimonyAdvantageSection>;
    externalLink?: Maybe<Scalars["String"]["output"]>;
    /** List of MiningNftAntimonyAdvantageSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    linkSubText?: Maybe<Scalars["String"]["output"]>;
    linkText?: Maybe<Scalars["String"]["output"]>;
    mainText?: Maybe<RichText>;
    oreImage?: Maybe<Asset>;
    periodicTableElementImage?: Maybe<Asset>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftAntimonyAdvantageSectionBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAntimonyAdvantageSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAntimonyAdvantageSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftAntimonyAdvantageSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftAntimonyAdvantageSectionOreImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAntimonyAdvantageSectionPeriodicTableElementImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAntimonyAdvantageSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAntimonyAdvantageSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftAntimonyAdvantageSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftAntimonyAdvantageSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftAntimonyAdvantageSectionConnection = {
  __typename?: "MiningNftAntimonyAdvantageSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftAntimonyAdvantageSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftAntimonyAdvantageSectionCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  linkSubText?: InputMaybe<Scalars["String"]["input"]>;
  linkText?: InputMaybe<Scalars["String"]["input"]>;
  mainText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  oreImage?: InputMaybe<AssetCreateOneInlineInput>;
  periodicTableElementImage?: InputMaybe<AssetCreateOneInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftAntimonyAdvantageSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNftAntimonyAdvantageSection documents */
  connect?: InputMaybe<
    Array<MiningNftAntimonyAdvantageSectionWhereUniqueInput>
  >;
  /** Create and connect multiple existing MiningNftAntimonyAdvantageSection documents */
  create?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionCreateInput>>;
};

export type MiningNftAntimonyAdvantageSectionCreateOneInlineInput = {
  /** Connect one existing MiningNftAntimonyAdvantageSection document */
  connect?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereUniqueInput>;
  /** Create and connect one MiningNftAntimonyAdvantageSection document */
  create?: InputMaybe<MiningNftAntimonyAdvantageSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftAntimonyAdvantageSectionEdge = {
  __typename?: "MiningNftAntimonyAdvantageSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftAntimonyAdvantageSection;
};

/** Identifies documents */
export type MiningNftAntimonyAdvantageSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereStageInput>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  externalLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  externalLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  externalLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  externalLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  externalLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  externalLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  externalLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  linkSubText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  linkSubText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  linkSubText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  linkSubText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  linkSubText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  linkSubText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  linkSubText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  linkSubText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  linkSubText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  linkSubText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  linkText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  linkText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  linkText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  linkText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  linkText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  linkText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  linkText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  linkText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  linkText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  oreImage?: InputMaybe<AssetWhereInput>;
  periodicTableElementImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftAntimonyAdvantageSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  externalLink_ASC = "externalLink_ASC",
  externalLink_DESC = "externalLink_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  linkSubText_ASC = "linkSubText_ASC",
  linkSubText_DESC = "linkSubText_DESC",
  linkText_ASC = "linkText_ASC",
  linkText_DESC = "linkText_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftAntimonyAdvantageSectionUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  linkSubText?: InputMaybe<Scalars["String"]["input"]>;
  linkText?: InputMaybe<Scalars["String"]["input"]>;
  mainText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  oreImage?: InputMaybe<AssetUpdateOneInlineInput>;
  periodicTableElementImage?: InputMaybe<AssetUpdateOneInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftAntimonyAdvantageSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftAntimonyAdvantageSection documents */
  connect?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionConnectInput>>;
  /** Create and connect multiple MiningNftAntimonyAdvantageSection documents */
  create?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionCreateInput>>;
  /** Delete multiple MiningNftAntimonyAdvantageSection documents */
  delete?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNftAntimonyAdvantageSection documents */
  disconnect?: InputMaybe<
    Array<MiningNftAntimonyAdvantageSectionWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing MiningNftAntimonyAdvantageSection documents */
  set?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereUniqueInput>>;
  /** Update multiple MiningNftAntimonyAdvantageSection documents */
  update?: InputMaybe<
    Array<MiningNftAntimonyAdvantageSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftAntimonyAdvantageSection documents */
  upsert?: InputMaybe<
    Array<MiningNftAntimonyAdvantageSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftAntimonyAdvantageSectionUpdateManyInput = {
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  linkSubText?: InputMaybe<Scalars["String"]["input"]>;
  linkText?: InputMaybe<Scalars["String"]["input"]>;
  mainText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftAntimonyAdvantageSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftAntimonyAdvantageSectionUpdateManyInput;
  /** Document search */
  where: MiningNftAntimonyAdvantageSectionWhereInput;
};

export type MiningNftAntimonyAdvantageSectionUpdateOneInlineInput = {
  /** Connect existing MiningNftAntimonyAdvantageSection document */
  connect?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereUniqueInput>;
  /** Create and connect one MiningNftAntimonyAdvantageSection document */
  create?: InputMaybe<MiningNftAntimonyAdvantageSectionCreateInput>;
  /** Delete currently connected MiningNftAntimonyAdvantageSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftAntimonyAdvantageSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftAntimonyAdvantageSection document */
  update?: InputMaybe<MiningNftAntimonyAdvantageSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftAntimonyAdvantageSection document */
  upsert?: InputMaybe<MiningNftAntimonyAdvantageSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftAntimonyAdvantageSectionUpdateWithNestedWhereUniqueInput =
  {
    /** Document to update */
    data: MiningNftAntimonyAdvantageSectionUpdateInput;
    /** Unique document search */
    where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
  };

export type MiningNftAntimonyAdvantageSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftAntimonyAdvantageSectionCreateInput;
  /** Update document if it exists */
  update: MiningNftAntimonyAdvantageSectionUpdateInput;
};

export type MiningNftAntimonyAdvantageSectionUpsertWithNestedWhereUniqueInput =
  {
    /** Upsert data */
    data: MiningNftAntimonyAdvantageSectionUpsertInput;
    /** Unique document search */
    where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
  };

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftAntimonyAdvantageSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftAntimonyAdvantageSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereStageInput>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  externalLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  externalLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  externalLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  externalLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  externalLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  externalLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  externalLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  linkSubText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  linkSubText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  linkSubText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  linkSubText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  linkSubText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  linkSubText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  linkSubText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  linkSubText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  linkSubText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  linkSubText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  linkText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  linkText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  linkText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  linkText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  linkText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  linkText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  linkText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  linkText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  linkText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  linkText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  oreImage?: InputMaybe<AssetWhereInput>;
  periodicTableElementImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftAntimonyAdvantageSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftAntimonyAdvantageSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftAntimonyAdvantageSection record uniquely */
export type MiningNftAntimonyAdvantageSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftComparison = Entity & {
  __typename?: "MiningNftComparison";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  publicBoolean?: Maybe<Scalars["Boolean"]["output"]>;
  publicNftValue?: Maybe<Scalars["String"]["output"]>;
  reservedBoolean?: Maybe<Scalars["Boolean"]["output"]>;
  reservedValue?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
  statName?: Maybe<Scalars["String"]["output"]>;
};

export type MiningNftComparisonConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftComparisonWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftComparisonConnection = {
  __typename?: "MiningNftComparisonConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftComparisonEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftComparisonCreateInput = {
  publicBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicNftValue?: InputMaybe<Scalars["String"]["input"]>;
  reservedBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedValue?: InputMaybe<Scalars["String"]["input"]>;
  statName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningNftComparisonCreateManyInlineInput = {
  /** Create and connect multiple existing MiningNftComparison documents */
  create?: InputMaybe<Array<MiningNftComparisonCreateInput>>;
};

export type MiningNftComparisonCreateOneInlineInput = {
  /** Create and connect one MiningNftComparison document */
  create?: InputMaybe<MiningNftComparisonCreateInput>;
};

export type MiningNftComparisonCreateWithPositionInput = {
  /** Document to create */
  data: MiningNftComparisonCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MiningNftComparisonEdge = {
  __typename?: "MiningNftComparisonEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftComparison;
};

/** Identifies documents */
export type MiningNftComparisonManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftComparisonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftComparisonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftComparisonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publicBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publicBoolean_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicNftValue?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  publicNftValue_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  publicNftValue_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  publicNftValue_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  publicNftValue_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  publicNftValue_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  publicNftValue_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  publicNftValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  publicNftValue_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  publicNftValue_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  reservedBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  reservedBoolean_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedValue?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  reservedValue_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  reservedValue_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  reservedValue_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  reservedValue_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  reservedValue_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  reservedValue_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  reservedValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  reservedValue_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  reservedValue_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  statName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  statName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  statName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  statName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  statName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  statName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  statName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  statName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  statName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  statName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MiningNftComparisonOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publicBoolean_ASC = "publicBoolean_ASC",
  publicBoolean_DESC = "publicBoolean_DESC",
  publicNftValue_ASC = "publicNftValue_ASC",
  publicNftValue_DESC = "publicNftValue_DESC",
  reservedBoolean_ASC = "reservedBoolean_ASC",
  reservedBoolean_DESC = "reservedBoolean_DESC",
  reservedValue_ASC = "reservedValue_ASC",
  reservedValue_DESC = "reservedValue_DESC",
  statName_ASC = "statName_ASC",
  statName_DESC = "statName_DESC",
}

export type MiningNftComparisonParent = MiningNftComparisonTable;

export type MiningNftComparisonParentConnectInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableConnectInput>;
};

export type MiningNftComparisonParentCreateInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableCreateInput>;
};

export type MiningNftComparisonParentCreateManyInlineInput = {
  /** Connect multiple existing MiningNftComparisonParent documents */
  connect?: InputMaybe<Array<MiningNftComparisonParentWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftComparisonParent documents */
  create?: InputMaybe<Array<MiningNftComparisonParentCreateInput>>;
};

export type MiningNftComparisonParentCreateOneInlineInput = {
  /** Connect one existing MiningNftComparisonParent document */
  connect?: InputMaybe<MiningNftComparisonParentWhereUniqueInput>;
  /** Create and connect one MiningNftComparisonParent document */
  create?: InputMaybe<MiningNftComparisonParentCreateInput>;
};

export type MiningNftComparisonParentUpdateInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableUpdateInput>;
};

export type MiningNftComparisonParentUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftComparisonParent documents */
  connect?: InputMaybe<Array<MiningNftComparisonParentConnectInput>>;
  /** Create and connect multiple MiningNftComparisonParent documents */
  create?: InputMaybe<Array<MiningNftComparisonParentCreateInput>>;
  /** Delete multiple MiningNftComparisonParent documents */
  delete?: InputMaybe<Array<MiningNftComparisonParentWhereUniqueInput>>;
  /** Disconnect multiple MiningNftComparisonParent documents */
  disconnect?: InputMaybe<Array<MiningNftComparisonParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftComparisonParent documents */
  set?: InputMaybe<Array<MiningNftComparisonParentWhereUniqueInput>>;
  /** Update multiple MiningNftComparisonParent documents */
  update?: InputMaybe<
    Array<MiningNftComparisonParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftComparisonParent documents */
  upsert?: InputMaybe<
    Array<MiningNftComparisonParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftComparisonParentUpdateManyWithNestedWhereInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableUpdateManyWithNestedWhereInput>;
};

export type MiningNftComparisonParentUpdateOneInlineInput = {
  /** Connect existing MiningNftComparisonParent document */
  connect?: InputMaybe<MiningNftComparisonParentWhereUniqueInput>;
  /** Create and connect one MiningNftComparisonParent document */
  create?: InputMaybe<MiningNftComparisonParentCreateInput>;
  /** Delete currently connected MiningNftComparisonParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftComparisonParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftComparisonParent document */
  update?: InputMaybe<MiningNftComparisonParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftComparisonParent document */
  upsert?: InputMaybe<MiningNftComparisonParentUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftComparisonParentUpdateWithNestedWhereUniqueInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableUpdateWithNestedWhereUniqueInput>;
};

export type MiningNftComparisonParentUpsertWithNestedWhereUniqueInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftComparisonParentWhereInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableWhereInput>;
};

export type MiningNftComparisonParentWhereUniqueInput = {
  MiningNftComparisonTable?: InputMaybe<MiningNftComparisonTableWhereUniqueInput>;
};

export type MiningNftComparisonTable = Entity &
  Node & {
    __typename?: "MiningNftComparisonTable";
    comparisonTableItems: Array<MiningNftComparison>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftComparisonTable>;
    /** List of MiningNftComparisonTable versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftComparisonTableComparisonTableItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MiningNftComparisonOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftComparisonWhereInput>;
};

export type MiningNftComparisonTableCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftComparisonTableDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftComparisonTableHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftComparisonTablePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftComparisonTableScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftComparisonTableUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftComparisonTableConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftComparisonTableWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftComparisonTableConnection = {
  __typename?: "MiningNftComparisonTableConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftComparisonTableEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftComparisonTableCreateInput = {
  comparisonTableItems?: InputMaybe<MiningNftComparisonCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftComparisonTableCreateManyInlineInput = {
  /** Connect multiple existing MiningNftComparisonTable documents */
  connect?: InputMaybe<Array<MiningNftComparisonTableWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftComparisonTable documents */
  create?: InputMaybe<Array<MiningNftComparisonTableCreateInput>>;
};

export type MiningNftComparisonTableCreateOneInlineInput = {
  /** Connect one existing MiningNftComparisonTable document */
  connect?: InputMaybe<MiningNftComparisonTableWhereUniqueInput>;
  /** Create and connect one MiningNftComparisonTable document */
  create?: InputMaybe<MiningNftComparisonTableCreateInput>;
};

/** An edge in a connection. */
export type MiningNftComparisonTableEdge = {
  __typename?: "MiningNftComparisonTableEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftComparisonTable;
};

/** Identifies documents */
export type MiningNftComparisonTableManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftComparisonTableWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftComparisonTableWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftComparisonTableWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  comparisonTableItems_every?: InputMaybe<MiningNftComparisonWhereInput>;
  comparisonTableItems_none?: InputMaybe<MiningNftComparisonWhereInput>;
  comparisonTableItems_some?: InputMaybe<MiningNftComparisonWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftComparisonTableWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftComparisonTableWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftComparisonTableWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftComparisonTableOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftComparisonTableUpdateInput = {
  comparisonTableItems?: InputMaybe<MiningNftComparisonUpdateManyInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftComparisonTableUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftComparisonTable documents */
  connect?: InputMaybe<Array<MiningNftComparisonTableConnectInput>>;
  /** Create and connect multiple MiningNftComparisonTable documents */
  create?: InputMaybe<Array<MiningNftComparisonTableCreateInput>>;
  /** Delete multiple MiningNftComparisonTable documents */
  delete?: InputMaybe<Array<MiningNftComparisonTableWhereUniqueInput>>;
  /** Disconnect multiple MiningNftComparisonTable documents */
  disconnect?: InputMaybe<Array<MiningNftComparisonTableWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftComparisonTable documents */
  set?: InputMaybe<Array<MiningNftComparisonTableWhereUniqueInput>>;
  /** Update multiple MiningNftComparisonTable documents */
  update?: InputMaybe<
    Array<MiningNftComparisonTableUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftComparisonTable documents */
  upsert?: InputMaybe<
    Array<MiningNftComparisonTableUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftComparisonTableUpdateManyInput = {
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftComparisonTableUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftComparisonTableUpdateManyInput;
  /** Document search */
  where: MiningNftComparisonTableWhereInput;
};

export type MiningNftComparisonTableUpdateOneInlineInput = {
  /** Connect existing MiningNftComparisonTable document */
  connect?: InputMaybe<MiningNftComparisonTableWhereUniqueInput>;
  /** Create and connect one MiningNftComparisonTable document */
  create?: InputMaybe<MiningNftComparisonTableCreateInput>;
  /** Delete currently connected MiningNftComparisonTable document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftComparisonTable document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftComparisonTable document */
  update?: InputMaybe<MiningNftComparisonTableUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftComparisonTable document */
  upsert?: InputMaybe<MiningNftComparisonTableUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftComparisonTableUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftComparisonTableUpdateInput;
  /** Unique document search */
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MiningNftComparisonTableUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftComparisonTableCreateInput;
  /** Update document if it exists */
  update: MiningNftComparisonTableUpdateInput;
};

export type MiningNftComparisonTableUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftComparisonTableUpsertInput;
  /** Unique document search */
  where: MiningNftComparisonTableWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftComparisonTableWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftComparisonTableWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftComparisonTableWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftComparisonTableWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftComparisonTableWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  comparisonTableItems_every?: InputMaybe<MiningNftComparisonWhereInput>;
  comparisonTableItems_none?: InputMaybe<MiningNftComparisonWhereInput>;
  comparisonTableItems_some?: InputMaybe<MiningNftComparisonWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftComparisonTableWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftComparisonTableWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftComparisonTableWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftComparisonTableWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftComparisonTableWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftComparisonTableWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftComparisonTableWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftComparisonTableWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftComparisonTable record uniquely */
export type MiningNftComparisonTableWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftComparisonUpdateInput = {
  publicBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicNftValue?: InputMaybe<Scalars["String"]["input"]>;
  reservedBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedValue?: InputMaybe<Scalars["String"]["input"]>;
  statName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningNftComparisonUpdateManyInlineInput = {
  /** Create and connect multiple MiningNftComparison component instances */
  create?: InputMaybe<Array<MiningNftComparisonCreateWithPositionInput>>;
  /** Delete multiple MiningNftComparison documents */
  delete?: InputMaybe<Array<MiningNftComparisonWhereUniqueInput>>;
  /** Update multiple MiningNftComparison component instances */
  update?: InputMaybe<
    Array<MiningNftComparisonUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MiningNftComparison component instances */
  upsert?: InputMaybe<
    Array<MiningNftComparisonUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MiningNftComparisonUpdateManyInput = {
  publicBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicNftValue?: InputMaybe<Scalars["String"]["input"]>;
  reservedBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedValue?: InputMaybe<Scalars["String"]["input"]>;
  statName?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningNftComparisonUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftComparisonUpdateManyInput;
  /** Document search */
  where: MiningNftComparisonWhereInput;
};

export type MiningNftComparisonUpdateOneInlineInput = {
  /** Create and connect one MiningNftComparison document */
  create?: InputMaybe<MiningNftComparisonCreateInput>;
  /** Delete currently connected MiningNftComparison document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftComparison document */
  update?: InputMaybe<MiningNftComparisonUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftComparison document */
  upsert?: InputMaybe<MiningNftComparisonUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftComparisonUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<MiningNftComparisonUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MiningNftComparisonWhereUniqueInput;
};

export type MiningNftComparisonUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftComparisonUpdateInput;
  /** Unique document search */
  where: MiningNftComparisonWhereUniqueInput;
};

export type MiningNftComparisonUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftComparisonCreateInput;
  /** Update document if it exists */
  update: MiningNftComparisonUpdateInput;
};

export type MiningNftComparisonUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<MiningNftComparisonUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: MiningNftComparisonWhereUniqueInput;
};

export type MiningNftComparisonUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftComparisonUpsertInput;
  /** Unique document search */
  where: MiningNftComparisonWhereUniqueInput;
};

/** Identifies documents */
export type MiningNftComparisonWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftComparisonWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftComparisonWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftComparisonWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publicBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publicBoolean_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  publicNftValue?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  publicNftValue_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  publicNftValue_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  publicNftValue_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  publicNftValue_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  publicNftValue_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  publicNftValue_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  publicNftValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  publicNftValue_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  publicNftValue_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  reservedBoolean?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  reservedBoolean_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  reservedValue?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  reservedValue_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  reservedValue_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  reservedValue_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  reservedValue_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  reservedValue_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  reservedValue_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  reservedValue_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  reservedValue_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  reservedValue_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  statName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  statName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  statName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  statName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  statName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  statName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  statName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  statName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  statName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  statName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References MiningNftComparison record uniquely */
export type MiningNftComparisonWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftEligibilitySection = Entity &
  Node & {
    __typename?: "MiningNftEligibilitySection";
    backgroundImage?: Maybe<Asset>;
    buttonText?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftEligibilitySection>;
    /** List of MiningNftEligibilitySection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    requirementsSection?: Maybe<RichText>;
    scheduledIn: Array<ScheduledOperation>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftEligibilitySectionBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftEligibilitySectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftEligibilitySectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftEligibilitySectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftEligibilitySectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftEligibilitySectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftEligibilitySectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftEligibilitySectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftEligibilitySectionConnection = {
  __typename?: "MiningNftEligibilitySectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftEligibilitySectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftEligibilitySectionCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  requirementsSection?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftEligibilitySectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNftEligibilitySection documents */
  connect?: InputMaybe<Array<MiningNftEligibilitySectionWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftEligibilitySection documents */
  create?: InputMaybe<Array<MiningNftEligibilitySectionCreateInput>>;
};

export type MiningNftEligibilitySectionCreateOneInlineInput = {
  /** Connect one existing MiningNftEligibilitySection document */
  connect?: InputMaybe<MiningNftEligibilitySectionWhereUniqueInput>;
  /** Create and connect one MiningNftEligibilitySection document */
  create?: InputMaybe<MiningNftEligibilitySectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftEligibilitySectionEdge = {
  __typename?: "MiningNftEligibilitySectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftEligibilitySection;
};

/** Identifies documents */
export type MiningNftEligibilitySectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftEligibilitySectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftEligibilitySectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftEligibilitySectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftEligibilitySectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftEligibilitySectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftEligibilitySectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftEligibilitySectionOrderByInput {
  buttonText_ASC = "buttonText_ASC",
  buttonText_DESC = "buttonText_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftEligibilitySectionUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  requirementsSection?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftEligibilitySectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftEligibilitySection documents */
  connect?: InputMaybe<Array<MiningNftEligibilitySectionConnectInput>>;
  /** Create and connect multiple MiningNftEligibilitySection documents */
  create?: InputMaybe<Array<MiningNftEligibilitySectionCreateInput>>;
  /** Delete multiple MiningNftEligibilitySection documents */
  delete?: InputMaybe<Array<MiningNftEligibilitySectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNftEligibilitySection documents */
  disconnect?: InputMaybe<Array<MiningNftEligibilitySectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftEligibilitySection documents */
  set?: InputMaybe<Array<MiningNftEligibilitySectionWhereUniqueInput>>;
  /** Update multiple MiningNftEligibilitySection documents */
  update?: InputMaybe<
    Array<MiningNftEligibilitySectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftEligibilitySection documents */
  upsert?: InputMaybe<
    Array<MiningNftEligibilitySectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftEligibilitySectionUpdateManyInput = {
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  requirementsSection?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftEligibilitySectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftEligibilitySectionUpdateManyInput;
  /** Document search */
  where: MiningNftEligibilitySectionWhereInput;
};

export type MiningNftEligibilitySectionUpdateOneInlineInput = {
  /** Connect existing MiningNftEligibilitySection document */
  connect?: InputMaybe<MiningNftEligibilitySectionWhereUniqueInput>;
  /** Create and connect one MiningNftEligibilitySection document */
  create?: InputMaybe<MiningNftEligibilitySectionCreateInput>;
  /** Delete currently connected MiningNftEligibilitySection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftEligibilitySection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftEligibilitySection document */
  update?: InputMaybe<MiningNftEligibilitySectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftEligibilitySection document */
  upsert?: InputMaybe<MiningNftEligibilitySectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftEligibilitySectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftEligibilitySectionUpdateInput;
  /** Unique document search */
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MiningNftEligibilitySectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftEligibilitySectionCreateInput;
  /** Update document if it exists */
  update: MiningNftEligibilitySectionUpdateInput;
};

export type MiningNftEligibilitySectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftEligibilitySectionUpsertInput;
  /** Unique document search */
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftEligibilitySectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftEligibilitySectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftEligibilitySectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftEligibilitySectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftEligibilitySectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  buttonText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  buttonText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  buttonText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  buttonText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  buttonText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  buttonText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  buttonText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  buttonText_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  buttonText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  buttonText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftEligibilitySectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftEligibilitySectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftEligibilitySectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftEligibilitySectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftEligibilitySectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftEligibilitySectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftEligibilitySectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftEligibilitySectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftEligibilitySection record uniquely */
export type MiningNftEligibilitySectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftProfitRoiSection = Entity &
  Node & {
    __typename?: "MiningNftProfitRoiSection";
    backgroundImage?: Maybe<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftProfitRoiSection>;
    /** List of MiningNftProfitRoiSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    leftSideOverlayImage?: Maybe<Asset>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    roiImage?: Maybe<Asset>;
    scheduledIn: Array<ScheduledOperation>;
    sectionText?: Maybe<RichText>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftProfitRoiSectionBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftProfitRoiSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftProfitRoiSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftProfitRoiSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftProfitRoiSectionLeftSideOverlayImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftProfitRoiSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftProfitRoiSectionRoiImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftProfitRoiSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftProfitRoiSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftProfitRoiSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftProfitRoiSectionConnection = {
  __typename?: "MiningNftProfitRoiSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftProfitRoiSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftProfitRoiSectionCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  leftSideOverlayImage?: InputMaybe<AssetCreateOneInlineInput>;
  roiImage?: InputMaybe<AssetCreateOneInlineInput>;
  sectionText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftProfitRoiSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNftProfitRoiSection documents */
  connect?: InputMaybe<Array<MiningNftProfitRoiSectionWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftProfitRoiSection documents */
  create?: InputMaybe<Array<MiningNftProfitRoiSectionCreateInput>>;
};

export type MiningNftProfitRoiSectionCreateOneInlineInput = {
  /** Connect one existing MiningNftProfitRoiSection document */
  connect?: InputMaybe<MiningNftProfitRoiSectionWhereUniqueInput>;
  /** Create and connect one MiningNftProfitRoiSection document */
  create?: InputMaybe<MiningNftProfitRoiSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftProfitRoiSectionEdge = {
  __typename?: "MiningNftProfitRoiSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftProfitRoiSection;
};

/** Identifies documents */
export type MiningNftProfitRoiSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftProfitRoiSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftProfitRoiSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftProfitRoiSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftProfitRoiSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftProfitRoiSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftProfitRoiSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  leftSideOverlayImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  roiImage?: InputMaybe<AssetWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftProfitRoiSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftProfitRoiSectionUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  leftSideOverlayImage?: InputMaybe<AssetUpdateOneInlineInput>;
  roiImage?: InputMaybe<AssetUpdateOneInlineInput>;
  sectionText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftProfitRoiSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftProfitRoiSection documents */
  connect?: InputMaybe<Array<MiningNftProfitRoiSectionConnectInput>>;
  /** Create and connect multiple MiningNftProfitRoiSection documents */
  create?: InputMaybe<Array<MiningNftProfitRoiSectionCreateInput>>;
  /** Delete multiple MiningNftProfitRoiSection documents */
  delete?: InputMaybe<Array<MiningNftProfitRoiSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNftProfitRoiSection documents */
  disconnect?: InputMaybe<Array<MiningNftProfitRoiSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftProfitRoiSection documents */
  set?: InputMaybe<Array<MiningNftProfitRoiSectionWhereUniqueInput>>;
  /** Update multiple MiningNftProfitRoiSection documents */
  update?: InputMaybe<
    Array<MiningNftProfitRoiSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftProfitRoiSection documents */
  upsert?: InputMaybe<
    Array<MiningNftProfitRoiSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftProfitRoiSectionUpdateManyInput = {
  sectionText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftProfitRoiSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftProfitRoiSectionUpdateManyInput;
  /** Document search */
  where: MiningNftProfitRoiSectionWhereInput;
};

export type MiningNftProfitRoiSectionUpdateOneInlineInput = {
  /** Connect existing MiningNftProfitRoiSection document */
  connect?: InputMaybe<MiningNftProfitRoiSectionWhereUniqueInput>;
  /** Create and connect one MiningNftProfitRoiSection document */
  create?: InputMaybe<MiningNftProfitRoiSectionCreateInput>;
  /** Delete currently connected MiningNftProfitRoiSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftProfitRoiSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftProfitRoiSection document */
  update?: InputMaybe<MiningNftProfitRoiSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftProfitRoiSection document */
  upsert?: InputMaybe<MiningNftProfitRoiSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftProfitRoiSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftProfitRoiSectionUpdateInput;
  /** Unique document search */
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MiningNftProfitRoiSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftProfitRoiSectionCreateInput;
  /** Update document if it exists */
  update: MiningNftProfitRoiSectionUpdateInput;
};

export type MiningNftProfitRoiSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftProfitRoiSectionUpsertInput;
  /** Unique document search */
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftProfitRoiSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftProfitRoiSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftProfitRoiSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftProfitRoiSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftProfitRoiSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftProfitRoiSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftProfitRoiSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftProfitRoiSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  leftSideOverlayImage?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  roiImage?: InputMaybe<AssetWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftProfitRoiSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftProfitRoiSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftProfitRoiSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftProfitRoiSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftProfitRoiSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftProfitRoiSection record uniquely */
export type MiningNftProfitRoiSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftTeamSection = Entity &
  Node & {
    __typename?: "MiningNftTeamSection";
    backgroundImage?: Maybe<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftTeamSection>;
    /** List of MiningNftTeamSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionSubtext?: Maybe<RichText>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    teamMembers: Array<TeamMemberImageNameTitle>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftTeamSectionBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftTeamSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftTeamSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftTeamSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftTeamSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftTeamSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftTeamSectionTeamMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TeamMemberImageNameTitleOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
};

export type MiningNftTeamSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftTeamSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftTeamSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftTeamSectionConnection = {
  __typename?: "MiningNftTeamSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftTeamSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftTeamSectionCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  sectionSubtext?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  teamMembers?: InputMaybe<TeamMemberImageNameTitleCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftTeamSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNftTeamSection documents */
  connect?: InputMaybe<Array<MiningNftTeamSectionWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftTeamSection documents */
  create?: InputMaybe<Array<MiningNftTeamSectionCreateInput>>;
};

export type MiningNftTeamSectionCreateOneInlineInput = {
  /** Connect one existing MiningNftTeamSection document */
  connect?: InputMaybe<MiningNftTeamSectionWhereUniqueInput>;
  /** Create and connect one MiningNftTeamSection document */
  create?: InputMaybe<MiningNftTeamSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftTeamSectionEdge = {
  __typename?: "MiningNftTeamSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftTeamSection;
};

/** Identifies documents */
export type MiningNftTeamSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftTeamSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftTeamSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftTeamSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftTeamSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftTeamSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftTeamSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  teamMembers_every?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_none?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_some?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftTeamSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftTeamSectionUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  sectionSubtext?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  teamMembers?: InputMaybe<TeamMemberImageNameTitleUpdateManyInlineInput>;
};

export type MiningNftTeamSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftTeamSection documents */
  connect?: InputMaybe<Array<MiningNftTeamSectionConnectInput>>;
  /** Create and connect multiple MiningNftTeamSection documents */
  create?: InputMaybe<Array<MiningNftTeamSectionCreateInput>>;
  /** Delete multiple MiningNftTeamSection documents */
  delete?: InputMaybe<Array<MiningNftTeamSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNftTeamSection documents */
  disconnect?: InputMaybe<Array<MiningNftTeamSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftTeamSection documents */
  set?: InputMaybe<Array<MiningNftTeamSectionWhereUniqueInput>>;
  /** Update multiple MiningNftTeamSection documents */
  update?: InputMaybe<
    Array<MiningNftTeamSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftTeamSection documents */
  upsert?: InputMaybe<
    Array<MiningNftTeamSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftTeamSectionUpdateManyInput = {
  sectionSubtext?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftTeamSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftTeamSectionUpdateManyInput;
  /** Document search */
  where: MiningNftTeamSectionWhereInput;
};

export type MiningNftTeamSectionUpdateOneInlineInput = {
  /** Connect existing MiningNftTeamSection document */
  connect?: InputMaybe<MiningNftTeamSectionWhereUniqueInput>;
  /** Create and connect one MiningNftTeamSection document */
  create?: InputMaybe<MiningNftTeamSectionCreateInput>;
  /** Delete currently connected MiningNftTeamSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftTeamSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftTeamSection document */
  update?: InputMaybe<MiningNftTeamSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftTeamSection document */
  upsert?: InputMaybe<MiningNftTeamSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftTeamSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftTeamSectionUpdateInput;
  /** Unique document search */
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MiningNftTeamSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftTeamSectionCreateInput;
  /** Update document if it exists */
  update: MiningNftTeamSectionUpdateInput;
};

export type MiningNftTeamSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftTeamSectionUpsertInput;
  /** Unique document search */
  where: MiningNftTeamSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftTeamSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftTeamSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftTeamSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftTeamSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftTeamSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftTeamSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftTeamSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftTeamSectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  teamMembers_every?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_none?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_some?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftTeamSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftTeamSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftTeamSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftTeamSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftTeamSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftTeamSection record uniquely */
export type MiningNftTeamSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftWhitelistManualEntry = Entity &
  Node & {
    __typename?: "MiningNftWhitelistManualEntry";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftWhitelistManualEntry>;
    /** List of MiningNftWhitelistManualEntry versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    whitelistedAddresses: Array<WhitelistedAddress>;
  };

export type MiningNftWhitelistManualEntryCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftWhitelistManualEntryDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftWhitelistManualEntryHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftWhitelistManualEntryPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftWhitelistManualEntryScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftWhitelistManualEntryUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftWhitelistManualEntryWhitelistedAddressesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<WhitelistedAddressOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<WhitelistedAddressWhereInput>;
};

export type MiningNftWhitelistManualEntryConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftWhitelistManualEntryConnection = {
  __typename?: "MiningNftWhitelistManualEntryConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftWhitelistManualEntryEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftWhitelistManualEntryCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  whitelistedAddresses?: InputMaybe<WhitelistedAddressCreateManyInlineInput>;
};

export type MiningNftWhitelistManualEntryCreateManyInlineInput = {
  /** Connect multiple existing MiningNftWhitelistManualEntry documents */
  connect?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftWhitelistManualEntry documents */
  create?: InputMaybe<Array<MiningNftWhitelistManualEntryCreateInput>>;
};

export type MiningNftWhitelistManualEntryCreateOneInlineInput = {
  /** Connect one existing MiningNftWhitelistManualEntry document */
  connect?: InputMaybe<MiningNftWhitelistManualEntryWhereUniqueInput>;
  /** Create and connect one MiningNftWhitelistManualEntry document */
  create?: InputMaybe<MiningNftWhitelistManualEntryCreateInput>;
};

/** An edge in a connection. */
export type MiningNftWhitelistManualEntryEdge = {
  __typename?: "MiningNftWhitelistManualEntryEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftWhitelistManualEntry;
};

/** Identifies documents */
export type MiningNftWhitelistManualEntryManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftWhitelistManualEntryWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftWhitelistManualEntryWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftWhitelistManualEntryWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitelistedAddresses_every?: InputMaybe<WhitelistedAddressWhereInput>;
  whitelistedAddresses_none?: InputMaybe<WhitelistedAddressWhereInput>;
  whitelistedAddresses_some?: InputMaybe<WhitelistedAddressWhereInput>;
};

export enum MiningNftWhitelistManualEntryOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftWhitelistManualEntryUpdateInput = {
  whitelistedAddresses?: InputMaybe<WhitelistedAddressUpdateManyInlineInput>;
};

export type MiningNftWhitelistManualEntryUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftWhitelistManualEntry documents */
  connect?: InputMaybe<Array<MiningNftWhitelistManualEntryConnectInput>>;
  /** Create and connect multiple MiningNftWhitelistManualEntry documents */
  create?: InputMaybe<Array<MiningNftWhitelistManualEntryCreateInput>>;
  /** Delete multiple MiningNftWhitelistManualEntry documents */
  delete?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereUniqueInput>>;
  /** Disconnect multiple MiningNftWhitelistManualEntry documents */
  disconnect?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftWhitelistManualEntry documents */
  set?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereUniqueInput>>;
  /** Update multiple MiningNftWhitelistManualEntry documents */
  update?: InputMaybe<
    Array<MiningNftWhitelistManualEntryUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftWhitelistManualEntry documents */
  upsert?: InputMaybe<
    Array<MiningNftWhitelistManualEntryUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftWhitelistManualEntryUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type MiningNftWhitelistManualEntryUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftWhitelistManualEntryUpdateManyInput;
  /** Document search */
  where: MiningNftWhitelistManualEntryWhereInput;
};

export type MiningNftWhitelistManualEntryUpdateOneInlineInput = {
  /** Connect existing MiningNftWhitelistManualEntry document */
  connect?: InputMaybe<MiningNftWhitelistManualEntryWhereUniqueInput>;
  /** Create and connect one MiningNftWhitelistManualEntry document */
  create?: InputMaybe<MiningNftWhitelistManualEntryCreateInput>;
  /** Delete currently connected MiningNftWhitelistManualEntry document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftWhitelistManualEntry document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftWhitelistManualEntry document */
  update?: InputMaybe<MiningNftWhitelistManualEntryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftWhitelistManualEntry document */
  upsert?: InputMaybe<MiningNftWhitelistManualEntryUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftWhitelistManualEntryUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftWhitelistManualEntryUpdateInput;
  /** Unique document search */
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MiningNftWhitelistManualEntryUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftWhitelistManualEntryCreateInput;
  /** Update document if it exists */
  update: MiningNftWhitelistManualEntryUpdateInput;
};

export type MiningNftWhitelistManualEntryUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftWhitelistManualEntryUpsertInput;
  /** Unique document search */
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftWhitelistManualEntryWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftWhitelistManualEntryWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftWhitelistManualEntryWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftWhitelistManualEntryWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftWhitelistManualEntryWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitelistedAddresses_every?: InputMaybe<WhitelistedAddressWhereInput>;
  whitelistedAddresses_none?: InputMaybe<WhitelistedAddressWhereInput>;
  whitelistedAddresses_some?: InputMaybe<WhitelistedAddressWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftWhitelistManualEntryWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftWhitelistManualEntryWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftWhitelistManualEntryWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftWhitelistManualEntry record uniquely */
export type MiningNftWhitelistManualEntryWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MiningNftfaqSection = Entity &
  Node & {
    __typename?: "MiningNftfaqSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MiningNftfaqSection>;
    faQs: Array<Faq>;
    /** List of MiningNftfaqSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    sectionTitle?: Maybe<RichText>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MiningNftfaqSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftfaqSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MiningNftfaqSectionFaQsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type MiningNftfaqSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MiningNftfaqSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftfaqSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MiningNftfaqSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MiningNftfaqSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MiningNftfaqSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type MiningNftfaqSectionConnection = {
  __typename?: "MiningNftfaqSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MiningNftfaqSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MiningNftfaqSectionCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  faQs?: InputMaybe<FaqCreateManyInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MiningNftfaqSectionCreateManyInlineInput = {
  /** Connect multiple existing MiningNftfaqSection documents */
  connect?: InputMaybe<Array<MiningNftfaqSectionWhereUniqueInput>>;
  /** Create and connect multiple existing MiningNftfaqSection documents */
  create?: InputMaybe<Array<MiningNftfaqSectionCreateInput>>;
};

export type MiningNftfaqSectionCreateOneInlineInput = {
  /** Connect one existing MiningNftfaqSection document */
  connect?: InputMaybe<MiningNftfaqSectionWhereUniqueInput>;
  /** Create and connect one MiningNftfaqSection document */
  create?: InputMaybe<MiningNftfaqSectionCreateInput>;
};

/** An edge in a connection. */
export type MiningNftfaqSectionEdge = {
  __typename?: "MiningNftfaqSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MiningNftfaqSection;
};

/** Identifies documents */
export type MiningNftfaqSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftfaqSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftfaqSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftfaqSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftfaqSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftfaqSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftfaqSectionWhereStageInput>;
  faQs_every?: InputMaybe<FaqWhereInput>;
  faQs_none?: InputMaybe<FaqWhereInput>;
  faQs_some?: InputMaybe<FaqWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MiningNftfaqSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MiningNftfaqSectionUpdateInput = {
  faQs?: InputMaybe<FaqUpdateManyInlineInput>;
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftfaqSectionUpdateManyInlineInput = {
  /** Connect multiple existing MiningNftfaqSection documents */
  connect?: InputMaybe<Array<MiningNftfaqSectionConnectInput>>;
  /** Create and connect multiple MiningNftfaqSection documents */
  create?: InputMaybe<Array<MiningNftfaqSectionCreateInput>>;
  /** Delete multiple MiningNftfaqSection documents */
  delete?: InputMaybe<Array<MiningNftfaqSectionWhereUniqueInput>>;
  /** Disconnect multiple MiningNftfaqSection documents */
  disconnect?: InputMaybe<Array<MiningNftfaqSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MiningNftfaqSection documents */
  set?: InputMaybe<Array<MiningNftfaqSectionWhereUniqueInput>>;
  /** Update multiple MiningNftfaqSection documents */
  update?: InputMaybe<
    Array<MiningNftfaqSectionUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MiningNftfaqSection documents */
  upsert?: InputMaybe<
    Array<MiningNftfaqSectionUpsertWithNestedWhereUniqueInput>
  >;
};

export type MiningNftfaqSectionUpdateManyInput = {
  sectionTitle?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type MiningNftfaqSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MiningNftfaqSectionUpdateManyInput;
  /** Document search */
  where: MiningNftfaqSectionWhereInput;
};

export type MiningNftfaqSectionUpdateOneInlineInput = {
  /** Connect existing MiningNftfaqSection document */
  connect?: InputMaybe<MiningNftfaqSectionWhereUniqueInput>;
  /** Create and connect one MiningNftfaqSection document */
  create?: InputMaybe<MiningNftfaqSectionCreateInput>;
  /** Delete currently connected MiningNftfaqSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MiningNftfaqSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MiningNftfaqSection document */
  update?: InputMaybe<MiningNftfaqSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MiningNftfaqSection document */
  upsert?: InputMaybe<MiningNftfaqSectionUpsertWithNestedWhereUniqueInput>;
};

export type MiningNftfaqSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MiningNftfaqSectionUpdateInput;
  /** Unique document search */
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MiningNftfaqSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: MiningNftfaqSectionCreateInput;
  /** Update document if it exists */
  update: MiningNftfaqSectionUpdateInput;
};

export type MiningNftfaqSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MiningNftfaqSectionUpsertInput;
  /** Unique document search */
  where: MiningNftfaqSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MiningNftfaqSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MiningNftfaqSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftfaqSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftfaqSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftfaqSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MiningNftfaqSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<MiningNftfaqSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<MiningNftfaqSectionWhereStageInput>;
  faQs_every?: InputMaybe<FaqWhereInput>;
  faQs_none?: InputMaybe<FaqWhereInput>;
  faQs_some?: InputMaybe<FaqWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MiningNftfaqSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MiningNftfaqSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MiningNftfaqSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MiningNftfaqSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MiningNftfaqSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MiningNftfaqSection record uniquely */
export type MiningNftfaqSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MintingCurrency = Entity &
  Node & {
    __typename?: "MintingCurrency";
    chain?: Maybe<Chain>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    decimals?: Maybe<Scalars["Int"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<MintingCurrency>;
    /** List of MintingCurrency versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    tokenAddress: Scalars["String"]["output"];
    tokenIcon?: Maybe<Asset>;
    tokenName?: Maybe<Scalars["String"]["output"]>;
    tokenSymbol?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MintingCurrencyChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MintingCurrencyHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MintingCurrencyPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MintingCurrencyTokenIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingCurrencyConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MintingCurrencyWhereUniqueInput;
};

/** A connection to a list of items. */
export type MintingCurrencyConnection = {
  __typename?: "MintingCurrencyConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MintingCurrencyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MintingCurrencyCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  clp90tubd2og001t9glx64li5?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  cls3dkic301hm01j1axgddhv5?: InputMaybe<MintingProjectCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  tokenAddress: Scalars["String"]["input"];
  tokenIcon?: InputMaybe<AssetCreateOneInlineInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MintingCurrencyCreateManyInlineInput = {
  /** Connect multiple existing MintingCurrency documents */
  connect?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Create and connect multiple existing MintingCurrency documents */
  create?: InputMaybe<Array<MintingCurrencyCreateInput>>;
};

export type MintingCurrencyCreateOneInlineInput = {
  /** Connect one existing MintingCurrency document */
  connect?: InputMaybe<MintingCurrencyWhereUniqueInput>;
  /** Create and connect one MintingCurrency document */
  create?: InputMaybe<MintingCurrencyCreateInput>;
};

/** An edge in a connection. */
export type MintingCurrencyEdge = {
  __typename?: "MintingCurrencyEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MintingCurrency;
};

/** Identifies documents */
export type MintingCurrencyManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  decimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  decimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  decimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  decimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  decimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  documentInStages_every?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingCurrencyWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenAddress_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenIcon?: InputMaybe<AssetWhereInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  tokenName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenSymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenSymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenSymbol_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenSymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenSymbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenSymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenSymbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MintingCurrencyOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  decimals_ASC = "decimals_ASC",
  decimals_DESC = "decimals_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  tokenAddress_ASC = "tokenAddress_ASC",
  tokenAddress_DESC = "tokenAddress_DESC",
  tokenName_ASC = "tokenName_ASC",
  tokenName_DESC = "tokenName_DESC",
  tokenSymbol_ASC = "tokenSymbol_ASC",
  tokenSymbol_DESC = "tokenSymbol_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MintingCurrencyUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  clp90tubd2og001t9glx64li5?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  cls3dkic301hm01j1axgddhv5?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
  tokenIcon?: InputMaybe<AssetUpdateOneInlineInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingCurrencyUpdateManyInlineInput = {
  /** Connect multiple existing MintingCurrency documents */
  connect?: InputMaybe<Array<MintingCurrencyConnectInput>>;
  /** Create and connect multiple MintingCurrency documents */
  create?: InputMaybe<Array<MintingCurrencyCreateInput>>;
  /** Delete multiple MintingCurrency documents */
  delete?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Disconnect multiple MintingCurrency documents */
  disconnect?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MintingCurrency documents */
  set?: InputMaybe<Array<MintingCurrencyWhereUniqueInput>>;
  /** Update multiple MintingCurrency documents */
  update?: InputMaybe<Array<MintingCurrencyUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MintingCurrency documents */
  upsert?: InputMaybe<Array<MintingCurrencyUpsertWithNestedWhereUniqueInput>>;
};

export type MintingCurrencyUpdateManyInput = {
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingCurrencyUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MintingCurrencyUpdateManyInput;
  /** Document search */
  where: MintingCurrencyWhereInput;
};

export type MintingCurrencyUpdateOneInlineInput = {
  /** Connect existing MintingCurrency document */
  connect?: InputMaybe<MintingCurrencyWhereUniqueInput>;
  /** Create and connect one MintingCurrency document */
  create?: InputMaybe<MintingCurrencyCreateInput>;
  /** Delete currently connected MintingCurrency document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MintingCurrency document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MintingCurrency document */
  update?: InputMaybe<MintingCurrencyUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MintingCurrency document */
  upsert?: InputMaybe<MintingCurrencyUpsertWithNestedWhereUniqueInput>;
};

export type MintingCurrencyUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MintingCurrencyUpdateInput;
  /** Unique document search */
  where: MintingCurrencyWhereUniqueInput;
};

export type MintingCurrencyUpsertInput = {
  /** Create document if it didn't exist */
  create: MintingCurrencyCreateInput;
  /** Update document if it exists */
  update: MintingCurrencyUpdateInput;
};

export type MintingCurrencyUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MintingCurrencyUpsertInput;
  /** Unique document search */
  where: MintingCurrencyWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MintingCurrencyWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MintingCurrencyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingCurrencyWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  decimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  decimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  decimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  decimals_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  decimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  decimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  decimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  decimals_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  documentInStages_every?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingCurrencyWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingCurrencyWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenAddress_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenIcon?: InputMaybe<AssetWhereInput>;
  tokenName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  tokenName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  tokenSymbol?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  tokenSymbol_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  tokenSymbol_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  tokenSymbol_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  tokenSymbol_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  tokenSymbol_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  tokenSymbol_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  tokenSymbol_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  tokenSymbol_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  tokenSymbol_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MintingCurrencyWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingCurrencyWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingCurrencyWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingCurrencyWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MintingCurrencyWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MintingCurrency record uniquely */
export type MintingCurrencyWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  tokenAddress?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProject = Entity &
  Node & {
    __typename?: "MintingProject";
    aboutSectionAccordions: Array<Faq>;
    aboutSectionHeader?: Maybe<Scalars["String"]["output"]>;
    aboutSectionText?: Maybe<Scalars["String"]["output"]>;
    allocation?: Maybe<Scalars["Int"]["output"]>;
    alphaSharesProject: Scalars["Boolean"]["output"];
    collectionCreator?: Maybe<CollectionCreator>;
    collectionInfoTable: Array<CollectionInfoTable>;
    collectionSampleImages: Array<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaSection?: Maybe<CtaSection>;
    currency?: Maybe<Currencies>;
    /** Get the document in other stages */
    documentInStages: Array<MintingProject>;
    dynamicPricingGetterFunction?: Maybe<Scalars["String"]["output"]>;
    fAQSection?: Maybe<FaqSection>;
    featured: Scalars["Boolean"]["output"];
    hasDynamicPricing: Scalars["Boolean"]["output"];
    /** List of MintingProject versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    maxMintPerTransaction: Scalars["Int"]["output"];
    merkleRoot?: Maybe<Scalars["Json"]["output"]>;
    /** If the minting collection is making use of Metafuse, we need the metafuse project ID */
    metafuseProjectId?: Maybe<Scalars["String"]["output"]>;
    mintDate?: Maybe<Scalars["DateTime"]["output"]>;
    mintInfoTable: Array<CollectionInfoTable>;
    mintPrice?: Maybe<Scalars["Float"]["output"]>;
    mintedNfTsCarouselHeader?: Maybe<Scalars["String"]["output"]>;
    mintedNfTsCarouselSubHeader?: Maybe<Scalars["String"]["output"]>;
    mintingContractAbi?: Maybe<Scalars["Json"]["output"]>;
    mintingContractAddress?: Maybe<Scalars["String"]["output"]>;
    /** Mint Currency, if not native gas token, then must fill out currency contract address as well. */
    mintingCurrency: Scalars["String"]["output"];
    /** Fill this out if the minting currency is not native gas token. */
    mintingCurrencyContractAddress?: Maybe<Scalars["String"]["output"]>;
    mintingCurrencyDecimals?: Maybe<Scalars["Int"]["output"]>;
    mintingCurrencyInformation?: Maybe<MintingCurrency>;
    mintingFunctionName?: Maybe<Scalars["String"]["output"]>;
    mintingStat?: Maybe<MintingStat>;
    nftCollection?: Maybe<NftCollection>;
    paperCreditCardMintingLink?: Maybe<Scalars["String"]["output"]>;
    presaleCheckerFunction?: Maybe<Scalars["String"]["output"]>;
    presaleHeroSectionCopy?: Maybe<Scalars["String"]["output"]>;
    presaleMaxMintGetter?: Maybe<Scalars["String"]["output"]>;
    presaleMintingFunctionName?: Maybe<Scalars["String"]["output"]>;
    presalePriceGetter?: Maybe<Scalars["String"]["output"]>;
    priceQueryFunctionName?: Maybe<Scalars["String"]["output"]>;
    priceQueryRequiresAccount: Scalars["Boolean"]["output"];
    projectName: Scalars["String"]["output"];
    projectSecurityTags: Array<ProjectSecurityTags>;
    publicSaleMaxMintGetter?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    securityLineItems: Array<SecurityInfo>;
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    seoTitle?: Maybe<Scalars["String"]["output"]>;
    /** Defaults to false. If true, the revealed images will be public on mint. */
    shouldMetafuseRevealOnMint: Scalars["Boolean"]["output"];
    /** url for minting project */
    slug?: Maybe<Scalars["String"]["output"]>;
    soldOut?: Maybe<Scalars["Boolean"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    whitelistChecker?: Maybe<Scalars["String"]["output"]>;
  };

export type MintingProjectAboutSectionAccordionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type MintingProjectCollectionCreatorArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectCollectionInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MintingProjectCollectionSampleImagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type MintingProjectCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MintingProjectFAqSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MintingProjectMintInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MintingProjectMintingCurrencyInformationArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectMintingStatArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectNftCollectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MintingProjectSecurityLineItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SecurityInfoOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SecurityInfoWhereInput>;
};

export type MintingProjectUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingProjectConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MintingProjectWhereUniqueInput;
};

/** A connection to a list of items. */
export type MintingProjectConnection = {
  __typename?: "MintingProjectConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MintingProjectEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MintingProjectCreateInput = {
  aboutSectionAccordions?: InputMaybe<FaqCreateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  alphaSharesProject: Scalars["Boolean"]["input"];
  collectionCreator?: InputMaybe<CollectionCreatorCreateOneInlineInput>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  currency?: InputMaybe<Currencies>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  fAQSection?: InputMaybe<FaqSectionCreateOneInlineInput>;
  featured: Scalars["Boolean"]["input"];
  hasDynamicPricing: Scalars["Boolean"]["input"];
  maxMintPerTransaction: Scalars["Int"]["input"];
  merkleRoot?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency: Scalars["String"]["input"];
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyCreateOneInlineInput>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatCreateOneInlineInput>;
  nftCollection?: InputMaybe<NftCollectionCreateOneInlineInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount: Scalars["Boolean"]["input"];
  projectName: Scalars["String"]["input"];
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  securityLineItems?: InputMaybe<SecurityInfoCreateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint: Scalars["Boolean"]["input"];
  slug?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProjectCreateManyInlineInput = {
  /** Connect multiple existing MintingProject documents */
  connect?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Create and connect multiple existing MintingProject documents */
  create?: InputMaybe<Array<MintingProjectCreateInput>>;
};

export type MintingProjectCreateOneInlineInput = {
  /** Connect one existing MintingProject document */
  connect?: InputMaybe<MintingProjectWhereUniqueInput>;
  /** Create and connect one MintingProject document */
  create?: InputMaybe<MintingProjectCreateInput>;
};

/** An edge in a connection. */
export type MintingProjectEdge = {
  __typename?: "MintingProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MintingProject;
};

/** Identifies documents */
export type MintingProjectManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  allocation_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  allocation_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  allocation_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  allocation_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  allocation_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  allocation_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  allocation_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingProjectWhereStageInput>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  dynamicPricingGetterFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  dynamicPricingGetterFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  dynamicPricingGetterFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  dynamicPricingGetterFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  dynamicPricingGetterFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  dynamicPricingGetterFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  dynamicPricingGetterFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  dynamicPricingGetterFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  dynamicPricingGetterFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  hasDynamicPricing_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values containing the given json path. */
  merkleRoot_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  merkleRoot_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  metafuseProjectId_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  metafuseProjectId_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  metafuseProjectId_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  metafuseProjectId_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  metafuseProjectId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  metafuseProjectId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  metafuseProjectId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  metafuseProjectId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  metafuseProjectId_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values containing the given json path. */
  mintingContractAbi_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  mintingContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingContractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingContractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingContractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingCurrencyContractAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  mintingCurrencyContractAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintingCurrencyContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrencyContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintingCurrencyContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintingCurrencyContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrencyContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingCurrencyContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  mintingCurrencyDecimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  mintingCurrencyDecimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrencyDecimals_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  mintingCurrencyDecimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  mintingCurrencyDecimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyDecimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrencyDecimals_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyWhereInput>;
  /** All values containing the given string. */
  mintingCurrency_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingCurrency_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrency_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrency_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrency_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingCurrency_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrency_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrency_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingCurrency_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingFunctionName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollection?: InputMaybe<NftCollectionWhereInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  paperCreditCardMintingLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  paperCreditCardMintingLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  paperCreditCardMintingLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  paperCreditCardMintingLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  paperCreditCardMintingLink_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  paperCreditCardMintingLink_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  paperCreditCardMintingLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  paperCreditCardMintingLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  paperCreditCardMintingLink_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleCheckerFunction_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleCheckerFunction_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleCheckerFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleCheckerFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleCheckerFunction_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleCheckerFunction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleCheckerFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleCheckerFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleCheckerFunction_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleHeroSectionCopy_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleHeroSectionCopy_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleHeroSectionCopy_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleHeroSectionCopy_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleHeroSectionCopy_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleHeroSectionCopy_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleHeroSectionCopy_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleHeroSectionCopy_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleHeroSectionCopy_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleMaxMintGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMaxMintGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMintingFunctionName_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  presaleMintingFunctionName_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  presaleMintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMintingFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleMintingFunctionName_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presalePriceGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presalePriceGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presalePriceGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presalePriceGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presalePriceGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presalePriceGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presalePriceGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presalePriceGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presalePriceGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  priceQueryFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  priceQueryFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  priceQueryFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  priceQueryFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  priceQueryFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  priceQueryFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  priceQueryFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  priceQueryFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryRequiresAccount_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  publicSaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  publicSaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  publicSaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  publicSaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  publicSaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  publicSaleMaxMintGetter_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  publicSaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  publicSaleMaxMintGetter_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  publicSaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  shouldMetafuseRevealOnMint_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  soldOut_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  whitelistChecker_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  whitelistChecker_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  whitelistChecker_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  whitelistChecker_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  whitelistChecker_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  whitelistChecker_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  whitelistChecker_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  whitelistChecker_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  whitelistChecker_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum MintingProjectOrderByInput {
  aboutSectionHeader_ASC = "aboutSectionHeader_ASC",
  aboutSectionHeader_DESC = "aboutSectionHeader_DESC",
  aboutSectionText_ASC = "aboutSectionText_ASC",
  aboutSectionText_DESC = "aboutSectionText_DESC",
  allocation_ASC = "allocation_ASC",
  allocation_DESC = "allocation_DESC",
  alphaSharesProject_ASC = "alphaSharesProject_ASC",
  alphaSharesProject_DESC = "alphaSharesProject_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  currency_ASC = "currency_ASC",
  currency_DESC = "currency_DESC",
  dynamicPricingGetterFunction_ASC = "dynamicPricingGetterFunction_ASC",
  dynamicPricingGetterFunction_DESC = "dynamicPricingGetterFunction_DESC",
  featured_ASC = "featured_ASC",
  featured_DESC = "featured_DESC",
  hasDynamicPricing_ASC = "hasDynamicPricing_ASC",
  hasDynamicPricing_DESC = "hasDynamicPricing_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  maxMintPerTransaction_ASC = "maxMintPerTransaction_ASC",
  maxMintPerTransaction_DESC = "maxMintPerTransaction_DESC",
  metafuseProjectId_ASC = "metafuseProjectId_ASC",
  metafuseProjectId_DESC = "metafuseProjectId_DESC",
  mintDate_ASC = "mintDate_ASC",
  mintDate_DESC = "mintDate_DESC",
  mintPrice_ASC = "mintPrice_ASC",
  mintPrice_DESC = "mintPrice_DESC",
  mintedNfTsCarouselHeader_ASC = "mintedNfTsCarouselHeader_ASC",
  mintedNfTsCarouselHeader_DESC = "mintedNfTsCarouselHeader_DESC",
  mintedNfTsCarouselSubHeader_ASC = "mintedNfTsCarouselSubHeader_ASC",
  mintedNfTsCarouselSubHeader_DESC = "mintedNfTsCarouselSubHeader_DESC",
  mintingContractAddress_ASC = "mintingContractAddress_ASC",
  mintingContractAddress_DESC = "mintingContractAddress_DESC",
  mintingCurrencyContractAddress_ASC = "mintingCurrencyContractAddress_ASC",
  mintingCurrencyContractAddress_DESC = "mintingCurrencyContractAddress_DESC",
  mintingCurrencyDecimals_ASC = "mintingCurrencyDecimals_ASC",
  mintingCurrencyDecimals_DESC = "mintingCurrencyDecimals_DESC",
  mintingCurrency_ASC = "mintingCurrency_ASC",
  mintingCurrency_DESC = "mintingCurrency_DESC",
  mintingFunctionName_ASC = "mintingFunctionName_ASC",
  mintingFunctionName_DESC = "mintingFunctionName_DESC",
  paperCreditCardMintingLink_ASC = "paperCreditCardMintingLink_ASC",
  paperCreditCardMintingLink_DESC = "paperCreditCardMintingLink_DESC",
  presaleCheckerFunction_ASC = "presaleCheckerFunction_ASC",
  presaleCheckerFunction_DESC = "presaleCheckerFunction_DESC",
  presaleHeroSectionCopy_ASC = "presaleHeroSectionCopy_ASC",
  presaleHeroSectionCopy_DESC = "presaleHeroSectionCopy_DESC",
  presaleMaxMintGetter_ASC = "presaleMaxMintGetter_ASC",
  presaleMaxMintGetter_DESC = "presaleMaxMintGetter_DESC",
  presaleMintingFunctionName_ASC = "presaleMintingFunctionName_ASC",
  presaleMintingFunctionName_DESC = "presaleMintingFunctionName_DESC",
  presalePriceGetter_ASC = "presalePriceGetter_ASC",
  presalePriceGetter_DESC = "presalePriceGetter_DESC",
  priceQueryFunctionName_ASC = "priceQueryFunctionName_ASC",
  priceQueryFunctionName_DESC = "priceQueryFunctionName_DESC",
  priceQueryRequiresAccount_ASC = "priceQueryRequiresAccount_ASC",
  priceQueryRequiresAccount_DESC = "priceQueryRequiresAccount_DESC",
  projectName_ASC = "projectName_ASC",
  projectName_DESC = "projectName_DESC",
  projectSecurityTags_ASC = "projectSecurityTags_ASC",
  projectSecurityTags_DESC = "projectSecurityTags_DESC",
  publicSaleMaxMintGetter_ASC = "publicSaleMaxMintGetter_ASC",
  publicSaleMaxMintGetter_DESC = "publicSaleMaxMintGetter_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  seoDescription_ASC = "seoDescription_ASC",
  seoDescription_DESC = "seoDescription_DESC",
  seoTitle_ASC = "seoTitle_ASC",
  seoTitle_DESC = "seoTitle_DESC",
  shouldMetafuseRevealOnMint_ASC = "shouldMetafuseRevealOnMint_ASC",
  shouldMetafuseRevealOnMint_DESC = "shouldMetafuseRevealOnMint_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  soldOut_ASC = "soldOut_ASC",
  soldOut_DESC = "soldOut_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
  whitelistChecker_ASC = "whitelistChecker_ASC",
  whitelistChecker_DESC = "whitelistChecker_DESC",
}

export type MintingProjectUpdateInput = {
  aboutSectionAccordions?: InputMaybe<FaqUpdateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorUpdateOneInlineInput>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetUpdateManyInlineInput>;
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  currency?: InputMaybe<Currencies>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  fAQSection?: InputMaybe<FaqSectionUpdateOneInlineInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  merkleRoot?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyUpdateOneInlineInput>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatUpdateOneInlineInput>;
  nftCollection?: InputMaybe<NftCollectionUpdateOneInlineInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  securityLineItems?: InputMaybe<SecurityInfoUpdateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProjectUpdateManyInlineInput = {
  /** Connect multiple existing MintingProject documents */
  connect?: InputMaybe<Array<MintingProjectConnectInput>>;
  /** Create and connect multiple MintingProject documents */
  create?: InputMaybe<Array<MintingProjectCreateInput>>;
  /** Delete multiple MintingProject documents */
  delete?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Disconnect multiple MintingProject documents */
  disconnect?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MintingProject documents */
  set?: InputMaybe<Array<MintingProjectWhereUniqueInput>>;
  /** Update multiple MintingProject documents */
  update?: InputMaybe<Array<MintingProjectUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MintingProject documents */
  upsert?: InputMaybe<Array<MintingProjectUpsertWithNestedWhereUniqueInput>>;
};

export type MintingProjectUpdateManyInput = {
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  currency?: InputMaybe<Currencies>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  merkleRoot?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingProjectUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MintingProjectUpdateManyInput;
  /** Document search */
  where: MintingProjectWhereInput;
};

export type MintingProjectUpdateOneInlineInput = {
  /** Connect existing MintingProject document */
  connect?: InputMaybe<MintingProjectWhereUniqueInput>;
  /** Create and connect one MintingProject document */
  create?: InputMaybe<MintingProjectCreateInput>;
  /** Delete currently connected MintingProject document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MintingProject document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MintingProject document */
  update?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MintingProject document */
  upsert?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type MintingProjectUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MintingProjectUpdateInput;
  /** Unique document search */
  where: MintingProjectWhereUniqueInput;
};

export type MintingProjectUpsertInput = {
  /** Create document if it didn't exist */
  create: MintingProjectCreateInput;
  /** Update document if it exists */
  update: MintingProjectUpdateInput;
};

export type MintingProjectUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MintingProjectUpsertInput;
  /** Unique document search */
  where: MintingProjectWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MintingProjectWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MintingProjectWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  allocation?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  allocation_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  allocation_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  allocation_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  allocation_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  allocation_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  allocation_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  allocation_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingProjectWhereStageInput>;
  dynamicPricingGetterFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  dynamicPricingGetterFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  dynamicPricingGetterFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  dynamicPricingGetterFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  dynamicPricingGetterFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  dynamicPricingGetterFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  dynamicPricingGetterFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  dynamicPricingGetterFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  dynamicPricingGetterFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  dynamicPricingGetterFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  hasDynamicPricing?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  hasDynamicPricing_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values containing the given json path. */
  merkleRoot_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  merkleRoot_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  metafuseProjectId?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  metafuseProjectId_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  metafuseProjectId_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  metafuseProjectId_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  metafuseProjectId_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  metafuseProjectId_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  metafuseProjectId_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  metafuseProjectId_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  metafuseProjectId_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  metafuseProjectId_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values containing the given json path. */
  mintingContractAbi_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  mintingContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  mintingContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingContractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingContractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingContractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrency?: InputMaybe<Scalars["String"]["input"]>;
  mintingCurrencyContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingCurrencyContractAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  mintingCurrencyContractAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintingCurrencyContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrencyContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintingCurrencyContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintingCurrencyContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrencyContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintingCurrencyContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingCurrencyDecimals?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  mintingCurrencyDecimals_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  mintingCurrencyDecimals_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrencyDecimals_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  mintingCurrencyDecimals_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  mintingCurrencyDecimals_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrencyDecimals_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrencyDecimals_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintingCurrencyInformation?: InputMaybe<MintingCurrencyWhereInput>;
  /** All values containing the given string. */
  mintingCurrency_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingCurrency_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingCurrency_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingCurrency_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingCurrency_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingCurrency_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingCurrency_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingCurrency_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingCurrency_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintingFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintingFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintingFunctionName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintingFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollection?: InputMaybe<NftCollectionWhereInput>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  paperCreditCardMintingLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  paperCreditCardMintingLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  paperCreditCardMintingLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  paperCreditCardMintingLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  paperCreditCardMintingLink_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  paperCreditCardMintingLink_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  paperCreditCardMintingLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  paperCreditCardMintingLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  paperCreditCardMintingLink_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presaleCheckerFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleCheckerFunction_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleCheckerFunction_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleCheckerFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleCheckerFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleCheckerFunction_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleCheckerFunction_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleCheckerFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleCheckerFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleCheckerFunction_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleHeroSectionCopy?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleHeroSectionCopy_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleHeroSectionCopy_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleHeroSectionCopy_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleHeroSectionCopy_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleHeroSectionCopy_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleHeroSectionCopy_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleHeroSectionCopy_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleHeroSectionCopy_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleHeroSectionCopy_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presaleMaxMintGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMaxMintGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleMintingFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presaleMintingFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presaleMintingFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presaleMintingFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presaleMintingFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presaleMintingFunctionName_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  presaleMintingFunctionName_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  presaleMintingFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presaleMintingFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  presaleMintingFunctionName_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  presalePriceGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  presalePriceGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  presalePriceGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  presalePriceGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  presalePriceGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  presalePriceGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  presalePriceGetter_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  presalePriceGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  presalePriceGetter_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  presalePriceGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryFunctionName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  priceQueryFunctionName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  priceQueryFunctionName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  priceQueryFunctionName_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryFunctionName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  priceQueryFunctionName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  priceQueryFunctionName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  priceQueryFunctionName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  priceQueryFunctionName_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  priceQueryFunctionName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  priceQueryRequiresAccount?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  priceQueryRequiresAccount_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publicSaleMaxMintGetter?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  publicSaleMaxMintGetter_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  publicSaleMaxMintGetter_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  publicSaleMaxMintGetter_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  publicSaleMaxMintGetter_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  publicSaleMaxMintGetter_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  publicSaleMaxMintGetter_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  publicSaleMaxMintGetter_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  publicSaleMaxMintGetter_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  publicSaleMaxMintGetter_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  shouldMetafuseRevealOnMint?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  shouldMetafuseRevealOnMint_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  soldOut_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  whitelistChecker?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  whitelistChecker_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  whitelistChecker_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  whitelistChecker_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  whitelistChecker_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  whitelistChecker_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  whitelistChecker_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  whitelistChecker_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  whitelistChecker_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  whitelistChecker_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MintingProjectWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingProjectWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingProjectWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingProjectWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MintingProjectWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MintingProject record uniquely */
export type MintingProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  paperCreditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingStat = Entity &
  Node & {
    __typename?: "MintingStat";
    backgroundImage?: Maybe<Asset>;
    button?: Maybe<Button>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<MintingStat>;
    feesInformationText?: Maybe<Scalars["String"]["output"]>;
    flexibleText?: Maybe<ColoredText>;
    heading?: Maybe<Scalars["String"]["output"]>;
    heroImage?: Maybe<Asset>;
    heroImageLeft?: Maybe<Asset>;
    /** List of MintingStat versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    inProgressText?: Maybe<Scalars["String"]["output"]>;
    mintedCountText?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    subHeading?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MintingStatBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatButtonArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MintingStatFlexibleTextArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatHeroImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatHeroImageLeftArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MintingStatPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MintingStatUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MintingStatConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MintingStatWhereUniqueInput;
};

/** A connection to a list of items. */
export type MintingStatConnection = {
  __typename?: "MintingStatConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MintingStatEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MintingStatCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  button?: InputMaybe<ButtonCreateOneInlineInput>;
  cl4cbjotb3puu01xud88bcwdi?: InputMaybe<PageCreateManyInlineInput>;
  cl80ihw7j09gi01t13voa75rh?: InputMaybe<MintingProjectCreateManyInlineInput>;
  clpbp452i7nv801ukfih32fqd?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextCreateOneInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetCreateOneInlineInput>;
  heroImageLeft?: InputMaybe<AssetCreateOneInlineInput>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MintingStatCreateManyInlineInput = {
  /** Connect multiple existing MintingStat documents */
  connect?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Create and connect multiple existing MintingStat documents */
  create?: InputMaybe<Array<MintingStatCreateInput>>;
};

export type MintingStatCreateOneInlineInput = {
  /** Connect one existing MintingStat document */
  connect?: InputMaybe<MintingStatWhereUniqueInput>;
  /** Create and connect one MintingStat document */
  create?: InputMaybe<MintingStatCreateInput>;
};

/** An edge in a connection. */
export type MintingStatEdge = {
  __typename?: "MintingStatEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MintingStat;
};

/** Identifies documents */
export type MintingStatManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  button?: InputMaybe<ButtonWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingStatWhereStageInput>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  feesInformationText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  feesInformationText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  feesInformationText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  feesInformationText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  feesInformationText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  feesInformationText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  feesInformationText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  feesInformationText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  feesInformationText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetWhereInput>;
  heroImageLeft?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  inProgressText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  inProgressText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  inProgressText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  inProgressText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  inProgressText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  inProgressText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  inProgressText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  inProgressText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  inProgressText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedCountText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedCountText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedCountText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedCountText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedCountText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintedCountText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintedCountText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedCountText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintedCountText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MintingStatOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  feesInformationText_ASC = "feesInformationText_ASC",
  feesInformationText_DESC = "feesInformationText_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  inProgressText_ASC = "inProgressText_ASC",
  inProgressText_DESC = "inProgressText_DESC",
  mintedCountText_ASC = "mintedCountText_ASC",
  mintedCountText_DESC = "mintedCountText_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MintingStatUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  button?: InputMaybe<ButtonUpdateOneInlineInput>;
  cl4cbjotb3puu01xud88bcwdi?: InputMaybe<PageUpdateManyInlineInput>;
  cl80ihw7j09gi01t13voa75rh?: InputMaybe<MintingProjectUpdateManyInlineInput>;
  clpbp452i7nv801ukfih32fqd?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextUpdateOneInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetUpdateOneInlineInput>;
  heroImageLeft?: InputMaybe<AssetUpdateOneInlineInput>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingStatUpdateManyInlineInput = {
  /** Connect multiple existing MintingStat documents */
  connect?: InputMaybe<Array<MintingStatConnectInput>>;
  /** Create and connect multiple MintingStat documents */
  create?: InputMaybe<Array<MintingStatCreateInput>>;
  /** Delete multiple MintingStat documents */
  delete?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Disconnect multiple MintingStat documents */
  disconnect?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MintingStat documents */
  set?: InputMaybe<Array<MintingStatWhereUniqueInput>>;
  /** Update multiple MintingStat documents */
  update?: InputMaybe<Array<MintingStatUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple MintingStat documents */
  upsert?: InputMaybe<Array<MintingStatUpsertWithNestedWhereUniqueInput>>;
};

export type MintingStatUpdateManyInput = {
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type MintingStatUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MintingStatUpdateManyInput;
  /** Document search */
  where: MintingStatWhereInput;
};

export type MintingStatUpdateOneInlineInput = {
  /** Connect existing MintingStat document */
  connect?: InputMaybe<MintingStatWhereUniqueInput>;
  /** Create and connect one MintingStat document */
  create?: InputMaybe<MintingStatCreateInput>;
  /** Delete currently connected MintingStat document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MintingStat document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MintingStat document */
  update?: InputMaybe<MintingStatUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MintingStat document */
  upsert?: InputMaybe<MintingStatUpsertWithNestedWhereUniqueInput>;
};

export type MintingStatUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MintingStatUpdateInput;
  /** Unique document search */
  where: MintingStatWhereUniqueInput;
};

export type MintingStatUpsertInput = {
  /** Create document if it didn't exist */
  create: MintingStatCreateInput;
  /** Update document if it exists */
  update: MintingStatUpdateInput;
};

export type MintingStatUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MintingStatUpsertInput;
  /** Unique document search */
  where: MintingStatWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MintingStatWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MintingStatWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingStatWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  button?: InputMaybe<ButtonWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_none?: InputMaybe<MintingStatWhereStageInput>;
  documentInStages_some?: InputMaybe<MintingStatWhereStageInput>;
  feesInformationText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  feesInformationText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  feesInformationText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  feesInformationText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  feesInformationText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  feesInformationText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  feesInformationText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  feesInformationText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  feesInformationText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  feesInformationText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  flexibleText?: InputMaybe<ColoredTextWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  heroImage?: InputMaybe<AssetWhereInput>;
  heroImageLeft?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  inProgressText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  inProgressText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  inProgressText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  inProgressText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  inProgressText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  inProgressText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  inProgressText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  inProgressText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  inProgressText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  inProgressText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedCountText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedCountText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedCountText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedCountText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedCountText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedCountText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mintedCountText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mintedCountText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedCountText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mintedCountText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MintingStatWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MintingStatWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MintingStatWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MintingStatWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MintingStatWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MintingStat record uniquely */
export type MintingStatWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MultiChainCreditCardLink = Entity & {
  __typename?: "MultiChainCreditCardLink";
  chain?: Maybe<Chain>;
  creditCardMintingLink?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
};

export type MultiChainCreditCardLinkChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainCreditCardLinkConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MultiChainCreditCardLinkWhereUniqueInput;
};

/** A connection to a list of items. */
export type MultiChainCreditCardLinkConnection = {
  __typename?: "MultiChainCreditCardLinkConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MultiChainCreditCardLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MultiChainCreditCardLinkCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  creditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiChainCreditCardLinkCreateManyInlineInput = {
  /** Create and connect multiple existing MultiChainCreditCardLink documents */
  create?: InputMaybe<Array<MultiChainCreditCardLinkCreateInput>>;
};

export type MultiChainCreditCardLinkCreateOneInlineInput = {
  /** Create and connect one MultiChainCreditCardLink document */
  create?: InputMaybe<MultiChainCreditCardLinkCreateInput>;
};

export type MultiChainCreditCardLinkCreateWithPositionInput = {
  /** Document to create */
  data: MultiChainCreditCardLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MultiChainCreditCardLinkEdge = {
  __typename?: "MultiChainCreditCardLinkEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MultiChainCreditCardLink;
};

/** Identifies documents */
export type MultiChainCreditCardLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainCreditCardLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainCreditCardLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainCreditCardLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  creditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  creditCardMintingLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  creditCardMintingLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  creditCardMintingLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  creditCardMintingLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  creditCardMintingLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  creditCardMintingLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  creditCardMintingLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  creditCardMintingLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  creditCardMintingLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum MultiChainCreditCardLinkOrderByInput {
  creditCardMintingLink_ASC = "creditCardMintingLink_ASC",
  creditCardMintingLink_DESC = "creditCardMintingLink_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
}

export type MultiChainCreditCardLinkParent = MultiChainMintingProject;

export type MultiChainCreditCardLinkParentConnectInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type MultiChainCreditCardLinkParentCreateInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type MultiChainCreditCardLinkParentCreateManyInlineInput = {
  /** Connect multiple existing MultiChainCreditCardLinkParent documents */
  connect?: InputMaybe<Array<MultiChainCreditCardLinkParentWhereUniqueInput>>;
  /** Create and connect multiple existing MultiChainCreditCardLinkParent documents */
  create?: InputMaybe<Array<MultiChainCreditCardLinkParentCreateInput>>;
};

export type MultiChainCreditCardLinkParentCreateOneInlineInput = {
  /** Connect one existing MultiChainCreditCardLinkParent document */
  connect?: InputMaybe<MultiChainCreditCardLinkParentWhereUniqueInput>;
  /** Create and connect one MultiChainCreditCardLinkParent document */
  create?: InputMaybe<MultiChainCreditCardLinkParentCreateInput>;
};

export type MultiChainCreditCardLinkParentUpdateInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type MultiChainCreditCardLinkParentUpdateManyInlineInput = {
  /** Connect multiple existing MultiChainCreditCardLinkParent documents */
  connect?: InputMaybe<Array<MultiChainCreditCardLinkParentConnectInput>>;
  /** Create and connect multiple MultiChainCreditCardLinkParent documents */
  create?: InputMaybe<Array<MultiChainCreditCardLinkParentCreateInput>>;
  /** Delete multiple MultiChainCreditCardLinkParent documents */
  delete?: InputMaybe<Array<MultiChainCreditCardLinkParentWhereUniqueInput>>;
  /** Disconnect multiple MultiChainCreditCardLinkParent documents */
  disconnect?: InputMaybe<
    Array<MultiChainCreditCardLinkParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing MultiChainCreditCardLinkParent documents */
  set?: InputMaybe<Array<MultiChainCreditCardLinkParentWhereUniqueInput>>;
  /** Update multiple MultiChainCreditCardLinkParent documents */
  update?: InputMaybe<
    Array<MultiChainCreditCardLinkParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MultiChainCreditCardLinkParent documents */
  upsert?: InputMaybe<
    Array<MultiChainCreditCardLinkParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type MultiChainCreditCardLinkParentUpdateManyWithNestedWhereInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type MultiChainCreditCardLinkParentUpdateOneInlineInput = {
  /** Connect existing MultiChainCreditCardLinkParent document */
  connect?: InputMaybe<MultiChainCreditCardLinkParentWhereUniqueInput>;
  /** Create and connect one MultiChainCreditCardLinkParent document */
  create?: InputMaybe<MultiChainCreditCardLinkParentCreateInput>;
  /** Delete currently connected MultiChainCreditCardLinkParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MultiChainCreditCardLinkParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MultiChainCreditCardLinkParent document */
  update?: InputMaybe<MultiChainCreditCardLinkParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MultiChainCreditCardLinkParent document */
  upsert?: InputMaybe<MultiChainCreditCardLinkParentUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainCreditCardLinkParentUpdateWithNestedWhereUniqueInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type MultiChainCreditCardLinkParentUpsertWithNestedWhereUniqueInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainCreditCardLinkParentWhereInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type MultiChainCreditCardLinkParentWhereUniqueInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type MultiChainCreditCardLinkUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  creditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiChainCreditCardLinkUpdateManyInlineInput = {
  /** Create and connect multiple MultiChainCreditCardLink component instances */
  create?: InputMaybe<Array<MultiChainCreditCardLinkCreateWithPositionInput>>;
  /** Delete multiple MultiChainCreditCardLink documents */
  delete?: InputMaybe<Array<MultiChainCreditCardLinkWhereUniqueInput>>;
  /** Update multiple MultiChainCreditCardLink component instances */
  update?: InputMaybe<
    Array<MultiChainCreditCardLinkUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MultiChainCreditCardLink component instances */
  upsert?: InputMaybe<
    Array<MultiChainCreditCardLinkUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MultiChainCreditCardLinkUpdateManyInput = {
  creditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiChainCreditCardLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MultiChainCreditCardLinkUpdateManyInput;
  /** Document search */
  where: MultiChainCreditCardLinkWhereInput;
};

export type MultiChainCreditCardLinkUpdateOneInlineInput = {
  /** Create and connect one MultiChainCreditCardLink document */
  create?: InputMaybe<MultiChainCreditCardLinkCreateInput>;
  /** Delete currently connected MultiChainCreditCardLink document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MultiChainCreditCardLink document */
  update?: InputMaybe<MultiChainCreditCardLinkUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MultiChainCreditCardLink document */
  upsert?: InputMaybe<MultiChainCreditCardLinkUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainCreditCardLinkUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<MultiChainCreditCardLinkUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MultiChainCreditCardLinkWhereUniqueInput;
  };

export type MultiChainCreditCardLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MultiChainCreditCardLinkUpdateInput;
  /** Unique document search */
  where: MultiChainCreditCardLinkWhereUniqueInput;
};

export type MultiChainCreditCardLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: MultiChainCreditCardLinkCreateInput;
  /** Update document if it exists */
  update: MultiChainCreditCardLinkUpdateInput;
};

export type MultiChainCreditCardLinkUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<MultiChainCreditCardLinkUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MultiChainCreditCardLinkWhereUniqueInput;
  };

export type MultiChainCreditCardLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MultiChainCreditCardLinkUpsertInput;
  /** Unique document search */
  where: MultiChainCreditCardLinkWhereUniqueInput;
};

/** Identifies documents */
export type MultiChainCreditCardLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainCreditCardLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainCreditCardLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainCreditCardLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  creditCardMintingLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  creditCardMintingLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  creditCardMintingLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  creditCardMintingLink_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  creditCardMintingLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  creditCardMintingLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  creditCardMintingLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  creditCardMintingLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  creditCardMintingLink_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  creditCardMintingLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

/** References MultiChainCreditCardLink record uniquely */
export type MultiChainCreditCardLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MultiChainMintingContract = Entity & {
  __typename?: "MultiChainMintingContract";
  chain?: Maybe<Chain>;
  contractAbi?: Maybe<Scalars["Json"]["output"]>;
  contractAddress?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  soldOut?: Maybe<Scalars["Boolean"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type MultiChainMintingContractChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingContractConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MultiChainMintingContractWhereUniqueInput;
};

/** A connection to a list of items. */
export type MultiChainMintingContractConnection = {
  __typename?: "MultiChainMintingContractConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MultiChainMintingContractEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MultiChainMintingContractCreateInput = {
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  contractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  contractAddress?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MultiChainMintingContractCreateManyInlineInput = {
  /** Create and connect multiple existing MultiChainMintingContract documents */
  create?: InputMaybe<Array<MultiChainMintingContractCreateInput>>;
};

export type MultiChainMintingContractCreateOneInlineInput = {
  /** Create and connect one MultiChainMintingContract document */
  create?: InputMaybe<MultiChainMintingContractCreateInput>;
};

export type MultiChainMintingContractCreateWithPositionInput = {
  /** Document to create */
  data: MultiChainMintingContractCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type MultiChainMintingContractEdge = {
  __typename?: "MultiChainMintingContractEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MultiChainMintingContract;
};

/** Identifies documents */
export type MultiChainMintingContractManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingContractWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingContractWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingContractWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  /** All values containing the given json path. */
  contractAbi_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  contractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  contractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  contractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  contractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  contractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  contractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  contractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  contractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  contractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  contractAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  contractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  soldOut_not?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export enum MultiChainMintingContractOrderByInput {
  contractAddress_ASC = "contractAddress_ASC",
  contractAddress_DESC = "contractAddress_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  soldOut_ASC = "soldOut_ASC",
  soldOut_DESC = "soldOut_DESC",
}

export type MultiChainMintingContractParent = MultiChainMintingProject;

export type MultiChainMintingContractParentConnectInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type MultiChainMintingContractParentCreateInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type MultiChainMintingContractParentCreateManyInlineInput = {
  /** Connect multiple existing MultiChainMintingContractParent documents */
  connect?: InputMaybe<Array<MultiChainMintingContractParentWhereUniqueInput>>;
  /** Create and connect multiple existing MultiChainMintingContractParent documents */
  create?: InputMaybe<Array<MultiChainMintingContractParentCreateInput>>;
};

export type MultiChainMintingContractParentCreateOneInlineInput = {
  /** Connect one existing MultiChainMintingContractParent document */
  connect?: InputMaybe<MultiChainMintingContractParentWhereUniqueInput>;
  /** Create and connect one MultiChainMintingContractParent document */
  create?: InputMaybe<MultiChainMintingContractParentCreateInput>;
};

export type MultiChainMintingContractParentUpdateInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type MultiChainMintingContractParentUpdateManyInlineInput = {
  /** Connect multiple existing MultiChainMintingContractParent documents */
  connect?: InputMaybe<Array<MultiChainMintingContractParentConnectInput>>;
  /** Create and connect multiple MultiChainMintingContractParent documents */
  create?: InputMaybe<Array<MultiChainMintingContractParentCreateInput>>;
  /** Delete multiple MultiChainMintingContractParent documents */
  delete?: InputMaybe<Array<MultiChainMintingContractParentWhereUniqueInput>>;
  /** Disconnect multiple MultiChainMintingContractParent documents */
  disconnect?: InputMaybe<
    Array<MultiChainMintingContractParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing MultiChainMintingContractParent documents */
  set?: InputMaybe<Array<MultiChainMintingContractParentWhereUniqueInput>>;
  /** Update multiple MultiChainMintingContractParent documents */
  update?: InputMaybe<
    Array<MultiChainMintingContractParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MultiChainMintingContractParent documents */
  upsert?: InputMaybe<
    Array<MultiChainMintingContractParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type MultiChainMintingContractParentUpdateManyWithNestedWhereInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type MultiChainMintingContractParentUpdateOneInlineInput = {
  /** Connect existing MultiChainMintingContractParent document */
  connect?: InputMaybe<MultiChainMintingContractParentWhereUniqueInput>;
  /** Create and connect one MultiChainMintingContractParent document */
  create?: InputMaybe<MultiChainMintingContractParentCreateInput>;
  /** Delete currently connected MultiChainMintingContractParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MultiChainMintingContractParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MultiChainMintingContractParent document */
  update?: InputMaybe<MultiChainMintingContractParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MultiChainMintingContractParent document */
  upsert?: InputMaybe<MultiChainMintingContractParentUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainMintingContractParentUpdateWithNestedWhereUniqueInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type MultiChainMintingContractParentUpsertWithNestedWhereUniqueInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainMintingContractParentWhereInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type MultiChainMintingContractParentWhereUniqueInput = {
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type MultiChainMintingContractUpdateInput = {
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  contractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  contractAddress?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MultiChainMintingContractUpdateManyInlineInput = {
  /** Create and connect multiple MultiChainMintingContract component instances */
  create?: InputMaybe<Array<MultiChainMintingContractCreateWithPositionInput>>;
  /** Delete multiple MultiChainMintingContract documents */
  delete?: InputMaybe<Array<MultiChainMintingContractWhereUniqueInput>>;
  /** Update multiple MultiChainMintingContract component instances */
  update?: InputMaybe<
    Array<MultiChainMintingContractUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple MultiChainMintingContract component instances */
  upsert?: InputMaybe<
    Array<MultiChainMintingContractUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type MultiChainMintingContractUpdateManyInput = {
  contractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  contractAddress?: InputMaybe<Scalars["String"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MultiChainMintingContractUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MultiChainMintingContractUpdateManyInput;
  /** Document search */
  where: MultiChainMintingContractWhereInput;
};

export type MultiChainMintingContractUpdateOneInlineInput = {
  /** Create and connect one MultiChainMintingContract document */
  create?: InputMaybe<MultiChainMintingContractCreateInput>;
  /** Delete currently connected MultiChainMintingContract document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MultiChainMintingContract document */
  update?: InputMaybe<MultiChainMintingContractUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MultiChainMintingContract document */
  upsert?: InputMaybe<MultiChainMintingContractUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainMintingContractUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<MultiChainMintingContractUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MultiChainMintingContractWhereUniqueInput;
  };

export type MultiChainMintingContractUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MultiChainMintingContractUpdateInput;
  /** Unique document search */
  where: MultiChainMintingContractWhereUniqueInput;
};

export type MultiChainMintingContractUpsertInput = {
  /** Create document if it didn't exist */
  create: MultiChainMintingContractCreateInput;
  /** Update document if it exists */
  update: MultiChainMintingContractUpdateInput;
};

export type MultiChainMintingContractUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<MultiChainMintingContractUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: MultiChainMintingContractWhereUniqueInput;
  };

export type MultiChainMintingContractUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MultiChainMintingContractUpsertInput;
  /** Unique document search */
  where: MultiChainMintingContractWhereUniqueInput;
};

/** Identifies documents */
export type MultiChainMintingContractWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingContractWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingContractWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingContractWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainWhereInput>;
  /** All values containing the given json path. */
  contractAbi_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  contractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  contractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  contractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  contractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  contractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  contractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  contractAddress_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  contractAddress_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  contractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  contractAddress_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  contractAddress_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  soldOut?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  soldOut_not?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** References MultiChainMintingContract record uniquely */
export type MultiChainMintingContractWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MultiChainMintingProject = Entity &
  Node & {
    __typename?: "MultiChainMintingProject";
    aboutSectionAccordions: Array<Faq>;
    aboutSectionHeader?: Maybe<Scalars["String"]["output"]>;
    aboutSectionText?: Maybe<Scalars["String"]["output"]>;
    alphaSharesProject: Scalars["Boolean"]["output"];
    collectionDescription?: Maybe<Scalars["String"]["output"]>;
    collectionInfoTable: Array<CollectionInfoTable>;
    collectionSampleImages: Array<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    creditCardLinksByChain: Array<MultiChainCreditCardLink>;
    ctaSection?: Maybe<CtaSection>;
    /** Get the document in other stages */
    documentInStages: Array<MultiChainMintingProject>;
    fAQSections: Array<FaqSection>;
    featured: Scalars["Boolean"]["output"];
    globalMintContractAbi?: Maybe<Scalars["Json"]["output"]>;
    globalMintContractMintFunction?: Maybe<Scalars["String"]["output"]>;
    /** List of MultiChainMintingProject versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    maxMintPerTransaction?: Maybe<Scalars["Int"]["output"]>;
    mintDate?: Maybe<Scalars["DateTime"]["output"]>;
    mintInfoTable: Array<CollectionInfoTable>;
    mintPrice?: Maybe<Scalars["Float"]["output"]>;
    mintedNfTsCarouselHeader?: Maybe<Scalars["String"]["output"]>;
    mintedNfTsCarouselSubHeader?: Maybe<Scalars["String"]["output"]>;
    mintingContracts: Array<MultiChainMintingContract>;
    mintingCurrencies: Array<MintingCurrency>;
    mintingStat?: Maybe<MintingStat>;
    nftCollections: Array<NftCollection>;
    projectName: Scalars["String"]["output"];
    projectSecurityTags: Array<ProjectSecurityTags>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    securityLineItems: Array<SecurityInfo>;
    seoDescription?: Maybe<Scalars["String"]["output"]>;
    seoImage?: Maybe<Asset>;
    seoTitle?: Maybe<Scalars["String"]["output"]>;
    /** url for minting project */
    slug?: Maybe<Scalars["String"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type MultiChainMintingProjectAboutSectionAccordionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqWhereInput>;
};

export type MultiChainMintingProjectCollectionInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MultiChainMintingProjectCollectionSampleImagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type MultiChainMintingProjectCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectCreditCardLinksByChainArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MultiChainCreditCardLinkOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainCreditCardLinkWhereInput>;
};

export type MultiChainMintingProjectCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type MultiChainMintingProjectFAqSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type MultiChainMintingProjectHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type MultiChainMintingProjectMintInfoTableArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<CollectionInfoTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionInfoTableWhereInput>;
};

export type MultiChainMintingProjectMintingContractsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MultiChainMintingContractOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingContractWhereInput>;
};

export type MultiChainMintingProjectMintingCurrenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

export type MultiChainMintingProjectMintingStatArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type MultiChainMintingProjectPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type MultiChainMintingProjectSecurityLineItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<SecurityInfoOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<SecurityInfoWhereInput>;
};

export type MultiChainMintingProjectSeoImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type MultiChainMintingProjectConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: MultiChainMintingProjectWhereUniqueInput;
};

/** A connection to a list of items. */
export type MultiChainMintingProjectConnection = {
  __typename?: "MultiChainMintingProjectConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<MultiChainMintingProjectEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type MultiChainMintingProjectCreateInput = {
  aboutSectionAccordions?: InputMaybe<FaqCreateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject: Scalars["Boolean"]["input"];
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  creditCardLinksByChain?: InputMaybe<MultiChainCreditCardLinkCreateManyInlineInput>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionCreateManyInlineInput>;
  featured: Scalars["Boolean"]["input"];
  globalMintContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableCreateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContracts?: InputMaybe<MultiChainMintingContractCreateManyInlineInput>;
  mintingCurrencies?: InputMaybe<MintingCurrencyCreateManyInlineInput>;
  mintingStat?: InputMaybe<MintingStatCreateOneInlineInput>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  projectName: Scalars["String"]["input"];
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  securityLineItems?: InputMaybe<SecurityInfoCreateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetCreateOneInlineInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type MultiChainMintingProjectCreateManyInlineInput = {
  /** Connect multiple existing MultiChainMintingProject documents */
  connect?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Create and connect multiple existing MultiChainMintingProject documents */
  create?: InputMaybe<Array<MultiChainMintingProjectCreateInput>>;
};

export type MultiChainMintingProjectCreateOneInlineInput = {
  /** Connect one existing MultiChainMintingProject document */
  connect?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
  /** Create and connect one MultiChainMintingProject document */
  create?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

/** An edge in a connection. */
export type MultiChainMintingProjectEdge = {
  __typename?: "MultiChainMintingProjectEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: MultiChainMintingProject;
};

/** Identifies documents */
export type MultiChainMintingProjectManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  creditCardLinksByChain_every?: InputMaybe<MultiChainCreditCardLinkWhereInput>;
  creditCardLinksByChain_none?: InputMaybe<MultiChainCreditCardLinkWhereInput>;
  creditCardLinksByChain_some?: InputMaybe<MultiChainCreditCardLinkWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** All values containing the given json path. */
  globalMintContractAbi_json_path_exists?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  globalMintContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  globalMintContractMintFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  globalMintContractMintFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  globalMintContractMintFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  globalMintContractMintFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  globalMintContractMintFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  globalMintContractMintFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  globalMintContractMintFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  globalMintContractMintFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  globalMintContractMintFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingContracts_every?: InputMaybe<MultiChainMintingContractWhereInput>;
  mintingContracts_none?: InputMaybe<MultiChainMintingContractWhereInput>;
  mintingContracts_some?: InputMaybe<MultiChainMintingContractWhereInput>;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum MultiChainMintingProjectOrderByInput {
  aboutSectionHeader_ASC = "aboutSectionHeader_ASC",
  aboutSectionHeader_DESC = "aboutSectionHeader_DESC",
  aboutSectionText_ASC = "aboutSectionText_ASC",
  aboutSectionText_DESC = "aboutSectionText_DESC",
  alphaSharesProject_ASC = "alphaSharesProject_ASC",
  alphaSharesProject_DESC = "alphaSharesProject_DESC",
  collectionDescription_ASC = "collectionDescription_ASC",
  collectionDescription_DESC = "collectionDescription_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  featured_ASC = "featured_ASC",
  featured_DESC = "featured_DESC",
  globalMintContractMintFunction_ASC = "globalMintContractMintFunction_ASC",
  globalMintContractMintFunction_DESC = "globalMintContractMintFunction_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  maxMintPerTransaction_ASC = "maxMintPerTransaction_ASC",
  maxMintPerTransaction_DESC = "maxMintPerTransaction_DESC",
  mintDate_ASC = "mintDate_ASC",
  mintDate_DESC = "mintDate_DESC",
  mintPrice_ASC = "mintPrice_ASC",
  mintPrice_DESC = "mintPrice_DESC",
  mintedNfTsCarouselHeader_ASC = "mintedNfTsCarouselHeader_ASC",
  mintedNfTsCarouselHeader_DESC = "mintedNfTsCarouselHeader_DESC",
  mintedNfTsCarouselSubHeader_ASC = "mintedNfTsCarouselSubHeader_ASC",
  mintedNfTsCarouselSubHeader_DESC = "mintedNfTsCarouselSubHeader_DESC",
  projectName_ASC = "projectName_ASC",
  projectName_DESC = "projectName_DESC",
  projectSecurityTags_ASC = "projectSecurityTags_ASC",
  projectSecurityTags_DESC = "projectSecurityTags_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  seoDescription_ASC = "seoDescription_ASC",
  seoDescription_DESC = "seoDescription_DESC",
  seoTitle_ASC = "seoTitle_ASC",
  seoTitle_DESC = "seoTitle_DESC",
  slug_ASC = "slug_ASC",
  slug_DESC = "slug_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type MultiChainMintingProjectUpdateInput = {
  aboutSectionAccordions?: InputMaybe<FaqUpdateManyInlineInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  collectionSampleImages?: InputMaybe<AssetUpdateManyInlineInput>;
  creditCardLinksByChain?: InputMaybe<MultiChainCreditCardLinkUpdateManyInlineInput>;
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionUpdateManyInlineInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  globalMintContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintInfoTable?: InputMaybe<CollectionInfoTableUpdateManyInlineInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintingContracts?: InputMaybe<MultiChainMintingContractUpdateManyInlineInput>;
  mintingCurrencies?: InputMaybe<MintingCurrencyUpdateManyInlineInput>;
  mintingStat?: InputMaybe<MintingStatUpdateOneInlineInput>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  securityLineItems?: InputMaybe<SecurityInfoUpdateManyInlineInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetUpdateOneInlineInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiChainMintingProjectUpdateManyInlineInput = {
  /** Connect multiple existing MultiChainMintingProject documents */
  connect?: InputMaybe<Array<MultiChainMintingProjectConnectInput>>;
  /** Create and connect multiple MultiChainMintingProject documents */
  create?: InputMaybe<Array<MultiChainMintingProjectCreateInput>>;
  /** Delete multiple MultiChainMintingProject documents */
  delete?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Disconnect multiple MultiChainMintingProject documents */
  disconnect?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing MultiChainMintingProject documents */
  set?: InputMaybe<Array<MultiChainMintingProjectWhereUniqueInput>>;
  /** Update multiple MultiChainMintingProject documents */
  update?: InputMaybe<
    Array<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple MultiChainMintingProject documents */
  upsert?: InputMaybe<
    Array<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>
  >;
};

export type MultiChainMintingProjectUpdateManyInput = {
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  globalMintContractAbi?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type MultiChainMintingProjectUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: MultiChainMintingProjectUpdateManyInput;
  /** Document search */
  where: MultiChainMintingProjectWhereInput;
};

export type MultiChainMintingProjectUpdateOneInlineInput = {
  /** Connect existing MultiChainMintingProject document */
  connect?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
  /** Create and connect one MultiChainMintingProject document */
  create?: InputMaybe<MultiChainMintingProjectCreateInput>;
  /** Delete currently connected MultiChainMintingProject document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected MultiChainMintingProject document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single MultiChainMintingProject document */
  update?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
  /** Upsert single MultiChainMintingProject document */
  upsert?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type MultiChainMintingProjectUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: MultiChainMintingProjectUpdateInput;
  /** Unique document search */
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MultiChainMintingProjectUpsertInput = {
  /** Create document if it didn't exist */
  create: MultiChainMintingProjectCreateInput;
  /** Update document if it exists */
  update: MultiChainMintingProjectUpdateInput;
};

export type MultiChainMintingProjectUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: MultiChainMintingProjectUpsertInput;
  /** Unique document search */
  where: MultiChainMintingProjectWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type MultiChainMintingProjectWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type MultiChainMintingProjectWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingProjectWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionAccordions_every?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_none?: InputMaybe<FaqWhereInput>;
  aboutSectionAccordions_some?: InputMaybe<FaqWhereInput>;
  aboutSectionHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionHeader_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionHeader_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionHeader_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  aboutSectionText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  aboutSectionText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  aboutSectionText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  aboutSectionText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  aboutSectionText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  aboutSectionText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  aboutSectionText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  aboutSectionText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  aboutSectionText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  aboutSectionText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  alphaSharesProject?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  alphaSharesProject_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  collectionSampleImages_every?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_none?: InputMaybe<AssetWhereInput>;
  collectionSampleImages_some?: InputMaybe<AssetWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  creditCardLinksByChain_every?: InputMaybe<MultiChainCreditCardLinkWhereInput>;
  creditCardLinksByChain_none?: InputMaybe<MultiChainCreditCardLinkWhereInput>;
  creditCardLinksByChain_some?: InputMaybe<MultiChainCreditCardLinkWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_none?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  documentInStages_some?: InputMaybe<MultiChainMintingProjectWhereStageInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  featured?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  featured_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** All values containing the given json path. */
  globalMintContractAbi_json_path_exists?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  globalMintContractAbi_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  globalMintContractMintFunction?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  globalMintContractMintFunction_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  globalMintContractMintFunction_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  globalMintContractMintFunction_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  globalMintContractMintFunction_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  globalMintContractMintFunction_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  globalMintContractMintFunction_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  globalMintContractMintFunction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  globalMintContractMintFunction_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  globalMintContractMintFunction_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  maxMintPerTransaction?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  maxMintPerTransaction_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  maxMintPerTransaction_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  maxMintPerTransaction_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  maxMintPerTransaction_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  maxMintPerTransaction_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  maxMintPerTransaction_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  maxMintPerTransaction_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  mintDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  mintDate_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  mintDate_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  mintDate_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  mintDate_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  mintDate_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintDate_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  mintDate_not_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  mintInfoTable_every?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_none?: InputMaybe<CollectionInfoTableWhereInput>;
  mintInfoTable_some?: InputMaybe<CollectionInfoTableWhereInput>;
  mintPrice?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  mintPrice_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  mintPrice_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  mintPrice_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  /** All values less than the given value. */
  mintPrice_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  mintPrice_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  mintPrice_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  mintPrice_not_in?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mintedNfTsCarouselHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselHeader_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mintedNfTsCarouselHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselHeader_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  mintedNfTsCarouselSubHeader?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mintedNfTsCarouselSubHeader_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mintedNfTsCarouselSubHeader_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  mintedNfTsCarouselSubHeader_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  mintedNfTsCarouselSubHeader_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mintedNfTsCarouselSubHeader_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  mintedNfTsCarouselSubHeader_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  mintedNfTsCarouselSubHeader_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mintedNfTsCarouselSubHeader_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  mintedNfTsCarouselSubHeader_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  mintingContracts_every?: InputMaybe<MultiChainMintingContractWhereInput>;
  mintingContracts_none?: InputMaybe<MultiChainMintingContractWhereInput>;
  mintingContracts_some?: InputMaybe<MultiChainMintingContractWhereInput>;
  mintingCurrencies_every?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_none?: InputMaybe<MintingCurrencyWhereInput>;
  mintingCurrencies_some?: InputMaybe<MintingCurrencyWhereInput>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  projectName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  projectName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  projectName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  projectName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  projectName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  projectName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  projectName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  projectName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  projectName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  projectSecurityTags?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains *all* items provided to the filter */
  projectSecurityTags_contains_all?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  projectSecurityTags_contains_none?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array contains at least one item provided to the filter */
  projectSecurityTags_contains_some?: InputMaybe<Array<ProjectSecurityTags>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  projectSecurityTags_not?: InputMaybe<Array<ProjectSecurityTags>>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  securityLineItems_every?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_none?: InputMaybe<SecurityInfoWhereInput>;
  securityLineItems_some?: InputMaybe<SecurityInfoWhereInput>;
  seoDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoDescription_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  seoDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  seoImage?: InputMaybe<AssetWhereInput>;
  seoTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  seoTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  seoTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  seoTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  seoTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  seoTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  seoTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  seoTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  seoTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  seoTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  slug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  slug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  slug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  slug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  slug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  slug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  slug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  slug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type MultiChainMintingProjectWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<MultiChainMintingProjectWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<MultiChainMintingProjectWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<MultiChainMintingProjectWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<MultiChainMintingProjectWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References MultiChainMintingProject record uniquely */
export type MultiChainMintingProjectWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  projectName?: InputMaybe<Scalars["String"]["input"]>;
  slug?: InputMaybe<Scalars["String"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  /** Create one alphaNftCollectionTier */
  createAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Create one alphaSharesInformation */
  createAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<Asset>;
  /** Create one chain */
  createChain?: Maybe<Chain>;
  /** Create one collectionCreator */
  createCollectionCreator?: Maybe<CollectionCreator>;
  /** Create one ctaSection */
  createCtaSection?: Maybe<CtaSection>;
  /** Create one fAQSection */
  createFAQSection?: Maybe<FaqSection>;
  /** Create one featuredCollectionGroup */
  createFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Create one featuredCreatorGroup */
  createFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Create one gettingStartedSection */
  createGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Create one infoCardsSection */
  createInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Create one landingPage */
  createLandingPage?: Maybe<LandingPage>;
  /** Create one marketplaceAnnouncementBanner */
  createMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Create one marketplaceContract */
  createMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Create one marketplacePage */
  createMarketplacePage?: Maybe<MarketplacePage>;
  /** Create one menu */
  createMenu?: Maybe<Menu>;
  /** Create one miningBtcSupplyDemandModelSection */
  createMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Create one miningContainer */
  createMiningContainer?: Maybe<MiningContainer>;
  /** Create one miningHeaderNotice */
  createMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Create one miningNFTLPPageHeroSection */
  createMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Create one miningNftAccessSection */
  createMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Create one miningNftAdvantageSection */
  createMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Create one miningNftAntimonyAdvantageSection */
  createMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Create one miningNftComparisonTable */
  createMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Create one miningNftEligibilitySection */
  createMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Create one miningNftProfitRoiSection */
  createMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Create one miningNftTeamSection */
  createMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Create one miningNftWhitelistManualEntry */
  createMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Create one miningNftfaqSection */
  createMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Create one mintingCurrency */
  createMintingCurrency?: Maybe<MintingCurrency>;
  /** Create one mintingProject */
  createMintingProject?: Maybe<MintingProject>;
  /** Create one mintingStat */
  createMintingStat?: Maybe<MintingStat>;
  /** Create one multiChainMintingProject */
  createMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Create one nftCategoryDisplay */
  createNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Create one nftCollection */
  createNftCollection?: Maybe<NftCollection>;
  /** Create one page */
  createPage?: Maybe<Page>;
  /** Create one pageHero */
  createPageHero?: Maybe<PageHero>;
  /** Create one partnerSection */
  createPartnerSection?: Maybe<PartnerSection>;
  /** Create one perksSection */
  createPerksSection?: Maybe<PerksSection>;
  /** Create one poweredBySection */
  createPoweredBySection?: Maybe<PoweredBySection>;
  /** Create one pupsPresaleCommunity */
  createPupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Create one roadmap */
  createRoadmap?: Maybe<Roadmap>;
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<ScheduledRelease>;
  /** Create one teamSection */
  createTeamSection?: Maybe<TeamSection>;
  /** Delete one alphaNftCollectionTier from _all_ existing stages. Returns deleted document. */
  deleteAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Delete one alphaSharesInformation from _all_ existing stages. Returns deleted document. */
  deleteAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<Asset>;
  /** Delete one chain from _all_ existing stages. Returns deleted document. */
  deleteChain?: Maybe<Chain>;
  /** Delete one collectionCreator from _all_ existing stages. Returns deleted document. */
  deleteCollectionCreator?: Maybe<CollectionCreator>;
  /** Delete one ctaSection from _all_ existing stages. Returns deleted document. */
  deleteCtaSection?: Maybe<CtaSection>;
  /** Delete one fAQSection from _all_ existing stages. Returns deleted document. */
  deleteFAQSection?: Maybe<FaqSection>;
  /** Delete one featuredCollectionGroup from _all_ existing stages. Returns deleted document. */
  deleteFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Delete one featuredCreatorGroup from _all_ existing stages. Returns deleted document. */
  deleteFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Delete one gettingStartedSection from _all_ existing stages. Returns deleted document. */
  deleteGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Delete one infoCardsSection from _all_ existing stages. Returns deleted document. */
  deleteInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Delete one landingPage from _all_ existing stages. Returns deleted document. */
  deleteLandingPage?: Maybe<LandingPage>;
  /**
   * Delete many AlphaNftCollectionTier documents
   * @deprecated Please use the new paginated many mutation (deleteManyAlphaNftCollectionTiersConnection)
   */
  deleteManyAlphaNftCollectionTiers: BatchPayload;
  /** Delete many AlphaNftCollectionTier documents, return deleted documents */
  deleteManyAlphaNftCollectionTiersConnection: AlphaNftCollectionTierConnection;
  /**
   * Delete many AlphaSharesInformation documents
   * @deprecated Please use the new paginated many mutation (deleteManyAlphaSharesInformationsConnection)
   */
  deleteManyAlphaSharesInformations: BatchPayload;
  /** Delete many AlphaSharesInformation documents, return deleted documents */
  deleteManyAlphaSharesInformationsConnection: AlphaSharesInformationConnection;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: BatchPayload;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: AssetConnection;
  /**
   * Delete many Chain documents
   * @deprecated Please use the new paginated many mutation (deleteManyChainsConnection)
   */
  deleteManyChains: BatchPayload;
  /** Delete many Chain documents, return deleted documents */
  deleteManyChainsConnection: ChainConnection;
  /**
   * Delete many CollectionCreator documents
   * @deprecated Please use the new paginated many mutation (deleteManyCollectionCreatorsConnection)
   */
  deleteManyCollectionCreators: BatchPayload;
  /** Delete many CollectionCreator documents, return deleted documents */
  deleteManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Delete many CtaSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyCtaSectionsConnection)
   */
  deleteManyCtaSections: BatchPayload;
  /** Delete many CtaSection documents, return deleted documents */
  deleteManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Delete many FAQSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyFAQSectionsConnection)
   */
  deleteManyFAQSections: BatchPayload;
  /** Delete many FAQSection documents, return deleted documents */
  deleteManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Delete many FeaturedCollectionGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyFeaturedCollectionGroupsConnection)
   */
  deleteManyFeaturedCollectionGroups: BatchPayload;
  /** Delete many FeaturedCollectionGroup documents, return deleted documents */
  deleteManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Delete many FeaturedCreatorGroup documents
   * @deprecated Please use the new paginated many mutation (deleteManyFeaturedCreatorGroupsConnection)
   */
  deleteManyFeaturedCreatorGroups: BatchPayload;
  /** Delete many FeaturedCreatorGroup documents, return deleted documents */
  deleteManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Delete many GettingStartedSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyGettingStartedSectionsConnection)
   */
  deleteManyGettingStartedSections: BatchPayload;
  /** Delete many GettingStartedSection documents, return deleted documents */
  deleteManyGettingStartedSectionsConnection: GettingStartedSectionConnection;
  /**
   * Delete many InfoCardsSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyInfoCardsSectionsConnection)
   */
  deleteManyInfoCardsSections: BatchPayload;
  /** Delete many InfoCardsSection documents, return deleted documents */
  deleteManyInfoCardsSectionsConnection: InfoCardsSectionConnection;
  /**
   * Delete many LandingPage documents
   * @deprecated Please use the new paginated many mutation (deleteManyLandingPagesConnection)
   */
  deleteManyLandingPages: BatchPayload;
  /** Delete many LandingPage documents, return deleted documents */
  deleteManyLandingPagesConnection: LandingPageConnection;
  /**
   * Delete many MarketplaceAnnouncementBanner documents
   * @deprecated Please use the new paginated many mutation (deleteManyMarketplaceAnnouncementBannersConnection)
   */
  deleteManyMarketplaceAnnouncementBanners: BatchPayload;
  /** Delete many MarketplaceAnnouncementBanner documents, return deleted documents */
  deleteManyMarketplaceAnnouncementBannersConnection: MarketplaceAnnouncementBannerConnection;
  /**
   * Delete many MarketplaceContract documents
   * @deprecated Please use the new paginated many mutation (deleteManyMarketplaceContractsConnection)
   */
  deleteManyMarketplaceContracts: BatchPayload;
  /** Delete many MarketplaceContract documents, return deleted documents */
  deleteManyMarketplaceContractsConnection: MarketplaceContractConnection;
  /**
   * Delete many MarketplacePage documents
   * @deprecated Please use the new paginated many mutation (deleteManyMarketplacePagesConnection)
   */
  deleteManyMarketplacePages: BatchPayload;
  /** Delete many MarketplacePage documents, return deleted documents */
  deleteManyMarketplacePagesConnection: MarketplacePageConnection;
  /**
   * Delete many Menu documents
   * @deprecated Please use the new paginated many mutation (deleteManyMenusConnection)
   */
  deleteManyMenus: BatchPayload;
  /** Delete many Menu documents, return deleted documents */
  deleteManyMenusConnection: MenuConnection;
  /**
   * Delete many MiningBtcSupplyDemandModelSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningBtcSupplyDemandModelSectionsConnection)
   */
  deleteManyMiningBtcSupplyDemandModelSections: BatchPayload;
  /** Delete many MiningBtcSupplyDemandModelSection documents, return deleted documents */
  deleteManyMiningBtcSupplyDemandModelSectionsConnection: MiningBtcSupplyDemandModelSectionConnection;
  /**
   * Delete many MiningContainer documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningContainersConnection)
   */
  deleteManyMiningContainers: BatchPayload;
  /** Delete many MiningContainer documents, return deleted documents */
  deleteManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Delete many MiningHeaderNotice documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningHeaderNoticesConnection)
   */
  deleteManyMiningHeaderNotices: BatchPayload;
  /** Delete many MiningHeaderNotice documents, return deleted documents */
  deleteManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Delete many MiningNFTLPPageHeroSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNFTLPPageHeroSectionsConnection)
   */
  deleteManyMiningNFTLPPageHeroSections: BatchPayload;
  /** Delete many MiningNFTLPPageHeroSection documents, return deleted documents */
  deleteManyMiningNFTLPPageHeroSectionsConnection: MiningNftlpPageHeroSectionConnection;
  /**
   * Delete many MiningNftAccessSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftAccessSectionsConnection)
   */
  deleteManyMiningNftAccessSections: BatchPayload;
  /** Delete many MiningNftAccessSection documents, return deleted documents */
  deleteManyMiningNftAccessSectionsConnection: MiningNftAccessSectionConnection;
  /**
   * Delete many MiningNftAdvantageSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftAdvantageSectionsConnection)
   */
  deleteManyMiningNftAdvantageSections: BatchPayload;
  /** Delete many MiningNftAdvantageSection documents, return deleted documents */
  deleteManyMiningNftAdvantageSectionsConnection: MiningNftAdvantageSectionConnection;
  /**
   * Delete many MiningNftAntimonyAdvantageSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftAntimonyAdvantageSectionsConnection)
   */
  deleteManyMiningNftAntimonyAdvantageSections: BatchPayload;
  /** Delete many MiningNftAntimonyAdvantageSection documents, return deleted documents */
  deleteManyMiningNftAntimonyAdvantageSectionsConnection: MiningNftAntimonyAdvantageSectionConnection;
  /**
   * Delete many MiningNftComparisonTable documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftComparisonTablesConnection)
   */
  deleteManyMiningNftComparisonTables: BatchPayload;
  /** Delete many MiningNftComparisonTable documents, return deleted documents */
  deleteManyMiningNftComparisonTablesConnection: MiningNftComparisonTableConnection;
  /**
   * Delete many MiningNftEligibilitySection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftEligibilitySectionsConnection)
   */
  deleteManyMiningNftEligibilitySections: BatchPayload;
  /** Delete many MiningNftEligibilitySection documents, return deleted documents */
  deleteManyMiningNftEligibilitySectionsConnection: MiningNftEligibilitySectionConnection;
  /**
   * Delete many MiningNftProfitRoiSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftProfitRoiSectionsConnection)
   */
  deleteManyMiningNftProfitRoiSections: BatchPayload;
  /** Delete many MiningNftProfitRoiSection documents, return deleted documents */
  deleteManyMiningNftProfitRoiSectionsConnection: MiningNftProfitRoiSectionConnection;
  /**
   * Delete many MiningNftTeamSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftTeamSectionsConnection)
   */
  deleteManyMiningNftTeamSections: BatchPayload;
  /** Delete many MiningNftTeamSection documents, return deleted documents */
  deleteManyMiningNftTeamSectionsConnection: MiningNftTeamSectionConnection;
  /**
   * Delete many MiningNftWhitelistManualEntry documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftWhitelistManualEntriesConnection)
   */
  deleteManyMiningNftWhitelistManualEntries: BatchPayload;
  /** Delete many MiningNftWhitelistManualEntry documents, return deleted documents */
  deleteManyMiningNftWhitelistManualEntriesConnection: MiningNftWhitelistManualEntryConnection;
  /**
   * Delete many MiningNftfaqSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyMiningNftfaqSectionsConnection)
   */
  deleteManyMiningNftfaqSections: BatchPayload;
  /** Delete many MiningNftfaqSection documents, return deleted documents */
  deleteManyMiningNftfaqSectionsConnection: MiningNftfaqSectionConnection;
  /**
   * Delete many MintingCurrency documents
   * @deprecated Please use the new paginated many mutation (deleteManyMintingCurrenciesConnection)
   */
  deleteManyMintingCurrencies: BatchPayload;
  /** Delete many MintingCurrency documents, return deleted documents */
  deleteManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Delete many MintingProject documents
   * @deprecated Please use the new paginated many mutation (deleteManyMintingProjectsConnection)
   */
  deleteManyMintingProjects: BatchPayload;
  /** Delete many MintingProject documents, return deleted documents */
  deleteManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Delete many MintingStat documents
   * @deprecated Please use the new paginated many mutation (deleteManyMintingStatsConnection)
   */
  deleteManyMintingStats: BatchPayload;
  /** Delete many MintingStat documents, return deleted documents */
  deleteManyMintingStatsConnection: MintingStatConnection;
  /**
   * Delete many MultiChainMintingProject documents
   * @deprecated Please use the new paginated many mutation (deleteManyMultiChainMintingProjectsConnection)
   */
  deleteManyMultiChainMintingProjects: BatchPayload;
  /** Delete many MultiChainMintingProject documents, return deleted documents */
  deleteManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Delete many NftCategoryDisplay documents
   * @deprecated Please use the new paginated many mutation (deleteManyNftCategoryDisplaysConnection)
   */
  deleteManyNftCategoryDisplays: BatchPayload;
  /** Delete many NftCategoryDisplay documents, return deleted documents */
  deleteManyNftCategoryDisplaysConnection: NftCategoryDisplayConnection;
  /**
   * Delete many NftCollection documents
   * @deprecated Please use the new paginated many mutation (deleteManyNftCollectionsConnection)
   */
  deleteManyNftCollections: BatchPayload;
  /** Delete many NftCollection documents, return deleted documents */
  deleteManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Delete many PageHero documents
   * @deprecated Please use the new paginated many mutation (deleteManyPageHeroesConnection)
   */
  deleteManyPageHeroes: BatchPayload;
  /** Delete many PageHero documents, return deleted documents */
  deleteManyPageHeroesConnection: PageHeroConnection;
  /**
   * Delete many Page documents
   * @deprecated Please use the new paginated many mutation (deleteManyPagesConnection)
   */
  deleteManyPages: BatchPayload;
  /** Delete many Page documents, return deleted documents */
  deleteManyPagesConnection: PageConnection;
  /**
   * Delete many PartnerSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyPartnerSectionsConnection)
   */
  deleteManyPartnerSections: BatchPayload;
  /** Delete many PartnerSection documents, return deleted documents */
  deleteManyPartnerSectionsConnection: PartnerSectionConnection;
  /**
   * Delete many PerksSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyPerksSectionsConnection)
   */
  deleteManyPerksSections: BatchPayload;
  /** Delete many PerksSection documents, return deleted documents */
  deleteManyPerksSectionsConnection: PerksSectionConnection;
  /**
   * Delete many PoweredBySection documents
   * @deprecated Please use the new paginated many mutation (deleteManyPoweredBySectionsConnection)
   */
  deleteManyPoweredBySections: BatchPayload;
  /** Delete many PoweredBySection documents, return deleted documents */
  deleteManyPoweredBySectionsConnection: PoweredBySectionConnection;
  /**
   * Delete many PupsPresaleCommunity documents
   * @deprecated Please use the new paginated many mutation (deleteManyPupsPresaleCommunitiesConnection)
   */
  deleteManyPupsPresaleCommunities: BatchPayload;
  /** Delete many PupsPresaleCommunity documents, return deleted documents */
  deleteManyPupsPresaleCommunitiesConnection: PupsPresaleCommunityConnection;
  /**
   * Delete many Roadmap documents
   * @deprecated Please use the new paginated many mutation (deleteManyRoadmapsConnection)
   */
  deleteManyRoadmaps: BatchPayload;
  /** Delete many Roadmap documents, return deleted documents */
  deleteManyRoadmapsConnection: RoadmapConnection;
  /**
   * Delete many TeamSection documents
   * @deprecated Please use the new paginated many mutation (deleteManyTeamSectionsConnection)
   */
  deleteManyTeamSections: BatchPayload;
  /** Delete many TeamSection documents, return deleted documents */
  deleteManyTeamSectionsConnection: TeamSectionConnection;
  /** Delete one marketplaceAnnouncementBanner from _all_ existing stages. Returns deleted document. */
  deleteMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Delete one marketplaceContract from _all_ existing stages. Returns deleted document. */
  deleteMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Delete one marketplacePage from _all_ existing stages. Returns deleted document. */
  deleteMarketplacePage?: Maybe<MarketplacePage>;
  /** Delete one menu from _all_ existing stages. Returns deleted document. */
  deleteMenu?: Maybe<Menu>;
  /** Delete one miningBtcSupplyDemandModelSection from _all_ existing stages. Returns deleted document. */
  deleteMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Delete one miningContainer from _all_ existing stages. Returns deleted document. */
  deleteMiningContainer?: Maybe<MiningContainer>;
  /** Delete one miningHeaderNotice from _all_ existing stages. Returns deleted document. */
  deleteMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Delete one miningNFTLPPageHeroSection from _all_ existing stages. Returns deleted document. */
  deleteMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Delete one miningNftAccessSection from _all_ existing stages. Returns deleted document. */
  deleteMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Delete one miningNftAdvantageSection from _all_ existing stages. Returns deleted document. */
  deleteMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Delete one miningNftAntimonyAdvantageSection from _all_ existing stages. Returns deleted document. */
  deleteMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Delete one miningNftComparisonTable from _all_ existing stages. Returns deleted document. */
  deleteMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Delete one miningNftEligibilitySection from _all_ existing stages. Returns deleted document. */
  deleteMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Delete one miningNftProfitRoiSection from _all_ existing stages. Returns deleted document. */
  deleteMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Delete one miningNftTeamSection from _all_ existing stages. Returns deleted document. */
  deleteMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Delete one miningNftWhitelistManualEntry from _all_ existing stages. Returns deleted document. */
  deleteMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Delete one miningNftfaqSection from _all_ existing stages. Returns deleted document. */
  deleteMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Delete one mintingCurrency from _all_ existing stages. Returns deleted document. */
  deleteMintingCurrency?: Maybe<MintingCurrency>;
  /** Delete one mintingProject from _all_ existing stages. Returns deleted document. */
  deleteMintingProject?: Maybe<MintingProject>;
  /** Delete one mintingStat from _all_ existing stages. Returns deleted document. */
  deleteMintingStat?: Maybe<MintingStat>;
  /** Delete one multiChainMintingProject from _all_ existing stages. Returns deleted document. */
  deleteMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Delete one nftCategoryDisplay from _all_ existing stages. Returns deleted document. */
  deleteNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Delete one nftCollection from _all_ existing stages. Returns deleted document. */
  deleteNftCollection?: Maybe<NftCollection>;
  /** Delete one page from _all_ existing stages. Returns deleted document. */
  deletePage?: Maybe<Page>;
  /** Delete one pageHero from _all_ existing stages. Returns deleted document. */
  deletePageHero?: Maybe<PageHero>;
  /** Delete one partnerSection from _all_ existing stages. Returns deleted document. */
  deletePartnerSection?: Maybe<PartnerSection>;
  /** Delete one perksSection from _all_ existing stages. Returns deleted document. */
  deletePerksSection?: Maybe<PerksSection>;
  /** Delete one poweredBySection from _all_ existing stages. Returns deleted document. */
  deletePoweredBySection?: Maybe<PoweredBySection>;
  /** Delete one pupsPresaleCommunity from _all_ existing stages. Returns deleted document. */
  deletePupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Delete one roadmap from _all_ existing stages. Returns deleted document. */
  deleteRoadmap?: Maybe<Roadmap>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<ScheduledOperation>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<ScheduledRelease>;
  /** Delete one teamSection from _all_ existing stages. Returns deleted document. */
  deleteTeamSection?: Maybe<TeamSection>;
  /** Publish one alphaNftCollectionTier */
  publishAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Publish one alphaSharesInformation */
  publishAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Publish one asset */
  publishAsset?: Maybe<Asset>;
  /** Publish one chain */
  publishChain?: Maybe<Chain>;
  /** Publish one collectionCreator */
  publishCollectionCreator?: Maybe<CollectionCreator>;
  /** Publish one ctaSection */
  publishCtaSection?: Maybe<CtaSection>;
  /** Publish one fAQSection */
  publishFAQSection?: Maybe<FaqSection>;
  /** Publish one featuredCollectionGroup */
  publishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Publish one featuredCreatorGroup */
  publishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Publish one gettingStartedSection */
  publishGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Publish one infoCardsSection */
  publishInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Publish one landingPage */
  publishLandingPage?: Maybe<LandingPage>;
  /**
   * Publish many AlphaNftCollectionTier documents
   * @deprecated Please use the new paginated many mutation (publishManyAlphaNftCollectionTiersConnection)
   */
  publishManyAlphaNftCollectionTiers: BatchPayload;
  /** Publish many AlphaNftCollectionTier documents */
  publishManyAlphaNftCollectionTiersConnection: AlphaNftCollectionTierConnection;
  /**
   * Publish many AlphaSharesInformation documents
   * @deprecated Please use the new paginated many mutation (publishManyAlphaSharesInformationsConnection)
   */
  publishManyAlphaSharesInformations: BatchPayload;
  /** Publish many AlphaSharesInformation documents */
  publishManyAlphaSharesInformationsConnection: AlphaSharesInformationConnection;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: BatchPayload;
  /** Publish many Asset documents */
  publishManyAssetsConnection: AssetConnection;
  /**
   * Publish many Chain documents
   * @deprecated Please use the new paginated many mutation (publishManyChainsConnection)
   */
  publishManyChains: BatchPayload;
  /** Publish many Chain documents */
  publishManyChainsConnection: ChainConnection;
  /**
   * Publish many CollectionCreator documents
   * @deprecated Please use the new paginated many mutation (publishManyCollectionCreatorsConnection)
   */
  publishManyCollectionCreators: BatchPayload;
  /** Publish many CollectionCreator documents */
  publishManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Publish many CtaSection documents
   * @deprecated Please use the new paginated many mutation (publishManyCtaSectionsConnection)
   */
  publishManyCtaSections: BatchPayload;
  /** Publish many CtaSection documents */
  publishManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Publish many FAQSection documents
   * @deprecated Please use the new paginated many mutation (publishManyFAQSectionsConnection)
   */
  publishManyFAQSections: BatchPayload;
  /** Publish many FAQSection documents */
  publishManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Publish many FeaturedCollectionGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyFeaturedCollectionGroupsConnection)
   */
  publishManyFeaturedCollectionGroups: BatchPayload;
  /** Publish many FeaturedCollectionGroup documents */
  publishManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Publish many FeaturedCreatorGroup documents
   * @deprecated Please use the new paginated many mutation (publishManyFeaturedCreatorGroupsConnection)
   */
  publishManyFeaturedCreatorGroups: BatchPayload;
  /** Publish many FeaturedCreatorGroup documents */
  publishManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Publish many GettingStartedSection documents
   * @deprecated Please use the new paginated many mutation (publishManyGettingStartedSectionsConnection)
   */
  publishManyGettingStartedSections: BatchPayload;
  /** Publish many GettingStartedSection documents */
  publishManyGettingStartedSectionsConnection: GettingStartedSectionConnection;
  /**
   * Publish many InfoCardsSection documents
   * @deprecated Please use the new paginated many mutation (publishManyInfoCardsSectionsConnection)
   */
  publishManyInfoCardsSections: BatchPayload;
  /** Publish many InfoCardsSection documents */
  publishManyInfoCardsSectionsConnection: InfoCardsSectionConnection;
  /**
   * Publish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (publishManyLandingPagesConnection)
   */
  publishManyLandingPages: BatchPayload;
  /** Publish many LandingPage documents */
  publishManyLandingPagesConnection: LandingPageConnection;
  /**
   * Publish many MarketplaceAnnouncementBanner documents
   * @deprecated Please use the new paginated many mutation (publishManyMarketplaceAnnouncementBannersConnection)
   */
  publishManyMarketplaceAnnouncementBanners: BatchPayload;
  /** Publish many MarketplaceAnnouncementBanner documents */
  publishManyMarketplaceAnnouncementBannersConnection: MarketplaceAnnouncementBannerConnection;
  /**
   * Publish many MarketplaceContract documents
   * @deprecated Please use the new paginated many mutation (publishManyMarketplaceContractsConnection)
   */
  publishManyMarketplaceContracts: BatchPayload;
  /** Publish many MarketplaceContract documents */
  publishManyMarketplaceContractsConnection: MarketplaceContractConnection;
  /**
   * Publish many MarketplacePage documents
   * @deprecated Please use the new paginated many mutation (publishManyMarketplacePagesConnection)
   */
  publishManyMarketplacePages: BatchPayload;
  /** Publish many MarketplacePage documents */
  publishManyMarketplacePagesConnection: MarketplacePageConnection;
  /**
   * Publish many Menu documents
   * @deprecated Please use the new paginated many mutation (publishManyMenusConnection)
   */
  publishManyMenus: BatchPayload;
  /** Publish many Menu documents */
  publishManyMenusConnection: MenuConnection;
  /**
   * Publish many MiningBtcSupplyDemandModelSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningBtcSupplyDemandModelSectionsConnection)
   */
  publishManyMiningBtcSupplyDemandModelSections: BatchPayload;
  /** Publish many MiningBtcSupplyDemandModelSection documents */
  publishManyMiningBtcSupplyDemandModelSectionsConnection: MiningBtcSupplyDemandModelSectionConnection;
  /**
   * Publish many MiningContainer documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningContainersConnection)
   */
  publishManyMiningContainers: BatchPayload;
  /** Publish many MiningContainer documents */
  publishManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Publish many MiningHeaderNotice documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningHeaderNoticesConnection)
   */
  publishManyMiningHeaderNotices: BatchPayload;
  /** Publish many MiningHeaderNotice documents */
  publishManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Publish many MiningNFTLPPageHeroSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNFTLPPageHeroSectionsConnection)
   */
  publishManyMiningNFTLPPageHeroSections: BatchPayload;
  /** Publish many MiningNFTLPPageHeroSection documents */
  publishManyMiningNFTLPPageHeroSectionsConnection: MiningNftlpPageHeroSectionConnection;
  /**
   * Publish many MiningNftAccessSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftAccessSectionsConnection)
   */
  publishManyMiningNftAccessSections: BatchPayload;
  /** Publish many MiningNftAccessSection documents */
  publishManyMiningNftAccessSectionsConnection: MiningNftAccessSectionConnection;
  /**
   * Publish many MiningNftAdvantageSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftAdvantageSectionsConnection)
   */
  publishManyMiningNftAdvantageSections: BatchPayload;
  /** Publish many MiningNftAdvantageSection documents */
  publishManyMiningNftAdvantageSectionsConnection: MiningNftAdvantageSectionConnection;
  /**
   * Publish many MiningNftAntimonyAdvantageSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftAntimonyAdvantageSectionsConnection)
   */
  publishManyMiningNftAntimonyAdvantageSections: BatchPayload;
  /** Publish many MiningNftAntimonyAdvantageSection documents */
  publishManyMiningNftAntimonyAdvantageSectionsConnection: MiningNftAntimonyAdvantageSectionConnection;
  /**
   * Publish many MiningNftComparisonTable documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftComparisonTablesConnection)
   */
  publishManyMiningNftComparisonTables: BatchPayload;
  /** Publish many MiningNftComparisonTable documents */
  publishManyMiningNftComparisonTablesConnection: MiningNftComparisonTableConnection;
  /**
   * Publish many MiningNftEligibilitySection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftEligibilitySectionsConnection)
   */
  publishManyMiningNftEligibilitySections: BatchPayload;
  /** Publish many MiningNftEligibilitySection documents */
  publishManyMiningNftEligibilitySectionsConnection: MiningNftEligibilitySectionConnection;
  /**
   * Publish many MiningNftProfitRoiSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftProfitRoiSectionsConnection)
   */
  publishManyMiningNftProfitRoiSections: BatchPayload;
  /** Publish many MiningNftProfitRoiSection documents */
  publishManyMiningNftProfitRoiSectionsConnection: MiningNftProfitRoiSectionConnection;
  /**
   * Publish many MiningNftTeamSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftTeamSectionsConnection)
   */
  publishManyMiningNftTeamSections: BatchPayload;
  /** Publish many MiningNftTeamSection documents */
  publishManyMiningNftTeamSectionsConnection: MiningNftTeamSectionConnection;
  /**
   * Publish many MiningNftWhitelistManualEntry documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftWhitelistManualEntriesConnection)
   */
  publishManyMiningNftWhitelistManualEntries: BatchPayload;
  /** Publish many MiningNftWhitelistManualEntry documents */
  publishManyMiningNftWhitelistManualEntriesConnection: MiningNftWhitelistManualEntryConnection;
  /**
   * Publish many MiningNftfaqSection documents
   * @deprecated Please use the new paginated many mutation (publishManyMiningNftfaqSectionsConnection)
   */
  publishManyMiningNftfaqSections: BatchPayload;
  /** Publish many MiningNftfaqSection documents */
  publishManyMiningNftfaqSectionsConnection: MiningNftfaqSectionConnection;
  /**
   * Publish many MintingCurrency documents
   * @deprecated Please use the new paginated many mutation (publishManyMintingCurrenciesConnection)
   */
  publishManyMintingCurrencies: BatchPayload;
  /** Publish many MintingCurrency documents */
  publishManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Publish many MintingProject documents
   * @deprecated Please use the new paginated many mutation (publishManyMintingProjectsConnection)
   */
  publishManyMintingProjects: BatchPayload;
  /** Publish many MintingProject documents */
  publishManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Publish many MintingStat documents
   * @deprecated Please use the new paginated many mutation (publishManyMintingStatsConnection)
   */
  publishManyMintingStats: BatchPayload;
  /** Publish many MintingStat documents */
  publishManyMintingStatsConnection: MintingStatConnection;
  /**
   * Publish many MultiChainMintingProject documents
   * @deprecated Please use the new paginated many mutation (publishManyMultiChainMintingProjectsConnection)
   */
  publishManyMultiChainMintingProjects: BatchPayload;
  /** Publish many MultiChainMintingProject documents */
  publishManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Publish many NftCategoryDisplay documents
   * @deprecated Please use the new paginated many mutation (publishManyNftCategoryDisplaysConnection)
   */
  publishManyNftCategoryDisplays: BatchPayload;
  /** Publish many NftCategoryDisplay documents */
  publishManyNftCategoryDisplaysConnection: NftCategoryDisplayConnection;
  /**
   * Publish many NftCollection documents
   * @deprecated Please use the new paginated many mutation (publishManyNftCollectionsConnection)
   */
  publishManyNftCollections: BatchPayload;
  /** Publish many NftCollection documents */
  publishManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Publish many PageHero documents
   * @deprecated Please use the new paginated many mutation (publishManyPageHeroesConnection)
   */
  publishManyPageHeroes: BatchPayload;
  /** Publish many PageHero documents */
  publishManyPageHeroesConnection: PageHeroConnection;
  /**
   * Publish many Page documents
   * @deprecated Please use the new paginated many mutation (publishManyPagesConnection)
   */
  publishManyPages: BatchPayload;
  /** Publish many Page documents */
  publishManyPagesConnection: PageConnection;
  /**
   * Publish many PartnerSection documents
   * @deprecated Please use the new paginated many mutation (publishManyPartnerSectionsConnection)
   */
  publishManyPartnerSections: BatchPayload;
  /** Publish many PartnerSection documents */
  publishManyPartnerSectionsConnection: PartnerSectionConnection;
  /**
   * Publish many PerksSection documents
   * @deprecated Please use the new paginated many mutation (publishManyPerksSectionsConnection)
   */
  publishManyPerksSections: BatchPayload;
  /** Publish many PerksSection documents */
  publishManyPerksSectionsConnection: PerksSectionConnection;
  /**
   * Publish many PoweredBySection documents
   * @deprecated Please use the new paginated many mutation (publishManyPoweredBySectionsConnection)
   */
  publishManyPoweredBySections: BatchPayload;
  /** Publish many PoweredBySection documents */
  publishManyPoweredBySectionsConnection: PoweredBySectionConnection;
  /**
   * Publish many PupsPresaleCommunity documents
   * @deprecated Please use the new paginated many mutation (publishManyPupsPresaleCommunitiesConnection)
   */
  publishManyPupsPresaleCommunities: BatchPayload;
  /** Publish many PupsPresaleCommunity documents */
  publishManyPupsPresaleCommunitiesConnection: PupsPresaleCommunityConnection;
  /**
   * Publish many Roadmap documents
   * @deprecated Please use the new paginated many mutation (publishManyRoadmapsConnection)
   */
  publishManyRoadmaps: BatchPayload;
  /** Publish many Roadmap documents */
  publishManyRoadmapsConnection: RoadmapConnection;
  /**
   * Publish many TeamSection documents
   * @deprecated Please use the new paginated many mutation (publishManyTeamSectionsConnection)
   */
  publishManyTeamSections: BatchPayload;
  /** Publish many TeamSection documents */
  publishManyTeamSectionsConnection: TeamSectionConnection;
  /** Publish one marketplaceAnnouncementBanner */
  publishMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Publish one marketplaceContract */
  publishMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Publish one marketplacePage */
  publishMarketplacePage?: Maybe<MarketplacePage>;
  /** Publish one menu */
  publishMenu?: Maybe<Menu>;
  /** Publish one miningBtcSupplyDemandModelSection */
  publishMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Publish one miningContainer */
  publishMiningContainer?: Maybe<MiningContainer>;
  /** Publish one miningHeaderNotice */
  publishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Publish one miningNFTLPPageHeroSection */
  publishMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Publish one miningNftAccessSection */
  publishMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Publish one miningNftAdvantageSection */
  publishMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Publish one miningNftAntimonyAdvantageSection */
  publishMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Publish one miningNftComparisonTable */
  publishMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Publish one miningNftEligibilitySection */
  publishMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Publish one miningNftProfitRoiSection */
  publishMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Publish one miningNftTeamSection */
  publishMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Publish one miningNftWhitelistManualEntry */
  publishMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Publish one miningNftfaqSection */
  publishMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Publish one mintingCurrency */
  publishMintingCurrency?: Maybe<MintingCurrency>;
  /** Publish one mintingProject */
  publishMintingProject?: Maybe<MintingProject>;
  /** Publish one mintingStat */
  publishMintingStat?: Maybe<MintingStat>;
  /** Publish one multiChainMintingProject */
  publishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Publish one nftCategoryDisplay */
  publishNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Publish one nftCollection */
  publishNftCollection?: Maybe<NftCollection>;
  /** Publish one page */
  publishPage?: Maybe<Page>;
  /** Publish one pageHero */
  publishPageHero?: Maybe<PageHero>;
  /** Publish one partnerSection */
  publishPartnerSection?: Maybe<PartnerSection>;
  /** Publish one perksSection */
  publishPerksSection?: Maybe<PerksSection>;
  /** Publish one poweredBySection */
  publishPoweredBySection?: Maybe<PoweredBySection>;
  /** Publish one pupsPresaleCommunity */
  publishPupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Publish one roadmap */
  publishRoadmap?: Maybe<Roadmap>;
  /** Publish one teamSection */
  publishTeamSection?: Maybe<TeamSection>;
  /** Schedule to publish one alphaNftCollectionTier */
  schedulePublishAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Schedule to publish one alphaSharesInformation */
  schedulePublishAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<Asset>;
  /** Schedule to publish one chain */
  schedulePublishChain?: Maybe<Chain>;
  /** Schedule to publish one collectionCreator */
  schedulePublishCollectionCreator?: Maybe<CollectionCreator>;
  /** Schedule to publish one ctaSection */
  schedulePublishCtaSection?: Maybe<CtaSection>;
  /** Schedule to publish one fAQSection */
  schedulePublishFAQSection?: Maybe<FaqSection>;
  /** Schedule to publish one featuredCollectionGroup */
  schedulePublishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Schedule to publish one featuredCreatorGroup */
  schedulePublishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Schedule to publish one gettingStartedSection */
  schedulePublishGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Schedule to publish one infoCardsSection */
  schedulePublishInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Schedule to publish one landingPage */
  schedulePublishLandingPage?: Maybe<LandingPage>;
  /** Schedule to publish one marketplaceAnnouncementBanner */
  schedulePublishMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Schedule to publish one marketplaceContract */
  schedulePublishMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Schedule to publish one marketplacePage */
  schedulePublishMarketplacePage?: Maybe<MarketplacePage>;
  /** Schedule to publish one menu */
  schedulePublishMenu?: Maybe<Menu>;
  /** Schedule to publish one miningBtcSupplyDemandModelSection */
  schedulePublishMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Schedule to publish one miningContainer */
  schedulePublishMiningContainer?: Maybe<MiningContainer>;
  /** Schedule to publish one miningHeaderNotice */
  schedulePublishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Schedule to publish one miningNFTLPPageHeroSection */
  schedulePublishMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Schedule to publish one miningNftAccessSection */
  schedulePublishMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Schedule to publish one miningNftAdvantageSection */
  schedulePublishMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Schedule to publish one miningNftAntimonyAdvantageSection */
  schedulePublishMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Schedule to publish one miningNftComparisonTable */
  schedulePublishMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Schedule to publish one miningNftEligibilitySection */
  schedulePublishMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Schedule to publish one miningNftProfitRoiSection */
  schedulePublishMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Schedule to publish one miningNftTeamSection */
  schedulePublishMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Schedule to publish one miningNftWhitelistManualEntry */
  schedulePublishMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Schedule to publish one miningNftfaqSection */
  schedulePublishMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Schedule to publish one mintingCurrency */
  schedulePublishMintingCurrency?: Maybe<MintingCurrency>;
  /** Schedule to publish one mintingProject */
  schedulePublishMintingProject?: Maybe<MintingProject>;
  /** Schedule to publish one mintingStat */
  schedulePublishMintingStat?: Maybe<MintingStat>;
  /** Schedule to publish one multiChainMintingProject */
  schedulePublishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Schedule to publish one nftCategoryDisplay */
  schedulePublishNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Schedule to publish one nftCollection */
  schedulePublishNftCollection?: Maybe<NftCollection>;
  /** Schedule to publish one page */
  schedulePublishPage?: Maybe<Page>;
  /** Schedule to publish one pageHero */
  schedulePublishPageHero?: Maybe<PageHero>;
  /** Schedule to publish one partnerSection */
  schedulePublishPartnerSection?: Maybe<PartnerSection>;
  /** Schedule to publish one perksSection */
  schedulePublishPerksSection?: Maybe<PerksSection>;
  /** Schedule to publish one poweredBySection */
  schedulePublishPoweredBySection?: Maybe<PoweredBySection>;
  /** Schedule to publish one pupsPresaleCommunity */
  schedulePublishPupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Schedule to publish one roadmap */
  schedulePublishRoadmap?: Maybe<Roadmap>;
  /** Schedule to publish one teamSection */
  schedulePublishTeamSection?: Maybe<TeamSection>;
  /** Unpublish one alphaNftCollectionTier from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Unpublish one alphaSharesInformation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<Asset>;
  /** Unpublish one chain from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishChain?: Maybe<Chain>;
  /** Unpublish one collectionCreator from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCollectionCreator?: Maybe<CollectionCreator>;
  /** Unpublish one ctaSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCtaSection?: Maybe<CtaSection>;
  /** Unpublish one fAQSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFAQSection?: Maybe<FaqSection>;
  /** Unpublish one featuredCollectionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Unpublish one featuredCreatorGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Unpublish one gettingStartedSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Unpublish one infoCardsSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishLandingPage?: Maybe<LandingPage>;
  /** Unpublish one marketplaceAnnouncementBanner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Unpublish one marketplaceContract from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Unpublish one marketplacePage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMarketplacePage?: Maybe<MarketplacePage>;
  /** Unpublish one menu from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMenu?: Maybe<Menu>;
  /** Unpublish one miningBtcSupplyDemandModelSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Unpublish one miningContainer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningContainer?: Maybe<MiningContainer>;
  /** Unpublish one miningHeaderNotice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Unpublish one miningNFTLPPageHeroSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Unpublish one miningNftAccessSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Unpublish one miningNftAdvantageSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Unpublish one miningNftAntimonyAdvantageSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Unpublish one miningNftComparisonTable from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Unpublish one miningNftEligibilitySection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Unpublish one miningNftProfitRoiSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Unpublish one miningNftTeamSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Unpublish one miningNftWhitelistManualEntry from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Unpublish one miningNftfaqSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Unpublish one mintingCurrency from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMintingCurrency?: Maybe<MintingCurrency>;
  /** Unpublish one mintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMintingProject?: Maybe<MintingProject>;
  /** Unpublish one mintingStat from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMintingStat?: Maybe<MintingStat>;
  /** Unpublish one multiChainMintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Unpublish one nftCategoryDisplay from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Unpublish one nftCollection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishNftCollection?: Maybe<NftCollection>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPage?: Maybe<Page>;
  /** Unpublish one pageHero from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPageHero?: Maybe<PageHero>;
  /** Unpublish one partnerSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPartnerSection?: Maybe<PartnerSection>;
  /** Unpublish one perksSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPerksSection?: Maybe<PerksSection>;
  /** Unpublish one poweredBySection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPoweredBySection?: Maybe<PoweredBySection>;
  /** Unpublish one pupsPresaleCommunity from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Unpublish one roadmap from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishRoadmap?: Maybe<Roadmap>;
  /** Unpublish one teamSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTeamSection?: Maybe<TeamSection>;
  /** Unpublish one alphaNftCollectionTier from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Unpublish one alphaSharesInformation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<Asset>;
  /** Unpublish one chain from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishChain?: Maybe<Chain>;
  /** Unpublish one collectionCreator from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCollectionCreator?: Maybe<CollectionCreator>;
  /** Unpublish one ctaSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCtaSection?: Maybe<CtaSection>;
  /** Unpublish one fAQSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFAQSection?: Maybe<FaqSection>;
  /** Unpublish one featuredCollectionGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Unpublish one featuredCreatorGroup from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Unpublish one gettingStartedSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Unpublish one infoCardsSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Unpublish one landingPage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishLandingPage?: Maybe<LandingPage>;
  /**
   * Unpublish many AlphaNftCollectionTier documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAlphaNftCollectionTiersConnection)
   */
  unpublishManyAlphaNftCollectionTiers: BatchPayload;
  /** Find many AlphaNftCollectionTier documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAlphaNftCollectionTiersConnection: AlphaNftCollectionTierConnection;
  /**
   * Unpublish many AlphaSharesInformation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAlphaSharesInformationsConnection)
   */
  unpublishManyAlphaSharesInformations: BatchPayload;
  /** Find many AlphaSharesInformation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAlphaSharesInformationsConnection: AlphaSharesInformationConnection;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: BatchPayload;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: AssetConnection;
  /**
   * Unpublish many Chain documents
   * @deprecated Please use the new paginated many mutation (unpublishManyChainsConnection)
   */
  unpublishManyChains: BatchPayload;
  /** Find many Chain documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyChainsConnection: ChainConnection;
  /**
   * Unpublish many CollectionCreator documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCollectionCreatorsConnection)
   */
  unpublishManyCollectionCreators: BatchPayload;
  /** Find many CollectionCreator documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Unpublish many CtaSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCtaSectionsConnection)
   */
  unpublishManyCtaSections: BatchPayload;
  /** Find many CtaSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Unpublish many FAQSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFAQSectionsConnection)
   */
  unpublishManyFAQSections: BatchPayload;
  /** Find many FAQSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Unpublish many FeaturedCollectionGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFeaturedCollectionGroupsConnection)
   */
  unpublishManyFeaturedCollectionGroups: BatchPayload;
  /** Find many FeaturedCollectionGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Unpublish many FeaturedCreatorGroup documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFeaturedCreatorGroupsConnection)
   */
  unpublishManyFeaturedCreatorGroups: BatchPayload;
  /** Find many FeaturedCreatorGroup documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Unpublish many GettingStartedSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyGettingStartedSectionsConnection)
   */
  unpublishManyGettingStartedSections: BatchPayload;
  /** Find many GettingStartedSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyGettingStartedSectionsConnection: GettingStartedSectionConnection;
  /**
   * Unpublish many InfoCardsSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyInfoCardsSectionsConnection)
   */
  unpublishManyInfoCardsSections: BatchPayload;
  /** Find many InfoCardsSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyInfoCardsSectionsConnection: InfoCardsSectionConnection;
  /**
   * Unpublish many LandingPage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyLandingPagesConnection)
   */
  unpublishManyLandingPages: BatchPayload;
  /** Find many LandingPage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyLandingPagesConnection: LandingPageConnection;
  /**
   * Unpublish many MarketplaceAnnouncementBanner documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMarketplaceAnnouncementBannersConnection)
   */
  unpublishManyMarketplaceAnnouncementBanners: BatchPayload;
  /** Find many MarketplaceAnnouncementBanner documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMarketplaceAnnouncementBannersConnection: MarketplaceAnnouncementBannerConnection;
  /**
   * Unpublish many MarketplaceContract documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMarketplaceContractsConnection)
   */
  unpublishManyMarketplaceContracts: BatchPayload;
  /** Find many MarketplaceContract documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMarketplaceContractsConnection: MarketplaceContractConnection;
  /**
   * Unpublish many MarketplacePage documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMarketplacePagesConnection)
   */
  unpublishManyMarketplacePages: BatchPayload;
  /** Find many MarketplacePage documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMarketplacePagesConnection: MarketplacePageConnection;
  /**
   * Unpublish many Menu documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMenusConnection)
   */
  unpublishManyMenus: BatchPayload;
  /** Find many Menu documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMenusConnection: MenuConnection;
  /**
   * Unpublish many MiningBtcSupplyDemandModelSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningBtcSupplyDemandModelSectionsConnection)
   */
  unpublishManyMiningBtcSupplyDemandModelSections: BatchPayload;
  /** Find many MiningBtcSupplyDemandModelSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningBtcSupplyDemandModelSectionsConnection: MiningBtcSupplyDemandModelSectionConnection;
  /**
   * Unpublish many MiningContainer documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningContainersConnection)
   */
  unpublishManyMiningContainers: BatchPayload;
  /** Find many MiningContainer documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Unpublish many MiningHeaderNotice documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningHeaderNoticesConnection)
   */
  unpublishManyMiningHeaderNotices: BatchPayload;
  /** Find many MiningHeaderNotice documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Unpublish many MiningNFTLPPageHeroSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNFTLPPageHeroSectionsConnection)
   */
  unpublishManyMiningNFTLPPageHeroSections: BatchPayload;
  /** Find many MiningNFTLPPageHeroSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNFTLPPageHeroSectionsConnection: MiningNftlpPageHeroSectionConnection;
  /**
   * Unpublish many MiningNftAccessSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftAccessSectionsConnection)
   */
  unpublishManyMiningNftAccessSections: BatchPayload;
  /** Find many MiningNftAccessSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftAccessSectionsConnection: MiningNftAccessSectionConnection;
  /**
   * Unpublish many MiningNftAdvantageSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftAdvantageSectionsConnection)
   */
  unpublishManyMiningNftAdvantageSections: BatchPayload;
  /** Find many MiningNftAdvantageSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftAdvantageSectionsConnection: MiningNftAdvantageSectionConnection;
  /**
   * Unpublish many MiningNftAntimonyAdvantageSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftAntimonyAdvantageSectionsConnection)
   */
  unpublishManyMiningNftAntimonyAdvantageSections: BatchPayload;
  /** Find many MiningNftAntimonyAdvantageSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftAntimonyAdvantageSectionsConnection: MiningNftAntimonyAdvantageSectionConnection;
  /**
   * Unpublish many MiningNftComparisonTable documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftComparisonTablesConnection)
   */
  unpublishManyMiningNftComparisonTables: BatchPayload;
  /** Find many MiningNftComparisonTable documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftComparisonTablesConnection: MiningNftComparisonTableConnection;
  /**
   * Unpublish many MiningNftEligibilitySection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftEligibilitySectionsConnection)
   */
  unpublishManyMiningNftEligibilitySections: BatchPayload;
  /** Find many MiningNftEligibilitySection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftEligibilitySectionsConnection: MiningNftEligibilitySectionConnection;
  /**
   * Unpublish many MiningNftProfitRoiSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftProfitRoiSectionsConnection)
   */
  unpublishManyMiningNftProfitRoiSections: BatchPayload;
  /** Find many MiningNftProfitRoiSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftProfitRoiSectionsConnection: MiningNftProfitRoiSectionConnection;
  /**
   * Unpublish many MiningNftTeamSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftTeamSectionsConnection)
   */
  unpublishManyMiningNftTeamSections: BatchPayload;
  /** Find many MiningNftTeamSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftTeamSectionsConnection: MiningNftTeamSectionConnection;
  /**
   * Unpublish many MiningNftWhitelistManualEntry documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftWhitelistManualEntriesConnection)
   */
  unpublishManyMiningNftWhitelistManualEntries: BatchPayload;
  /** Find many MiningNftWhitelistManualEntry documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftWhitelistManualEntriesConnection: MiningNftWhitelistManualEntryConnection;
  /**
   * Unpublish many MiningNftfaqSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMiningNftfaqSectionsConnection)
   */
  unpublishManyMiningNftfaqSections: BatchPayload;
  /** Find many MiningNftfaqSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMiningNftfaqSectionsConnection: MiningNftfaqSectionConnection;
  /**
   * Unpublish many MintingCurrency documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMintingCurrenciesConnection)
   */
  unpublishManyMintingCurrencies: BatchPayload;
  /** Find many MintingCurrency documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Unpublish many MintingProject documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMintingProjectsConnection)
   */
  unpublishManyMintingProjects: BatchPayload;
  /** Find many MintingProject documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Unpublish many MintingStat documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMintingStatsConnection)
   */
  unpublishManyMintingStats: BatchPayload;
  /** Find many MintingStat documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMintingStatsConnection: MintingStatConnection;
  /**
   * Unpublish many MultiChainMintingProject documents
   * @deprecated Please use the new paginated many mutation (unpublishManyMultiChainMintingProjectsConnection)
   */
  unpublishManyMultiChainMintingProjects: BatchPayload;
  /** Find many MultiChainMintingProject documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Unpublish many NftCategoryDisplay documents
   * @deprecated Please use the new paginated many mutation (unpublishManyNftCategoryDisplaysConnection)
   */
  unpublishManyNftCategoryDisplays: BatchPayload;
  /** Find many NftCategoryDisplay documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyNftCategoryDisplaysConnection: NftCategoryDisplayConnection;
  /**
   * Unpublish many NftCollection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyNftCollectionsConnection)
   */
  unpublishManyNftCollections: BatchPayload;
  /** Find many NftCollection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Unpublish many PageHero documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPageHeroesConnection)
   */
  unpublishManyPageHeroes: BatchPayload;
  /** Find many PageHero documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPageHeroesConnection: PageHeroConnection;
  /**
   * Unpublish many Page documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPagesConnection)
   */
  unpublishManyPages: BatchPayload;
  /** Find many Page documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPagesConnection: PageConnection;
  /**
   * Unpublish many PartnerSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPartnerSectionsConnection)
   */
  unpublishManyPartnerSections: BatchPayload;
  /** Find many PartnerSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPartnerSectionsConnection: PartnerSectionConnection;
  /**
   * Unpublish many PerksSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPerksSectionsConnection)
   */
  unpublishManyPerksSections: BatchPayload;
  /** Find many PerksSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPerksSectionsConnection: PerksSectionConnection;
  /**
   * Unpublish many PoweredBySection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPoweredBySectionsConnection)
   */
  unpublishManyPoweredBySections: BatchPayload;
  /** Find many PoweredBySection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPoweredBySectionsConnection: PoweredBySectionConnection;
  /**
   * Unpublish many PupsPresaleCommunity documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPupsPresaleCommunitiesConnection)
   */
  unpublishManyPupsPresaleCommunities: BatchPayload;
  /** Find many PupsPresaleCommunity documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPupsPresaleCommunitiesConnection: PupsPresaleCommunityConnection;
  /**
   * Unpublish many Roadmap documents
   * @deprecated Please use the new paginated many mutation (unpublishManyRoadmapsConnection)
   */
  unpublishManyRoadmaps: BatchPayload;
  /** Find many Roadmap documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyRoadmapsConnection: RoadmapConnection;
  /**
   * Unpublish many TeamSection documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTeamSectionsConnection)
   */
  unpublishManyTeamSections: BatchPayload;
  /** Find many TeamSection documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTeamSectionsConnection: TeamSectionConnection;
  /** Unpublish one marketplaceAnnouncementBanner from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Unpublish one marketplaceContract from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Unpublish one marketplacePage from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMarketplacePage?: Maybe<MarketplacePage>;
  /** Unpublish one menu from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMenu?: Maybe<Menu>;
  /** Unpublish one miningBtcSupplyDemandModelSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Unpublish one miningContainer from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningContainer?: Maybe<MiningContainer>;
  /** Unpublish one miningHeaderNotice from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Unpublish one miningNFTLPPageHeroSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Unpublish one miningNftAccessSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Unpublish one miningNftAdvantageSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Unpublish one miningNftAntimonyAdvantageSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Unpublish one miningNftComparisonTable from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Unpublish one miningNftEligibilitySection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Unpublish one miningNftProfitRoiSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Unpublish one miningNftTeamSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Unpublish one miningNftWhitelistManualEntry from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Unpublish one miningNftfaqSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Unpublish one mintingCurrency from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMintingCurrency?: Maybe<MintingCurrency>;
  /** Unpublish one mintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMintingProject?: Maybe<MintingProject>;
  /** Unpublish one mintingStat from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMintingStat?: Maybe<MintingStat>;
  /** Unpublish one multiChainMintingProject from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Unpublish one nftCategoryDisplay from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Unpublish one nftCollection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishNftCollection?: Maybe<NftCollection>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPage?: Maybe<Page>;
  /** Unpublish one pageHero from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPageHero?: Maybe<PageHero>;
  /** Unpublish one partnerSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPartnerSection?: Maybe<PartnerSection>;
  /** Unpublish one perksSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPerksSection?: Maybe<PerksSection>;
  /** Unpublish one poweredBySection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPoweredBySection?: Maybe<PoweredBySection>;
  /** Unpublish one pupsPresaleCommunity from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Unpublish one roadmap from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishRoadmap?: Maybe<Roadmap>;
  /** Unpublish one teamSection from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTeamSection?: Maybe<TeamSection>;
  /** Update one alphaNftCollectionTier */
  updateAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Update one alphaSharesInformation */
  updateAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Update one asset */
  updateAsset?: Maybe<Asset>;
  /** Update one chain */
  updateChain?: Maybe<Chain>;
  /** Update one collectionCreator */
  updateCollectionCreator?: Maybe<CollectionCreator>;
  /** Update one ctaSection */
  updateCtaSection?: Maybe<CtaSection>;
  /** Update one fAQSection */
  updateFAQSection?: Maybe<FaqSection>;
  /** Update one featuredCollectionGroup */
  updateFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Update one featuredCreatorGroup */
  updateFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Update one gettingStartedSection */
  updateGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Update one infoCardsSection */
  updateInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Update one landingPage */
  updateLandingPage?: Maybe<LandingPage>;
  /**
   * Update many alphaNftCollectionTiers
   * @deprecated Please use the new paginated many mutation (updateManyAlphaNftCollectionTiersConnection)
   */
  updateManyAlphaNftCollectionTiers: BatchPayload;
  /** Update many AlphaNftCollectionTier documents */
  updateManyAlphaNftCollectionTiersConnection: AlphaNftCollectionTierConnection;
  /**
   * Update many alphaSharesInformations
   * @deprecated Please use the new paginated many mutation (updateManyAlphaSharesInformationsConnection)
   */
  updateManyAlphaSharesInformations: BatchPayload;
  /** Update many AlphaSharesInformation documents */
  updateManyAlphaSharesInformationsConnection: AlphaSharesInformationConnection;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: BatchPayload;
  /** Update many Asset documents */
  updateManyAssetsConnection: AssetConnection;
  /**
   * Update many chains
   * @deprecated Please use the new paginated many mutation (updateManyChainsConnection)
   */
  updateManyChains: BatchPayload;
  /** Update many Chain documents */
  updateManyChainsConnection: ChainConnection;
  /**
   * Update many collectionCreators
   * @deprecated Please use the new paginated many mutation (updateManyCollectionCreatorsConnection)
   */
  updateManyCollectionCreators: BatchPayload;
  /** Update many CollectionCreator documents */
  updateManyCollectionCreatorsConnection: CollectionCreatorConnection;
  /**
   * Update many ctaSections
   * @deprecated Please use the new paginated many mutation (updateManyCtaSectionsConnection)
   */
  updateManyCtaSections: BatchPayload;
  /** Update many CtaSection documents */
  updateManyCtaSectionsConnection: CtaSectionConnection;
  /**
   * Update many fAQSections
   * @deprecated Please use the new paginated many mutation (updateManyFAQSectionsConnection)
   */
  updateManyFAQSections: BatchPayload;
  /** Update many FAQSection documents */
  updateManyFAQSectionsConnection: FaqSectionConnection;
  /**
   * Update many featuredCollectionGroups
   * @deprecated Please use the new paginated many mutation (updateManyFeaturedCollectionGroupsConnection)
   */
  updateManyFeaturedCollectionGroups: BatchPayload;
  /** Update many FeaturedCollectionGroup documents */
  updateManyFeaturedCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /**
   * Update many featuredCreatorGroups
   * @deprecated Please use the new paginated many mutation (updateManyFeaturedCreatorGroupsConnection)
   */
  updateManyFeaturedCreatorGroups: BatchPayload;
  /** Update many FeaturedCreatorGroup documents */
  updateManyFeaturedCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /**
   * Update many gettingStartedSections
   * @deprecated Please use the new paginated many mutation (updateManyGettingStartedSectionsConnection)
   */
  updateManyGettingStartedSections: BatchPayload;
  /** Update many GettingStartedSection documents */
  updateManyGettingStartedSectionsConnection: GettingStartedSectionConnection;
  /**
   * Update many infoCardsSections
   * @deprecated Please use the new paginated many mutation (updateManyInfoCardsSectionsConnection)
   */
  updateManyInfoCardsSections: BatchPayload;
  /** Update many InfoCardsSection documents */
  updateManyInfoCardsSectionsConnection: InfoCardsSectionConnection;
  /**
   * Update many landingPages
   * @deprecated Please use the new paginated many mutation (updateManyLandingPagesConnection)
   */
  updateManyLandingPages: BatchPayload;
  /** Update many LandingPage documents */
  updateManyLandingPagesConnection: LandingPageConnection;
  /**
   * Update many marketplaceAnnouncementBanners
   * @deprecated Please use the new paginated many mutation (updateManyMarketplaceAnnouncementBannersConnection)
   */
  updateManyMarketplaceAnnouncementBanners: BatchPayload;
  /** Update many MarketplaceAnnouncementBanner documents */
  updateManyMarketplaceAnnouncementBannersConnection: MarketplaceAnnouncementBannerConnection;
  /**
   * Update many marketplaceContracts
   * @deprecated Please use the new paginated many mutation (updateManyMarketplaceContractsConnection)
   */
  updateManyMarketplaceContracts: BatchPayload;
  /** Update many MarketplaceContract documents */
  updateManyMarketplaceContractsConnection: MarketplaceContractConnection;
  /**
   * Update many marketplacePages
   * @deprecated Please use the new paginated many mutation (updateManyMarketplacePagesConnection)
   */
  updateManyMarketplacePages: BatchPayload;
  /** Update many MarketplacePage documents */
  updateManyMarketplacePagesConnection: MarketplacePageConnection;
  /**
   * Update many menus
   * @deprecated Please use the new paginated many mutation (updateManyMenusConnection)
   */
  updateManyMenus: BatchPayload;
  /** Update many Menu documents */
  updateManyMenusConnection: MenuConnection;
  /**
   * Update many miningBtcSupplyDemandModelSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningBtcSupplyDemandModelSectionsConnection)
   */
  updateManyMiningBtcSupplyDemandModelSections: BatchPayload;
  /** Update many MiningBtcSupplyDemandModelSection documents */
  updateManyMiningBtcSupplyDemandModelSectionsConnection: MiningBtcSupplyDemandModelSectionConnection;
  /**
   * Update many miningContainers
   * @deprecated Please use the new paginated many mutation (updateManyMiningContainersConnection)
   */
  updateManyMiningContainers: BatchPayload;
  /** Update many MiningContainer documents */
  updateManyMiningContainersConnection: MiningContainerConnection;
  /**
   * Update many miningHeaderNotices
   * @deprecated Please use the new paginated many mutation (updateManyMiningHeaderNoticesConnection)
   */
  updateManyMiningHeaderNotices: BatchPayload;
  /** Update many MiningHeaderNotice documents */
  updateManyMiningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /**
   * Update many miningNFTLPPageHeroSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNFTLPPageHeroSectionsConnection)
   */
  updateManyMiningNFTLPPageHeroSections: BatchPayload;
  /** Update many MiningNFTLPPageHeroSection documents */
  updateManyMiningNFTLPPageHeroSectionsConnection: MiningNftlpPageHeroSectionConnection;
  /**
   * Update many miningNftAccessSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftAccessSectionsConnection)
   */
  updateManyMiningNftAccessSections: BatchPayload;
  /** Update many MiningNftAccessSection documents */
  updateManyMiningNftAccessSectionsConnection: MiningNftAccessSectionConnection;
  /**
   * Update many miningNftAdvantageSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftAdvantageSectionsConnection)
   */
  updateManyMiningNftAdvantageSections: BatchPayload;
  /** Update many MiningNftAdvantageSection documents */
  updateManyMiningNftAdvantageSectionsConnection: MiningNftAdvantageSectionConnection;
  /**
   * Update many miningNftAntimonyAdvantageSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftAntimonyAdvantageSectionsConnection)
   */
  updateManyMiningNftAntimonyAdvantageSections: BatchPayload;
  /** Update many MiningNftAntimonyAdvantageSection documents */
  updateManyMiningNftAntimonyAdvantageSectionsConnection: MiningNftAntimonyAdvantageSectionConnection;
  /**
   * Update many miningNftComparisonTables
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftComparisonTablesConnection)
   */
  updateManyMiningNftComparisonTables: BatchPayload;
  /** Update many MiningNftComparisonTable documents */
  updateManyMiningNftComparisonTablesConnection: MiningNftComparisonTableConnection;
  /**
   * Update many miningNftEligibilitySections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftEligibilitySectionsConnection)
   */
  updateManyMiningNftEligibilitySections: BatchPayload;
  /** Update many MiningNftEligibilitySection documents */
  updateManyMiningNftEligibilitySectionsConnection: MiningNftEligibilitySectionConnection;
  /**
   * Update many miningNftProfitRoiSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftProfitRoiSectionsConnection)
   */
  updateManyMiningNftProfitRoiSections: BatchPayload;
  /** Update many MiningNftProfitRoiSection documents */
  updateManyMiningNftProfitRoiSectionsConnection: MiningNftProfitRoiSectionConnection;
  /**
   * Update many miningNftTeamSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftTeamSectionsConnection)
   */
  updateManyMiningNftTeamSections: BatchPayload;
  /** Update many MiningNftTeamSection documents */
  updateManyMiningNftTeamSectionsConnection: MiningNftTeamSectionConnection;
  /**
   * Update many miningNftWhitelistManualEntries
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftWhitelistManualEntriesConnection)
   */
  updateManyMiningNftWhitelistManualEntries: BatchPayload;
  /** Update many MiningNftWhitelistManualEntry documents */
  updateManyMiningNftWhitelistManualEntriesConnection: MiningNftWhitelistManualEntryConnection;
  /**
   * Update many miningNftfaqSections
   * @deprecated Please use the new paginated many mutation (updateManyMiningNftfaqSectionsConnection)
   */
  updateManyMiningNftfaqSections: BatchPayload;
  /** Update many MiningNftfaqSection documents */
  updateManyMiningNftfaqSectionsConnection: MiningNftfaqSectionConnection;
  /**
   * Update many mintingCurrencies
   * @deprecated Please use the new paginated many mutation (updateManyMintingCurrenciesConnection)
   */
  updateManyMintingCurrencies: BatchPayload;
  /** Update many MintingCurrency documents */
  updateManyMintingCurrenciesConnection: MintingCurrencyConnection;
  /**
   * Update many mintingProjects
   * @deprecated Please use the new paginated many mutation (updateManyMintingProjectsConnection)
   */
  updateManyMintingProjects: BatchPayload;
  /** Update many MintingProject documents */
  updateManyMintingProjectsConnection: MintingProjectConnection;
  /**
   * Update many mintingStats
   * @deprecated Please use the new paginated many mutation (updateManyMintingStatsConnection)
   */
  updateManyMintingStats: BatchPayload;
  /** Update many MintingStat documents */
  updateManyMintingStatsConnection: MintingStatConnection;
  /**
   * Update many multiChainMintingProjects
   * @deprecated Please use the new paginated many mutation (updateManyMultiChainMintingProjectsConnection)
   */
  updateManyMultiChainMintingProjects: BatchPayload;
  /** Update many MultiChainMintingProject documents */
  updateManyMultiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /**
   * Update many nftCategoryDisplays
   * @deprecated Please use the new paginated many mutation (updateManyNftCategoryDisplaysConnection)
   */
  updateManyNftCategoryDisplays: BatchPayload;
  /** Update many NftCategoryDisplay documents */
  updateManyNftCategoryDisplaysConnection: NftCategoryDisplayConnection;
  /**
   * Update many nftCollections
   * @deprecated Please use the new paginated many mutation (updateManyNftCollectionsConnection)
   */
  updateManyNftCollections: BatchPayload;
  /** Update many NftCollection documents */
  updateManyNftCollectionsConnection: NftCollectionConnection;
  /**
   * Update many pageHeroes
   * @deprecated Please use the new paginated many mutation (updateManyPageHeroesConnection)
   */
  updateManyPageHeroes: BatchPayload;
  /** Update many PageHero documents */
  updateManyPageHeroesConnection: PageHeroConnection;
  /**
   * Update many pages
   * @deprecated Please use the new paginated many mutation (updateManyPagesConnection)
   */
  updateManyPages: BatchPayload;
  /** Update many Page documents */
  updateManyPagesConnection: PageConnection;
  /**
   * Update many partnerSections
   * @deprecated Please use the new paginated many mutation (updateManyPartnerSectionsConnection)
   */
  updateManyPartnerSections: BatchPayload;
  /** Update many PartnerSection documents */
  updateManyPartnerSectionsConnection: PartnerSectionConnection;
  /**
   * Update many perksSections
   * @deprecated Please use the new paginated many mutation (updateManyPerksSectionsConnection)
   */
  updateManyPerksSections: BatchPayload;
  /** Update many PerksSection documents */
  updateManyPerksSectionsConnection: PerksSectionConnection;
  /**
   * Update many poweredBySections
   * @deprecated Please use the new paginated many mutation (updateManyPoweredBySectionsConnection)
   */
  updateManyPoweredBySections: BatchPayload;
  /** Update many PoweredBySection documents */
  updateManyPoweredBySectionsConnection: PoweredBySectionConnection;
  /**
   * Update many pupsPresaleCommunities
   * @deprecated Please use the new paginated many mutation (updateManyPupsPresaleCommunitiesConnection)
   */
  updateManyPupsPresaleCommunities: BatchPayload;
  /** Update many PupsPresaleCommunity documents */
  updateManyPupsPresaleCommunitiesConnection: PupsPresaleCommunityConnection;
  /**
   * Update many roadmaps
   * @deprecated Please use the new paginated many mutation (updateManyRoadmapsConnection)
   */
  updateManyRoadmaps: BatchPayload;
  /** Update many Roadmap documents */
  updateManyRoadmapsConnection: RoadmapConnection;
  /**
   * Update many teamSections
   * @deprecated Please use the new paginated many mutation (updateManyTeamSectionsConnection)
   */
  updateManyTeamSections: BatchPayload;
  /** Update many TeamSection documents */
  updateManyTeamSectionsConnection: TeamSectionConnection;
  /** Update one marketplaceAnnouncementBanner */
  updateMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Update one marketplaceContract */
  updateMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Update one marketplacePage */
  updateMarketplacePage?: Maybe<MarketplacePage>;
  /** Update one menu */
  updateMenu?: Maybe<Menu>;
  /** Update one miningBtcSupplyDemandModelSection */
  updateMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Update one miningContainer */
  updateMiningContainer?: Maybe<MiningContainer>;
  /** Update one miningHeaderNotice */
  updateMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Update one miningNFTLPPageHeroSection */
  updateMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Update one miningNftAccessSection */
  updateMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Update one miningNftAdvantageSection */
  updateMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Update one miningNftAntimonyAdvantageSection */
  updateMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Update one miningNftComparisonTable */
  updateMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Update one miningNftEligibilitySection */
  updateMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Update one miningNftProfitRoiSection */
  updateMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Update one miningNftTeamSection */
  updateMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Update one miningNftWhitelistManualEntry */
  updateMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Update one miningNftfaqSection */
  updateMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Update one mintingCurrency */
  updateMintingCurrency?: Maybe<MintingCurrency>;
  /** Update one mintingProject */
  updateMintingProject?: Maybe<MintingProject>;
  /** Update one mintingStat */
  updateMintingStat?: Maybe<MintingStat>;
  /** Update one multiChainMintingProject */
  updateMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Update one nftCategoryDisplay */
  updateNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Update one nftCollection */
  updateNftCollection?: Maybe<NftCollection>;
  /** Update one page */
  updatePage?: Maybe<Page>;
  /** Update one pageHero */
  updatePageHero?: Maybe<PageHero>;
  /** Update one partnerSection */
  updatePartnerSection?: Maybe<PartnerSection>;
  /** Update one perksSection */
  updatePerksSection?: Maybe<PerksSection>;
  /** Update one poweredBySection */
  updatePoweredBySection?: Maybe<PoweredBySection>;
  /** Update one pupsPresaleCommunity */
  updatePupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Update one roadmap */
  updateRoadmap?: Maybe<Roadmap>;
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<ScheduledRelease>;
  /** Update one teamSection */
  updateTeamSection?: Maybe<TeamSection>;
  /** Upsert one alphaNftCollectionTier */
  upsertAlphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Upsert one alphaSharesInformation */
  upsertAlphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Upsert one asset */
  upsertAsset?: Maybe<Asset>;
  /** Upsert one chain */
  upsertChain?: Maybe<Chain>;
  /** Upsert one collectionCreator */
  upsertCollectionCreator?: Maybe<CollectionCreator>;
  /** Upsert one ctaSection */
  upsertCtaSection?: Maybe<CtaSection>;
  /** Upsert one fAQSection */
  upsertFAQSection?: Maybe<FaqSection>;
  /** Upsert one featuredCollectionGroup */
  upsertFeaturedCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Upsert one featuredCreatorGroup */
  upsertFeaturedCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Upsert one gettingStartedSection */
  upsertGettingStartedSection?: Maybe<GettingStartedSection>;
  /** Upsert one infoCardsSection */
  upsertInfoCardsSection?: Maybe<InfoCardsSection>;
  /** Upsert one landingPage */
  upsertLandingPage?: Maybe<LandingPage>;
  /** Upsert one marketplaceAnnouncementBanner */
  upsertMarketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Upsert one marketplaceContract */
  upsertMarketplaceContract?: Maybe<MarketplaceContract>;
  /** Upsert one marketplacePage */
  upsertMarketplacePage?: Maybe<MarketplacePage>;
  /** Upsert one menu */
  upsertMenu?: Maybe<Menu>;
  /** Upsert one miningBtcSupplyDemandModelSection */
  upsertMiningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Upsert one miningContainer */
  upsertMiningContainer?: Maybe<MiningContainer>;
  /** Upsert one miningHeaderNotice */
  upsertMiningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Upsert one miningNFTLPPageHeroSection */
  upsertMiningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Upsert one miningNftAccessSection */
  upsertMiningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Upsert one miningNftAdvantageSection */
  upsertMiningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Upsert one miningNftAntimonyAdvantageSection */
  upsertMiningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Upsert one miningNftComparisonTable */
  upsertMiningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Upsert one miningNftEligibilitySection */
  upsertMiningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Upsert one miningNftProfitRoiSection */
  upsertMiningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Upsert one miningNftTeamSection */
  upsertMiningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Upsert one miningNftWhitelistManualEntry */
  upsertMiningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Upsert one miningNftfaqSection */
  upsertMiningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Upsert one mintingCurrency */
  upsertMintingCurrency?: Maybe<MintingCurrency>;
  /** Upsert one mintingProject */
  upsertMintingProject?: Maybe<MintingProject>;
  /** Upsert one mintingStat */
  upsertMintingStat?: Maybe<MintingStat>;
  /** Upsert one multiChainMintingProject */
  upsertMultiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Upsert one nftCategoryDisplay */
  upsertNftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Upsert one nftCollection */
  upsertNftCollection?: Maybe<NftCollection>;
  /** Upsert one page */
  upsertPage?: Maybe<Page>;
  /** Upsert one pageHero */
  upsertPageHero?: Maybe<PageHero>;
  /** Upsert one partnerSection */
  upsertPartnerSection?: Maybe<PartnerSection>;
  /** Upsert one perksSection */
  upsertPerksSection?: Maybe<PerksSection>;
  /** Upsert one poweredBySection */
  upsertPoweredBySection?: Maybe<PoweredBySection>;
  /** Upsert one pupsPresaleCommunity */
  upsertPupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Upsert one roadmap */
  upsertRoadmap?: Maybe<Roadmap>;
  /** Upsert one teamSection */
  upsertTeamSection?: Maybe<TeamSection>;
};

export type MutationCreateAlphaNftCollectionTierArgs = {
  data: AlphaNftCollectionTierCreateInput;
};

export type MutationCreateAlphaSharesInformationArgs = {
  data: AlphaSharesInformationCreateInput;
};

export type MutationCreateAssetArgs = {
  data: AssetCreateInput;
};

export type MutationCreateChainArgs = {
  data: ChainCreateInput;
};

export type MutationCreateCollectionCreatorArgs = {
  data: CollectionCreatorCreateInput;
};

export type MutationCreateCtaSectionArgs = {
  data: CtaSectionCreateInput;
};

export type MutationCreateFaqSectionArgs = {
  data: FaqSectionCreateInput;
};

export type MutationCreateFeaturedCollectionGroupArgs = {
  data: FeaturedCollectionGroupCreateInput;
};

export type MutationCreateFeaturedCreatorGroupArgs = {
  data: FeaturedCreatorGroupCreateInput;
};

export type MutationCreateGettingStartedSectionArgs = {
  data: GettingStartedSectionCreateInput;
};

export type MutationCreateInfoCardsSectionArgs = {
  data: InfoCardsSectionCreateInput;
};

export type MutationCreateLandingPageArgs = {
  data: LandingPageCreateInput;
};

export type MutationCreateMarketplaceAnnouncementBannerArgs = {
  data: MarketplaceAnnouncementBannerCreateInput;
};

export type MutationCreateMarketplaceContractArgs = {
  data: MarketplaceContractCreateInput;
};

export type MutationCreateMarketplacePageArgs = {
  data: MarketplacePageCreateInput;
};

export type MutationCreateMenuArgs = {
  data: MenuCreateInput;
};

export type MutationCreateMiningBtcSupplyDemandModelSectionArgs = {
  data: MiningBtcSupplyDemandModelSectionCreateInput;
};

export type MutationCreateMiningContainerArgs = {
  data: MiningContainerCreateInput;
};

export type MutationCreateMiningHeaderNoticeArgs = {
  data: MiningHeaderNoticeCreateInput;
};

export type MutationCreateMiningNftlpPageHeroSectionArgs = {
  data: MiningNftlpPageHeroSectionCreateInput;
};

export type MutationCreateMiningNftAccessSectionArgs = {
  data: MiningNftAccessSectionCreateInput;
};

export type MutationCreateMiningNftAdvantageSectionArgs = {
  data: MiningNftAdvantageSectionCreateInput;
};

export type MutationCreateMiningNftAntimonyAdvantageSectionArgs = {
  data: MiningNftAntimonyAdvantageSectionCreateInput;
};

export type MutationCreateMiningNftComparisonTableArgs = {
  data: MiningNftComparisonTableCreateInput;
};

export type MutationCreateMiningNftEligibilitySectionArgs = {
  data: MiningNftEligibilitySectionCreateInput;
};

export type MutationCreateMiningNftProfitRoiSectionArgs = {
  data: MiningNftProfitRoiSectionCreateInput;
};

export type MutationCreateMiningNftTeamSectionArgs = {
  data: MiningNftTeamSectionCreateInput;
};

export type MutationCreateMiningNftWhitelistManualEntryArgs = {
  data: MiningNftWhitelistManualEntryCreateInput;
};

export type MutationCreateMiningNftfaqSectionArgs = {
  data: MiningNftfaqSectionCreateInput;
};

export type MutationCreateMintingCurrencyArgs = {
  data: MintingCurrencyCreateInput;
};

export type MutationCreateMintingProjectArgs = {
  data: MintingProjectCreateInput;
};

export type MutationCreateMintingStatArgs = {
  data: MintingStatCreateInput;
};

export type MutationCreateMultiChainMintingProjectArgs = {
  data: MultiChainMintingProjectCreateInput;
};

export type MutationCreateNftCategoryDisplayArgs = {
  data: NftCategoryDisplayCreateInput;
};

export type MutationCreateNftCollectionArgs = {
  data: NftCollectionCreateInput;
};

export type MutationCreatePageArgs = {
  data: PageCreateInput;
};

export type MutationCreatePageHeroArgs = {
  data: PageHeroCreateInput;
};

export type MutationCreatePartnerSectionArgs = {
  data: PartnerSectionCreateInput;
};

export type MutationCreatePerksSectionArgs = {
  data: PerksSectionCreateInput;
};

export type MutationCreatePoweredBySectionArgs = {
  data: PoweredBySectionCreateInput;
};

export type MutationCreatePupsPresaleCommunityArgs = {
  data: PupsPresaleCommunityCreateInput;
};

export type MutationCreateRoadmapArgs = {
  data: RoadmapCreateInput;
};

export type MutationCreateScheduledReleaseArgs = {
  data: ScheduledReleaseCreateInput;
};

export type MutationCreateTeamSectionArgs = {
  data: TeamSectionCreateInput;
};

export type MutationDeleteAlphaNftCollectionTierArgs = {
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type MutationDeleteAlphaSharesInformationArgs = {
  where: AlphaSharesInformationWhereUniqueInput;
};

export type MutationDeleteAssetArgs = {
  where: AssetWhereUniqueInput;
};

export type MutationDeleteChainArgs = {
  where: ChainWhereUniqueInput;
};

export type MutationDeleteCollectionCreatorArgs = {
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationDeleteCtaSectionArgs = {
  where: CtaSectionWhereUniqueInput;
};

export type MutationDeleteFaqSectionArgs = {
  where: FaqSectionWhereUniqueInput;
};

export type MutationDeleteFeaturedCollectionGroupArgs = {
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationDeleteFeaturedCreatorGroupArgs = {
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationDeleteGettingStartedSectionArgs = {
  where: GettingStartedSectionWhereUniqueInput;
};

export type MutationDeleteInfoCardsSectionArgs = {
  where: InfoCardsSectionWhereUniqueInput;
};

export type MutationDeleteLandingPageArgs = {
  where: LandingPageWhereUniqueInput;
};

export type MutationDeleteManyAlphaNftCollectionTiersArgs = {
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationDeleteManyAlphaNftCollectionTiersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationDeleteManyAlphaSharesInformationsArgs = {
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationDeleteManyAlphaSharesInformationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationDeleteManyAssetsArgs = {
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationDeleteManyChainsArgs = {
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationDeleteManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationDeleteManyCollectionCreatorsArgs = {
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationDeleteManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationDeleteManyCtaSectionsArgs = {
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationDeleteManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationDeleteManyFaqSectionsArgs = {
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationDeleteManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationDeleteManyFeaturedCollectionGroupsArgs = {
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationDeleteManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationDeleteManyFeaturedCreatorGroupsArgs = {
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationDeleteManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationDeleteManyGettingStartedSectionsArgs = {
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationDeleteManyGettingStartedSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationDeleteManyInfoCardsSectionsArgs = {
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationDeleteManyInfoCardsSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationDeleteManyLandingPagesArgs = {
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationDeleteManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationDeleteManyMarketplaceAnnouncementBannersArgs = {
  where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
};

export type MutationDeleteManyMarketplaceAnnouncementBannersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
};

export type MutationDeleteManyMarketplaceContractsArgs = {
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationDeleteManyMarketplaceContractsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationDeleteManyMarketplacePagesArgs = {
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationDeleteManyMarketplacePagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationDeleteManyMenusArgs = {
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationDeleteManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationDeleteManyMiningBtcSupplyDemandModelSectionsArgs = {
  where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
};

export type MutationDeleteManyMiningBtcSupplyDemandModelSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
  };

export type MutationDeleteManyMiningContainersArgs = {
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationDeleteManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationDeleteManyMiningHeaderNoticesArgs = {
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationDeleteManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationDeleteManyMiningNftlpPageHeroSectionsArgs = {
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftlpPageHeroSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftAccessSectionsArgs = {
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftAccessSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftAdvantageSectionsArgs = {
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftAdvantageSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftAntimonyAdvantageSectionsArgs = {
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftAntimonyAdvantageSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
  };

export type MutationDeleteManyMiningNftComparisonTablesArgs = {
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationDeleteManyMiningNftComparisonTablesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationDeleteManyMiningNftEligibilitySectionsArgs = {
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftEligibilitySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftProfitRoiSectionsArgs = {
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftProfitRoiSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftTeamSectionsArgs = {
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftWhitelistManualEntriesArgs = {
  where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
};

export type MutationDeleteManyMiningNftWhitelistManualEntriesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
};

export type MutationDeleteManyMiningNftfaqSectionsArgs = {
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationDeleteManyMiningNftfaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationDeleteManyMintingCurrenciesArgs = {
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationDeleteManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationDeleteManyMintingProjectsArgs = {
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationDeleteManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationDeleteManyMintingStatsArgs = {
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationDeleteManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationDeleteManyMultiChainMintingProjectsArgs = {
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationDeleteManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationDeleteManyNftCategoryDisplaysArgs = {
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationDeleteManyNftCategoryDisplaysConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationDeleteManyNftCollectionsArgs = {
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationDeleteManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationDeleteManyPageHeroesArgs = {
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationDeleteManyPageHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationDeleteManyPagesArgs = {
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationDeleteManyPartnerSectionsArgs = {
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationDeleteManyPartnerSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationDeleteManyPerksSectionsArgs = {
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationDeleteManyPerksSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationDeleteManyPoweredBySectionsArgs = {
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationDeleteManyPoweredBySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationDeleteManyPupsPresaleCommunitiesArgs = {
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationDeleteManyPupsPresaleCommunitiesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationDeleteManyRoadmapsArgs = {
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationDeleteManyRoadmapsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationDeleteManyTeamSectionsArgs = {
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationDeleteManyTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationDeleteMarketplaceAnnouncementBannerArgs = {
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MutationDeleteMarketplaceContractArgs = {
  where: MarketplaceContractWhereUniqueInput;
};

export type MutationDeleteMarketplacePageArgs = {
  where: MarketplacePageWhereUniqueInput;
};

export type MutationDeleteMenuArgs = {
  where: MenuWhereUniqueInput;
};

export type MutationDeleteMiningBtcSupplyDemandModelSectionArgs = {
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type MutationDeleteMiningContainerArgs = {
  where: MiningContainerWhereUniqueInput;
};

export type MutationDeleteMiningHeaderNoticeArgs = {
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationDeleteMiningNftlpPageHeroSectionArgs = {
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MutationDeleteMiningNftAccessSectionArgs = {
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MutationDeleteMiningNftAdvantageSectionArgs = {
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MutationDeleteMiningNftAntimonyAdvantageSectionArgs = {
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type MutationDeleteMiningNftComparisonTableArgs = {
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MutationDeleteMiningNftEligibilitySectionArgs = {
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MutationDeleteMiningNftProfitRoiSectionArgs = {
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MutationDeleteMiningNftTeamSectionArgs = {
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MutationDeleteMiningNftWhitelistManualEntryArgs = {
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MutationDeleteMiningNftfaqSectionArgs = {
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MutationDeleteMintingCurrencyArgs = {
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationDeleteMintingProjectArgs = {
  where: MintingProjectWhereUniqueInput;
};

export type MutationDeleteMintingStatArgs = {
  where: MintingStatWhereUniqueInput;
};

export type MutationDeleteMultiChainMintingProjectArgs = {
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationDeleteNftCategoryDisplayArgs = {
  where: NftCategoryDisplayWhereUniqueInput;
};

export type MutationDeleteNftCollectionArgs = {
  where: NftCollectionWhereUniqueInput;
};

export type MutationDeletePageArgs = {
  where: PageWhereUniqueInput;
};

export type MutationDeletePageHeroArgs = {
  where: PageHeroWhereUniqueInput;
};

export type MutationDeletePartnerSectionArgs = {
  where: PartnerSectionWhereUniqueInput;
};

export type MutationDeletePerksSectionArgs = {
  where: PerksSectionWhereUniqueInput;
};

export type MutationDeletePoweredBySectionArgs = {
  where: PoweredBySectionWhereUniqueInput;
};

export type MutationDeletePupsPresaleCommunityArgs = {
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type MutationDeleteRoadmapArgs = {
  where: RoadmapWhereUniqueInput;
};

export type MutationDeleteScheduledOperationArgs = {
  where: ScheduledOperationWhereUniqueInput;
};

export type MutationDeleteScheduledReleaseArgs = {
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationDeleteTeamSectionArgs = {
  where: TeamSectionWhereUniqueInput;
};

export type MutationPublishAlphaNftCollectionTierArgs = {
  to?: Array<Stage>;
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type MutationPublishAlphaSharesInformationArgs = {
  to?: Array<Stage>;
  where: AlphaSharesInformationWhereUniqueInput;
};

export type MutationPublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationPublishChainArgs = {
  to?: Array<Stage>;
  where: ChainWhereUniqueInput;
};

export type MutationPublishCollectionCreatorArgs = {
  to?: Array<Stage>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationPublishCtaSectionArgs = {
  to?: Array<Stage>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationPublishFaqSectionArgs = {
  to?: Array<Stage>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationPublishFeaturedCollectionGroupArgs = {
  to?: Array<Stage>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationPublishFeaturedCreatorGroupArgs = {
  to?: Array<Stage>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationPublishGettingStartedSectionArgs = {
  to?: Array<Stage>;
  where: GettingStartedSectionWhereUniqueInput;
};

export type MutationPublishInfoCardsSectionArgs = {
  to?: Array<Stage>;
  where: InfoCardsSectionWhereUniqueInput;
};

export type MutationPublishLandingPageArgs = {
  to?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
};

export type MutationPublishManyAlphaNftCollectionTiersArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationPublishManyAlphaNftCollectionTiersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationPublishManyAlphaSharesInformationsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationPublishManyAlphaSharesInformationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationPublishManyAssetsArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationPublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<AssetManyWhereInput>;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationPublishManyChainsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationPublishManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationPublishManyCollectionCreatorsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationPublishManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationPublishManyCtaSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationPublishManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationPublishManyFaqSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationPublishManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationPublishManyFeaturedCollectionGroupsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationPublishManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationPublishManyFeaturedCreatorGroupsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationPublishManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationPublishManyGettingStartedSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationPublishManyGettingStartedSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationPublishManyInfoCardsSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationPublishManyInfoCardsSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationPublishManyLandingPagesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationPublishManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationPublishManyMarketplaceAnnouncementBannersArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
};

export type MutationPublishManyMarketplaceAnnouncementBannersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
};

export type MutationPublishManyMarketplaceContractsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationPublishManyMarketplaceContractsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationPublishManyMarketplacePagesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationPublishManyMarketplacePagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationPublishManyMenusArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationPublishManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationPublishManyMiningBtcSupplyDemandModelSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
};

export type MutationPublishManyMiningBtcSupplyDemandModelSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    from?: InputMaybe<Stage>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    to?: Array<Stage>;
    where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
  };

export type MutationPublishManyMiningContainersArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationPublishManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationPublishManyMiningHeaderNoticesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationPublishManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationPublishManyMiningNftlpPageHeroSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftlpPageHeroSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftAccessSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftAccessSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftAdvantageSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftAdvantageSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftAntimonyAdvantageSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftAntimonyAdvantageSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    from?: InputMaybe<Stage>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    to?: Array<Stage>;
    where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
  };

export type MutationPublishManyMiningNftComparisonTablesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationPublishManyMiningNftComparisonTablesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationPublishManyMiningNftEligibilitySectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationPublishManyMiningNftEligibilitySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationPublishManyMiningNftProfitRoiSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftProfitRoiSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftTeamSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftWhitelistManualEntriesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
};

export type MutationPublishManyMiningNftWhitelistManualEntriesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
};

export type MutationPublishManyMiningNftfaqSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationPublishManyMiningNftfaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationPublishManyMintingCurrenciesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationPublishManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationPublishManyMintingProjectsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationPublishManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationPublishManyMintingStatsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationPublishManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationPublishManyMultiChainMintingProjectsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationPublishManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationPublishManyNftCategoryDisplaysArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationPublishManyNftCategoryDisplaysConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationPublishManyNftCollectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationPublishManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationPublishManyPageHeroesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationPublishManyPageHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationPublishManyPagesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationPublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationPublishManyPartnerSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationPublishManyPartnerSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationPublishManyPerksSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationPublishManyPerksSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationPublishManyPoweredBySectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationPublishManyPoweredBySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationPublishManyPupsPresaleCommunitiesArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationPublishManyPupsPresaleCommunitiesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationPublishManyRoadmapsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationPublishManyRoadmapsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationPublishManyTeamSectionsArgs = {
  to?: Array<Stage>;
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationPublishManyTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: InputMaybe<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  to?: Array<Stage>;
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationPublishMarketplaceAnnouncementBannerArgs = {
  to?: Array<Stage>;
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MutationPublishMarketplaceContractArgs = {
  to?: Array<Stage>;
  where: MarketplaceContractWhereUniqueInput;
};

export type MutationPublishMarketplacePageArgs = {
  to?: Array<Stage>;
  where: MarketplacePageWhereUniqueInput;
};

export type MutationPublishMenuArgs = {
  to?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationPublishMiningBtcSupplyDemandModelSectionArgs = {
  to?: Array<Stage>;
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type MutationPublishMiningContainerArgs = {
  to?: Array<Stage>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationPublishMiningHeaderNoticeArgs = {
  to?: Array<Stage>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationPublishMiningNftlpPageHeroSectionArgs = {
  to?: Array<Stage>;
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MutationPublishMiningNftAccessSectionArgs = {
  to?: Array<Stage>;
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MutationPublishMiningNftAdvantageSectionArgs = {
  to?: Array<Stage>;
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MutationPublishMiningNftAntimonyAdvantageSectionArgs = {
  to?: Array<Stage>;
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type MutationPublishMiningNftComparisonTableArgs = {
  to?: Array<Stage>;
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MutationPublishMiningNftEligibilitySectionArgs = {
  to?: Array<Stage>;
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MutationPublishMiningNftProfitRoiSectionArgs = {
  to?: Array<Stage>;
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MutationPublishMiningNftTeamSectionArgs = {
  to?: Array<Stage>;
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MutationPublishMiningNftWhitelistManualEntryArgs = {
  to?: Array<Stage>;
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MutationPublishMiningNftfaqSectionArgs = {
  to?: Array<Stage>;
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MutationPublishMintingCurrencyArgs = {
  to?: Array<Stage>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationPublishMintingProjectArgs = {
  to?: Array<Stage>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationPublishMintingStatArgs = {
  to?: Array<Stage>;
  where: MintingStatWhereUniqueInput;
};

export type MutationPublishMultiChainMintingProjectArgs = {
  to?: Array<Stage>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationPublishNftCategoryDisplayArgs = {
  to?: Array<Stage>;
  where: NftCategoryDisplayWhereUniqueInput;
};

export type MutationPublishNftCollectionArgs = {
  to?: Array<Stage>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationPublishPageArgs = {
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
};

export type MutationPublishPageHeroArgs = {
  to?: Array<Stage>;
  where: PageHeroWhereUniqueInput;
};

export type MutationPublishPartnerSectionArgs = {
  to?: Array<Stage>;
  where: PartnerSectionWhereUniqueInput;
};

export type MutationPublishPerksSectionArgs = {
  to?: Array<Stage>;
  where: PerksSectionWhereUniqueInput;
};

export type MutationPublishPoweredBySectionArgs = {
  to?: Array<Stage>;
  where: PoweredBySectionWhereUniqueInput;
};

export type MutationPublishPupsPresaleCommunityArgs = {
  to?: Array<Stage>;
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type MutationPublishRoadmapArgs = {
  to?: Array<Stage>;
  where: RoadmapWhereUniqueInput;
};

export type MutationPublishTeamSectionArgs = {
  to?: Array<Stage>;
  where: TeamSectionWhereUniqueInput;
};

export type MutationSchedulePublishAlphaNftCollectionTierArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type MutationSchedulePublishAlphaSharesInformationArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: AlphaSharesInformationWhereUniqueInput;
};

export type MutationSchedulePublishAssetArgs = {
  locales?: InputMaybe<Array<Locale>>;
  publishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: AssetWhereUniqueInput;
  withDefaultLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationSchedulePublishChainArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: ChainWhereUniqueInput;
};

export type MutationSchedulePublishCollectionCreatorArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationSchedulePublishCtaSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationSchedulePublishFaqSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationSchedulePublishFeaturedCollectionGroupArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationSchedulePublishFeaturedCreatorGroupArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationSchedulePublishGettingStartedSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: GettingStartedSectionWhereUniqueInput;
};

export type MutationSchedulePublishInfoCardsSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: InfoCardsSectionWhereUniqueInput;
};

export type MutationSchedulePublishLandingPageArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
};

export type MutationSchedulePublishMarketplaceAnnouncementBannerArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MutationSchedulePublishMarketplaceContractArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MarketplaceContractWhereUniqueInput;
};

export type MutationSchedulePublishMarketplacePageArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MarketplacePageWhereUniqueInput;
};

export type MutationSchedulePublishMenuArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationSchedulePublishMiningBtcSupplyDemandModelSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningContainerArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationSchedulePublishMiningHeaderNoticeArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftlpPageHeroSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftAccessSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftAdvantageSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftAntimonyAdvantageSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftComparisonTableArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftEligibilitySectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftProfitRoiSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftTeamSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftWhitelistManualEntryArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MutationSchedulePublishMiningNftfaqSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MutationSchedulePublishMintingCurrencyArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationSchedulePublishMintingProjectArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationSchedulePublishMintingStatArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MintingStatWhereUniqueInput;
};

export type MutationSchedulePublishMultiChainMintingProjectArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationSchedulePublishNftCategoryDisplayArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: NftCategoryDisplayWhereUniqueInput;
};

export type MutationSchedulePublishNftCollectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationSchedulePublishPageArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: PageWhereUniqueInput;
};

export type MutationSchedulePublishPageHeroArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: PageHeroWhereUniqueInput;
};

export type MutationSchedulePublishPartnerSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: PartnerSectionWhereUniqueInput;
};

export type MutationSchedulePublishPerksSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: PerksSectionWhereUniqueInput;
};

export type MutationSchedulePublishPoweredBySectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: PoweredBySectionWhereUniqueInput;
};

export type MutationSchedulePublishPupsPresaleCommunityArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type MutationSchedulePublishRoadmapArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: RoadmapWhereUniqueInput;
};

export type MutationSchedulePublishTeamSectionArgs = {
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  to?: Array<Stage>;
  where: TeamSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishAlphaNftCollectionTierArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type MutationScheduleUnpublishAlphaSharesInformationArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: AlphaSharesInformationWhereUniqueInput;
};

export type MutationScheduleUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where: AssetWhereUniqueInput;
};

export type MutationScheduleUnpublishChainArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: ChainWhereUniqueInput;
};

export type MutationScheduleUnpublishCollectionCreatorArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationScheduleUnpublishCtaSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishFaqSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishFeaturedCollectionGroupArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishFeaturedCreatorGroupArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationScheduleUnpublishGettingStartedSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: GettingStartedSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishInfoCardsSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: InfoCardsSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishLandingPageArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: LandingPageWhereUniqueInput;
};

export type MutationScheduleUnpublishMarketplaceAnnouncementBannerArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MutationScheduleUnpublishMarketplaceContractArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MarketplaceContractWhereUniqueInput;
};

export type MutationScheduleUnpublishMarketplacePageArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MarketplacePageWhereUniqueInput;
};

export type MutationScheduleUnpublishMenuArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MenuWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningBtcSupplyDemandModelSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningContainerArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningHeaderNoticeArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftlpPageHeroSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftAccessSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftAdvantageSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftAntimonyAdvantageSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftComparisonTableArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftEligibilitySectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftProfitRoiSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftTeamSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftWhitelistManualEntryArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MutationScheduleUnpublishMiningNftfaqSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishMintingCurrencyArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationScheduleUnpublishMintingProjectArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationScheduleUnpublishMintingStatArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MintingStatWhereUniqueInput;
};

export type MutationScheduleUnpublishMultiChainMintingProjectArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationScheduleUnpublishNftCategoryDisplayArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: NftCategoryDisplayWhereUniqueInput;
};

export type MutationScheduleUnpublishNftCollectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationScheduleUnpublishPageArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: PageWhereUniqueInput;
};

export type MutationScheduleUnpublishPageHeroArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: PageHeroWhereUniqueInput;
};

export type MutationScheduleUnpublishPartnerSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: PartnerSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishPerksSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: PerksSectionWhereUniqueInput;
};

export type MutationScheduleUnpublishPoweredBySectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: PoweredBySectionWhereUniqueInput;
};

export type MutationScheduleUnpublishPupsPresaleCommunityArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type MutationScheduleUnpublishRoadmapArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: RoadmapWhereUniqueInput;
};

export type MutationScheduleUnpublishTeamSectionArgs = {
  from?: Array<Stage>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  releaseId?: InputMaybe<Scalars["String"]["input"]>;
  where: TeamSectionWhereUniqueInput;
};

export type MutationUnpublishAlphaNftCollectionTierArgs = {
  from?: Array<Stage>;
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type MutationUnpublishAlphaSharesInformationArgs = {
  from?: Array<Stage>;
  where: AlphaSharesInformationWhereUniqueInput;
};

export type MutationUnpublishAssetArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where: AssetWhereUniqueInput;
};

export type MutationUnpublishChainArgs = {
  from?: Array<Stage>;
  where: ChainWhereUniqueInput;
};

export type MutationUnpublishCollectionCreatorArgs = {
  from?: Array<Stage>;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationUnpublishCtaSectionArgs = {
  from?: Array<Stage>;
  where: CtaSectionWhereUniqueInput;
};

export type MutationUnpublishFaqSectionArgs = {
  from?: Array<Stage>;
  where: FaqSectionWhereUniqueInput;
};

export type MutationUnpublishFeaturedCollectionGroupArgs = {
  from?: Array<Stage>;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationUnpublishFeaturedCreatorGroupArgs = {
  from?: Array<Stage>;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationUnpublishGettingStartedSectionArgs = {
  from?: Array<Stage>;
  where: GettingStartedSectionWhereUniqueInput;
};

export type MutationUnpublishInfoCardsSectionArgs = {
  from?: Array<Stage>;
  where: InfoCardsSectionWhereUniqueInput;
};

export type MutationUnpublishLandingPageArgs = {
  from?: Array<Stage>;
  where: LandingPageWhereUniqueInput;
};

export type MutationUnpublishManyAlphaNftCollectionTiersArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationUnpublishManyAlphaNftCollectionTiersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationUnpublishManyAlphaSharesInformationsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationUnpublishManyAlphaSharesInformationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationUnpublishManyAssetsArgs = {
  from?: Array<Stage>;
  locales?: InputMaybe<Array<Locale>>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  unpublishBase?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUnpublishManyChainsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUnpublishManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUnpublishManyCollectionCreatorsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUnpublishManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUnpublishManyCtaSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUnpublishManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUnpublishManyFaqSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUnpublishManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCollectionGroupsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCreatorGroupsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUnpublishManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUnpublishManyGettingStartedSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationUnpublishManyGettingStartedSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationUnpublishManyInfoCardsSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationUnpublishManyInfoCardsSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationUnpublishManyLandingPagesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUnpublishManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUnpublishManyMarketplaceAnnouncementBannersArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
};

export type MutationUnpublishManyMarketplaceAnnouncementBannersConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
  };

export type MutationUnpublishManyMarketplaceContractsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationUnpublishManyMarketplaceContractsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationUnpublishManyMarketplacePagesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationUnpublishManyMarketplacePagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationUnpublishManyMenusArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUnpublishManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUnpublishManyMiningBtcSupplyDemandModelSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningBtcSupplyDemandModelSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
  };

export type MutationUnpublishManyMiningContainersArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUnpublishManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUnpublishManyMiningHeaderNoticesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUnpublishManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUnpublishManyMiningNftlpPageHeroSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftlpPageHeroSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftAccessSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftAccessSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftAdvantageSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftAdvantageSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftAntimonyAdvantageSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftAntimonyAdvantageSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
  };

export type MutationUnpublishManyMiningNftComparisonTablesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationUnpublishManyMiningNftComparisonTablesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationUnpublishManyMiningNftEligibilitySectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftEligibilitySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftProfitRoiSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftProfitRoiSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftTeamSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftWhitelistManualEntriesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
};

export type MutationUnpublishManyMiningNftWhitelistManualEntriesConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    from?: Array<Stage>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    stage?: InputMaybe<Stage>;
    where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
  };

export type MutationUnpublishManyMiningNftfaqSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationUnpublishManyMiningNftfaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationUnpublishManyMintingCurrenciesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUnpublishManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUnpublishManyMintingProjectsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUnpublishManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUnpublishManyMintingStatsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUnpublishManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUnpublishManyMultiChainMintingProjectsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUnpublishManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUnpublishManyNftCategoryDisplaysArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationUnpublishManyNftCategoryDisplaysConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationUnpublishManyNftCollectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUnpublishManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUnpublishManyPageHeroesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationUnpublishManyPageHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationUnpublishManyPagesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUnpublishManyPartnerSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationUnpublishManyPartnerSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationUnpublishManyPerksSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationUnpublishManyPerksSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationUnpublishManyPoweredBySectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationUnpublishManyPoweredBySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationUnpublishManyPupsPresaleCommunitiesArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationUnpublishManyPupsPresaleCommunitiesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationUnpublishManyRoadmapsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationUnpublishManyRoadmapsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationUnpublishManyTeamSectionsArgs = {
  from?: Array<Stage>;
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationUnpublishManyTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  from?: Array<Stage>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: InputMaybe<Stage>;
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationUnpublishMarketplaceAnnouncementBannerArgs = {
  from?: Array<Stage>;
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MutationUnpublishMarketplaceContractArgs = {
  from?: Array<Stage>;
  where: MarketplaceContractWhereUniqueInput;
};

export type MutationUnpublishMarketplacePageArgs = {
  from?: Array<Stage>;
  where: MarketplacePageWhereUniqueInput;
};

export type MutationUnpublishMenuArgs = {
  from?: Array<Stage>;
  where: MenuWhereUniqueInput;
};

export type MutationUnpublishMiningBtcSupplyDemandModelSectionArgs = {
  from?: Array<Stage>;
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type MutationUnpublishMiningContainerArgs = {
  from?: Array<Stage>;
  where: MiningContainerWhereUniqueInput;
};

export type MutationUnpublishMiningHeaderNoticeArgs = {
  from?: Array<Stage>;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationUnpublishMiningNftlpPageHeroSectionArgs = {
  from?: Array<Stage>;
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MutationUnpublishMiningNftAccessSectionArgs = {
  from?: Array<Stage>;
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MutationUnpublishMiningNftAdvantageSectionArgs = {
  from?: Array<Stage>;
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MutationUnpublishMiningNftAntimonyAdvantageSectionArgs = {
  from?: Array<Stage>;
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type MutationUnpublishMiningNftComparisonTableArgs = {
  from?: Array<Stage>;
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MutationUnpublishMiningNftEligibilitySectionArgs = {
  from?: Array<Stage>;
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MutationUnpublishMiningNftProfitRoiSectionArgs = {
  from?: Array<Stage>;
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MutationUnpublishMiningNftTeamSectionArgs = {
  from?: Array<Stage>;
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MutationUnpublishMiningNftWhitelistManualEntryArgs = {
  from?: Array<Stage>;
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MutationUnpublishMiningNftfaqSectionArgs = {
  from?: Array<Stage>;
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MutationUnpublishMintingCurrencyArgs = {
  from?: Array<Stage>;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationUnpublishMintingProjectArgs = {
  from?: Array<Stage>;
  where: MintingProjectWhereUniqueInput;
};

export type MutationUnpublishMintingStatArgs = {
  from?: Array<Stage>;
  where: MintingStatWhereUniqueInput;
};

export type MutationUnpublishMultiChainMintingProjectArgs = {
  from?: Array<Stage>;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationUnpublishNftCategoryDisplayArgs = {
  from?: Array<Stage>;
  where: NftCategoryDisplayWhereUniqueInput;
};

export type MutationUnpublishNftCollectionArgs = {
  from?: Array<Stage>;
  where: NftCollectionWhereUniqueInput;
};

export type MutationUnpublishPageArgs = {
  from?: Array<Stage>;
  where: PageWhereUniqueInput;
};

export type MutationUnpublishPageHeroArgs = {
  from?: Array<Stage>;
  where: PageHeroWhereUniqueInput;
};

export type MutationUnpublishPartnerSectionArgs = {
  from?: Array<Stage>;
  where: PartnerSectionWhereUniqueInput;
};

export type MutationUnpublishPerksSectionArgs = {
  from?: Array<Stage>;
  where: PerksSectionWhereUniqueInput;
};

export type MutationUnpublishPoweredBySectionArgs = {
  from?: Array<Stage>;
  where: PoweredBySectionWhereUniqueInput;
};

export type MutationUnpublishPupsPresaleCommunityArgs = {
  from?: Array<Stage>;
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type MutationUnpublishRoadmapArgs = {
  from?: Array<Stage>;
  where: RoadmapWhereUniqueInput;
};

export type MutationUnpublishTeamSectionArgs = {
  from?: Array<Stage>;
  where: TeamSectionWhereUniqueInput;
};

export type MutationUpdateAlphaNftCollectionTierArgs = {
  data: AlphaNftCollectionTierUpdateInput;
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type MutationUpdateAlphaSharesInformationArgs = {
  data: AlphaSharesInformationUpdateInput;
  where: AlphaSharesInformationWhereUniqueInput;
};

export type MutationUpdateAssetArgs = {
  data: AssetUpdateInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpdateChainArgs = {
  data: ChainUpdateInput;
  where: ChainWhereUniqueInput;
};

export type MutationUpdateCollectionCreatorArgs = {
  data: CollectionCreatorUpdateInput;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationUpdateCtaSectionArgs = {
  data: CtaSectionUpdateInput;
  where: CtaSectionWhereUniqueInput;
};

export type MutationUpdateFaqSectionArgs = {
  data: FaqSectionUpdateInput;
  where: FaqSectionWhereUniqueInput;
};

export type MutationUpdateFeaturedCollectionGroupArgs = {
  data: FeaturedCollectionGroupUpdateInput;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationUpdateFeaturedCreatorGroupArgs = {
  data: FeaturedCreatorGroupUpdateInput;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationUpdateGettingStartedSectionArgs = {
  data: GettingStartedSectionUpdateInput;
  where: GettingStartedSectionWhereUniqueInput;
};

export type MutationUpdateInfoCardsSectionArgs = {
  data: InfoCardsSectionUpdateInput;
  where: InfoCardsSectionWhereUniqueInput;
};

export type MutationUpdateLandingPageArgs = {
  data: LandingPageUpdateInput;
  where: LandingPageWhereUniqueInput;
};

export type MutationUpdateManyAlphaNftCollectionTiersArgs = {
  data: AlphaNftCollectionTierUpdateManyInput;
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationUpdateManyAlphaNftCollectionTiersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: AlphaNftCollectionTierUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlphaNftCollectionTierManyWhereInput>;
};

export type MutationUpdateManyAlphaSharesInformationsArgs = {
  data: AlphaSharesInformationUpdateManyInput;
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationUpdateManyAlphaSharesInformationsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: AlphaSharesInformationUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AlphaSharesInformationManyWhereInput>;
};

export type MutationUpdateManyAssetsArgs = {
  data: AssetUpdateManyInput;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: AssetUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetManyWhereInput>;
};

export type MutationUpdateManyChainsArgs = {
  data: ChainUpdateManyInput;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUpdateManyChainsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: ChainUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainManyWhereInput>;
};

export type MutationUpdateManyCollectionCreatorsArgs = {
  data: CollectionCreatorUpdateManyInput;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUpdateManyCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: CollectionCreatorUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CollectionCreatorManyWhereInput>;
};

export type MutationUpdateManyCtaSectionsArgs = {
  data: CtaSectionUpdateManyInput;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUpdateManyCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: CtaSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<CtaSectionManyWhereInput>;
};

export type MutationUpdateManyFaqSectionsArgs = {
  data: FaqSectionUpdateManyInput;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUpdateManyFaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: FaqSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionManyWhereInput>;
};

export type MutationUpdateManyFeaturedCollectionGroupsArgs = {
  data: FeaturedCollectionGroupUpdateManyInput;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUpdateManyFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: FeaturedCollectionGroupUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCollectionGroupManyWhereInput>;
};

export type MutationUpdateManyFeaturedCreatorGroupsArgs = {
  data: FeaturedCreatorGroupUpdateManyInput;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUpdateManyFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: FeaturedCreatorGroupUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FeaturedCreatorGroupManyWhereInput>;
};

export type MutationUpdateManyGettingStartedSectionsArgs = {
  data: GettingStartedSectionUpdateManyInput;
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationUpdateManyGettingStartedSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: GettingStartedSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<GettingStartedSectionManyWhereInput>;
};

export type MutationUpdateManyInfoCardsSectionsArgs = {
  data: InfoCardsSectionUpdateManyInput;
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationUpdateManyInfoCardsSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: InfoCardsSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<InfoCardsSectionManyWhereInput>;
};

export type MutationUpdateManyLandingPagesArgs = {
  data: LandingPageUpdateManyInput;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUpdateManyLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: LandingPageUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LandingPageManyWhereInput>;
};

export type MutationUpdateManyMarketplaceAnnouncementBannersArgs = {
  data: MarketplaceAnnouncementBannerUpdateManyInput;
  where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
};

export type MutationUpdateManyMarketplaceAnnouncementBannersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MarketplaceAnnouncementBannerUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MarketplaceAnnouncementBannerManyWhereInput>;
};

export type MutationUpdateManyMarketplaceContractsArgs = {
  data: MarketplaceContractUpdateManyInput;
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationUpdateManyMarketplaceContractsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MarketplaceContractUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MarketplaceContractManyWhereInput>;
};

export type MutationUpdateManyMarketplacePagesArgs = {
  data: MarketplacePageUpdateManyInput;
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationUpdateManyMarketplacePagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MarketplacePageUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MarketplacePageManyWhereInput>;
};

export type MutationUpdateManyMenusArgs = {
  data: MenuUpdateManyInput;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUpdateManyMenusConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MenuUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MenuManyWhereInput>;
};

export type MutationUpdateManyMiningBtcSupplyDemandModelSectionsArgs = {
  data: MiningBtcSupplyDemandModelSectionUpdateManyInput;
  where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
};

export type MutationUpdateManyMiningBtcSupplyDemandModelSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    data: MiningBtcSupplyDemandModelSectionUpdateManyInput;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<MiningBtcSupplyDemandModelSectionManyWhereInput>;
  };

export type MutationUpdateManyMiningContainersArgs = {
  data: MiningContainerUpdateManyInput;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUpdateManyMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningContainerUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningContainerManyWhereInput>;
};

export type MutationUpdateManyMiningHeaderNoticesArgs = {
  data: MiningHeaderNoticeUpdateManyInput;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUpdateManyMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningHeaderNoticeUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningHeaderNoticeManyWhereInput>;
};

export type MutationUpdateManyMiningNftlpPageHeroSectionsArgs = {
  data: MiningNftlpPageHeroSectionUpdateManyInput;
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftlpPageHeroSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftlpPageHeroSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftlpPageHeroSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftAccessSectionsArgs = {
  data: MiningNftAccessSectionUpdateManyInput;
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftAccessSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftAccessSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftAccessSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftAdvantageSectionsArgs = {
  data: MiningNftAdvantageSectionUpdateManyInput;
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftAdvantageSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftAdvantageSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftAdvantageSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftAntimonyAdvantageSectionsArgs = {
  data: MiningNftAntimonyAdvantageSectionUpdateManyInput;
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftAntimonyAdvantageSectionsConnectionArgs =
  {
    after?: InputMaybe<Scalars["ID"]["input"]>;
    before?: InputMaybe<Scalars["ID"]["input"]>;
    data: MiningNftAntimonyAdvantageSectionUpdateManyInput;
    first?: InputMaybe<Scalars["Int"]["input"]>;
    last?: InputMaybe<Scalars["Int"]["input"]>;
    skip?: InputMaybe<Scalars["Int"]["input"]>;
    where?: InputMaybe<MiningNftAntimonyAdvantageSectionManyWhereInput>;
  };

export type MutationUpdateManyMiningNftComparisonTablesArgs = {
  data: MiningNftComparisonTableUpdateManyInput;
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationUpdateManyMiningNftComparisonTablesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftComparisonTableUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftComparisonTableManyWhereInput>;
};

export type MutationUpdateManyMiningNftEligibilitySectionsArgs = {
  data: MiningNftEligibilitySectionUpdateManyInput;
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftEligibilitySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftEligibilitySectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftEligibilitySectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftProfitRoiSectionsArgs = {
  data: MiningNftProfitRoiSectionUpdateManyInput;
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftProfitRoiSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftProfitRoiSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftProfitRoiSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftTeamSectionsArgs = {
  data: MiningNftTeamSectionUpdateManyInput;
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftTeamSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftTeamSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftWhitelistManualEntriesArgs = {
  data: MiningNftWhitelistManualEntryUpdateManyInput;
  where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
};

export type MutationUpdateManyMiningNftWhitelistManualEntriesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftWhitelistManualEntryUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftWhitelistManualEntryManyWhereInput>;
};

export type MutationUpdateManyMiningNftfaqSectionsArgs = {
  data: MiningNftfaqSectionUpdateManyInput;
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationUpdateManyMiningNftfaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MiningNftfaqSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MiningNftfaqSectionManyWhereInput>;
};

export type MutationUpdateManyMintingCurrenciesArgs = {
  data: MintingCurrencyUpdateManyInput;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUpdateManyMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MintingCurrencyUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingCurrencyManyWhereInput>;
};

export type MutationUpdateManyMintingProjectsArgs = {
  data: MintingProjectUpdateManyInput;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUpdateManyMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MintingProjectUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingProjectManyWhereInput>;
};

export type MutationUpdateManyMintingStatsArgs = {
  data: MintingStatUpdateManyInput;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUpdateManyMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MintingStatUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MintingStatManyWhereInput>;
};

export type MutationUpdateManyMultiChainMintingProjectsArgs = {
  data: MultiChainMintingProjectUpdateManyInput;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUpdateManyMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: MultiChainMintingProjectUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<MultiChainMintingProjectManyWhereInput>;
};

export type MutationUpdateManyNftCategoryDisplaysArgs = {
  data: NftCategoryDisplayUpdateManyInput;
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationUpdateManyNftCategoryDisplaysConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: NftCategoryDisplayUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCategoryDisplayManyWhereInput>;
};

export type MutationUpdateManyNftCollectionsArgs = {
  data: NftCollectionUpdateManyInput;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUpdateManyNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: NftCollectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionManyWhereInput>;
};

export type MutationUpdateManyPageHeroesArgs = {
  data: PageHeroUpdateManyInput;
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationUpdateManyPageHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: PageHeroUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageHeroManyWhereInput>;
};

export type MutationUpdateManyPagesArgs = {
  data: PageUpdateManyInput;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyPagesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: PageUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PageManyWhereInput>;
};

export type MutationUpdateManyPartnerSectionsArgs = {
  data: PartnerSectionUpdateManyInput;
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationUpdateManyPartnerSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: PartnerSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PartnerSectionManyWhereInput>;
};

export type MutationUpdateManyPerksSectionsArgs = {
  data: PerksSectionUpdateManyInput;
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationUpdateManyPerksSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: PerksSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PerksSectionManyWhereInput>;
};

export type MutationUpdateManyPoweredBySectionsArgs = {
  data: PoweredBySectionUpdateManyInput;
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationUpdateManyPoweredBySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: PoweredBySectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PoweredBySectionManyWhereInput>;
};

export type MutationUpdateManyPupsPresaleCommunitiesArgs = {
  data: PupsPresaleCommunityUpdateManyInput;
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationUpdateManyPupsPresaleCommunitiesConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: PupsPresaleCommunityUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<PupsPresaleCommunityManyWhereInput>;
};

export type MutationUpdateManyRoadmapsArgs = {
  data: RoadmapUpdateManyInput;
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationUpdateManyRoadmapsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: RoadmapUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RoadmapManyWhereInput>;
};

export type MutationUpdateManyTeamSectionsArgs = {
  data: TeamSectionUpdateManyInput;
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationUpdateManyTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["ID"]["input"]>;
  before?: InputMaybe<Scalars["ID"]["input"]>;
  data: TeamSectionUpdateManyInput;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TeamSectionManyWhereInput>;
};

export type MutationUpdateMarketplaceAnnouncementBannerArgs = {
  data: MarketplaceAnnouncementBannerUpdateInput;
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MutationUpdateMarketplaceContractArgs = {
  data: MarketplaceContractUpdateInput;
  where: MarketplaceContractWhereUniqueInput;
};

export type MutationUpdateMarketplacePageArgs = {
  data: MarketplacePageUpdateInput;
  where: MarketplacePageWhereUniqueInput;
};

export type MutationUpdateMenuArgs = {
  data: MenuUpdateInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpdateMiningBtcSupplyDemandModelSectionArgs = {
  data: MiningBtcSupplyDemandModelSectionUpdateInput;
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type MutationUpdateMiningContainerArgs = {
  data: MiningContainerUpdateInput;
  where: MiningContainerWhereUniqueInput;
};

export type MutationUpdateMiningHeaderNoticeArgs = {
  data: MiningHeaderNoticeUpdateInput;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationUpdateMiningNftlpPageHeroSectionArgs = {
  data: MiningNftlpPageHeroSectionUpdateInput;
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MutationUpdateMiningNftAccessSectionArgs = {
  data: MiningNftAccessSectionUpdateInput;
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MutationUpdateMiningNftAdvantageSectionArgs = {
  data: MiningNftAdvantageSectionUpdateInput;
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MutationUpdateMiningNftAntimonyAdvantageSectionArgs = {
  data: MiningNftAntimonyAdvantageSectionUpdateInput;
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type MutationUpdateMiningNftComparisonTableArgs = {
  data: MiningNftComparisonTableUpdateInput;
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MutationUpdateMiningNftEligibilitySectionArgs = {
  data: MiningNftEligibilitySectionUpdateInput;
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MutationUpdateMiningNftProfitRoiSectionArgs = {
  data: MiningNftProfitRoiSectionUpdateInput;
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MutationUpdateMiningNftTeamSectionArgs = {
  data: MiningNftTeamSectionUpdateInput;
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MutationUpdateMiningNftWhitelistManualEntryArgs = {
  data: MiningNftWhitelistManualEntryUpdateInput;
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MutationUpdateMiningNftfaqSectionArgs = {
  data: MiningNftfaqSectionUpdateInput;
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MutationUpdateMintingCurrencyArgs = {
  data: MintingCurrencyUpdateInput;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationUpdateMintingProjectArgs = {
  data: MintingProjectUpdateInput;
  where: MintingProjectWhereUniqueInput;
};

export type MutationUpdateMintingStatArgs = {
  data: MintingStatUpdateInput;
  where: MintingStatWhereUniqueInput;
};

export type MutationUpdateMultiChainMintingProjectArgs = {
  data: MultiChainMintingProjectUpdateInput;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationUpdateNftCategoryDisplayArgs = {
  data: NftCategoryDisplayUpdateInput;
  where: NftCategoryDisplayWhereUniqueInput;
};

export type MutationUpdateNftCollectionArgs = {
  data: NftCollectionUpdateInput;
  where: NftCollectionWhereUniqueInput;
};

export type MutationUpdatePageArgs = {
  data: PageUpdateInput;
  where: PageWhereUniqueInput;
};

export type MutationUpdatePageHeroArgs = {
  data: PageHeroUpdateInput;
  where: PageHeroWhereUniqueInput;
};

export type MutationUpdatePartnerSectionArgs = {
  data: PartnerSectionUpdateInput;
  where: PartnerSectionWhereUniqueInput;
};

export type MutationUpdatePerksSectionArgs = {
  data: PerksSectionUpdateInput;
  where: PerksSectionWhereUniqueInput;
};

export type MutationUpdatePoweredBySectionArgs = {
  data: PoweredBySectionUpdateInput;
  where: PoweredBySectionWhereUniqueInput;
};

export type MutationUpdatePupsPresaleCommunityArgs = {
  data: PupsPresaleCommunityUpdateInput;
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type MutationUpdateRoadmapArgs = {
  data: RoadmapUpdateInput;
  where: RoadmapWhereUniqueInput;
};

export type MutationUpdateScheduledReleaseArgs = {
  data: ScheduledReleaseUpdateInput;
  where: ScheduledReleaseWhereUniqueInput;
};

export type MutationUpdateTeamSectionArgs = {
  data: TeamSectionUpdateInput;
  where: TeamSectionWhereUniqueInput;
};

export type MutationUpsertAlphaNftCollectionTierArgs = {
  upsert: AlphaNftCollectionTierUpsertInput;
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type MutationUpsertAlphaSharesInformationArgs = {
  upsert: AlphaSharesInformationUpsertInput;
  where: AlphaSharesInformationWhereUniqueInput;
};

export type MutationUpsertAssetArgs = {
  upsert: AssetUpsertInput;
  where: AssetWhereUniqueInput;
};

export type MutationUpsertChainArgs = {
  upsert: ChainUpsertInput;
  where: ChainWhereUniqueInput;
};

export type MutationUpsertCollectionCreatorArgs = {
  upsert: CollectionCreatorUpsertInput;
  where: CollectionCreatorWhereUniqueInput;
};

export type MutationUpsertCtaSectionArgs = {
  upsert: CtaSectionUpsertInput;
  where: CtaSectionWhereUniqueInput;
};

export type MutationUpsertFaqSectionArgs = {
  upsert: FaqSectionUpsertInput;
  where: FaqSectionWhereUniqueInput;
};

export type MutationUpsertFeaturedCollectionGroupArgs = {
  upsert: FeaturedCollectionGroupUpsertInput;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type MutationUpsertFeaturedCreatorGroupArgs = {
  upsert: FeaturedCreatorGroupUpsertInput;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type MutationUpsertGettingStartedSectionArgs = {
  upsert: GettingStartedSectionUpsertInput;
  where: GettingStartedSectionWhereUniqueInput;
};

export type MutationUpsertInfoCardsSectionArgs = {
  upsert: InfoCardsSectionUpsertInput;
  where: InfoCardsSectionWhereUniqueInput;
};

export type MutationUpsertLandingPageArgs = {
  upsert: LandingPageUpsertInput;
  where: LandingPageWhereUniqueInput;
};

export type MutationUpsertMarketplaceAnnouncementBannerArgs = {
  upsert: MarketplaceAnnouncementBannerUpsertInput;
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type MutationUpsertMarketplaceContractArgs = {
  upsert: MarketplaceContractUpsertInput;
  where: MarketplaceContractWhereUniqueInput;
};

export type MutationUpsertMarketplacePageArgs = {
  upsert: MarketplacePageUpsertInput;
  where: MarketplacePageWhereUniqueInput;
};

export type MutationUpsertMenuArgs = {
  upsert: MenuUpsertInput;
  where: MenuWhereUniqueInput;
};

export type MutationUpsertMiningBtcSupplyDemandModelSectionArgs = {
  upsert: MiningBtcSupplyDemandModelSectionUpsertInput;
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type MutationUpsertMiningContainerArgs = {
  upsert: MiningContainerUpsertInput;
  where: MiningContainerWhereUniqueInput;
};

export type MutationUpsertMiningHeaderNoticeArgs = {
  upsert: MiningHeaderNoticeUpsertInput;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type MutationUpsertMiningNftlpPageHeroSectionArgs = {
  upsert: MiningNftlpPageHeroSectionUpsertInput;
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type MutationUpsertMiningNftAccessSectionArgs = {
  upsert: MiningNftAccessSectionUpsertInput;
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type MutationUpsertMiningNftAdvantageSectionArgs = {
  upsert: MiningNftAdvantageSectionUpsertInput;
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type MutationUpsertMiningNftAntimonyAdvantageSectionArgs = {
  upsert: MiningNftAntimonyAdvantageSectionUpsertInput;
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type MutationUpsertMiningNftComparisonTableArgs = {
  upsert: MiningNftComparisonTableUpsertInput;
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type MutationUpsertMiningNftEligibilitySectionArgs = {
  upsert: MiningNftEligibilitySectionUpsertInput;
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type MutationUpsertMiningNftProfitRoiSectionArgs = {
  upsert: MiningNftProfitRoiSectionUpsertInput;
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type MutationUpsertMiningNftTeamSectionArgs = {
  upsert: MiningNftTeamSectionUpsertInput;
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type MutationUpsertMiningNftWhitelistManualEntryArgs = {
  upsert: MiningNftWhitelistManualEntryUpsertInput;
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type MutationUpsertMiningNftfaqSectionArgs = {
  upsert: MiningNftfaqSectionUpsertInput;
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type MutationUpsertMintingCurrencyArgs = {
  upsert: MintingCurrencyUpsertInput;
  where: MintingCurrencyWhereUniqueInput;
};

export type MutationUpsertMintingProjectArgs = {
  upsert: MintingProjectUpsertInput;
  where: MintingProjectWhereUniqueInput;
};

export type MutationUpsertMintingStatArgs = {
  upsert: MintingStatUpsertInput;
  where: MintingStatWhereUniqueInput;
};

export type MutationUpsertMultiChainMintingProjectArgs = {
  upsert: MultiChainMintingProjectUpsertInput;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type MutationUpsertNftCategoryDisplayArgs = {
  upsert: NftCategoryDisplayUpsertInput;
  where: NftCategoryDisplayWhereUniqueInput;
};

export type MutationUpsertNftCollectionArgs = {
  upsert: NftCollectionUpsertInput;
  where: NftCollectionWhereUniqueInput;
};

export type MutationUpsertPageArgs = {
  upsert: PageUpsertInput;
  where: PageWhereUniqueInput;
};

export type MutationUpsertPageHeroArgs = {
  upsert: PageHeroUpsertInput;
  where: PageHeroWhereUniqueInput;
};

export type MutationUpsertPartnerSectionArgs = {
  upsert: PartnerSectionUpsertInput;
  where: PartnerSectionWhereUniqueInput;
};

export type MutationUpsertPerksSectionArgs = {
  upsert: PerksSectionUpsertInput;
  where: PerksSectionWhereUniqueInput;
};

export type MutationUpsertPoweredBySectionArgs = {
  upsert: PoweredBySectionUpsertInput;
  where: PoweredBySectionWhereUniqueInput;
};

export type MutationUpsertPupsPresaleCommunityArgs = {
  upsert: PupsPresaleCommunityUpsertInput;
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type MutationUpsertRoadmapArgs = {
  upsert: RoadmapUpsertInput;
  where: RoadmapWhereUniqueInput;
};

export type MutationUpsertTeamSectionArgs = {
  upsert: TeamSectionUpsertInput;
  where: TeamSectionWhereUniqueInput;
};

export type NavigationItem = Entity & {
  __typename?: "NavigationItem";
  /**
   * This is required in order to handle rewrites in the system to connect the different apps together seamlessly for the user.
   *
   * For Rewards, Minting, Landing Page, select MarketplaceLegacy.
   *
   * For anything collection or marketplace related, select MarketplaceNext
   *
   * For external links, select Global
   */
  destinationAppId: AppId;
  externalLink?: Maybe<Scalars["String"]["output"]>;
  iconComponent?: Maybe<HeroMenuIcons>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  internalSlug?: Maybe<Scalars["String"]["output"]>;
  navigationItemText?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type NavigationItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: NavigationItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type NavigationItemConnection = {
  __typename?: "NavigationItemConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<NavigationItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type NavigationItemCreateInput = {
  destinationAppId: AppId;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
};

export type NavigationItemCreateManyInlineInput = {
  /** Create and connect multiple existing NavigationItem documents */
  create?: InputMaybe<Array<NavigationItemCreateInput>>;
};

export type NavigationItemCreateOneInlineInput = {
  /** Create and connect one NavigationItem document */
  create?: InputMaybe<NavigationItemCreateInput>;
};

export type NavigationItemCreateWithPositionInput = {
  /** Document to create */
  data: NavigationItemCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type NavigationItemEdge = {
  __typename?: "NavigationItemEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: NavigationItem;
};

/** Identifies documents */
export type NavigationItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  destinationAppId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  destinationAppId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  destinationAppId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  destinationAppId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  externalLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  externalLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  externalLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  externalLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  externalLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  externalLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  externalLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  /** All values that are contained in given list. */
  iconComponent_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconComponent_not?: InputMaybe<HeroMenuIcons>;
  /** All values that are not contained in given list. */
  iconComponent_not_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  internalSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  internalSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  internalSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  internalSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  internalSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  internalSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  internalSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  internalSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  navigationItemText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  navigationItemText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  navigationItemText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  navigationItemText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  navigationItemText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  navigationItemText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  navigationItemText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  navigationItemText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  navigationItemText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum NavigationItemOrderByInput {
  destinationAppId_ASC = "destinationAppId_ASC",
  destinationAppId_DESC = "destinationAppId_DESC",
  externalLink_ASC = "externalLink_ASC",
  externalLink_DESC = "externalLink_DESC",
  iconComponent_ASC = "iconComponent_ASC",
  iconComponent_DESC = "iconComponent_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  internalSlug_ASC = "internalSlug_ASC",
  internalSlug_DESC = "internalSlug_DESC",
  navigationItemText_ASC = "navigationItemText_ASC",
  navigationItemText_DESC = "navigationItemText_DESC",
}

export type NavigationItemParent = Menu;

export type NavigationItemParentConnectInput = {
  Menu?: InputMaybe<MenuConnectInput>;
};

export type NavigationItemParentCreateInput = {
  Menu?: InputMaybe<MenuCreateInput>;
};

export type NavigationItemParentCreateManyInlineInput = {
  /** Connect multiple existing NavigationItemParent documents */
  connect?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Create and connect multiple existing NavigationItemParent documents */
  create?: InputMaybe<Array<NavigationItemParentCreateInput>>;
};

export type NavigationItemParentCreateOneInlineInput = {
  /** Connect one existing NavigationItemParent document */
  connect?: InputMaybe<NavigationItemParentWhereUniqueInput>;
  /** Create and connect one NavigationItemParent document */
  create?: InputMaybe<NavigationItemParentCreateInput>;
};

export type NavigationItemParentUpdateInput = {
  Menu?: InputMaybe<MenuUpdateInput>;
};

export type NavigationItemParentUpdateManyInlineInput = {
  /** Connect multiple existing NavigationItemParent documents */
  connect?: InputMaybe<Array<NavigationItemParentConnectInput>>;
  /** Create and connect multiple NavigationItemParent documents */
  create?: InputMaybe<Array<NavigationItemParentCreateInput>>;
  /** Delete multiple NavigationItemParent documents */
  delete?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Disconnect multiple NavigationItemParent documents */
  disconnect?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing NavigationItemParent documents */
  set?: InputMaybe<Array<NavigationItemParentWhereUniqueInput>>;
  /** Update multiple NavigationItemParent documents */
  update?: InputMaybe<
    Array<NavigationItemParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple NavigationItemParent documents */
  upsert?: InputMaybe<
    Array<NavigationItemParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type NavigationItemParentUpdateManyWithNestedWhereInput = {
  Menu?: InputMaybe<MenuUpdateManyWithNestedWhereInput>;
};

export type NavigationItemParentUpdateOneInlineInput = {
  /** Connect existing NavigationItemParent document */
  connect?: InputMaybe<NavigationItemParentWhereUniqueInput>;
  /** Create and connect one NavigationItemParent document */
  create?: InputMaybe<NavigationItemParentCreateInput>;
  /** Delete currently connected NavigationItemParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected NavigationItemParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single NavigationItemParent document */
  update?: InputMaybe<NavigationItemParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NavigationItemParent document */
  upsert?: InputMaybe<NavigationItemParentUpsertWithNestedWhereUniqueInput>;
};

export type NavigationItemParentUpdateWithNestedWhereUniqueInput = {
  Menu?: InputMaybe<MenuUpdateWithNestedWhereUniqueInput>;
};

export type NavigationItemParentUpsertWithNestedWhereUniqueInput = {
  Menu?: InputMaybe<MenuUpsertWithNestedWhereUniqueInput>;
};

export type NavigationItemParentWhereInput = {
  Menu?: InputMaybe<MenuWhereInput>;
};

export type NavigationItemParentWhereUniqueInput = {
  Menu?: InputMaybe<MenuWhereUniqueInput>;
};

export type NavigationItemUpdateInput = {
  destinationAppId?: InputMaybe<AppId>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
};

export type NavigationItemUpdateManyInlineInput = {
  /** Create and connect multiple NavigationItem component instances */
  create?: InputMaybe<Array<NavigationItemCreateWithPositionInput>>;
  /** Delete multiple NavigationItem documents */
  delete?: InputMaybe<Array<NavigationItemWhereUniqueInput>>;
  /** Update multiple NavigationItem component instances */
  update?: InputMaybe<
    Array<NavigationItemUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple NavigationItem component instances */
  upsert?: InputMaybe<
    Array<NavigationItemUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type NavigationItemUpdateManyInput = {
  destinationAppId?: InputMaybe<AppId>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
};

export type NavigationItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NavigationItemUpdateManyInput;
  /** Document search */
  where: NavigationItemWhereInput;
};

export type NavigationItemUpdateOneInlineInput = {
  /** Create and connect one NavigationItem document */
  create?: InputMaybe<NavigationItemCreateInput>;
  /** Delete currently connected NavigationItem document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single NavigationItem document */
  update?: InputMaybe<NavigationItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NavigationItem document */
  upsert?: InputMaybe<NavigationItemUpsertWithNestedWhereUniqueInput>;
};

export type NavigationItemUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<NavigationItemUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: NavigationItemWhereUniqueInput;
};

export type NavigationItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NavigationItemUpdateInput;
  /** Unique document search */
  where: NavigationItemWhereUniqueInput;
};

export type NavigationItemUpsertInput = {
  /** Create document if it didn't exist */
  create: NavigationItemCreateInput;
  /** Update document if it exists */
  update: NavigationItemUpdateInput;
};

export type NavigationItemUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<NavigationItemUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: NavigationItemWhereUniqueInput;
};

export type NavigationItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NavigationItemUpsertInput;
  /** Unique document search */
  where: NavigationItemWhereUniqueInput;
};

/** Identifies documents */
export type NavigationItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NavigationItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  destinationAppId?: InputMaybe<AppId>;
  /** All values that are contained in given list. */
  destinationAppId_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  /** Any other value that exists and is not equal to the given value. */
  destinationAppId_not?: InputMaybe<AppId>;
  /** All values that are not contained in given list. */
  destinationAppId_not_in?: InputMaybe<Array<InputMaybe<AppId>>>;
  externalLink?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  externalLink_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  externalLink_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  externalLink_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  externalLink_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  externalLink_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  externalLink_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  externalLink_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  externalLink_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  externalLink_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  iconComponent?: InputMaybe<HeroMenuIcons>;
  /** All values that are contained in given list. */
  iconComponent_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  /** Any other value that exists and is not equal to the given value. */
  iconComponent_not?: InputMaybe<HeroMenuIcons>;
  /** All values that are not contained in given list. */
  iconComponent_not_in?: InputMaybe<Array<InputMaybe<HeroMenuIcons>>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  internalSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  internalSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  internalSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  internalSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  internalSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  internalSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  internalSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  internalSlug_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  internalSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  internalSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  navigationItemText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  navigationItemText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  navigationItemText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  navigationItemText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  navigationItemText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  navigationItemText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  navigationItemText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  navigationItemText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  navigationItemText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  navigationItemText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References NavigationItem record uniquely */
export type NavigationItemWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type NftCategoryDisplay = Entity &
  Node & {
    __typename?: "NftCategoryDisplay";
    categoryDescription?: Maybe<Scalars["String"]["output"]>;
    categoryDisplayTitle?: Maybe<Scalars["String"]["output"]>;
    chain?: Maybe<ChainIDs>;
    collectionCategory: CollectionCategories;
    collectionCategoryBanner?: Maybe<Asset>;
    collectionCategoryThumbnail?: Maybe<Asset>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<NftCategoryDisplay>;
    /** List of NftCategoryDisplay versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    nftCollections: Array<NftCollection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type NftCategoryDisplayCollectionCategoryBannerArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCategoryDisplayCollectionCategoryThumbnailArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCategoryDisplayCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCategoryDisplayDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type NftCategoryDisplayHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type NftCategoryDisplayNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type NftCategoryDisplayPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCategoryDisplayScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type NftCategoryDisplayUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCategoryDisplayConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: NftCategoryDisplayWhereUniqueInput;
};

/** A connection to a list of items. */
export type NftCategoryDisplayConnection = {
  __typename?: "NftCategoryDisplayConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<NftCategoryDisplayEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type NftCategoryDisplayCreateInput = {
  categoryDescription?: InputMaybe<Scalars["String"]["input"]>;
  categoryDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainIDs>;
  collectionCategory: CollectionCategories;
  collectionCategoryBanner?: InputMaybe<AssetCreateOneInlineInput>;
  collectionCategoryThumbnail?: InputMaybe<AssetCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  nftCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type NftCategoryDisplayCreateManyInlineInput = {
  /** Connect multiple existing NftCategoryDisplay documents */
  connect?: InputMaybe<Array<NftCategoryDisplayWhereUniqueInput>>;
  /** Create and connect multiple existing NftCategoryDisplay documents */
  create?: InputMaybe<Array<NftCategoryDisplayCreateInput>>;
};

export type NftCategoryDisplayCreateOneInlineInput = {
  /** Connect one existing NftCategoryDisplay document */
  connect?: InputMaybe<NftCategoryDisplayWhereUniqueInput>;
  /** Create and connect one NftCategoryDisplay document */
  create?: InputMaybe<NftCategoryDisplayCreateInput>;
};

/** An edge in a connection. */
export type NftCategoryDisplayEdge = {
  __typename?: "NftCategoryDisplayEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: NftCategoryDisplay;
};

/** Identifies documents */
export type NftCategoryDisplayManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCategoryDisplayWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCategoryDisplayWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCategoryDisplayWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  categoryDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  categoryDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  categoryDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  categoryDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  categoryDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  categoryDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  categoryDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  categoryDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  categoryDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  categoryDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  categoryDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  categoryDisplayTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  categoryDisplayTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  categoryDisplayTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  categoryDisplayTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  categoryDisplayTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  categoryDisplayTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  categoryDisplayTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  categoryDisplayTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  categoryDisplayTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainIDs>;
  /** All values that are contained in given list. */
  chain_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  /** Any other value that exists and is not equal to the given value. */
  chain_not?: InputMaybe<ChainIDs>;
  /** All values that are not contained in given list. */
  chain_not_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  collectionCategory?: InputMaybe<CollectionCategories>;
  collectionCategoryBanner?: InputMaybe<AssetWhereInput>;
  collectionCategoryThumbnail?: InputMaybe<AssetWhereInput>;
  /** All values that are contained in given list. */
  collectionCategory_in?: InputMaybe<Array<InputMaybe<CollectionCategories>>>;
  /** Any other value that exists and is not equal to the given value. */
  collectionCategory_not?: InputMaybe<CollectionCategories>;
  /** All values that are not contained in given list. */
  collectionCategory_not_in?: InputMaybe<
    Array<InputMaybe<CollectionCategories>>
  >;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<NftCategoryDisplayWhereStageInput>;
  documentInStages_none?: InputMaybe<NftCategoryDisplayWhereStageInput>;
  documentInStages_some?: InputMaybe<NftCategoryDisplayWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum NftCategoryDisplayOrderByInput {
  categoryDescription_ASC = "categoryDescription_ASC",
  categoryDescription_DESC = "categoryDescription_DESC",
  categoryDisplayTitle_ASC = "categoryDisplayTitle_ASC",
  categoryDisplayTitle_DESC = "categoryDisplayTitle_DESC",
  chain_ASC = "chain_ASC",
  chain_DESC = "chain_DESC",
  collectionCategory_ASC = "collectionCategory_ASC",
  collectionCategory_DESC = "collectionCategory_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type NftCategoryDisplayUpdateInput = {
  categoryDescription?: InputMaybe<Scalars["String"]["input"]>;
  categoryDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainIDs>;
  collectionCategory?: InputMaybe<CollectionCategories>;
  collectionCategoryBanner?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionCategoryThumbnail?: InputMaybe<AssetUpdateOneInlineInput>;
  nftCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
};

export type NftCategoryDisplayUpdateManyInlineInput = {
  /** Connect multiple existing NftCategoryDisplay documents */
  connect?: InputMaybe<Array<NftCategoryDisplayConnectInput>>;
  /** Create and connect multiple NftCategoryDisplay documents */
  create?: InputMaybe<Array<NftCategoryDisplayCreateInput>>;
  /** Delete multiple NftCategoryDisplay documents */
  delete?: InputMaybe<Array<NftCategoryDisplayWhereUniqueInput>>;
  /** Disconnect multiple NftCategoryDisplay documents */
  disconnect?: InputMaybe<Array<NftCategoryDisplayWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing NftCategoryDisplay documents */
  set?: InputMaybe<Array<NftCategoryDisplayWhereUniqueInput>>;
  /** Update multiple NftCategoryDisplay documents */
  update?: InputMaybe<
    Array<NftCategoryDisplayUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple NftCategoryDisplay documents */
  upsert?: InputMaybe<
    Array<NftCategoryDisplayUpsertWithNestedWhereUniqueInput>
  >;
};

export type NftCategoryDisplayUpdateManyInput = {
  categoryDescription?: InputMaybe<Scalars["String"]["input"]>;
  categoryDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainIDs>;
  collectionCategory?: InputMaybe<CollectionCategories>;
};

export type NftCategoryDisplayUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NftCategoryDisplayUpdateManyInput;
  /** Document search */
  where: NftCategoryDisplayWhereInput;
};

export type NftCategoryDisplayUpdateOneInlineInput = {
  /** Connect existing NftCategoryDisplay document */
  connect?: InputMaybe<NftCategoryDisplayWhereUniqueInput>;
  /** Create and connect one NftCategoryDisplay document */
  create?: InputMaybe<NftCategoryDisplayCreateInput>;
  /** Delete currently connected NftCategoryDisplay document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected NftCategoryDisplay document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single NftCategoryDisplay document */
  update?: InputMaybe<NftCategoryDisplayUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NftCategoryDisplay document */
  upsert?: InputMaybe<NftCategoryDisplayUpsertWithNestedWhereUniqueInput>;
};

export type NftCategoryDisplayUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NftCategoryDisplayUpdateInput;
  /** Unique document search */
  where: NftCategoryDisplayWhereUniqueInput;
};

export type NftCategoryDisplayUpsertInput = {
  /** Create document if it didn't exist */
  create: NftCategoryDisplayCreateInput;
  /** Update document if it exists */
  update: NftCategoryDisplayUpdateInput;
};

export type NftCategoryDisplayUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NftCategoryDisplayUpsertInput;
  /** Unique document search */
  where: NftCategoryDisplayWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type NftCategoryDisplayWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type NftCategoryDisplayWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCategoryDisplayWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCategoryDisplayWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCategoryDisplayWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  categoryDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  categoryDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  categoryDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  categoryDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  categoryDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  categoryDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  categoryDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  categoryDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  categoryDescription_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  categoryDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  categoryDisplayTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  categoryDisplayTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  categoryDisplayTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  categoryDisplayTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  categoryDisplayTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  categoryDisplayTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  categoryDisplayTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  categoryDisplayTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  categoryDisplayTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  categoryDisplayTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  chain?: InputMaybe<ChainIDs>;
  /** All values that are contained in given list. */
  chain_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  /** Any other value that exists and is not equal to the given value. */
  chain_not?: InputMaybe<ChainIDs>;
  /** All values that are not contained in given list. */
  chain_not_in?: InputMaybe<Array<InputMaybe<ChainIDs>>>;
  collectionCategory?: InputMaybe<CollectionCategories>;
  collectionCategoryBanner?: InputMaybe<AssetWhereInput>;
  collectionCategoryThumbnail?: InputMaybe<AssetWhereInput>;
  /** All values that are contained in given list. */
  collectionCategory_in?: InputMaybe<Array<InputMaybe<CollectionCategories>>>;
  /** Any other value that exists and is not equal to the given value. */
  collectionCategory_not?: InputMaybe<CollectionCategories>;
  /** All values that are not contained in given list. */
  collectionCategory_not_in?: InputMaybe<
    Array<InputMaybe<CollectionCategories>>
  >;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<NftCategoryDisplayWhereStageInput>;
  documentInStages_none?: InputMaybe<NftCategoryDisplayWhereStageInput>;
  documentInStages_some?: InputMaybe<NftCategoryDisplayWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  nftCollections_every?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_none?: InputMaybe<NftCollectionWhereInput>;
  nftCollections_some?: InputMaybe<NftCollectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type NftCategoryDisplayWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCategoryDisplayWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCategoryDisplayWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCategoryDisplayWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<NftCategoryDisplayWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References NftCategoryDisplay record uniquely */
export type NftCategoryDisplayWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type NftCollection = Entity &
  Node & {
    __typename?: "NftCollection";
    bundleName?: Maybe<Scalars["String"]["output"]>;
    bundledCollections: Array<NftCollection>;
    chain?: Maybe<Chain>;
    collectionBannerImage?: Maybe<Asset>;
    collectionCategories: Array<CollectionCategories>;
    collectionContractAddress: Scalars["String"]["output"];
    collectionCreator?: Maybe<CollectionCreator>;
    collectionDescription?: Maybe<Scalars["String"]["output"]>;
    collectionDiscordServer?: Maybe<Scalars["String"]["output"]>;
    collectionFeaturedImage: Array<Asset>;
    collectionHomePage?: Maybe<Scalars["String"]["output"]>;
    collectionInstagramProfile?: Maybe<Scalars["String"]["output"]>;
    collectionListingDate?: Maybe<Scalars["Date"]["output"]>;
    collectionMediumHome?: Maybe<Scalars["String"]["output"]>;
    /**
     * This field is for the amount in BPS - example, for 10% royalty, use:
     *
     * 1000
     *
     * for 5% royalty use:
     * 500
     */
    collectionRoyaltyFee?: Maybe<Scalars["Float"]["output"]>;
    collectionRoyaltyRecipientAddress?: Maybe<Scalars["String"]["output"]>;
    collectionThumbnail?: Maybe<Asset>;
    collectionTitle?: Maybe<Scalars["String"]["output"]>;
    collectionTotalSupply?: Maybe<Scalars["Int"]["output"]>;
    collectionTwitterProfile?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    currency?: Maybe<Currencies>;
    /** Get the document in other stages */
    documentInStages: Array<NftCollection>;
    /** List of NftCollection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    marketplaceContract?: Maybe<MarketplaceContract>;
    mintingProject?: Maybe<MintingProject>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    tradable: Scalars["Boolean"]["output"];
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type NftCollectionBundledCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type NftCollectionChainArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCollectionBannerImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCollectionCreatorArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCollectionFeaturedImageArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type NftCollectionCollectionThumbnailArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type NftCollectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type NftCollectionMarketplaceContractArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionMintingProjectArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type NftCollectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type NftCollectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: NftCollectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type NftCollectionConnection = {
  __typename?: "NftCollectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<NftCollectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type NftCollectionCreateInput = {
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections?: InputMaybe<NftCollectionCreateManyInlineInput>;
  chain?: InputMaybe<ChainCreateOneInlineInput>;
  cl6leuk5f0mtp01uocoza8dkd?: InputMaybe<NftCategoryDisplayCreateManyInlineInput>;
  clp8xmmiv2ckc01um6dgqfr9c?: InputMaybe<NftCollectionCreateManyInlineInput>;
  clp90v1ga2ol701t95b1qdth4?: InputMaybe<MultiChainMintingProjectCreateManyInlineInput>;
  clrntvrdl0h1d01kagy8uaje1?: InputMaybe<FeaturedCollectionGroupCreateManyInlineInput>;
  collectionBannerImage?: InputMaybe<AssetCreateOneInlineInput>;
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress: Scalars["String"]["input"];
  collectionCreator?: InputMaybe<CollectionCreatorCreateOneInlineInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage?: InputMaybe<AssetCreateManyInlineInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionThumbnail?: InputMaybe<AssetCreateOneInlineInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  currency?: InputMaybe<Currencies>;
  marketplaceContract?: InputMaybe<MarketplaceContractCreateOneInlineInput>;
  mintingProject?: InputMaybe<MintingProjectCreateOneInlineInput>;
  tradable: Scalars["Boolean"]["input"];
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type NftCollectionCreateManyInlineInput = {
  /** Connect multiple existing NftCollection documents */
  connect?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Create and connect multiple existing NftCollection documents */
  create?: InputMaybe<Array<NftCollectionCreateInput>>;
};

export type NftCollectionCreateOneInlineInput = {
  /** Connect one existing NftCollection document */
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  /** Create and connect one NftCollection document */
  create?: InputMaybe<NftCollectionCreateInput>;
};

/** An edge in a connection. */
export type NftCollectionEdge = {
  __typename?: "NftCollectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: NftCollection;
};

/** Identifies documents */
export type NftCollectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  bundleName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  bundleName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  bundleName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  bundleName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  bundleName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  bundleName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  bundleName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  bundleName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  bundleName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections_every?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_none?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_some?: InputMaybe<NftCollectionWhereInput>;
  chain?: InputMaybe<ChainWhereInput>;
  collectionBannerImage?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  collectionCategories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  collectionCategories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  collectionCategories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  collectionCategories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDiscordServer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDiscordServer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDiscordServer_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDiscordServer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDiscordServer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDiscordServer_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionDiscordServer_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDiscordServer_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDiscordServer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage_every?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_none?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_some?: InputMaybe<AssetWhereInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionHomePage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionHomePage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionHomePage_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionHomePage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionHomePage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionHomePage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionHomePage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionHomePage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionHomePage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionInstagramProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionInstagramProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionInstagramProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionInstagramProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionInstagramProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionInstagramProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionInstagramProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionInstagramProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionInstagramProfile_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  collectionListingDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionListingDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  collectionListingDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionListingDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  collectionListingDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionListingDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  collectionListingDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionMediumHome_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionMediumHome_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionMediumHome_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionMediumHome_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionMediumHome_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionMediumHome_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionMediumHome_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionMediumHome_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionMediumHome_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  collectionRoyaltyFee_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionRoyaltyFee_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  collectionRoyaltyFee_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionRoyaltyFee_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  collectionRoyaltyFee_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyFee_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  collectionRoyaltyFee_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionRoyaltyRecipientAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  collectionRoyaltyRecipientAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  collectionRoyaltyRecipientAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyRecipientAddress_not?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not containing the given string. */
  collectionRoyaltyRecipientAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionRoyaltyRecipientAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionRoyaltyRecipientAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionRoyaltyRecipientAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionRoyaltyRecipientAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionThumbnail?: InputMaybe<AssetWhereInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  collectionTotalSupply_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionTotalSupply_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  collectionTotalSupply_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionTotalSupply_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  collectionTotalSupply_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionTotalSupply_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTotalSupply_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTwitterProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTwitterProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTwitterProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTwitterProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTwitterProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionTwitterProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionTwitterProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTwitterProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionTwitterProfile_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_none?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_some?: InputMaybe<NftCollectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  marketplaceContract?: InputMaybe<MarketplaceContractWhereInput>;
  mintingProject?: InputMaybe<MintingProjectWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  tradable_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum NftCollectionOrderByInput {
  bundleName_ASC = "bundleName_ASC",
  bundleName_DESC = "bundleName_DESC",
  collectionCategories_ASC = "collectionCategories_ASC",
  collectionCategories_DESC = "collectionCategories_DESC",
  collectionContractAddress_ASC = "collectionContractAddress_ASC",
  collectionContractAddress_DESC = "collectionContractAddress_DESC",
  collectionDescription_ASC = "collectionDescription_ASC",
  collectionDescription_DESC = "collectionDescription_DESC",
  collectionDiscordServer_ASC = "collectionDiscordServer_ASC",
  collectionDiscordServer_DESC = "collectionDiscordServer_DESC",
  collectionHomePage_ASC = "collectionHomePage_ASC",
  collectionHomePage_DESC = "collectionHomePage_DESC",
  collectionInstagramProfile_ASC = "collectionInstagramProfile_ASC",
  collectionInstagramProfile_DESC = "collectionInstagramProfile_DESC",
  collectionListingDate_ASC = "collectionListingDate_ASC",
  collectionListingDate_DESC = "collectionListingDate_DESC",
  collectionMediumHome_ASC = "collectionMediumHome_ASC",
  collectionMediumHome_DESC = "collectionMediumHome_DESC",
  collectionRoyaltyFee_ASC = "collectionRoyaltyFee_ASC",
  collectionRoyaltyFee_DESC = "collectionRoyaltyFee_DESC",
  collectionRoyaltyRecipientAddress_ASC = "collectionRoyaltyRecipientAddress_ASC",
  collectionRoyaltyRecipientAddress_DESC = "collectionRoyaltyRecipientAddress_DESC",
  collectionTitle_ASC = "collectionTitle_ASC",
  collectionTitle_DESC = "collectionTitle_DESC",
  collectionTotalSupply_ASC = "collectionTotalSupply_ASC",
  collectionTotalSupply_DESC = "collectionTotalSupply_DESC",
  collectionTwitterProfile_ASC = "collectionTwitterProfile_ASC",
  collectionTwitterProfile_DESC = "collectionTwitterProfile_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  currency_ASC = "currency_ASC",
  currency_DESC = "currency_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  tradable_ASC = "tradable_ASC",
  tradable_DESC = "tradable_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type NftCollectionUpdateInput = {
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  chain?: InputMaybe<ChainUpdateOneInlineInput>;
  cl6leuk5f0mtp01uocoza8dkd?: InputMaybe<NftCategoryDisplayUpdateManyInlineInput>;
  clp8xmmiv2ckc01um6dgqfr9c?: InputMaybe<NftCollectionUpdateManyInlineInput>;
  clp90v1ga2ol701t95b1qdth4?: InputMaybe<MultiChainMintingProjectUpdateManyInlineInput>;
  clrntvrdl0h1d01kagy8uaje1?: InputMaybe<FeaturedCollectionGroupUpdateManyInlineInput>;
  collectionBannerImage?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionCreator?: InputMaybe<CollectionCreatorUpdateOneInlineInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage?: InputMaybe<AssetUpdateManyInlineInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionThumbnail?: InputMaybe<AssetUpdateOneInlineInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Currencies>;
  marketplaceContract?: InputMaybe<MarketplaceContractUpdateOneInlineInput>;
  mintingProject?: InputMaybe<MintingProjectUpdateOneInlineInput>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NftCollectionUpdateManyInlineInput = {
  /** Connect multiple existing NftCollection documents */
  connect?: InputMaybe<Array<NftCollectionConnectInput>>;
  /** Create and connect multiple NftCollection documents */
  create?: InputMaybe<Array<NftCollectionCreateInput>>;
  /** Delete multiple NftCollection documents */
  delete?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Disconnect multiple NftCollection documents */
  disconnect?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing NftCollection documents */
  set?: InputMaybe<Array<NftCollectionWhereUniqueInput>>;
  /** Update multiple NftCollection documents */
  update?: InputMaybe<Array<NftCollectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple NftCollection documents */
  upsert?: InputMaybe<Array<NftCollectionUpsertWithNestedWhereUniqueInput>>;
};

export type NftCollectionUpdateManyInput = {
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  currency?: InputMaybe<Currencies>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type NftCollectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: NftCollectionUpdateManyInput;
  /** Document search */
  where: NftCollectionWhereInput;
};

export type NftCollectionUpdateOneInlineInput = {
  /** Connect existing NftCollection document */
  connect?: InputMaybe<NftCollectionWhereUniqueInput>;
  /** Create and connect one NftCollection document */
  create?: InputMaybe<NftCollectionCreateInput>;
  /** Delete currently connected NftCollection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected NftCollection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single NftCollection document */
  update?: InputMaybe<NftCollectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single NftCollection document */
  upsert?: InputMaybe<NftCollectionUpsertWithNestedWhereUniqueInput>;
};

export type NftCollectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: NftCollectionUpdateInput;
  /** Unique document search */
  where: NftCollectionWhereUniqueInput;
};

export type NftCollectionUpsertInput = {
  /** Create document if it didn't exist */
  create: NftCollectionCreateInput;
  /** Update document if it exists */
  update: NftCollectionUpdateInput;
};

export type NftCollectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: NftCollectionUpsertInput;
  /** Unique document search */
  where: NftCollectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type NftCollectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type NftCollectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCollectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  bundleName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  bundleName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  bundleName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  bundleName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  bundleName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  bundleName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  bundleName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  bundleName_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  bundleName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  bundleName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  bundledCollections_every?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_none?: InputMaybe<NftCollectionWhereInput>;
  bundledCollections_some?: InputMaybe<NftCollectionWhereInput>;
  chain?: InputMaybe<ChainWhereInput>;
  collectionBannerImage?: InputMaybe<AssetWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  collectionCategories?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  collectionCategories_contains_all?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  collectionCategories_contains_none?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  collectionCategories_contains_some?: InputMaybe<Array<CollectionCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  collectionCategories_not?: InputMaybe<Array<CollectionCategories>>;
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionContractAddress_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionContractAddress_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionContractAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionContractAddress_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionContractAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionContractAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionContractAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionContractAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionContractAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionCreator?: InputMaybe<CollectionCreatorWhereInput>;
  collectionDescription?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDescription_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDescription_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDescription_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDescription_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDescription_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDescription_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionDescription_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDescription_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDescription_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionDiscordServer?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionDiscordServer_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionDiscordServer_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionDiscordServer_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionDiscordServer_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionDiscordServer_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionDiscordServer_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionDiscordServer_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionDiscordServer_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionDiscordServer_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionFeaturedImage_every?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_none?: InputMaybe<AssetWhereInput>;
  collectionFeaturedImage_some?: InputMaybe<AssetWhereInput>;
  collectionHomePage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionHomePage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionHomePage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionHomePage_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionHomePage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionHomePage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionHomePage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionHomePage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionHomePage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionHomePage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionInstagramProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionInstagramProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionInstagramProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionInstagramProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionInstagramProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionInstagramProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionInstagramProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionInstagramProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionInstagramProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionInstagramProfile_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionListingDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  collectionListingDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionListingDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  collectionListingDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionListingDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  collectionListingDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionListingDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  collectionListingDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  collectionMediumHome?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionMediumHome_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionMediumHome_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionMediumHome_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionMediumHome_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionMediumHome_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionMediumHome_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionMediumHome_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionMediumHome_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionMediumHome_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionRoyaltyFee?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than the given value. */
  collectionRoyaltyFee_gt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionRoyaltyFee_gte?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are contained in given list. */
  collectionRoyaltyFee_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionRoyaltyFee_lt?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values less than or equal the given value. */
  collectionRoyaltyFee_lte?: InputMaybe<Scalars["Float"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyFee_not?: InputMaybe<Scalars["Float"]["input"]>;
  /** All values that are not contained in given list. */
  collectionRoyaltyFee_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Float"]["input"]>>
  >;
  collectionRoyaltyRecipientAddress?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionRoyaltyRecipientAddress_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values ending with the given string. */
  collectionRoyaltyRecipientAddress_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are contained in given list. */
  collectionRoyaltyRecipientAddress_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionRoyaltyRecipientAddress_not?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not containing the given string. */
  collectionRoyaltyRecipientAddress_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionRoyaltyRecipientAddress_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionRoyaltyRecipientAddress_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionRoyaltyRecipientAddress_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionRoyaltyRecipientAddress_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  collectionThumbnail?: InputMaybe<AssetWhereInput>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  collectionTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  collectionTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  collectionTotalSupply?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  collectionTotalSupply_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  collectionTotalSupply_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  collectionTotalSupply_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  collectionTotalSupply_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  collectionTotalSupply_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  collectionTotalSupply_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  collectionTotalSupply_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  collectionTwitterProfile?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  collectionTwitterProfile_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  collectionTwitterProfile_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  collectionTwitterProfile_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  collectionTwitterProfile_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  collectionTwitterProfile_not_contains?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values not ending with the given string */
  collectionTwitterProfile_not_ends_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values that are not contained in given list. */
  collectionTwitterProfile_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  collectionTwitterProfile_not_starts_with?: InputMaybe<
    Scalars["String"]["input"]
  >;
  /** All values starting with the given string. */
  collectionTwitterProfile_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  currency?: InputMaybe<Currencies>;
  /** All values that are contained in given list. */
  currency_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  /** Any other value that exists and is not equal to the given value. */
  currency_not?: InputMaybe<Currencies>;
  /** All values that are not contained in given list. */
  currency_not_in?: InputMaybe<Array<InputMaybe<Currencies>>>;
  documentInStages_every?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_none?: InputMaybe<NftCollectionWhereStageInput>;
  documentInStages_some?: InputMaybe<NftCollectionWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  marketplaceContract?: InputMaybe<MarketplaceContractWhereInput>;
  mintingProject?: InputMaybe<MintingProjectWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  tradable?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  tradable_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type NftCollectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<NftCollectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<NftCollectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<NftCollectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<NftCollectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References NftCollection record uniquely */
export type NftCollectionWhereUniqueInput = {
  collectionContractAddress?: InputMaybe<Scalars["String"]["input"]>;
  collectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** An object with an ID */
export type Node = {
  /** The id of the object. */
  id: Scalars["ID"]["output"];
  /** The Stage of an object */
  stage: Stage;
};

export type Page = Entity &
  Node & {
    __typename?: "Page";
    alphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    ctaSection?: Maybe<CtaSection>;
    /** Get the document in other stages */
    documentInStages: Array<Page>;
    fAQSection?: Maybe<FaqSection>;
    /** FAQ Sections */
    fAQSections: Array<FaqSection>;
    gettingStartedSection?: Maybe<GettingStartedSection>;
    /** List of Page versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    infoCardsSection?: Maybe<InfoCardsSection>;
    mintingStat?: Maybe<MintingStat>;
    pageHero?: Maybe<PageHero>;
    pageSlug?: Maybe<Scalars["String"]["output"]>;
    pageTitle?: Maybe<Scalars["String"]["output"]>;
    partnerSection?: Maybe<PartnerSection>;
    perksSection?: Maybe<PerksSection>;
    poweredBySection?: Maybe<PoweredBySection>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    teamSection?: Maybe<TeamSection>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type PageAlphaNftCollectionTierArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageCtaSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type PageFAqSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageFAqSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type PageGettingStartedSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type PageInfoCardsSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageMintingStatArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PagePageHeroArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PagePartnerSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PagePerksSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PagePoweredBySectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PagePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PageTeamSectionArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageConnection = {
  __typename?: "PageConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageCreateInput = {
  alphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierCreateOneInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  ctaSection?: InputMaybe<CtaSectionCreateOneInlineInput>;
  fAQSection?: InputMaybe<FaqSectionCreateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionCreateManyInlineInput>;
  gettingStartedSection?: InputMaybe<GettingStartedSectionCreateOneInlineInput>;
  infoCardsSection?: InputMaybe<InfoCardsSectionCreateOneInlineInput>;
  mintingStat?: InputMaybe<MintingStatCreateOneInlineInput>;
  pageHero?: InputMaybe<PageHeroCreateOneInlineInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  partnerSection?: InputMaybe<PartnerSectionCreateOneInlineInput>;
  perksSection?: InputMaybe<PerksSectionCreateOneInlineInput>;
  poweredBySection?: InputMaybe<PoweredBySectionCreateOneInlineInput>;
  teamSection?: InputMaybe<TeamSectionCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PageCreateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Create and connect multiple existing Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
};

export type PageCreateOneInlineInput = {
  /** Connect one existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
};

/** An edge in a connection. */
export type PageEdge = {
  __typename?: "PageEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Page;
};

export type PageHero = Entity &
  Node & {
    __typename?: "PageHero";
    backgroundImage?: Maybe<Asset>;
    buttons: Array<PageHerobuttonsUnion>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<PageHero>;
    flexibleText: Array<PageHeroflexibleTextUnion>;
    heroImages: Array<Asset>;
    /** List of PageHero versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    mainHeading?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    subHeading?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
    valueProps: Array<PageHerovaluePropsUnion>;
  };

export type PageHeroBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageHeroButtonsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageHeroCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageHeroDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type PageHeroFlexibleTextArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageHeroHeroImagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export type PageHeroHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type PageHeroPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageHeroScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PageHeroUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PageHeroValuePropsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageHeroConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PageHeroWhereUniqueInput;
};

/** A connection to a list of items. */
export type PageHeroConnection = {
  __typename?: "PageHeroConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PageHeroEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PageHeroCreateInput = {
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  buttons?: InputMaybe<PageHerobuttonsUnionCreateManyInlineInput>;
  cl4casr593o3c01wd0dn93ni5?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  flexibleText?: InputMaybe<PageHeroflexibleTextUnionCreateManyInlineInput>;
  heroImages?: InputMaybe<AssetCreateManyInlineInput>;
  mainHeading?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  valueProps?: InputMaybe<PageHerovaluePropsUnionCreateManyInlineInput>;
};

export type PageHeroCreateManyInlineInput = {
  /** Connect multiple existing PageHero documents */
  connect?: InputMaybe<Array<PageHeroWhereUniqueInput>>;
  /** Create and connect multiple existing PageHero documents */
  create?: InputMaybe<Array<PageHeroCreateInput>>;
};

export type PageHeroCreateOneInlineInput = {
  /** Connect one existing PageHero document */
  connect?: InputMaybe<PageHeroWhereUniqueInput>;
  /** Create and connect one PageHero document */
  create?: InputMaybe<PageHeroCreateInput>;
};

/** An edge in a connection. */
export type PageHeroEdge = {
  __typename?: "PageHeroEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: PageHero;
};

/** Identifies documents */
export type PageHeroManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageHeroWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageHeroWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageHeroWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  /** All values in which the union is empty. */
  buttons_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  buttons_some?: InputMaybe<PageHerobuttonsUnionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageHeroWhereStageInput>;
  documentInStages_none?: InputMaybe<PageHeroWhereStageInput>;
  documentInStages_some?: InputMaybe<PageHeroWhereStageInput>;
  /** All values in which the union is empty. */
  flexibleText_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  flexibleText_some?: InputMaybe<PageHeroflexibleTextUnionWhereInput>;
  heroImages_every?: InputMaybe<AssetWhereInput>;
  heroImages_none?: InputMaybe<AssetWhereInput>;
  heroImages_some?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mainHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mainHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mainHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mainHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  mainHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mainHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mainHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mainHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mainHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mainHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  /** All values in which the union is empty. */
  valueProps_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  valueProps_some?: InputMaybe<PageHerovaluePropsUnionWhereInput>;
};

export enum PageHeroOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  mainHeading_ASC = "mainHeading_ASC",
  mainHeading_DESC = "mainHeading_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type PageHeroUpdateInput = {
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  buttons?: InputMaybe<PageHerobuttonsUnionUpdateManyInlineInput>;
  cl4casr593o3c01wd0dn93ni5?: InputMaybe<PageUpdateManyInlineInput>;
  flexibleText?: InputMaybe<PageHeroflexibleTextUnionUpdateManyInlineInput>;
  heroImages?: InputMaybe<AssetUpdateManyInlineInput>;
  mainHeading?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  valueProps?: InputMaybe<PageHerovaluePropsUnionUpdateManyInlineInput>;
};

export type PageHeroUpdateManyInlineInput = {
  /** Connect multiple existing PageHero documents */
  connect?: InputMaybe<Array<PageHeroConnectInput>>;
  /** Create and connect multiple PageHero documents */
  create?: InputMaybe<Array<PageHeroCreateInput>>;
  /** Delete multiple PageHero documents */
  delete?: InputMaybe<Array<PageHeroWhereUniqueInput>>;
  /** Disconnect multiple PageHero documents */
  disconnect?: InputMaybe<Array<PageHeroWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PageHero documents */
  set?: InputMaybe<Array<PageHeroWhereUniqueInput>>;
  /** Update multiple PageHero documents */
  update?: InputMaybe<Array<PageHeroUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PageHero documents */
  upsert?: InputMaybe<Array<PageHeroUpsertWithNestedWhereUniqueInput>>;
};

export type PageHeroUpdateManyInput = {
  mainHeading?: InputMaybe<Scalars["String"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageHeroUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageHeroUpdateManyInput;
  /** Document search */
  where: PageHeroWhereInput;
};

export type PageHeroUpdateOneInlineInput = {
  /** Connect existing PageHero document */
  connect?: InputMaybe<PageHeroWhereUniqueInput>;
  /** Create and connect one PageHero document */
  create?: InputMaybe<PageHeroCreateInput>;
  /** Delete currently connected PageHero document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected PageHero document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PageHero document */
  update?: InputMaybe<PageHeroUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageHero document */
  upsert?: InputMaybe<PageHeroUpsertWithNestedWhereUniqueInput>;
};

export type PageHeroUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageHeroUpdateInput;
  /** Unique document search */
  where: PageHeroWhereUniqueInput;
};

export type PageHeroUpsertInput = {
  /** Create document if it didn't exist */
  create: PageHeroCreateInput;
  /** Update document if it exists */
  update: PageHeroUpdateInput;
};

export type PageHeroUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageHeroUpsertInput;
  /** Unique document search */
  where: PageHeroWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PageHeroWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type PageHeroWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageHeroWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageHeroWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageHeroWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  /** All values in which the union is empty. */
  buttons_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  buttons_some?: InputMaybe<PageHerobuttonsUnionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PageHeroWhereStageInput>;
  documentInStages_none?: InputMaybe<PageHeroWhereStageInput>;
  documentInStages_some?: InputMaybe<PageHeroWhereStageInput>;
  /** All values in which the union is empty. */
  flexibleText_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  flexibleText_some?: InputMaybe<PageHeroflexibleTextUnionWhereInput>;
  heroImages_every?: InputMaybe<AssetWhereInput>;
  heroImages_none?: InputMaybe<AssetWhereInput>;
  heroImages_some?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  mainHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  mainHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  mainHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  mainHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  mainHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  mainHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  mainHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  mainHeading_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  mainHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  mainHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
  /** All values in which the union is empty. */
  valueProps_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  valueProps_some?: InputMaybe<PageHerovaluePropsUnionWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PageHeroWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageHeroWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageHeroWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageHeroWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PageHeroWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PageHero record uniquely */
export type PageHeroWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PageHerobuttonsUnion = Button;

export type PageHerobuttonsUnionConnectInput = {
  Button?: InputMaybe<ButtonConnectInput>;
};

export type PageHerobuttonsUnionCreateInput = {
  Button?: InputMaybe<ButtonCreateInput>;
};

export type PageHerobuttonsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing PageHerobuttonsUnion documents */
  create?: InputMaybe<Array<PageHerobuttonsUnionCreateInput>>;
};

export type PageHerobuttonsUnionCreateOneInlineInput = {
  /** Create and connect one PageHerobuttonsUnion document */
  create?: InputMaybe<PageHerobuttonsUnionCreateInput>;
};

export type PageHerobuttonsUnionCreateWithPositionInput = {
  Button?: InputMaybe<ButtonCreateWithPositionInput>;
};

export type PageHerobuttonsUnionUpdateInput = {
  Button?: InputMaybe<ButtonUpdateInput>;
};

export type PageHerobuttonsUnionUpdateManyInlineInput = {
  /** Create and connect multiple PageHerobuttonsUnion component instances */
  create?: InputMaybe<Array<PageHerobuttonsUnionCreateWithPositionInput>>;
  /** Delete multiple PageHerobuttonsUnion documents */
  delete?: InputMaybe<Array<PageHerobuttonsUnionWhereUniqueInput>>;
  /** Update multiple PageHerobuttonsUnion component instances */
  update?: InputMaybe<
    Array<PageHerobuttonsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple PageHerobuttonsUnion component instances */
  upsert?: InputMaybe<
    Array<PageHerobuttonsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type PageHerobuttonsUnionUpdateManyWithNestedWhereInput = {
  Button?: InputMaybe<ButtonUpdateManyWithNestedWhereInput>;
};

export type PageHerobuttonsUnionUpdateOneInlineInput = {
  /** Create and connect one PageHerobuttonsUnion document */
  create?: InputMaybe<PageHerobuttonsUnionCreateInput>;
  /** Delete currently connected PageHerobuttonsUnion document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PageHerobuttonsUnion document */
  update?: InputMaybe<PageHerobuttonsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageHerobuttonsUnion document */
  upsert?: InputMaybe<PageHerobuttonsUnionUpsertWithNestedWhereUniqueInput>;
};

export type PageHerobuttonsUnionUpdateWithNestedWhereUniqueAndPositionInput = {
  Button?: InputMaybe<ButtonUpdateWithNestedWhereUniqueAndPositionInput>;
};

export type PageHerobuttonsUnionUpdateWithNestedWhereUniqueInput = {
  Button?: InputMaybe<ButtonUpdateWithNestedWhereUniqueInput>;
};

export type PageHerobuttonsUnionUpsertWithNestedWhereUniqueAndPositionInput = {
  Button?: InputMaybe<ButtonUpsertWithNestedWhereUniqueAndPositionInput>;
};

export type PageHerobuttonsUnionUpsertWithNestedWhereUniqueInput = {
  Button?: InputMaybe<ButtonUpsertWithNestedWhereUniqueInput>;
};

export type PageHerobuttonsUnionWhereInput = {
  Button?: InputMaybe<ButtonWhereInput>;
};

export type PageHerobuttonsUnionWhereUniqueInput = {
  Button?: InputMaybe<ButtonWhereUniqueInput>;
};

export type PageHeroflexibleTextUnion = ColoredText;

export type PageHeroflexibleTextUnionConnectInput = {
  ColoredText?: InputMaybe<ColoredTextConnectInput>;
};

export type PageHeroflexibleTextUnionCreateInput = {
  ColoredText?: InputMaybe<ColoredTextCreateInput>;
};

export type PageHeroflexibleTextUnionCreateManyInlineInput = {
  /** Create and connect multiple existing PageHeroflexibleTextUnion documents */
  create?: InputMaybe<Array<PageHeroflexibleTextUnionCreateInput>>;
};

export type PageHeroflexibleTextUnionCreateOneInlineInput = {
  /** Create and connect one PageHeroflexibleTextUnion document */
  create?: InputMaybe<PageHeroflexibleTextUnionCreateInput>;
};

export type PageHeroflexibleTextUnionCreateWithPositionInput = {
  ColoredText?: InputMaybe<ColoredTextCreateWithPositionInput>;
};

export type PageHeroflexibleTextUnionUpdateInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateInput>;
};

export type PageHeroflexibleTextUnionUpdateManyInlineInput = {
  /** Create and connect multiple PageHeroflexibleTextUnion component instances */
  create?: InputMaybe<Array<PageHeroflexibleTextUnionCreateWithPositionInput>>;
  /** Delete multiple PageHeroflexibleTextUnion documents */
  delete?: InputMaybe<Array<PageHeroflexibleTextUnionWhereUniqueInput>>;
  /** Update multiple PageHeroflexibleTextUnion component instances */
  update?: InputMaybe<
    Array<PageHeroflexibleTextUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple PageHeroflexibleTextUnion component instances */
  upsert?: InputMaybe<
    Array<PageHeroflexibleTextUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type PageHeroflexibleTextUnionUpdateManyWithNestedWhereInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateManyWithNestedWhereInput>;
};

export type PageHeroflexibleTextUnionUpdateOneInlineInput = {
  /** Create and connect one PageHeroflexibleTextUnion document */
  create?: InputMaybe<PageHeroflexibleTextUnionCreateInput>;
  /** Delete currently connected PageHeroflexibleTextUnion document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PageHeroflexibleTextUnion document */
  update?: InputMaybe<PageHeroflexibleTextUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageHeroflexibleTextUnion document */
  upsert?: InputMaybe<PageHeroflexibleTextUnionUpsertWithNestedWhereUniqueInput>;
};

export type PageHeroflexibleTextUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    ColoredText?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type PageHeroflexibleTextUnionUpdateWithNestedWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextUpdateWithNestedWhereUniqueInput>;
};

export type PageHeroflexibleTextUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    ColoredText?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type PageHeroflexibleTextUnionUpsertWithNestedWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextUpsertWithNestedWhereUniqueInput>;
};

export type PageHeroflexibleTextUnionWhereInput = {
  ColoredText?: InputMaybe<ColoredTextWhereInput>;
};

export type PageHeroflexibleTextUnionWhereUniqueInput = {
  ColoredText?: InputMaybe<ColoredTextWhereUniqueInput>;
};

export type PageHerovaluePropsUnion = ValueProposition;

export type PageHerovaluePropsUnionConnectInput = {
  ValueProposition?: InputMaybe<ValuePropositionConnectInput>;
};

export type PageHerovaluePropsUnionCreateInput = {
  ValueProposition?: InputMaybe<ValuePropositionCreateInput>;
};

export type PageHerovaluePropsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing PageHerovaluePropsUnion documents */
  create?: InputMaybe<Array<PageHerovaluePropsUnionCreateInput>>;
};

export type PageHerovaluePropsUnionCreateOneInlineInput = {
  /** Create and connect one PageHerovaluePropsUnion document */
  create?: InputMaybe<PageHerovaluePropsUnionCreateInput>;
};

export type PageHerovaluePropsUnionCreateWithPositionInput = {
  ValueProposition?: InputMaybe<ValuePropositionCreateWithPositionInput>;
};

export type PageHerovaluePropsUnionUpdateInput = {
  ValueProposition?: InputMaybe<ValuePropositionUpdateInput>;
};

export type PageHerovaluePropsUnionUpdateManyInlineInput = {
  /** Create and connect multiple PageHerovaluePropsUnion component instances */
  create?: InputMaybe<Array<PageHerovaluePropsUnionCreateWithPositionInput>>;
  /** Delete multiple PageHerovaluePropsUnion documents */
  delete?: InputMaybe<Array<PageHerovaluePropsUnionWhereUniqueInput>>;
  /** Update multiple PageHerovaluePropsUnion component instances */
  update?: InputMaybe<
    Array<PageHerovaluePropsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple PageHerovaluePropsUnion component instances */
  upsert?: InputMaybe<
    Array<PageHerovaluePropsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type PageHerovaluePropsUnionUpdateManyWithNestedWhereInput = {
  ValueProposition?: InputMaybe<ValuePropositionUpdateManyWithNestedWhereInput>;
};

export type PageHerovaluePropsUnionUpdateOneInlineInput = {
  /** Create and connect one PageHerovaluePropsUnion document */
  create?: InputMaybe<PageHerovaluePropsUnionCreateInput>;
  /** Delete currently connected PageHerovaluePropsUnion document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PageHerovaluePropsUnion document */
  update?: InputMaybe<PageHerovaluePropsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PageHerovaluePropsUnion document */
  upsert?: InputMaybe<PageHerovaluePropsUnionUpsertWithNestedWhereUniqueInput>;
};

export type PageHerovaluePropsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    ValueProposition?: InputMaybe<ValuePropositionUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type PageHerovaluePropsUnionUpdateWithNestedWhereUniqueInput = {
  ValueProposition?: InputMaybe<ValuePropositionUpdateWithNestedWhereUniqueInput>;
};

export type PageHerovaluePropsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    ValueProposition?: InputMaybe<ValuePropositionUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type PageHerovaluePropsUnionUpsertWithNestedWhereUniqueInput = {
  ValueProposition?: InputMaybe<ValuePropositionUpsertWithNestedWhereUniqueInput>;
};

export type PageHerovaluePropsUnionWhereInput = {
  ValueProposition?: InputMaybe<ValuePropositionWhereInput>;
};

export type PageHerovaluePropsUnionWhereUniqueInput = {
  ValueProposition?: InputMaybe<ValuePropositionWhereUniqueInput>;
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["String"]["output"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"]["output"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"]["output"];
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars["Int"]["output"]>;
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

/** Identifies documents */
export type PageManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  alphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  gettingStartedSection?: InputMaybe<GettingStartedSectionWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  infoCardsSection?: InputMaybe<InfoCardsSectionWhereInput>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  pageHero?: InputMaybe<PageHeroWhereInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  partnerSection?: InputMaybe<PartnerSectionWhereInput>;
  perksSection?: InputMaybe<PerksSectionWhereInput>;
  poweredBySection?: InputMaybe<PoweredBySectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  teamSection?: InputMaybe<TeamSectionWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PageOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  pageSlug_ASC = "pageSlug_ASC",
  pageSlug_DESC = "pageSlug_DESC",
  pageTitle_ASC = "pageTitle_ASC",
  pageTitle_DESC = "pageTitle_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type PageUpdateInput = {
  alphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierUpdateOneInlineInput>;
  ctaSection?: InputMaybe<CtaSectionUpdateOneInlineInput>;
  fAQSection?: InputMaybe<FaqSectionUpdateOneInlineInput>;
  fAQSections?: InputMaybe<FaqSectionUpdateManyInlineInput>;
  gettingStartedSection?: InputMaybe<GettingStartedSectionUpdateOneInlineInput>;
  infoCardsSection?: InputMaybe<InfoCardsSectionUpdateOneInlineInput>;
  mintingStat?: InputMaybe<MintingStatUpdateOneInlineInput>;
  pageHero?: InputMaybe<PageHeroUpdateOneInlineInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  partnerSection?: InputMaybe<PartnerSectionUpdateOneInlineInput>;
  perksSection?: InputMaybe<PerksSectionUpdateOneInlineInput>;
  poweredBySection?: InputMaybe<PoweredBySectionUpdateOneInlineInput>;
  teamSection?: InputMaybe<TeamSectionUpdateOneInlineInput>;
};

export type PageUpdateManyInlineInput = {
  /** Connect multiple existing Page documents */
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Create and connect multiple Page documents */
  create?: InputMaybe<Array<PageCreateInput>>;
  /** Delete multiple Page documents */
  delete?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Disconnect multiple Page documents */
  disconnect?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Page documents */
  set?: InputMaybe<Array<PageWhereUniqueInput>>;
  /** Update multiple Page documents */
  update?: InputMaybe<Array<PageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Page documents */
  upsert?: InputMaybe<Array<PageUpsertWithNestedWhereUniqueInput>>;
};

export type PageUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PageUpdateManyInput;
  /** Document search */
  where: PageWhereInput;
};

export type PageUpdateOneInlineInput = {
  /** Connect existing Page document */
  connect?: InputMaybe<PageWhereUniqueInput>;
  /** Create and connect one Page document */
  create?: InputMaybe<PageCreateInput>;
  /** Delete currently connected Page document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Page document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Page document */
  update?: InputMaybe<PageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Page document */
  upsert?: InputMaybe<PageUpsertWithNestedWhereUniqueInput>;
};

export type PageUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PageUpdateInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

export type PageUpsertInput = {
  /** Create document if it didn't exist */
  create: PageCreateInput;
  /** Update document if it exists */
  update: PageUpdateInput;
};

export type PageUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PageUpsertInput;
  /** Unique document search */
  where: PageWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PageWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type PageWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  alphaNftCollectionTier?: InputMaybe<AlphaNftCollectionTierWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  ctaSection?: InputMaybe<CtaSectionWhereInput>;
  documentInStages_every?: InputMaybe<PageWhereStageInput>;
  documentInStages_none?: InputMaybe<PageWhereStageInput>;
  documentInStages_some?: InputMaybe<PageWhereStageInput>;
  fAQSection?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_every?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_none?: InputMaybe<FaqSectionWhereInput>;
  fAQSections_some?: InputMaybe<FaqSectionWhereInput>;
  gettingStartedSection?: InputMaybe<GettingStartedSectionWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  infoCardsSection?: InputMaybe<InfoCardsSectionWhereInput>;
  mintingStat?: InputMaybe<MintingStatWhereInput>;
  pageHero?: InputMaybe<PageHeroWhereInput>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageSlug_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageSlug_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageSlug_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageSlug_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageSlug_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageSlug_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageSlug_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageSlug_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageSlug_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageTitle_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  partnerSection?: InputMaybe<PartnerSectionWhereInput>;
  perksSection?: InputMaybe<PerksSectionWhereInput>;
  poweredBySection?: InputMaybe<PoweredBySectionWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  teamSection?: InputMaybe<TeamSectionWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PageWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PageWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PageWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PageWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Page record uniquely */
export type PageWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  pageSlug?: InputMaybe<Scalars["String"]["input"]>;
  pageTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type PartnerSection = Entity &
  Node & {
    __typename?: "PartnerSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<PartnerSection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of PartnerSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    partnerLogos: Array<ImageWithAltTextAndLink>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type PartnerSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PartnerSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type PartnerSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type PartnerSectionPartnerLogosArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ImageWithAltTextAndLinkOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ImageWithAltTextAndLinkWhereInput>;
};

export type PartnerSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PartnerSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PartnerSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PartnerSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PartnerSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type PartnerSectionConnection = {
  __typename?: "PartnerSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PartnerSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PartnerSectionCreateInput = {
  cl4j4budo4loe01xvgrcs4sms?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  partnerLogos?: InputMaybe<ImageWithAltTextAndLinkCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PartnerSectionCreateManyInlineInput = {
  /** Connect multiple existing PartnerSection documents */
  connect?: InputMaybe<Array<PartnerSectionWhereUniqueInput>>;
  /** Create and connect multiple existing PartnerSection documents */
  create?: InputMaybe<Array<PartnerSectionCreateInput>>;
};

export type PartnerSectionCreateOneInlineInput = {
  /** Connect one existing PartnerSection document */
  connect?: InputMaybe<PartnerSectionWhereUniqueInput>;
  /** Create and connect one PartnerSection document */
  create?: InputMaybe<PartnerSectionCreateInput>;
};

/** An edge in a connection. */
export type PartnerSectionEdge = {
  __typename?: "PartnerSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: PartnerSection;
};

/** Identifies documents */
export type PartnerSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PartnerSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<PartnerSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<PartnerSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  partnerLogos_every?: InputMaybe<ImageWithAltTextAndLinkWhereInput>;
  partnerLogos_none?: InputMaybe<ImageWithAltTextAndLinkWhereInput>;
  partnerLogos_some?: InputMaybe<ImageWithAltTextAndLinkWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PartnerSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type PartnerSectionUpdateInput = {
  cl4j4budo4loe01xvgrcs4sms?: InputMaybe<PageUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  partnerLogos?: InputMaybe<ImageWithAltTextAndLinkUpdateManyInlineInput>;
};

export type PartnerSectionUpdateManyInlineInput = {
  /** Connect multiple existing PartnerSection documents */
  connect?: InputMaybe<Array<PartnerSectionConnectInput>>;
  /** Create and connect multiple PartnerSection documents */
  create?: InputMaybe<Array<PartnerSectionCreateInput>>;
  /** Delete multiple PartnerSection documents */
  delete?: InputMaybe<Array<PartnerSectionWhereUniqueInput>>;
  /** Disconnect multiple PartnerSection documents */
  disconnect?: InputMaybe<Array<PartnerSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PartnerSection documents */
  set?: InputMaybe<Array<PartnerSectionWhereUniqueInput>>;
  /** Update multiple PartnerSection documents */
  update?: InputMaybe<Array<PartnerSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PartnerSection documents */
  upsert?: InputMaybe<Array<PartnerSectionUpsertWithNestedWhereUniqueInput>>;
};

export type PartnerSectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
};

export type PartnerSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PartnerSectionUpdateManyInput;
  /** Document search */
  where: PartnerSectionWhereInput;
};

export type PartnerSectionUpdateOneInlineInput = {
  /** Connect existing PartnerSection document */
  connect?: InputMaybe<PartnerSectionWhereUniqueInput>;
  /** Create and connect one PartnerSection document */
  create?: InputMaybe<PartnerSectionCreateInput>;
  /** Delete currently connected PartnerSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected PartnerSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PartnerSection document */
  update?: InputMaybe<PartnerSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PartnerSection document */
  upsert?: InputMaybe<PartnerSectionUpsertWithNestedWhereUniqueInput>;
};

export type PartnerSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PartnerSectionUpdateInput;
  /** Unique document search */
  where: PartnerSectionWhereUniqueInput;
};

export type PartnerSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: PartnerSectionCreateInput;
  /** Update document if it exists */
  update: PartnerSectionUpdateInput;
};

export type PartnerSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PartnerSectionUpsertInput;
  /** Unique document search */
  where: PartnerSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PartnerSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type PartnerSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PartnerSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<PartnerSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<PartnerSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  partnerLogos_every?: InputMaybe<ImageWithAltTextAndLinkWhereInput>;
  partnerLogos_none?: InputMaybe<ImageWithAltTextAndLinkWhereInput>;
  partnerLogos_some?: InputMaybe<ImageWithAltTextAndLinkWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PartnerSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PartnerSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PartnerSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PartnerSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PartnerSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PartnerSection record uniquely */
export type PartnerSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PerksSection = Entity &
  Node & {
    __typename?: "PerksSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<PerksSection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of PerksSection versions */
    history: Array<Version>;
    iconHeaderSubheading: Array<LargeIconHeading>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type PerksSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PerksSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type PerksSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type PerksSectionIconHeaderSubheadingArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<LargeIconHeadingOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<LargeIconHeadingWhereInput>;
};

export type PerksSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PerksSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PerksSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PerksSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PerksSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type PerksSectionConnection = {
  __typename?: "PerksSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PerksSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PerksSectionCreateInput = {
  cl4j4aiht4iiz01yrb9t7a6bf?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  iconHeaderSubheading?: InputMaybe<LargeIconHeadingCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PerksSectionCreateManyInlineInput = {
  /** Connect multiple existing PerksSection documents */
  connect?: InputMaybe<Array<PerksSectionWhereUniqueInput>>;
  /** Create and connect multiple existing PerksSection documents */
  create?: InputMaybe<Array<PerksSectionCreateInput>>;
};

export type PerksSectionCreateOneInlineInput = {
  /** Connect one existing PerksSection document */
  connect?: InputMaybe<PerksSectionWhereUniqueInput>;
  /** Create and connect one PerksSection document */
  create?: InputMaybe<PerksSectionCreateInput>;
};

/** An edge in a connection. */
export type PerksSectionEdge = {
  __typename?: "PerksSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: PerksSection;
};

/** Identifies documents */
export type PerksSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PerksSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PerksSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PerksSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PerksSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<PerksSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<PerksSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  iconHeaderSubheading_every?: InputMaybe<LargeIconHeadingWhereInput>;
  iconHeaderSubheading_none?: InputMaybe<LargeIconHeadingWhereInput>;
  iconHeaderSubheading_some?: InputMaybe<LargeIconHeadingWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PerksSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type PerksSectionUpdateInput = {
  cl4j4aiht4iiz01yrb9t7a6bf?: InputMaybe<PageUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  iconHeaderSubheading?: InputMaybe<LargeIconHeadingUpdateManyInlineInput>;
};

export type PerksSectionUpdateManyInlineInput = {
  /** Connect multiple existing PerksSection documents */
  connect?: InputMaybe<Array<PerksSectionConnectInput>>;
  /** Create and connect multiple PerksSection documents */
  create?: InputMaybe<Array<PerksSectionCreateInput>>;
  /** Delete multiple PerksSection documents */
  delete?: InputMaybe<Array<PerksSectionWhereUniqueInput>>;
  /** Disconnect multiple PerksSection documents */
  disconnect?: InputMaybe<Array<PerksSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PerksSection documents */
  set?: InputMaybe<Array<PerksSectionWhereUniqueInput>>;
  /** Update multiple PerksSection documents */
  update?: InputMaybe<Array<PerksSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PerksSection documents */
  upsert?: InputMaybe<Array<PerksSectionUpsertWithNestedWhereUniqueInput>>;
};

export type PerksSectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
};

export type PerksSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PerksSectionUpdateManyInput;
  /** Document search */
  where: PerksSectionWhereInput;
};

export type PerksSectionUpdateOneInlineInput = {
  /** Connect existing PerksSection document */
  connect?: InputMaybe<PerksSectionWhereUniqueInput>;
  /** Create and connect one PerksSection document */
  create?: InputMaybe<PerksSectionCreateInput>;
  /** Delete currently connected PerksSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected PerksSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PerksSection document */
  update?: InputMaybe<PerksSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PerksSection document */
  upsert?: InputMaybe<PerksSectionUpsertWithNestedWhereUniqueInput>;
};

export type PerksSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PerksSectionUpdateInput;
  /** Unique document search */
  where: PerksSectionWhereUniqueInput;
};

export type PerksSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: PerksSectionCreateInput;
  /** Update document if it exists */
  update: PerksSectionUpdateInput;
};

export type PerksSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PerksSectionUpsertInput;
  /** Unique document search */
  where: PerksSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PerksSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type PerksSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PerksSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PerksSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PerksSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PerksSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<PerksSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<PerksSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  iconHeaderSubheading_every?: InputMaybe<LargeIconHeadingWhereInput>;
  iconHeaderSubheading_none?: InputMaybe<LargeIconHeadingWhereInput>;
  iconHeaderSubheading_some?: InputMaybe<LargeIconHeadingWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PerksSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PerksSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PerksSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PerksSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PerksSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PerksSection record uniquely */
export type PerksSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PoweredBySection = Entity &
  Node & {
    __typename?: "PoweredBySection";
    buttons: Array<PoweredBySectionbuttonsUnion>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<PoweredBySection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of PoweredBySection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    information?: Maybe<Scalars["String"]["output"]>;
    logo?: Maybe<Asset>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type PoweredBySectionButtonsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PoweredBySectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PoweredBySectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type PoweredBySectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type PoweredBySectionLogoArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PoweredBySectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PoweredBySectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PoweredBySectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PoweredBySectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PoweredBySectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type PoweredBySectionConnection = {
  __typename?: "PoweredBySectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PoweredBySectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PoweredBySectionCreateInput = {
  buttons?: InputMaybe<PoweredBySectionbuttonsUnionCreateManyInlineInput>;
  cl4ccsfns3rfo01w85fa224jj?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<AssetCreateOneInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PoweredBySectionCreateManyInlineInput = {
  /** Connect multiple existing PoweredBySection documents */
  connect?: InputMaybe<Array<PoweredBySectionWhereUniqueInput>>;
  /** Create and connect multiple existing PoweredBySection documents */
  create?: InputMaybe<Array<PoweredBySectionCreateInput>>;
};

export type PoweredBySectionCreateOneInlineInput = {
  /** Connect one existing PoweredBySection document */
  connect?: InputMaybe<PoweredBySectionWhereUniqueInput>;
  /** Create and connect one PoweredBySection document */
  create?: InputMaybe<PoweredBySectionCreateInput>;
};

/** An edge in a connection. */
export type PoweredBySectionEdge = {
  __typename?: "PoweredBySectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: PoweredBySection;
};

/** Identifies documents */
export type PoweredBySectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PoweredBySectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PoweredBySectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PoweredBySectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  /** All values in which the union is empty. */
  buttons_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  buttons_some?: InputMaybe<PoweredBySectionbuttonsUnionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PoweredBySectionWhereStageInput>;
  documentInStages_none?: InputMaybe<PoweredBySectionWhereStageInput>;
  documentInStages_some?: InputMaybe<PoweredBySectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  information_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  information_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  information_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  information_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  information_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  information_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  information_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  information_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  information_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PoweredBySectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  information_ASC = "information_ASC",
  information_DESC = "information_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type PoweredBySectionUpdateInput = {
  buttons?: InputMaybe<PoweredBySectionbuttonsUnionUpdateManyInlineInput>;
  cl4ccsfns3rfo01w85fa224jj?: InputMaybe<PageUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<AssetUpdateOneInlineInput>;
};

export type PoweredBySectionUpdateManyInlineInput = {
  /** Connect multiple existing PoweredBySection documents */
  connect?: InputMaybe<Array<PoweredBySectionConnectInput>>;
  /** Create and connect multiple PoweredBySection documents */
  create?: InputMaybe<Array<PoweredBySectionCreateInput>>;
  /** Delete multiple PoweredBySection documents */
  delete?: InputMaybe<Array<PoweredBySectionWhereUniqueInput>>;
  /** Disconnect multiple PoweredBySection documents */
  disconnect?: InputMaybe<Array<PoweredBySectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PoweredBySection documents */
  set?: InputMaybe<Array<PoweredBySectionWhereUniqueInput>>;
  /** Update multiple PoweredBySection documents */
  update?: InputMaybe<Array<PoweredBySectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple PoweredBySection documents */
  upsert?: InputMaybe<Array<PoweredBySectionUpsertWithNestedWhereUniqueInput>>;
};

export type PoweredBySectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
};

export type PoweredBySectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PoweredBySectionUpdateManyInput;
  /** Document search */
  where: PoweredBySectionWhereInput;
};

export type PoweredBySectionUpdateOneInlineInput = {
  /** Connect existing PoweredBySection document */
  connect?: InputMaybe<PoweredBySectionWhereUniqueInput>;
  /** Create and connect one PoweredBySection document */
  create?: InputMaybe<PoweredBySectionCreateInput>;
  /** Delete currently connected PoweredBySection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected PoweredBySection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PoweredBySection document */
  update?: InputMaybe<PoweredBySectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PoweredBySection document */
  upsert?: InputMaybe<PoweredBySectionUpsertWithNestedWhereUniqueInput>;
};

export type PoweredBySectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PoweredBySectionUpdateInput;
  /** Unique document search */
  where: PoweredBySectionWhereUniqueInput;
};

export type PoweredBySectionUpsertInput = {
  /** Create document if it didn't exist */
  create: PoweredBySectionCreateInput;
  /** Update document if it exists */
  update: PoweredBySectionUpdateInput;
};

export type PoweredBySectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PoweredBySectionUpsertInput;
  /** Unique document search */
  where: PoweredBySectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PoweredBySectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type PoweredBySectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PoweredBySectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PoweredBySectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PoweredBySectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  /** All values in which the union is empty. */
  buttons_empty?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Matches if the modular component contains at least one connection to the item provided to the filter */
  buttons_some?: InputMaybe<PoweredBySectionbuttonsUnionWhereInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PoweredBySectionWhereStageInput>;
  documentInStages_none?: InputMaybe<PoweredBySectionWhereStageInput>;
  documentInStages_some?: InputMaybe<PoweredBySectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  information?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  information_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  information_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  information_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  information_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  information_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  information_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  information_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  information_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  information_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<AssetWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PoweredBySectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PoweredBySectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PoweredBySectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PoweredBySectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PoweredBySectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PoweredBySection record uniquely */
export type PoweredBySectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PoweredBySectionbuttonsUnion = Button;

export type PoweredBySectionbuttonsUnionConnectInput = {
  Button?: InputMaybe<ButtonConnectInput>;
};

export type PoweredBySectionbuttonsUnionCreateInput = {
  Button?: InputMaybe<ButtonCreateInput>;
};

export type PoweredBySectionbuttonsUnionCreateManyInlineInput = {
  /** Create and connect multiple existing PoweredBySectionbuttonsUnion documents */
  create?: InputMaybe<Array<PoweredBySectionbuttonsUnionCreateInput>>;
};

export type PoweredBySectionbuttonsUnionCreateOneInlineInput = {
  /** Create and connect one PoweredBySectionbuttonsUnion document */
  create?: InputMaybe<PoweredBySectionbuttonsUnionCreateInput>;
};

export type PoweredBySectionbuttonsUnionCreateWithPositionInput = {
  Button?: InputMaybe<ButtonCreateWithPositionInput>;
};

export type PoweredBySectionbuttonsUnionUpdateInput = {
  Button?: InputMaybe<ButtonUpdateInput>;
};

export type PoweredBySectionbuttonsUnionUpdateManyInlineInput = {
  /** Create and connect multiple PoweredBySectionbuttonsUnion component instances */
  create?: InputMaybe<
    Array<PoweredBySectionbuttonsUnionCreateWithPositionInput>
  >;
  /** Delete multiple PoweredBySectionbuttonsUnion documents */
  delete?: InputMaybe<Array<PoweredBySectionbuttonsUnionWhereUniqueInput>>;
  /** Update multiple PoweredBySectionbuttonsUnion component instances */
  update?: InputMaybe<
    Array<PoweredBySectionbuttonsUnionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple PoweredBySectionbuttonsUnion component instances */
  upsert?: InputMaybe<
    Array<PoweredBySectionbuttonsUnionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type PoweredBySectionbuttonsUnionUpdateManyWithNestedWhereInput = {
  Button?: InputMaybe<ButtonUpdateManyWithNestedWhereInput>;
};

export type PoweredBySectionbuttonsUnionUpdateOneInlineInput = {
  /** Create and connect one PoweredBySectionbuttonsUnion document */
  create?: InputMaybe<PoweredBySectionbuttonsUnionCreateInput>;
  /** Delete currently connected PoweredBySectionbuttonsUnion document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PoweredBySectionbuttonsUnion document */
  update?: InputMaybe<PoweredBySectionbuttonsUnionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PoweredBySectionbuttonsUnion document */
  upsert?: InputMaybe<PoweredBySectionbuttonsUnionUpsertWithNestedWhereUniqueInput>;
};

export type PoweredBySectionbuttonsUnionUpdateWithNestedWhereUniqueAndPositionInput =
  {
    Button?: InputMaybe<ButtonUpdateWithNestedWhereUniqueAndPositionInput>;
  };

export type PoweredBySectionbuttonsUnionUpdateWithNestedWhereUniqueInput = {
  Button?: InputMaybe<ButtonUpdateWithNestedWhereUniqueInput>;
};

export type PoweredBySectionbuttonsUnionUpsertWithNestedWhereUniqueAndPositionInput =
  {
    Button?: InputMaybe<ButtonUpsertWithNestedWhereUniqueAndPositionInput>;
  };

export type PoweredBySectionbuttonsUnionUpsertWithNestedWhereUniqueInput = {
  Button?: InputMaybe<ButtonUpsertWithNestedWhereUniqueInput>;
};

export type PoweredBySectionbuttonsUnionWhereInput = {
  Button?: InputMaybe<ButtonWhereInput>;
};

export type PoweredBySectionbuttonsUnionWhereUniqueInput = {
  Button?: InputMaybe<ButtonWhereUniqueInput>;
};

export enum ProjectSecurityTags {
  Audit = "Audit",
  Doxxed = "Doxxed",
  KYC = "KYC",
  MultiSig = "MultiSig",
}

export type PublishLocaleInput = {
  /** Locales to publish */
  locale: Locale;
  /** Stages to publish selected locales to */
  stages: Array<Stage>;
};

export type PupsPresaleCommunity = Entity &
  Node & {
    __typename?: "PupsPresaleCommunity";
    communityName?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<PupsPresaleCommunity>;
    /** List of PupsPresaleCommunity versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    pageUrl: Scalars["String"]["output"];
    presaleLimit?: Maybe<Scalars["Int"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    totalMinted?: Maybe<Scalars["Int"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type PupsPresaleCommunityCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PupsPresaleCommunityDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type PupsPresaleCommunityHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type PupsPresaleCommunityPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PupsPresaleCommunityScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type PupsPresaleCommunityUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type PupsPresaleCommunityConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: PupsPresaleCommunityWhereUniqueInput;
};

/** A connection to a list of items. */
export type PupsPresaleCommunityConnection = {
  __typename?: "PupsPresaleCommunityConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<PupsPresaleCommunityEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PupsPresaleCommunityCreateInput = {
  communityName?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  pageUrl: Scalars["String"]["input"];
  presaleLimit?: InputMaybe<Scalars["Int"]["input"]>;
  totalMinted?: InputMaybe<Scalars["Int"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PupsPresaleCommunityCreateManyInlineInput = {
  /** Connect multiple existing PupsPresaleCommunity documents */
  connect?: InputMaybe<Array<PupsPresaleCommunityWhereUniqueInput>>;
  /** Create and connect multiple existing PupsPresaleCommunity documents */
  create?: InputMaybe<Array<PupsPresaleCommunityCreateInput>>;
};

export type PupsPresaleCommunityCreateOneInlineInput = {
  /** Connect one existing PupsPresaleCommunity document */
  connect?: InputMaybe<PupsPresaleCommunityWhereUniqueInput>;
  /** Create and connect one PupsPresaleCommunity document */
  create?: InputMaybe<PupsPresaleCommunityCreateInput>;
};

/** An edge in a connection. */
export type PupsPresaleCommunityEdge = {
  __typename?: "PupsPresaleCommunityEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: PupsPresaleCommunity;
};

/** Identifies documents */
export type PupsPresaleCommunityManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PupsPresaleCommunityWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PupsPresaleCommunityWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PupsPresaleCommunityWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  communityName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  communityName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  communityName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  communityName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  communityName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  communityName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  communityName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  communityName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  communityName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  communityName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PupsPresaleCommunityWhereStageInput>;
  documentInStages_none?: InputMaybe<PupsPresaleCommunityWhereStageInput>;
  documentInStages_some?: InputMaybe<PupsPresaleCommunityWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleLimit?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  presaleLimit_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  presaleLimit_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  presaleLimit_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  presaleLimit_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  presaleLimit_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  presaleLimit_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  presaleLimit_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  totalMinted?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  totalMinted_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  totalMinted_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  totalMinted_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  totalMinted_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  totalMinted_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  totalMinted_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  totalMinted_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum PupsPresaleCommunityOrderByInput {
  communityName_ASC = "communityName_ASC",
  communityName_DESC = "communityName_DESC",
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  pageUrl_ASC = "pageUrl_ASC",
  pageUrl_DESC = "pageUrl_DESC",
  presaleLimit_ASC = "presaleLimit_ASC",
  presaleLimit_DESC = "presaleLimit_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  totalMinted_ASC = "totalMinted_ASC",
  totalMinted_DESC = "totalMinted_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type PupsPresaleCommunityUpdateInput = {
  communityName?: InputMaybe<Scalars["String"]["input"]>;
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
  presaleLimit?: InputMaybe<Scalars["Int"]["input"]>;
  totalMinted?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PupsPresaleCommunityUpdateManyInlineInput = {
  /** Connect multiple existing PupsPresaleCommunity documents */
  connect?: InputMaybe<Array<PupsPresaleCommunityConnectInput>>;
  /** Create and connect multiple PupsPresaleCommunity documents */
  create?: InputMaybe<Array<PupsPresaleCommunityCreateInput>>;
  /** Delete multiple PupsPresaleCommunity documents */
  delete?: InputMaybe<Array<PupsPresaleCommunityWhereUniqueInput>>;
  /** Disconnect multiple PupsPresaleCommunity documents */
  disconnect?: InputMaybe<Array<PupsPresaleCommunityWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing PupsPresaleCommunity documents */
  set?: InputMaybe<Array<PupsPresaleCommunityWhereUniqueInput>>;
  /** Update multiple PupsPresaleCommunity documents */
  update?: InputMaybe<
    Array<PupsPresaleCommunityUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple PupsPresaleCommunity documents */
  upsert?: InputMaybe<
    Array<PupsPresaleCommunityUpsertWithNestedWhereUniqueInput>
  >;
};

export type PupsPresaleCommunityUpdateManyInput = {
  communityName?: InputMaybe<Scalars["String"]["input"]>;
  presaleLimit?: InputMaybe<Scalars["Int"]["input"]>;
  totalMinted?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PupsPresaleCommunityUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: PupsPresaleCommunityUpdateManyInput;
  /** Document search */
  where: PupsPresaleCommunityWhereInput;
};

export type PupsPresaleCommunityUpdateOneInlineInput = {
  /** Connect existing PupsPresaleCommunity document */
  connect?: InputMaybe<PupsPresaleCommunityWhereUniqueInput>;
  /** Create and connect one PupsPresaleCommunity document */
  create?: InputMaybe<PupsPresaleCommunityCreateInput>;
  /** Delete currently connected PupsPresaleCommunity document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected PupsPresaleCommunity document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single PupsPresaleCommunity document */
  update?: InputMaybe<PupsPresaleCommunityUpdateWithNestedWhereUniqueInput>;
  /** Upsert single PupsPresaleCommunity document */
  upsert?: InputMaybe<PupsPresaleCommunityUpsertWithNestedWhereUniqueInput>;
};

export type PupsPresaleCommunityUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: PupsPresaleCommunityUpdateInput;
  /** Unique document search */
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type PupsPresaleCommunityUpsertInput = {
  /** Create document if it didn't exist */
  create: PupsPresaleCommunityCreateInput;
  /** Update document if it exists */
  update: PupsPresaleCommunityUpdateInput;
};

export type PupsPresaleCommunityUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: PupsPresaleCommunityUpsertInput;
  /** Unique document search */
  where: PupsPresaleCommunityWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type PupsPresaleCommunityWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type PupsPresaleCommunityWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PupsPresaleCommunityWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PupsPresaleCommunityWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PupsPresaleCommunityWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  communityName?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  communityName_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  communityName_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  communityName_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  communityName_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  communityName_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  communityName_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  communityName_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  communityName_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  communityName_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<PupsPresaleCommunityWhereStageInput>;
  documentInStages_none?: InputMaybe<PupsPresaleCommunityWhereStageInput>;
  documentInStages_some?: InputMaybe<PupsPresaleCommunityWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  pageUrl_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  pageUrl_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  pageUrl_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  pageUrl_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  pageUrl_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  pageUrl_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  pageUrl_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  pageUrl_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  pageUrl_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  presaleLimit?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  presaleLimit_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  presaleLimit_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  presaleLimit_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  presaleLimit_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  presaleLimit_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  presaleLimit_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  presaleLimit_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  totalMinted?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  totalMinted_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  totalMinted_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  totalMinted_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  /** All values less than the given value. */
  totalMinted_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  totalMinted_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  totalMinted_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  totalMinted_not_in?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type PupsPresaleCommunityWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<PupsPresaleCommunityWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<PupsPresaleCommunityWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<PupsPresaleCommunityWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<PupsPresaleCommunityWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References PupsPresaleCommunity record uniquely */
export type PupsPresaleCommunityWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  /** Retrieve a single alphaNftCollectionTier */
  alphaNftCollectionTier?: Maybe<AlphaNftCollectionTier>;
  /** Retrieve document version */
  alphaNftCollectionTierVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple alphaNftCollectionTiers */
  alphaNftCollectionTiers: Array<AlphaNftCollectionTier>;
  /** Retrieve multiple alphaNftCollectionTiers using the Relay connection interface */
  alphaNftCollectionTiersConnection: AlphaNftCollectionTierConnection;
  /** Retrieve a single alphaSharesInformation */
  alphaSharesInformation?: Maybe<AlphaSharesInformation>;
  /** Retrieve document version */
  alphaSharesInformationVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple alphaSharesInformations */
  alphaSharesInformations: Array<AlphaSharesInformation>;
  /** Retrieve multiple alphaSharesInformations using the Relay connection interface */
  alphaSharesInformationsConnection: AlphaSharesInformationConnection;
  /** Retrieve a single asset */
  asset?: Maybe<Asset>;
  /** Retrieve document version */
  assetVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple assets */
  assets: Array<Asset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: AssetConnection;
  /** Retrieve a single chain */
  chain?: Maybe<Chain>;
  /** Retrieve document version */
  chainVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple chains */
  chains: Array<Chain>;
  /** Retrieve multiple chains using the Relay connection interface */
  chainsConnection: ChainConnection;
  /** Retrieve a single collectionCreator */
  collectionCreator?: Maybe<CollectionCreator>;
  /** Retrieve document version */
  collectionCreatorVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple collectionCreators */
  collectionCreators: Array<CollectionCreator>;
  /** Retrieve multiple collectionCreators using the Relay connection interface */
  collectionCreatorsConnection: CollectionCreatorConnection;
  /** Retrieve a single ctaSection */
  ctaSection?: Maybe<CtaSection>;
  /** Retrieve document version */
  ctaSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple ctaSections */
  ctaSections: Array<CtaSection>;
  /** Retrieve multiple ctaSections using the Relay connection interface */
  ctaSectionsConnection: CtaSectionConnection;
  /** Fetches an object given its ID */
  entities?: Maybe<Array<Entity>>;
  /** Retrieve a single fAQSection */
  fAQSection?: Maybe<FaqSection>;
  /** Retrieve document version */
  fAQSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple fAQSections */
  fAQSections: Array<FaqSection>;
  /** Retrieve multiple fAQSections using the Relay connection interface */
  fAQSectionsConnection: FaqSectionConnection;
  /** Retrieve a single featuredCollectionGroup */
  featuredCollectionGroup?: Maybe<FeaturedCollectionGroup>;
  /** Retrieve document version */
  featuredCollectionGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple featuredCollectionGroups */
  featuredCollectionGroups: Array<FeaturedCollectionGroup>;
  /** Retrieve multiple featuredCollectionGroups using the Relay connection interface */
  featuredCollectionGroupsConnection: FeaturedCollectionGroupConnection;
  /** Retrieve a single featuredCreatorGroup */
  featuredCreatorGroup?: Maybe<FeaturedCreatorGroup>;
  /** Retrieve document version */
  featuredCreatorGroupVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple featuredCreatorGroups */
  featuredCreatorGroups: Array<FeaturedCreatorGroup>;
  /** Retrieve multiple featuredCreatorGroups using the Relay connection interface */
  featuredCreatorGroupsConnection: FeaturedCreatorGroupConnection;
  /** Retrieve a single gettingStartedSection */
  gettingStartedSection?: Maybe<GettingStartedSection>;
  /** Retrieve document version */
  gettingStartedSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple gettingStartedSections */
  gettingStartedSections: Array<GettingStartedSection>;
  /** Retrieve multiple gettingStartedSections using the Relay connection interface */
  gettingStartedSectionsConnection: GettingStartedSectionConnection;
  /** Retrieve a single infoCardsSection */
  infoCardsSection?: Maybe<InfoCardsSection>;
  /** Retrieve document version */
  infoCardsSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple infoCardsSections */
  infoCardsSections: Array<InfoCardsSection>;
  /** Retrieve multiple infoCardsSections using the Relay connection interface */
  infoCardsSectionsConnection: InfoCardsSectionConnection;
  /** Retrieve a single landingPage */
  landingPage?: Maybe<LandingPage>;
  /** Retrieve document version */
  landingPageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple landingPages */
  landingPages: Array<LandingPage>;
  /** Retrieve multiple landingPages using the Relay connection interface */
  landingPagesConnection: LandingPageConnection;
  /** Retrieve a single marketplaceAnnouncementBanner */
  marketplaceAnnouncementBanner?: Maybe<MarketplaceAnnouncementBanner>;
  /** Retrieve document version */
  marketplaceAnnouncementBannerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple marketplaceAnnouncementBanners */
  marketplaceAnnouncementBanners: Array<MarketplaceAnnouncementBanner>;
  /** Retrieve multiple marketplaceAnnouncementBanners using the Relay connection interface */
  marketplaceAnnouncementBannersConnection: MarketplaceAnnouncementBannerConnection;
  /** Retrieve a single marketplaceContract */
  marketplaceContract?: Maybe<MarketplaceContract>;
  /** Retrieve document version */
  marketplaceContractVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple marketplaceContracts */
  marketplaceContracts: Array<MarketplaceContract>;
  /** Retrieve multiple marketplaceContracts using the Relay connection interface */
  marketplaceContractsConnection: MarketplaceContractConnection;
  /** Retrieve a single marketplacePage */
  marketplacePage?: Maybe<MarketplacePage>;
  /** Retrieve document version */
  marketplacePageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple marketplacePages */
  marketplacePages: Array<MarketplacePage>;
  /** Retrieve multiple marketplacePages using the Relay connection interface */
  marketplacePagesConnection: MarketplacePageConnection;
  /** Retrieve a single menu */
  menu?: Maybe<Menu>;
  /** Retrieve document version */
  menuVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple menus */
  menus: Array<Menu>;
  /** Retrieve multiple menus using the Relay connection interface */
  menusConnection: MenuConnection;
  /** Retrieve a single miningBtcSupplyDemandModelSection */
  miningBtcSupplyDemandModelSection?: Maybe<MiningBtcSupplyDemandModelSection>;
  /** Retrieve document version */
  miningBtcSupplyDemandModelSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningBtcSupplyDemandModelSections */
  miningBtcSupplyDemandModelSections: Array<MiningBtcSupplyDemandModelSection>;
  /** Retrieve multiple miningBtcSupplyDemandModelSections using the Relay connection interface */
  miningBtcSupplyDemandModelSectionsConnection: MiningBtcSupplyDemandModelSectionConnection;
  /** Retrieve a single miningContainer */
  miningContainer?: Maybe<MiningContainer>;
  /** Retrieve document version */
  miningContainerVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningContainers */
  miningContainers: Array<MiningContainer>;
  /** Retrieve multiple miningContainers using the Relay connection interface */
  miningContainersConnection: MiningContainerConnection;
  /** Retrieve a single miningHeaderNotice */
  miningHeaderNotice?: Maybe<MiningHeaderNotice>;
  /** Retrieve document version */
  miningHeaderNoticeVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningHeaderNotices */
  miningHeaderNotices: Array<MiningHeaderNotice>;
  /** Retrieve multiple miningHeaderNotices using the Relay connection interface */
  miningHeaderNoticesConnection: MiningHeaderNoticeConnection;
  /** Retrieve a single miningNFTLPPageHeroSection */
  miningNFTLPPageHeroSection?: Maybe<MiningNftlpPageHeroSection>;
  /** Retrieve document version */
  miningNFTLPPageHeroSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNFTLPPageHeroSections */
  miningNFTLPPageHeroSections: Array<MiningNftlpPageHeroSection>;
  /** Retrieve multiple miningNFTLPPageHeroSections using the Relay connection interface */
  miningNFTLPPageHeroSectionsConnection: MiningNftlpPageHeroSectionConnection;
  /** Retrieve a single miningNftAccessSection */
  miningNftAccessSection?: Maybe<MiningNftAccessSection>;
  /** Retrieve document version */
  miningNftAccessSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftAccessSections */
  miningNftAccessSections: Array<MiningNftAccessSection>;
  /** Retrieve multiple miningNftAccessSections using the Relay connection interface */
  miningNftAccessSectionsConnection: MiningNftAccessSectionConnection;
  /** Retrieve a single miningNftAdvantageSection */
  miningNftAdvantageSection?: Maybe<MiningNftAdvantageSection>;
  /** Retrieve document version */
  miningNftAdvantageSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftAdvantageSections */
  miningNftAdvantageSections: Array<MiningNftAdvantageSection>;
  /** Retrieve multiple miningNftAdvantageSections using the Relay connection interface */
  miningNftAdvantageSectionsConnection: MiningNftAdvantageSectionConnection;
  /** Retrieve a single miningNftAntimonyAdvantageSection */
  miningNftAntimonyAdvantageSection?: Maybe<MiningNftAntimonyAdvantageSection>;
  /** Retrieve document version */
  miningNftAntimonyAdvantageSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftAntimonyAdvantageSections */
  miningNftAntimonyAdvantageSections: Array<MiningNftAntimonyAdvantageSection>;
  /** Retrieve multiple miningNftAntimonyAdvantageSections using the Relay connection interface */
  miningNftAntimonyAdvantageSectionsConnection: MiningNftAntimonyAdvantageSectionConnection;
  /** Retrieve a single miningNftComparisonTable */
  miningNftComparisonTable?: Maybe<MiningNftComparisonTable>;
  /** Retrieve document version */
  miningNftComparisonTableVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftComparisonTables */
  miningNftComparisonTables: Array<MiningNftComparisonTable>;
  /** Retrieve multiple miningNftComparisonTables using the Relay connection interface */
  miningNftComparisonTablesConnection: MiningNftComparisonTableConnection;
  /** Retrieve a single miningNftEligibilitySection */
  miningNftEligibilitySection?: Maybe<MiningNftEligibilitySection>;
  /** Retrieve document version */
  miningNftEligibilitySectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftEligibilitySections */
  miningNftEligibilitySections: Array<MiningNftEligibilitySection>;
  /** Retrieve multiple miningNftEligibilitySections using the Relay connection interface */
  miningNftEligibilitySectionsConnection: MiningNftEligibilitySectionConnection;
  /** Retrieve a single miningNftProfitRoiSection */
  miningNftProfitRoiSection?: Maybe<MiningNftProfitRoiSection>;
  /** Retrieve document version */
  miningNftProfitRoiSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftProfitRoiSections */
  miningNftProfitRoiSections: Array<MiningNftProfitRoiSection>;
  /** Retrieve multiple miningNftProfitRoiSections using the Relay connection interface */
  miningNftProfitRoiSectionsConnection: MiningNftProfitRoiSectionConnection;
  /** Retrieve a single miningNftTeamSection */
  miningNftTeamSection?: Maybe<MiningNftTeamSection>;
  /** Retrieve document version */
  miningNftTeamSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftTeamSections */
  miningNftTeamSections: Array<MiningNftTeamSection>;
  /** Retrieve multiple miningNftTeamSections using the Relay connection interface */
  miningNftTeamSectionsConnection: MiningNftTeamSectionConnection;
  /** Retrieve multiple miningNftWhitelistManualEntries */
  miningNftWhitelistManualEntries: Array<MiningNftWhitelistManualEntry>;
  /** Retrieve multiple miningNftWhitelistManualEntries using the Relay connection interface */
  miningNftWhitelistManualEntriesConnection: MiningNftWhitelistManualEntryConnection;
  /** Retrieve a single miningNftWhitelistManualEntry */
  miningNftWhitelistManualEntry?: Maybe<MiningNftWhitelistManualEntry>;
  /** Retrieve document version */
  miningNftWhitelistManualEntryVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single miningNftfaqSection */
  miningNftfaqSection?: Maybe<MiningNftfaqSection>;
  /** Retrieve document version */
  miningNftfaqSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple miningNftfaqSections */
  miningNftfaqSections: Array<MiningNftfaqSection>;
  /** Retrieve multiple miningNftfaqSections using the Relay connection interface */
  miningNftfaqSectionsConnection: MiningNftfaqSectionConnection;
  /** Retrieve multiple mintingCurrencies */
  mintingCurrencies: Array<MintingCurrency>;
  /** Retrieve multiple mintingCurrencies using the Relay connection interface */
  mintingCurrenciesConnection: MintingCurrencyConnection;
  /** Retrieve a single mintingCurrency */
  mintingCurrency?: Maybe<MintingCurrency>;
  /** Retrieve document version */
  mintingCurrencyVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single mintingProject */
  mintingProject?: Maybe<MintingProject>;
  /** Retrieve document version */
  mintingProjectVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple mintingProjects */
  mintingProjects: Array<MintingProject>;
  /** Retrieve multiple mintingProjects using the Relay connection interface */
  mintingProjectsConnection: MintingProjectConnection;
  /** Retrieve a single mintingStat */
  mintingStat?: Maybe<MintingStat>;
  /** Retrieve document version */
  mintingStatVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple mintingStats */
  mintingStats: Array<MintingStat>;
  /** Retrieve multiple mintingStats using the Relay connection interface */
  mintingStatsConnection: MintingStatConnection;
  /** Retrieve a single multiChainMintingProject */
  multiChainMintingProject?: Maybe<MultiChainMintingProject>;
  /** Retrieve document version */
  multiChainMintingProjectVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple multiChainMintingProjects */
  multiChainMintingProjects: Array<MultiChainMintingProject>;
  /** Retrieve multiple multiChainMintingProjects using the Relay connection interface */
  multiChainMintingProjectsConnection: MultiChainMintingProjectConnection;
  /** Retrieve a single nftCategoryDisplay */
  nftCategoryDisplay?: Maybe<NftCategoryDisplay>;
  /** Retrieve document version */
  nftCategoryDisplayVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple nftCategoryDisplays */
  nftCategoryDisplays: Array<NftCategoryDisplay>;
  /** Retrieve multiple nftCategoryDisplays using the Relay connection interface */
  nftCategoryDisplaysConnection: NftCategoryDisplayConnection;
  /** Retrieve a single nftCollection */
  nftCollection?: Maybe<NftCollection>;
  /** Retrieve document version */
  nftCollectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple nftCollections */
  nftCollections: Array<NftCollection>;
  /** Retrieve multiple nftCollections using the Relay connection interface */
  nftCollectionsConnection: NftCollectionConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Retrieve a single page */
  page?: Maybe<Page>;
  /** Retrieve a single pageHero */
  pageHero?: Maybe<PageHero>;
  /** Retrieve document version */
  pageHeroVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple pageHeroes */
  pageHeroes: Array<PageHero>;
  /** Retrieve multiple pageHeroes using the Relay connection interface */
  pageHeroesConnection: PageHeroConnection;
  /** Retrieve document version */
  pageVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple pages */
  pages: Array<Page>;
  /** Retrieve multiple pages using the Relay connection interface */
  pagesConnection: PageConnection;
  /** Retrieve a single partnerSection */
  partnerSection?: Maybe<PartnerSection>;
  /** Retrieve document version */
  partnerSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple partnerSections */
  partnerSections: Array<PartnerSection>;
  /** Retrieve multiple partnerSections using the Relay connection interface */
  partnerSectionsConnection: PartnerSectionConnection;
  /** Retrieve a single perksSection */
  perksSection?: Maybe<PerksSection>;
  /** Retrieve document version */
  perksSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple perksSections */
  perksSections: Array<PerksSection>;
  /** Retrieve multiple perksSections using the Relay connection interface */
  perksSectionsConnection: PerksSectionConnection;
  /** Retrieve a single poweredBySection */
  poweredBySection?: Maybe<PoweredBySection>;
  /** Retrieve document version */
  poweredBySectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple poweredBySections */
  poweredBySections: Array<PoweredBySection>;
  /** Retrieve multiple poweredBySections using the Relay connection interface */
  poweredBySectionsConnection: PoweredBySectionConnection;
  /** Retrieve multiple pupsPresaleCommunities */
  pupsPresaleCommunities: Array<PupsPresaleCommunity>;
  /** Retrieve multiple pupsPresaleCommunities using the Relay connection interface */
  pupsPresaleCommunitiesConnection: PupsPresaleCommunityConnection;
  /** Retrieve a single pupsPresaleCommunity */
  pupsPresaleCommunity?: Maybe<PupsPresaleCommunity>;
  /** Retrieve document version */
  pupsPresaleCommunityVersion?: Maybe<DocumentVersion>;
  /** Retrieve a single roadmap */
  roadmap?: Maybe<Roadmap>;
  /** Retrieve document version */
  roadmapVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple roadmaps */
  roadmaps: Array<Roadmap>;
  /** Retrieve multiple roadmaps using the Relay connection interface */
  roadmapsConnection: RoadmapConnection;
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<ScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: ScheduledOperationConnection;
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<ScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: ScheduledReleaseConnection;
  /** Retrieve a single teamSection */
  teamSection?: Maybe<TeamSection>;
  /** Retrieve document version */
  teamSectionVersion?: Maybe<DocumentVersion>;
  /** Retrieve multiple teamSections */
  teamSections: Array<TeamSection>;
  /** Retrieve multiple teamSections using the Relay connection interface */
  teamSectionsConnection: TeamSectionConnection;
  /** Retrieve a single user */
  user?: Maybe<User>;
  /** Retrieve multiple users */
  users: Array<User>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: UserConnection;
};

export type QueryAlphaNftCollectionTierArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AlphaNftCollectionTierWhereUniqueInput;
};

export type QueryAlphaNftCollectionTierVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAlphaNftCollectionTiersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AlphaNftCollectionTierOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AlphaNftCollectionTierWhereInput>;
};

export type QueryAlphaNftCollectionTiersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AlphaNftCollectionTierOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AlphaNftCollectionTierWhereInput>;
};

export type QueryAlphaSharesInformationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AlphaSharesInformationWhereUniqueInput;
};

export type QueryAlphaSharesInformationVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAlphaSharesInformationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AlphaSharesInformationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AlphaSharesInformationWhereInput>;
};

export type QueryAlphaSharesInformationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AlphaSharesInformationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AlphaSharesInformationWhereInput>;
};

export type QueryAssetArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: AssetWhereUniqueInput;
};

export type QueryAssetVersionArgs = {
  where: VersionWhereInput;
};

export type QueryAssetsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryAssetsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<AssetWhereInput>;
};

export type QueryChainArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ChainWhereUniqueInput;
};

export type QueryChainVersionArgs = {
  where: VersionWhereInput;
};

export type QueryChainsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ChainWhereInput>;
};

export type QueryChainsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ChainWhereInput>;
};

export type QueryCollectionCreatorArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CollectionCreatorWhereUniqueInput;
};

export type QueryCollectionCreatorVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCollectionCreatorsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

export type QueryCollectionCreatorsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CollectionCreatorOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CollectionCreatorWhereInput>;
};

export type QueryCtaSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: CtaSectionWhereUniqueInput;
};

export type QueryCtaSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryCtaSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CtaSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CtaSectionWhereInput>;
};

export type QueryCtaSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<CtaSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<CtaSectionWhereInput>;
};

export type QueryEntitiesArgs = {
  locales?: InputMaybe<Array<Locale>>;
  where: Array<EntityWhereInput>;
};

export type QueryFAqSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FaqSectionWhereUniqueInput;
};

export type QueryFAqSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryFAqSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type QueryFAqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FaqSectionWhereInput>;
};

export type QueryFeaturedCollectionGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FeaturedCollectionGroupWhereUniqueInput;
};

export type QueryFeaturedCollectionGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryFeaturedCollectionGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCollectionGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCollectionGroupWhereInput>;
};

export type QueryFeaturedCollectionGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCollectionGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCollectionGroupWhereInput>;
};

export type QueryFeaturedCreatorGroupArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: FeaturedCreatorGroupWhereUniqueInput;
};

export type QueryFeaturedCreatorGroupVersionArgs = {
  where: VersionWhereInput;
};

export type QueryFeaturedCreatorGroupsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCreatorGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCreatorGroupWhereInput>;
};

export type QueryFeaturedCreatorGroupsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<FeaturedCreatorGroupOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<FeaturedCreatorGroupWhereInput>;
};

export type QueryGettingStartedSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: GettingStartedSectionWhereUniqueInput;
};

export type QueryGettingStartedSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryGettingStartedSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GettingStartedSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<GettingStartedSectionWhereInput>;
};

export type QueryGettingStartedSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<GettingStartedSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<GettingStartedSectionWhereInput>;
};

export type QueryInfoCardsSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: InfoCardsSectionWhereUniqueInput;
};

export type QueryInfoCardsSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryInfoCardsSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<InfoCardsSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<InfoCardsSectionWhereInput>;
};

export type QueryInfoCardsSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<InfoCardsSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<InfoCardsSectionWhereInput>;
};

export type QueryLandingPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: LandingPageWhereUniqueInput;
};

export type QueryLandingPageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryLandingPagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<LandingPageWhereInput>;
};

export type QueryLandingPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<LandingPageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<LandingPageWhereInput>;
};

export type QueryMarketplaceAnnouncementBannerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MarketplaceAnnouncementBannerWhereUniqueInput;
};

export type QueryMarketplaceAnnouncementBannerVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMarketplaceAnnouncementBannersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MarketplaceAnnouncementBannerOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MarketplaceAnnouncementBannerWhereInput>;
};

export type QueryMarketplaceAnnouncementBannersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MarketplaceAnnouncementBannerOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MarketplaceAnnouncementBannerWhereInput>;
};

export type QueryMarketplaceContractArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MarketplaceContractWhereUniqueInput;
};

export type QueryMarketplaceContractVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMarketplaceContractsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MarketplaceContractOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MarketplaceContractWhereInput>;
};

export type QueryMarketplaceContractsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MarketplaceContractOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MarketplaceContractWhereInput>;
};

export type QueryMarketplacePageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MarketplacePageWhereUniqueInput;
};

export type QueryMarketplacePageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMarketplacePagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MarketplacePageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MarketplacePageWhereInput>;
};

export type QueryMarketplacePagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MarketplacePageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MarketplacePageWhereInput>;
};

export type QueryMenuArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MenuWhereUniqueInput;
};

export type QueryMenuVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMenusArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MenuOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryMenusConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MenuOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MenuWhereInput>;
};

export type QueryMiningBtcSupplyDemandModelSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningBtcSupplyDemandModelSectionWhereUniqueInput;
};

export type QueryMiningBtcSupplyDemandModelSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningBtcSupplyDemandModelSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningBtcSupplyDemandModelSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
};

export type QueryMiningBtcSupplyDemandModelSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningBtcSupplyDemandModelSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningBtcSupplyDemandModelSectionWhereInput>;
};

export type QueryMiningContainerArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningContainerWhereUniqueInput;
};

export type QueryMiningContainerVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningContainersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningContainerOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningContainerWhereInput>;
};

export type QueryMiningContainersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningContainerOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningContainerWhereInput>;
};

export type QueryMiningHeaderNoticeArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningHeaderNoticeWhereUniqueInput;
};

export type QueryMiningHeaderNoticeVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningHeaderNoticesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningHeaderNoticeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningHeaderNoticeWhereInput>;
};

export type QueryMiningHeaderNoticesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningHeaderNoticeOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningHeaderNoticeWhereInput>;
};

export type QueryMiningNftlpPageHeroSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftlpPageHeroSectionWhereUniqueInput;
};

export type QueryMiningNftlpPageHeroSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftlpPageHeroSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftlpPageHeroSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
};

export type QueryMiningNftlpPageHeroSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftlpPageHeroSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftlpPageHeroSectionWhereInput>;
};

export type QueryMiningNftAccessSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftAccessSectionWhereUniqueInput;
};

export type QueryMiningNftAccessSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftAccessSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftAccessSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftAccessSectionWhereInput>;
};

export type QueryMiningNftAccessSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftAccessSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftAccessSectionWhereInput>;
};

export type QueryMiningNftAdvantageSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftAdvantageSectionWhereUniqueInput;
};

export type QueryMiningNftAdvantageSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftAdvantageSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftAdvantageSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftAdvantageSectionWhereInput>;
};

export type QueryMiningNftAdvantageSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftAdvantageSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftAdvantageSectionWhereInput>;
};

export type QueryMiningNftAntimonyAdvantageSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftAntimonyAdvantageSectionWhereUniqueInput;
};

export type QueryMiningNftAntimonyAdvantageSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftAntimonyAdvantageSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftAntimonyAdvantageSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
};

export type QueryMiningNftAntimonyAdvantageSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftAntimonyAdvantageSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftAntimonyAdvantageSectionWhereInput>;
};

export type QueryMiningNftComparisonTableArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftComparisonTableWhereUniqueInput;
};

export type QueryMiningNftComparisonTableVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftComparisonTablesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftComparisonTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftComparisonTableWhereInput>;
};

export type QueryMiningNftComparisonTablesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftComparisonTableOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftComparisonTableWhereInput>;
};

export type QueryMiningNftEligibilitySectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftEligibilitySectionWhereUniqueInput;
};

export type QueryMiningNftEligibilitySectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftEligibilitySectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftEligibilitySectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
};

export type QueryMiningNftEligibilitySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftEligibilitySectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftEligibilitySectionWhereInput>;
};

export type QueryMiningNftProfitRoiSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftProfitRoiSectionWhereUniqueInput;
};

export type QueryMiningNftProfitRoiSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftProfitRoiSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftProfitRoiSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
};

export type QueryMiningNftProfitRoiSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftProfitRoiSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftProfitRoiSectionWhereInput>;
};

export type QueryMiningNftTeamSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftTeamSectionWhereUniqueInput;
};

export type QueryMiningNftTeamSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftTeamSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftTeamSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftTeamSectionWhereInput>;
};

export type QueryMiningNftTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftTeamSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftTeamSectionWhereInput>;
};

export type QueryMiningNftWhitelistManualEntriesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftWhitelistManualEntryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftWhitelistManualEntryWhereInput>;
};

export type QueryMiningNftWhitelistManualEntriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftWhitelistManualEntryOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftWhitelistManualEntryWhereInput>;
};

export type QueryMiningNftWhitelistManualEntryArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftWhitelistManualEntryWhereUniqueInput;
};

export type QueryMiningNftWhitelistManualEntryVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftfaqSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MiningNftfaqSectionWhereUniqueInput;
};

export type QueryMiningNftfaqSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMiningNftfaqSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftfaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftfaqSectionWhereInput>;
};

export type QueryMiningNftfaqSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MiningNftfaqSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MiningNftfaqSectionWhereInput>;
};

export type QueryMintingCurrenciesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

export type QueryMintingCurrenciesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingCurrencyOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingCurrencyWhereInput>;
};

export type QueryMintingCurrencyArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MintingCurrencyWhereUniqueInput;
};

export type QueryMintingCurrencyVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMintingProjectArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MintingProjectWhereUniqueInput;
};

export type QueryMintingProjectVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMintingProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingProjectWhereInput>;
};

export type QueryMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingProjectWhereInput>;
};

export type QueryMintingStatArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MintingStatWhereUniqueInput;
};

export type QueryMintingStatVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMintingStatsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingStatWhereInput>;
};

export type QueryMintingStatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MintingStatOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MintingStatWhereInput>;
};

export type QueryMultiChainMintingProjectArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: MultiChainMintingProjectWhereUniqueInput;
};

export type QueryMultiChainMintingProjectVersionArgs = {
  where: VersionWhereInput;
};

export type QueryMultiChainMintingProjectsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type QueryMultiChainMintingProjectsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<MultiChainMintingProjectOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type QueryNftCategoryDisplayArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: NftCategoryDisplayWhereUniqueInput;
};

export type QueryNftCategoryDisplayVersionArgs = {
  where: VersionWhereInput;
};

export type QueryNftCategoryDisplaysArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<NftCategoryDisplayOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<NftCategoryDisplayWhereInput>;
};

export type QueryNftCategoryDisplaysConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<NftCategoryDisplayOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<NftCategoryDisplayWhereInput>;
};

export type QueryNftCollectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: NftCollectionWhereUniqueInput;
};

export type QueryNftCollectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryNftCollectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type QueryNftCollectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<NftCollectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<NftCollectionWhereInput>;
};

export type QueryNodeArgs = {
  id: Scalars["ID"]["input"];
  locales?: Array<Locale>;
  stage?: Stage;
};

export type QueryPageArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PageWhereUniqueInput;
};

export type QueryPageHeroArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PageHeroWhereUniqueInput;
};

export type QueryPageHeroVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPageHeroesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PageHeroWhereInput>;
};

export type QueryPageHeroesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageHeroOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PageHeroWhereInput>;
};

export type QueryPageVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};

export type QueryPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PageOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PageWhereInput>;
};

export type QueryPartnerSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PartnerSectionWhereUniqueInput;
};

export type QueryPartnerSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPartnerSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PartnerSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PartnerSectionWhereInput>;
};

export type QueryPartnerSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PartnerSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PartnerSectionWhereInput>;
};

export type QueryPerksSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PerksSectionWhereUniqueInput;
};

export type QueryPerksSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPerksSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PerksSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PerksSectionWhereInput>;
};

export type QueryPerksSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PerksSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PerksSectionWhereInput>;
};

export type QueryPoweredBySectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PoweredBySectionWhereUniqueInput;
};

export type QueryPoweredBySectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryPoweredBySectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PoweredBySectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PoweredBySectionWhereInput>;
};

export type QueryPoweredBySectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PoweredBySectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PoweredBySectionWhereInput>;
};

export type QueryPupsPresaleCommunitiesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PupsPresaleCommunityOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PupsPresaleCommunityWhereInput>;
};

export type QueryPupsPresaleCommunitiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<PupsPresaleCommunityOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<PupsPresaleCommunityWhereInput>;
};

export type QueryPupsPresaleCommunityArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: PupsPresaleCommunityWhereUniqueInput;
};

export type QueryPupsPresaleCommunityVersionArgs = {
  where: VersionWhereInput;
};

export type QueryRoadmapArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: RoadmapWhereUniqueInput;
};

export type QueryRoadmapVersionArgs = {
  where: VersionWhereInput;
};

export type QueryRoadmapsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RoadmapOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<RoadmapWhereInput>;
};

export type QueryRoadmapsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<RoadmapOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<RoadmapWhereInput>;
};

export type QueryScheduledOperationArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledOperationWhereUniqueInput;
};

export type QueryScheduledOperationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledOperationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type QueryScheduledReleaseArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: ScheduledReleaseWhereUniqueInput;
};

export type QueryScheduledReleasesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryScheduledReleasesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<ScheduledReleaseOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<ScheduledReleaseWhereInput>;
};

export type QueryTeamSectionArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: TeamSectionWhereUniqueInput;
};

export type QueryTeamSectionVersionArgs = {
  where: VersionWhereInput;
};

export type QueryTeamSectionsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TeamSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<TeamSectionWhereInput>;
};

export type QueryTeamSectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<TeamSectionOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<TeamSectionWhereInput>;
};

export type QueryUserArgs = {
  locales?: Array<Locale>;
  stage?: Stage;
  where: UserWhereUniqueInput;
};

export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: Array<Locale>;
  orderBy?: InputMaybe<UserOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  stage?: Stage;
  where?: InputMaybe<UserWhereInput>;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type Rgba = {
  __typename?: "RGBA";
  a: Scalars["RGBATransparency"]["output"];
  b: Scalars["RGBAHue"]["output"];
  g: Scalars["RGBAHue"]["output"];
  r: Scalars["RGBAHue"]["output"];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type RgbaInput = {
  a: Scalars["RGBATransparency"]["input"];
  b: Scalars["RGBAHue"]["input"];
  g: Scalars["RGBAHue"]["input"];
  r: Scalars["RGBAHue"]["input"];
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type RichText = {
  __typename?: "RichText";
  /** Returns HTMl representation */
  html: Scalars["String"]["output"];
  /** Returns Markdown representation */
  markdown: Scalars["String"]["output"];
  /** Returns AST representation */
  raw: Scalars["RichTextAST"]["output"];
  /** Returns plain-text contents of RichText */
  text: Scalars["String"]["output"];
};

export type RichTextImageRow = Entity & {
  __typename?: "RichTextImageRow";
  backgroundColor?: Maybe<Colors>;
  backgroundImage?: Maybe<Asset>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  image?: Maybe<Asset>;
  imageSide?: Maybe<ImageSide>;
  /** System stage field */
  stage: Stage;
  text?: Maybe<RichText>;
};

export type RichTextImageRowBackgroundImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RichTextImageRowImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RichTextImageRowConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: RichTextImageRowWhereUniqueInput;
};

/** A connection to a list of items. */
export type RichTextImageRowConnection = {
  __typename?: "RichTextImageRowConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<RichTextImageRowEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RichTextImageRowCreateInput = {
  backgroundColor?: InputMaybe<Colors>;
  backgroundImage?: InputMaybe<AssetCreateOneInlineInput>;
  image?: InputMaybe<AssetCreateOneInlineInput>;
  imageSide?: InputMaybe<ImageSide>;
  text?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type RichTextImageRowCreateManyInlineInput = {
  /** Create and connect multiple existing RichTextImageRow documents */
  create?: InputMaybe<Array<RichTextImageRowCreateInput>>;
};

export type RichTextImageRowCreateOneInlineInput = {
  /** Create and connect one RichTextImageRow document */
  create?: InputMaybe<RichTextImageRowCreateInput>;
};

export type RichTextImageRowCreateWithPositionInput = {
  /** Document to create */
  data: RichTextImageRowCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type RichTextImageRowEdge = {
  __typename?: "RichTextImageRowEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: RichTextImageRow;
};

/** Identifies documents */
export type RichTextImageRowManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextImageRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextImageRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextImageRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundColor?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundColor_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  imageSide?: InputMaybe<ImageSide>;
  /** All values that are contained in given list. */
  imageSide_in?: InputMaybe<Array<InputMaybe<ImageSide>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageSide_not?: InputMaybe<ImageSide>;
  /** All values that are not contained in given list. */
  imageSide_not_in?: InputMaybe<Array<InputMaybe<ImageSide>>>;
};

export enum RichTextImageRowOrderByInput {
  backgroundColor_ASC = "backgroundColor_ASC",
  backgroundColor_DESC = "backgroundColor_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  imageSide_ASC = "imageSide_ASC",
  imageSide_DESC = "imageSide_DESC",
}

export type RichTextImageRowParent = LandingPage;

export type RichTextImageRowParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>;
};

export type RichTextImageRowParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>;
};

export type RichTextImageRowParentCreateManyInlineInput = {
  /** Connect multiple existing RichTextImageRowParent documents */
  connect?: InputMaybe<Array<RichTextImageRowParentWhereUniqueInput>>;
  /** Create and connect multiple existing RichTextImageRowParent documents */
  create?: InputMaybe<Array<RichTextImageRowParentCreateInput>>;
};

export type RichTextImageRowParentCreateOneInlineInput = {
  /** Connect one existing RichTextImageRowParent document */
  connect?: InputMaybe<RichTextImageRowParentWhereUniqueInput>;
  /** Create and connect one RichTextImageRowParent document */
  create?: InputMaybe<RichTextImageRowParentCreateInput>;
};

export type RichTextImageRowParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>;
};

export type RichTextImageRowParentUpdateManyInlineInput = {
  /** Connect multiple existing RichTextImageRowParent documents */
  connect?: InputMaybe<Array<RichTextImageRowParentConnectInput>>;
  /** Create and connect multiple RichTextImageRowParent documents */
  create?: InputMaybe<Array<RichTextImageRowParentCreateInput>>;
  /** Delete multiple RichTextImageRowParent documents */
  delete?: InputMaybe<Array<RichTextImageRowParentWhereUniqueInput>>;
  /** Disconnect multiple RichTextImageRowParent documents */
  disconnect?: InputMaybe<Array<RichTextImageRowParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing RichTextImageRowParent documents */
  set?: InputMaybe<Array<RichTextImageRowParentWhereUniqueInput>>;
  /** Update multiple RichTextImageRowParent documents */
  update?: InputMaybe<
    Array<RichTextImageRowParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple RichTextImageRowParent documents */
  upsert?: InputMaybe<
    Array<RichTextImageRowParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type RichTextImageRowParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>;
};

export type RichTextImageRowParentUpdateOneInlineInput = {
  /** Connect existing RichTextImageRowParent document */
  connect?: InputMaybe<RichTextImageRowParentWhereUniqueInput>;
  /** Create and connect one RichTextImageRowParent document */
  create?: InputMaybe<RichTextImageRowParentCreateInput>;
  /** Delete currently connected RichTextImageRowParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected RichTextImageRowParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single RichTextImageRowParent document */
  update?: InputMaybe<RichTextImageRowParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single RichTextImageRowParent document */
  upsert?: InputMaybe<RichTextImageRowParentUpsertWithNestedWhereUniqueInput>;
};

export type RichTextImageRowParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>;
};

export type RichTextImageRowParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>;
};

export type RichTextImageRowParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>;
};

export type RichTextImageRowParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>;
};

export type RichTextImageRowUpdateInput = {
  backgroundColor?: InputMaybe<Colors>;
  backgroundImage?: InputMaybe<AssetUpdateOneInlineInput>;
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  imageSide?: InputMaybe<ImageSide>;
  text?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type RichTextImageRowUpdateManyInlineInput = {
  /** Create and connect multiple RichTextImageRow component instances */
  create?: InputMaybe<Array<RichTextImageRowCreateWithPositionInput>>;
  /** Delete multiple RichTextImageRow documents */
  delete?: InputMaybe<Array<RichTextImageRowWhereUniqueInput>>;
  /** Update multiple RichTextImageRow component instances */
  update?: InputMaybe<
    Array<RichTextImageRowUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple RichTextImageRow component instances */
  upsert?: InputMaybe<
    Array<RichTextImageRowUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type RichTextImageRowUpdateManyInput = {
  backgroundColor?: InputMaybe<Colors>;
  imageSide?: InputMaybe<ImageSide>;
  text?: InputMaybe<Scalars["RichTextAST"]["input"]>;
};

export type RichTextImageRowUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RichTextImageRowUpdateManyInput;
  /** Document search */
  where: RichTextImageRowWhereInput;
};

export type RichTextImageRowUpdateOneInlineInput = {
  /** Create and connect one RichTextImageRow document */
  create?: InputMaybe<RichTextImageRowCreateInput>;
  /** Delete currently connected RichTextImageRow document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single RichTextImageRow document */
  update?: InputMaybe<RichTextImageRowUpdateWithNestedWhereUniqueInput>;
  /** Upsert single RichTextImageRow document */
  upsert?: InputMaybe<RichTextImageRowUpsertWithNestedWhereUniqueInput>;
};

export type RichTextImageRowUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<RichTextImageRowUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: RichTextImageRowWhereUniqueInput;
};

export type RichTextImageRowUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RichTextImageRowUpdateInput;
  /** Unique document search */
  where: RichTextImageRowWhereUniqueInput;
};

export type RichTextImageRowUpsertInput = {
  /** Create document if it didn't exist */
  create: RichTextImageRowCreateInput;
  /** Update document if it exists */
  update: RichTextImageRowUpdateInput;
};

export type RichTextImageRowUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<RichTextImageRowUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: RichTextImageRowWhereUniqueInput;
};

export type RichTextImageRowUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RichTextImageRowUpsertInput;
  /** Unique document search */
  where: RichTextImageRowWhereUniqueInput;
};

/** Identifies documents */
export type RichTextImageRowWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RichTextImageRowWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RichTextImageRowWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RichTextImageRowWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  backgroundColor?: InputMaybe<Colors>;
  /** All values that are contained in given list. */
  backgroundColor_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  /** Any other value that exists and is not equal to the given value. */
  backgroundColor_not?: InputMaybe<Colors>;
  /** All values that are not contained in given list. */
  backgroundColor_not_in?: InputMaybe<Array<InputMaybe<Colors>>>;
  backgroundImage?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  imageSide?: InputMaybe<ImageSide>;
  /** All values that are contained in given list. */
  imageSide_in?: InputMaybe<Array<InputMaybe<ImageSide>>>;
  /** Any other value that exists and is not equal to the given value. */
  imageSide_not?: InputMaybe<ImageSide>;
  /** All values that are not contained in given list. */
  imageSide_not_in?: InputMaybe<Array<InputMaybe<ImageSide>>>;
};

/** References RichTextImageRow record uniquely */
export type RichTextImageRowWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Roadmap = Entity &
  Node & {
    __typename?: "Roadmap";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<Roadmap>;
    /** List of Roadmap versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    roadmapCategory: Array<RoadmapItemCategories>;
    /** Optional rich text to display below the title of the roadmap that will give users a general idea of what the roadmap is for. */
    roadmapDescription?: Maybe<RichText>;
    roadmapItems: Array<RoadmapItem>;
    roadmapTitle?: Maybe<Scalars["String"]["output"]>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type RoadmapCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RoadmapDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type RoadmapHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type RoadmapPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RoadmapRoadmapItemsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<RoadmapItemOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<RoadmapItemWhereInput>;
};

export type RoadmapScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type RoadmapUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type RoadmapConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: RoadmapWhereUniqueInput;
};

/** A connection to a list of items. */
export type RoadmapConnection = {
  __typename?: "RoadmapConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<RoadmapEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RoadmapCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  roadmapCategory?: InputMaybe<Array<RoadmapItemCategories>>;
  roadmapDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  roadmapItems?: InputMaybe<RoadmapItemCreateManyInlineInput>;
  roadmapTitle?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type RoadmapCreateManyInlineInput = {
  /** Connect multiple existing Roadmap documents */
  connect?: InputMaybe<Array<RoadmapWhereUniqueInput>>;
  /** Create and connect multiple existing Roadmap documents */
  create?: InputMaybe<Array<RoadmapCreateInput>>;
};

export type RoadmapCreateOneInlineInput = {
  /** Connect one existing Roadmap document */
  connect?: InputMaybe<RoadmapWhereUniqueInput>;
  /** Create and connect one Roadmap document */
  create?: InputMaybe<RoadmapCreateInput>;
};

/** An edge in a connection. */
export type RoadmapEdge = {
  __typename?: "RoadmapEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: Roadmap;
};

export type RoadmapItem = Entity & {
  __typename?: "RoadmapItem";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  roadmapItemDescription?: Maybe<RichText>;
  roadmapItemPlannedReleaseDate?: Maybe<Scalars["Date"]["output"]>;
  /** Place one or more sample images of the roadmap item's concept here, it will then be available within a gallery / lightbox interface on the roadmap for users to see. */
  roadmapItemSampleImages: Array<Asset>;
  roadmapItemStartDate?: Maybe<Scalars["Date"]["output"]>;
  roadmapItemTitle?: Maybe<Scalars["String"]["output"]>;
  roadmapPriority?: Maybe<RoadmapItemPriorities>;
  /** System stage field */
  stage: Stage;
};

export type RoadmapItemRoadmapItemSampleImagesArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<AssetOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<AssetWhereInput>;
};

export enum RoadmapItemCategories {
  Antimony = "Antimony",
  Defi = "Defi",
  Launchpad = "Launchpad",
  Marketplace = "Marketplace",
  Mining = "Mining",
}

export type RoadmapItemConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: RoadmapItemWhereUniqueInput;
};

/** A connection to a list of items. */
export type RoadmapItemConnection = {
  __typename?: "RoadmapItemConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<RoadmapItemEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type RoadmapItemCreateInput = {
  roadmapItemDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  roadmapItemPlannedReleaseDate?: InputMaybe<Scalars["Date"]["input"]>;
  roadmapItemSampleImages?: InputMaybe<AssetCreateManyInlineInput>;
  roadmapItemStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  roadmapItemTitle?: InputMaybe<Scalars["String"]["input"]>;
  roadmapPriority?: InputMaybe<RoadmapItemPriorities>;
};

export type RoadmapItemCreateManyInlineInput = {
  /** Create and connect multiple existing RoadmapItem documents */
  create?: InputMaybe<Array<RoadmapItemCreateInput>>;
};

export type RoadmapItemCreateOneInlineInput = {
  /** Create and connect one RoadmapItem document */
  create?: InputMaybe<RoadmapItemCreateInput>;
};

export type RoadmapItemCreateWithPositionInput = {
  /** Document to create */
  data: RoadmapItemCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type RoadmapItemEdge = {
  __typename?: "RoadmapItemEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: RoadmapItem;
};

/** Identifies documents */
export type RoadmapItemManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RoadmapItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RoadmapItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RoadmapItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  roadmapItemPlannedReleaseDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  roadmapItemPlannedReleaseDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  roadmapItemPlannedReleaseDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  roadmapItemPlannedReleaseDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  roadmapItemPlannedReleaseDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  roadmapItemPlannedReleaseDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapItemPlannedReleaseDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapItemPlannedReleaseDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  roadmapItemSampleImages_every?: InputMaybe<AssetWhereInput>;
  roadmapItemSampleImages_none?: InputMaybe<AssetWhereInput>;
  roadmapItemSampleImages_some?: InputMaybe<AssetWhereInput>;
  roadmapItemStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  roadmapItemStartDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  roadmapItemStartDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  roadmapItemStartDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  roadmapItemStartDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  roadmapItemStartDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapItemStartDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapItemStartDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  roadmapItemTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  roadmapItemTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  roadmapItemTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  roadmapItemTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  roadmapItemTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  roadmapItemTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  roadmapItemTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapItemTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  roadmapItemTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  roadmapItemTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  roadmapPriority?: InputMaybe<RoadmapItemPriorities>;
  /** All values that are contained in given list. */
  roadmapPriority_in?: InputMaybe<Array<InputMaybe<RoadmapItemPriorities>>>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapPriority_not?: InputMaybe<RoadmapItemPriorities>;
  /** All values that are not contained in given list. */
  roadmapPriority_not_in?: InputMaybe<Array<InputMaybe<RoadmapItemPriorities>>>;
};

export enum RoadmapItemOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  roadmapItemPlannedReleaseDate_ASC = "roadmapItemPlannedReleaseDate_ASC",
  roadmapItemPlannedReleaseDate_DESC = "roadmapItemPlannedReleaseDate_DESC",
  roadmapItemStartDate_ASC = "roadmapItemStartDate_ASC",
  roadmapItemStartDate_DESC = "roadmapItemStartDate_DESC",
  roadmapItemTitle_ASC = "roadmapItemTitle_ASC",
  roadmapItemTitle_DESC = "roadmapItemTitle_DESC",
  roadmapPriority_ASC = "roadmapPriority_ASC",
  roadmapPriority_DESC = "roadmapPriority_DESC",
}

export type RoadmapItemParent = Roadmap;

export type RoadmapItemParentConnectInput = {
  Roadmap?: InputMaybe<RoadmapConnectInput>;
};

export type RoadmapItemParentCreateInput = {
  Roadmap?: InputMaybe<RoadmapCreateInput>;
};

export type RoadmapItemParentCreateManyInlineInput = {
  /** Connect multiple existing RoadmapItemParent documents */
  connect?: InputMaybe<Array<RoadmapItemParentWhereUniqueInput>>;
  /** Create and connect multiple existing RoadmapItemParent documents */
  create?: InputMaybe<Array<RoadmapItemParentCreateInput>>;
};

export type RoadmapItemParentCreateOneInlineInput = {
  /** Connect one existing RoadmapItemParent document */
  connect?: InputMaybe<RoadmapItemParentWhereUniqueInput>;
  /** Create and connect one RoadmapItemParent document */
  create?: InputMaybe<RoadmapItemParentCreateInput>;
};

export type RoadmapItemParentUpdateInput = {
  Roadmap?: InputMaybe<RoadmapUpdateInput>;
};

export type RoadmapItemParentUpdateManyInlineInput = {
  /** Connect multiple existing RoadmapItemParent documents */
  connect?: InputMaybe<Array<RoadmapItemParentConnectInput>>;
  /** Create and connect multiple RoadmapItemParent documents */
  create?: InputMaybe<Array<RoadmapItemParentCreateInput>>;
  /** Delete multiple RoadmapItemParent documents */
  delete?: InputMaybe<Array<RoadmapItemParentWhereUniqueInput>>;
  /** Disconnect multiple RoadmapItemParent documents */
  disconnect?: InputMaybe<Array<RoadmapItemParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing RoadmapItemParent documents */
  set?: InputMaybe<Array<RoadmapItemParentWhereUniqueInput>>;
  /** Update multiple RoadmapItemParent documents */
  update?: InputMaybe<Array<RoadmapItemParentUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple RoadmapItemParent documents */
  upsert?: InputMaybe<Array<RoadmapItemParentUpsertWithNestedWhereUniqueInput>>;
};

export type RoadmapItemParentUpdateManyWithNestedWhereInput = {
  Roadmap?: InputMaybe<RoadmapUpdateManyWithNestedWhereInput>;
};

export type RoadmapItemParentUpdateOneInlineInput = {
  /** Connect existing RoadmapItemParent document */
  connect?: InputMaybe<RoadmapItemParentWhereUniqueInput>;
  /** Create and connect one RoadmapItemParent document */
  create?: InputMaybe<RoadmapItemParentCreateInput>;
  /** Delete currently connected RoadmapItemParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected RoadmapItemParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single RoadmapItemParent document */
  update?: InputMaybe<RoadmapItemParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single RoadmapItemParent document */
  upsert?: InputMaybe<RoadmapItemParentUpsertWithNestedWhereUniqueInput>;
};

export type RoadmapItemParentUpdateWithNestedWhereUniqueInput = {
  Roadmap?: InputMaybe<RoadmapUpdateWithNestedWhereUniqueInput>;
};

export type RoadmapItemParentUpsertWithNestedWhereUniqueInput = {
  Roadmap?: InputMaybe<RoadmapUpsertWithNestedWhereUniqueInput>;
};

export type RoadmapItemParentWhereInput = {
  Roadmap?: InputMaybe<RoadmapWhereInput>;
};

export type RoadmapItemParentWhereUniqueInput = {
  Roadmap?: InputMaybe<RoadmapWhereUniqueInput>;
};

export enum RoadmapItemPriorities {
  Blocker = "Blocker",
  Critical = "Critical",
  High = "High",
  Low = "Low",
  Medium = "Medium",
}

export type RoadmapItemUpdateInput = {
  roadmapItemDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  roadmapItemPlannedReleaseDate?: InputMaybe<Scalars["Date"]["input"]>;
  roadmapItemSampleImages?: InputMaybe<AssetUpdateManyInlineInput>;
  roadmapItemStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  roadmapItemTitle?: InputMaybe<Scalars["String"]["input"]>;
  roadmapPriority?: InputMaybe<RoadmapItemPriorities>;
};

export type RoadmapItemUpdateManyInlineInput = {
  /** Create and connect multiple RoadmapItem component instances */
  create?: InputMaybe<Array<RoadmapItemCreateWithPositionInput>>;
  /** Delete multiple RoadmapItem documents */
  delete?: InputMaybe<Array<RoadmapItemWhereUniqueInput>>;
  /** Update multiple RoadmapItem component instances */
  update?: InputMaybe<
    Array<RoadmapItemUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple RoadmapItem component instances */
  upsert?: InputMaybe<
    Array<RoadmapItemUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type RoadmapItemUpdateManyInput = {
  roadmapItemDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  roadmapItemPlannedReleaseDate?: InputMaybe<Scalars["Date"]["input"]>;
  roadmapItemStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  roadmapItemTitle?: InputMaybe<Scalars["String"]["input"]>;
  roadmapPriority?: InputMaybe<RoadmapItemPriorities>;
};

export type RoadmapItemUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RoadmapItemUpdateManyInput;
  /** Document search */
  where: RoadmapItemWhereInput;
};

export type RoadmapItemUpdateOneInlineInput = {
  /** Create and connect one RoadmapItem document */
  create?: InputMaybe<RoadmapItemCreateInput>;
  /** Delete currently connected RoadmapItem document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single RoadmapItem document */
  update?: InputMaybe<RoadmapItemUpdateWithNestedWhereUniqueInput>;
  /** Upsert single RoadmapItem document */
  upsert?: InputMaybe<RoadmapItemUpsertWithNestedWhereUniqueInput>;
};

export type RoadmapItemUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<RoadmapItemUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: RoadmapItemWhereUniqueInput;
};

export type RoadmapItemUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RoadmapItemUpdateInput;
  /** Unique document search */
  where: RoadmapItemWhereUniqueInput;
};

export type RoadmapItemUpsertInput = {
  /** Create document if it didn't exist */
  create: RoadmapItemCreateInput;
  /** Update document if it exists */
  update: RoadmapItemUpdateInput;
};

export type RoadmapItemUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<RoadmapItemUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: RoadmapItemWhereUniqueInput;
};

export type RoadmapItemUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RoadmapItemUpsertInput;
  /** Unique document search */
  where: RoadmapItemWhereUniqueInput;
};

/** Identifies documents */
export type RoadmapItemWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RoadmapItemWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RoadmapItemWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RoadmapItemWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  roadmapItemPlannedReleaseDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  roadmapItemPlannedReleaseDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  roadmapItemPlannedReleaseDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  roadmapItemPlannedReleaseDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  roadmapItemPlannedReleaseDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  roadmapItemPlannedReleaseDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapItemPlannedReleaseDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapItemPlannedReleaseDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  roadmapItemSampleImages_every?: InputMaybe<AssetWhereInput>;
  roadmapItemSampleImages_none?: InputMaybe<AssetWhereInput>;
  roadmapItemSampleImages_some?: InputMaybe<AssetWhereInput>;
  roadmapItemStartDate?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than the given value. */
  roadmapItemStartDate_gt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values greater than or equal the given value. */
  roadmapItemStartDate_gte?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are contained in given list. */
  roadmapItemStartDate_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  /** All values less than the given value. */
  roadmapItemStartDate_lt?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values less than or equal the given value. */
  roadmapItemStartDate_lte?: InputMaybe<Scalars["Date"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapItemStartDate_not?: InputMaybe<Scalars["Date"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapItemStartDate_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Date"]["input"]>>
  >;
  roadmapItemTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  roadmapItemTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  roadmapItemTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  roadmapItemTitle_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  roadmapItemTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  roadmapItemTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  roadmapItemTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapItemTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  roadmapItemTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  roadmapItemTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  roadmapPriority?: InputMaybe<RoadmapItemPriorities>;
  /** All values that are contained in given list. */
  roadmapPriority_in?: InputMaybe<Array<InputMaybe<RoadmapItemPriorities>>>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapPriority_not?: InputMaybe<RoadmapItemPriorities>;
  /** All values that are not contained in given list. */
  roadmapPriority_not_in?: InputMaybe<Array<InputMaybe<RoadmapItemPriorities>>>;
};

/** References RoadmapItem record uniquely */
export type RoadmapItemWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Identifies documents */
export type RoadmapManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RoadmapWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RoadmapWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RoadmapWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<RoadmapWhereStageInput>;
  documentInStages_none?: InputMaybe<RoadmapWhereStageInput>;
  documentInStages_some?: InputMaybe<RoadmapWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  roadmapCategory?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  roadmapCategory_contains_all?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  roadmapCategory_contains_none?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  roadmapCategory_contains_some?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  roadmapCategory_not?: InputMaybe<Array<RoadmapItemCategories>>;
  roadmapItems_every?: InputMaybe<RoadmapItemWhereInput>;
  roadmapItems_none?: InputMaybe<RoadmapItemWhereInput>;
  roadmapItems_some?: InputMaybe<RoadmapItemWhereInput>;
  roadmapTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  roadmapTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  roadmapTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  roadmapTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  roadmapTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  roadmapTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  roadmapTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  roadmapTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum RoadmapOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  roadmapCategory_ASC = "roadmapCategory_ASC",
  roadmapCategory_DESC = "roadmapCategory_DESC",
  roadmapTitle_ASC = "roadmapTitle_ASC",
  roadmapTitle_DESC = "roadmapTitle_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type RoadmapUpdateInput = {
  roadmapCategory?: InputMaybe<Array<RoadmapItemCategories>>;
  roadmapDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  roadmapItems?: InputMaybe<RoadmapItemUpdateManyInlineInput>;
  roadmapTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type RoadmapUpdateManyInlineInput = {
  /** Connect multiple existing Roadmap documents */
  connect?: InputMaybe<Array<RoadmapConnectInput>>;
  /** Create and connect multiple Roadmap documents */
  create?: InputMaybe<Array<RoadmapCreateInput>>;
  /** Delete multiple Roadmap documents */
  delete?: InputMaybe<Array<RoadmapWhereUniqueInput>>;
  /** Disconnect multiple Roadmap documents */
  disconnect?: InputMaybe<Array<RoadmapWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing Roadmap documents */
  set?: InputMaybe<Array<RoadmapWhereUniqueInput>>;
  /** Update multiple Roadmap documents */
  update?: InputMaybe<Array<RoadmapUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Roadmap documents */
  upsert?: InputMaybe<Array<RoadmapUpsertWithNestedWhereUniqueInput>>;
};

export type RoadmapUpdateManyInput = {
  roadmapCategory?: InputMaybe<Array<RoadmapItemCategories>>;
  roadmapDescription?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  roadmapTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type RoadmapUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: RoadmapUpdateManyInput;
  /** Document search */
  where: RoadmapWhereInput;
};

export type RoadmapUpdateOneInlineInput = {
  /** Connect existing Roadmap document */
  connect?: InputMaybe<RoadmapWhereUniqueInput>;
  /** Create and connect one Roadmap document */
  create?: InputMaybe<RoadmapCreateInput>;
  /** Delete currently connected Roadmap document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected Roadmap document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single Roadmap document */
  update?: InputMaybe<RoadmapUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Roadmap document */
  upsert?: InputMaybe<RoadmapUpsertWithNestedWhereUniqueInput>;
};

export type RoadmapUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: RoadmapUpdateInput;
  /** Unique document search */
  where: RoadmapWhereUniqueInput;
};

export type RoadmapUpsertInput = {
  /** Create document if it didn't exist */
  create: RoadmapCreateInput;
  /** Update document if it exists */
  update: RoadmapUpdateInput;
};

export type RoadmapUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: RoadmapUpsertInput;
  /** Unique document search */
  where: RoadmapWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type RoadmapWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type RoadmapWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RoadmapWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RoadmapWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RoadmapWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<RoadmapWhereStageInput>;
  documentInStages_none?: InputMaybe<RoadmapWhereStageInput>;
  documentInStages_some?: InputMaybe<RoadmapWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  roadmapCategory?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array contains *all* items provided to the filter */
  roadmapCategory_contains_all?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  roadmapCategory_contains_none?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array contains at least one item provided to the filter */
  roadmapCategory_contains_some?: InputMaybe<Array<RoadmapItemCategories>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  roadmapCategory_not?: InputMaybe<Array<RoadmapItemCategories>>;
  roadmapItems_every?: InputMaybe<RoadmapItemWhereInput>;
  roadmapItems_none?: InputMaybe<RoadmapItemWhereInput>;
  roadmapItems_some?: InputMaybe<RoadmapItemWhereInput>;
  roadmapTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  roadmapTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  roadmapTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  roadmapTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  roadmapTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  roadmapTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  roadmapTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  roadmapTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  roadmapTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  roadmapTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type RoadmapWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<RoadmapWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<RoadmapWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<RoadmapWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<RoadmapWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References Roadmap record uniquely */
export type RoadmapWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Scheduled Operation system model */
export type ScheduledOperation = Entity &
  Node & {
    __typename?: "ScheduledOperation";
    affectedDocuments: Array<ScheduledOperationAffectedDocument>;
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Operation description */
    description?: Maybe<Scalars["String"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<ScheduledOperation>;
    /** Operation error message */
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    /** Raw operation payload including all details, this field is subject to change */
    rawPayload: Scalars["Json"]["output"];
    /** The release this operation is scheduled for */
    release?: Maybe<ScheduledRelease>;
    /** System stage field */
    stage: Stage;
    /** operation Status */
    status: ScheduledOperationStatus;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Scheduled Operation system model */
export type ScheduledOperationAffectedDocumentsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

/** Scheduled Operation system model */
export type ScheduledOperationCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Scheduled Operation system model */
export type ScheduledOperationPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationReleaseArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Operation system model */
export type ScheduledOperationUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledOperationAffectedDocument =
  | AlphaNftCollectionTier
  | AlphaSharesInformation
  | Asset
  | Chain
  | CollectionCreator
  | CtaSection
  | FaqSection
  | FeaturedCollectionGroup
  | FeaturedCreatorGroup
  | GettingStartedSection
  | InfoCardsSection
  | LandingPage
  | MarketplaceAnnouncementBanner
  | MarketplaceContract
  | MarketplacePage
  | Menu
  | MiningBtcSupplyDemandModelSection
  | MiningContainer
  | MiningHeaderNotice
  | MiningNftlpPageHeroSection
  | MiningNftAccessSection
  | MiningNftAdvantageSection
  | MiningNftAntimonyAdvantageSection
  | MiningNftComparisonTable
  | MiningNftEligibilitySection
  | MiningNftProfitRoiSection
  | MiningNftTeamSection
  | MiningNftWhitelistManualEntry
  | MiningNftfaqSection
  | MintingCurrency
  | MintingProject
  | MintingStat
  | MultiChainMintingProject
  | NftCategoryDisplay
  | NftCollection
  | Page
  | PageHero
  | PartnerSection
  | PerksSection
  | PoweredBySection
  | PupsPresaleCommunity
  | Roadmap
  | TeamSection;

export type ScheduledOperationConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledOperationWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledOperationConnection = {
  __typename?: "ScheduledOperationConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledOperationEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type ScheduledOperationEdge = {
  __typename?: "ScheduledOperationEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ScheduledOperation;
};

/** Identifies documents */
export type ScheduledOperationManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledOperationOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  errorMessage_ASC = "errorMessage_ASC",
  errorMessage_DESC = "errorMessage_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** System Scheduled Operation Status */
export enum ScheduledOperationStatus {
  CANCELED = "CANCELED",
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export type ScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: InputMaybe<Array<ScheduledOperationConnectInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: InputMaybe<Array<ScheduledOperationWhereUniqueInput>>;
};

export type ScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: InputMaybe<ScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type ScheduledOperationWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledOperationWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  /** All values containing the given json path. */
  rawPayload_json_path_exists?: InputMaybe<Scalars["String"]["input"]>;
  /**
   * Recursively tries to find the provided JSON scalar value inside the field.
   * It does use an exact match when comparing values.
   * If you pass `null` as value the filter will be ignored.
   * Note: This filter fails if you try to look for a non scalar JSON value!
   */
  rawPayload_value_recursive?: InputMaybe<Scalars["Json"]["input"]>;
  release?: InputMaybe<ScheduledReleaseWhereInput>;
  status?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledOperationStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledOperationStatus>>>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledOperation record uniquely */
export type ScheduledOperationWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Scheduled Release system model */
export type ScheduledRelease = Entity &
  Node & {
    __typename?: "ScheduledRelease";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Release description */
    description?: Maybe<Scalars["String"]["output"]>;
    /** Get the document in other stages */
    documentInStages: Array<ScheduledRelease>;
    /** Release error message */
    errorMessage?: Maybe<Scalars["String"]["output"]>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** Whether scheduled release should be run */
    isActive: Scalars["Boolean"]["output"];
    /** Whether scheduled release is implicit */
    isImplicit: Scalars["Boolean"]["output"];
    /** Operations to run with this release */
    operations: Array<ScheduledOperation>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    /** Release date and time */
    releaseAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** Release Status */
    status: ScheduledReleaseStatus;
    /** Release Title */
    title?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

/** Scheduled Release system model */
export type ScheduledReleaseCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

/** Scheduled Release system model */
export type ScheduledReleaseOperationsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ScheduledOperationOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

/** Scheduled Release system model */
export type ScheduledReleasePublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

/** Scheduled Release system model */
export type ScheduledReleaseUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type ScheduledReleaseConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ScheduledReleaseWhereUniqueInput;
};

/** A connection to a list of items. */
export type ScheduledReleaseConnection = {
  __typename?: "ScheduledReleaseConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ScheduledReleaseEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ScheduledReleaseCreateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ScheduledReleaseCreateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
};

export type ScheduledReleaseCreateOneInlineInput = {
  /** Connect one existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
};

/** An edge in a connection. */
export type ScheduledReleaseEdge = {
  __typename?: "ScheduledReleaseEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ScheduledRelease;
};

/** Identifies documents */
export type ScheduledReleaseManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  isImplicit?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum ScheduledReleaseOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  description_ASC = "description_ASC",
  description_DESC = "description_DESC",
  errorMessage_ASC = "errorMessage_ASC",
  errorMessage_DESC = "errorMessage_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isActive_ASC = "isActive_ASC",
  isActive_DESC = "isActive_DESC",
  isImplicit_ASC = "isImplicit_ASC",
  isImplicit_DESC = "isImplicit_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  releaseAt_ASC = "releaseAt_ASC",
  releaseAt_DESC = "releaseAt_DESC",
  status_ASC = "status_ASC",
  status_DESC = "status_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

/** System Scheduled Release Status */
export enum ScheduledReleaseStatus {
  COMPLETED = "COMPLETED",
  FAILED = "FAILED",
  IN_PROGRESS = "IN_PROGRESS",
  PENDING = "PENDING",
}

export type ScheduledReleaseUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ScheduledReleaseUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledRelease documents */
  connect?: InputMaybe<Array<ScheduledReleaseConnectInput>>;
  /** Create and connect multiple ScheduledRelease documents */
  create?: InputMaybe<Array<ScheduledReleaseCreateInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: InputMaybe<Array<ScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: InputMaybe<Array<ScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: InputMaybe<Array<ScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
};

export type ScheduledReleaseUpdateManyInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ScheduledReleaseUpdateManyInput;
  /** Document search */
  where: ScheduledReleaseWhereInput;
};

export type ScheduledReleaseUpdateOneInlineInput = {
  /** Connect existing ScheduledRelease document */
  connect?: InputMaybe<ScheduledReleaseWhereUniqueInput>;
  /** Create and connect one ScheduledRelease document */
  create?: InputMaybe<ScheduledReleaseCreateInput>;
  /** Delete currently connected ScheduledRelease document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ScheduledRelease document */
  update?: InputMaybe<ScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: InputMaybe<ScheduledReleaseUpsertWithNestedWhereUniqueInput>;
};

export type ScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ScheduledReleaseUpdateInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

export type ScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: ScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: ScheduledReleaseUpdateInput;
};

export type ScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ScheduledReleaseUpsertInput;
  /** Unique document search */
  where: ScheduledReleaseWhereUniqueInput;
};

/** Identifies documents */
export type ScheduledReleaseWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ScheduledReleaseWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  description_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  description_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  description_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  description_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  description_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  description_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  description_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  description_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  description_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  errorMessage?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  errorMessage_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  errorMessage_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  errorMessage_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  isImplicit?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isImplicit_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  operations_every?: InputMaybe<ScheduledOperationWhereInput>;
  operations_none?: InputMaybe<ScheduledOperationWhereInput>;
  operations_some?: InputMaybe<ScheduledOperationWhereInput>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  releaseAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  releaseAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  releaseAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  releaseAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  releaseAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  status?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  /** Any other value that exists and is not equal to the given value. */
  status_not?: InputMaybe<ScheduledReleaseStatus>;
  /** All values that are not contained in given list. */
  status_not_in?: InputMaybe<Array<InputMaybe<ScheduledReleaseStatus>>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** References ScheduledRelease record uniquely */
export type ScheduledReleaseWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SecurityInfo = Entity & {
  __typename?: "SecurityInfo";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  securityLineItem?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type SecurityInfoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SecurityInfoWhereUniqueInput;
};

/** A connection to a list of items. */
export type SecurityInfoConnection = {
  __typename?: "SecurityInfoConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SecurityInfoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SecurityInfoCreateInput = {
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecurityInfoCreateManyInlineInput = {
  /** Create and connect multiple existing SecurityInfo documents */
  create?: InputMaybe<Array<SecurityInfoCreateInput>>;
};

export type SecurityInfoCreateOneInlineInput = {
  /** Create and connect one SecurityInfo document */
  create?: InputMaybe<SecurityInfoCreateInput>;
};

export type SecurityInfoCreateWithPositionInput = {
  /** Document to create */
  data: SecurityInfoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SecurityInfoEdge = {
  __typename?: "SecurityInfoEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: SecurityInfo;
};

/** Identifies documents */
export type SecurityInfoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  securityLineItem_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  securityLineItem_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  securityLineItem_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  securityLineItem_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  securityLineItem_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  securityLineItem_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  securityLineItem_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  securityLineItem_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  securityLineItem_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SecurityInfoOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  securityLineItem_ASC = "securityLineItem_ASC",
  securityLineItem_DESC = "securityLineItem_DESC",
}

export type SecurityInfoParent = MintingProject | MultiChainMintingProject;

export type SecurityInfoParentConnectInput = {
  MintingProject?: InputMaybe<MintingProjectConnectInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectConnectInput>;
};

export type SecurityInfoParentCreateInput = {
  MintingProject?: InputMaybe<MintingProjectCreateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectCreateInput>;
};

export type SecurityInfoParentCreateManyInlineInput = {
  /** Connect multiple existing SecurityInfoParent documents */
  connect?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Create and connect multiple existing SecurityInfoParent documents */
  create?: InputMaybe<Array<SecurityInfoParentCreateInput>>;
};

export type SecurityInfoParentCreateOneInlineInput = {
  /** Connect one existing SecurityInfoParent document */
  connect?: InputMaybe<SecurityInfoParentWhereUniqueInput>;
  /** Create and connect one SecurityInfoParent document */
  create?: InputMaybe<SecurityInfoParentCreateInput>;
};

export type SecurityInfoParentUpdateInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateInput>;
};

export type SecurityInfoParentUpdateManyInlineInput = {
  /** Connect multiple existing SecurityInfoParent documents */
  connect?: InputMaybe<Array<SecurityInfoParentConnectInput>>;
  /** Create and connect multiple SecurityInfoParent documents */
  create?: InputMaybe<Array<SecurityInfoParentCreateInput>>;
  /** Delete multiple SecurityInfoParent documents */
  delete?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Disconnect multiple SecurityInfoParent documents */
  disconnect?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SecurityInfoParent documents */
  set?: InputMaybe<Array<SecurityInfoParentWhereUniqueInput>>;
  /** Update multiple SecurityInfoParent documents */
  update?: InputMaybe<
    Array<SecurityInfoParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple SecurityInfoParent documents */
  upsert?: InputMaybe<
    Array<SecurityInfoParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type SecurityInfoParentUpdateManyWithNestedWhereInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateManyWithNestedWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateManyWithNestedWhereInput>;
};

export type SecurityInfoParentUpdateOneInlineInput = {
  /** Connect existing SecurityInfoParent document */
  connect?: InputMaybe<SecurityInfoParentWhereUniqueInput>;
  /** Create and connect one SecurityInfoParent document */
  create?: InputMaybe<SecurityInfoParentCreateInput>;
  /** Delete currently connected SecurityInfoParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected SecurityInfoParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single SecurityInfoParent document */
  update?: InputMaybe<SecurityInfoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SecurityInfoParent document */
  upsert?: InputMaybe<SecurityInfoParentUpsertWithNestedWhereUniqueInput>;
};

export type SecurityInfoParentUpdateWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpdateWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpdateWithNestedWhereUniqueInput>;
};

export type SecurityInfoParentUpsertWithNestedWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectUpsertWithNestedWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectUpsertWithNestedWhereUniqueInput>;
};

export type SecurityInfoParentWhereInput = {
  MintingProject?: InputMaybe<MintingProjectWhereInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereInput>;
};

export type SecurityInfoParentWhereUniqueInput = {
  MintingProject?: InputMaybe<MintingProjectWhereUniqueInput>;
  MultiChainMintingProject?: InputMaybe<MultiChainMintingProjectWhereUniqueInput>;
};

export type SecurityInfoUpdateInput = {
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecurityInfoUpdateManyInlineInput = {
  /** Create and connect multiple SecurityInfo component instances */
  create?: InputMaybe<Array<SecurityInfoCreateWithPositionInput>>;
  /** Delete multiple SecurityInfo documents */
  delete?: InputMaybe<Array<SecurityInfoWhereUniqueInput>>;
  /** Update multiple SecurityInfo component instances */
  update?: InputMaybe<
    Array<SecurityInfoUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SecurityInfo component instances */
  upsert?: InputMaybe<
    Array<SecurityInfoUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SecurityInfoUpdateManyInput = {
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
};

export type SecurityInfoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SecurityInfoUpdateManyInput;
  /** Document search */
  where: SecurityInfoWhereInput;
};

export type SecurityInfoUpdateOneInlineInput = {
  /** Create and connect one SecurityInfo document */
  create?: InputMaybe<SecurityInfoCreateInput>;
  /** Delete currently connected SecurityInfo document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single SecurityInfo document */
  update?: InputMaybe<SecurityInfoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SecurityInfo document */
  upsert?: InputMaybe<SecurityInfoUpsertWithNestedWhereUniqueInput>;
};

export type SecurityInfoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SecurityInfoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SecurityInfoWhereUniqueInput;
};

export type SecurityInfoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SecurityInfoUpdateInput;
  /** Unique document search */
  where: SecurityInfoWhereUniqueInput;
};

export type SecurityInfoUpsertInput = {
  /** Create document if it didn't exist */
  create: SecurityInfoCreateInput;
  /** Update document if it exists */
  update: SecurityInfoUpdateInput;
};

export type SecurityInfoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SecurityInfoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SecurityInfoWhereUniqueInput;
};

export type SecurityInfoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SecurityInfoUpsertInput;
  /** Unique document search */
  where: SecurityInfoWhereUniqueInput;
};

/** Identifies documents */
export type SecurityInfoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SecurityInfoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  securityLineItem?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  securityLineItem_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  securityLineItem_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  securityLineItem_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  securityLineItem_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  securityLineItem_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  securityLineItem_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  securityLineItem_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  securityLineItem_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  securityLineItem_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References SecurityInfo record uniquely */
export type SecurityInfoWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SimpleLink = Entity & {
  __typename?: "SimpleLink";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
};

/** A connection to a list of items. */
export type SimpleLinkConnection = {
  __typename?: "SimpleLinkConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SimpleLinkEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SimpleLinkCreateInput = {
  /** No fields in create input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type SimpleLinkCreateWithPositionInput = {
  /** Document to create */
  data: SimpleLinkCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SimpleLinkEdge = {
  __typename?: "SimpleLinkEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: SimpleLink;
};

/** Identifies documents */
export type SimpleLinkManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SimpleLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SimpleLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SimpleLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum SimpleLinkOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
}

export type SimpleLinkUpdateInput = {
  /** No fields in update input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type SimpleLinkUpdateManyInput = {
  /** No fields in updateMany data input */
  _?: InputMaybe<Scalars["String"]["input"]>;
};

export type SimpleLinkUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SimpleLinkUpdateManyInput;
  /** Document search */
  where: SimpleLinkWhereInput;
};

export type SimpleLinkUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SimpleLinkUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SimpleLinkWhereUniqueInput;
};

export type SimpleLinkUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SimpleLinkUpdateInput;
  /** Unique document search */
  where: SimpleLinkWhereUniqueInput;
};

export type SimpleLinkUpsertInput = {
  /** Create document if it didn't exist */
  create: SimpleLinkCreateInput;
  /** Update document if it exists */
  update: SimpleLinkUpdateInput;
};

export type SimpleLinkUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SimpleLinkUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SimpleLinkWhereUniqueInput;
};

export type SimpleLinkUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SimpleLinkUpsertInput;
  /** Unique document search */
  where: SimpleLinkWhereUniqueInput;
};

/** Identifies documents */
export type SimpleLinkWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SimpleLinkWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SimpleLinkWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SimpleLinkWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

/** References SimpleLink record uniquely */
export type SimpleLinkWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type SmallIconHeadingText = Entity & {
  __typename?: "SmallIconHeadingText";
  heading?: Maybe<Scalars["String"]["output"]>;
  icon?: Maybe<Asset>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  richText?: Maybe<RichText>;
  /** System stage field */
  stage: Stage;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type SmallIconHeadingTextIconArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type SmallIconHeadingTextConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: SmallIconHeadingTextWhereUniqueInput;
};

/** A connection to a list of items. */
export type SmallIconHeadingTextConnection = {
  __typename?: "SmallIconHeadingTextConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<SmallIconHeadingTextEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type SmallIconHeadingTextCreateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetCreateOneInlineInput>;
  richText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type SmallIconHeadingTextCreateManyInlineInput = {
  /** Create and connect multiple existing SmallIconHeadingText documents */
  create?: InputMaybe<Array<SmallIconHeadingTextCreateInput>>;
};

export type SmallIconHeadingTextCreateOneInlineInput = {
  /** Create and connect one SmallIconHeadingText document */
  create?: InputMaybe<SmallIconHeadingTextCreateInput>;
};

export type SmallIconHeadingTextCreateWithPositionInput = {
  /** Document to create */
  data: SmallIconHeadingTextCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type SmallIconHeadingTextEdge = {
  __typename?: "SmallIconHeadingTextEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: SmallIconHeadingText;
};

/** Identifies documents */
export type SmallIconHeadingTextManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum SmallIconHeadingTextOrderByInput {
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  text_ASC = "text_ASC",
  text_DESC = "text_DESC",
}

export type SmallIconHeadingTextParent =
  | MiningNftAccessSection
  | MiningNftAdvantageSection;

export type SmallIconHeadingTextParentConnectInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionConnectInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionConnectInput>;
};

export type SmallIconHeadingTextParentCreateInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionCreateInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionCreateInput>;
};

export type SmallIconHeadingTextParentCreateManyInlineInput = {
  /** Connect multiple existing SmallIconHeadingTextParent documents */
  connect?: InputMaybe<Array<SmallIconHeadingTextParentWhereUniqueInput>>;
  /** Create and connect multiple existing SmallIconHeadingTextParent documents */
  create?: InputMaybe<Array<SmallIconHeadingTextParentCreateInput>>;
};

export type SmallIconHeadingTextParentCreateOneInlineInput = {
  /** Connect one existing SmallIconHeadingTextParent document */
  connect?: InputMaybe<SmallIconHeadingTextParentWhereUniqueInput>;
  /** Create and connect one SmallIconHeadingTextParent document */
  create?: InputMaybe<SmallIconHeadingTextParentCreateInput>;
};

export type SmallIconHeadingTextParentUpdateInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionUpdateInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionUpdateInput>;
};

export type SmallIconHeadingTextParentUpdateManyInlineInput = {
  /** Connect multiple existing SmallIconHeadingTextParent documents */
  connect?: InputMaybe<Array<SmallIconHeadingTextParentConnectInput>>;
  /** Create and connect multiple SmallIconHeadingTextParent documents */
  create?: InputMaybe<Array<SmallIconHeadingTextParentCreateInput>>;
  /** Delete multiple SmallIconHeadingTextParent documents */
  delete?: InputMaybe<Array<SmallIconHeadingTextParentWhereUniqueInput>>;
  /** Disconnect multiple SmallIconHeadingTextParent documents */
  disconnect?: InputMaybe<Array<SmallIconHeadingTextParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing SmallIconHeadingTextParent documents */
  set?: InputMaybe<Array<SmallIconHeadingTextParentWhereUniqueInput>>;
  /** Update multiple SmallIconHeadingTextParent documents */
  update?: InputMaybe<
    Array<SmallIconHeadingTextParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple SmallIconHeadingTextParent documents */
  upsert?: InputMaybe<
    Array<SmallIconHeadingTextParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type SmallIconHeadingTextParentUpdateManyWithNestedWhereInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionUpdateManyWithNestedWhereInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionUpdateManyWithNestedWhereInput>;
};

export type SmallIconHeadingTextParentUpdateOneInlineInput = {
  /** Connect existing SmallIconHeadingTextParent document */
  connect?: InputMaybe<SmallIconHeadingTextParentWhereUniqueInput>;
  /** Create and connect one SmallIconHeadingTextParent document */
  create?: InputMaybe<SmallIconHeadingTextParentCreateInput>;
  /** Delete currently connected SmallIconHeadingTextParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected SmallIconHeadingTextParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single SmallIconHeadingTextParent document */
  update?: InputMaybe<SmallIconHeadingTextParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SmallIconHeadingTextParent document */
  upsert?: InputMaybe<SmallIconHeadingTextParentUpsertWithNestedWhereUniqueInput>;
};

export type SmallIconHeadingTextParentUpdateWithNestedWhereUniqueInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionUpdateWithNestedWhereUniqueInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionUpdateWithNestedWhereUniqueInput>;
};

export type SmallIconHeadingTextParentUpsertWithNestedWhereUniqueInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionUpsertWithNestedWhereUniqueInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionUpsertWithNestedWhereUniqueInput>;
};

export type SmallIconHeadingTextParentWhereInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionWhereInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionWhereInput>;
};

export type SmallIconHeadingTextParentWhereUniqueInput = {
  MiningNftAccessSection?: InputMaybe<MiningNftAccessSectionWhereUniqueInput>;
  MiningNftAdvantageSection?: InputMaybe<MiningNftAdvantageSectionWhereUniqueInput>;
};

export type SmallIconHeadingTextUpdateInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetUpdateOneInlineInput>;
  richText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type SmallIconHeadingTextUpdateManyInlineInput = {
  /** Create and connect multiple SmallIconHeadingText component instances */
  create?: InputMaybe<Array<SmallIconHeadingTextCreateWithPositionInput>>;
  /** Delete multiple SmallIconHeadingText documents */
  delete?: InputMaybe<Array<SmallIconHeadingTextWhereUniqueInput>>;
  /** Update multiple SmallIconHeadingText component instances */
  update?: InputMaybe<
    Array<SmallIconHeadingTextUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple SmallIconHeadingText component instances */
  upsert?: InputMaybe<
    Array<SmallIconHeadingTextUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type SmallIconHeadingTextUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  richText?: InputMaybe<Scalars["RichTextAST"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type SmallIconHeadingTextUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: SmallIconHeadingTextUpdateManyInput;
  /** Document search */
  where: SmallIconHeadingTextWhereInput;
};

export type SmallIconHeadingTextUpdateOneInlineInput = {
  /** Create and connect one SmallIconHeadingText document */
  create?: InputMaybe<SmallIconHeadingTextCreateInput>;
  /** Delete currently connected SmallIconHeadingText document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single SmallIconHeadingText document */
  update?: InputMaybe<SmallIconHeadingTextUpdateWithNestedWhereUniqueInput>;
  /** Upsert single SmallIconHeadingText document */
  upsert?: InputMaybe<SmallIconHeadingTextUpsertWithNestedWhereUniqueInput>;
};

export type SmallIconHeadingTextUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<SmallIconHeadingTextUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

export type SmallIconHeadingTextUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: SmallIconHeadingTextUpdateInput;
  /** Unique document search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

export type SmallIconHeadingTextUpsertInput = {
  /** Create document if it didn't exist */
  create: SmallIconHeadingTextCreateInput;
  /** Update document if it exists */
  update: SmallIconHeadingTextUpdateInput;
};

export type SmallIconHeadingTextUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<SmallIconHeadingTextUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

export type SmallIconHeadingTextUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: SmallIconHeadingTextUpsertInput;
  /** Unique document search */
  where: SmallIconHeadingTextWhereUniqueInput;
};

/** Identifies documents */
export type SmallIconHeadingTextWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<SmallIconHeadingTextWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  icon?: InputMaybe<AssetWhereInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  text_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  text_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  text_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  text_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  text_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  text_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  text_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  text_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  text_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References SmallIconHeadingText record uniquely */
export type SmallIconHeadingTextWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Stage system enumeration */
export enum Stage {
  /** The Draft is the default stage for all your content. */
  DRAFT = "DRAFT",
  /** The Published stage is where you can publish your content to. */
  PUBLISHED = "PUBLISHED",
}

export enum SystemDateTimeFieldVariation {
  BASE = "BASE",
  COMBINED = "COMBINED",
  LOCALIZATION = "LOCALIZATION",
}

export enum Tags {
  body1 = "body1",
  div = "div",
  h1 = "h1",
  h2 = "h2",
  h3 = "h3",
  h4 = "h4",
  h5 = "h5",
  p = "p",
  span = "span",
}

export type TeamMemberImageNameTitle = Entity & {
  __typename?: "TeamMemberImageNameTitle";
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  image?: Maybe<Asset>;
  name?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
  title?: Maybe<Scalars["String"]["output"]>;
  truncatedBio?: Maybe<Scalars["String"]["output"]>;
};

export type TeamMemberImageNameTitleImageArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TeamMemberImageNameTitleConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TeamMemberImageNameTitleWhereUniqueInput;
};

/** A connection to a list of items. */
export type TeamMemberImageNameTitleConnection = {
  __typename?: "TeamMemberImageNameTitleConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TeamMemberImageNameTitleEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TeamMemberImageNameTitleCreateInput = {
  image?: InputMaybe<AssetCreateOneInlineInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamMemberImageNameTitleCreateManyInlineInput = {
  /** Create and connect multiple existing TeamMemberImageNameTitle documents */
  create?: InputMaybe<Array<TeamMemberImageNameTitleCreateInput>>;
};

export type TeamMemberImageNameTitleCreateOneInlineInput = {
  /** Create and connect one TeamMemberImageNameTitle document */
  create?: InputMaybe<TeamMemberImageNameTitleCreateInput>;
};

export type TeamMemberImageNameTitleCreateWithPositionInput = {
  /** Document to create */
  data: TeamMemberImageNameTitleCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TeamMemberImageNameTitleEdge = {
  __typename?: "TeamMemberImageNameTitleEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: TeamMemberImageNameTitle;
};

/** Identifies documents */
export type TeamMemberImageNameTitleManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  truncatedBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  truncatedBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  truncatedBio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  truncatedBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  truncatedBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  truncatedBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  truncatedBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  truncatedBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  truncatedBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TeamMemberImageNameTitleOrderByInput {
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  title_ASC = "title_ASC",
  title_DESC = "title_DESC",
  truncatedBio_ASC = "truncatedBio_ASC",
  truncatedBio_DESC = "truncatedBio_DESC",
}

export type TeamMemberImageNameTitleParent = MiningNftTeamSection | TeamSection;

export type TeamMemberImageNameTitleParentConnectInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionConnectInput>;
  TeamSection?: InputMaybe<TeamSectionConnectInput>;
};

export type TeamMemberImageNameTitleParentCreateInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionCreateInput>;
  TeamSection?: InputMaybe<TeamSectionCreateInput>;
};

export type TeamMemberImageNameTitleParentCreateManyInlineInput = {
  /** Connect multiple existing TeamMemberImageNameTitleParent documents */
  connect?: InputMaybe<Array<TeamMemberImageNameTitleParentWhereUniqueInput>>;
  /** Create and connect multiple existing TeamMemberImageNameTitleParent documents */
  create?: InputMaybe<Array<TeamMemberImageNameTitleParentCreateInput>>;
};

export type TeamMemberImageNameTitleParentCreateOneInlineInput = {
  /** Connect one existing TeamMemberImageNameTitleParent document */
  connect?: InputMaybe<TeamMemberImageNameTitleParentWhereUniqueInput>;
  /** Create and connect one TeamMemberImageNameTitleParent document */
  create?: InputMaybe<TeamMemberImageNameTitleParentCreateInput>;
};

export type TeamMemberImageNameTitleParentUpdateInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionUpdateInput>;
  TeamSection?: InputMaybe<TeamSectionUpdateInput>;
};

export type TeamMemberImageNameTitleParentUpdateManyInlineInput = {
  /** Connect multiple existing TeamMemberImageNameTitleParent documents */
  connect?: InputMaybe<Array<TeamMemberImageNameTitleParentConnectInput>>;
  /** Create and connect multiple TeamMemberImageNameTitleParent documents */
  create?: InputMaybe<Array<TeamMemberImageNameTitleParentCreateInput>>;
  /** Delete multiple TeamMemberImageNameTitleParent documents */
  delete?: InputMaybe<Array<TeamMemberImageNameTitleParentWhereUniqueInput>>;
  /** Disconnect multiple TeamMemberImageNameTitleParent documents */
  disconnect?: InputMaybe<
    Array<TeamMemberImageNameTitleParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing TeamMemberImageNameTitleParent documents */
  set?: InputMaybe<Array<TeamMemberImageNameTitleParentWhereUniqueInput>>;
  /** Update multiple TeamMemberImageNameTitleParent documents */
  update?: InputMaybe<
    Array<TeamMemberImageNameTitleParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple TeamMemberImageNameTitleParent documents */
  upsert?: InputMaybe<
    Array<TeamMemberImageNameTitleParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type TeamMemberImageNameTitleParentUpdateManyWithNestedWhereInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionUpdateManyWithNestedWhereInput>;
  TeamSection?: InputMaybe<TeamSectionUpdateManyWithNestedWhereInput>;
};

export type TeamMemberImageNameTitleParentUpdateOneInlineInput = {
  /** Connect existing TeamMemberImageNameTitleParent document */
  connect?: InputMaybe<TeamMemberImageNameTitleParentWhereUniqueInput>;
  /** Create and connect one TeamMemberImageNameTitleParent document */
  create?: InputMaybe<TeamMemberImageNameTitleParentCreateInput>;
  /** Delete currently connected TeamMemberImageNameTitleParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected TeamMemberImageNameTitleParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TeamMemberImageNameTitleParent document */
  update?: InputMaybe<TeamMemberImageNameTitleParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TeamMemberImageNameTitleParent document */
  upsert?: InputMaybe<TeamMemberImageNameTitleParentUpsertWithNestedWhereUniqueInput>;
};

export type TeamMemberImageNameTitleParentUpdateWithNestedWhereUniqueInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionUpdateWithNestedWhereUniqueInput>;
  TeamSection?: InputMaybe<TeamSectionUpdateWithNestedWhereUniqueInput>;
};

export type TeamMemberImageNameTitleParentUpsertWithNestedWhereUniqueInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionUpsertWithNestedWhereUniqueInput>;
  TeamSection?: InputMaybe<TeamSectionUpsertWithNestedWhereUniqueInput>;
};

export type TeamMemberImageNameTitleParentWhereInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionWhereInput>;
  TeamSection?: InputMaybe<TeamSectionWhereInput>;
};

export type TeamMemberImageNameTitleParentWhereUniqueInput = {
  MiningNftTeamSection?: InputMaybe<MiningNftTeamSectionWhereUniqueInput>;
  TeamSection?: InputMaybe<TeamSectionWhereUniqueInput>;
};

export type TeamMemberImageNameTitleUpdateInput = {
  image?: InputMaybe<AssetUpdateOneInlineInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamMemberImageNameTitleUpdateManyInlineInput = {
  /** Create and connect multiple TeamMemberImageNameTitle component instances */
  create?: InputMaybe<Array<TeamMemberImageNameTitleCreateWithPositionInput>>;
  /** Delete multiple TeamMemberImageNameTitle documents */
  delete?: InputMaybe<Array<TeamMemberImageNameTitleWhereUniqueInput>>;
  /** Update multiple TeamMemberImageNameTitle component instances */
  update?: InputMaybe<
    Array<TeamMemberImageNameTitleUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TeamMemberImageNameTitle component instances */
  upsert?: InputMaybe<
    Array<TeamMemberImageNameTitleUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TeamMemberImageNameTitleUpdateManyInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamMemberImageNameTitleUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TeamMemberImageNameTitleUpdateManyInput;
  /** Document search */
  where: TeamMemberImageNameTitleWhereInput;
};

export type TeamMemberImageNameTitleUpdateOneInlineInput = {
  /** Create and connect one TeamMemberImageNameTitle document */
  create?: InputMaybe<TeamMemberImageNameTitleCreateInput>;
  /** Delete currently connected TeamMemberImageNameTitle document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TeamMemberImageNameTitle document */
  update?: InputMaybe<TeamMemberImageNameTitleUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TeamMemberImageNameTitle document */
  upsert?: InputMaybe<TeamMemberImageNameTitleUpsertWithNestedWhereUniqueInput>;
};

export type TeamMemberImageNameTitleUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<TeamMemberImageNameTitleUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TeamMemberImageNameTitleWhereUniqueInput;
  };

export type TeamMemberImageNameTitleUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TeamMemberImageNameTitleUpdateInput;
  /** Unique document search */
  where: TeamMemberImageNameTitleWhereUniqueInput;
};

export type TeamMemberImageNameTitleUpsertInput = {
  /** Create document if it didn't exist */
  create: TeamMemberImageNameTitleCreateInput;
  /** Update document if it exists */
  update: TeamMemberImageNameTitleUpdateInput;
};

export type TeamMemberImageNameTitleUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<TeamMemberImageNameTitleUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TeamMemberImageNameTitleWhereUniqueInput;
  };

export type TeamMemberImageNameTitleUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TeamMemberImageNameTitleUpsertInput;
  /** Unique document search */
  where: TeamMemberImageNameTitleWhereUniqueInput;
};

/** Identifies documents */
export type TeamMemberImageNameTitleWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TeamMemberImageNameTitleWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  image?: InputMaybe<AssetWhereInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  title_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  title_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  title_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  title_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  title_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  title_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  title_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  title_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  title_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  truncatedBio?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  truncatedBio_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  truncatedBio_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  truncatedBio_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  truncatedBio_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  truncatedBio_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  truncatedBio_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  truncatedBio_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  truncatedBio_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  truncatedBio_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References TeamMemberImageNameTitle record uniquely */
export type TeamMemberImageNameTitleWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type TeamSection = Entity &
  Node & {
    __typename?: "TeamSection";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** User that created this document */
    createdBy?: Maybe<User>;
    /** Get the document in other stages */
    documentInStages: Array<TeamSection>;
    heading?: Maybe<Scalars["String"]["output"]>;
    /** List of TeamSection versions */
    history: Array<Version>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** User that last published this document */
    publishedBy?: Maybe<User>;
    scheduledIn: Array<ScheduledOperation>;
    /** System stage field */
    stage: Stage;
    teamMembers: Array<TeamMemberImageNameTitle>;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
    /** User that last updated this document */
    updatedBy?: Maybe<User>;
  };

export type TeamSectionCreatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TeamSectionDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type TeamSectionHistoryArgs = {
  limit?: Scalars["Int"]["input"];
  skip?: Scalars["Int"]["input"];
  stageOverride?: InputMaybe<Stage>;
};

export type TeamSectionPublishedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TeamSectionScheduledInArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ScheduledOperationWhereInput>;
};

export type TeamSectionTeamMembersArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<TeamMemberImageNameTitleOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
};

export type TeamSectionUpdatedByArgs = {
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
};

export type TeamSectionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TeamSectionWhereUniqueInput;
};

/** A connection to a list of items. */
export type TeamSectionConnection = {
  __typename?: "TeamSectionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TeamSectionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TeamSectionCreateInput = {
  cl4j4c5xy4lpc01xv10lqesiz?: InputMaybe<PageCreateManyInlineInput>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  teamMembers?: InputMaybe<TeamMemberImageNameTitleCreateManyInlineInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type TeamSectionCreateManyInlineInput = {
  /** Connect multiple existing TeamSection documents */
  connect?: InputMaybe<Array<TeamSectionWhereUniqueInput>>;
  /** Create and connect multiple existing TeamSection documents */
  create?: InputMaybe<Array<TeamSectionCreateInput>>;
};

export type TeamSectionCreateOneInlineInput = {
  /** Connect one existing TeamSection document */
  connect?: InputMaybe<TeamSectionWhereUniqueInput>;
  /** Create and connect one TeamSection document */
  create?: InputMaybe<TeamSectionCreateInput>;
};

/** An edge in a connection. */
export type TeamSectionEdge = {
  __typename?: "TeamSectionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: TeamSection;
};

/** Identifies documents */
export type TeamSectionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TeamSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TeamSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TeamSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TeamSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<TeamSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<TeamSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  teamMembers_every?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_none?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_some?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

export enum TeamSectionOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type TeamSectionUpdateInput = {
  cl4j4c5xy4lpc01xv10lqesiz?: InputMaybe<PageUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  teamMembers?: InputMaybe<TeamMemberImageNameTitleUpdateManyInlineInput>;
};

export type TeamSectionUpdateManyInlineInput = {
  /** Connect multiple existing TeamSection documents */
  connect?: InputMaybe<Array<TeamSectionConnectInput>>;
  /** Create and connect multiple TeamSection documents */
  create?: InputMaybe<Array<TeamSectionCreateInput>>;
  /** Delete multiple TeamSection documents */
  delete?: InputMaybe<Array<TeamSectionWhereUniqueInput>>;
  /** Disconnect multiple TeamSection documents */
  disconnect?: InputMaybe<Array<TeamSectionWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing TeamSection documents */
  set?: InputMaybe<Array<TeamSectionWhereUniqueInput>>;
  /** Update multiple TeamSection documents */
  update?: InputMaybe<Array<TeamSectionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TeamSection documents */
  upsert?: InputMaybe<Array<TeamSectionUpsertWithNestedWhereUniqueInput>>;
};

export type TeamSectionUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
};

export type TeamSectionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TeamSectionUpdateManyInput;
  /** Document search */
  where: TeamSectionWhereInput;
};

export type TeamSectionUpdateOneInlineInput = {
  /** Connect existing TeamSection document */
  connect?: InputMaybe<TeamSectionWhereUniqueInput>;
  /** Create and connect one TeamSection document */
  create?: InputMaybe<TeamSectionCreateInput>;
  /** Delete currently connected TeamSection document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected TeamSection document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TeamSection document */
  update?: InputMaybe<TeamSectionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TeamSection document */
  upsert?: InputMaybe<TeamSectionUpsertWithNestedWhereUniqueInput>;
};

export type TeamSectionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TeamSectionUpdateInput;
  /** Unique document search */
  where: TeamSectionWhereUniqueInput;
};

export type TeamSectionUpsertInput = {
  /** Create document if it didn't exist */
  create: TeamSectionCreateInput;
  /** Update document if it exists */
  update: TeamSectionUpdateInput;
};

export type TeamSectionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TeamSectionUpsertInput;
  /** Unique document search */
  where: TeamSectionWhereUniqueInput;
};

/** This contains a set of filters that can be used to compare values internally */
export type TeamSectionWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type TeamSectionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TeamSectionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TeamSectionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TeamSectionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  createdBy?: InputMaybe<UserWhereInput>;
  documentInStages_every?: InputMaybe<TeamSectionWhereStageInput>;
  documentInStages_none?: InputMaybe<TeamSectionWhereStageInput>;
  documentInStages_some?: InputMaybe<TeamSectionWhereStageInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  publishedBy?: InputMaybe<UserWhereInput>;
  scheduledIn_every?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_none?: InputMaybe<ScheduledOperationWhereInput>;
  scheduledIn_some?: InputMaybe<ScheduledOperationWhereInput>;
  teamMembers_every?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_none?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  teamMembers_some?: InputMaybe<TeamMemberImageNameTitleWhereInput>;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedBy?: InputMaybe<UserWhereInput>;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type TeamSectionWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TeamSectionWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TeamSectionWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TeamSectionWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<TeamSectionWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References TeamSection record uniquely */
export type TeamSectionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type TrendingCollectionsCarousel = Entity & {
  __typename?: "TrendingCollectionsCarousel";
  chains: Array<Chain>;
  heading?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  numberOfCollectionsPerChain?: Maybe<Scalars["Int"]["output"]>;
  /** System stage field */
  stage: Stage;
  subHeading?: Maybe<Scalars["String"]["output"]>;
};

export type TrendingCollectionsCarouselChainsArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  before?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  forceParentLocale?: InputMaybe<Scalars["Boolean"]["input"]>;
  last?: InputMaybe<Scalars["Int"]["input"]>;
  locales?: InputMaybe<Array<Locale>>;
  orderBy?: InputMaybe<ChainOrderByInput>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  where?: InputMaybe<ChainWhereInput>;
};

export type TrendingCollectionsCarouselConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: TrendingCollectionsCarouselWhereUniqueInput;
};

/** A connection to a list of items. */
export type TrendingCollectionsCarouselConnection = {
  __typename?: "TrendingCollectionsCarouselConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<TrendingCollectionsCarouselEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type TrendingCollectionsCarouselCreateInput = {
  chains?: InputMaybe<ChainCreateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrendingCollectionsCarouselCreateManyInlineInput = {
  /** Create and connect multiple existing TrendingCollectionsCarousel documents */
  create?: InputMaybe<Array<TrendingCollectionsCarouselCreateInput>>;
};

export type TrendingCollectionsCarouselCreateOneInlineInput = {
  /** Create and connect one TrendingCollectionsCarousel document */
  create?: InputMaybe<TrendingCollectionsCarouselCreateInput>;
};

export type TrendingCollectionsCarouselCreateWithPositionInput = {
  /** Document to create */
  data: TrendingCollectionsCarouselCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type TrendingCollectionsCarouselEdge = {
  __typename?: "TrendingCollectionsCarouselEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: TrendingCollectionsCarousel;
};

/** Identifies documents */
export type TrendingCollectionsCarouselManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chains_every?: InputMaybe<ChainWhereInput>;
  chains_none?: InputMaybe<ChainWhereInput>;
  chains_some?: InputMaybe<ChainWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  numberOfCollectionsPerChain_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  numberOfCollectionsPerChain_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  numberOfCollectionsPerChain_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  numberOfCollectionsPerChain_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  numberOfCollectionsPerChain_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  numberOfCollectionsPerChain_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  numberOfCollectionsPerChain_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum TrendingCollectionsCarouselOrderByInput {
  heading_ASC = "heading_ASC",
  heading_DESC = "heading_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  numberOfCollectionsPerChain_ASC = "numberOfCollectionsPerChain_ASC",
  numberOfCollectionsPerChain_DESC = "numberOfCollectionsPerChain_DESC",
  subHeading_ASC = "subHeading_ASC",
  subHeading_DESC = "subHeading_DESC",
}

export type TrendingCollectionsCarouselParent = MarketplacePage;

export type TrendingCollectionsCarouselParentConnectInput = {
  MarketplacePage?: InputMaybe<MarketplacePageConnectInput>;
};

export type TrendingCollectionsCarouselParentCreateInput = {
  MarketplacePage?: InputMaybe<MarketplacePageCreateInput>;
};

export type TrendingCollectionsCarouselParentCreateManyInlineInput = {
  /** Connect multiple existing TrendingCollectionsCarouselParent documents */
  connect?: InputMaybe<
    Array<TrendingCollectionsCarouselParentWhereUniqueInput>
  >;
  /** Create and connect multiple existing TrendingCollectionsCarouselParent documents */
  create?: InputMaybe<Array<TrendingCollectionsCarouselParentCreateInput>>;
};

export type TrendingCollectionsCarouselParentCreateOneInlineInput = {
  /** Connect one existing TrendingCollectionsCarouselParent document */
  connect?: InputMaybe<TrendingCollectionsCarouselParentWhereUniqueInput>;
  /** Create and connect one TrendingCollectionsCarouselParent document */
  create?: InputMaybe<TrendingCollectionsCarouselParentCreateInput>;
};

export type TrendingCollectionsCarouselParentUpdateInput = {
  MarketplacePage?: InputMaybe<MarketplacePageUpdateInput>;
};

export type TrendingCollectionsCarouselParentUpdateManyInlineInput = {
  /** Connect multiple existing TrendingCollectionsCarouselParent documents */
  connect?: InputMaybe<Array<TrendingCollectionsCarouselParentConnectInput>>;
  /** Create and connect multiple TrendingCollectionsCarouselParent documents */
  create?: InputMaybe<Array<TrendingCollectionsCarouselParentCreateInput>>;
  /** Delete multiple TrendingCollectionsCarouselParent documents */
  delete?: InputMaybe<Array<TrendingCollectionsCarouselParentWhereUniqueInput>>;
  /** Disconnect multiple TrendingCollectionsCarouselParent documents */
  disconnect?: InputMaybe<
    Array<TrendingCollectionsCarouselParentWhereUniqueInput>
  >;
  /** Override currently-connected documents with multiple existing TrendingCollectionsCarouselParent documents */
  set?: InputMaybe<Array<TrendingCollectionsCarouselParentWhereUniqueInput>>;
  /** Update multiple TrendingCollectionsCarouselParent documents */
  update?: InputMaybe<
    Array<TrendingCollectionsCarouselParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple TrendingCollectionsCarouselParent documents */
  upsert?: InputMaybe<
    Array<TrendingCollectionsCarouselParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type TrendingCollectionsCarouselParentUpdateManyWithNestedWhereInput = {
  MarketplacePage?: InputMaybe<MarketplacePageUpdateManyWithNestedWhereInput>;
};

export type TrendingCollectionsCarouselParentUpdateOneInlineInput = {
  /** Connect existing TrendingCollectionsCarouselParent document */
  connect?: InputMaybe<TrendingCollectionsCarouselParentWhereUniqueInput>;
  /** Create and connect one TrendingCollectionsCarouselParent document */
  create?: InputMaybe<TrendingCollectionsCarouselParentCreateInput>;
  /** Delete currently connected TrendingCollectionsCarouselParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected TrendingCollectionsCarouselParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TrendingCollectionsCarouselParent document */
  update?: InputMaybe<TrendingCollectionsCarouselParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TrendingCollectionsCarouselParent document */
  upsert?: InputMaybe<TrendingCollectionsCarouselParentUpsertWithNestedWhereUniqueInput>;
};

export type TrendingCollectionsCarouselParentUpdateWithNestedWhereUniqueInput =
  {
    MarketplacePage?: InputMaybe<MarketplacePageUpdateWithNestedWhereUniqueInput>;
  };

export type TrendingCollectionsCarouselParentUpsertWithNestedWhereUniqueInput =
  {
    MarketplacePage?: InputMaybe<MarketplacePageUpsertWithNestedWhereUniqueInput>;
  };

export type TrendingCollectionsCarouselParentWhereInput = {
  MarketplacePage?: InputMaybe<MarketplacePageWhereInput>;
};

export type TrendingCollectionsCarouselParentWhereUniqueInput = {
  MarketplacePage?: InputMaybe<MarketplacePageWhereUniqueInput>;
};

export type TrendingCollectionsCarouselUpdateInput = {
  chains?: InputMaybe<ChainUpdateManyInlineInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrendingCollectionsCarouselUpdateManyInlineInput = {
  /** Create and connect multiple TrendingCollectionsCarousel component instances */
  create?: InputMaybe<
    Array<TrendingCollectionsCarouselCreateWithPositionInput>
  >;
  /** Delete multiple TrendingCollectionsCarousel documents */
  delete?: InputMaybe<Array<TrendingCollectionsCarouselWhereUniqueInput>>;
  /** Update multiple TrendingCollectionsCarousel component instances */
  update?: InputMaybe<
    Array<TrendingCollectionsCarouselUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple TrendingCollectionsCarousel component instances */
  upsert?: InputMaybe<
    Array<TrendingCollectionsCarouselUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type TrendingCollectionsCarouselUpdateManyInput = {
  heading?: InputMaybe<Scalars["String"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrendingCollectionsCarouselUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: TrendingCollectionsCarouselUpdateManyInput;
  /** Document search */
  where: TrendingCollectionsCarouselWhereInput;
};

export type TrendingCollectionsCarouselUpdateOneInlineInput = {
  /** Create and connect one TrendingCollectionsCarousel document */
  create?: InputMaybe<TrendingCollectionsCarouselCreateInput>;
  /** Delete currently connected TrendingCollectionsCarousel document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single TrendingCollectionsCarousel document */
  update?: InputMaybe<TrendingCollectionsCarouselUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TrendingCollectionsCarousel document */
  upsert?: InputMaybe<TrendingCollectionsCarouselUpsertWithNestedWhereUniqueInput>;
};

export type TrendingCollectionsCarouselUpdateWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to update */
    data?: InputMaybe<TrendingCollectionsCarouselUpdateInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TrendingCollectionsCarouselWhereUniqueInput;
  };

export type TrendingCollectionsCarouselUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: TrendingCollectionsCarouselUpdateInput;
  /** Unique document search */
  where: TrendingCollectionsCarouselWhereUniqueInput;
};

export type TrendingCollectionsCarouselUpsertInput = {
  /** Create document if it didn't exist */
  create: TrendingCollectionsCarouselCreateInput;
  /** Update document if it exists */
  update: TrendingCollectionsCarouselUpdateInput;
};

export type TrendingCollectionsCarouselUpsertWithNestedWhereUniqueAndPositionInput =
  {
    /** Document to upsert */
    data?: InputMaybe<TrendingCollectionsCarouselUpsertInput>;
    /** Position in the list of existing component instances, will default to appending at the end of list */
    position?: InputMaybe<ConnectPositionInput>;
    /** Unique component instance search */
    where: TrendingCollectionsCarouselWhereUniqueInput;
  };

export type TrendingCollectionsCarouselUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: TrendingCollectionsCarouselUpsertInput;
  /** Unique document search */
  where: TrendingCollectionsCarouselWhereUniqueInput;
};

/** Identifies documents */
export type TrendingCollectionsCarouselWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<TrendingCollectionsCarouselWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  chains_every?: InputMaybe<ChainWhereInput>;
  chains_none?: InputMaybe<ChainWhereInput>;
  chains_some?: InputMaybe<ChainWhereInput>;
  heading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  heading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  heading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  heading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  heading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  heading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  heading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  heading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  heading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  heading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  numberOfCollectionsPerChain?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than the given value. */
  numberOfCollectionsPerChain_gt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values greater than or equal the given value. */
  numberOfCollectionsPerChain_gte?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are contained in given list. */
  numberOfCollectionsPerChain_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  /** All values less than the given value. */
  numberOfCollectionsPerChain_lt?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values less than or equal the given value. */
  numberOfCollectionsPerChain_lte?: InputMaybe<Scalars["Int"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  numberOfCollectionsPerChain_not?: InputMaybe<Scalars["Int"]["input"]>;
  /** All values that are not contained in given list. */
  numberOfCollectionsPerChain_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["Int"]["input"]>>
  >;
  subHeading?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  subHeading_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  subHeading_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  subHeading_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  subHeading_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  subHeading_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  subHeading_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  subHeading_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  subHeading_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  subHeading_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References TrendingCollectionsCarousel record uniquely */
export type TrendingCollectionsCarouselWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: Locale;
  /** Stages to unpublish selected locales from */
  stages: Array<Stage>;
};

/** User system model */
export type User = Entity &
  Node & {
    __typename?: "User";
    /** The time the document was created */
    createdAt: Scalars["DateTime"]["output"];
    /** Get the document in other stages */
    documentInStages: Array<User>;
    /** The unique identifier */
    id: Scalars["ID"]["output"];
    /** Flag to determine if user is active or not */
    isActive: Scalars["Boolean"]["output"];
    /** User Kind. Can be either MEMBER, PAT or PUBLIC */
    kind: UserKind;
    /** The username */
    name: Scalars["String"]["output"];
    /** Profile Picture url */
    picture?: Maybe<Scalars["String"]["output"]>;
    /** The time the document was published. Null on documents in draft stage. */
    publishedAt?: Maybe<Scalars["DateTime"]["output"]>;
    /** System stage field */
    stage: Stage;
    /** The time the document was updated */
    updatedAt: Scalars["DateTime"]["output"];
  };

/** User system model */
export type UserDocumentInStagesArgs = {
  includeCurrent?: Scalars["Boolean"]["input"];
  inheritLocale?: Scalars["Boolean"]["input"];
  stages?: Array<Stage>;
};

export type UserConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: UserWhereUniqueInput;
};

/** A connection to a list of items. */
export type UserConnection = {
  __typename?: "UserConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<UserEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type UserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
};

/** An edge in a connection. */
export type UserEdge = {
  __typename?: "UserEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: User;
};

/** System User Kind */
export enum UserKind {
  MEMBER = "MEMBER",
  PAT = "PAT",
  PUBLIC = "PUBLIC",
  WEBHOOK = "WEBHOOK",
}

/** Identifies documents */
export type UserManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  picture?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
};

export enum UserOrderByInput {
  createdAt_ASC = "createdAt_ASC",
  createdAt_DESC = "createdAt_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  isActive_ASC = "isActive_ASC",
  isActive_DESC = "isActive_DESC",
  kind_ASC = "kind_ASC",
  kind_DESC = "kind_DESC",
  name_ASC = "name_ASC",
  name_DESC = "name_DESC",
  picture_ASC = "picture_ASC",
  picture_DESC = "picture_DESC",
  publishedAt_ASC = "publishedAt_ASC",
  publishedAt_DESC = "publishedAt_DESC",
  updatedAt_ASC = "updatedAt_ASC",
  updatedAt_DESC = "updatedAt_DESC",
}

export type UserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Disconnect multiple User documents */
  disconnect?: InputMaybe<Array<UserWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: InputMaybe<Array<UserWhereUniqueInput>>;
};

export type UserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: InputMaybe<UserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** This contains a set of filters that can be used to compare values internally */
export type UserWhereComparatorInput = {
  /** This field can be used to request to check if the entry is outdated by internal comparison */
  outdated_to?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/** Identifies documents */
export type UserWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  createdAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  createdAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  createdAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  createdAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  createdAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  documentInStages_every?: InputMaybe<UserWhereStageInput>;
  documentInStages_none?: InputMaybe<UserWhereStageInput>;
  documentInStages_some?: InputMaybe<UserWhereStageInput>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  isActive_not?: InputMaybe<Scalars["Boolean"]["input"]>;
  kind?: InputMaybe<UserKind>;
  /** All values that are contained in given list. */
  kind_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  /** Any other value that exists and is not equal to the given value. */
  kind_not?: InputMaybe<UserKind>;
  /** All values that are not contained in given list. */
  kind_not_in?: InputMaybe<Array<InputMaybe<UserKind>>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  name_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  name_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  name_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  name_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  name_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  name_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  name_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  name_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  name_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  picture?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  picture_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  picture_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  picture_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  picture_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  picture_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  picture_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  picture_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  picture_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  publishedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  publishedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  publishedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  publishedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  publishedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
  updatedAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than the given value. */
  updatedAt_gt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are contained in given list. */
  updatedAt_in?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  /** All values less than the given value. */
  updatedAt_lt?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** Any other value that exists and is not equal to the given value. */
  updatedAt_not?: InputMaybe<Scalars["DateTime"]["input"]>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["DateTime"]["input"]>>
  >;
};

/** The document in stages filter allows specifying a stage entry to cross compare the same document between different stages */
export type UserWhereStageInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<UserWhereStageInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<UserWhereStageInput>>;
  /** This field contains fields which can be set as true or false to specify an internal comparison */
  compareWithParent?: InputMaybe<UserWhereComparatorInput>;
  /** Specify the stage to compare with */
  stage?: InputMaybe<Stage>;
};

/** References User record uniquely */
export type UserWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

/**
 * Top line is a heading in white
 * Second line is information in accent text
 */
export type ValueProposition = Entity & {
  __typename?: "ValueProposition";
  headingText?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  informationText?: Maybe<Scalars["String"]["output"]>;
  /** System stage field */
  stage: Stage;
};

export type ValuePropositionConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: ValuePropositionWhereUniqueInput;
};

/** A connection to a list of items. */
export type ValuePropositionConnection = {
  __typename?: "ValuePropositionConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<ValuePropositionEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type ValuePropositionCreateInput = {
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  informationText?: InputMaybe<Scalars["String"]["input"]>;
};

export type ValuePropositionCreateManyInlineInput = {
  /** Create and connect multiple existing ValueProposition documents */
  create?: InputMaybe<Array<ValuePropositionCreateInput>>;
};

export type ValuePropositionCreateOneInlineInput = {
  /** Create and connect one ValueProposition document */
  create?: InputMaybe<ValuePropositionCreateInput>;
};

export type ValuePropositionCreateWithPositionInput = {
  /** Document to create */
  data: ValuePropositionCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type ValuePropositionEdge = {
  __typename?: "ValuePropositionEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: ValueProposition;
};

/** Identifies documents */
export type ValuePropositionManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ValuePropositionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ValuePropositionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ValuePropositionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  headingText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  headingText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  headingText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  headingText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  headingText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  headingText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  headingText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  headingText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  headingText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  informationText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  informationText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  informationText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  informationText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  informationText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  informationText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  informationText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  informationText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  informationText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  informationText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

export enum ValuePropositionOrderByInput {
  headingText_ASC = "headingText_ASC",
  headingText_DESC = "headingText_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  informationText_ASC = "informationText_ASC",
  informationText_DESC = "informationText_DESC",
}

export type ValuePropositionParent = PageHero;

export type ValuePropositionParentConnectInput = {
  PageHero?: InputMaybe<PageHeroConnectInput>;
};

export type ValuePropositionParentCreateInput = {
  PageHero?: InputMaybe<PageHeroCreateInput>;
};

export type ValuePropositionParentCreateManyInlineInput = {
  /** Connect multiple existing ValuePropositionParent documents */
  connect?: InputMaybe<Array<ValuePropositionParentWhereUniqueInput>>;
  /** Create and connect multiple existing ValuePropositionParent documents */
  create?: InputMaybe<Array<ValuePropositionParentCreateInput>>;
};

export type ValuePropositionParentCreateOneInlineInput = {
  /** Connect one existing ValuePropositionParent document */
  connect?: InputMaybe<ValuePropositionParentWhereUniqueInput>;
  /** Create and connect one ValuePropositionParent document */
  create?: InputMaybe<ValuePropositionParentCreateInput>;
};

export type ValuePropositionParentUpdateInput = {
  PageHero?: InputMaybe<PageHeroUpdateInput>;
};

export type ValuePropositionParentUpdateManyInlineInput = {
  /** Connect multiple existing ValuePropositionParent documents */
  connect?: InputMaybe<Array<ValuePropositionParentConnectInput>>;
  /** Create and connect multiple ValuePropositionParent documents */
  create?: InputMaybe<Array<ValuePropositionParentCreateInput>>;
  /** Delete multiple ValuePropositionParent documents */
  delete?: InputMaybe<Array<ValuePropositionParentWhereUniqueInput>>;
  /** Disconnect multiple ValuePropositionParent documents */
  disconnect?: InputMaybe<Array<ValuePropositionParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing ValuePropositionParent documents */
  set?: InputMaybe<Array<ValuePropositionParentWhereUniqueInput>>;
  /** Update multiple ValuePropositionParent documents */
  update?: InputMaybe<
    Array<ValuePropositionParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple ValuePropositionParent documents */
  upsert?: InputMaybe<
    Array<ValuePropositionParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type ValuePropositionParentUpdateManyWithNestedWhereInput = {
  PageHero?: InputMaybe<PageHeroUpdateManyWithNestedWhereInput>;
};

export type ValuePropositionParentUpdateOneInlineInput = {
  /** Connect existing ValuePropositionParent document */
  connect?: InputMaybe<ValuePropositionParentWhereUniqueInput>;
  /** Create and connect one ValuePropositionParent document */
  create?: InputMaybe<ValuePropositionParentCreateInput>;
  /** Delete currently connected ValuePropositionParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected ValuePropositionParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ValuePropositionParent document */
  update?: InputMaybe<ValuePropositionParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ValuePropositionParent document */
  upsert?: InputMaybe<ValuePropositionParentUpsertWithNestedWhereUniqueInput>;
};

export type ValuePropositionParentUpdateWithNestedWhereUniqueInput = {
  PageHero?: InputMaybe<PageHeroUpdateWithNestedWhereUniqueInput>;
};

export type ValuePropositionParentUpsertWithNestedWhereUniqueInput = {
  PageHero?: InputMaybe<PageHeroUpsertWithNestedWhereUniqueInput>;
};

export type ValuePropositionParentWhereInput = {
  PageHero?: InputMaybe<PageHeroWhereInput>;
};

export type ValuePropositionParentWhereUniqueInput = {
  PageHero?: InputMaybe<PageHeroWhereUniqueInput>;
};

export type ValuePropositionUpdateInput = {
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  informationText?: InputMaybe<Scalars["String"]["input"]>;
};

export type ValuePropositionUpdateManyInlineInput = {
  /** Create and connect multiple ValueProposition component instances */
  create?: InputMaybe<Array<ValuePropositionCreateWithPositionInput>>;
  /** Delete multiple ValueProposition documents */
  delete?: InputMaybe<Array<ValuePropositionWhereUniqueInput>>;
  /** Update multiple ValueProposition component instances */
  update?: InputMaybe<
    Array<ValuePropositionUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple ValueProposition component instances */
  upsert?: InputMaybe<
    Array<ValuePropositionUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type ValuePropositionUpdateManyInput = {
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  informationText?: InputMaybe<Scalars["String"]["input"]>;
};

export type ValuePropositionUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: ValuePropositionUpdateManyInput;
  /** Document search */
  where: ValuePropositionWhereInput;
};

export type ValuePropositionUpdateOneInlineInput = {
  /** Create and connect one ValueProposition document */
  create?: InputMaybe<ValuePropositionCreateInput>;
  /** Delete currently connected ValueProposition document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single ValueProposition document */
  update?: InputMaybe<ValuePropositionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ValueProposition document */
  upsert?: InputMaybe<ValuePropositionUpsertWithNestedWhereUniqueInput>;
};

export type ValuePropositionUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<ValuePropositionUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ValuePropositionWhereUniqueInput;
};

export type ValuePropositionUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: ValuePropositionUpdateInput;
  /** Unique document search */
  where: ValuePropositionWhereUniqueInput;
};

export type ValuePropositionUpsertInput = {
  /** Create document if it didn't exist */
  create: ValuePropositionCreateInput;
  /** Update document if it exists */
  update: ValuePropositionUpdateInput;
};

export type ValuePropositionUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<ValuePropositionUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: ValuePropositionWhereUniqueInput;
};

export type ValuePropositionUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: ValuePropositionUpsertInput;
  /** Unique document search */
  where: ValuePropositionWhereUniqueInput;
};

/** Identifies documents */
export type ValuePropositionWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<ValuePropositionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<ValuePropositionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<ValuePropositionWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  headingText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  headingText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  headingText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  headingText_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  headingText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  headingText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  headingText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  headingText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  headingText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  headingText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  informationText?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  informationText_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  informationText_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  informationText_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** Any other value that exists and is not equal to the given value. */
  informationText_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  informationText_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  informationText_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  informationText_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  informationText_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  informationText_starts_with?: InputMaybe<Scalars["String"]["input"]>;
};

/** References ValueProposition record uniquely */
export type ValuePropositionWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type Version = {
  __typename?: "Version";
  createdAt: Scalars["DateTime"]["output"];
  id: Scalars["ID"]["output"];
  revision: Scalars["Int"]["output"];
  stage: Stage;
};

export type VersionWhereInput = {
  id: Scalars["ID"]["input"];
  revision: Scalars["Int"]["input"];
  stage: Stage;
};

export type WhitelistedAddress = Entity & {
  __typename?: "WhitelistedAddress";
  address?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
};

export type WhitelistedAddressConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: WhitelistedAddressWhereUniqueInput;
};

/** A connection to a list of items. */
export type WhitelistedAddressConnection = {
  __typename?: "WhitelistedAddressConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<WhitelistedAddressEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type WhitelistedAddressCreateInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
};

export type WhitelistedAddressCreateManyInlineInput = {
  /** Create and connect multiple existing WhitelistedAddress documents */
  create?: InputMaybe<Array<WhitelistedAddressCreateInput>>;
};

export type WhitelistedAddressCreateOneInlineInput = {
  /** Create and connect one WhitelistedAddress document */
  create?: InputMaybe<WhitelistedAddressCreateInput>;
};

export type WhitelistedAddressCreateWithPositionInput = {
  /** Document to create */
  data: WhitelistedAddressCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type WhitelistedAddressEdge = {
  __typename?: "WhitelistedAddressEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: WhitelistedAddress;
};

/** Identifies documents */
export type WhitelistedAddressManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<WhitelistedAddressWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<WhitelistedAddressWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<WhitelistedAddressWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  address_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  address_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  address_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  address_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  address_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  address_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  address_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  address_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  address_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum WhitelistedAddressOrderByInput {
  address_ASC = "address_ASC",
  address_DESC = "address_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
}

export type WhitelistedAddressParent = MiningNftWhitelistManualEntry;

export type WhitelistedAddressParentConnectInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryConnectInput>;
};

export type WhitelistedAddressParentCreateInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryCreateInput>;
};

export type WhitelistedAddressParentCreateManyInlineInput = {
  /** Connect multiple existing WhitelistedAddressParent documents */
  connect?: InputMaybe<Array<WhitelistedAddressParentWhereUniqueInput>>;
  /** Create and connect multiple existing WhitelistedAddressParent documents */
  create?: InputMaybe<Array<WhitelistedAddressParentCreateInput>>;
};

export type WhitelistedAddressParentCreateOneInlineInput = {
  /** Connect one existing WhitelistedAddressParent document */
  connect?: InputMaybe<WhitelistedAddressParentWhereUniqueInput>;
  /** Create and connect one WhitelistedAddressParent document */
  create?: InputMaybe<WhitelistedAddressParentCreateInput>;
};

export type WhitelistedAddressParentUpdateInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryUpdateInput>;
};

export type WhitelistedAddressParentUpdateManyInlineInput = {
  /** Connect multiple existing WhitelistedAddressParent documents */
  connect?: InputMaybe<Array<WhitelistedAddressParentConnectInput>>;
  /** Create and connect multiple WhitelistedAddressParent documents */
  create?: InputMaybe<Array<WhitelistedAddressParentCreateInput>>;
  /** Delete multiple WhitelistedAddressParent documents */
  delete?: InputMaybe<Array<WhitelistedAddressParentWhereUniqueInput>>;
  /** Disconnect multiple WhitelistedAddressParent documents */
  disconnect?: InputMaybe<Array<WhitelistedAddressParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing WhitelistedAddressParent documents */
  set?: InputMaybe<Array<WhitelistedAddressParentWhereUniqueInput>>;
  /** Update multiple WhitelistedAddressParent documents */
  update?: InputMaybe<
    Array<WhitelistedAddressParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple WhitelistedAddressParent documents */
  upsert?: InputMaybe<
    Array<WhitelistedAddressParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type WhitelistedAddressParentUpdateManyWithNestedWhereInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryUpdateManyWithNestedWhereInput>;
};

export type WhitelistedAddressParentUpdateOneInlineInput = {
  /** Connect existing WhitelistedAddressParent document */
  connect?: InputMaybe<WhitelistedAddressParentWhereUniqueInput>;
  /** Create and connect one WhitelistedAddressParent document */
  create?: InputMaybe<WhitelistedAddressParentCreateInput>;
  /** Delete currently connected WhitelistedAddressParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected WhitelistedAddressParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single WhitelistedAddressParent document */
  update?: InputMaybe<WhitelistedAddressParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single WhitelistedAddressParent document */
  upsert?: InputMaybe<WhitelistedAddressParentUpsertWithNestedWhereUniqueInput>;
};

export type WhitelistedAddressParentUpdateWithNestedWhereUniqueInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryUpdateWithNestedWhereUniqueInput>;
};

export type WhitelistedAddressParentUpsertWithNestedWhereUniqueInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryUpsertWithNestedWhereUniqueInput>;
};

export type WhitelistedAddressParentWhereInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryWhereInput>;
};

export type WhitelistedAddressParentWhereUniqueInput = {
  MiningNftWhitelistManualEntry?: InputMaybe<MiningNftWhitelistManualEntryWhereUniqueInput>;
};

export type WhitelistedAddressUpdateInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
};

export type WhitelistedAddressUpdateManyInlineInput = {
  /** Create and connect multiple WhitelistedAddress component instances */
  create?: InputMaybe<Array<WhitelistedAddressCreateWithPositionInput>>;
  /** Delete multiple WhitelistedAddress documents */
  delete?: InputMaybe<Array<WhitelistedAddressWhereUniqueInput>>;
  /** Update multiple WhitelistedAddress component instances */
  update?: InputMaybe<
    Array<WhitelistedAddressUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple WhitelistedAddress component instances */
  upsert?: InputMaybe<
    Array<WhitelistedAddressUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type WhitelistedAddressUpdateManyInput = {
  address?: InputMaybe<Scalars["String"]["input"]>;
};

export type WhitelistedAddressUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: WhitelistedAddressUpdateManyInput;
  /** Document search */
  where: WhitelistedAddressWhereInput;
};

export type WhitelistedAddressUpdateOneInlineInput = {
  /** Create and connect one WhitelistedAddress document */
  create?: InputMaybe<WhitelistedAddressCreateInput>;
  /** Delete currently connected WhitelistedAddress document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single WhitelistedAddress document */
  update?: InputMaybe<WhitelistedAddressUpdateWithNestedWhereUniqueInput>;
  /** Upsert single WhitelistedAddress document */
  upsert?: InputMaybe<WhitelistedAddressUpsertWithNestedWhereUniqueInput>;
};

export type WhitelistedAddressUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<WhitelistedAddressUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: WhitelistedAddressWhereUniqueInput;
};

export type WhitelistedAddressUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: WhitelistedAddressUpdateInput;
  /** Unique document search */
  where: WhitelistedAddressWhereUniqueInput;
};

export type WhitelistedAddressUpsertInput = {
  /** Create document if it didn't exist */
  create: WhitelistedAddressCreateInput;
  /** Update document if it exists */
  update: WhitelistedAddressUpdateInput;
};

export type WhitelistedAddressUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<WhitelistedAddressUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: WhitelistedAddressWhereUniqueInput;
};

export type WhitelistedAddressUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: WhitelistedAddressUpsertInput;
  /** Unique document search */
  where: WhitelistedAddressWhereUniqueInput;
};

/** Identifies documents */
export type WhitelistedAddressWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<WhitelistedAddressWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<WhitelistedAddressWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<WhitelistedAddressWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  address?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  address_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  address_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  address_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  address_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  address_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  address_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  address_not_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** All values not starting with the given string. */
  address_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  address_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
};

/** References WhitelistedAddress record uniquely */
export type WhitelistedAddressWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type YoutubeVideo = Entity & {
  __typename?: "YoutubeVideo";
  /** this is optional, only add if you want a specific title to show up above the carousel */
  carouselTitle?: Maybe<Scalars["String"]["output"]>;
  /** The unique identifier */
  id: Scalars["ID"]["output"];
  /** System stage field */
  stage: Stage;
  youtubeVideoId: Array<Scalars["String"]["output"]>;
};

export type YoutubeVideoConnectInput = {
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Document to connect */
  where: YoutubeVideoWhereUniqueInput;
};

/** A connection to a list of items. */
export type YoutubeVideoConnection = {
  __typename?: "YoutubeVideoConnection";
  aggregate: Aggregate;
  /** A list of edges. */
  edges: Array<YoutubeVideoEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type YoutubeVideoCreateInput = {
  carouselTitle?: InputMaybe<Scalars["String"]["input"]>;
  youtubeVideoId?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type YoutubeVideoCreateManyInlineInput = {
  /** Create and connect multiple existing YoutubeVideo documents */
  create?: InputMaybe<Array<YoutubeVideoCreateInput>>;
};

export type YoutubeVideoCreateOneInlineInput = {
  /** Create and connect one YoutubeVideo document */
  create?: InputMaybe<YoutubeVideoCreateInput>;
};

export type YoutubeVideoCreateWithPositionInput = {
  /** Document to create */
  data: YoutubeVideoCreateInput;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
};

/** An edge in a connection. */
export type YoutubeVideoEdge = {
  __typename?: "YoutubeVideoEdge";
  /** A cursor for use in pagination. */
  cursor: Scalars["String"]["output"];
  /** The item at the end of the edge. */
  node: YoutubeVideo;
};

/** Identifies documents */
export type YoutubeVideoManyWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<YoutubeVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<YoutubeVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<YoutubeVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  carouselTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  carouselTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  carouselTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  carouselTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  carouselTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  carouselTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  carouselTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  carouselTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  carouselTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  carouselTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  youtubeVideoId?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  youtubeVideoId_contains_all?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  youtubeVideoId_contains_none?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array contains at least one item provided to the filter */
  youtubeVideoId_contains_some?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  youtubeVideoId_not?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export enum YoutubeVideoOrderByInput {
  carouselTitle_ASC = "carouselTitle_ASC",
  carouselTitle_DESC = "carouselTitle_DESC",
  id_ASC = "id_ASC",
  id_DESC = "id_DESC",
  youtubeVideoId_ASC = "youtubeVideoId_ASC",
  youtubeVideoId_DESC = "youtubeVideoId_DESC",
}

export type YoutubeVideoParent = LandingPage;

export type YoutubeVideoParentConnectInput = {
  LandingPage?: InputMaybe<LandingPageConnectInput>;
};

export type YoutubeVideoParentCreateInput = {
  LandingPage?: InputMaybe<LandingPageCreateInput>;
};

export type YoutubeVideoParentCreateManyInlineInput = {
  /** Connect multiple existing YoutubeVideoParent documents */
  connect?: InputMaybe<Array<YoutubeVideoParentWhereUniqueInput>>;
  /** Create and connect multiple existing YoutubeVideoParent documents */
  create?: InputMaybe<Array<YoutubeVideoParentCreateInput>>;
};

export type YoutubeVideoParentCreateOneInlineInput = {
  /** Connect one existing YoutubeVideoParent document */
  connect?: InputMaybe<YoutubeVideoParentWhereUniqueInput>;
  /** Create and connect one YoutubeVideoParent document */
  create?: InputMaybe<YoutubeVideoParentCreateInput>;
};

export type YoutubeVideoParentUpdateInput = {
  LandingPage?: InputMaybe<LandingPageUpdateInput>;
};

export type YoutubeVideoParentUpdateManyInlineInput = {
  /** Connect multiple existing YoutubeVideoParent documents */
  connect?: InputMaybe<Array<YoutubeVideoParentConnectInput>>;
  /** Create and connect multiple YoutubeVideoParent documents */
  create?: InputMaybe<Array<YoutubeVideoParentCreateInput>>;
  /** Delete multiple YoutubeVideoParent documents */
  delete?: InputMaybe<Array<YoutubeVideoParentWhereUniqueInput>>;
  /** Disconnect multiple YoutubeVideoParent documents */
  disconnect?: InputMaybe<Array<YoutubeVideoParentWhereUniqueInput>>;
  /** Override currently-connected documents with multiple existing YoutubeVideoParent documents */
  set?: InputMaybe<Array<YoutubeVideoParentWhereUniqueInput>>;
  /** Update multiple YoutubeVideoParent documents */
  update?: InputMaybe<
    Array<YoutubeVideoParentUpdateWithNestedWhereUniqueInput>
  >;
  /** Upsert multiple YoutubeVideoParent documents */
  upsert?: InputMaybe<
    Array<YoutubeVideoParentUpsertWithNestedWhereUniqueInput>
  >;
};

export type YoutubeVideoParentUpdateManyWithNestedWhereInput = {
  LandingPage?: InputMaybe<LandingPageUpdateManyWithNestedWhereInput>;
};

export type YoutubeVideoParentUpdateOneInlineInput = {
  /** Connect existing YoutubeVideoParent document */
  connect?: InputMaybe<YoutubeVideoParentWhereUniqueInput>;
  /** Create and connect one YoutubeVideoParent document */
  create?: InputMaybe<YoutubeVideoParentCreateInput>;
  /** Delete currently connected YoutubeVideoParent document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Disconnect currently connected YoutubeVideoParent document */
  disconnect?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single YoutubeVideoParent document */
  update?: InputMaybe<YoutubeVideoParentUpdateWithNestedWhereUniqueInput>;
  /** Upsert single YoutubeVideoParent document */
  upsert?: InputMaybe<YoutubeVideoParentUpsertWithNestedWhereUniqueInput>;
};

export type YoutubeVideoParentUpdateWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpdateWithNestedWhereUniqueInput>;
};

export type YoutubeVideoParentUpsertWithNestedWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageUpsertWithNestedWhereUniqueInput>;
};

export type YoutubeVideoParentWhereInput = {
  LandingPage?: InputMaybe<LandingPageWhereInput>;
};

export type YoutubeVideoParentWhereUniqueInput = {
  LandingPage?: InputMaybe<LandingPageWhereUniqueInput>;
};

export type YoutubeVideoUpdateInput = {
  carouselTitle?: InputMaybe<Scalars["String"]["input"]>;
  youtubeVideoId?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type YoutubeVideoUpdateManyInlineInput = {
  /** Create and connect multiple YoutubeVideo component instances */
  create?: InputMaybe<Array<YoutubeVideoCreateWithPositionInput>>;
  /** Delete multiple YoutubeVideo documents */
  delete?: InputMaybe<Array<YoutubeVideoWhereUniqueInput>>;
  /** Update multiple YoutubeVideo component instances */
  update?: InputMaybe<
    Array<YoutubeVideoUpdateWithNestedWhereUniqueAndPositionInput>
  >;
  /** Upsert multiple YoutubeVideo component instances */
  upsert?: InputMaybe<
    Array<YoutubeVideoUpsertWithNestedWhereUniqueAndPositionInput>
  >;
};

export type YoutubeVideoUpdateManyInput = {
  carouselTitle?: InputMaybe<Scalars["String"]["input"]>;
  youtubeVideoId?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type YoutubeVideoUpdateManyWithNestedWhereInput = {
  /** Update many input */
  data: YoutubeVideoUpdateManyInput;
  /** Document search */
  where: YoutubeVideoWhereInput;
};

export type YoutubeVideoUpdateOneInlineInput = {
  /** Create and connect one YoutubeVideo document */
  create?: InputMaybe<YoutubeVideoCreateInput>;
  /** Delete currently connected YoutubeVideo document */
  delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  /** Update single YoutubeVideo document */
  update?: InputMaybe<YoutubeVideoUpdateWithNestedWhereUniqueInput>;
  /** Upsert single YoutubeVideo document */
  upsert?: InputMaybe<YoutubeVideoUpsertWithNestedWhereUniqueInput>;
};

export type YoutubeVideoUpdateWithNestedWhereUniqueAndPositionInput = {
  /** Document to update */
  data?: InputMaybe<YoutubeVideoUpdateInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: YoutubeVideoWhereUniqueInput;
};

export type YoutubeVideoUpdateWithNestedWhereUniqueInput = {
  /** Document to update */
  data: YoutubeVideoUpdateInput;
  /** Unique document search */
  where: YoutubeVideoWhereUniqueInput;
};

export type YoutubeVideoUpsertInput = {
  /** Create document if it didn't exist */
  create: YoutubeVideoCreateInput;
  /** Update document if it exists */
  update: YoutubeVideoUpdateInput;
};

export type YoutubeVideoUpsertWithNestedWhereUniqueAndPositionInput = {
  /** Document to upsert */
  data?: InputMaybe<YoutubeVideoUpsertInput>;
  /** Position in the list of existing component instances, will default to appending at the end of list */
  position?: InputMaybe<ConnectPositionInput>;
  /** Unique component instance search */
  where: YoutubeVideoWhereUniqueInput;
};

export type YoutubeVideoUpsertWithNestedWhereUniqueInput = {
  /** Upsert data */
  data: YoutubeVideoUpsertInput;
  /** Unique document search */
  where: YoutubeVideoWhereUniqueInput;
};

/** Identifies documents */
export type YoutubeVideoWhereInput = {
  /** Logical AND on all given filters. */
  AND?: InputMaybe<Array<YoutubeVideoWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: InputMaybe<Array<YoutubeVideoWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: InputMaybe<Array<YoutubeVideoWhereInput>>;
  /** Contains search across all appropriate fields. */
  _search?: InputMaybe<Scalars["String"]["input"]>;
  carouselTitle?: InputMaybe<Scalars["String"]["input"]>;
  /** All values containing the given string. */
  carouselTitle_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values ending with the given string. */
  carouselTitle_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are contained in given list. */
  carouselTitle_in?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  carouselTitle_not?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not containing the given string. */
  carouselTitle_not_contains?: InputMaybe<Scalars["String"]["input"]>;
  /** All values not ending with the given string */
  carouselTitle_not_ends_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values that are not contained in given list. */
  carouselTitle_not_in?: InputMaybe<
    Array<InputMaybe<Scalars["String"]["input"]>>
  >;
  /** All values not starting with the given string. */
  carouselTitle_not_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  /** All values starting with the given string. */
  carouselTitle_starts_with?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values containing the given string. */
  id_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values ending with the given string. */
  id_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are contained in given list. */
  id_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** Any other value that exists and is not equal to the given value. */
  id_not?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not containing the given string. */
  id_not_contains?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values not ending with the given string */
  id_not_ends_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values that are not contained in given list. */
  id_not_in?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  /** All values not starting with the given string. */
  id_not_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** All values starting with the given string. */
  id_starts_with?: InputMaybe<Scalars["ID"]["input"]>;
  /** Matches if the field array contains *all* items provided to the filter and order does match */
  youtubeVideoId?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array contains *all* items provided to the filter */
  youtubeVideoId_contains_all?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array does not contain any of the items provided to the filter */
  youtubeVideoId_contains_none?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array contains at least one item provided to the filter */
  youtubeVideoId_contains_some?: InputMaybe<Array<Scalars["String"]["input"]>>;
  /** Matches if the field array does not contains *all* items provided to the filter or order does not match */
  youtubeVideoId_not?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

/** References YoutubeVideo record uniquely */
export type YoutubeVideoWhereUniqueInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export enum _FilterKind {
  AND = "AND",
  NOT = "NOT",
  OR = "OR",
  contains = "contains",
  contains_all = "contains_all",
  contains_none = "contains_none",
  contains_some = "contains_some",
  ends_with = "ends_with",
  eq = "eq",
  eq_not = "eq_not",
  gt = "gt",
  gte = "gte",
  in = "in",
  json_path_exists = "json_path_exists",
  json_value_recursive = "json_value_recursive",
  lt = "lt",
  lte = "lte",
  not_contains = "not_contains",
  not_ends_with = "not_ends_with",
  not_in = "not_in",
  not_starts_with = "not_starts_with",
  relational_every = "relational_every",
  relational_none = "relational_none",
  relational_single = "relational_single",
  relational_some = "relational_some",
  search = "search",
  starts_with = "starts_with",
  union_empty = "union_empty",
  union_every = "union_every",
  union_none = "union_none",
  union_single = "union_single",
  union_some = "union_some",
}

export enum _MutationInputFieldKind {
  enum = "enum",
  relation = "relation",
  richText = "richText",
  richTextWithEmbeds = "richTextWithEmbeds",
  scalar = "scalar",
  union = "union",
  virtual = "virtual",
}

export enum _MutationKind {
  create = "create",
  delete = "delete",
  deleteMany = "deleteMany",
  publish = "publish",
  publishMany = "publishMany",
  schedulePublish = "schedulePublish",
  scheduleUnpublish = "scheduleUnpublish",
  unpublish = "unpublish",
  unpublishMany = "unpublishMany",
  update = "update",
  updateMany = "updateMany",
  upsert = "upsert",
}

export enum _OrderDirection {
  asc = "asc",
  desc = "desc",
}

export enum _RelationInputCardinality {
  many = "many",
  one = "one",
}

export enum _RelationInputKind {
  create = "create",
  update = "update",
}

export enum _RelationKind {
  regular = "regular",
  union = "union",
}

export enum _SystemDateTimeFieldVariation {
  base = "base",
  combined = "combined",
  localization = "localization",
}

export type UpdateTotalMintedPupsPresaleMutationVariables = Exact<{
  totalMinted?: InputMaybe<Scalars["Int"]["input"]>;
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdateTotalMintedPupsPresaleMutation = {
  __typename?: "Mutation";
  updatePupsPresaleCommunity?: {
    __typename?: "PupsPresaleCommunity";
    totalMinted?: number | null;
  } | null;
};

export type PublishPupsPresaleCommunityMutationVariables = Exact<{
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type PublishPupsPresaleCommunityMutation = {
  __typename?: "Mutation";
  publishPupsPresaleCommunity?: {
    __typename?: "PupsPresaleCommunity";
    totalMinted?: number | null;
    pageUrl: string;
    presaleLimit?: number | null;
  } | null;
};

export type GetAlphaMintingProjectsCarouselQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAlphaMintingProjectsCarouselQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type BrandAmbassadorCtaQueryVariables = Exact<{ [key: string]: never }>;

export type BrandAmbassadorCtaQuery = {
  __typename?: "Query";
  ctaSection?: {
    __typename?: "CtaSection";
    backgroundFrom?: Colors | null;
    backgroundTo?: Colors | null;
    image?: { __typename?: "Asset"; url: string } | null;
    text: Array<{
      __typename?: "ColoredText";
      id: string;
      tag?: Tags | null;
      text?: string | null;
      textColor?: Colors | null;
    }>;
    mainButton?: {
      __typename?: "Button";
      buttonLink?: string | null;
      buttonText?: string | null;
      buttonVariant?: ButtonVariants | null;
      externalLink?: boolean | null;
      id: string;
    } | null;
  } | null;
};

export type CollectionCategoryListingQueryVariables = Exact<{
  [key: string]: never;
}>;

export type CollectionCategoryListingQuery = {
  __typename?: "Query";
  nftCategoryDisplays: Array<{
    __typename?: "NftCategoryDisplay";
    id: string;
    categoryDisplayTitle?: string | null;
    categoryDescription?: string | null;
    nftCollections: Array<{
      __typename?: "NftCollection";
      id: string;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      currency?: Currencies | null;
      chain?: {
        __typename?: "Chain";
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainId?: number | null;
        chainSlug: string;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    }>;
  }>;
};

export type FeaturedCollectionGroupQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type FeaturedCollectionGroupQuery = {
  __typename?: "Query";
  featuredCollectionGroup?: {
    __typename?: "FeaturedCollectionGroup";
    nftCollections: Array<{
      __typename?: "NftCollection";
      id: string;
      collectionTitle?: string | null;
      collectionContractAddress: string;
      collectionCategories: Array<CollectionCategories>;
      collectionDescription?: string | null;
      collectionTotalSupply?: number | null;
      collectionListingDate?: any | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainSlug: string;
      } | null;
    }>;
  } | null;
};

export type GetCollectionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetCollectionsQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionTitle?: string | null;
    collectionContractAddress: string;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type GetCollectionsByCategoryQueryVariables = Exact<{
  collectionCategories_contains_all?: InputMaybe<
    Array<CollectionCategories> | CollectionCategories
  >;
}>;

export type GetCollectionsByCategoryQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionTitle?: string | null;
    collectionContractAddress: string;
    collectionCategories: Array<CollectionCategories>;
    collectionDescription?: string | null;
    collectionListingDate?: any | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type GetHeroCollectionsByCategoryQueryVariables = Exact<{
  collectionCategories_contains_all?: InputMaybe<
    Array<CollectionCategories> | CollectionCategories
  >;
}>;

export type GetHeroCollectionsByCategoryQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionTitle?: string | null;
    collectionContractAddress: string;
    collectionCategories: Array<CollectionCategories>;
    collectionDescription?: string | null;
    collectionTotalSupply?: number | null;
    collectionListingDate?: any | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    chain?: {
      __typename?: "Chain";
      chainId?: number | null;
      chainSlug: string;
    } | null;
  }>;
};

export type GetCollectionCreatorBySlugQueryVariables = Exact<{
  collectionCreatorSlug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetCollectionCreatorBySlugQuery = {
  __typename?: "Query";
  collectionCreator?: {
    __typename?: "CollectionCreator";
    collectionCreatorBio?: string | null;
    collectionCreatorName?: string | null;
    collectionCreatorSlug?: string | null;
    collectionCreatorUrl?: string | null;
    categories: Array<CollectionCategories>;
    collectionCreatorThumbnailImage?: {
      __typename?: "Asset";
      url: string;
    } | null;
    collectionCreatorBannerImage?: { __typename?: "Asset"; url: string } | null;
    nftCollections: Array<{
      __typename?: "NftCollection";
      id: string;
      collectionContractAddress: string;
      collectionCategories: Array<CollectionCategories>;
      collectionDescription?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
      } | null;
      collectionFeaturedImage: Array<{ __typename?: "Asset"; url: string }>;
    }>;
  } | null;
};

export type CollectionCreatorsQueryVariables = Exact<{ [key: string]: never }>;

export type CollectionCreatorsQuery = {
  __typename?: "Query";
  collectionCreators: Array<{
    __typename?: "CollectionCreator";
    collectionCreatorBio?: string | null;
    collectionCreatorName?: string | null;
    collectionCreatorSlug?: string | null;
    collectionCreatorUrl?: string | null;
    categories: Array<CollectionCategories>;
    collectionCreatorThumbnailImage?: {
      __typename?: "Asset";
      url: string;
    } | null;
    collectionCreatorBannerImage?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type FeaturedCreatorGroupQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type FeaturedCreatorGroupQuery = {
  __typename?: "Query";
  featuredCreatorGroup?: {
    __typename?: "FeaturedCreatorGroup";
    collectionCreators: Array<{
      __typename?: "CollectionCreator";
      categories: Array<CollectionCategories>;
      collectionCreatorBio?: string | null;
      collectionCreatorName?: string | null;
      collectionCreatorSlug?: string | null;
      collectionCreatorUrl?: string | null;
      collectionCreatorBannerImage?: {
        __typename?: "Asset";
        url: string;
      } | null;
      collectionCreatorThumbnailImage?: {
        __typename?: "Asset";
        url: string;
      } | null;
    }>;
  } | null;
};

export type GeneralTextQueryVariables = Exact<{ [key: string]: never }>;

export type GeneralTextQuery = {
  __typename?: "Query";
  alphaSharesInformation?: {
    __typename?: "AlphaSharesInformation";
    disclaimer?: string | null;
  } | null;
};

export type GetNftCollectionAddressesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetNftCollectionAddressesQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    collectionContractAddress: string;
  }>;
};

export type GetSidebarNavigationQueryVariables = Exact<{
  applicationId?: InputMaybe<AppId>;
}>;

export type GetSidebarNavigationQuery = {
  __typename?: "Query";
  menus: Array<{
    __typename?: "Menu";
    id: string;
    menuGroupName?: string | null;
    menuSection: MenuType;
    navigationItems: Array<{
      __typename?: "NavigationItem";
      iconComponent?: HeroMenuIcons | null;
      id: string;
      internalSlug?: string | null;
      navigationItemText?: string | null;
      externalLink?: string | null;
      destinationAppId: AppId;
    }>;
  }>;
  menu?: {
    __typename?: "Menu";
    id: string;
    menuGroupName?: string | null;
    menuSection: MenuType;
    navigationItems: Array<{
      __typename?: "NavigationItem";
      externalLink?: string | null;
      iconComponent?: HeroMenuIcons | null;
      id: string;
      internalSlug?: string | null;
      navigationItemText?: string | null;
      destinationAppId: AppId;
    }>;
  } | null;
};

export type HelpCenterQueryVariables = Exact<{ [key: string]: never }>;

export type HelpCenterQuery = {
  __typename?: "Query";
  page?: {
    __typename?: "Page";
    pageTitle?: string | null;
    pageSlug?: string | null;
    fAQSections: Array<{
      __typename?: "FAQSection";
      id: string;
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        answer?: string | null;
        id: string;
      }>;
    }>;
  } | null;
};

export type HomePageQueryVariables = Exact<{ [key: string]: never }>;

export type HomePageQuery = {
  __typename?: "Query";
  page?: {
    __typename?: "Page";
    pageTitle?: string | null;
    pageSlug?: string | null;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    gettingStartedSection?: {
      __typename?: "GettingStartedSection";
      heading?: string | null;
      sections: Array<{
        __typename?: "InfoCard";
        cardHeading?: string | null;
        cardText?: string | null;
        id: string;
      }>;
    } | null;
    pageHero?: {
      __typename?: "PageHero";
      mainHeading?: string | null;
      subHeading?: string | null;
      backgroundImage?: {
        __typename?: "Asset";
        url: string;
        width?: number | null;
      } | null;
      buttons: Array<{
        __typename?: "Button";
        id: string;
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
      }>;
      flexibleText: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      heroImages: Array<{ __typename?: "Asset"; url: string }>;
      valueProps: Array<{
        __typename?: "ValueProposition";
        id: string;
        headingText?: string | null;
        informationText?: string | null;
      }>;
    } | null;
    infoCardsSection?: {
      __typename?: "InfoCardsSection";
      heading?: string | null;
      infoCard: Array<{
        __typename?: "InfoCard";
        id: string;
        cardText?: string | null;
        cardHeading?: string | null;
      }>;
    } | null;
    mintingStat?: {
      __typename?: "MintingStat";
      heading?: string | null;
      subHeading?: string | null;
      mintedCountText?: string | null;
      inProgressText?: string | null;
      feesInformationText?: string | null;
      backgroundImage?: {
        __typename?: "Asset";
        url: string;
        width?: number | null;
      } | null;
      button?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
      flexibleText?: {
        __typename?: "ColoredText";
        textColor?: Colors | null;
        text?: string | null;
        id: string;
        tag?: Tags | null;
      } | null;
      heroImage?: {
        __typename?: "Asset";
        url: string;
        width?: number | null;
      } | null;
    } | null;
    partnerSection?: {
      __typename?: "PartnerSection";
      heading?: string | null;
      partnerLogos: Array<{
        __typename?: "ImageWithAltTextAndLink";
        altText?: string | null;
        id: string;
        link?: string | null;
        image?: { __typename?: "Asset"; url: string } | null;
      }>;
    } | null;
    perksSection?: {
      __typename?: "PerksSection";
      heading?: string | null;
      iconHeaderSubheading: Array<{
        __typename?: "LargeIconHeading";
        subHeading?: string | null;
        id: string;
        heading?: string | null;
        icon?: { __typename?: "Asset"; id: string; url: string } | null;
      }>;
    } | null;
    poweredBySection?: {
      __typename?: "PoweredBySection";
      heading?: string | null;
      information?: string | null;
      buttons: Array<{
        __typename?: "Button";
        id: string;
        externalLink?: boolean | null;
        buttonVariant?: ButtonVariants | null;
        buttonText?: string | null;
        buttonLink?: string | null;
      }>;
      logo?: { __typename?: "Asset"; url: string } | null;
    } | null;
    fAQSection?: {
      __typename?: "FAQSection";
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        answer?: string | null;
        id: string;
      }>;
    } | null;
    alphaNftCollectionTier?: {
      __typename?: "AlphaNftCollectionTier";
      heading?: string | null;
      subHeading?: string | null;
      information?: string | null;
      tiers: Array<{
        __typename?: "AlphaNftTier";
        id: string;
        tier?: AlphaWolfTier | null;
        heading?: string | null;
        weightText?: string | null;
        totalAvailableText?: string | null;
        description?: string | null;
        image?: { __typename?: "Asset"; url: string } | null;
      }>;
    } | null;
    teamSection?: {
      __typename?: "TeamSection";
      heading?: string | null;
      teamMembers: Array<{
        __typename?: "TeamMemberImageNameTitle";
        id: string;
        name?: string | null;
        title?: string | null;
        truncatedBio?: string | null;
        image?: { __typename?: "Asset"; url: string } | null;
      }>;
    } | null;
  } | null;
};

export type LandingPageBySlugQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type LandingPageBySlugQuery = {
  __typename?: "Query";
  landingPage?: {
    __typename?: "LandingPage";
    pageTitle?: string | null;
    seoPageTitle?: string | null;
    seoDescription?: string | null;
    faqSectionTitle?: string | null;
    pressSectionTitle?: string | null;
    seoImage?: { __typename?: "Asset"; url: string } | null;
    logoImage?: { __typename?: "Asset"; url: string } | null;
    heroBannerImage?: { __typename?: "Asset"; url: string } | null;
    faQs: Array<{
      __typename?: "Faq";
      question?: string | null;
      id: string;
      answerRichText?: { __typename?: "RichText"; raw: any } | null;
    }>;
    heroText?: { __typename?: "RichText"; raw: any } | null;
    youtubeCarousel?: {
      __typename?: "YoutubeVideo";
      id: string;
      youtubeVideoId: Array<string>;
    } | null;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    ctaSectionBottom?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    textImageRow: Array<{
      __typename?: "RichTextImageRow";
      imageSide?: ImageSide | null;
      id: string;
      backgroundColor?: Colors | null;
      text?: { __typename?: "RichText"; raw: any } | null;
      backgroundImage?: { __typename?: "Asset"; url: string } | null;
      image?: { __typename?: "Asset"; url: string } | null;
    }>;
  } | null;
};

export type MetaFuseProjectsWithRevealOnMintQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MetaFuseProjectsWithRevealOnMintQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    metafuseProjectId?: string | null;
    shouldMetafuseRevealOnMint: boolean;
    maxMintPerTransaction: number;
    nftCollection?: {
      __typename?: "NftCollection";
      collectionContractAddress: string;
      collectionTotalSupply?: number | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainEnum?: ChainIDs | null;
        chainName?: string | null;
      } | null;
    } | null;
  }>;
};

export type GetAllMintingProjectsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetAllMintingProjectsQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    mintingCurrencyInformation?: {
      __typename?: "MintingCurrency";
      tokenIcon?: { __typename?: "Asset"; url: string } | null;
    } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type GetMintingProjectByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetMintingProjectByIdQuery = {
  __typename?: "Query";
  mintingProject?: {
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    presaleHeroSectionCopy?: string | null;
    metafuseProjectId?: string | null;
    shouldMetafuseRevealOnMint: boolean;
    hasDynamicPricing: boolean;
    dynamicPricingGetterFunction?: string | null;
    allocation?: number | null;
    paperCreditCardMintingLink?: string | null;
    mintingContractAbi?: any | null;
    presaleCheckerFunction?: string | null;
    priceQueryFunctionName?: string | null;
    presalePriceGetter?: string | null;
    presaleMintingFunctionName?: string | null;
    mintingFunctionName?: string | null;
    mintingCurrencyDecimals?: number | null;
    presaleMaxMintGetter?: string | null;
    publicSaleMaxMintGetter?: string | null;
    priceQueryRequiresAccount: boolean;
    currency?: Currencies | null;
    mintDate?: any | null;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    whitelistChecker?: string | null;
    mintPrice?: number | null;
    projectName: string;
    aboutSectionHeader?: string | null;
    aboutSectionText?: string | null;
    mintedNfTsCarouselHeader?: string | null;
    mintedNfTsCarouselSubHeader?: string | null;
    fAQSection?: {
      __typename?: "FAQSection";
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        id: string;
        answer?: string | null;
      }>;
    } | null;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    mintInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      name?: string | null;
      value?: string | null;
      id: string;
    }>;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    collectionSampleImages: Array<{ __typename?: "Asset"; url: string }>;
    collectionCreator?: {
      __typename?: "CollectionCreator";
      collectionCreatorBio?: string | null;
      collectionCreatorName?: string | null;
      collectionCreatorUrl?: string | null;
    } | null;
    nftCollection?: {
      __typename?: "NftCollection";
      currency?: Currencies | null;
      tradable: boolean;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    } | null;
    aboutSectionAccordions: Array<{
      __typename?: "Faq";
      answer?: string | null;
      id: string;
      question?: string | null;
    }>;
    mintingStat?: {
      __typename?: "MintingStat";
      heading?: string | null;
      subHeading?: string | null;
      feesInformationText?: string | null;
      inProgressText?: string | null;
      mintedCountText?: string | null;
      backgroundImage?: { __typename?: "Asset"; url: string } | null;
      button?: {
        __typename?: "Button";
        buttonText?: string | null;
        buttonLink?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
      } | null;
      flexibleText?: {
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      } | null;
      heroImage?: { __typename?: "Asset"; url: string } | null;
      heroImageLeft?: { __typename?: "Asset"; url: string } | null;
    } | null;
  } | null;
};

export type GetMintingProjectBySlugQueryVariables = Exact<{
  slug: Scalars["String"]["input"];
}>;

export type GetMintingProjectBySlugQuery = {
  __typename?: "Query";
  mintingProject?: {
    __typename?: "MintingProject";
    presaleHeroSectionCopy?: string | null;
    metafuseProjectId?: string | null;
    shouldMetafuseRevealOnMint: boolean;
    hasDynamicPricing: boolean;
    dynamicPricingGetterFunction?: string | null;
    allocation?: number | null;
    paperCreditCardMintingLink?: string | null;
    mintingContractAbi?: any | null;
    presaleCheckerFunction?: string | null;
    priceQueryFunctionName?: string | null;
    presalePriceGetter?: string | null;
    presaleMintingFunctionName?: string | null;
    mintingFunctionName?: string | null;
    mintingCurrencyDecimals?: number | null;
    presaleMaxMintGetter?: string | null;
    publicSaleMaxMintGetter?: string | null;
    priceQueryRequiresAccount: boolean;
    currency?: Currencies | null;
    mintDate?: any | null;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    whitelistChecker?: string | null;
    mintPrice?: number | null;
    projectName: string;
    aboutSectionHeader?: string | null;
    aboutSectionText?: string | null;
    mintedNfTsCarouselHeader?: string | null;
    mintedNfTsCarouselSubHeader?: string | null;
    fAQSection?: {
      __typename?: "FAQSection";
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        id: string;
        answer?: string | null;
      }>;
    } | null;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    mintInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      name?: string | null;
      value?: string | null;
      id: string;
    }>;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    collectionSampleImages: Array<{ __typename?: "Asset"; url: string }>;
    collectionCreator?: {
      __typename?: "CollectionCreator";
      collectionCreatorBio?: string | null;
      collectionCreatorName?: string | null;
      collectionCreatorUrl?: string | null;
    } | null;
    nftCollection?: {
      __typename?: "NftCollection";
      currency?: Currencies | null;
      tradable: boolean;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    } | null;
    aboutSectionAccordions: Array<{
      __typename?: "Faq";
      answer?: string | null;
      id: string;
      question?: string | null;
    }>;
    mintingStat?: {
      __typename?: "MintingStat";
      heading?: string | null;
      subHeading?: string | null;
      feesInformationText?: string | null;
      inProgressText?: string | null;
      mintedCountText?: string | null;
      backgroundImage?: { __typename?: "Asset"; url: string } | null;
      button?: {
        __typename?: "Button";
        buttonText?: string | null;
        buttonLink?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
      } | null;
      flexibleText?: {
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      } | null;
      heroImage?: { __typename?: "Asset"; url: string } | null;
      heroImageLeft?: { __typename?: "Asset"; url: string } | null;
    } | null;
  } | null;
};

export type GetMintingProjectSlugsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMintingProjectSlugsQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    slug?: string | null;
  }>;
};

export type GetMintingProjectsQueryVariables = Exact<{
  chainEnum?: InputMaybe<ChainIDs>;
}>;

export type GetMintingProjectsQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    soldOut?: boolean | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type GetMintingProjectsCarouselQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetMintingProjectsCarouselQuery = {
  __typename?: "Query";
  mintingProjects: Array<{
    __typename?: "MintingProject";
    id: string;
    slug?: string | null;
    currency?: Currencies | null;
    featured: boolean;
    projectSecurityTags: Array<ProjectSecurityTags>;
    mintingCurrency: string;
    mintingCurrencyContractAddress?: string | null;
    paperCreditCardMintingLink?: string | null;
    maxMintPerTransaction: number;
    mintingContractAddress?: string | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    projectName: string;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    mintingStat?: { __typename?: "MintingStat"; id: string } | null;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    nftCollection?: {
      __typename?: "NftCollection";
      tradable: boolean;
      currency?: Currencies | null;
      collectionCategories: Array<CollectionCategories>;
      collectionContractAddress: string;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionHomePage?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionTitle?: string | null;
      collectionTotalSupply?: number | null;
      collectionTwitterProfile?: string | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainName?: string | null;
        chainEnum?: ChainIDs | null;
        chainSlug: string;
      } | null;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    } | null;
  }>;
};

export type MultiChainMintingProjectSlugsQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MultiChainMintingProjectSlugsQuery = {
  __typename?: "Query";
  multiChainMintingProjects: Array<{
    __typename?: "MultiChainMintingProject";
    slug?: string | null;
  }>;
};

export type MultiChainMintingProjectQueryVariables = Exact<{
  slug?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type MultiChainMintingProjectQuery = {
  __typename?: "Query";
  multiChainMintingProject?: {
    __typename?: "MultiChainMintingProject";
    id: string;
    aboutSectionHeader?: string | null;
    aboutSectionText?: string | null;
    alphaSharesProject: boolean;
    projectName: string;
    collectionDescription?: string | null;
    slug?: string | null;
    featured: boolean;
    maxMintPerTransaction?: number | null;
    mintDate?: any | null;
    mintPrice?: number | null;
    mintedNfTsCarouselHeader?: string | null;
    mintedNfTsCarouselSubHeader?: string | null;
    globalMintContractAbi?: any | null;
    globalMintContractMintFunction?: string | null;
    projectSecurityTags: Array<ProjectSecurityTags>;
    fAQSections: Array<{
      __typename?: "FAQSection";
      heading?: string | null;
      questions: Array<{
        __typename?: "Faq";
        question?: string | null;
        id: string;
        answer?: string | null;
      }>;
    }>;
    securityLineItems: Array<{
      __typename?: "SecurityInfo";
      securityLineItem?: string | null;
      id: string;
    }>;
    aboutSectionAccordions: Array<{
      __typename?: "Faq";
      answer?: string | null;
      id: string;
      question?: string | null;
    }>;
    ctaSection?: {
      __typename?: "CtaSection";
      backgroundFrom?: Colors | null;
      backgroundTo?: Colors | null;
      image?: { __typename?: "Asset"; url: string } | null;
      text: Array<{
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      }>;
      mainButton?: {
        __typename?: "Button";
        buttonLink?: string | null;
        buttonText?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
        id: string;
      } | null;
    } | null;
    mintInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      name?: string | null;
      value?: string | null;
      id: string;
    }>;
    collectionInfoTable: Array<{
      __typename?: "CollectionInfoTable";
      id: string;
      name?: string | null;
      value?: string | null;
    }>;
    collectionSampleImages: Array<{ __typename?: "Asset"; url: string }>;
    mintingStat?: {
      __typename?: "MintingStat";
      heading?: string | null;
      subHeading?: string | null;
      feesInformationText?: string | null;
      inProgressText?: string | null;
      mintedCountText?: string | null;
      backgroundImage?: { __typename?: "Asset"; url: string } | null;
      button?: {
        __typename?: "Button";
        buttonText?: string | null;
        buttonLink?: string | null;
        buttonVariant?: ButtonVariants | null;
        externalLink?: boolean | null;
      } | null;
      flexibleText?: {
        __typename?: "ColoredText";
        id: string;
        tag?: Tags | null;
        text?: string | null;
        textColor?: Colors | null;
      } | null;
      heroImage?: { __typename?: "Asset"; url: string } | null;
      heroImageLeft?: { __typename?: "Asset"; url: string } | null;
    } | null;
    creditCardLinksByChain: Array<{
      __typename?: "MultiChainCreditCardLink";
      creditCardMintingLink?: string | null;
      id: string;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainSlug: string;
      } | null;
    }>;
    mintingContracts: Array<{
      __typename?: "MultiChainMintingContract";
      contractAbi?: any | null;
      contractAddress?: string | null;
      id: string;
      soldOut?: boolean | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainSlug: string;
      } | null;
    }>;
    mintingCurrencies: Array<{
      __typename?: "MintingCurrency";
      decimals?: number | null;
      tokenAddress: string;
      tokenName?: string | null;
      tokenSymbol?: string | null;
      chain?: { __typename?: "Chain"; chainId?: number | null } | null;
      tokenIcon?: { __typename?: "Asset"; url: string } | null;
    }>;
    nftCollections: Array<{
      __typename?: "NftCollection";
      collectionContractAddress: string;
      collectionTotalSupply?: number | null;
      collectionHomePage?: string | null;
      collectionTitle?: string | null;
      collectionTwitterProfile?: string | null;
      collectionCategories: Array<CollectionCategories>;
      collectionRoyaltyFee?: number | null;
      collectionDescription?: string | null;
      collectionDiscordServer?: string | null;
      collectionInstagramProfile?: string | null;
      collectionMediumHome?: string | null;
      collectionListingDate?: any | null;
      tradable: boolean;
      collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
      chain?: {
        __typename?: "Chain";
        chainId?: number | null;
        chainEnum?: ChainIDs | null;
        chainName?: string | null;
        chainSlug: string;
        chainNativeCurrencySymbol?: string | null;
        chainNativeCurrencyIcon?: { __typename?: "Asset"; url: string } | null;
      } | null;
    }>;
  } | null;
};

export type NftCollectionByAddressQueryVariables = Exact<{
  collectionContractAddress: Scalars["String"]["input"];
}>;

export type NftCollectionByAddressQuery = {
  __typename?: "Query";
  nftCollection?: {
    __typename?: "NftCollection";
    currency?: Currencies | null;
    collectionHomePage?: string | null;
    collectionTitle?: string | null;
    collectionTwitterProfile?: string | null;
    collectionCategories: Array<CollectionCategories>;
    collectionRoyaltyFee?: number | null;
    collectionRoyaltyRecipientAddress?: string | null;
    collectionTotalSupply?: number | null;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionDiscordServer?: string | null;
    collectionInstagramProfile?: string | null;
    collectionMediumHome?: string | null;
    collectionListingDate?: any | null;
    chain?: {
      __typename?: "Chain";
      chainId?: number | null;
      chainName?: string | null;
      chainSlug: string;
    } | null;
    marketplaceContract?: {
      __typename?: "MarketplaceContract";
      marketplaceContractAddress?: string | null;
      transactionFee?: number | null;
    } | null;
    collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    collectionFeaturedImage: Array<{ __typename?: "Asset"; url: string }>;
  } | null;
};

export type NftCollectionsByNameSearchQueryVariables = Exact<{
  collectionNameSearch: Scalars["String"]["input"];
  chainId: Scalars["Int"]["input"];
}>;

export type NftCollectionsByNameSearchQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    currency?: Currencies | null;
    collectionHomePage?: string | null;
    collectionTitle?: string | null;
    collectionTwitterProfile?: string | null;
    collectionCategories: Array<CollectionCategories>;
    collectionRoyaltyFee?: number | null;
    collectionTotalSupply?: number | null;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionDiscordServer?: string | null;
    collectionInstagramProfile?: string | null;
    collectionMediumHome?: string | null;
    collectionListingDate?: any | null;
    chain?: {
      __typename?: "Chain";
      chainId?: number | null;
      chainName?: string | null;
    } | null;
    marketplaceContract?: {
      __typename?: "MarketplaceContract";
      marketplaceContractAddress?: string | null;
      transactionFee?: number | null;
    } | null;
    collectionBannerImage?: { __typename?: "Asset"; url: string } | null;
    collectionFeaturedImage: Array<{ __typename?: "Asset"; url: string }>;
  }>;
};

export type GetFooterNavigationQueryVariables = Exact<{
  applicationId?: InputMaybe<AppId>;
}>;

export type GetFooterNavigationQuery = {
  __typename?: "Query";
  menus: Array<{
    __typename?: "Menu";
    id: string;
    menuGroupName?: string | null;
    menuSection: MenuType;
    navigationItems: Array<{
      __typename?: "NavigationItem";
      iconComponent?: HeroMenuIcons | null;
      id: string;
      internalSlug?: string | null;
      navigationItemText?: string | null;
      externalLink?: string | null;
      destinationAppId: AppId;
    }>;
  }>;
};

export type MarketplaceAnnouncementBannerQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type MarketplaceAnnouncementBannerQuery = {
  __typename?: "Query";
  marketplaceAnnouncementBanner?: {
    __typename?: "MarketplaceAnnouncementBanner";
    announcementText?: string | null;
    id: string;
  } | null;
};

export type MarketplaceHomePageQueryVariables = Exact<{
  id?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type MarketplaceHomePageQuery = {
  __typename?: "Query";
  marketplacePage?: {
    __typename?: "MarketplacePage";
    pageTitle?: string | null;
    pageSeoDescription?: string | null;
    pageComponentSections: Array<
      | {
          __typename: "LatestListingsByCollectionAndChainId";
          id: string;
          numberOfItemsOnCarousel?: number | null;
          heading?: string | null;
          subHeading?: string | null;
          chainAndCollectionParams: Array<{
            __typename?: "LatestListingsQuery";
            includeOtherMarketplaces: boolean;
            collectionContractAddress: Array<string>;
            chain?: { __typename?: "Chain"; chainId?: number | null } | null;
          }>;
        }
      | {
          __typename: "MarketplacePageComponent";
          id: string;
          componentHeading?: string | null;
          componentSubHeading?: string | null;
          componentName: ComponentName;
          sideText?: { __typename?: "RichText"; html: string } | null;
          chain?: { __typename?: "Chain"; chainId?: number | null } | null;
        }
      | {
          __typename: "TrendingCollectionsCarousel";
          id: string;
          heading?: string | null;
          numberOfCollectionsPerChain?: number | null;
          subHeading?: string | null;
          chains: Array<{ __typename?: "Chain"; chainId?: number | null }>;
        }
    >;
  } | null;
};

export type GetPupsPresaleCommunitiesQueryVariables = Exact<{
  [key: string]: never;
}>;

export type GetPupsPresaleCommunitiesQuery = {
  __typename?: "Query";
  pupsPresaleCommunities: Array<{
    __typename?: "PupsPresaleCommunity";
    pageUrl: string;
  }>;
};

export type GetPupsPresaleCommunityByPageUrlQueryVariables = Exact<{
  pageUrl?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type GetPupsPresaleCommunityByPageUrlQuery = {
  __typename?: "Query";
  pupsPresaleCommunity?: {
    __typename?: "PupsPresaleCommunity";
    communityName?: string | null;
    pageUrl: string;
    presaleLimit?: number | null;
    totalMinted?: number | null;
  } | null;
};

export type AllCollectionVolumeQueryVariables = Exact<{ [key: string]: never }>;

export type AllCollectionVolumeQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionTitle?: string | null;
    collectionTotalSupply?: number | null;
    currency?: Currencies | null;
    chain?: {
      __typename?: "Chain";
      chainName?: string | null;
      chainEnum?: ChainIDs | null;
      chainId?: number | null;
    } | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export type CollectionVolumeByNetworkQueryVariables = Exact<{
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type CollectionVolumeByNetworkQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    collectionContractAddress: string;
    currency?: Currencies | null;
    collectionDescription?: string | null;
    collectionTitle?: string | null;
    collectionTotalSupply?: number | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
    chain?: { __typename?: "Chain"; chainId?: number | null } | null;
  }>;
};

export type AllCollectionVolumePaginatedQueryVariables = Exact<{
  first?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  chainId?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type AllCollectionVolumePaginatedQuery = {
  __typename?: "Query";
  nftCollections: Array<{
    __typename?: "NftCollection";
    id: string;
    collectionContractAddress: string;
    collectionDescription?: string | null;
    collectionTitle?: string | null;
    collectionTotalSupply?: number | null;
    currency?: Currencies | null;
    chain?: {
      __typename?: "Chain";
      chainName?: string | null;
      chainEnum?: ChainIDs | null;
      chainId?: number | null;
    } | null;
    collectionThumbnail?: { __typename?: "Asset"; url: string } | null;
  }>;
};

export const UpdateTotalMintedPupsPresaleDocument = `
    mutation updateTotalMintedPupsPresale($totalMinted: Int = 1, $pageUrl: String = "") {
  updatePupsPresaleCommunity(
    data: {totalMinted: $totalMinted}
    where: {pageUrl: $pageUrl}
  ) {
    totalMinted
  }
}
    `;
export const useUpdateTotalMintedPupsPresaleMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    UpdateTotalMintedPupsPresaleMutation,
    TError,
    UpdateTotalMintedPupsPresaleMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) =>
  useMutation<
    UpdateTotalMintedPupsPresaleMutation,
    TError,
    UpdateTotalMintedPupsPresaleMutationVariables,
    TContext
  >(
    ["updateTotalMintedPupsPresale"],
    (variables?: UpdateTotalMintedPupsPresaleMutationVariables) =>
      fetcher<
        UpdateTotalMintedPupsPresaleMutation,
        UpdateTotalMintedPupsPresaleMutationVariables
      >(client, UpdateTotalMintedPupsPresaleDocument, variables, headers)(),
    options,
  );
export const PublishPupsPresaleCommunityDocument = `
    mutation publishPupsPresaleCommunity($pageUrl: String = "") {
  publishPupsPresaleCommunity(where: {pageUrl: $pageUrl}) {
    totalMinted
    pageUrl
    presaleLimit
  }
}
    `;
export const usePublishPupsPresaleCommunityMutation = <
  TError = unknown,
  TContext = unknown,
>(
  client: GraphQLClient,
  options?: UseMutationOptions<
    PublishPupsPresaleCommunityMutation,
    TError,
    PublishPupsPresaleCommunityMutationVariables,
    TContext
  >,
  headers?: RequestInit["headers"],
) =>
  useMutation<
    PublishPupsPresaleCommunityMutation,
    TError,
    PublishPupsPresaleCommunityMutationVariables,
    TContext
  >(
    ["publishPupsPresaleCommunity"],
    (variables?: PublishPupsPresaleCommunityMutationVariables) =>
      fetcher<
        PublishPupsPresaleCommunityMutation,
        PublishPupsPresaleCommunityMutationVariables
      >(client, PublishPupsPresaleCommunityDocument, variables, headers)(),
    options,
  );
export const GetAlphaMintingProjectsCarouselDocument = `
    query getAlphaMintingProjectsCarousel {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {alphaSharesProject: true}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    paperCreditCardMintingLink
    maxMintPerTransaction
    mintingContractAddress
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetAlphaMintingProjectsCarouselQuery = <
  TData = GetAlphaMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
  options?: UseQueryOptions<
    GetAlphaMintingProjectsCarouselQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetAlphaMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getAlphaMintingProjectsCarousel"]
      : ["getAlphaMintingProjectsCarousel", variables],
    fetcher<
      GetAlphaMintingProjectsCarouselQuery,
      GetAlphaMintingProjectsCarouselQueryVariables
    >(client, GetAlphaMintingProjectsCarouselDocument, variables, headers),
    options,
  );

useGetAlphaMintingProjectsCarouselQuery.getKey = (
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getAlphaMintingProjectsCarousel"]
    : ["getAlphaMintingProjectsCarousel", variables];
export const useInfiniteGetAlphaMintingProjectsCarouselQuery = <
  TData = GetAlphaMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetAlphaMintingProjectsCarouselQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetAlphaMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getAlphaMintingProjectsCarousel.infinite"]
      : ["getAlphaMintingProjectsCarousel.infinite", variables],
    (metaData) =>
      fetcher<
        GetAlphaMintingProjectsCarouselQuery,
        GetAlphaMintingProjectsCarouselQueryVariables
      >(
        client,
        GetAlphaMintingProjectsCarouselDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetAlphaMintingProjectsCarouselQuery.getKey = (
  variables?: GetAlphaMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getAlphaMintingProjectsCarousel.infinite"]
    : ["getAlphaMintingProjectsCarousel.infinite", variables];
export const BrandAmbassadorCtaDocument = `
    query brandAmbassadorCTA {
  ctaSection(where: {id: "cl83guu3i2f6y0ck2v3loyeg4"}) {
    backgroundFrom
    backgroundTo
    image {
      url
    }
    text {
      ... on ColoredText {
        id
        tag
        text
        textColor
      }
    }
    mainButton {
      buttonLink
      buttonText
      buttonVariant
      externalLink
      id
    }
  }
}
    `;
export const useBrandAmbassadorCtaQuery = <
  TData = BrandAmbassadorCtaQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: BrandAmbassadorCtaQueryVariables,
  options?: UseQueryOptions<BrandAmbassadorCtaQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<BrandAmbassadorCtaQuery, TError, TData>(
    variables === undefined
      ? ["brandAmbassadorCTA"]
      : ["brandAmbassadorCTA", variables],
    fetcher<BrandAmbassadorCtaQuery, BrandAmbassadorCtaQueryVariables>(
      client,
      BrandAmbassadorCtaDocument,
      variables,
      headers,
    ),
    options,
  );

useBrandAmbassadorCtaQuery.getKey = (
  variables?: BrandAmbassadorCtaQueryVariables,
) =>
  variables === undefined
    ? ["brandAmbassadorCTA"]
    : ["brandAmbassadorCTA", variables];
export const useInfiniteBrandAmbassadorCtaQuery = <
  TData = BrandAmbassadorCtaQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: BrandAmbassadorCtaQueryVariables,
  options?: UseInfiniteQueryOptions<BrandAmbassadorCtaQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<BrandAmbassadorCtaQuery, TError, TData>(
    variables === undefined
      ? ["brandAmbassadorCTA.infinite"]
      : ["brandAmbassadorCTA.infinite", variables],
    (metaData) =>
      fetcher<BrandAmbassadorCtaQuery, BrandAmbassadorCtaQueryVariables>(
        client,
        BrandAmbassadorCtaDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteBrandAmbassadorCtaQuery.getKey = (
  variables?: BrandAmbassadorCtaQueryVariables,
) =>
  variables === undefined
    ? ["brandAmbassadorCTA.infinite"]
    : ["brandAmbassadorCTA.infinite", variables];
export const CollectionCategoryListingDocument = `
    query collectionCategoryListing {
  nftCategoryDisplays {
    id
    categoryDisplayTitle
    categoryDescription
    nftCollections {
      id
      chain {
        chainName
        chainEnum
        chainId
        chainSlug
      }
      collectionContractAddress
      collectionDescription
      collectionThumbnail {
        url
      }
      collectionTitle
      collectionTotalSupply
      currency
    }
  }
}
    `;
export const useCollectionCategoryListingQuery = <
  TData = CollectionCategoryListingQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionCategoryListingQueryVariables,
  options?: UseQueryOptions<CollectionCategoryListingQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<CollectionCategoryListingQuery, TError, TData>(
    variables === undefined
      ? ["collectionCategoryListing"]
      : ["collectionCategoryListing", variables],
    fetcher<
      CollectionCategoryListingQuery,
      CollectionCategoryListingQueryVariables
    >(client, CollectionCategoryListingDocument, variables, headers),
    options,
  );

useCollectionCategoryListingQuery.getKey = (
  variables?: CollectionCategoryListingQueryVariables,
) =>
  variables === undefined
    ? ["collectionCategoryListing"]
    : ["collectionCategoryListing", variables];
export const useInfiniteCollectionCategoryListingQuery = <
  TData = CollectionCategoryListingQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionCategoryListingQueryVariables,
  options?: UseInfiniteQueryOptions<
    CollectionCategoryListingQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<CollectionCategoryListingQuery, TError, TData>(
    variables === undefined
      ? ["collectionCategoryListing.infinite"]
      : ["collectionCategoryListing.infinite", variables],
    (metaData) =>
      fetcher<
        CollectionCategoryListingQuery,
        CollectionCategoryListingQueryVariables
      >(
        client,
        CollectionCategoryListingDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteCollectionCategoryListingQuery.getKey = (
  variables?: CollectionCategoryListingQueryVariables,
) =>
  variables === undefined
    ? ["collectionCategoryListing.infinite"]
    : ["collectionCategoryListing.infinite", variables];
export const FeaturedCollectionGroupDocument = `
    query featuredCollectionGroup($id: ID = "clrnu0zd7w0hp0bivbemr9pga") {
  featuredCollectionGroup(where: {id: $id}) {
    nftCollections {
      id
      collectionTitle
      collectionContractAddress
      collectionCategories
      collectionDescription
      collectionTotalSupply
      collectionListingDate
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
      chain {
        chainId
        chainSlug
      }
    }
  }
}
    `;
export const useFeaturedCollectionGroupQuery = <
  TData = FeaturedCollectionGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCollectionGroupQueryVariables,
  options?: UseQueryOptions<FeaturedCollectionGroupQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<FeaturedCollectionGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCollectionGroup"]
      : ["featuredCollectionGroup", variables],
    fetcher<
      FeaturedCollectionGroupQuery,
      FeaturedCollectionGroupQueryVariables
    >(client, FeaturedCollectionGroupDocument, variables, headers),
    options,
  );

useFeaturedCollectionGroupQuery.getKey = (
  variables?: FeaturedCollectionGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCollectionGroup"]
    : ["featuredCollectionGroup", variables];
export const useInfiniteFeaturedCollectionGroupQuery = <
  TData = FeaturedCollectionGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCollectionGroupQueryVariables,
  options?: UseInfiniteQueryOptions<
    FeaturedCollectionGroupQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<FeaturedCollectionGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCollectionGroup.infinite"]
      : ["featuredCollectionGroup.infinite", variables],
    (metaData) =>
      fetcher<
        FeaturedCollectionGroupQuery,
        FeaturedCollectionGroupQueryVariables
      >(
        client,
        FeaturedCollectionGroupDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteFeaturedCollectionGroupQuery.getKey = (
  variables?: FeaturedCollectionGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCollectionGroup.infinite"]
    : ["featuredCollectionGroup.infinite", variables];
export const GetCollectionsDocument = `
    query getCollections {
  nftCollections(where: {tradable: true}, first: 1000) {
    id
    collectionTitle
    collectionContractAddress
    collectionThumbnail {
      url
    }
  }
}
    `;
export const useGetCollectionsQuery = <
  TData = GetCollectionsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsQueryVariables,
  options?: UseQueryOptions<GetCollectionsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetCollectionsQuery, TError, TData>(
    variables === undefined
      ? ["getCollections"]
      : ["getCollections", variables],
    fetcher<GetCollectionsQuery, GetCollectionsQueryVariables>(
      client,
      GetCollectionsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetCollectionsQuery.getKey = (variables?: GetCollectionsQueryVariables) =>
  variables === undefined ? ["getCollections"] : ["getCollections", variables];
export const useInfiniteGetCollectionsQuery = <
  TData = GetCollectionsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsQueryVariables,
  options?: UseInfiniteQueryOptions<GetCollectionsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetCollectionsQuery, TError, TData>(
    variables === undefined
      ? ["getCollections.infinite"]
      : ["getCollections.infinite", variables],
    (metaData) =>
      fetcher<GetCollectionsQuery, GetCollectionsQueryVariables>(
        client,
        GetCollectionsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetCollectionsQuery.getKey = (
  variables?: GetCollectionsQueryVariables,
) =>
  variables === undefined
    ? ["getCollections.infinite"]
    : ["getCollections.infinite", variables];
export const GetCollectionsByCategoryDocument = `
    query getCollectionsByCategory($collectionCategories_contains_all: [CollectionCategories!]) {
  nftCollections(
    where: {collectionCategories_contains_all: $collectionCategories_contains_all, tradable: true}
  ) {
    id
    collectionTitle
    collectionContractAddress
    collectionCategories
    collectionDescription
    collectionThumbnail {
      url
    }
    collectionListingDate
  }
}
    `;
export const useGetCollectionsByCategoryQuery = <
  TData = GetCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsByCategoryQueryVariables,
  options?: UseQueryOptions<GetCollectionsByCategoryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getCollectionsByCategory"]
      : ["getCollectionsByCategory", variables],
    fetcher<
      GetCollectionsByCategoryQuery,
      GetCollectionsByCategoryQueryVariables
    >(client, GetCollectionsByCategoryDocument, variables, headers),
    options,
  );

useGetCollectionsByCategoryQuery.getKey = (
  variables?: GetCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getCollectionsByCategory"]
    : ["getCollectionsByCategory", variables];
export const useInfiniteGetCollectionsByCategoryQuery = <
  TData = GetCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionsByCategoryQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCollectionsByCategoryQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getCollectionsByCategory.infinite"]
      : ["getCollectionsByCategory.infinite", variables],
    (metaData) =>
      fetcher<
        GetCollectionsByCategoryQuery,
        GetCollectionsByCategoryQueryVariables
      >(
        client,
        GetCollectionsByCategoryDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetCollectionsByCategoryQuery.getKey = (
  variables?: GetCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getCollectionsByCategory.infinite"]
    : ["getCollectionsByCategory.infinite", variables];
export const GetHeroCollectionsByCategoryDocument = `
    query getHeroCollectionsByCategory($collectionCategories_contains_all: [CollectionCategories!]) {
  nftCollections(
    where: {collectionCategories_contains_all: $collectionCategories_contains_all}
  ) {
    id
    collectionTitle
    collectionContractAddress
    collectionCategories
    collectionDescription
    collectionTotalSupply
    collectionListingDate
    collectionThumbnail {
      url
    }
    collectionBannerImage {
      url
    }
    chain {
      chainId
      chainSlug
    }
  }
}
    `;
export const useGetHeroCollectionsByCategoryQuery = <
  TData = GetHeroCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetHeroCollectionsByCategoryQueryVariables,
  options?: UseQueryOptions<GetHeroCollectionsByCategoryQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetHeroCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getHeroCollectionsByCategory"]
      : ["getHeroCollectionsByCategory", variables],
    fetcher<
      GetHeroCollectionsByCategoryQuery,
      GetHeroCollectionsByCategoryQueryVariables
    >(client, GetHeroCollectionsByCategoryDocument, variables, headers),
    options,
  );

useGetHeroCollectionsByCategoryQuery.getKey = (
  variables?: GetHeroCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getHeroCollectionsByCategory"]
    : ["getHeroCollectionsByCategory", variables];
export const useInfiniteGetHeroCollectionsByCategoryQuery = <
  TData = GetHeroCollectionsByCategoryQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetHeroCollectionsByCategoryQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetHeroCollectionsByCategoryQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetHeroCollectionsByCategoryQuery, TError, TData>(
    variables === undefined
      ? ["getHeroCollectionsByCategory.infinite"]
      : ["getHeroCollectionsByCategory.infinite", variables],
    (metaData) =>
      fetcher<
        GetHeroCollectionsByCategoryQuery,
        GetHeroCollectionsByCategoryQueryVariables
      >(
        client,
        GetHeroCollectionsByCategoryDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetHeroCollectionsByCategoryQuery.getKey = (
  variables?: GetHeroCollectionsByCategoryQueryVariables,
) =>
  variables === undefined
    ? ["getHeroCollectionsByCategory.infinite"]
    : ["getHeroCollectionsByCategory.infinite", variables];
export const GetCollectionCreatorBySlugDocument = `
    query GetCollectionCreatorBySlug($collectionCreatorSlug: String = "") {
  collectionCreator(where: {collectionCreatorSlug: $collectionCreatorSlug}) {
    collectionCreatorBio
    collectionCreatorName
    collectionCreatorSlug
    collectionCreatorUrl
    collectionCreatorThumbnailImage {
      url
    }
    collectionCreatorBannerImage {
      url
    }
    categories
    nftCollections {
      id
      collectionContractAddress
      collectionCategories
      collectionDescription
      collectionTitle
      collectionBannerImage {
        url
      }
      collectionThumbnail {
        url
      }
      chain {
        chainId
        chainName
        chainEnum
      }
      collectionTotalSupply
      collectionFeaturedImage {
        url
      }
    }
  }
}
    `;
export const useGetCollectionCreatorBySlugQuery = <
  TData = GetCollectionCreatorBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionCreatorBySlugQueryVariables,
  options?: UseQueryOptions<GetCollectionCreatorBySlugQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetCollectionCreatorBySlugQuery, TError, TData>(
    variables === undefined
      ? ["GetCollectionCreatorBySlug"]
      : ["GetCollectionCreatorBySlug", variables],
    fetcher<
      GetCollectionCreatorBySlugQuery,
      GetCollectionCreatorBySlugQueryVariables
    >(client, GetCollectionCreatorBySlugDocument, variables, headers),
    options,
  );

useGetCollectionCreatorBySlugQuery.getKey = (
  variables?: GetCollectionCreatorBySlugQueryVariables,
) =>
  variables === undefined
    ? ["GetCollectionCreatorBySlug"]
    : ["GetCollectionCreatorBySlug", variables];
export const useInfiniteGetCollectionCreatorBySlugQuery = <
  TData = GetCollectionCreatorBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetCollectionCreatorBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetCollectionCreatorBySlugQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetCollectionCreatorBySlugQuery, TError, TData>(
    variables === undefined
      ? ["GetCollectionCreatorBySlug.infinite"]
      : ["GetCollectionCreatorBySlug.infinite", variables],
    (metaData) =>
      fetcher<
        GetCollectionCreatorBySlugQuery,
        GetCollectionCreatorBySlugQueryVariables
      >(
        client,
        GetCollectionCreatorBySlugDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetCollectionCreatorBySlugQuery.getKey = (
  variables?: GetCollectionCreatorBySlugQueryVariables,
) =>
  variables === undefined
    ? ["GetCollectionCreatorBySlug.infinite"]
    : ["GetCollectionCreatorBySlug.infinite", variables];
export const CollectionCreatorsDocument = `
    query CollectionCreators {
  collectionCreators {
    collectionCreatorBio
    collectionCreatorName
    collectionCreatorSlug
    collectionCreatorUrl
    collectionCreatorThumbnailImage {
      url
    }
    collectionCreatorBannerImage {
      url
    }
    categories
  }
}
    `;
export const useCollectionCreatorsQuery = <
  TData = CollectionCreatorsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionCreatorsQueryVariables,
  options?: UseQueryOptions<CollectionCreatorsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<CollectionCreatorsQuery, TError, TData>(
    variables === undefined
      ? ["CollectionCreators"]
      : ["CollectionCreators", variables],
    fetcher<CollectionCreatorsQuery, CollectionCreatorsQueryVariables>(
      client,
      CollectionCreatorsDocument,
      variables,
      headers,
    ),
    options,
  );

useCollectionCreatorsQuery.getKey = (
  variables?: CollectionCreatorsQueryVariables,
) =>
  variables === undefined
    ? ["CollectionCreators"]
    : ["CollectionCreators", variables];
export const useInfiniteCollectionCreatorsQuery = <
  TData = CollectionCreatorsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionCreatorsQueryVariables,
  options?: UseInfiniteQueryOptions<CollectionCreatorsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<CollectionCreatorsQuery, TError, TData>(
    variables === undefined
      ? ["CollectionCreators.infinite"]
      : ["CollectionCreators.infinite", variables],
    (metaData) =>
      fetcher<CollectionCreatorsQuery, CollectionCreatorsQueryVariables>(
        client,
        CollectionCreatorsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteCollectionCreatorsQuery.getKey = (
  variables?: CollectionCreatorsQueryVariables,
) =>
  variables === undefined
    ? ["CollectionCreators.infinite"]
    : ["CollectionCreators.infinite", variables];
export const FeaturedCreatorGroupDocument = `
    query featuredCreatorGroup($id: ID = "clrnt8dugvvn10ait5xxwtrn1") {
  featuredCreatorGroup(where: {id: $id}) {
    collectionCreators {
      collectionCreatorBannerImage {
        url
      }
      categories
      collectionCreatorBio
      collectionCreatorName
      collectionCreatorSlug
      collectionCreatorUrl
      collectionCreatorThumbnailImage {
        url
      }
    }
  }
}
    `;
export const useFeaturedCreatorGroupQuery = <
  TData = FeaturedCreatorGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCreatorGroupQueryVariables,
  options?: UseQueryOptions<FeaturedCreatorGroupQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<FeaturedCreatorGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCreatorGroup"]
      : ["featuredCreatorGroup", variables],
    fetcher<FeaturedCreatorGroupQuery, FeaturedCreatorGroupQueryVariables>(
      client,
      FeaturedCreatorGroupDocument,
      variables,
      headers,
    ),
    options,
  );

useFeaturedCreatorGroupQuery.getKey = (
  variables?: FeaturedCreatorGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCreatorGroup"]
    : ["featuredCreatorGroup", variables];
export const useInfiniteFeaturedCreatorGroupQuery = <
  TData = FeaturedCreatorGroupQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: FeaturedCreatorGroupQueryVariables,
  options?: UseInfiniteQueryOptions<FeaturedCreatorGroupQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<FeaturedCreatorGroupQuery, TError, TData>(
    variables === undefined
      ? ["featuredCreatorGroup.infinite"]
      : ["featuredCreatorGroup.infinite", variables],
    (metaData) =>
      fetcher<FeaturedCreatorGroupQuery, FeaturedCreatorGroupQueryVariables>(
        client,
        FeaturedCreatorGroupDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteFeaturedCreatorGroupQuery.getKey = (
  variables?: FeaturedCreatorGroupQueryVariables,
) =>
  variables === undefined
    ? ["featuredCreatorGroup.infinite"]
    : ["featuredCreatorGroup.infinite", variables];
export const GeneralTextDocument = `
    query GeneralText {
  alphaSharesInformation(where: {id: "cl3vbrqwm7rrd0aiq4n5ry619"}) {
    disclaimer
  }
}
    `;
export const useGeneralTextQuery = <TData = GeneralTextQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: GeneralTextQueryVariables,
  options?: UseQueryOptions<GeneralTextQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GeneralTextQuery, TError, TData>(
    variables === undefined ? ["GeneralText"] : ["GeneralText", variables],
    fetcher<GeneralTextQuery, GeneralTextQueryVariables>(
      client,
      GeneralTextDocument,
      variables,
      headers,
    ),
    options,
  );

useGeneralTextQuery.getKey = (variables?: GeneralTextQueryVariables) =>
  variables === undefined ? ["GeneralText"] : ["GeneralText", variables];
export const useInfiniteGeneralTextQuery = <
  TData = GeneralTextQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GeneralTextQueryVariables,
  options?: UseInfiniteQueryOptions<GeneralTextQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GeneralTextQuery, TError, TData>(
    variables === undefined
      ? ["GeneralText.infinite"]
      : ["GeneralText.infinite", variables],
    (metaData) =>
      fetcher<GeneralTextQuery, GeneralTextQueryVariables>(
        client,
        GeneralTextDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGeneralTextQuery.getKey = (variables?: GeneralTextQueryVariables) =>
  variables === undefined
    ? ["GeneralText.infinite"]
    : ["GeneralText.infinite", variables];
export const GetNftCollectionAddressesDocument = `
    query getNFTCollectionAddresses {
  nftCollections(where: {tradable: true}) {
    collectionContractAddress
  }
}
    `;
export const useGetNftCollectionAddressesQuery = <
  TData = GetNftCollectionAddressesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetNftCollectionAddressesQueryVariables,
  options?: UseQueryOptions<GetNftCollectionAddressesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetNftCollectionAddressesQuery, TError, TData>(
    variables === undefined
      ? ["getNFTCollectionAddresses"]
      : ["getNFTCollectionAddresses", variables],
    fetcher<
      GetNftCollectionAddressesQuery,
      GetNftCollectionAddressesQueryVariables
    >(client, GetNftCollectionAddressesDocument, variables, headers),
    options,
  );

useGetNftCollectionAddressesQuery.getKey = (
  variables?: GetNftCollectionAddressesQueryVariables,
) =>
  variables === undefined
    ? ["getNFTCollectionAddresses"]
    : ["getNFTCollectionAddresses", variables];
export const useInfiniteGetNftCollectionAddressesQuery = <
  TData = GetNftCollectionAddressesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetNftCollectionAddressesQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetNftCollectionAddressesQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetNftCollectionAddressesQuery, TError, TData>(
    variables === undefined
      ? ["getNFTCollectionAddresses.infinite"]
      : ["getNFTCollectionAddresses.infinite", variables],
    (metaData) =>
      fetcher<
        GetNftCollectionAddressesQuery,
        GetNftCollectionAddressesQueryVariables
      >(
        client,
        GetNftCollectionAddressesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetNftCollectionAddressesQuery.getKey = (
  variables?: GetNftCollectionAddressesQueryVariables,
) =>
  variables === undefined
    ? ["getNFTCollectionAddresses.infinite"]
    : ["getNFTCollectionAddresses.infinite", variables];
export const GetSidebarNavigationDocument = `
    query getSidebarNavigation($applicationId: AppId = MarketplaceNext) {
  menus(where: {applicationId: $applicationId}) {
    id
    navigationItems {
      iconComponent
      id
      internalSlug
      navigationItemText
      externalLink
      destinationAppId
    }
    menuGroupName
    menuSection
  }
  menu(where: {id: "clq2n9klmuzsm0ak401uwbbqw"}) {
    id
    menuGroupName
    menuSection
    navigationItems {
      externalLink
      iconComponent
      id
      internalSlug
      navigationItemText
      destinationAppId
    }
  }
}
    `;
export const useGetSidebarNavigationQuery = <
  TData = GetSidebarNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetSidebarNavigationQueryVariables,
  options?: UseQueryOptions<GetSidebarNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetSidebarNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getSidebarNavigation"]
      : ["getSidebarNavigation", variables],
    fetcher<GetSidebarNavigationQuery, GetSidebarNavigationQueryVariables>(
      client,
      GetSidebarNavigationDocument,
      variables,
      headers,
    ),
    options,
  );

useGetSidebarNavigationQuery.getKey = (
  variables?: GetSidebarNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getSidebarNavigation"]
    : ["getSidebarNavigation", variables];
export const useInfiniteGetSidebarNavigationQuery = <
  TData = GetSidebarNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetSidebarNavigationQueryVariables,
  options?: UseInfiniteQueryOptions<GetSidebarNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetSidebarNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getSidebarNavigation.infinite"]
      : ["getSidebarNavigation.infinite", variables],
    (metaData) =>
      fetcher<GetSidebarNavigationQuery, GetSidebarNavigationQueryVariables>(
        client,
        GetSidebarNavigationDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetSidebarNavigationQuery.getKey = (
  variables?: GetSidebarNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getSidebarNavigation.infinite"]
    : ["getSidebarNavigation.infinite", variables];
export const HelpCenterDocument = `
    query HelpCenter {
  page(where: {pageTitle: "Help Center"}) {
    pageTitle
    pageSlug
    fAQSections {
      id
      heading
      questions {
        question
        answer
        id
      }
    }
  }
}
    `;
export const useHelpCenterQuery = <TData = HelpCenterQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: HelpCenterQueryVariables,
  options?: UseQueryOptions<HelpCenterQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<HelpCenterQuery, TError, TData>(
    variables === undefined ? ["HelpCenter"] : ["HelpCenter", variables],
    fetcher<HelpCenterQuery, HelpCenterQueryVariables>(
      client,
      HelpCenterDocument,
      variables,
      headers,
    ),
    options,
  );

useHelpCenterQuery.getKey = (variables?: HelpCenterQueryVariables) =>
  variables === undefined ? ["HelpCenter"] : ["HelpCenter", variables];
export const useInfiniteHelpCenterQuery = <
  TData = HelpCenterQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: HelpCenterQueryVariables,
  options?: UseInfiniteQueryOptions<HelpCenterQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<HelpCenterQuery, TError, TData>(
    variables === undefined
      ? ["HelpCenter.infinite"]
      : ["HelpCenter.infinite", variables],
    (metaData) =>
      fetcher<HelpCenterQuery, HelpCenterQueryVariables>(
        client,
        HelpCenterDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteHelpCenterQuery.getKey = (variables?: HelpCenterQueryVariables) =>
  variables === undefined
    ? ["HelpCenter.infinite"]
    : ["HelpCenter.infinite", variables];
export const HomePageDocument = `
    query homePage {
  page(where: {pageTitle: "Home Page"}) {
    pageTitle
    pageSlug
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    gettingStartedSection {
      heading
      sections {
        cardHeading
        cardText
        id
      }
    }
    pageHero {
      backgroundImage {
        url
        width
      }
      buttons {
        ... on Button {
          id
          buttonLink
          buttonText
          buttonVariant
          externalLink
        }
      }
      flexibleText {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      heroImages {
        url
      }
      mainHeading
      subHeading
      valueProps {
        ... on ValueProposition {
          id
          headingText
          informationText
        }
      }
    }
    infoCardsSection {
      heading
      infoCard {
        id
        cardText
        cardHeading
      }
    }
    mintingStat {
      backgroundImage {
        url
        width
      }
      button {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
      flexibleText {
        textColor
        text
        id
        tag
      }
      heading
      heroImage {
        url
        width
      }
      subHeading
      mintedCountText
      inProgressText
      feesInformationText
    }
    partnerSection {
      heading
      partnerLogos {
        altText
        id
        image {
          url
        }
        link
      }
    }
    perksSection {
      heading
      iconHeaderSubheading {
        icon {
          id
          url
        }
        subHeading
        id
        heading
      }
    }
    poweredBySection {
      buttons {
        ... on Button {
          id
          externalLink
          buttonVariant
          buttonText
          buttonLink
        }
      }
      heading
      information
      logo {
        url
      }
    }
    fAQSection {
      heading
      questions {
        question
        answer
        id
      }
    }
    alphaNftCollectionTier {
      heading
      subHeading
      information
      tiers {
        image {
          url
        }
        id
        tier
        heading
        weightText
        totalAvailableText
        description
      }
    }
    teamSection {
      heading
      teamMembers {
        id
        name
        title
        truncatedBio
        image {
          url
        }
      }
    }
  }
}
    `;
export const useHomePageQuery = <TData = HomePageQuery, TError = unknown>(
  client: GraphQLClient,
  variables?: HomePageQueryVariables,
  options?: UseQueryOptions<HomePageQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<HomePageQuery, TError, TData>(
    variables === undefined ? ["homePage"] : ["homePage", variables],
    fetcher<HomePageQuery, HomePageQueryVariables>(
      client,
      HomePageDocument,
      variables,
      headers,
    ),
    options,
  );

useHomePageQuery.getKey = (variables?: HomePageQueryVariables) =>
  variables === undefined ? ["homePage"] : ["homePage", variables];
export const useInfiniteHomePageQuery = <
  TData = HomePageQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: HomePageQueryVariables,
  options?: UseInfiniteQueryOptions<HomePageQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<HomePageQuery, TError, TData>(
    variables === undefined
      ? ["homePage.infinite"]
      : ["homePage.infinite", variables],
    (metaData) =>
      fetcher<HomePageQuery, HomePageQueryVariables>(
        client,
        HomePageDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteHomePageQuery.getKey = (variables?: HomePageQueryVariables) =>
  variables === undefined
    ? ["homePage.infinite"]
    : ["homePage.infinite", variables];
export const LandingPageBySlugDocument = `
    query LandingPageBySlug($slug: String = "") {
  landingPage(where: {slug: $slug}) {
    pageTitle
    seoPageTitle
    seoDescription
    seoImage {
      url
    }
    logoImage {
      url
    }
    heroBannerImage {
      url
    }
    faqSectionTitle
    faQs {
      answerRichText {
        raw
      }
      question
      id
    }
    heroText {
      raw
    }
    pressSectionTitle
    youtubeCarousel {
      id
      youtubeVideoId
    }
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    ctaSectionBottom {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    textImageRow {
      text {
        raw
      }
      imageSide
      id
      backgroundColor
      backgroundImage {
        url
      }
      image {
        url
      }
    }
  }
}
    `;
export const useLandingPageBySlugQuery = <
  TData = LandingPageBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: LandingPageBySlugQueryVariables,
  options?: UseQueryOptions<LandingPageBySlugQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<LandingPageBySlugQuery, TError, TData>(
    variables === undefined
      ? ["LandingPageBySlug"]
      : ["LandingPageBySlug", variables],
    fetcher<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>(
      client,
      LandingPageBySlugDocument,
      variables,
      headers,
    ),
    options,
  );

useLandingPageBySlugQuery.getKey = (
  variables?: LandingPageBySlugQueryVariables,
) =>
  variables === undefined
    ? ["LandingPageBySlug"]
    : ["LandingPageBySlug", variables];
export const useInfiniteLandingPageBySlugQuery = <
  TData = LandingPageBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: LandingPageBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<LandingPageBySlugQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<LandingPageBySlugQuery, TError, TData>(
    variables === undefined
      ? ["LandingPageBySlug.infinite"]
      : ["LandingPageBySlug.infinite", variables],
    (metaData) =>
      fetcher<LandingPageBySlugQuery, LandingPageBySlugQueryVariables>(
        client,
        LandingPageBySlugDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteLandingPageBySlugQuery.getKey = (
  variables?: LandingPageBySlugQueryVariables,
) =>
  variables === undefined
    ? ["LandingPageBySlug.infinite"]
    : ["LandingPageBySlug.infinite", variables];
export const MetaFuseProjectsWithRevealOnMintDocument = `
    query MetaFuseProjectsWithRevealOnMint {
  mintingProjects(where: {shouldMetafuseRevealOnMint: true}) {
    metafuseProjectId
    shouldMetafuseRevealOnMint
    maxMintPerTransaction
    nftCollection {
      collectionContractAddress
      collectionTotalSupply
      chain {
        chainId
        chainEnum
        chainName
      }
    }
  }
}
    `;
export const useMetaFuseProjectsWithRevealOnMintQuery = <
  TData = MetaFuseProjectsWithRevealOnMintQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
  options?: UseQueryOptions<
    MetaFuseProjectsWithRevealOnMintQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useQuery<MetaFuseProjectsWithRevealOnMintQuery, TError, TData>(
    variables === undefined
      ? ["MetaFuseProjectsWithRevealOnMint"]
      : ["MetaFuseProjectsWithRevealOnMint", variables],
    fetcher<
      MetaFuseProjectsWithRevealOnMintQuery,
      MetaFuseProjectsWithRevealOnMintQueryVariables
    >(client, MetaFuseProjectsWithRevealOnMintDocument, variables, headers),
    options,
  );

useMetaFuseProjectsWithRevealOnMintQuery.getKey = (
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
) =>
  variables === undefined
    ? ["MetaFuseProjectsWithRevealOnMint"]
    : ["MetaFuseProjectsWithRevealOnMint", variables];
export const useInfiniteMetaFuseProjectsWithRevealOnMintQuery = <
  TData = MetaFuseProjectsWithRevealOnMintQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
  options?: UseInfiniteQueryOptions<
    MetaFuseProjectsWithRevealOnMintQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<MetaFuseProjectsWithRevealOnMintQuery, TError, TData>(
    variables === undefined
      ? ["MetaFuseProjectsWithRevealOnMint.infinite"]
      : ["MetaFuseProjectsWithRevealOnMint.infinite", variables],
    (metaData) =>
      fetcher<
        MetaFuseProjectsWithRevealOnMintQuery,
        MetaFuseProjectsWithRevealOnMintQueryVariables
      >(
        client,
        MetaFuseProjectsWithRevealOnMintDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteMetaFuseProjectsWithRevealOnMintQuery.getKey = (
  variables?: MetaFuseProjectsWithRevealOnMintQueryVariables,
) =>
  variables === undefined
    ? ["MetaFuseProjectsWithRevealOnMint.infinite"]
    : ["MetaFuseProjectsWithRevealOnMint.infinite", variables];
export const GetAllMintingProjectsDocument = `
    query getAllMintingProjects {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {featured: true, AND: {soldOut: false}}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    mintingCurrencyInformation {
      tokenIcon {
        url
      }
    }
    soldOut
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAddress
    paperCreditCardMintingLink
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetAllMintingProjectsQuery = <
  TData = GetAllMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAllMintingProjectsQueryVariables,
  options?: UseQueryOptions<GetAllMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetAllMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getAllMintingProjects"]
      : ["getAllMintingProjects", variables],
    fetcher<GetAllMintingProjectsQuery, GetAllMintingProjectsQueryVariables>(
      client,
      GetAllMintingProjectsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetAllMintingProjectsQuery.getKey = (
  variables?: GetAllMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getAllMintingProjects"]
    : ["getAllMintingProjects", variables];
export const useInfiniteGetAllMintingProjectsQuery = <
  TData = GetAllMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetAllMintingProjectsQueryVariables,
  options?: UseInfiniteQueryOptions<GetAllMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetAllMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getAllMintingProjects.infinite"]
      : ["getAllMintingProjects.infinite", variables],
    (metaData) =>
      fetcher<GetAllMintingProjectsQuery, GetAllMintingProjectsQueryVariables>(
        client,
        GetAllMintingProjectsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetAllMintingProjectsQuery.getKey = (
  variables?: GetAllMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getAllMintingProjects.infinite"]
    : ["getAllMintingProjects.infinite", variables];
export const GetMintingProjectByIdDocument = `
    query getMintingProjectById($id: ID!) {
  mintingProject(where: {id: $id}) {
    id
    slug
    presaleHeroSectionCopy
    metafuseProjectId
    shouldMetafuseRevealOnMint
    hasDynamicPricing
    dynamicPricingGetterFunction
    allocation
    paperCreditCardMintingLink
    mintingContractAbi
    presaleCheckerFunction
    priceQueryFunctionName
    presalePriceGetter
    presaleMintingFunctionName
    mintingFunctionName
    mintingCurrencyDecimals
    presaleMaxMintGetter
    publicSaleMaxMintGetter
    priceQueryRequiresAccount
    currency
    mintDate
    soldOut
    fAQSection {
      heading
      questions {
        question
        id
        answer
      }
    }
    securityLineItems {
      securityLineItem
      id
    }
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAbi
    mintingContractAddress
    whitelistChecker
    mintDate
    mintInfoTable {
      name
      value
      id
    }
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    collectionSampleImages {
      url
    }
    collectionCreator {
      collectionCreatorBio
      collectionCreatorName
      collectionCreatorUrl
    }
    nftCollection {
      currency
      tradable
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionBannerImage {
        url
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
    }
    aboutSectionHeader
    aboutSectionText
    aboutSectionAccordions {
      answer
      id
      question
    }
    mintedNfTsCarouselHeader
    mintedNfTsCarouselSubHeader
    mintingStat {
      heading
      subHeading
      backgroundImage {
        url
      }
      button {
        buttonText
        buttonLink
        buttonVariant
        externalLink
      }
      feesInformationText
      flexibleText {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      heroImage {
        url
      }
      heroImageLeft {
        url
      }
      inProgressText
      mintedCountText
    }
  }
}
    `;
export const useGetMintingProjectByIdQuery = <
  TData = GetMintingProjectByIdQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectByIdQueryVariables,
  options?: UseQueryOptions<GetMintingProjectByIdQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectByIdQuery, TError, TData>(
    ["getMintingProjectById", variables],
    fetcher<GetMintingProjectByIdQuery, GetMintingProjectByIdQueryVariables>(
      client,
      GetMintingProjectByIdDocument,
      variables,
      headers,
    ),
    options,
  );

useGetMintingProjectByIdQuery.getKey = (
  variables: GetMintingProjectByIdQueryVariables,
) => ["getMintingProjectById", variables];
export const useInfiniteGetMintingProjectByIdQuery = <
  TData = GetMintingProjectByIdQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectByIdQueryVariables,
  options?: UseInfiniteQueryOptions<GetMintingProjectByIdQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectByIdQuery, TError, TData>(
    ["getMintingProjectById.infinite", variables],
    (metaData) =>
      fetcher<GetMintingProjectByIdQuery, GetMintingProjectByIdQueryVariables>(
        client,
        GetMintingProjectByIdDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectByIdQuery.getKey = (
  variables: GetMintingProjectByIdQueryVariables,
) => ["getMintingProjectById.infinite", variables];
export const GetMintingProjectBySlugDocument = `
    query getMintingProjectBySlug($slug: String!) {
  mintingProject(where: {slug: $slug}) {
    presaleHeroSectionCopy
    metafuseProjectId
    shouldMetafuseRevealOnMint
    hasDynamicPricing
    dynamicPricingGetterFunction
    allocation
    paperCreditCardMintingLink
    mintingContractAbi
    presaleCheckerFunction
    priceQueryFunctionName
    presalePriceGetter
    presaleMintingFunctionName
    mintingFunctionName
    mintingCurrencyDecimals
    presaleMaxMintGetter
    publicSaleMaxMintGetter
    priceQueryRequiresAccount
    currency
    mintDate
    soldOut
    fAQSection {
      heading
      questions {
        question
        id
        answer
      }
    }
    securityLineItems {
      securityLineItem
      id
    }
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAbi
    mintingContractAddress
    whitelistChecker
    mintDate
    mintInfoTable {
      name
      value
      id
    }
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    collectionSampleImages {
      url
    }
    collectionCreator {
      collectionCreatorBio
      collectionCreatorName
      collectionCreatorUrl
    }
    nftCollection {
      currency
      tradable
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionBannerImage {
        url
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
    }
    aboutSectionHeader
    aboutSectionText
    aboutSectionAccordions {
      answer
      id
      question
    }
    mintedNfTsCarouselHeader
    mintedNfTsCarouselSubHeader
    mintingStat {
      heading
      subHeading
      backgroundImage {
        url
      }
      button {
        buttonText
        buttonLink
        buttonVariant
        externalLink
      }
      feesInformationText
      flexibleText {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      heroImage {
        url
      }
      heroImageLeft {
        url
      }
      inProgressText
      mintedCountText
    }
  }
}
    `;
export const useGetMintingProjectBySlugQuery = <
  TData = GetMintingProjectBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectBySlugQueryVariables,
  options?: UseQueryOptions<GetMintingProjectBySlugQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectBySlugQuery, TError, TData>(
    ["getMintingProjectBySlug", variables],
    fetcher<
      GetMintingProjectBySlugQuery,
      GetMintingProjectBySlugQueryVariables
    >(client, GetMintingProjectBySlugDocument, variables, headers),
    options,
  );

useGetMintingProjectBySlugQuery.getKey = (
  variables: GetMintingProjectBySlugQueryVariables,
) => ["getMintingProjectBySlug", variables];
export const useInfiniteGetMintingProjectBySlugQuery = <
  TData = GetMintingProjectBySlugQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: GetMintingProjectBySlugQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetMintingProjectBySlugQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectBySlugQuery, TError, TData>(
    ["getMintingProjectBySlug.infinite", variables],
    (metaData) =>
      fetcher<
        GetMintingProjectBySlugQuery,
        GetMintingProjectBySlugQueryVariables
      >(
        client,
        GetMintingProjectBySlugDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectBySlugQuery.getKey = (
  variables: GetMintingProjectBySlugQueryVariables,
) => ["getMintingProjectBySlug.infinite", variables];
export const GetMintingProjectSlugsDocument = `
    query getMintingProjectSlugs {
  mintingProjects(stage: PUBLISHED) {
    slug
  }
}
    `;
export const useGetMintingProjectSlugsQuery = <
  TData = GetMintingProjectSlugsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectSlugsQueryVariables,
  options?: UseQueryOptions<GetMintingProjectSlugsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectSlugsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectSlugs"]
      : ["getMintingProjectSlugs", variables],
    fetcher<GetMintingProjectSlugsQuery, GetMintingProjectSlugsQueryVariables>(
      client,
      GetMintingProjectSlugsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetMintingProjectSlugsQuery.getKey = (
  variables?: GetMintingProjectSlugsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectSlugs"]
    : ["getMintingProjectSlugs", variables];
export const useInfiniteGetMintingProjectSlugsQuery = <
  TData = GetMintingProjectSlugsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectSlugsQueryVariables,
  options?: UseInfiniteQueryOptions<GetMintingProjectSlugsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectSlugsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectSlugs.infinite"]
      : ["getMintingProjectSlugs.infinite", variables],
    (metaData) =>
      fetcher<
        GetMintingProjectSlugsQuery,
        GetMintingProjectSlugsQueryVariables
      >(
        client,
        GetMintingProjectSlugsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectSlugsQuery.getKey = (
  variables?: GetMintingProjectSlugsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectSlugs.infinite"]
    : ["getMintingProjectSlugs.infinite", variables];
export const GetMintingProjectsDocument = `
    query getMintingProjects($chainEnum: ChainIDs = AVAX) {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {nftCollection: {chain: {chainEnum: $chainEnum}}}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    soldOut
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    maxMintPerTransaction
    mintingContractAddress
    paperCreditCardMintingLink
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetMintingProjectsQuery = <
  TData = GetMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsQueryVariables,
  options?: UseQueryOptions<GetMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjects"]
      : ["getMintingProjects", variables],
    fetcher<GetMintingProjectsQuery, GetMintingProjectsQueryVariables>(
      client,
      GetMintingProjectsDocument,
      variables,
      headers,
    ),
    options,
  );

useGetMintingProjectsQuery.getKey = (
  variables?: GetMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjects"]
    : ["getMintingProjects", variables];
export const useInfiniteGetMintingProjectsQuery = <
  TData = GetMintingProjectsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsQueryVariables,
  options?: UseInfiniteQueryOptions<GetMintingProjectsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectsQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjects.infinite"]
      : ["getMintingProjects.infinite", variables],
    (metaData) =>
      fetcher<GetMintingProjectsQuery, GetMintingProjectsQueryVariables>(
        client,
        GetMintingProjectsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectsQuery.getKey = (
  variables?: GetMintingProjectsQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjects.infinite"]
    : ["getMintingProjects.infinite", variables];
export const GetMintingProjectsCarouselDocument = `
    query getMintingProjectsCarousel {
  mintingProjects(
    stage: PUBLISHED
    orderBy: mintDate_DESC
    where: {alphaSharesProject: false}
  ) {
    id
    slug
    currency
    featured
    securityLineItems {
      securityLineItem
      id
    }
    mintingStat {
      id
    }
    projectSecurityTags
    mintingCurrency
    mintingCurrencyContractAddress
    paperCreditCardMintingLink
    maxMintPerTransaction
    mintingContractAddress
    mintDate
    mintPrice
    projectName
    collectionInfoTable {
      id
      name
      value
    }
    nftCollection {
      tradable
      currency
      chain {
        chainId
        chainName
        chainEnum
        chainSlug
      }
      collectionCategories
      collectionContractAddress
      collectionDescription
      collectionDiscordServer
      collectionHomePage
      collectionInstagramProfile
      collectionMediumHome
      collectionTitle
      collectionTotalSupply
      collectionTwitterProfile
      collectionThumbnail {
        url
      }
      collectionBannerImage {
        url
      }
    }
  }
}
    `;
export const useGetMintingProjectsCarouselQuery = <
  TData = GetMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsCarouselQueryVariables,
  options?: UseQueryOptions<GetMintingProjectsCarouselQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectsCarousel"]
      : ["getMintingProjectsCarousel", variables],
    fetcher<
      GetMintingProjectsCarouselQuery,
      GetMintingProjectsCarouselQueryVariables
    >(client, GetMintingProjectsCarouselDocument, variables, headers),
    options,
  );

useGetMintingProjectsCarouselQuery.getKey = (
  variables?: GetMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectsCarousel"]
    : ["getMintingProjectsCarousel", variables];
export const useInfiniteGetMintingProjectsCarouselQuery = <
  TData = GetMintingProjectsCarouselQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetMintingProjectsCarouselQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetMintingProjectsCarouselQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetMintingProjectsCarouselQuery, TError, TData>(
    variables === undefined
      ? ["getMintingProjectsCarousel.infinite"]
      : ["getMintingProjectsCarousel.infinite", variables],
    (metaData) =>
      fetcher<
        GetMintingProjectsCarouselQuery,
        GetMintingProjectsCarouselQueryVariables
      >(
        client,
        GetMintingProjectsCarouselDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetMintingProjectsCarouselQuery.getKey = (
  variables?: GetMintingProjectsCarouselQueryVariables,
) =>
  variables === undefined
    ? ["getMintingProjectsCarousel.infinite"]
    : ["getMintingProjectsCarousel.infinite", variables];
export const MultiChainMintingProjectSlugsDocument = `
    query multiChainMintingProjectSlugs {
  multiChainMintingProjects {
    slug
  }
}
    `;
export const useMultiChainMintingProjectSlugsQuery = <
  TData = MultiChainMintingProjectSlugsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MultiChainMintingProjectSlugsQueryVariables,
  options?: UseQueryOptions<MultiChainMintingProjectSlugsQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<MultiChainMintingProjectSlugsQuery, TError, TData>(
    variables === undefined
      ? ["multiChainMintingProjectSlugs"]
      : ["multiChainMintingProjectSlugs", variables],
    fetcher<
      MultiChainMintingProjectSlugsQuery,
      MultiChainMintingProjectSlugsQueryVariables
    >(client, MultiChainMintingProjectSlugsDocument, variables, headers),
    options,
  );

useMultiChainMintingProjectSlugsQuery.getKey = (
  variables?: MultiChainMintingProjectSlugsQueryVariables,
) =>
  variables === undefined
    ? ["multiChainMintingProjectSlugs"]
    : ["multiChainMintingProjectSlugs", variables];
export const useInfiniteMultiChainMintingProjectSlugsQuery = <
  TData = MultiChainMintingProjectSlugsQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MultiChainMintingProjectSlugsQueryVariables,
  options?: UseInfiniteQueryOptions<
    MultiChainMintingProjectSlugsQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<MultiChainMintingProjectSlugsQuery, TError, TData>(
    variables === undefined
      ? ["multiChainMintingProjectSlugs.infinite"]
      : ["multiChainMintingProjectSlugs.infinite", variables],
    (metaData) =>
      fetcher<
        MultiChainMintingProjectSlugsQuery,
        MultiChainMintingProjectSlugsQueryVariables
      >(
        client,
        MultiChainMintingProjectSlugsDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteMultiChainMintingProjectSlugsQuery.getKey = (
  variables?: MultiChainMintingProjectSlugsQueryVariables,
) =>
  variables === undefined
    ? ["multiChainMintingProjectSlugs.infinite"]
    : ["multiChainMintingProjectSlugs.infinite", variables];
export const MultiChainMintingProjectDocument = `
    query multiChainMintingProject($slug: String = "test-multi") {
  multiChainMintingProject(where: {slug: $slug}) {
    id
    aboutSectionHeader
    aboutSectionText
    alphaSharesProject
    projectName
    collectionDescription
    slug
    featured
    maxMintPerTransaction
    mintDate
    mintPrice
    mintedNfTsCarouselHeader
    mintedNfTsCarouselSubHeader
    globalMintContractAbi
    globalMintContractMintFunction
    fAQSections {
      heading
      questions {
        question
        id
        answer
      }
    }
    securityLineItems {
      securityLineItem
      id
    }
    aboutSectionAccordions {
      answer
      id
      question
    }
    ctaSection {
      backgroundFrom
      backgroundTo
      image {
        url
      }
      text {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      mainButton {
        buttonLink
        buttonText
        buttonVariant
        externalLink
        id
      }
    }
    projectSecurityTags
    mintInfoTable {
      name
      value
      id
    }
    collectionInfoTable {
      id
      name
      value
    }
    collectionSampleImages {
      url
    }
    mintingStat {
      heading
      subHeading
      backgroundImage {
        url
      }
      button {
        buttonText
        buttonLink
        buttonVariant
        externalLink
      }
      feesInformationText
      flexibleText {
        ... on ColoredText {
          id
          tag
          text
          textColor
        }
      }
      heroImage {
        url
      }
      heroImageLeft {
        url
      }
      inProgressText
      mintedCountText
    }
    creditCardLinksByChain {
      creditCardMintingLink
      id
      chain {
        chainId
        chainSlug
      }
    }
    mintingContracts {
      contractAbi
      contractAddress
      id
      chain {
        chainId
        chainSlug
      }
      soldOut
    }
    mintingCurrencies {
      chain {
        chainId
      }
      decimals
      tokenAddress
      tokenName
      tokenSymbol
      tokenIcon {
        url
      }
    }
    nftCollections {
      collectionContractAddress
      collectionTotalSupply
      collectionHomePage
      collectionTitle
      collectionTwitterProfile
      collectionCategories
      collectionRoyaltyFee
      collectionTotalSupply
      collectionDescription
      collectionDiscordServer
      collectionInstagramProfile
      collectionMediumHome
      collectionListingDate
      collectionThumbnail {
        url
      }
      chain {
        chainId
        chainEnum
        chainName
        chainSlug
        chainNativeCurrencySymbol
        chainNativeCurrencyIcon {
          url
        }
      }
      tradable
    }
  }
}
    `;
export const useMultiChainMintingProjectQuery = <
  TData = MultiChainMintingProjectQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MultiChainMintingProjectQueryVariables,
  options?: UseQueryOptions<MultiChainMintingProjectQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<MultiChainMintingProjectQuery, TError, TData>(
    variables === undefined
      ? ["multiChainMintingProject"]
      : ["multiChainMintingProject", variables],
    fetcher<
      MultiChainMintingProjectQuery,
      MultiChainMintingProjectQueryVariables
    >(client, MultiChainMintingProjectDocument, variables, headers),
    options,
  );

useMultiChainMintingProjectQuery.getKey = (
  variables?: MultiChainMintingProjectQueryVariables,
) =>
  variables === undefined
    ? ["multiChainMintingProject"]
    : ["multiChainMintingProject", variables];
export const useInfiniteMultiChainMintingProjectQuery = <
  TData = MultiChainMintingProjectQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MultiChainMintingProjectQueryVariables,
  options?: UseInfiniteQueryOptions<
    MultiChainMintingProjectQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<MultiChainMintingProjectQuery, TError, TData>(
    variables === undefined
      ? ["multiChainMintingProject.infinite"]
      : ["multiChainMintingProject.infinite", variables],
    (metaData) =>
      fetcher<
        MultiChainMintingProjectQuery,
        MultiChainMintingProjectQueryVariables
      >(
        client,
        MultiChainMintingProjectDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteMultiChainMintingProjectQuery.getKey = (
  variables?: MultiChainMintingProjectQueryVariables,
) =>
  variables === undefined
    ? ["multiChainMintingProject.infinite"]
    : ["multiChainMintingProject.infinite", variables];
export const NftCollectionByAddressDocument = `
    query nftCollectionByAddress($collectionContractAddress: String!) {
  nftCollection(where: {collectionContractAddress: $collectionContractAddress}) {
    currency
    collectionHomePage
    collectionTitle
    collectionTwitterProfile
    collectionCategories
    collectionRoyaltyFee
    collectionRoyaltyRecipientAddress
    collectionTotalSupply
    collectionContractAddress
    collectionDescription
    collectionDiscordServer
    collectionInstagramProfile
    collectionMediumHome
    collectionListingDate
    chain {
      chainId
      chainName
      chainSlug
    }
    marketplaceContract {
      marketplaceContractAddress
      transactionFee
    }
    collectionBannerImage {
      url
    }
    collectionThumbnail {
      url
    }
    collectionFeaturedImage {
      url
    }
  }
}
    `;
export const useNftCollectionByAddressQuery = <
  TData = NftCollectionByAddressQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionByAddressQueryVariables,
  options?: UseQueryOptions<NftCollectionByAddressQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<NftCollectionByAddressQuery, TError, TData>(
    ["nftCollectionByAddress", variables],
    fetcher<NftCollectionByAddressQuery, NftCollectionByAddressQueryVariables>(
      client,
      NftCollectionByAddressDocument,
      variables,
      headers,
    ),
    options,
  );

useNftCollectionByAddressQuery.getKey = (
  variables: NftCollectionByAddressQueryVariables,
) => ["nftCollectionByAddress", variables];
export const useInfiniteNftCollectionByAddressQuery = <
  TData = NftCollectionByAddressQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionByAddressQueryVariables,
  options?: UseInfiniteQueryOptions<NftCollectionByAddressQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<NftCollectionByAddressQuery, TError, TData>(
    ["nftCollectionByAddress.infinite", variables],
    (metaData) =>
      fetcher<
        NftCollectionByAddressQuery,
        NftCollectionByAddressQueryVariables
      >(
        client,
        NftCollectionByAddressDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteNftCollectionByAddressQuery.getKey = (
  variables: NftCollectionByAddressQueryVariables,
) => ["nftCollectionByAddress.infinite", variables];
export const NftCollectionsByNameSearchDocument = `
    query nftCollectionsByNameSearch($collectionNameSearch: String!, $chainId: Int!) {
  nftCollections(
    where: {collectionTitle_contains: $collectionNameSearch, chain: {AND: {chainId: $chainId}}}
  ) {
    currency
    collectionHomePage
    collectionTitle
    collectionTwitterProfile
    collectionCategories
    collectionRoyaltyFee
    collectionTotalSupply
    collectionContractAddress
    collectionDescription
    collectionDiscordServer
    collectionInstagramProfile
    collectionMediumHome
    collectionListingDate
    chain {
      chainId
      chainName
    }
    marketplaceContract {
      marketplaceContractAddress
      transactionFee
    }
    collectionBannerImage {
      url
    }
    collectionFeaturedImage {
      url
    }
  }
}
    `;
export const useNftCollectionsByNameSearchQuery = <
  TData = NftCollectionsByNameSearchQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionsByNameSearchQueryVariables,
  options?: UseQueryOptions<NftCollectionsByNameSearchQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<NftCollectionsByNameSearchQuery, TError, TData>(
    ["nftCollectionsByNameSearch", variables],
    fetcher<
      NftCollectionsByNameSearchQuery,
      NftCollectionsByNameSearchQueryVariables
    >(client, NftCollectionsByNameSearchDocument, variables, headers),
    options,
  );

useNftCollectionsByNameSearchQuery.getKey = (
  variables: NftCollectionsByNameSearchQueryVariables,
) => ["nftCollectionsByNameSearch", variables];
export const useInfiniteNftCollectionsByNameSearchQuery = <
  TData = NftCollectionsByNameSearchQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables: NftCollectionsByNameSearchQueryVariables,
  options?: UseInfiniteQueryOptions<
    NftCollectionsByNameSearchQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<NftCollectionsByNameSearchQuery, TError, TData>(
    ["nftCollectionsByNameSearch.infinite", variables],
    (metaData) =>
      fetcher<
        NftCollectionsByNameSearchQuery,
        NftCollectionsByNameSearchQueryVariables
      >(
        client,
        NftCollectionsByNameSearchDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteNftCollectionsByNameSearchQuery.getKey = (
  variables: NftCollectionsByNameSearchQueryVariables,
) => ["nftCollectionsByNameSearch.infinite", variables];
export const GetFooterNavigationDocument = `
    query getFooterNavigation($applicationId: AppId = Global) {
  menus(where: {applicationId: $applicationId}) {
    id
    navigationItems {
      iconComponent
      id
      internalSlug
      navigationItemText
      externalLink
      destinationAppId
    }
    menuGroupName
    menuSection
  }
}
    `;
export const useGetFooterNavigationQuery = <
  TData = GetFooterNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetFooterNavigationQueryVariables,
  options?: UseQueryOptions<GetFooterNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetFooterNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getFooterNavigation"]
      : ["getFooterNavigation", variables],
    fetcher<GetFooterNavigationQuery, GetFooterNavigationQueryVariables>(
      client,
      GetFooterNavigationDocument,
      variables,
      headers,
    ),
    options,
  );

useGetFooterNavigationQuery.getKey = (
  variables?: GetFooterNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getFooterNavigation"]
    : ["getFooterNavigation", variables];
export const useInfiniteGetFooterNavigationQuery = <
  TData = GetFooterNavigationQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetFooterNavigationQueryVariables,
  options?: UseInfiniteQueryOptions<GetFooterNavigationQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetFooterNavigationQuery, TError, TData>(
    variables === undefined
      ? ["getFooterNavigation.infinite"]
      : ["getFooterNavigation.infinite", variables],
    (metaData) =>
      fetcher<GetFooterNavigationQuery, GetFooterNavigationQueryVariables>(
        client,
        GetFooterNavigationDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetFooterNavigationQuery.getKey = (
  variables?: GetFooterNavigationQueryVariables,
) =>
  variables === undefined
    ? ["getFooterNavigation.infinite"]
    : ["getFooterNavigation.infinite", variables];
export const MarketplaceAnnouncementBannerDocument = `
    query marketplaceAnnouncementBanner($id: ID = "clshm53axixpu0aiu00hshfal") {
  marketplaceAnnouncementBanner(where: {id: $id}) {
    announcementText
    id
  }
}
    `;
export const useMarketplaceAnnouncementBannerQuery = <
  TData = MarketplaceAnnouncementBannerQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MarketplaceAnnouncementBannerQueryVariables,
  options?: UseQueryOptions<MarketplaceAnnouncementBannerQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<MarketplaceAnnouncementBannerQuery, TError, TData>(
    variables === undefined
      ? ["marketplaceAnnouncementBanner"]
      : ["marketplaceAnnouncementBanner", variables],
    fetcher<
      MarketplaceAnnouncementBannerQuery,
      MarketplaceAnnouncementBannerQueryVariables
    >(client, MarketplaceAnnouncementBannerDocument, variables, headers),
    options,
  );

useMarketplaceAnnouncementBannerQuery.getKey = (
  variables?: MarketplaceAnnouncementBannerQueryVariables,
) =>
  variables === undefined
    ? ["marketplaceAnnouncementBanner"]
    : ["marketplaceAnnouncementBanner", variables];
export const useInfiniteMarketplaceAnnouncementBannerQuery = <
  TData = MarketplaceAnnouncementBannerQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MarketplaceAnnouncementBannerQueryVariables,
  options?: UseInfiniteQueryOptions<
    MarketplaceAnnouncementBannerQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<MarketplaceAnnouncementBannerQuery, TError, TData>(
    variables === undefined
      ? ["marketplaceAnnouncementBanner.infinite"]
      : ["marketplaceAnnouncementBanner.infinite", variables],
    (metaData) =>
      fetcher<
        MarketplaceAnnouncementBannerQuery,
        MarketplaceAnnouncementBannerQueryVariables
      >(
        client,
        MarketplaceAnnouncementBannerDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteMarketplaceAnnouncementBannerQuery.getKey = (
  variables?: MarketplaceAnnouncementBannerQueryVariables,
) =>
  variables === undefined
    ? ["marketplaceAnnouncementBanner.infinite"]
    : ["marketplaceAnnouncementBanner.infinite", variables];
export const MarketplaceHomePageDocument = `
    query marketplaceHomePage($id: ID = "clqn4hduhmyjb0bk75drzgr78") {
  marketplacePage(where: {id: $id}) {
    pageTitle
    pageSeoDescription
    pageComponentSections {
      ... on LatestListingsByCollectionAndChainId {
        __typename
        id
        chainAndCollectionParams {
          includeOtherMarketplaces
          collectionContractAddress
          chain {
            chainId
          }
        }
        numberOfItemsOnCarousel
        heading
        subHeading
      }
      ... on MarketplacePageComponent {
        __typename
        id
        componentHeading
        componentSubHeading
        componentName
        sideText {
          html
        }
        chain {
          chainId
        }
      }
      ... on TrendingCollectionsCarousel {
        __typename
        id
        heading
        numberOfCollectionsPerChain
        subHeading
        chains {
          chainId
        }
      }
    }
  }
}
    `;
export const useMarketplaceHomePageQuery = <
  TData = MarketplaceHomePageQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MarketplaceHomePageQueryVariables,
  options?: UseQueryOptions<MarketplaceHomePageQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<MarketplaceHomePageQuery, TError, TData>(
    variables === undefined
      ? ["marketplaceHomePage"]
      : ["marketplaceHomePage", variables],
    fetcher<MarketplaceHomePageQuery, MarketplaceHomePageQueryVariables>(
      client,
      MarketplaceHomePageDocument,
      variables,
      headers,
    ),
    options,
  );

useMarketplaceHomePageQuery.getKey = (
  variables?: MarketplaceHomePageQueryVariables,
) =>
  variables === undefined
    ? ["marketplaceHomePage"]
    : ["marketplaceHomePage", variables];
export const useInfiniteMarketplaceHomePageQuery = <
  TData = MarketplaceHomePageQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: MarketplaceHomePageQueryVariables,
  options?: UseInfiniteQueryOptions<MarketplaceHomePageQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<MarketplaceHomePageQuery, TError, TData>(
    variables === undefined
      ? ["marketplaceHomePage.infinite"]
      : ["marketplaceHomePage.infinite", variables],
    (metaData) =>
      fetcher<MarketplaceHomePageQuery, MarketplaceHomePageQueryVariables>(
        client,
        MarketplaceHomePageDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteMarketplaceHomePageQuery.getKey = (
  variables?: MarketplaceHomePageQueryVariables,
) =>
  variables === undefined
    ? ["marketplaceHomePage.infinite"]
    : ["marketplaceHomePage.infinite", variables];
export const GetPupsPresaleCommunitiesDocument = `
    query getPupsPresaleCommunities {
  pupsPresaleCommunities {
    pageUrl
  }
}
    `;
export const useGetPupsPresaleCommunitiesQuery = <
  TData = GetPupsPresaleCommunitiesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetPupsPresaleCommunitiesQueryVariables,
  options?: UseQueryOptions<GetPupsPresaleCommunitiesQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetPupsPresaleCommunitiesQuery, TError, TData>(
    variables === undefined
      ? ["getPupsPresaleCommunities"]
      : ["getPupsPresaleCommunities", variables],
    fetcher<
      GetPupsPresaleCommunitiesQuery,
      GetPupsPresaleCommunitiesQueryVariables
    >(client, GetPupsPresaleCommunitiesDocument, variables, headers),
    options,
  );

useGetPupsPresaleCommunitiesQuery.getKey = (
  variables?: GetPupsPresaleCommunitiesQueryVariables,
) =>
  variables === undefined
    ? ["getPupsPresaleCommunities"]
    : ["getPupsPresaleCommunities", variables];
export const useInfiniteGetPupsPresaleCommunitiesQuery = <
  TData = GetPupsPresaleCommunitiesQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetPupsPresaleCommunitiesQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetPupsPresaleCommunitiesQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetPupsPresaleCommunitiesQuery, TError, TData>(
    variables === undefined
      ? ["getPupsPresaleCommunities.infinite"]
      : ["getPupsPresaleCommunities.infinite", variables],
    (metaData) =>
      fetcher<
        GetPupsPresaleCommunitiesQuery,
        GetPupsPresaleCommunitiesQueryVariables
      >(
        client,
        GetPupsPresaleCommunitiesDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetPupsPresaleCommunitiesQuery.getKey = (
  variables?: GetPupsPresaleCommunitiesQueryVariables,
) =>
  variables === undefined
    ? ["getPupsPresaleCommunities.infinite"]
    : ["getPupsPresaleCommunities.infinite", variables];
export const GetPupsPresaleCommunityByPageUrlDocument = `
    query getPupsPresaleCommunityByPageUrl($pageUrl: String = "") {
  pupsPresaleCommunity(where: {pageUrl: $pageUrl}) {
    communityName
    pageUrl
    presaleLimit
    totalMinted
  }
}
    `;
export const useGetPupsPresaleCommunityByPageUrlQuery = <
  TData = GetPupsPresaleCommunityByPageUrlQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetPupsPresaleCommunityByPageUrlQueryVariables,
  options?: UseQueryOptions<
    GetPupsPresaleCommunityByPageUrlQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useQuery<GetPupsPresaleCommunityByPageUrlQuery, TError, TData>(
    variables === undefined
      ? ["getPupsPresaleCommunityByPageUrl"]
      : ["getPupsPresaleCommunityByPageUrl", variables],
    fetcher<
      GetPupsPresaleCommunityByPageUrlQuery,
      GetPupsPresaleCommunityByPageUrlQueryVariables
    >(client, GetPupsPresaleCommunityByPageUrlDocument, variables, headers),
    options,
  );

useGetPupsPresaleCommunityByPageUrlQuery.getKey = (
  variables?: GetPupsPresaleCommunityByPageUrlQueryVariables,
) =>
  variables === undefined
    ? ["getPupsPresaleCommunityByPageUrl"]
    : ["getPupsPresaleCommunityByPageUrl", variables];
export const useInfiniteGetPupsPresaleCommunityByPageUrlQuery = <
  TData = GetPupsPresaleCommunityByPageUrlQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: GetPupsPresaleCommunityByPageUrlQueryVariables,
  options?: UseInfiniteQueryOptions<
    GetPupsPresaleCommunityByPageUrlQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<GetPupsPresaleCommunityByPageUrlQuery, TError, TData>(
    variables === undefined
      ? ["getPupsPresaleCommunityByPageUrl.infinite"]
      : ["getPupsPresaleCommunityByPageUrl.infinite", variables],
    (metaData) =>
      fetcher<
        GetPupsPresaleCommunityByPageUrlQuery,
        GetPupsPresaleCommunityByPageUrlQueryVariables
      >(
        client,
        GetPupsPresaleCommunityByPageUrlDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteGetPupsPresaleCommunityByPageUrlQuery.getKey = (
  variables?: GetPupsPresaleCommunityByPageUrlQueryVariables,
) =>
  variables === undefined
    ? ["getPupsPresaleCommunityByPageUrl.infinite"]
    : ["getPupsPresaleCommunityByPageUrl.infinite", variables];
export const AllCollectionVolumeDocument = `
    query allCollectionVolume {
  nftCollections(where: {tradable: true}, first: 1000) {
    id
    chain {
      chainName
      chainEnum
      chainId
    }
    collectionContractAddress
    collectionDescription
    collectionThumbnail {
      url
    }
    collectionTitle
    collectionTotalSupply
    currency
  }
}
    `;
export const useAllCollectionVolumeQuery = <
  TData = AllCollectionVolumeQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumeQueryVariables,
  options?: UseQueryOptions<AllCollectionVolumeQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<AllCollectionVolumeQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolume"]
      : ["allCollectionVolume", variables],
    fetcher<AllCollectionVolumeQuery, AllCollectionVolumeQueryVariables>(
      client,
      AllCollectionVolumeDocument,
      variables,
      headers,
    ),
    options,
  );

useAllCollectionVolumeQuery.getKey = (
  variables?: AllCollectionVolumeQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolume"]
    : ["allCollectionVolume", variables];
export const useInfiniteAllCollectionVolumeQuery = <
  TData = AllCollectionVolumeQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumeQueryVariables,
  options?: UseInfiniteQueryOptions<AllCollectionVolumeQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<AllCollectionVolumeQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolume.infinite"]
      : ["allCollectionVolume.infinite", variables],
    (metaData) =>
      fetcher<AllCollectionVolumeQuery, AllCollectionVolumeQueryVariables>(
        client,
        AllCollectionVolumeDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteAllCollectionVolumeQuery.getKey = (
  variables?: AllCollectionVolumeQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolume.infinite"]
    : ["allCollectionVolume.infinite", variables];
export const CollectionVolumeByNetworkDocument = `
    query collectionVolumeByNetwork($chainId: Int) {
  nftCollections(where: {chain: {chainId: $chainId}}) {
    collectionContractAddress
    collectionThumbnail {
      url
    }
    currency
    chain {
      chainId
    }
    collectionDescription
    collectionTitle
    collectionTotalSupply
  }
}
    `;
export const useCollectionVolumeByNetworkQuery = <
  TData = CollectionVolumeByNetworkQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionVolumeByNetworkQueryVariables,
  options?: UseQueryOptions<CollectionVolumeByNetworkQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<CollectionVolumeByNetworkQuery, TError, TData>(
    variables === undefined
      ? ["collectionVolumeByNetwork"]
      : ["collectionVolumeByNetwork", variables],
    fetcher<
      CollectionVolumeByNetworkQuery,
      CollectionVolumeByNetworkQueryVariables
    >(client, CollectionVolumeByNetworkDocument, variables, headers),
    options,
  );

useCollectionVolumeByNetworkQuery.getKey = (
  variables?: CollectionVolumeByNetworkQueryVariables,
) =>
  variables === undefined
    ? ["collectionVolumeByNetwork"]
    : ["collectionVolumeByNetwork", variables];
export const useInfiniteCollectionVolumeByNetworkQuery = <
  TData = CollectionVolumeByNetworkQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: CollectionVolumeByNetworkQueryVariables,
  options?: UseInfiniteQueryOptions<
    CollectionVolumeByNetworkQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<CollectionVolumeByNetworkQuery, TError, TData>(
    variables === undefined
      ? ["collectionVolumeByNetwork.infinite"]
      : ["collectionVolumeByNetwork.infinite", variables],
    (metaData) =>
      fetcher<
        CollectionVolumeByNetworkQuery,
        CollectionVolumeByNetworkQueryVariables
      >(
        client,
        CollectionVolumeByNetworkDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteCollectionVolumeByNetworkQuery.getKey = (
  variables?: CollectionVolumeByNetworkQueryVariables,
) =>
  variables === undefined
    ? ["collectionVolumeByNetwork.infinite"]
    : ["collectionVolumeByNetwork.infinite", variables];
export const AllCollectionVolumePaginatedDocument = `
    query allCollectionVolumePaginated($first: Int, $skip: Int, $chainId: Int) {
  nftCollections(
    where: {tradable: true, AND: {chain: {chainId: $chainId}}}
    first: $first
    skip: $skip
  ) {
    id
    chain {
      chainName
      chainEnum
      chainId
    }
    collectionContractAddress
    collectionDescription
    collectionThumbnail {
      url
    }
    collectionTitle
    collectionTotalSupply
    currency
  }
}
    `;
export const useAllCollectionVolumePaginatedQuery = <
  TData = AllCollectionVolumePaginatedQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumePaginatedQueryVariables,
  options?: UseQueryOptions<AllCollectionVolumePaginatedQuery, TError, TData>,
  headers?: RequestInit["headers"],
) =>
  useQuery<AllCollectionVolumePaginatedQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolumePaginated"]
      : ["allCollectionVolumePaginated", variables],
    fetcher<
      AllCollectionVolumePaginatedQuery,
      AllCollectionVolumePaginatedQueryVariables
    >(client, AllCollectionVolumePaginatedDocument, variables, headers),
    options,
  );

useAllCollectionVolumePaginatedQuery.getKey = (
  variables?: AllCollectionVolumePaginatedQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolumePaginated"]
    : ["allCollectionVolumePaginated", variables];
export const useInfiniteAllCollectionVolumePaginatedQuery = <
  TData = AllCollectionVolumePaginatedQuery,
  TError = unknown,
>(
  client: GraphQLClient,
  variables?: AllCollectionVolumePaginatedQueryVariables,
  options?: UseInfiniteQueryOptions<
    AllCollectionVolumePaginatedQuery,
    TError,
    TData
  >,
  headers?: RequestInit["headers"],
) =>
  useInfiniteQuery<AllCollectionVolumePaginatedQuery, TError, TData>(
    variables === undefined
      ? ["allCollectionVolumePaginated.infinite"]
      : ["allCollectionVolumePaginated.infinite", variables],
    (metaData) =>
      fetcher<
        AllCollectionVolumePaginatedQuery,
        AllCollectionVolumePaginatedQueryVariables
      >(
        client,
        AllCollectionVolumePaginatedDocument,
        { ...variables, ...(metaData.pageParam ?? {}) },
        headers,
      )(),
    options,
  );

useInfiniteAllCollectionVolumePaginatedQuery.getKey = (
  variables?: AllCollectionVolumePaginatedQueryVariables,
) =>
  variables === undefined
    ? ["allCollectionVolumePaginated.infinite"]
    : ["allCollectionVolumePaginated.infinite", variables];
