import React from 'react'
import Link from 'next/link'
import {
  Typography, MainLogoIcon, MainLogoText, ConnectButtonRainbowkit, AlphaWolfIcon, AlphaDex, AlphaMeteor, AlphaWallet
} from '../../index';

import { useAccount } from 'wagmi'
import { useRouter } from 'next/router'
import { MenuIcon, XIcon } from '@heroicons/react/outline';

import { SOCIALS } from '@alpha-shares/config'

import { useQueryClient } from '@tanstack/react-query';
import { graphCMSClient } from '@alpha-shares/fetchers';

import { 
  GetSidebarNavigationDocument,
  GetSidebarNavigationQuery,
  GetSidebarNavigationQueryVariables,
  AppId,
  MenuType,
  HeroMenuIcons,
  useGetSidebarNavigationQuery
} from '@alpha-shares/graphql-codegen/generated/graphCMS';

import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  CollectionIcon,
  PhotographIcon,
  ZoomInIcon,
  SearchIcon,
  ChartBarIcon
} from '@heroicons/react/outline'

import { ShieldCheckIcon } from '@heroicons/react/solid'

import { BitcoinIcon, AtomIcon, NFTIcon } from '../../index'

import { APP_BASE_PATH } from '@alpha-shares/config/constants/appBasePaths'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

type TSidebarNavigationProps = {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children?: React.ReactNode
  appId?: AppId
  root?: string
}

const Icons = {
  MainLogoIcon: () => <MainLogoIcon containerClass='mr-2' colorClass="hover:text-black" widthClass="w-8" heightClass="h-8" />,
  AlphaWolfIcon,
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  CollectionIcon,
  PhotographIcon,
  ZoomInIcon,
  SearchIcon,
  ShieldCheckIcon,
  BitcoinIcon, 
  AtomIcon, 
  NFTIcon,
  ChartBarIcon,
  AlphaDex, 
  AlphaMeteor, 
  AlphaWallet
}

export const SidebarNavigation:React.FC<TSidebarNavigationProps> = ({ setOpen, children, appId, root = '' }) => {
  
  const { address } = useAccount()
  const { route, query, pathname, asPath, basePath } = useRouter();
  const queryClient = useQueryClient();

  const initialMenuData = queryClient.getQueryData<GetSidebarNavigationQuery>(['navigationData'])

  const { data: menuData } = useGetSidebarNavigationQuery(graphCMSClient, { applicationId: appId || AppId.MarketplaceNext }, {
    initialData: initialMenuData,
    refetchOnWindowFocus: true,
  })

  const mainMenu = menuData?.menus.find((menu) => menu.menuSection === MenuType.Main)
  const asCollections = menuData?.menus.find((menu) => menu.menuSection === MenuType.AlphaSharesCollections)
  const ecosystemMenu = menuData?.menu
  const helpMenu = menuData?.menus.find((menu) => menu.menuSection === MenuType.Help)

  const menuItemClasses = (href: string | boolean) => classNames(
    route === href || href === true ? 'text-white bg-primary-800 bg-opacity-30' : 'text-gray-400 hover:text-dark-50 hover:bg-gradient-to-r from-blue-light to-blue-dark',
    'group flex items-center gap-x-3 rounded-md text-sm leading-6 font-semibold p-3 transition transition-all'
  )
  
  React.useMemo(() => {
    if (mainMenu?.navigationItems && appId === AppId.MarketplaceNext && address) {
      mainMenu?.navigationItems.push({
        id: 'portfolio',
        navigationItemText: 'Portfolio',
        iconComponent: HeroMenuIcons.NFTIcon,
        internalSlug: `portfolio/${address}`,
        destinationAppId: AppId.MarketplaceNext,
      })
    }
  }, [mainMenu?.navigationItems, address, appId] )

  function resolveBasePath(link: string,  destinationAppId: AppId) {
    if (basePath !== APP_BASE_PATH[destinationAppId]) {
      return `${APP_BASE_PATH[destinationAppId]}${link}`
    }
    return link;
  }
  
  return (
    <>
      <div className="bg-dark-50 flex grow flex-col gap-y-5 px-6 h-content">
        <div className="mt-7 flex shrink-0 items-center relative">
          <XIcon onClick={() => setOpen(!open)} className="mr-4 h-8 w-8 text-primary-600 cursor-pointer " />
          <Link href="/">
            <div className="flex items-center">
              <MainLogoIcon colorClass="text-blue-light" widthClass="w-8" heightClass="h-8" />
              <MainLogoText containerClass="hidden lg:block ml-1 lg:ml-2 text-xl lg:text-2xl" />
            </div>
          </Link>
        </div>
        <div>
          {children}
        </div>
        <div className="pb-16">
          <nav className="flex flex-1 flex-col pb-6">
            <ul className="flex flex-1 flex-col gap-y-7">
              <li>
                <ul className="-mx-2 space-y-1">
                  {mainMenu && mainMenu.navigationItems.map(({ destinationAppId, navigationItemText, externalLink, internalSlug, iconComponent }) => {
                    // @ts-ignore
                    const Icon = Icons[iconComponent]
                    let internalSlugPath: string = '';
                    let multiAppRoute: string = '';

                    if (internalSlug && APP_BASE_PATH[destinationAppId] === basePath) {
                      internalSlugPath = `/${internalSlug}`
                    }

                    if (internalSlug && APP_BASE_PATH[destinationAppId] !== basePath) {
                      multiAppRoute = `${APP_BASE_PATH[destinationAppId]}/${internalSlug}`
                    }

                    return (
                    <li key={navigationItemText}>
                      {externalLink && (
                        <Link
                          href={externalLink}
                          className={menuItemClasses(externalLink)}
                          target='_blank'
                        >
                          {/* @ts-ignore */}
                          <Icon widthClass='h-8 w-8' className="h-8 w-8 shrink-0 mr-2" aria-hidden="true" />
                          {navigationItemText}
                        </Link>
                      )}
                      {internalSlugPath && (
                        <Link
                          href={internalSlugPath}
                          className={menuItemClasses(internalSlugPath)}
                        >
                          <Icon className="h-8 w-8 shrink-0 mr-2" aria-hidden="true" />
                          {navigationItemText}
                        </Link>
                      )}
                      {multiAppRoute && (
                        <a
                          href={multiAppRoute}
                          className={menuItemClasses(internalSlugPath)}
                        >
                          <Icon className="h-8 w-8 shrink-0 mr-2" aria-hidden="true" />
                          {navigationItemText}
                        </a>
                      )}
                    </li>
                  )}
                  )}
                </ul>
              </li>
            </ul>
          </nav>

          {asCollections && asCollections?.navigationItems?.length > 0 && (
            <nav className="flex flex-1 flex-col border-t border-dark-800 py-6 mt-6">
              <Typography variant="h5">Featured Creators</Typography>
            <ul className="flex flex-1 flex-col gap-y-7 mt-6">
              <li>
                <ul className="-mx-2 space-y-1">
                  {asCollections && asCollections?.navigationItems?.map(({ destinationAppId, navigationItemText, externalLink, internalSlug, iconComponent }) => {
                    // @ts-ignore
                    const Icon = Icons[iconComponent]
                    let internalSlugPath: string = '';
                    let multiAppRoute: string = '';

                    if (internalSlug && APP_BASE_PATH[destinationAppId] === basePath) {
                      internalSlugPath = `/${internalSlug}`
                    }

                    if (internalSlug && APP_BASE_PATH[destinationAppId] !== basePath) {
                      multiAppRoute = `${APP_BASE_PATH[destinationAppId]}/${internalSlug}`
                    }

                    return (
                    <li key={navigationItemText}>
                      {externalLink && (
                        <Link
                          href={externalLink}
                          className={menuItemClasses(externalLink)}
                          target='_blank'
                        >
                          {/* @ts-ignore */}
                          <Icon widthClass='h-8 w-8' className="h-8 w-8 shrink-0 mr-2" aria-hidden="true" />
                          {navigationItemText}
                        </Link>
                      )}
                      {internalSlugPath && (
                        <Link
                          href={internalSlugPath}
                          className={menuItemClasses(internalSlugPath)}
                        >
                          <Icon className="h-8 w-8 shrink-0 mr-2" aria-hidden="true" />
                          {navigationItemText}
                        </Link>
                      )}
                      {multiAppRoute && (
                        <a
                          href={multiAppRoute}
                          className={menuItemClasses(internalSlugPath)}
                        >
                          <Icon className="h-8 w-8 shrink-0 mr-2" aria-hidden="true" />
                          {navigationItemText}
                        </a>
                      )}
                    </li>
                  )}
                  )}
                </ul>
              </li>
            </ul>
          </nav>
          )}

          <nav className="flex flex-1 flex-col border-t border-dark-800 py-6 mt-6">
            <Typography variant="h5">Alpha Shares Ecosystem</Typography>
            <ul className="flex flex-1 flex-col gap-y-7 mt-6">
              <li>
                <ul className="-mx-2 space-y-1">
                  {ecosystemMenu?.navigationItems.map((item) => (
                    <li key={item.navigationItemText}>

                      <Link
                        target="_blank"
                        href={item.externalLink as string}
                        className={classNames(
                          'text-gray-400 hover:text-dark-50 hover:bg-gradient-to-r from-blue-light to-blue-dark',
                          'group flex items-center gap-x-3 rounded-md text-sm leading-6 font-semibold p-3 transition transition-all'
                        )}
                        rel="noreferrer"
                      >
                        {item.navigationItemText}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            </ul>
          </nav>
          <nav className="flex flex-1 flex-col border-t border-dark-800 pt-6">
            <Typography variant="h5">Help</Typography>
            <ul className="flex flex-1 flex-col gap-y-7 mt-6">
              <li>
                <ul className="-mx-2 space-y-1">
                  {helpMenu?.navigationItems.map((item) => { 
                    let internalSlugPath: string = '';
                    let multiAppRoute: string = '';

                    if (item?.internalSlug && APP_BASE_PATH[item.destinationAppId] === basePath) {
                      internalSlugPath = `/${item?.internalSlug}`
                    }

                    if (item?.internalSlug && APP_BASE_PATH[item.destinationAppId] !== basePath) {
                      multiAppRoute = `${APP_BASE_PATH[item.destinationAppId]}/${item?.internalSlug}`
                    }

                    return (
                      <li key={item.navigationItemText}>

                      {item.externalLink && (
                        <Link
                          target="_blank"
                          href={item.externalLink as string}
                          className={classNames(
                            'text-gray-400 hover:text-dark-50 hover:bg-gradient-to-r from-blue-light to-blue-dark',
                            'group flex items-center gap-x-3 rounded-md text-sm leading-6 font-semibold p-3 transition transition-all'
                          )}
                          rel="noreferrer"
                        >
                          {item.navigationItemText}
                        </Link>
                      )}
                      {internalSlugPath && (
                        <Link
                          href={internalSlugPath as string}
                          className={classNames(
                            'text-gray-400 hover:text-dark-50 hover:bg-gradient-to-r from-blue-light to-blue-dark',
                            'group flex items-center gap-x-3 rounded-md text-sm leading-6 font-semibold p-3 transition transition-all'
                          )}
                          rel="noreferrer"
                        >
                          {item.navigationItemText}
                        </Link>
                      )}
                      {multiAppRoute && (
                        <a
                          href={multiAppRoute}
                          className={menuItemClasses(internalSlugPath)}
                        >
                          {item.navigationItemText}
                        </a>
                      )}
                    </li>
                  )})}
                </ul>
              </li>
            </ul>
          </nav>
          <div className="flex flex-col gap-y-8 items-center">

            <ConnectButtonRainbowkit />
            <div className="mt-4 flex items-center gap-4 pb-4 border-dark-100">
              {
            SOCIALS.items.map((element) => (
              <a className="text-gray-500" href={element.route} target="_blank" rel="noreferrer" key={element.route}>
                <element.icon className="w-8 h-8" />
              </a>
            ))
          }
            </div>
          </div>
        </div>
      </div>
    </>

  )
}
