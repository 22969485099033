import React from 'react'
import Image from "next/image"

import { Button, Typography } from '@alpha-shares/react-components'

import { CollectionCreator } from '@alpha-shares/graphql-codegen/generated/graphCMS'

type CreatorCardProps = {
  creator: CollectionCreator
}

export const CreatorCard:React.FC<CreatorCardProps> = ({ creator }) => {
  return (
    <div className='relative shadow-xl bg-dark-300 p-3 rounded-xl border border-transparent hover:border-primary-500'>            
      {creator.collectionCreatorThumbnailImage && (
      <div className='rounded-lg relative' style={{ aspectRatio: '1/1'}}>
        <Image
          src={creator.collectionCreatorThumbnailImage.url}
          alt={creator.collectionCreatorName ?? 'Creator Image'}
          fill
          className='rounded-lg' />
      </div>
      )}
      <Typography className='my-2 text-center' variant='h4'>{creator.collectionCreatorName}</Typography>
      <Typography className='my-2 h-24 line-clamp-4 font-light' variant='body1'>{creator.collectionCreatorBio}</Typography>
      <Button href={`/featured/${creator.collectionCreatorSlug}`}>View Collections</Button>
    </div>
  );
}