import React from 'react';
import { FooterMedium } from '../Organisms/Footers/FooterMedium';

interface IStandardLayoutProps {
  children: React.ReactNode;  
}

export const SimpleLayout:React.FC<IStandardLayoutProps> = ({
  children
}) => {
 
  return (
    <>
      <main className="h-full w-full relative">      
        {children}
      </main>
      <FooterMedium className='mt-12' />      
    </>
  );
}
