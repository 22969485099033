// @ts-nocheck
import { useEffect, useState } from "react";
import { ChainId } from "@alpha-shares/web3-sdk";
import Axios from 'axios'
import { useQueries } from "@tanstack/react-query";
import supportedChains from "@alpha-shares/res-utils/chains";

const marketplaceApiClient = Axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_HOST_URL : '',
  headers: {
    "Content-Type": "application/json",
  },
});

export const useCrossChainTrendingCollections = (numberPerChain = 2, chainId?: ChainId[] = null) => {
  const [loaded, setLoaded] = useState(false);
  
  let queries = [];
  let endpoints = [];

  if (chainId) {
    chainId.forEach((id) => {
      let selectedChain = supportedChains.find((chain) => chain.id === id);
      if (!selectedChain) {
        throw new Error("Chain not supported");
      }
      endpoints.push({
        id: selectedChain.id,
        endpoint: selectedChain.proxyApi
      });
    })
    
  } else {
    endpoints = supportedChains.map((chain) => {
      return {
        id: chain.id,
        endpoint: chain.proxyApi
      }
    });
  }

  queries = endpoints.map(({ endpoint, id }) => {    
    const query = {
      queryKey: ["trendingCollections", endpoint + '_' + JSON.stringify(chainId) + '_' + id],
      queryFn: () => marketplaceApiClient.get(endpoint + "/collections/trending/v1?limit=" + numberPerChain + "&period=24h&sortBy=volume&normalizeRoyalties=true").then(res => res.data)
    }
    return query;
  })
  
  const results = useQueries({ queries: 
    queries
  }).map((result, idx) => {
    return {
      ...result,
      chainId: endpoints[idx].id
    }
  })

  useEffect(() => {
    if (results.every((result) => (result.isSuccess && result.data))) {
      setLoaded(true)
    }
  },[results])

  return { loaded, results }
}