import React from 'react';
import Image from "next/image";

import { Button } from '../../Atoms'

import supportedChains, { DefaultChain } from '@alpha-shares/res-utils/chains'

type ChainSelectionTabsProps = {
  selectedChain: typeof DefaultChain,
  setSelectedChain: React.Dispatch<React.SetStateAction<typeof DefaultChain>>
}

export const ChainSelectionTabs:React.FC<ChainSelectionTabsProps> = ({ selectedChain, setSelectedChain }) => {
  return (
    <div className='hidden lg:flex flex-row gap-4 items-center justify-center mt-8'>
      {supportedChains.map((supportedChain) => (
        // @ts-ignore
        <Button className='rounded-b-none'  onClick={() => setSelectedChain(supportedChain)} variant={selectedChain.id === supportedChain.id ? 'secondary' : 'link'} key={supportedChain.id}>
          <Image
            alt={supportedChain.name}
            src={
              supportedChain.lightIconUrl
            }
            height={20}
            width={supportedChain.id === 1 ? 15 : 20}
            style={{
              maxWidth: "100%",
              height: "auto"
            }} />
        </Button>
      ))}
    </div>
  );
}