import React from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  Typography, NFTTypeCard
} from '../../'

import {
  HomePageQuery
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

type NFTTier = {
  id: string
  description: string
  weightText: string
  totalAvailableText: string
}

export const CollectionInfoSection:React.FC = () => {
  const queryClient = useQueryClient()
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage'])
  const page = queryData?.page
  const alphaNftCollectionTier = page?.alphaNftCollectionTier
  const heading = alphaNftCollectionTier?.heading
  const subHeading = alphaNftCollectionTier?.subHeading
  const tiers = alphaNftCollectionTier?.tiers
  const information = alphaNftCollectionTier?.information

  
  return (
    <div className="mt-24 py-20 bg-dark-300">
      <div className="max-w-screen-xl mx-auto items-center grid lg:grid-cols-2 gap-10 px-6 lg:px-0">
        <div>
          <Typography variant="h1" as="h2" className="font-bold py-8 text-center lg:text-left">
            {heading}
            <br />
            {subHeading}
          </Typography>
          <Typography variant="body1" className="text-lg font-semibold text-gray-300">
            {information}
          </Typography>
        </div>
        <div className="grid content-center align-middle justify-center">
          {/* @ts-ignore */}
          {tiers && tiers.map((tier: NFTTier, i: 0 | 1 | 2 | 3) => (
            <NFTTypeCard
              key={tier.id}
              nftType={i}
              variant="description"
              descriptionText={tier.description}
              weightText={tier.weightText}
              availableText={tier.totalAvailableText}
            />
          ))}
        </div>
      </div>
    </div>
  )
}
