import React from 'react'
import { Typography } from '../../Atoms'

import { graphCMSClient } from '@alpha-shares/fetchers'

import {
  useMarketplaceAnnouncementBannerQuery
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

export const AnnouncementBanner:React.FC = () => {

  const { data, isFetched } = useMarketplaceAnnouncementBannerQuery(graphCMSClient)

  if (!isFetched || !data?.marketplaceAnnouncementBanner?.announcementText) {
    return null
  }

  return (
    <div className='bg-primary-500 p-2 text-center bg-opacity-50'>
      <Typography variant='body1'>{data?.marketplaceAnnouncementBanner?.announcementText}</Typography>
    </div>
  )
}