import React from 'react';
import classNames from 'classnames';

interface ILogoProps {
  colorClass?: string;
  containerClass?: string;
}

export const MainLogoText: React.FC<ILogoProps> = ({
  colorClass,
  containerClass,
}) => {
  const containerClasses = classNames(
    containerClass,
    colorClass,
    'flex items-center'
  );

  return (
    <div className={containerClasses}>
      <span className="inline-block ml-1 font-bold text-white">
        alpha&nbsp;
        <span className="font-light text-white inline-block">shares</span>
      </span>
    </div>
  );
};
