import React, { useState } from 'react';
import { ArrowCircleUpIcon } from '@heroicons/react/outline';
import { Transition } from '@headlessui/react';

export const ScrollTopButton: React.FC = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300) {
      setVisible(true);
    } else if (scrolled <= 300) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <Transition
      className="fixed bottom-6 right-6 lg:bottom-8 lg:right-8 cursor-pointer inline z-50"
      show={visible}
      enter="transition duration-150 transform"
      enterFrom="opacity-0 translate-y-full scale-25"
      enterTo="opacity-100 translate-y-0 scale-100"
      leave="transition duration-150 transform"
      leaveFrom="opacity-100 translate-y-0 scale-100"
      leaveTo="opacity-0 translate-y-0 scale-50"
    >
      <ArrowCircleUpIcon
        className="w-10 h-10 lg:w-16 lg:h-16 text-primary-500"
        onClick={scrollToTop}
      />
    </Transition>
  );
};
