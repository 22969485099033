import {
  CalendarIcon,
  ChartPieIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  CollectionIcon,
  PhotographIcon,
  ZoomInIcon,
  SearchIcon
} from '@heroicons/react/outline'

// import * as HeroIcons from '@heroicons/react/solid'

import { ShieldCheckIcon } from '@heroicons/react/solid'

import { BitcoinIcon, AtomIcon, NFTIcon, MainLogoIcon, MainLogoText, Typography } from '@alpha-shares/react-components'

// export const Icons = HeroIcons;

export const MAIN_NAV = {
  navigation: [
    {
      name: 'Home', href: '/', icon: HomeIcon
    },
    {
      name: 'My Account', href: '/account', icon: UsersIcon
    },
    {
      name: 'AMC Dashboard', href: 'https://mining.alphashares.io/dashboard', icon: UsersIcon, target: '_blank'
    },
    {
      name: 'Mint', href: '/mint', icon: CollectionIcon
    },
    {
      name: 'Explore', href: '/explore', icon: NFTIcon
    },
    {
      name: 'Browse', href: '/assets', icon: SearchIcon
    },
    {
      name: 'Alpha Mining Co', href: '/explore/0xa8a49255026ec0ab032bbb5c4f457aaa9b138ea6', icon: PhotographIcon, current: false
    },
  ],
  help: [
    {
      id: 1, name: 'FAQ', href: '/help-center', icon: AtomIcon, target: 'self'
    },
    {
      id: 2, name: 'Docs', href: 'https://docs.alphashares.io/guides/welcome', current: false, icon: AtomIcon, target: '_blank'
    },
    {
      id: 1, name: 'Medium', href: 'https://alphashares.medium.com/', icon: AtomIcon, target: '_blank'
    },
  ],
  ecosystem: [
    {
      id: 1, name: 'Alpha Shares NFT Conference', href: 'https://launch.alphashares.io/#nft-conference', icon: AtomIcon, target: '_blank'
    },
    {
      id: 1, name: 'NFT Launchpad', href: 'https://launch.alphashares.io', icon: AtomIcon, target: '_blank'
    },
    {
      id: 1, name: 'Momentum Finance', href: 'https://www.momentumproject.finance', icon: AtomIcon, target: '_blank'
    },
    // { id: 2, name: 'AlphaDex', href: '#', initial: 'T', current: false, icon: AtomIcon },

  ]
};
