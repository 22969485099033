import * as React from 'react';
// import { Transition } from '@headlessui/react';


interface ILoadingGradientBar {
  showing?: boolean;
  className?: string;
}

// <Transition
//       show={showing}
//       enter="transition duration-100 transform"
//       enterFrom="opacity-0"
//       enterTo="opacity-100"
//       leave="transition duration-250 transform"
//       leaveFrom="opacity-100 "
//       leaveTo="opacity-0 "
//     >

export const LoadingGradientBar:React.FC<ILoadingGradientBar> = ({
  showing = false,
  className = '-mb-1 h-1 ',
}) => {
  return (
      <div className={`${className} ${!showing && 'opacity-0'} w-full animated-gradient z-50`} />
  );
};
