import React from 'react';
import { Tab } from '@headlessui/react';

import classNames from 'classnames';

export type Slot = {
  tabTitle: string;
  tabTitleContent: any;
  tabContent: any;
};

export interface IFullWidthTabs {
  slots: Slot[];
  defaultTab?: number;
  containerClasses?: string;
  listUlClasses?: string;
  listLiClasses?: string;
  panelClasses?: string;
}

const AlphaTab = ({ children, className }: { children: React.ReactNode, className?: string }) => (
  <Tab
    className={({ selected }) => classNames(
      selected ? 'border-primary text-primary' : 'border-dark-200',
      'text-sm lg:text-base lg:pt-6 px-4 lg:px-6 pb-4 hover:text-primary-500 hover:border-accent transition-all text-white border-b-4 -mb-1 flex flex-wrap justify-center lg:items-center gap-2 font-semibold',
      className
    )}
  >
    {children}
  </Tab>
);

export const FullWidthTabs: React.FC<IFullWidthTabs> = ({
  slots,
  defaultTab,
  containerClasses,
  listUlClasses,
  listLiClasses,
  panelClasses,
}) => {
  const mergedContainerClasses = classNames('bg-dark-50', containerClasses);
  const mergedListUlClassesClasses = (numItems?: number) => classNames(
    // `grid ${gridCols[numItems]}`,
    'md:container justify-center flex',
    'w-full bg-dark-50 pt-4 border-dark-300',
    listUlClasses
  );

  return (
    <div className={mergedContainerClasses}>
      {slots && (
        <Tab.Group defaultIndex={defaultTab}>
          <div className="border-b-4 border-dark-200 w-full flex">
            <Tab.List className={mergedListUlClassesClasses(slots.length)}>
              {slots.map(({ tabTitle, tabTitleContent }) => (
                <AlphaTab className={listLiClasses} key={tabTitle}>
                  {tabTitleContent}
                </AlphaTab>
              ))}
            </Tab.List>
          </div>
          <Tab.Panels>
            {slots.map(({ tabContent, tabTitle }) => (
              <Tab.Panel key={tabTitle}>{tabContent}</Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      )}
    </div>
  );
};
