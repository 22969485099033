/* eslint-disable react/no-array-index-key */
import React from 'react'
import Image from "next/image"
import { useRouter } from 'next/router';
import {
  ImageElement,
  Typography,
  MultiCarousel,
  Button,  
} from '../../../';

import { Box, FormatCryptoCurrency, Text } from '../../../primitives'

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'

import { useCrossChainTrendingCollections } from '@alpha-shares/res-utils/hooks/useCrossChainTrendingCollections'
import supportedChains from '@alpha-shares/res-utils/chains'

import { ChainId } from '@alpha-shares/web3-sdk'
import { APP_BASE_PATH } from '@alpha-shares/config/constants/appBasePaths';


interface IAssetCarousel {
  title?: string;
  titleClass?: string;
  HeadingLink?: JSX.Element
  chainId?: ChainId[];
  numberPerChain?: number;
  showGradientbg?: boolean;
  config?: {
    sm?: number,
    md?: number,
    lg?: number,
    xl?: number,
  }
}

const getChainImage = (chainId: ChainId) => {
  const url = supportedChains.find((chain) => {
    return chain.id === Number(chainId)
  })?.lightIconUrl
  
  return url
}

const getCollectionLink = (chainId: ChainId, contractAddress: string, basePath: string): string => {
  const chainPrefix = supportedChains.find((chain) => {
    return chain.id === Number(chainId)
  })?.routePrefix

  let routeBasePath = ''

  if (basePath !== APP_BASE_PATH.MarketplaceNext) {
    routeBasePath = APP_BASE_PATH.MarketplaceNext
  }

  let link = `${routeBasePath}/${chainPrefix}/collection/${contractAddress}`;

  return link
}

const LoadingState = () => (
  <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>
    <div className="opacity-[30] animate animate-pulse mx-4 rounded-xl">
      <Skeleton count={1} style={{aspectRatio: '1 / 1'}} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-t-xl' />
      <Skeleton count={1} height={78} containerClassName='animate animate-pulse rounded-xl' className='bg-dark-50 rounded-b-xl' />          
    </div>
  </SkeletonTheme>
  )

export const TrendingCollectionsCarousel:React.FC<IAssetCarousel> = ({
  title = 'Trending Collections',
  titleClass = '', 
  HeadingLink,
  config = {
    sm: 1,
    md: 2,
    lg: 4,
    xl: 6
  },
  chainId,
  numberPerChain = 3,
  showGradientbg = false
}) => {
  const router = useRouter();
  const { basePath } = router;

  const { loaded, results } = useCrossChainTrendingCollections(numberPerChain, chainId)
  
  let collectionsCarouselData: any[] = []  

  collectionsCarouselData = React.useMemo(() => {
    if (loaded && results.length > 0) {
      results.forEach((result, idx) => {
        let trendingCollections = result?.data
        // @ts-ignore
        if (trendingCollections?.collections?.length)  {
          // @ts-ignore
          let thisChainCollections = trendingCollections?.collections?.map((collection) => {
            return {
              ...collection,
              chainId: result.chainId
            }
          })
          collectionsCarouselData.push(thisChainCollections)
        }        
      })
    }
    // @ts-ignore
    return collectionsCarouselData.flatMap(item => item)

  }, [loaded] )

  const responsive = [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 5,
        slidesToScroll: 1,          
      }
    },
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        centerPadding: '40px'          
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    }      
  ]
 
  return (
    <div className='relative pt-6 mt-0 md:mx-0'>
      {showGradientbg && (
      <div className="absolute w-full h-[580px] -mt-10 pointer-events-none opacity-50" style={
        {
          backgroundImage: 'url(https://media.graphassets.com/S26EpP1URReV49K6sW5e)', 
          backgroundSize: 'auto', 
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',                    
        }}></div>
        )}
    {/* @ts-ignore */}
      <MultiCarousel
        HeadingLink={HeadingLink}
        carouselTitle={title}
        autoPlay
        centerMode
        containerClasses='mt-6'
        titleClass={`mx-auto text-center text-white text-3xl font-bold mb-8 ${titleClass}`}
        //@ts-ignore
        responsiveConfig={responsive}
      >
        {(!loaded) && ([...new Array(12).fill('x')].map((item, index) => (
          <LoadingState key={index} />
        )))}

        
        {/* @ts-ignore */}
        {(collectionsCarouselData && loaded) && collectionsCarouselData?.filter(item => !!item.image).map((item) => (          
          <div className="p-3 lg:p-4 group relative" key={item.id}>
              <ImageElement
                width={item.chainId == 1 ? 15 : 24}
                height={24}
                alt='Network Logo'
                // @ts-ignore
                src={getChainImage(item.chainId)}
                className='absolute top-6 left-6 z-10 bg-dark-550 rounded-xl p-1'
              />
            <div className='bg-dark-550 rounded-b-xl overflow-hidden relative'>
              {item?.image && (
              <div className='relative' style={{'aspectRatio' : '1 / 1'}}>                
              <Image
                src={item.image}
                //@ts-ignore
                alt={item.name}
                fill
                sizes={`(min-width: 1024px) 400px, (min-width: 768px) 300px, 200px`}
                style={{
                  maxWidth: "100%",
                }} />
              </div>
              )}
              <div className="cursor-grab">
                <div className='pt-4'>
                  <Typography variant="h4" as="div" className="text-center text-gray-300 px-4 line-clamp-1 group-hover:bg-transparent">
                    {item.name}
                  </Typography>   
                  <div className='p-4 mx-auto flex justify-center items-center relative'>
                    <div className='flex justify-between px-4 w-full'>
                      <Box>
                        <Text
                          style="subtitle2"
                          color="subtle"
                          as="p"
                          css={{ mb: 2 }}
                        >
                          FLOOR
                        </Text>
                        <FormatCryptoCurrency
                          chainId={item.chainId}
                          amount={
                            item?.floorAsk?.price?.amount?.native ?? 0
                          }
                          textStyle={'h6'}
                          logoHeight={12}
                          address={
                            item?.floorAsk?.price?.currency?.contract
                          }
                        />
                      </Box>
                      <Box>
                        <Text style="subtitle2" color="subtle" as="p">
                          24H SALES
                        </Text>
                        <Text style="h6" as="h4" css={{ mt: 2 }}>
                          {item.count?.toLocaleString()}
                        </Text>
                      </Box>
                    </div>
                  </div>        
                  <div className="p-4">
                    <Button variant='secondary' href={getCollectionLink(item.chainId, item.id, basePath)} className='w-full'>Browse</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </MultiCarousel>
    </div>
  );
}
