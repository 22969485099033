/* eslint-disable react/display-name */

import { AppId } from '@alpha-shares/graphql-codegen/generated/graphCMS';
import React from 'react';

// @ts-ignore
export const withLayout = (Layout, Component, appId?: AppId = AppId.MarketplaceNext) => (props) => (
  <Layout appId={appId}>
    <Component {...props} />
  </Layout>
);
