/* eslint-disable react/no-array-index-key */
import React from 'react'

import { BLOCKCHAIN_CONFIG } from '@alpha-shares/config';
import { Address } from 'wagmi'
import { ChainId } from '@alpha-shares/web3-sdk';

import { useAccount } from 'wagmi';

import { useMarketplaceRouteBasePath } from '@alpha-shares/res-utils/hooks';

import {
  Typography,
  MultiCarousel,
  Button,
} from '../../..';

import TokenCard from '../../../collections/TokenCard';

import { 
  useDynamicTokens,   
  useUserTokens,
  useUserCollections
} from '@reservoir0x/reservoir-kit-ui'

interface IAssetCarousel {
  title?: string;
  subHeader?: string;
  titleClass?: string;
  HeadingLink?: JSX.Element
  chainId?: ChainId;
  collectionAddress?: string;
  queryParams?: any;  
}

export const UserTokenCarousel:React.FC<IAssetCarousel> = ({
  title = 'More From This Collection',
  subHeader,
  titleClass = 'mx-auto text-center py-4 mt-6',  
  HeadingLink,
  chainId = ChainId.AVALANCHE,
  collectionAddress = BLOCKCHAIN_CONFIG.ALPHA_NFT_ADDRESS[ChainId.AVALANCHE],  
}) => {
  const marketPlaceRouteBasePath = useMarketplaceRouteBasePath()
  const [numOwned, setNumOwned] = React.useState<number>(0)
  const { address } = useAccount()
  const [playingElement, setPlayingElement] = React.useState<
    HTMLAudioElement | HTMLVideoElement | null
  >()

  const { data: ownershipInfo } = useUserCollections(
    address,
    {
      collection: collectionAddress,            
    },
    {},
    chainId
  )

  const { data: tokens } = useUserTokens(
    address,
    {
      limit: 10,
      collection: collectionAddress,
    },
    {},
    chainId
  );

  const responsive = [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: (tokens.length < 6) ? tokens.length : 6,
        slidesToScroll: 1,          
      }
    },
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: (tokens.length < 6) ? tokens.length : 6,
        slidesToScroll: 1,
        centerPadding: '40px'          
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: (tokens.length < 5) ? tokens.length : 5,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: (tokens.length < 4) ? tokens.length : 4,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: (tokens.length < 2) ? tokens.length : 2,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    }      
  ]

  React.useEffect(() => {
    if (ownershipInfo && ownershipInfo?.length) {
      let num = 0;
      if (ownershipInfo[0].ownership?.tokenCount) {
        num = Number(ownershipInfo[0].ownership?.tokenCount)
      }
      setNumOwned(num)
    }
  }, [ownershipInfo])

  const HeadingLinkComponent = () => (
    <div className='mx-auto pb-6 max-w-lg'>
      <Button
        href={`${marketPlaceRouteBasePath}/portfolio/${address}?chain=avalanche`}
        variant='tertiary' 
        className='mx-auto'
        >Manage Your Alphas</Button>
    </div>
  )

  return (
    <>
    {subHeader && (
      <Typography
        variant="subtitle1"
        className="text-center mx-auto !text-primary-500 mb-6"
      >
        {subHeader}
      </Typography>
    )}
    {/* @ts-ignore */}
      <MultiCarousel
        HeadingLink={<HeadingLinkComponent />}
        carouselTitle={`You Own ${numOwned} Alphas`}
        titleClass={titleClass}
        //@ts-ignore
        responsiveConfig={responsive}
        centerMode
      > 

        {/* @ts-ignore */}
        {tokens && tokens.map((token, idx) => (          
          <div className="p-3 " style={{maxWidth: '275px'}} key={idx}>
            <div className='bg-dark-400 rounded-xl overflow-hidden'>
                <TokenCard
                  isOwner={true}
                  key={idx}
                  // @ts-ignore
                  token={token}
                  address={collectionAddress as Address}
                  selectedItems={[]}
                  setSelectedItems={() => {}}
                  // mutate={mutate}
                  rarityEnabled={true}
                  onMediaPlayed={(e) => {
                    if (
                      playingElement
                        && playingElement !== e.nativeEvent.target
                    ) {
                      playingElement.pause()
                    }
                    const element = (e.nativeEvent.target as HTMLAudioElement)
                        || (e.nativeEvent.target as HTMLVideoElement)
                    if (element) {
                      setPlayingElement(element)
                    }
                  }}                
                />
            </div>
          </div> 
        ))}
      </MultiCarousel>
    </>
  )
}
