import React, { useContext, useEffect, useState } from 'react'
import Image from "next/image"
import {
  Text,
  Flex,
  Box,
  FormatCryptoCurrency,
} from '../../primitives'

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'

import { ChainContext } from '@alpha-shares/react-components/Contexts/ChainContextProvider'

import supportedChains, { DefaultChain } from '@alpha-shares/res-utils/chains'

import Img from '../../primitives/Img'
import useTopSellingCollections, { type TopSellingCollectionv2Data } from '@alpha-shares/res-utils/hooks/useTopSellingCollections'
import ReactMarkdown from 'react-markdown'

import { styled } from '@alpha-shares/theme/stitches.config'

import optimizeImage from '@alpha-shares/res-utils/optimizeImage'
import { MarkdownLink } from '../../primitives/MarkdownLink'
import { useRouter } from 'next/router'

import { Typography, Button, withLayout, LoadingIndicator, ChainSelectionTabs  } from '@alpha-shares/react-components'

type TopSellingCollectionsProps = {
 
}

const StyledImage = styled('img', {})

const LoadingState = () => (
  <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>
    <div className='hidden lg:flex flex-row gap-4 items-center justify-center mt-8'>
      {supportedChains.map((supportedChain, idx) => (
        // @ts-ignore
        <Button disabled className='rounded-b-none' variant='link' key={idx}>
          <Image
            alt={supportedChain.name}
            src={
              supportedChain.lightIconUrl
            }
            height={20}
            width={supportedChain.id === 1 ? 15 : 20}
            style={{
              maxWidth: "100%",
              height: "auto"
            }} />
        </Button>
      ))}
    </div>
    <div className="hidden md:grid rounded-xl p-8 bg-dark-400 container md:grid-cols-1 lg:grid-cols-2 gap-8 opacity-[30] animate animate-pulse">
      <Skeleton count={1} height={600}  containerClassName='animate animate-pulse col col-span-1 ' className=' bg-dark-50' />      
      <div className='col-span-1 grid grid-cols-2'>
        <Skeleton count={1} height={50}  containerClassName='animate animate-pulse col col-span-1' />
        <Skeleton count={1} height={100}  containerClassName='animate animate-pulse col col-span-2' />
        <div className='grid grid-cols-4 gap-4 mt-4 col-span-2'>
          <Skeleton count={1} height={120}  containerClassName='animate animate-pulse col col-span-1' />
          <Skeleton count={1} height={120}  containerClassName='animate animate-pulse col col-span-1' />
          <Skeleton count={1} height={120}  containerClassName='animate animate-pulse col col-span-1' />
          <Skeleton count={1} height={120}  containerClassName='animate animate-pulse col col-span-1' />
        </div>
        <div className='mt-4 grid grid-cols-2 col-span-2 gap-4'>
          <Skeleton count={2} height={40}  containerClassName='animate animate-pulse col-span-1' />
          <Skeleton count={2} height={40}  containerClassName='animate animate-pulse col-span-1' />
        </div>
        <div className='grid grid-cols-2 col-span-2 gap-4 mt-8'>
          <Skeleton count={1} height={60}  containerClassName='animate animate-pulse' />                        
        </div>
      </div>
    </div>
  </SkeletonTheme>
)

export const TopSellingCollections:React.FC<TopSellingCollectionsProps> = () => {
  const [selectedChain, setSelectedChain] = useState(DefaultChain)
  const router = useRouter()
  // const { chain } = useContext(ChainContext)
  let topCollection: any;

  const { data: topSellingCollectionsData, isFetching, isLoading, isError } = useTopSellingCollections(
    {
      period: '24h',
      includeRecentSales: true,
      limit: 7,
      fillType: 'sale',
    },
    selectedChain.id 
  )
  
  if (isFetching || isLoading) {
    return (
      <LoadingState />
    )
  }

  if(topSellingCollectionsData && topSellingCollectionsData.collections) {
    topCollection = topSellingCollectionsData.collections[0] 
  } else {
    return <LoadingState />;
  }

  return (
    <>
    <ChainSelectionTabs selectedChain={selectedChain} setSelectedChain={setSelectedChain} />
    <div className='hidden md:flex md:flex-col lg:flex-row gap-6 justify-center mx-auto p-8 bg-dark-400 rounded-xl'>
      <Box
        css={{
          position: 'absolute',
          top: 0,
          display: 'none',
          zIndex: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backdropFilter: 'blur(20px)',
          background: 'rgba(255, 255, 255, 0.9)',
        }}
      />

      {topSellingCollectionsData && (
        <>
          <Box
            css={{
              flex: 2,
              position: 'relative',
              zIndex: 5,
              '@xl': {
                flex: 3,
              },
            }}
          >
            <StyledImage
              src={optimizeImage(
                topCollection?.banner ||
                  topCollection?.image ||
                  topCollection?.recentSales?.[0]?.collection?.image ||
                  topCollection?.recentSales?.[0]?.token?.image,
                1820
              )}
              css={{
                width: '100%',
                borderRadius: 8,
                height: 320,
                '@lg': {
                  height: 540,
                },
                '@xl': {
                  height: 640,
                },
                objectFit: 'cover',
              }}
            />
            <Box
              css={{
                position: 'absolute',
                left: '$4',
                '@lg': {
                  top: '$4',
                },
                bottom: '$4',
              }}
            >
              <Img
                alt="collection image"
                width={100}
                height={100}
                style={{
                  display: 'block',
                  borderRadius: 8,
                  border: '2px solid rgba(255,255,255,0.6)',
                }}
                src={optimizeImage(topCollection?.image, 200) as string}
              />
            </Box>
          </Box>
          <Box css={{ flex: 2, zIndex: 4 }}>
            <Flex direction="column" css={{ height: '100%' }}>
              <Box css={{ flex: 1 }}>
                <Text style="h3" css={{ mt: '$3', mb: '$2' }} as="h3">
                  {topCollection?.name}
                </Text>

                <Box
                  css={{
                    maxWidth: 720,
                    lineHeight: 1.5,
                    fontSize: 16,
                    fontWeight: 400,
                    display: '-webkit-box',
                    color: '$gray12',
                    fontFamily: '$body',
                    WebkitLineClamp: 3,
                    WebkitBoxOrient: 'vertical',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                  }}
                >
                  <ReactMarkdown
                    children={topCollection?.description || ''}
                    components={{
                      a: MarkdownLink,
                      p: Text as any,
                    }}
                  />
                </Box>

                <Flex css={{ mt: '$4' }}>
                  <Box css={{ mr: '$5' }}>
                    <Text style="subtitle2" color="subtle">
                      FLOOR
                    </Text>
                    <Box css={{ mt: 2 }}>
                      <FormatCryptoCurrency
                        amount={
                          topCollection?.floorAsk?.price?.amount
                            ?.native ?? 0
                        }
                        textStyle={'h4'}
                        logoHeight={20}
                        address={
                          topCollection?.floorAsk?.price?.currency
                            ?.contract
                        }
                      />
                    </Box>
                  </Box>

                  <Box css={{ mr: '$4' }}>
                    <Text style="subtitle2" color="subtle">
                      24H SALES
                    </Text>
                    <Text style="h4" as="h4" css={{ mt: 2 }}>
                      {topCollection?.count?.toLocaleString()}
                    </Text>
                  </Box>
                </Flex>
                <Box
                  css={{
                    display: 'none',
                    '@lg': {
                      display: 'block',
                    },
                  }}
                >
                  <Text
                    style="subtitle2"
                    color="subtle"
                    as="p"
                    css={{ mt: '$4' }}
                  >
                    RECENT SALES
                  </Text>
                  <Flex
                    css={{
                      mt: '$2',
                      gap: '$3',
                    }}
                  >
                    {topCollection?.recentSales
                      ?.slice(0, 4)
                      ?.map((sale: any, i: number) => (
                        <Box
                          css={{
                            aspectRatio: '1/1',
                            maxWidth: 120,
                          }}
                          key={i}
                          onClick={(e) => {
                            e.stopPropagation()
                            e.preventDefault()
                            if (
                              sale?.collection?.id &&
                              sale?.token?.id
                            ) {
                              router.push(
                                `/${selectedChain.routePrefix}/asset/${sale?.collection?.id}:${sale?.token?.id}`
                              )
                            }
                          }}
                        >
                          <img
                            style={{ borderRadius: 4 }}
                            src={optimizeImage(
                              sale?.token?.image ||
                                topCollection?.image,
                              250
                            )}
                          />
                          <Box css={{ mt: '$1' }}>
                            <FormatCryptoCurrency
                              chainId={selectedChain.id}
                              amount={sale?.price?.amount?.decimal ?? 0}
                              textStyle={'h6'}
                              logoHeight={16}
                              address={sale?.price?.currency?.contract}
                            />
                          </Box>
                        </Box>
                      ))}
                    <Box css={{ flex: 1 }} />
                    <Box css={{ flex: 1 }} />
                  </Flex>
                </Box>
              </Box>
              <Flex css={{ gap: '$4', mt: '$5' }}>                        
                <Button href={`/${selectedChain.routePrefix}/collection/${topCollection?.id}`} variant="primary">
                  Explore Collection
                </Button>
              </Flex>
            </Flex>
          </Box>
        </>
      )}
    </div>
    </>
  )
}