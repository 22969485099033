import React from 'react'
import Image from "next/image"
import { useQueryClient } from '@tanstack/react-query';

import {
  Typography,
} from '../../';

import {
  type HomePageQuery,
  type PartnerSection
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

export const PartnersSection:React.FC = () => {
  const queryClient = useQueryClient();
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage']);
  const page = queryData?.page;
  const partnerSection = page?.partnerSection;
  
  const { heading, partnerLogos } = partnerSection as PartnerSection;

  return (
    <div className="text-center mt-24 max-w-screen-xl mx-auto items-center px-4 lg:px-0">
      <Typography variant="h2" as="div">
        {heading}
      </Typography>
      <div className="mt-8 flex-col lg:flex-row flex items-center justify-center gap-16">
        {partnerLogos.map((partner) => (
          <>
          { partner?.image && partner?.link && (
          <a key={partner.id} href={partner?.link} target="_blank" rel="noreferrer" className="flex">
            <Image
              src={partner?.image?.url}
              alt={partner?.link}
              width={300}
              height={150}
              style={{
                maxWidth: "100%",
                height: "auto"
              }} />
          </a>
          )}
          </>
        ))}
      </div>
    </div>
  );
}
