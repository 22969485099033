import React from 'react'
import Image from "next/image"
import classNames from 'classnames'
import { useQueryClient } from '@tanstack/react-query'

import {
  MainLogoIcon,
  Button,
  Typography,
} from '../../';

import {
  HomePageQuery,
  type CtaSection
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

const backgroundGradientMap = {
  from: {
    primary: 'from-primary-500',
    secondary: 'from-secondary-500',
    tertiary: 'from-tertiary-500',
    accent: 'from-accent-500',
    dark: 'from-dark-500',
    gray: 'from-gray-500',
    black: 'from-black-500',
    white: 'from-white-500',
  },
  to: {
    primary: 'to-primary-500',
    secondary: 'to-secondary-500',
    tertiary: 'to-tertiary-500',
    accent: 'to-accent-500',
    dark: 'to-dark-500',
    gray: 'to-gray-500',
    black: 'to-black-500',
    white: 'to-white-500',
  }
}

export const CTASection:React.FC = () => {
  const queryClient = useQueryClient()
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage'])
  const page = queryData?.page;
  const ctaSection = page?.ctaSection;
  
  const {
    image, text, mainButton, backgroundFrom, backgroundTo
  } = ctaSection as CtaSection

  const bgfrom = backgroundFrom ?? 'primary'
  const bgto = backgroundTo ?? 'secondary'

  const backgroundClass = classNames(
    'py-4 lg:py-0',
    'flex-col lg:flex-row rounded-lg text-center lg:text-left flex items-center justify-center',
    'bg-primary-500',
    'bg-gradient-to-br',
    `${backgroundGradientMap.from[bgfrom]}`,
    `${backgroundGradientMap.to[bgto]}`,
  )

  return (
    <div className="py-24 max-w-screen-md mx-auto px-4 lg:px-0">
      <div className={backgroundClass}>
        {image?.url ? (
          <Image
            src={image.url}
            width={256}
            height={256}
            alt="Background Image"
            style={{
              maxWidth: "100%",
              height: "auto"
            }} />
        )
          : (
            <div className="py-4 lg:py-12">
              <MainLogoIcon
                widthClass="w-20 md:w-32"
                heightClass="h-20 md:h-32"
                colorClass="text-primary-500"
              />
            </div>
          )}
        <div className="flex flex-col px-4 lg:px-10 content-between">
          {text && text.map((textBlock) => (
            <Typography
              key={textBlock.id}
              // @ts-ignore
              variant={textBlock.tag}
              className={`font-bold text-${textBlock.textColor}-500`}
            >
              {textBlock.text}
            </Typography>
          ))}

          <Button
            href={mainButton?.buttonLink}
            rounded
            // @ts-ignore
            variant={mainButton?.buttonVariant}
            className="lg:w-4/6 mt-4"
          >
            {mainButton?.buttonText}
          </Button>
        </div>
      </div>
    </div>
  );
}
