import { mainnet } from 'wagmi/chains'
import { useQuery } from '@tanstack/react-query'
import { useAccount } from 'wagmi'
import { truncateAddress, truncateEns } from '../truncate'

const useENSResolver = (address?: string, chainId: number = 1) => {
  const { address: accountAddress } = useAccount()
  const addressLowercase = address?.toLowerCase()
  const isENSAvailable = chainId === mainnet.id
  const isAccountAddress =
    accountAddress && address?.toLowerCase() === accountAddress?.toLowerCase()

  // console.log('in use ens resolver', address, chainId, isENSAvailable, isAccountAddress)

  const fetchUrl = `https://api.ensideas.com/ens/resolve/${addressLowercase}`

  const response = useQuery(
    ['ens', addressLowercase],
    () => fetch(fetchUrl).then((response) => response.json()),
    {
      enabled: isENSAvailable && !!address,    
      refetchOnWindowFocus: false,
    }
  )

  const shortAddress = address ? truncateAddress(address) : ''
  const shortName = response.data?.name ? truncateEns(response.data.name) : null
  let displayName = ''

  if (isAccountAddress) {
    displayName = 'You'
  } else if (response.data?.name) {
    displayName = shortName || ''
  } else if (address) {
    displayName = shortAddress
  }

  return {
    ...response,
    address,
    name: response.data?.name,
    shortName,
    displayName,
    shortAddress,
    avatar: response.data?.avatar,
  }
}

export default useENSResolver;

