import React, { FC } from 'react';
import { Oval } from '@agney/react-loading';
import classNames from '@alpha-shares/theme/utils/cn';

import { useIsFetching, useIsMutating } from '@tanstack/react-query';

export interface LoadingProps {
  label?: string;
  className?: string;
  isFixedTop?: boolean;
  forcedShow?: boolean;
}

export const LoadingIndicator: FC<LoadingProps> = ({
  label,
  className,
  forcedShow,
  isFixedTop = true,
  ...props
}) => {
  
  const classes = classNames(className, '');
  const containerClasses = classNames(
    isFixedTop
      && 'flex items-center justify-center absolute top-[0.5em] right-[0.5em] opacity-40',
    'flex h-full'
  );
  const isFetching = useIsFetching();
  const isMutating = useIsMutating();

  return isFetching || isMutating || forcedShow ? (
    <div className={containerClasses}>
      <Oval className={classes} {...props}>
        {label}
      </Oval>
    </div>
  ) : null;
};
