/* eslint-disable react/no-array-index-key */
import React from 'react'
import Image from "next/image"
import { useRouter } from 'next/router';
import Link from 'next/link'
import { ChevronRightIcon } from '@heroicons/react/outline'
import { ChainId } from '@alpha-shares/web3-sdk';

import {
  Typography,
  MultiCarousel,
  Button,
  CarouselControls,
} from '../../../';

import { graphCMSClient } from '@alpha-shares/fetchers';

import Skeleton, { SkeletonTheme }from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

import { colors } from '@alpha-shares/theme/stitches.config'

import {
  type FeaturedCollectionGroupQueryVariables,
  type FeaturedCollectionGroupQuery,
  useFeaturedCollectionGroupQuery
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

import { useMarketplaceRouteBasePath } from '@alpha-shares/res-utils/hooks';
import supportedChains from '@alpha-shares/res-utils/chains'

interface IAssetCarousel {
  title?: string;
  titleClass?: string;
  containerClasses?: string;
  HeadingLink?: JSX.Element;
  variables?: FeaturedCollectionGroupQueryVariables  
}

function getNetworkRoot(chainId: ChainId) {
  const chain = supportedChains.find((chain) => {
    return chain.id === chainId
  })

  return chain?.routePrefix
}

const LoadingState = () => (
  <div className="opacity-[30] animate animate-pulse mx-4 rounded-xl overflow-hidden">
  <SkeletonTheme baseColor={colors.dark[300]} highlightColor={colors.dark[50]}>    
      <Skeleton count={1} style={{aspectRatio: '1 / 1'}} containerClassName='animate animate-pulse rounded-xl lg:hidden' className='bg-dark-50' />
      <div className="relative hidden lg:block rounded-b-2xl overflow-hidden min-h-[640px]"  style={{aspectRatio: '21 / 6'}}>
        <Skeleton count={1} height={800} containerClassName='animate animate-pulse rounded-xl hidden lg:block' className='bg-dark-50' />
      </div>          
  </SkeletonTheme>
  </div>
  )

export const HeroCarousel:React.FC<IAssetCarousel> = ({
  title = 'Collections',
  containerClasses,
  titleClass = '',
  variables = { id: 'clrnu0zd7w0hp0bivbemr9pga' },
  HeadingLink,  
}) => {
  const marketplaceRootPath = useMarketplaceRouteBasePath();
  
  const { data, isFetching, isLoading, isFetched } = useFeaturedCollectionGroupQuery(graphCMSClient, variables)

  const responsive = [
    {
      breakpoint: 4000,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,          
      }
    },
    {
      breakpoint: 2500,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '40px'          
      }
    },
    {
      breakpoint: 1920,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      }
    },
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '30px'
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: '20px'
      }
    }      
  ]

  const collections = data?.featuredCollectionGroup?.nftCollections.filter((item) => 
    Boolean(
      item.collectionBannerImage?.url && 
      item.collectionThumbnail?.url
      ))

  return (
    <MultiCarousel
      containerClasses="h-full relative"
      HeadingLink={HeadingLink}
      carouselTitle={null}
      titleClass={titleClass}
      //@ts-ignore
      responsiveConfig={responsive}
    >
      {(isFetching || isLoading || !isFetched) && ([...new Array(3).fill('x')].map((item, index) => (
        <LoadingState key={index} />
      )))}
      {collections && collections?.length > 0 && collections?.map((item) => (
        <React.Fragment key={item.id}>
          <div className="relative overflow-hidden px-3"  >
            <div className='bg-dark-550 overflow-hidden relative rounded-b-xl'>
            {item.collectionBannerImage?.url && (
              <>
                <div className="relative lg:hidden" style={{aspectRatio: '1 / 1'}} >
                  {item.collectionThumbnail?.url && (
                  <Image
                    src={item.collectionThumbnail?.url}
                    alt={item.collectionTitle ?? 'Collection Thumbnail'}
                    fill
                    className="h-full w-full object-cover rounded-b-xl"
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover"
                    }} />
                  )}
                </div>
                <div className="relative hidden lg:block rounded-b-2xl overflow-hidden min-h-[640px]"  style={{aspectRatio: '21 / 6'}}>
                  <Image
                    src={item.collectionBannerImage?.url}
                    //@ts-ignore
                    alt={item.collectionTitle ?? 'Collection Banner'}
                    fill
                    className="h-full w-full object-cover rounded-b-2xl"
                    style={{
                      maxWidth: "100%",
                      objectFit: "cover"
                    }} />
                </div>
              </>
            )}
            <div className="absolute w-full bottom-0 mx-auto">
              <div className="bg-dark-550 bg-opacity-80  mx-auto p-2 xl:p-4 pr-6 rounded-b-xl md:rounded-b-xl">
                <Link href={`${marketplaceRootPath}/${getNetworkRoot(item?.chain?.chainId as ChainId)}/collection/${item.collectionContractAddress}`}>
                  <div className="flex items-center justify-between md:justify-center cursor-pointer">
                    <div className='hidden md:block '>
                      <Image
                        //@ts-ignore
                        src={item.collectionThumbnail.url}
                        width={50}
                        height={50}
                        //@ts-ignore
                        alt={item.collectionTitle}
                        className="rounded-xl"
                        style={{
                          maxWidth: "100%",
                          height: "auto",
                          objectFit: "cover"
                        }} />
                    </div>
                    <div className="ml-2 xl:ml-4 flex flex-col justify-between">
                      <Typography variant="body1" className="!text-xs uppercase font-bold hidden md:block " color="text-gray-400">
                        Featured Collection
                      </Typography>
                      <Typography variant="h3">
                        {item.collectionTitle}
                      </Typography>
                    </div>
                    <div className="ml-4 flex items-center">
                      <Link href={`${marketplaceRootPath}/${getNetworkRoot(item?.chain?.chainId as ChainId)}/collection/${item.collectionContractAddress}`}>
                        <Button className="!p-1" variant="tertiary"><ChevronRightIcon className="w-5 h-5" /></Button>
                      </Link>
                    </div>
                  </div>
                </Link>
              </div>
            </div>
            </div>
          </div>
        </React.Fragment>
      ))}
    </MultiCarousel>
  );
}
