import React from 'react';
import { useQueryClient } from '@tanstack/react-query'

import {
  HomePageQuery,
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

// import {
//   AssetCarousel,
// } from 'components';

// import { MintsGrid } from 'components/Features/MintingPage/MintsGrid';
import { HeroSection } from './HeroSection';

import { InfoCardsSection } from './InfoCardsSection';
import { PoweredBySection } from './PoweredBySection';
import { CollectionInfoSection } from './CollectionInfoSection';
import { InfoIconSection } from './InfoIconSection';
import { PerksSection } from './PerksSection';
import { PartnersSection } from './PartnersSection';
import { TeamSection } from './TeamSection';
import { FAQSection } from './FAQSection';
import { CTASection } from './CTASection';

import { TrendingCollectionsCarousel } from '../Carousels';

export const HomePage:React.FC = () => {
  const queryClient = useQueryClient()
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage'])

  if (!queryData?.page) return null;

  const { page } = queryData;

  const {
    pageHero,
    gettingStartedSection,
    mintingStat,
    perksSection,
    infoCardsSection,
    poweredBySection,
    alphaNftCollectionTier,
    partnerSection,
    teamSection,
    fAQSection,
    ctaSection,
  } = page

  return (
    <>
      <div className="text-white px-6">
        {/* {pageHero
        && <HeroSection />} */}
        {poweredBySection
        && <PoweredBySection />}

        {infoCardsSection
        && <InfoCardsSection />}

        {gettingStartedSection
        && <InfoIconSection />}

        {/* {perksSection
        && <PerksSection />} */}

        {alphaNftCollectionTier
        && <CollectionInfoSection />}

        {partnerSection
        && <PartnersSection />}

        {/* {teamSection
        && <TeamSection />} */}

        {fAQSection
        && <FAQSection />}

        {ctaSection
        && <CTASection />}

      </div>
    </>
  );
}
