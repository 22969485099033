// @ts-nocheck
import { useEffect, useState } from "react";
import { ChainId } from "@alpha-shares/web3-sdk";
import Axios from 'axios'
import { useQueries } from "@tanstack/react-query";
import supportedChains from "@alpha-shares/res-utils/chains";

const marketplaceApiClient = Axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? process.env.NEXT_PUBLIC_HOST_URL : '',
  headers: {
    "Content-Type": "application/json",
  },
});

export type UseCrossChainTokensArgs = {
  numberPerChain?: number,
  query: {
    nativeSource?: string,
    chainId: ChainId,
    contracts: string[]
  }[]
}

function constructQuery(endpoint, contracts, nativeSource, numberPerChain) {
  let query = `${endpoint}/tokens/v7?limit=${numberPerChain}&sortBy=floorAskPrice&sortDirection=asc&normalizeRoyalties=true&contract=${contracts.join('&contract=')}`
  if (nativeSource) {
    query += `&nativeSource=${nativeSource}`
  }
  return query;
}

export const useCrossChainTokens = (
  numberPerChain: UseCrossChainTokensArgs['numberPerChain'] = 2, 
  query: UseCrossChainTokensArgs['query']
) => {
  const [loaded, setLoaded] = useState(false);
  
  let queries = [];
  let endpoints = [];

  query.forEach(({ chainId, contracts, nativeSource }) => {
    let selectedChain = supportedChains.find((chain) => chain.id === chainId);
    if (!selectedChain) {
      throw new Error("Chain not supported");
    }
    endpoints.push({
      id: selectedChain.id,
      endpoint: selectedChain.proxyApi,
      contracts,
      nativeSource
    });
  })    

  queries = endpoints.map(({ endpoint, contracts, nativeSource }) => {
    const contractQueryArg = contracts.join('&contract=')    
    const fullEndpoint = constructQuery(endpoint, contracts, nativeSource, numberPerChain)
    const query = {
      queryKey: ["latestListings", endpoint + '_' + contractQueryArg],
      queryFn: () => marketplaceApiClient.get(fullEndpoint).then(res => res.data)
    }
    return query;
  })
  
  const results = useQueries({ queries: 
    queries
  }).map((result, idx) => {
    return {
      ...result,
      chainId: endpoints[idx].id
    }
  })

  useEffect(() => {
    if (results.every((result) => (result.isSuccess && result.data))) {
      setLoaded(true)
    }
  },[results])

  return { loaded, results }
}