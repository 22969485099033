import React from 'react'

import cn from '@alpha-shares/theme/utils/cn'

import { graphCMSClient } from '@alpha-shares/fetchers'

import { Typography } from '../../Atoms'

import {  
  useFeaturedCreatorGroupQuery,
  type CollectionCreator
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

import { CreatorCard } from './CreatorCard'
import { CreatorCardLoadingState } from './CreatorCardLoadingState'

type CreatorsGridProps = {
  titleClasses?: string;
  containerClasses?: string;
}

export const CreatorsGrid:React.FC<CreatorsGridProps> = ({
  titleClasses,
  containerClasses
}) => {

  const { data, isFetched } = useFeaturedCreatorGroupQuery(graphCMSClient, { id: 'clrnt8dugvvn10ait5xxwtrn1' })

  return (
    <>
      <div className='mx-6 mt-6'>
        <Typography className={cn(titleClasses)} variant='h1' as='h3'>Featured Creators</Typography>
      </div>
      <div className={cn('grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-6 mx-6 mt-6', containerClasses)}>      
        {(!isFetched) && (
        ([...new Array(6).fill('x')].map((item, index) => (          
              <CreatorCardLoadingState key={index} />
          )))
        )}  
        {isFetched && data?.featuredCreatorGroup?.collectionCreators.map((creator) => {
          return (
            <CreatorCard creator={creator as CollectionCreator} /> )})}
      </div>    
    </>
  )
}