import React from 'react';

import cn from '@alpha-shares/theme/utils/cn'
import { Tags } from '@alpha-shares/graphql-codegen/generated/graphCMS';

export type TVariant = keyof typeof variantClasses;

export interface TypographyProps {
  as?: keyof JSX.IntrinsicElements;
  variant: TVariant;
  className?: string;
  color?: string;
  children: React.ReactNode;
  [x: string]: any;
}

enum variantClasses {
  h1 = 'text-3xl md:text-4xl font-bold',
  h2 = 'text-2xl md:text-3xl font-bold',
  h3 = 'text-xl md:text-2xl font-bold',
  h4 = 'text-lg md:text-xl font-bold',
  h5 = 'text-lg font-bold',
  subtitle1 = 'text-base font-bold',
  subtitle2 = 'text-base font-normal',
  p = 'text-base font-bold mt-2',
  body1 = 'text-base font-bold',
  body2 = 'text-sm font-normal',
  caption = 'text-sm font-light',
};

export const Typography: React.FC<TypographyProps> = ({
  as,
  children,
  className,
  variant,
  color = 'text-white',
  ...restProps
}) => {
  const mergedClasses = cn(
    color,
    variantClasses[variant],
    className
  );

  if (as) {
    const Tag: keyof JSX.IntrinsicElements = `${as}`;
    return (
      // @ts-ignore
      <Tag className={mergedClasses} {...restProps}>
        {children}
      </Tag>
    );
  }
  switch (variant) {
    case 'p':
      return (
        <p className={mergedClasses} {...restProps}>
          {children}
        </p>
      );
    case 'h1':
      return (
        <h1 className={mergedClasses} {...restProps}>
          {children}
        </h1>
      );
    case 'h2':
      return (
        <h2 className={mergedClasses} {...restProps}>
          {children}
        </h2>
      );
    case 'h3':
      return (
        <h3 className={mergedClasses} {...restProps}>
          {children}
        </h3>
      );
    case 'h4':
      return (
        <h4 className={mergedClasses} {...restProps}>
          {children}
        </h4>
      );
    case 'h5':
      return (
        <h5 className={mergedClasses} {...restProps}>
          {children}
        </h5>
      );
    case 'subtitle1':
      return (
        <h6 className={mergedClasses} {...restProps}>
          {children}
        </h6>
      );
    case 'subtitle2':
      return (
        <h6 className={mergedClasses} {...restProps}>
          {children}
        </h6>
      );
    case 'body1':
      return (
        <p className={mergedClasses} {...restProps}>
          {children}
        </p>
      );
    case 'body2':
      return (
        <p className={mergedClasses} {...restProps}>
          {children}
        </p>
      );
    default:
      return null;
  }
};

Typography.displayName = 'Typography';
