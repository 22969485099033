import React from 'react'
import { useQueryClient } from '@tanstack/react-query'

import {
  BookmarkAltIcon, DatabaseIcon, PhotographIcon
} from '@heroicons/react/outline';

import {
  MainLogoIcon,
  Typography,
} from '../../';

import {
  type HomePageQuery,
  type GettingStartedSection
} from '@alpha-shares/graphql-codegen/generated/graphCMS'

export const InfoIconSection:React.FC = () => {
  const queryClient = useQueryClient()
  const queryData = queryClient.getQueryData<HomePageQuery>(['homePage'])
  const page = queryData?.page
  const gettingStartedSection = page?.gettingStartedSection
  
  const {
    sections
  } = gettingStartedSection as GettingStartedSection
  return (
    <div className="mt-24 px-20 lg:px-0 grid gap-8 md:grid-cols-2 lg:grid-cols-4 text-center max-w-screen-xl mx-auto">
      <div className="flex flex-col items-center">
        <BookmarkAltIcon className="h-10 w-10 text-primary-500 rotate-90" />
        <Typography variant="h5" className="mt-6">
          {sections[0].cardHeading}
        </Typography>
        <Typography variant="body1" className="mt-4 px-4 text-xs font-semibold">
          {sections[0].cardText}
        </Typography>
      </div>
      <div className="flex flex-col items-center">
        <DatabaseIcon className="h-10 w-10 text-primary-500" />
        <Typography variant="h5" className="mt-6">
          {sections[1].cardHeading}
        </Typography>
        <Typography variant="body1" className="mt-4 px-4 text-xs font-semibold">
          {sections[1].cardText}
        </Typography>
      </div>
      <div className="flex flex-col items-center">
        <PhotographIcon className="h-10 w-10 text-primary-500" />
        <Typography variant="h5" className="mt-6">
          {sections[2].cardHeading}
        </Typography>
        <Typography variant="body1" className="mt-4 px-4 text-xs font-semibold">
          {sections[2].cardText}
        </Typography>
      </div>
      <div className="flex flex-col items-center">
        <MainLogoIcon
          widthClass="w-10 "
          heightClass="h-10 "
          colorClass="text-primary"
        />
        <Typography variant="h5" className="mt-6">
          {sections[3].cardHeading}
        </Typography>
        <Typography variant="body1" className="mt-4 px-4 text-xs font-semibold">
          {sections[3].cardText}
        </Typography>
      </div>
    </div>
  )
}
