import { useContext } from "react";
import { useAccount, useWalletClient } from 'wagmi'
import { useReservoirClient } from "@reservoir0x/reservoir-kit-ui";
import { ToastContext } from '@alpha-shares/react-components/Contexts/ToastContextProvider'

export default function useBulkTransferNFTs(
  token: string[], 
  onProgress: (arg: any) => void,
  onError: (arg: any) => void,
  ) {
  const { addToast } = useContext(ToastContext)
  const client = useReservoirClient()
  const currentChain = client?.currentChain()
  const { address } = useAccount();
  const { data: wallet } = useWalletClient({ chainId: currentChain?.id })  
  
  return function executeTransfer(to: `0x${string}`) {
    client?.actions.transferTokens({
        to: to,
        items: [
          ...token.map((t) => ({
            token: t,
            quantity: 1,
          })),
        ],
        // @ts-ignore
        wallet,
        onProgress: (steps) => {
          onProgress(steps)
        },      
      }).catch(onError)
  }
}