import * as React from 'react'
import getConfig from 'next/config';

import { GoogleAnalytics } from 'nextjs-google-analytics';

import AnalyticsProvider, {
  initializeAnalytics,
} from '@alpha-shares/react-components/Contexts/AnalyticsProvider'
import ErrorTrackingProvider from '@alpha-shares/react-components/Contexts/ErrorTrackingProvider'
import merge from 'lodash/merge';

import type { AppProps } from 'next/app'
import { ThemeProvider } from 'next-themes'
import {
  darkTheme, globalReset, alphaTheme
} from '@alpha-shares/theme/stitches.config'

import '@rainbow-me/rainbowkit/styles.css'
import 'styles/tailwind.css';

import {  
  injectedWallet,
  trustWallet,
  walletConnectWallet,
  metaMaskWallet,
  coinbaseWallet
} from '@rainbow-me/rainbowkit/wallets';

import {
  connectorsForWallets,
  RainbowKitProvider,
  midnightTheme,
  Theme
} from '@rainbow-me/rainbowkit'

import { WagmiConfig, createConfig, configureChains } from 'wagmi'
import * as Tooltip from '@radix-ui/react-tooltip'
import { publicProvider } from 'wagmi/providers/public'
import { alchemyProvider } from 'wagmi/providers/alchemy'
import { infuraProvider } from 'wagmi/providers/infura'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import {
  ReservoirKitProvider,
  ReservoirKitTheme,
  CartProvider,
} from '@reservoir0x/reservoir-kit-ui'
import {
  FC, useContext, useState
} from 'react'
import { HotkeysProvider } from 'react-hotkeys-hook'
import ToastContextProvider from '@alpha-shares/react-components/Contexts/ToastContextProvider'
import supportedChains from 'utils/chains'
import { useMarketplaceChain } from '@alpha-shares/res-utils/hooks'

import ChainContextProvider, { useChainContext } from '@alpha-shares/react-components/Contexts/ChainContextProvider'
import { WebsocketContextProvider } from '@alpha-shares/react-components/Contexts/WebsocketContextProvider'

import ReferralContextProvider, {
  ReferralContext,
} from '@alpha-shares/react-components/Contexts/ReferralContextProvider'
import { chainPaymentTokensMap } from '@alpha-shares/res-utils'

// start alpha shares port
import { QueryClient, QueryClientProvider, Hydrate } from '@tanstack/react-query';

import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ClientOnly, ScrollTopButton } from '@alpha-shares/react-components';
import { LogLevel } from '@reservoir0x/reservoir-sdk';

initializeAnalytics()

export const NORMALIZE_ROYALTIES = true

process.env.NEXT_PUBLIC_NORMALIZE_ROYALTIES
  ? process.env.NEXT_PUBLIC_NORMALIZE_ROYALTIES === 'true'
  : false

const projectId = '1f8d457b89fabb7f964179ee43f1556a';

const { chains, publicClient } = configureChains(supportedChains, [
  alchemyProvider({ apiKey: 'TZQpE46-BAIk2mSrMJmd7jWfx9PVlgDm' }),
  infuraProvider({ apiKey: 'de6de2f5188349e9a7a173bc3a80ba88' }),
  jsonRpcProvider({
    rpc: () => ({
      http: 'https://polygon-mainnet.g.alchemy.com/v2/uU00bcMBe2py1ivlJ2yl_RIxAz--WY25'
    })
  }),
  publicProvider(),
])

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      injectedWallet({ chains }),
      walletConnectWallet({ projectId, chains }),

    ]
  },
  {
    groupName: 'Others',
    wallets: [
      trustWallet({
        projectId,
        chains
      }),
      coinbaseWallet({ chains, appName: 'Alpha Shares Marketplace' }),
    ],
  },
]);

const wagmiClient = createConfig({
  autoConnect: true,
  connectors,
  publicClient,
})

const alphaSharesTheme = merge(merge(midnightTheme(), {
  colors: {
    accentColor: '#00D4FF',
    accentColorForeground: 'black',
    modalBackground: '#09111a'
  },
  fonts: {
    body: 'Manrope'
  }
} as Theme));

function AppWrapper(props: AppProps & { baseUrl: string }) {
  
  React.useEffect(() => {
    if (wagmiClient.status !== 'connected') {
      wagmiClient.autoConnect()
    }
  }, [])

  return (
    <>
    <GoogleAnalytics trackPageViews />
    <ThemeProvider
      attribute="class"
      defaultTheme="dark"
      value={{
        dark: darkTheme.className,
        light: 'light',
      }}
    > 
        <WagmiConfig config={wagmiClient}>
          <ChainContextProvider>
            <AnalyticsProvider>
              <ErrorTrackingProvider>
                <ReferralContextProvider>
                  <MyApp {...props} />
                </ReferralContextProvider>
              </ErrorTrackingProvider>
            </AnalyticsProvider>
          </ChainContextProvider>
        </WagmiConfig>
    </ThemeProvider>
    </>
  )
}

function MyApp({
  Component,
  pageProps,
}: AppProps) {
  globalReset()
  const queryClient = new QueryClient()
  const { publicRuntimeConfig } = getConfig();
  const { fees } = useChainContext()

  const { localUrl, vercelUrl, branchUrl } = publicRuntimeConfig;

  let baseUrl = localUrl ?? vercelUrl ?? branchUrl;

  if (baseUrl && (!baseUrl?.includes('https://') && !baseUrl?.includes('http://'))) {
    baseUrl = `https://${baseUrl}`;
  }

  const marketplaceChain = useMarketplaceChain()
  const [reservoirKitTheme, setReservoirKitTheme] = useState<
    ReservoirKitTheme | undefined
  >(alphaTheme)
  
  const { feesOnTop } = useContext(ReferralContext)

  const FunctionalComponent = Component as FC

  let source = process.env.NEXT_PUBLIC_MARKETPLACE_SOURCE

  if (!source && process.env.NEXT_PUBLIC_HOST_URL) {
    try {
      const url = new URL(process.env.NEXT_PUBLIC_HOST_URL)
      source = url.host
    } catch (e) {}
  }

  return (
    <QueryClientProvider client={queryClient}>
      <Hydrate state={pageProps.dehydratedState}>
        <HotkeysProvider>       
          <ReservoirKitProvider
            options={{
            // Reservoir API key which you can generate at https://reservoir.tools/
            // This is a protected key and displays as 'undefined' on the browser
            // DO NOT add NEXT_PUBLIC to the key or you'll risk leaking it on the browser
              apiKey: process.env.RESERVOIR_API_KEY,
              // CONFIGURABLE: Override any configuration available in RK: https://docs.reservoir.tools/docs/reservoirkit-ui#configuring-reservoirkit-ui
              // Note that you should at the very least configure the source with your own domain
              chains: supportedChains.map(
                ({
                  reservoirBaseUrl,
                  proxyApi,
                  id,
                  name,
                  checkPollingInterval,
                }) => {
                  // console.log('reservoirBaseUrl, proxyApi, id, name, checkPollingInterval : ', reservoirBaseUrl, proxyApi, id, name, checkPollingInterval)
                  // console.log('`${baseUrl}${proxyApi}` :' , `${baseUrl}${basePath}${proxyApi}`)
                  let proxyApiUrl = `${baseUrl}${proxyApi?.replace('reservoir', 'alpha-shares')}`
                  // console.log('====================================== proxyApiUrl : ', proxyApiUrl)
                  return {
                    id,
                    name,
                    baseApiUrl: proxyApi
                      ? `${proxyApiUrl}`
                      : reservoirBaseUrl,
                    active: marketplaceChain.id === id,
                    checkPollingInterval,
                    paymentTokens: chainPaymentTokensMap[id],
                  }
                }
              ),
              logLevel: process.env.NEXT_PUBLIC_LOG_LEVEL as unknown as LogLevel,
              source,
              normalizeRoyalties: NORMALIZE_ROYALTIES,
              // CONFIGURABLE: Set your marketplace fee and recipient, (fee is in BPS)
              // Note that this impacts orders created on your marketplace (offers/listings)
              marketplaceFees: [fees],
              disablePoweredByReservoir: true,
              automatedRoyalties: true,
            }}
            theme={reservoirKitTheme}
          >
            <CartProvider feesOnTopUsd={feesOnTop}>
              <WebsocketContextProvider>
                <Tooltip.Provider>
                  <RainbowKitProvider
                    chains={chains}
                    theme={alphaSharesTheme}
                  >
                    <ToastContextProvider>
                      <FunctionalComponent {...pageProps} />
                      <ClientOnly>
                        <ScrollTopButton />
                      </ClientOnly>
                      <ReactQueryDevtools initialIsOpen={false} />
                    </ToastContextProvider>
                  </RainbowKitProvider>
                </Tooltip.Provider>
              </WebsocketContextProvider>
            </CartProvider>
          </ReservoirKitProvider>
        </HotkeysProvider>
      </Hydrate>
    </QueryClientProvider>
  )
}


export default AppWrapper
